import { useState, useCallback, } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, } from '@mui/material/styles';
import { ROUTES, } from 'Utils/constants';
import AppLayout from 'Components/AppLayout/AppLayout';
import SidebarContent from '../SidebarContent';
import { DASHBOARD_MENU_ITEMS, } from './constants';

const home = `/${ROUTES.dashboard}`;

const LayoutDashboard = (): JSX.Element => {
  const isMatchingLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
  const [isOpenMobileMenu, setOpenMobileMenu,] = useState<boolean>(false);
  const handleOpenMobileMenu = useCallback(() => setOpenMobileMenu(true), []);
  const handleCloseMobileMenu = useCallback(() => setOpenMobileMenu(false), []);

  const mobileMenuContent = <SidebarContent onClose={handleCloseMobileMenu} menuItems={DASHBOARD_MENU_ITEMS} sidebarHref={home} />;
  const sidebarContent = <SidebarContent menuItems={DASHBOARD_MENU_ITEMS} sidebarHref={home} />;

  return (
    <AppLayout
      toHome={home}
      isMatchingLg={isMatchingLg}
      isOpenMobileMenu={isOpenMobileMenu}
      mobileMenuContent={mobileMenuContent}
      sidebarContent={sidebarContent}
      onCloseMobileMenu={handleCloseMobileMenu}
      onOpenMobileMenu={handleOpenMobileMenu}
    />
  );
};

export default LayoutDashboard;
