import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { AssortmentStore, useAssortmentStore, } from 'Modules/AssortmentAdmin/store/AssortmentStore';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import FetchData from './FetchData';

const selectModalData = (s: AssortmentStore) => s.modalEditAssortment;
const selectClose = (s: AssortmentStore) => s.closeModalEditAssortment;

const ModalEditAssortment = (): JSX.Element => {
  const { isOpen, assortment, } = useAssortmentStore(selectModalData);
  const onClose = useAssortmentStore(selectClose);
  const { t, } = useTranslation('assortmentAdmin');

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!assortment}
    >
      <ModalDrawerTitle
        onClose={onClose}
      >
        {t('editAssortmentModal.title')}
      </ModalDrawerTitle>
      <FetchData
        assortment={assortment}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalEditAssortment;
