import { yupResolver, } from '@hookform/resolvers/yup';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { FormProvider, useForm, } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { usePromotionPurchaseForm, } from './utils';
import View from './View';

const selectStoreData = (s: RegistrationStore) => ({
  goBackToPhase: s.goBackToPhase,
  completePromotionPurchasePhase: s.completePromotionPurchasePhase,
  inputPromotionPurchase: s.inputPromotionPurchase,
  promotionCategoryPurchaseList: s.promotionCategoryPurchaseList,
  activePromotionPurchase: s.activePromotionPurchase,
});

const Logic = (): JSX.Element => {
  const { goBackToPhase, promotionCategoryPurchaseList, activePromotionPurchase, inputPromotionPurchase, } = useRegistrationStore(
    selectStoreData,
    shallow,
  );
  const { index, id, } = activePromotionPurchase;
  const { promotion, assortmentCategories, } = promotionCategoryPurchaseList[id || ''][index || 0];
  const assortmentCategoryOptions = promotion.promotionAssortmentCategoriesForPurchases.map((category) => category.assortmentCategory);
  const { initValues, validationSchema, structure, } = usePromotionPurchaseForm(assortmentCategories, assortmentCategoryOptions, promotion);
  const methods = useForm({
    defaultValues: initValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    mode: 'onChange',
  });

  const handleSubmit = methods.handleSubmit((values) => {
    inputPromotionPurchase(values.values.assortmentCategories, id || '', index || 0);
    goBackToPhase('purchase');
  });

  const goBack = () => {
    goBackToPhase('purchase');
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <View goBack={goBack} structure={structure} promotion={promotion} />
      </form>
    </FormProvider>
  );
};

export default Logic;
