import { Typography, } from "@mui/material";
import { UserFragment, useUserTaxQuery, } from "Apollo/graphql";
import LoadingBox from "Components/LoadingBox";
import { usePreserveValue, } from "Tools/usePreserveValue";
import ViewTaxInfo from "./ViewTaxInfo";

interface Props {
  user: UserFragment,
};

const FetchTaxData = ({
  user,
}: Props): JSX.Element => {
  const preservedUser = usePreserveValue(user);
  const { loading, error, data, } = useUserTaxQuery({
    variables: { userId: preservedUser.id, },
    fetchPolicy: 'network-only',
  });
  if (loading) return (
    <LoadingBox />
  );
  if (error || !data?.userTaxInfoById) return (
    <Typography>{error?.message}</Typography>
  );
  return (
    <ViewTaxInfo
      userTax={data.userTaxInfoById}
    />
  );
};

export default FetchTaxData;
