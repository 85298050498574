import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useWatch, useFormContext, } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { isGlobalLimitEnabled, } from './utils';

interface Props {
  max?: number | null;
}

export const PromotionsRemainingCount = ({ max, }: Props): JSX.Element => {
  const { t, } = useTranslation();
  const { control, } = useFormContext();
  const value = useWatch({ name: 'values.promotions', control, });

  const remaining = useMemo<number | null>(() => {
    if (!max) return null;
    const keys = Object.keys(value || {});
    const total = keys.reduce((prev, curr) => prev + (value[curr]?.count || 0), 0);
    return max - total;
  }, [max, value,]);

  return (
    <Typography variant="body1" sx={{ mb: 1, }}>
      {isGlobalLimitEnabled(max)
        ? t('registrationSeller:phasePurchase.promotionRemainingCountGlobal', { remaining: remaining || '0', })
        : t('registrationSeller:phasePurchase.promotionRemainingCountSingle')}
    </Typography>
  );
};

export default PromotionsRemainingCount;
