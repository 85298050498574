import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import SidebarLogo from 'Components/AppLayout/components/SidebarLogo';
import SidebarMenuButton from 'Components/AppLayout/components/SidebarMenuButton';
import SidebarMenuLink from 'Components/AppLayout/components/SidebarMenuLink';
import { useTranslation, } from 'react-i18next';
import { isPermitted, useAuthLogout, useAuthUser, } from 'Tools/auth';
import CloseIcon from 'Utils/svg/CloseIcon';
import LogoutColoredIcon from 'Utils/svg/LogoutColoredIcon';
import { NotificationType, } from 'Apollo/graphql';
import useNotifications from 'Tools/useNotifications';
import { camelCase, } from 'change-case';
import { CamelCase, } from 'type-fest';
import { MenuItem, } from './types';

interface Props {
  onClose?: () => void;
  menuItems: MenuItem[];
  sidebarHref: string;
}

const menuNotificationTypeMap = new Map([
  ['assortment', NotificationType.Assortment,],
  ['promotions', NotificationType.Promotion,],
  ['sellerTasks', NotificationType.Task,],
  ['news', NotificationType.NewsArticle,],
  ['myPoints', NotificationType.Voucher,],
]);

const SidebarContent = ({ onClose, menuItems, sidebarHref, }: Props): JSX.Element => {
  const {
    t,
  } = useTranslation();
  const { unreadNotificationsByCategory, } = useNotifications();
  const authUser = useAuthUser();
  const authLogout = useAuthLogout();
  const handleClick = () => onClose?.();

  return (
    <>
      {onClose && (
        <IconButton
          size="large"
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            '& .MuiSvgIcon-root': {
              fontSize: '1rem',
            },
          }}
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
      <SidebarLogo to={sidebarHref} />
      <List>
        {menuItems.map((item) => {

          // casting as CamelCase<T> - function camelCase returns only string
          const category = camelCase(menuNotificationTypeMap.get(item.id) ?? '') as CamelCase<NotificationType>;

          const unreadNotificationsNumber = category ? unreadNotificationsByCategory[category] : 0;

          return (
            <SidebarMenuLink
              key={item.id}
              exact={item.exact}
              icon={item.icon}
              route={item.route}
              onClick={handleClick}
              isAllowed={isPermitted(authUser, item.claims, item.flag, item.posFlag)}
              unreadNotificationsNumber={unreadNotificationsNumber}
              sx={item.sx}
            >
              {t(item.textKey)}
            </SidebarMenuLink>
          );
        })}
      </List>

      <List sx={{ paddingTop: 3, }}>
        <SidebarMenuButton icon={<LogoutColoredIcon />} onClick={authLogout.logout} disabled={authLogout.loading}>
          {t('common:sidebar.btnLogout')}
        </SidebarMenuButton>
      </List>
    </>
  );
};

export default SidebarContent;
