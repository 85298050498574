import { useMemo, } from 'react';
import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { AssortmentCategoryOptionFragment, AssortmentFragment, AssortmentInput, FileFragment, } from 'Apollo/graphql';

export type AssortmentFormValues = {
  name: string,
  description: string,
  category: AssortmentCategoryOptionFragment | null,
  image: FileFragment | null,
  notificationsVisible: boolean,
};

export const initAssortmentFormValues: AssortmentFormValues = {
  name: '',
  description: '',
  category: null,
  image: null,
  notificationsVisible: false,
};

export const useInitAssortmentValues = (assortment: AssortmentFragment): AssortmentFormValues => useMemo<AssortmentFormValues>(
  () => ({
    category: assortment.assortmentCategory,
    description: assortment.description,
    name: assortment.name,
    image: assortment.image || null,
    notificationsVisible: assortment.notificationsVisible,
  }),
  [assortment,],
);

export const assortmentFormValidationSchema = yup.object().shape({
  name: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  description: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(2047, INPUT_FORM_ERRORS.STRING_MAX),
  category: yup.object()
    .nullable()
    .required(INPUT_FORM_ERRORS.REQUIRED),
  image: yup.object()
    .nullable()
    .required(INPUT_FORM_ERRORS.REQUIRED),
});

export const mapFormToVariables = (values: AssortmentFormValues): AssortmentInput => ({
  name: values.name,
  description: values.description,
  categoryId: values.category?.id || '',
  imageFileId: values.image?.id || null,
  notificationsVisible: values.notificationsVisible,
});
