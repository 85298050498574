/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloCache, DefaultContext, MutationUpdaterFunction, Reference, } from '@apollo/client';
import { CreateNewsArticleMutation, DeleteNewsArticleMutation, Exact, NewsArticleFragmentDoc, NewsArticleInput, } from 'Apollo/graphql';

type UpdateNewsArticleCreate = MutationUpdaterFunction<CreateNewsArticleMutation, Exact<{
  newsArticleInput: NewsArticleInput;
}>, DefaultContext, ApolloCache<any>> | undefined;

export const updateNewsArticleCreate: UpdateNewsArticleCreate = (cache, { data, }) => {
  try {
    if (data?.createNewsArticle) {
      const newRef = cache.writeFragment({
        id: cache.identify(data.createNewsArticle),
        data: data.createNewsArticle,
        fragment: NewsArticleFragmentDoc,
        fragmentName: data.createNewsArticle.__typename,
      });
  
      cache.modify({
        fields: {
          newsArticles: (existing) => ({
            ...existing,
            data: [ ...existing.data, newRef, ],
          }),
        },
      });
    }
  } catch {
    // ignore error
  }
};

type UpdateNewsArticleRemove = MutationUpdaterFunction<DeleteNewsArticleMutation, Exact<{
  id: string;
}>, DefaultContext, ApolloCache<any>> | undefined;

export const updateNewsArticleRemove: UpdateNewsArticleRemove = (cache, { data, }) => {
  try {
    if (data?.deleteNewsArticle) {
      cache.modify({
        fields: {
          newsArticles: (existing, { readField, }) => ({
            ...existing,
            data: existing.data.filter((itemRef: Reference) => readField('id', itemRef) !== data.deleteNewsArticle.id),
          }),
        },
      });
    }
  } catch {
    // ignore error
  }
};