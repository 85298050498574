import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useGetAvailableVoucherPurchaseOptionsQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import LoadingBox from 'Components/LoadingBox';
import { useTranslation, } from 'react-i18next';
import View from './View';

const Vouchers = (): JSX.Element => {
  const { t, } = useTranslation('myPoints');
  const { error, loading, data, } = useGetAvailableVoucherPurchaseOptionsQuery({ fetchPolicy: 'cache-and-network',
});
  if (loading) return <LoadingBox />;
  if (error || !data?.getAvailableVoucherPurchaseOptions) return <ErrorAlert forceError error={error} />;
  if (data.getAvailableVoucherPurchaseOptions.noVouchersAvailable) return (
    <Box
      pt={3}
      pb={3}
      pl={2}
      pr={2}
    >
      <Typography color="textSecondary">{t('voucher.noVouchers')}</Typography>
    </Box>
  );
  return <View data={data} />;
};
export default Vouchers;
