import { Link, } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import LogoIcon from 'Utils/svg/LogoIcon';
import ButtonMenu from './ButtonMenu';

interface Props {
  onOpenMobileMenu?: () => void,
  to?: string,
};

const TopPanel = ({
  onOpenMobileMenu,
  to,
}: Props): JSX.Element => {
  return (
    <AppBar color="default">
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            {to ? (
              <Link
                to={to}
              >
                <LogoIcon sx={{ fontSize: '2rem', }} />
              </Link>
            ) : (
              <LogoIcon sx={{ fontSize: '2rem', }} />
            )}
          </Grid>
          <Grid item>
            {onOpenMobileMenu && (
              <ButtonMenu onClick={onOpenMobileMenu} />
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};


export default TopPanel;
