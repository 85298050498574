import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export enum AcceptedRejected {
  accepted='accepted',
  rejected='rejected',
}

export type IsAcceptedOption = {
  id: string,
  labelKey: string,
  value: boolean,
};

export const IS_ACCEPTED_OPTION: IsAcceptedOption[] = [
  {
    id: AcceptedRejected.accepted,
    labelKey: 'common:conditions.accepted',
    value:true,
  },
  {
    id: AcceptedRejected.rejected,
    labelKey: 'common:conditions.rejected',
    value:false,
  },
];

type GetOptionLabel = (o: IsAcceptedOption) => string;
type GetOptionKey = (o: IsAcceptedOption) => string;

type UseOptionsIsAccepted = () => {
  options: IsAcceptedOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<IsAcceptedOption>, 
};

export const useOptionsIsAccepted: UseOptionsIsAccepted = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: IS_ACCEPTED_OPTION,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
