/* eslint-disable react/no-array-index-key */
import { Typography, } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useAssortmentCategoriesQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import CardItem from './CardItem';

const AssortmentList = (): JSX.Element => {
  const { data, loading, error, } = useAssortmentCategoriesQuery();

  if (loading) {
    return (
      <Box display="flex" flexDirection="column">
        <Typography
          variant="h2"
          marginBottom={2}
        >
          <Skeleton width="60%" variant="text" />
        </Typography>
        <Box display="flex" flexDirection="column">
          {[...Array(5),].map((_, index) => (
            <CardItem
              key={index}
              title={<Skeleton width="80px" variant="text" />}
              disabled
            >
              <Skeleton />
            </CardItem>
          ))}
        </Box>
      </Box>
    );
  }

  if (error || !data?.assortmentCategories) return (
    <ErrorAlert
      forceError
      error={error}
    />
  );

  const categoriesWithProducts = data.assortmentCategories.filter(category => category.items.length);

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      {categoriesWithProducts.map((category) => (
        <div key={category.id}>
          <Typography
            variant="h2"
            color="primary"
            marginBottom={2}
          >
            {category.name}
          </Typography>
          <Box display="flex" flexDirection="column">
            {category.items.map((item) => (
              <CardItem
                key={item.id}
                title={item.name}
                imageSrc={item.image?.publicUrl}
              >
                {item.description}
              </CardItem>
            ))}
          </Box>
        </div>
      ))}
    </Box>
  );
};

export default AssortmentList;
