import { useForm, FormProvider, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useForgottenPasswordMutation, } from 'Apollo/graphql';
import { initForgottenPasswordFormValues, forgottenPasswordFormValidationSchema, mapFormToVariables, } from './utils';
import FormView from './FormView';
import { ForgottenPasswordEmail, } from './types';

interface Props {
  setForgottenPasswordEmail: (forgottenPasswordEmail: ForgottenPasswordEmail) => void;
}

const FormLogic = ({
  setForgottenPasswordEmail,
}: Props): JSX.Element => {
  const methods = useForm({
    defaultValues: initForgottenPasswordFormValues,
    resolver: yupResolver(forgottenPasswordFormValidationSchema),
  });

  const [ forgotMutation, forgotMutationResult, ] = useForgottenPasswordMutation({
    onCompleted: () => {
      setForgottenPasswordEmail({
        isSent: true,
        email: methods.getValues('email'),
      });
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await forgotMutation({
      variables: mapFormToVariables(values),
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <FormView
          forgotMutationResult={forgotMutationResult}
        />
      </form>
    </FormProvider>
  );
};

export default FormLogic;
