import { yupResolver, } from '@hookform/resolvers/yup';
import { AuthUserFragment, CustomerVerificationMethod, useCheckVerificationCodeMutation, useStartCustomerVerificationMutation, } from 'Apollo/graphql';
import { CustomErrorMessage, } from 'Components/Errors/types';
import { useSnackbar, } from 'notistack';
import { useState, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import { CreateReplacementStore, } from '../../../createReplacementStore';
import View from './View';
import { initPhaseCustomerVerificationFormValues, phaseCustomerVerificationValidationSchema, } from './utils';
import { mapRegisterVariables, } from '../../../PhaseCustomerRegistration/utils';

interface Props {
  customer: CreateReplacementStore['customer'];
  verification: CreateReplacementStore['customerVerification'];
  goBackToPhase: CreateReplacementStore['goBackToPhase'];
  completeCustomerVerificationPhase: (verification: CreateReplacementStore['customerVerification']) => void;
  method: CustomerVerificationMethod;
  onClose: () => void;
  customerRegistrationValues: CreateReplacementStore['customerRegistrationValues'];
}

const EmailAndSmsVerification = ({
  customer,
  verification,
  goBackToPhase,
  completeCustomerVerificationPhase,
  method,
  onClose,
  customerRegistrationValues,
}: Props): JSX.Element => {
  const authUser = useAuthUser();
  const { t, } = useTranslation('ReplacementAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const [errorInvalidVerificationCode, setErrorInvalidVerificationCode,] = useState<CustomErrorMessage | null>(null);

  const methods = useForm({
    defaultValues: initPhaseCustomerVerificationFormValues,
    resolver: yupResolver(phaseCustomerVerificationValidationSchema),
    mode: 'onSubmit',
  });

  const [mutationCheckVerificationCode, checkVerificationCodeResult,] = useCheckVerificationCodeMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (response.checkVerificationCode.isValid) {
        completeCustomerVerificationPhase({
          ...verification,
          shouldVerify: false,
          isVerified: true,
          method,
        });
      } else {
        setErrorInvalidVerificationCode({
          title: t('phaseCustomerVerification.errorInvalidVerificationCode.title'),
          text: t('phaseCustomerVerification.errorInvalidVerificationCode.text'),
        });
      }
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    setErrorInvalidVerificationCode(null);
    await mutationCheckVerificationCode({
      variables: { verificationCode: values.verificationCode, },
    });
  });

  const [sendVerificationCode, sendVerificationCodeResult,] = useStartCustomerVerificationMutation({
    fetchPolicy: 'no-cache',
  });


  const handleResend = () => {
    sendVerificationCode({
      variables: {
        method,
        verificatedValue: verification.value || '',
        customerInput: mapRegisterVariables(customerRegistrationValues.email, customerRegistrationValues, authUser as AuthUserFragment).customerInput,
      },
      onCompleted: () =>
        enqueueSnackbar({
          message: t('phaseCustomerVerification.codeSent'),
          variant: 'success',
        }),
      onError: () =>
        enqueueSnackbar({
          message: t('phaseCustomerVerification.codeSendFailed'),
          variant: 'success',
        }),
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <View
          errorInvalidVerificationCode={errorInvalidVerificationCode}
          verification={verification}
          customer={customer}
          checkVerificationCodeResult={checkVerificationCodeResult}
          sendVerificationCodeResult={sendVerificationCodeResult}
          goBackToPhase={goBackToPhase}
          onResend={handleResend}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default EmailAndSmsVerification;
