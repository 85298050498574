import { RenderLeafProps, } from 'slate-react';

const Leaf = ({
  attributes,
  children,
  leaf,
}: RenderLeafProps): JSX.Element => {
  let tmpChildren = children;

  if (leaf.bold) tmpChildren = <strong>{tmpChildren}</strong>;
  if (leaf.code) tmpChildren = <code>{tmpChildren}</code>;
  if (leaf.italic) tmpChildren = <em>{tmpChildren}</em>;
  if (leaf.underline) tmpChildren = <u>{tmpChildren}</u>;

  return <span {...attributes}>{tmpChildren}</span>;
};

export default Leaf;
