import { useEffect, } from 'react';
import { useParams, Link as RouterLink, } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { ROUTES, } from 'Utils/constants';
import { FillTaskStore, useFillTaskStore, } from 'Modules/TasksSeller/store/fillTaskStore';
import AppContainer from 'Components/AppContainer';
import Phases from './Phases';

const selectStoreData = (s: FillTaskStore) => ({
  taskResult: s.taskResult,
  fetchTask: s.fetchTask,
  resetStore: s.resetStore,
});

const FillTaskPage = (): JSX.Element => {
  const { taskId, } = useParams();
  const { t, } = useTranslation('tasksSeller');
  const {
    taskResult: {
      loading,
      error,
      data,
    },
    fetchTask,
    resetStore, 
  } = useFillTaskStore(selectStoreData, shallow);

  useEffect(
    () => {
      fetchTask(taskId);
      return () => resetStore();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (loading) return (
    <AppContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h1"
        mb={1.5}
      >
        <Skeleton variant="text" width="40%" />
      </Typography>
      <Typography
        variant="body1"
        mb={4}
      >
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="50%" />
      </Typography>

      <Paper
        sx={{
          pt: 8,
          pr: 2,
          pb: 8,
          pl: 2,
          mb: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={30} />
      </Paper>
      <Button
        component={RouterLink}
        to={`/${ROUTES.tasks}`}
        size="large"
        sx={{ width: { xs: '100%', lg: 'unset', }, }}
        variant="outlined"
        color="primary"
      >
        {t('common:actions.back')}
      </Button>
    </AppContainer>
  );
  if (error) return (
    <AppContainer maxWidth="sm">
      <ErrorAlert
        mb={4}
        forceError
        error={error}
      />
      <Button
        component={RouterLink}
        to={`/${ROUTES.tasks}`}
        size="large"
        sx={{ width: { xs: '100%', lg: 'unset', }, }}
        variant="outlined"
        color="primary"
      >
        {t('common:actions.back')}
      </Button>
    </AppContainer>
  );
  if (!data?.taskCompletionInfo?.canComplete) return (
    <AppContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h1"
        mb={1.5}
      >
        {data?.name || ''}
      </Typography>
      <Typography
        variant="body1"
        mb={4}
      >
        {t('fill.init.infoCantFill')}
      </Typography>
       <Paper
        sx={{
          pt: 8,
          pr: 2,
          pb: 8,
          pl: 2,
          mb: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography
          color="textSecondary"
        >
          {t('fill.init.textCantFill')}
        </Typography>
      </Paper>
      <Button
        component={RouterLink}
        to={`/${ROUTES.tasks}`}
        size="large"
        sx={{ width: { xs: '100%', lg: 'unset', }, }}
        variant="outlined"
        color="primary"
      >
        {t('common:actions.back')}
      </Button>
    </AppContainer>
  );
  return <Phases />;
};

export default FillTaskPage;
