import { yupResolver, } from '@hookform/resolvers/yup/dist/yup';
import {
  PointOfSellOptionFragment,
  useGetQrCodeForBrandAmbassadorLazyQuery,
} from 'Apollo/graphql';
import { FormInputAutocomplete, } from 'Components/Form';
import { useAuthUser, } from 'Tools/auth';
import { renderOptionById, } from 'Utils/helpers';
import { useSearchOptionsPos, } from 'Utils/options/useSearchOptionsPos';
import { useEffect, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { Grid, } from '@mui/material';
import { validationSchema, } from '../utils';
import RenderQr from '../RenderQr';

const InputPos = (): JSX.Element => {
  const { t, } = useTranslation('registrationSeller');
  const authUser = useAuthUser();
  const showPosSelector =
    authUser?.featureFlagsObject
      .FEATURE_FLAG_ENABLE_BRAND_AMBASSADOR_WITH_POS_QR_REGISTRATION || false;
  const [getQrQuery, qrData,] = useGetQrCodeForBrandAmbassadorLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const posOptions = useSearchOptionsPos();
  const methods = useForm({
    defaultValues: {
      pos: null as PointOfSellOptionFragment | null,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  });
  useEffect(() => {
    if (!showPosSelector) {
      getQrQuery();
    }
    const subscription = methods.watch((value, { name, type, }) =>
      name === 'pos'
        ? getQrQuery({ variables: { posId: value.pos?.id, }, })
        : null,
    );
    return () => subscription.unsubscribe();
  }, [methods.watch,]);
  const posId = methods.watch('pos.id');
  const ignorePosId = showPosSelector ? posId : true;

  return (
    <>
      <Grid item xs={12}>
        <FormProvider {...methods}>
          <form noValidate autoComplete="off">
            <FormInputAutocomplete<PointOfSellOptionFragment>
              name="pos"
              label={t('phaseInformationVerification.labelPos')}
              options={posOptions.options}
              getOptionLabel={posOptions.getOptionLabel}
              isOptionEqualToValue={posOptions.isOptionEqualToValue}
              renderOption={renderOptionById(posOptions.getOptionLabel)}
              filterOptions={posOptions.filterOptions}
              loading={posOptions.loading}
              onInputChange={posOptions.onInputChange}
              noOptionsText={posOptions.noOptionsText}
              required
            />
          </form>
        </FormProvider>
      </Grid>
      {qrData?.data && ignorePosId && (
        <Grid item xs={12}>
          <RenderQr value={qrData.data?.getQrCodeForBrandAmbassador.url} />
        </Grid>
      )}
    </>
  );
};

export default InputPos;
