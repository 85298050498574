import { PointOfSellFragment, usePointOfSellQuery, } from 'Apollo/graphql';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import View from './View';

interface Props {
  pointOfSell?: PointOfSellFragment | null,
  onClose: () => void,
};

const FetchData = ({
  pointOfSell,
  onClose,
}: Props): JSX.Element => {
  const preservedPointOfSell = usePreserveValue(pointOfSell);

  const { loading, error, data, } = usePointOfSellQuery({
    variables: { id: preservedPointOfSell.id, },
    fetchPolicy: 'network-only',
  });

  if (loading) return (
    <ModalDrawerBodyLoading
      onClose={onClose}
    />
  );
  if (error || !data?.pointOfSell) return (
    <ModalDrawerBodyError
      onClose={onClose}
      error={error}
    />
  );
  return (
    <View
      pointOfSell={data.pointOfSell}
      onClose={onClose}
    />
  );
};

export default FetchData;
