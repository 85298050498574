import { yupResolver, } from '@hookform/resolvers/yup';
import { AssortmentCategoryWithAssortmentOptionFragment, } from 'Apollo/graphql';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementSeller/store/ReplacementStore';
import { FormProvider, useForm, } from 'react-hook-form';
import { useProductsForm, } from './utils';
import View from './View';

const selectCompleteReplacementFormPhase = (s: ReplacementStore) => s.completeReplacementFormPhase;
const selectExchangedProducts = (s:ReplacementStore) => s.replacement.exchangedProducts;
const selectReplacement = (s:ReplacementStore) => s.replacement;

interface Props {
  category: AssortmentCategoryWithAssortmentOptionFragment,
};

const Logic = ({
  category,
}: Props): JSX.Element => {
  const completeReplacementFormPhase = useReplacementStore(selectCompleteReplacementFormPhase);
  const exchangedProducts = useReplacementStore(selectExchangedProducts);
  const replacement = useReplacementStore(selectReplacement);

  const {
    initValues,
    validationSchema,
    structure,
  } = useProductsForm(category, exchangedProducts,replacement, true);
  const methods = useForm({
    defaultValues: initValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    mode: 'onChange',
  });

  const handleSubmit = methods.handleSubmit((values) => {
    completeReplacementFormPhase({
      ...values,
    },replacement.batchCode
    );
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <View
          structure={structure}
        />
      </form>
    </FormProvider>
  );
};

export default Logic;
