import AppContainer from 'Components/AppContainer';
import { NotificationType, } from 'Apollo/graphql';
import { useDelayedResetNotificationCategoryCount, } from 'Tools/useDelayedResetNotificationCategoryCount';
import View from './View';

const MyPointsSeller = (): JSX.Element => {

  useDelayedResetNotificationCategoryCount(NotificationType.Voucher);

  return (
    <AppContainer maxWidth="sm">
      <View />
    </AppContainer>
  );
};

export default MyPointsSeller;
