import { styled, } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';

const InputLabelClassic = styled(InputLabel)(({ theme, }) => ({
  position: 'relative',
  marginBottom: theme.spacing(1.5),
  top: 'unset',
  left: 'unset',
  maxWidth: 'unset',
  transform: 'none',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1.125rem',
  lineHeight: '1.35em',
  letterSpacing: 0.25,
  whiteSpace: 'unset',

  '&.Mui-error': {
    color: 'unset',
  },

  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.primary.main,
  },

  '&.MuiFormLabel-colorSecondary': {
    color: theme.palette.secondary.main,
  },
  
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem',
  },

  [theme.breakpoints.up('lg')]: {
    marginBottom: theme.spacing(1),
    fontSize: '1rem',
  },
}));

export default InputLabelClassic;
