import { useTranslation, } from 'react-i18next';
import Autocomplete, { AutocompleteProps, } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export interface InputAutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>
  extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'name' | 'onChange' | 'renderInput'
  > {
  onChange: (value: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['value']) => void,
}

const InputAutocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  value,
  placeholder,
  onChange,
  ...rest
}: InputAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  const { t, } = useTranslation('common');
  const handleChange = (e: React.ChangeEvent<unknown>, newValue: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['value']) => onChange(newValue);

  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      openText={t('inputAutocomplete.open')}
      closeText={t('inputAutocomplete.close')}
      clearText={t('inputAutocomplete.clear')}
      loadingText={t('inputAutocomplete.loading')}
      noOptionsText={t('inputAutocomplete.noOptions')}  
      {...rest}
      value={value}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          fullWidth
        />
      )}
    />
  );
};

export default InputAutocomplete;
