import { Button, Paper, Stack, styled, } from '@mui/material';
import {
  PointOfSellOptionFragment,
  ChainFragment,
  useChainsQuery,
  usePointsOfSellListQuery,
  useGetAllUserRolesQuery,
  UserRoleOptionFragment,
  useAssortmentsQuery,
  AssortmentCategoryOptionFragment,
  AssortmentFragment,
  ChainsQueryVariables,
  useDashboardRolesQuery,
  DashboardRolesQueryResult,
} from 'Apollo/graphql';
import { FormInputAutocomplete, } from 'Components/Form';
import {
  renderOptionById,
  getOptionFieldName,
  areOptionsEqualById,
  getOptionFieldIdAndName,
} from 'Utils/helpers';
import { useTranslation, } from 'react-i18next';
import { useFormContext, } from 'react-hook-form';
import { useState, } from 'react';
import ClassicStaticDatePicker from 'Components/Inputs/ClassicStaticDatePicker';
import useOutsideClick from 'Utils/useClickOutside';
import PlusIcon from 'Utils/svg/PlusIcon';
import InputLabelClassic from 'Components/Inputs/components/InputLabelClassic';
import CalendarIcon from 'Utils/svg/CalendarIcon';
import moment from 'moment';
import { OptionTypes, } from './types';

const StyledPaper = styled(Paper)(({ theme, }) => ({
  zIndex: 1,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  right: 0,
  position: 'fixed',
  top: 'calc(50% + 56px)',
  height: '100%',
  overflowY: 'scroll',
  transform: 'translateY(-50%)',
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    top: '110%',
    height: 'auto',
    overflowY: 'auto',
    transform: 'unset',
  },
}));

const StyledCalendarsWrapper = styled(Stack)(({ theme, }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const StyledButton = styled('button')(({ theme, }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  padding: 0,
  transition: 'opacity 0.2s',
  '&:hover': {
    opacity: 0.4,
  },
}));

const StyledDatePopupButton = styled('button')(({ theme, }) => ({
  background: 'white',
  border: '1px solid #D9D9D9',
  padding: '12px 15px',
  fontSize: '1rem',
  font: 'inherit',
  lineHeight: '1.375em',
  borderRadius: '4px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const Filtration = ({
  options,
  chainsVariables,
}: {
  options: OptionTypes;
  chainsVariables?: ChainsQueryVariables;
}) => {
  const { getValues, setValue, } = useFormContext<{
    posIds: PointOfSellOptionFragment[];
    chainIds: ChainFragment[];
    userRoleId: UserRoleOptionFragment | null;
    categoryId: AssortmentCategoryOptionFragment | null;
    productId: AssortmentFragment | null;
    date: string | string[];
  }>();
  const [showDatePopup, setShowDatePopup,] = useState(false);
  const ref = useOutsideClick(
    () => setShowDatePopup(false),
    '.date-popup-button',
  );
  const posResult = usePointsOfSellListQuery();
  const chainsResult = useChainsQuery({ variables: chainsVariables, });
  const userRoleResult = useDashboardRolesQuery();
  const productsResult = useAssortmentsQuery();
  const productCategories = Array.from(
    new Set(
      productsResult.data?.assortments.data.map(
        (assortment) => assortment.assortmentCategory,
      ),
    ),
  );
  const { t, } = useTranslation('dashboards');
  const dateTranslationsMap = {
    Today: t('dashboards:dashboards:filtration.today'),
    Yesterday: t('dashboards:dashboards:filtration.yesterday'),
    'This week': t('dashboards:dashboards:filtration.thisWeek'),
    'This month': t('dashboards:dashboards:filtration.thisMonth'),
    'This year': t('dashboards:dashboards:filtration.thisYear'),
    'Last 7 days': t('dashboards:dashboards:filtration.last7Days'),
    'Last week': t('dashboards:dashboards:filtration.lastWeek'),
    'Last month': t('dashboards:dashboards:filtration.lastMonth'),
    'Last year': t('dashboards:dashboards:filtration.lastYear'),
    'All time': t('dashboards:dashboards:filtration.allTime'),
  };
  const date = getValues('date');
  if (options === OptionTypes.source) {
    return (
      <>
        <Stack width={200}>
          <FormInputAutocomplete<ChainFragment, true>
            name="chainIds"
            getOptionLabel={getOptionFieldName}
            isOptionEqualToValue={areOptionsEqualById}
            options={
              (getValues('posIds')?.length > 0
                ? chainsResult.data?.chains.filter((chain) =>
                    getValues('posIds')?.some(
                      (pos) => pos.chain.id === chain.id,
                    ),
                  )
                : chainsResult.data?.chains) || []
            }
            renderOption={renderOptionById(getOptionFieldIdAndName)}
            loading={chainsResult.loading}
            label={t('dashboards.filtration.chain')}
            placeholder={t('common:select.placeholder')}
            multiple
          />
        </Stack>
        <Stack width={200}>
          <FormInputAutocomplete<PointOfSellOptionFragment, true>
            name="posIds"
            getOptionLabel={getOptionFieldName}
            isOptionEqualToValue={areOptionsEqualById}
            renderOption={renderOptionById(getOptionFieldIdAndName)}
            options={
              (getValues('chainIds')?.length > 0
                ? posResult.data?.pointsOfSellList.data?.filter((pos) =>
                    getValues('chainIds')?.some(
                      (chain) => chain.id === pos.chain.id,
                    ),
                  )
                : posResult.data?.pointsOfSellList.data) || []
            }
            loading={chainsResult.loading}
            label={t('dashboards.filtration.pos')}
            placeholder={t('common:select.placeholder')}
            multiple
          />
        </Stack>
      </>
    );
  }
  if (options === OptionTypes.role) {
    return (
      <Stack width={200}>
        <FormInputAutocomplete<
          {
            id: string;
            name: string;
          }
        >
          name="userRoleId"
          getOptionLabel={getOptionFieldName}
          isOptionEqualToValue={areOptionsEqualById}
          renderOption={renderOptionById(getOptionFieldName)}
          options={userRoleResult.data?.dashboardRoles ?? []}
          loading={userRoleResult.loading}
          label={t('dashboards.filtration.role')}
          placeholder={t('common:select.placeholder')}
        />
      </Stack>
    );
  }
  if (options === OptionTypes.category) {
    return (
      <Stack width={200}>
        <FormInputAutocomplete<AssortmentCategoryOptionFragment>
          name="categoryId"
          getOptionLabel={getOptionFieldName}
          isOptionEqualToValue={areOptionsEqualById}
          renderOption={renderOptionById(getOptionFieldName)}
          options={productCategories}
          loading={userRoleResult.loading}
          label={t('dashboards.filtration.category')}
          placeholder={t('common:select.placeholder')}
        />
      </Stack>
    );
  }
  if (options === OptionTypes.products) {
    return (
      <Stack width={200}>
        <FormInputAutocomplete<AssortmentFragment>
          name="productId"
          getOptionLabel={getOptionFieldName}
          isOptionEqualToValue={areOptionsEqualById}
          renderOption={renderOptionById(getOptionFieldName)}
          options={
            (getValues('categoryId')
              ? productsResult.data?.assortments.data.filter(
                  (product) =>
                    product.assortmentCategory.id ===
                    getValues('categoryId.id'),
                )
              : productsResult.data?.assortments.data) || []
          }
          loading={userRoleResult.loading}
          label={t('dashboards.filtration.product')}
          placeholder={t('common:select.placeholder')}
        />
      </Stack>
    );
  }

  if (options === OptionTypes.time) {
    return (
      <Stack
        sx={{
          position: [null, null, 'relative',],
        }}
        justifyContent="flex-start"
      >
        <Stack>
          <InputLabelClassic color="primary" shrink>
            {t('dashboards.filtration.dateFrom')} /{' '}
            {t('dashboards.filtration.dateTo')}
          </InputLabelClassic>
          <StyledDatePopupButton
            type="button"
            className="date-popup-button"
            onClick={() => setShowDatePopup(!showDatePopup)}
          >
            <CalendarIcon />
            {Array.isArray(date)
              ? date
                  .map((dateString) => {
                    if (moment(dateString).isValid()) {
                      return moment(dateString).format('DD.MM.YYYY');
                    }
                    return '---';
                  })
                  .join(' - ')
              : dateTranslationsMap[date as keyof typeof dateTranslationsMap]}
          </StyledDatePopupButton>
        </Stack>

        {showDatePopup && (
          <StyledPaper ref={ref}>
            <Stack alignItems="flex-end">
              <StyledButton
                type="button"
                onClick={() => {
                  setShowDatePopup(false);
                }}
              >
                <PlusIcon style={{ transform: 'rotate(45deg)', }} />
              </StyledButton>
            </Stack>
            <StyledCalendarsWrapper>
              <Stack>
                <ClassicStaticDatePicker
                  minDate={new Date('2021-01-01')}
                  value={Array.isArray(date) ? date[0] : null}
                  onChange={(value) => {
                    if (!value) return;
                    setValue('date', [value.toString(), date[1],]);
                  }}
                />
              </Stack>
              <Stack>
                <ClassicStaticDatePicker
                  minDate={new Date('2021-01-01')}
                  value={Array.isArray(date) ? date[1] : null}
                  onChange={(value) => {
                    if (!value) return;
                    setValue('date', [date[0], value.toString(),]);
                  }}
                />
              </Stack>
            </StyledCalendarsWrapper>
            <Stack display="flex" direction="row" flexWrap="wrap" gap={2}>
              {Object.entries(dateTranslationsMap).map(([key, value,]) => (
                <Button
                  variant="contained"
                  color={date === key ? 'primary' : 'secondary'}
                  onClick={() => {
                    setValue('date', key);
                    setShowDatePopup(false);
                  }}
                >
                  {value}
                </Button>
              ))}
            </Stack>
          </StyledPaper>
        )}
      </Stack>
    );
  }

  if (options === OptionTypes.chain) {
    return (
      <Stack width={200}>
        <FormInputAutocomplete<ChainFragment, true>
          name="chainIds"
          getOptionLabel={getOptionFieldName}
          isOptionEqualToValue={areOptionsEqualById}
          renderOption={renderOptionById(getOptionFieldName)}
          options={chainsResult.data?.chains || []}
          loading={chainsResult.loading}
          label={t('dashboards.filtration.chain')}
          placeholder={t('common:select.placeholder')}
          multiple
        />
      </Stack>
    );
  }
  return null;
};

export default Filtration;
