import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const KeyboardArrowDownIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 27 27"
    fill="none"
    {...props}
  >
    <path
      d="M21.54 9.744c.03.358-.13.624-.358.881a2855.347 2855.347 0 0 0-6.397 7.23c-.748.85-1.774.867-2.53.019a5312.178 5312.178 0 0 0-6.415-7.18c-.422-.473-.504-1.082-.208-1.572a1.247 1.247 0 0 1 1.417-.537c.197.066.373.182.511.337 1.883 2.128 3.761 4.261 5.634 6.398.266.303.313.303.578 0l5.614-6.382a1.216 1.216 0 0 1 2.08.367c.04.143.065.29.074.439z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default KeyboardArrowDownIcon;
