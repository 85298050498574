import { styled, } from '@mui/material/styles';
import FormHelperText, { FormHelperTextProps, } from '@mui/material/FormHelperText';
import ErrorFilledIcon from 'Utils/svg/ErrorFilledIcon';

const StyledFormHelperTextClassic = styled(FormHelperText)(({ theme, }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: '0.875rem',
  lineHeight: '1.375rem',
  marginLeft: 0,
  marginRight: 0,

  '& .MuiSvgIcon-root': {
    width: '1rem',
    height: '1rem',
    marginRight: theme.spacing(0.75),
  },
}));

const FormHelperTextClassic = ({
  children,
  error,
}: FormHelperTextProps) => (
  <StyledFormHelperTextClassic
    error={error}
  >
    {error && (<ErrorFilledIcon />)}<span>{children}</span>
  </StyledFormHelperTextClassic>
);

export default FormHelperTextClassic;

