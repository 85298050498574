import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const NewsColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#news-colored)">
      <path
        d="M1.003 11.408V3.917c0-1.314.719-2.006 2.099-2.007h13.225c1.355 0 2.08.693 2.086 1.985 0 1.117.013 2.234 0 3.35 0 .312.1.394.413.38.55-.023 1.1-.019 1.649.013.683.04 1.325.328 1.793.805.468.478.728 1.108.726 1.763.012 2.944.012 5.89 0 8.835a2.986 2.986 0 0 1-.901 2.107 3.262 3.262 0 0 1-2.176.925c-.205.01-.412.01-.617.01H4.39c-1.429 0-2.502-.59-3.092-1.84a3.273 3.273 0 0 1-.275-1.337c-.034-2.504-.02-5.001-.02-7.498Zm15.575.357V4.31c0-.504-.114-.629-.604-.63H3.434c-.46 0-.598.132-.598.57V18.9c0 .88.56 1.408 1.482 1.408h11.82c.438 0 .589-.188.537-.602a9.117 9.117 0 0 1-.09-1.11c-.01-2.27-.005-4.548-.007-6.825v-.006Zm4.584 2.875v-4.268c0-.657-.358-1.001-1.026-1.006h-.963c-.65 0-.758.102-.758.723v8.697c-.002.164.011.328.038.49.056.27.202.515.415.7.214.184.484.297.77.323.888.067 1.512-.493 1.52-1.393.012-1.417.004-2.84.004-4.261v-.005Z"
        fill="#000"
      />
      <path
        d="M5.003 9.347V7.345c0-.738.44-1.174 1.203-1.182.95-.01 1.9-.012 2.849 0 .74.009 1.2.453 1.204 1.156.006 1.335.006 2.67 0 4.006 0 .743-.47 1.183-1.245 1.183-.916.008-1.831 0-2.75 0-.805 0-1.256-.435-1.262-1.199-.005-.648 0-1.305 0-1.962Z"
        fill="#000"
      />
      <path
        d="M9.919 15.548H5.556c-.66 0-.883-.215-.888-.851 0-.657.224-.903.872-.903h8.797c.324 0 .652.045.784.36.15.361.194.778-.056 1.1a.997.997 0 0 1-.678.284c-1.49.022-2.98.01-4.468.01ZM8.774 19.048H5.511c-.638 0-.843-.224-.843-.889 0-.63.213-.851.846-.851h6.559c.636 0 .845.226.842.888 0 .624-.22.852-.845.853-1.098.001-2.198-.001-3.296-.001ZM13.632 10.294c.296 0 .595-.01.89 0a.686.686 0 0 1 .47.173.63.63 0 0 1 .205.44c.017.176.017.352 0 .526a.62.62 0 0 1-.208.44.677.677 0 0 1-.47.17c-.607.012-1.213 0-1.82 0a.702.702 0 0 1-.232-.04c-.402-.14-.572-.572-.457-1.152a.667.667 0 0 1 .254-.414.725.725 0 0 1 .478-.147c.297-.006.594 0 .892 0l-.002.004ZM13.58 8.531c-.296 0-.595.01-.89 0-.425-.017-.67-.262-.687-.665a4.032 4.032 0 0 1 0-.425c.022-.375.257-.627.644-.637a37.069 37.069 0 0 1 1.925 0 .635.635 0 0 1 .437.167.58.58 0 0 1 .19.412c.016.163.019.327.008.49-.025.415-.262.643-.688.657-.321.012-.63.001-.938.001Z"
        fill="#D47E5F"
      />
    </g>
    <defs>
      <clipPath id="news-colored">
        <path
          fill="#fff"
          transform="translate(1 1.917)"
          d="M0 0h22v20.167H0z"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default NewsColoredIcon;
