/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, } from 'react';

export type FormBlockerContextValue = {
  addBlocker: () => void,
  removeBlocker: () => void,
  blockerCount: number,
  isBlocked: boolean,
};

export const FormBlockerContext = createContext<FormBlockerContextValue | null>(null);
