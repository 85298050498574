import { ForwardRefExoticComponent, ReactNode, } from 'react';
import { SxProps, Theme, styled, } from '@mui/material/styles';
import { Link, LinkProps, } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useIsActualRoute, } from 'Tools/useIsActualRoute';
import SidebarMenuNotificationDot from './SidebarMenuNotificationDot';

const StyledListItemButton = styled(ListItemButton)<{
  component: ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>;
  to: string;
}>(({ theme, }) => ({
  padding: '16px 24px',

  '& .MuiListItemIcon-root': {
    minWidth: 40,
  },

  '& .MuiListItemText-primary': {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '0.875rem',
    lineHeight: '1.5em',
    letterSpacing: '0.25px',
  },

  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: '#E6E6E6',
  },
}));

interface Props {
  children: ReactNode;
  icon: ReactNode;
  route: string;
  exact: boolean;
  onClick?: () => void;
  isAllowed?: boolean;
  unreadNotificationsNumber?: number | undefined;
  sx?: SxProps<Theme>;
}

const SidebarMenuLink = ({ icon, route, exact, children, onClick, isAllowed, unreadNotificationsNumber = 0, sx, }: Props): JSX.Element | null => {
  const isSelected = useIsActualRoute(route, exact);

  if (isAllowed === false) return null;

  return (
    <StyledListItemButton component={Link} to={route} selected={isSelected} onClick={onClick} sx={sx}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={children} />
      {unreadNotificationsNumber > 0 && <SidebarMenuNotificationDot number={unreadNotificationsNumber} />}
    </StyledListItemButton>
  );
};

export default SidebarMenuLink;
