import Grid from '@mui/material/Grid';
import { DetailVoucherPurchaseFragment, VoucherType, } from 'Apollo/graphql';
import { FormInputAutocomplete, } from 'Components/Form';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { useTranslation, } from 'react-i18next';
import { areOptionsEqualById, renderOptionById, } from 'Utils/helpers';
import { StatusOption, useVoucherPurchaseStatusOption, } from 'Utils/options/useOptionsVoucherPurchaseStatus';
import { valueOrEmpty, } from 'Utils/parsers';

interface Props {
  voucherPurchase?: DetailVoucherPurchaseFragment;
}

const VoucherPurchaseForm = ({ voucherPurchase, }: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const voucherPurchaseStatusOption = useVoucherPurchaseStatusOption();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('purchases.form.username')}>{valueOrEmpty(voucherPurchase?.user.username)}</NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('purchases.form.posId')}>{valueOrEmpty(voucherPurchase?.user.posId)}</NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('purchases.form.createdAt')}>
          {valueOrEmpty(t('common:date.fullDate', { date: new Date(voucherPurchase?.createdAt), }))}
        </NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('purchases.form.voucherId')}>{valueOrEmpty(voucherPurchase?.voucher.id)}</NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('purchases.form.type')}>{valueOrEmpty(voucherPurchase?.voucher.type)}</NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('purchases.form.name')}>{valueOrEmpty(voucherPurchase?.voucher.name)}</NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('purchases.form.validFrom')}>
          {valueOrEmpty(t('common:date.fullDate', { date: new Date(voucherPurchase?.voucher.validFrom), }))}
        </NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('purchases.form.validTo')}>
          {valueOrEmpty(t('common:date.fullDate', { date: new Date(voucherPurchase?.voucher.validTo), }))}
        </NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('purchases.form.pricePoints')}>{valueOrEmpty(voucherPurchase?.voucher.pricePoints)}</NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <FormInputAutocomplete<StatusOption, true>
          name="status"
          getOptionLabel={voucherPurchaseStatusOption.getOptionLabel}
          isOptionEqualToValue={areOptionsEqualById}
          options={voucherPurchaseStatusOption.options}
          label={t('purchases.form.status')}
          required
          renderOption={renderOptionById(voucherPurchaseStatusOption.getOptionLabel)}
          disabled={voucherPurchase?.voucher.type=== VoucherType.Alza}
        />
      </Grid>
    </Grid>
  );
};

export default VoucherPurchaseForm;
