import create from 'zustand';
import { AssessmentMethodEnum, ChainFragment, TaskTypeEnum, } from 'Apollo/graphql';
import { getOptionTaskMethodById, TaskMethodOption, } from 'Utils/options/useOptionTaskMethod';
import { TaskRenewalOption, } from 'Utils/options/useOptionTaskRenewal';
import { TaskTypeOption, } from 'Utils/options/useOptionTaskType';
import { TaskTypeOfCompletionOption, } from 'Utils/options/useOptionTaskTypeOfCompletion';
import { ContentValues, } from '../pages/CreateTaskPage/PhaseContent/types';
import { AssessmentValues, } from '../pages/CreateTaskPage/PhaseAssessment/types';

export type CreateTaskPhase = 'type' | 'method' | 'parameters' | 'content' | 'assessment';

export type ParametersFormConfig = {
  inputTypeOfCompletion: boolean,
  inputAttemptLimit: boolean,
  inputPointsLimit: boolean,
  inputPointsForCompletion: boolean,
  inputRenewal: boolean,
};

export type AssessmentConfig = {
  enableCorrectAnswer: boolean;
};

export type TypeValues = {
  type: TaskTypeOption | null;
};

export type MethodValues = {
  method: TaskMethodOption | null;
};

export type ParametersValues = {
  name: string;
  description: string;
  typeOfCompletion: TaskTypeOfCompletionOption | null;
  pointsForCompletion: number | null;
  pointsLimit: number | null;
  attemptLimit: number | null;
  validFrom: Date | null;
  validTo: Date | null;
  chains: ChainFragment[] | null;
  renewal: TaskRenewalOption | null;
  notificationsVisible: boolean;
};

type CreateTaskStoreState = {
  phase: CreateTaskPhase,
  skipPhaseMethod: boolean,
  skipPhaseContent: boolean,
  parametersFormConfig: ParametersFormConfig,
  assessmentConfig: AssessmentConfig,
  typeValues: TypeValues,
  methodValues: MethodValues,
  parametersValues: ParametersValues,
  contentValues: ContentValues,
  assessmentValues: AssessmentValues,
};

export type CreateTaskStore = CreateTaskStoreState & {
  resetStore: () => void;
  goBackToPhase: (phase: CreateTaskPhase) => void;
  completeTypePhase: (typeValues: TypeValues) => void;
  completeMethodPhase: (methodValues: MethodValues) => void;
  completeParametersPhase: (parametersValues: ParametersValues) => void;
  completeContentPhase: (contentValues: ContentValues) => void;
  completeAssessmentPhase: (assessmentValues: AssessmentValues) => void;
  saveParametersValues: (parametersValues: ParametersValues) => void;
  saveContentValues: (contentValues: ContentValues) => void;
  saveAssessmentValues: (assessmentValues: AssessmentValues) => void;
};

export const INIT_STATE: CreateTaskStoreState = {
  phase: 'type',
  skipPhaseMethod: false,
  skipPhaseContent: false,
  parametersFormConfig: {
    inputTypeOfCompletion: false,
    inputAttemptLimit: false,
    inputPointsLimit: false,
    inputPointsForCompletion: false,
    inputRenewal: false,
  },
  assessmentConfig: {
    enableCorrectAnswer: true,
  },
  typeValues: {
    type: null,
  },
  methodValues: {
    method: null,
  },
  parametersValues: {
    name: '',
    description: '',
    typeOfCompletion: null,
    pointsForCompletion: null,
    pointsLimit: null,
    attemptLimit: null,
    validFrom: null,
    validTo: null,
    chains: [],
    renewal: null,
    notificationsVisible: false,
  },
  contentValues: {
    nodes: [],
  },
  assessmentValues: {
    nodes: [],
  },
};

export const useCreateTaskStore = create<CreateTaskStore>((set) => ({
  ...INIT_STATE,
  resetStore: () => set({
    ...INIT_STATE,
  }),
  goBackToPhase: (phase) => set({
    phase,
  }),
  
  completeTypePhase: (typeValues) => set(() => {
    const typeId = typeValues.type?.id || null;
    const additional = typeId === TaskTypeEnum.Additional;
    const elearning = typeId === TaskTypeEnum.Elearning;
    const obligatory = typeId === TaskTypeEnum.Obligatory;

    return {
      phase: additional ? 'method' : 'parameters',
      skipPhaseMethod: obligatory || elearning,
      skipPhaseContent: obligatory || additional,
      parametersFormConfig: {
        inputPointsForCompletion: additional || obligatory,
        inputAttemptLimit: elearning,
        inputPointsLimit: elearning,
        inputTypeOfCompletion: obligatory,
        inputRenewal: additional || obligatory,
      },
      assessmentConfig: {
        enableCorrectAnswer: elearning,
      },
      typeValues,
      methodValues: {
        method: obligatory
          ? getOptionTaskMethodById(AssessmentMethodEnum.Photo)
          : elearning
            ? getOptionTaskMethodById(AssessmentMethodEnum.Test)
            : null,
      },
      contentValues: {
        nodes: [],
      },
      assessmentValues: {
        nodes: [],
      },
    };
  }),
  completeMethodPhase: (methodValues) => set((state) => {
    const methodId = methodValues.method?.id || null;
    const survey = methodId === AssessmentMethodEnum.Survey;
    const test = methodId === AssessmentMethodEnum.Test;

    return {
      phase: 'parameters',
      parametersFormConfig: {
        ...state.parametersFormConfig,
        inputAttemptLimit: test,
        inputPointsLimit: test,
        inputTypeOfCompletion: survey,
        inputPointsForCompletion: survey,
      },
      assessmentConfig: {
        enableCorrectAnswer: test,
      },
      methodValues,
    };
  }),
  completeParametersPhase: (parametersValues) => set((state) => ({
    phase: state.skipPhaseContent ? 'assessment' : 'content',
    parametersValues,
  })),
  completeContentPhase: (contentValues) => set({
    phase: 'assessment',
    contentValues,
  }),
  completeAssessmentPhase: (assessmentValues) => set({
    assessmentValues,
  }),
  saveParametersValues: (parametersValues) => set({
    parametersValues,
  }),
  saveContentValues: (contentValues) => set({
    contentValues,
  }),
  saveAssessmentValues: (assessmentValues) =>set({
    assessmentValues,
  }),
}));
