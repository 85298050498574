import ModalCreateAssortment from 'Modules/AssortmentAdmin/modals/ModalCreateAssortment';
import ModalEditAssortment from 'Modules/AssortmentAdmin/modals/ModalEditAssortment';
import ModalRemoveAssortment from 'Modules/AssortmentAdmin/modals/ModalRemoveAssortment';
import AssortmentsTable from './AssortmentsTable';

const AssortmentAdminPage = (): JSX.Element => {  
  return (
    <>
      <AssortmentsTable />

      <ModalCreateAssortment />
      <ModalEditAssortment />
      <ModalRemoveAssortment />
    </>
  );
};

export default AssortmentAdminPage;
