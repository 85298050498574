import { CSSProperties, ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material';
import Autocomplete, { AutocompleteProps, } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import ArrowFullDownIcon from 'Utils/svg/ArrowFullDownIcon';
import CloseIcon from 'Utils/svg/CloseIcon';
import { useFormContext, } from 'react-hook-form';
import InputTextClassic, { InputTextClassicProps, } from './InputTextClassic';

const StyledPopper = styled(Popper)(({ theme, }) => ({
  '& .MuiAutocomplete-paper': {
    marginTop: theme.spacing(1),
  },
  '& .MuiAutocomplete-listbox > .MuiAutocomplete-option': {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
}));

export interface InputAutocompleteClassicProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'variant' | 'PopperComponent'> {
  label?: ReactNode,
  helperText?: ReactNode,
  error?: boolean,
  required?: boolean,
  inputProps?: Omit<InputTextClassicProps, 'onChange' | 'value' | 'label' | 'error' | 'helperText'>,
  overwriteStyles?: {
    [key: string]: CSSProperties;
  }};

const InputAutocompleteClassic = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  label,
  helperText,
  error,
  required,
  placeholder,
  overwriteStyles,
  multiple,
  ...rest
}: InputAutocompleteClassicProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  const { t, } = useTranslation('common');
  const { setValue, } = useFormContext() || {};
  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      openText={t('inputAutocomplete.open')}
      closeText={t('inputAutocomplete.close')}
      clearText={t('inputAutocomplete.clear')}
      loadingText={t('inputAutocomplete.loading')}
      noOptionsText={t('inputAutocomplete.noOptions')}
      clearIcon={<CloseIcon />}
      popupIcon={<ArrowFullDownIcon color="primary" />}
      multiple={multiple || undefined}
      {...rest}
      onInputChange={(event, value, reason) => {
        if (reason === 'input' && multiple) {
          const inputData = value;
          const dataContainsCommas = inputData?.includes(',') || false;
          const dataAsArray: string[] = dataContainsCommas ? (inputData?.split(',') as []) : [];
          const cleanDataAsArray = dataAsArray.map((item) => item.trim());
          if (dataAsArray.length > 0) {
            const { options, } = rest;
            const dataToOptions = cleanDataAsArray.reduce((acc, cur) => {
              const existingOption = options.find((option) => (option as T & { id: string }).id === cur);
              if (existingOption) {
                return [existingOption, ...acc,];
              }
              return acc;
            }, [] as T[]);
            if (dataToOptions.length > 0 && rest.id && setValue) {
              setValue(rest.id, dataToOptions);
            }
          }
        }

        if (!rest.onInputChange) {
          return () => {};
        }
        return rest.onInputChange(event, value, reason);
      }}
      sx={{
        '& .Mui-disabled .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
          color: 'rgba(0, 0, 0, 0.38)',
        },
        '& .MuiInputBase-input': {
          paddingRight: 0,
        },
        '& .MuiAutocomplete-endAdornment .MuiSvgIcon-fontSizeMedium': {
          fontSize: {
            xs: '0.875rem',
            lg: '0.75rem',
          },
        },
        '& .MuiAutocomplete-endAdornment': {
          paddingRight: '8px',
          top: {
            xs: 'calc(50% - 12px)',
            lg: 'calc(50% - 12px)',
          },
        },
        '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot': {
          paddingRight: {
            xs: '36px',
            lg: '32px',
          },
        },
        '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
          paddingRight: {
            xs: '64px',
            lg: '56px',
          },
        },
        '& .MuiAutocomplete-clearIndicator, & .MuiAutocomplete-popupIndicator': {
          width: {
            xs: '28px',
            lg: '24px',
          },
          height: {
            xs: '28px',
            lg: '24px',
          },
        },
        ...overwriteStyles,
      }}
      PopperComponent={StyledPopper}
      renderInput={(ip) => (
        <InputTextClassic
          ref={ip.InputProps.ref}
          className={ip.InputProps.className}
          endAdornment={ip.InputProps.endAdornment}
          startAdornment={ip.InputProps.startAdornment}
          InputLabelProps={ip.InputLabelProps}
          inputProps={ip.inputProps}
          id={ip.id}
          disabled={ip.disabled}
          fullWidth={ip.fullWidth}
          error={error}
          required={required}
          label={label}
          helperText={helperText}
          placeholder={placeholder}
          autoComplete={ip.inputProps.id}
          // size
        />
      )}
    />
  );
};

export default InputAutocompleteClassic;
