import shallow from 'zustand/shallow';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { NewsArticlesStore, useNewsArticlesStore, } from 'Modules/NewsArticlesAdmin/store/NewsArticlesStore';
import Logic from './Logic';

const selectStoreData = (s: NewsArticlesStore) => ({
  onCloseRemove: s.closeModalRemoveNewsArticle,
  onCloseEdit: s.closeModalEditNewsArticle,
  isOpen: s.modalRemoveNewsArticle.isOpen,
  newsArticle: s.modalRemoveNewsArticle.newsArticle,
});

const ModalRemoveNewsArticle = (): JSX.Element => {
  const {
    onCloseRemove,
    onCloseEdit,
    isOpen,
    newsArticle,
  } = useNewsArticlesStore(selectStoreData, shallow);

  return (
    <ModalDrawer
      onClose={onCloseRemove}
      open={isOpen && !!newsArticle}
    >
      <Logic
        newsArticle={newsArticle}
        onCloseRemove={onCloseRemove}
        onCloseEdit={onCloseEdit}
      />
    </ModalDrawer>
  );
};

export default ModalRemoveNewsArticle;
