import { useCallback, useEffect, useState, } from 'react';
import { useForm, FormProvider, useWatch, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import shallow from 'zustand/shallow';
import { RegistrationType, RoleEnum, useSearchCustomerByEmailLazyQuery, } from 'Apollo/graphql';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { mapBadFormDataError, } from 'Components/Form';
import { initPhaseBadFormDataFieldMapper, phaseInitFormValidationSchema, PhaseInitFormValues, } from './utils';
import { SearchedCustomerByEmail, } from './types';
import View from './View';
import { useAuthUser } from 'Tools/auth';

const selectStoreData = (s: RegistrationStore) => ({
  registrationEmail: s.registrationEmail,
  completeInitPhase: s.completeInitPhase,
  isShortRegistration: s.isShortRegistration,
  toPhase: s.goBackToPhase,
});

const PhaseInit = (): JSX.Element => {
  const authUser = useAuthUser()
  const {
    registrationEmail,
    completeInitPhase,
    isShortRegistration,
    toPhase,
  } = useRegistrationStore(selectStoreData, shallow);
  if (isShortRegistration) toPhase('purchase');
  const methods = useForm<PhaseInitFormValues>({
    defaultValues: { email: registrationEmail, },
    resolver: yupResolver(phaseInitFormValidationSchema),
    mode: 'onSubmit',
  });
  const [searchedCustomerByEmail, setSearchedCustomerByEmail,] = useState<SearchedCustomerByEmail | null>(null);

  const emailValue = useWatch({ control: methods.control, name: 'email', });
  useEffect(
    () => setSearchedCustomerByEmail(null),
    [emailValue,],
  );

  const [searchCustomerByEmailQuery, searchCustomerByEmailResult,] = useSearchCustomerByEmailLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result.searchCustomerByEmail) {
        setSearchedCustomerByEmail({
          isValid: true,
          customer: result.searchCustomerByEmail.customer || null,
        });
      }
      else {
        setSearchedCustomerByEmail({
          isValid: false,
          customer: null,
        });
      }
    },
    onError: (responseError) => {
      mapBadFormDataError({
        apolloError: responseError,
        fieldMapper: initPhaseBadFormDataFieldMapper,
        methods,
      });
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await searchCustomerByEmailQuery({
      variables: {
        email: values.email,
      },
    });
  });

  const handlePurchaseOrRegister = useCallback(
    () => {
      completeInitPhase(
        searchedCustomerByEmail?.customer ? {
          id: searchedCustomerByEmail.customer.id,
          dateOfBirth: searchedCustomerByEmail.customer.dateOfBirth ? new Date(searchedCustomerByEmail.customer.dateOfBirth) : null,
          email: searchedCustomerByEmail.customer.email,
          firstName: searchedCustomerByEmail.customer.firstName,
          lastName: searchedCustomerByEmail.customer.lastName,
          phoneNumber: searchedCustomerByEmail.customer.phoneNumber,
          phonePrefix: searchedCustomerByEmail.customer.phonePrefix,
          acceptedPulzeCare: searchedCustomerByEmail.customer.acceptedPulzeCare,
          acceptedPulzeOn: searchedCustomerByEmail.customer.acceptedPulzeOn,
          acceptedTermsAndConditions: searchedCustomerByEmail.customer.acceptedTermsAndConditions,
          previouslyOwnedDevices: searchedCustomerByEmail.customer.previouslyOwnedDevices.id,
          purchasedProductsForGlobalDB: searchedCustomerByEmail.customer.purchasedProductsForGlobalDB.id,
          // FIXME: this should not be there, should be handled by the backend
          registrationType: RegistrationType.Standard,
        } : null,
        emailValue,
        searchedCustomerByEmail?.customer?.doi ?? false,
        authUser?.roleCode === RoleEnum.CallCenterAgent
      );
    },
    [completeInitPhase, searchedCustomerByEmail, emailValue, authUser?.roleCode],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <View
          searchedCustomerByEmail={searchedCustomerByEmail}
          searchCustomerByEmailResult={searchCustomerByEmailResult}
          onPurchaseOrRegister={handlePurchaseOrRegister}
        />
      </form>
    </FormProvider>
  );
};

export default PhaseInit;
