import AppContainer from 'Components/AppContainer';
import { Grid, Typography, } from '@mui/material';
import TopChainsInReplacements from 'Components/Dashboard/Chains/TopChainsInReplacements';
import ActiveInactiveChains from 'Components/Dashboard/Chains/ActiveInactiveChains';
import TopChainsInRegistrations from 'Components/Dashboard/Chains/TopChainsInRegistrations';
import {
  Claim,
  DashboardLayoutItemName,
  useUserDashboardLayoutQuery,
} from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import { useTranslation, } from 'react-i18next';

const Chains = () => {
  const { data, } = useUserDashboardLayoutQuery();
  const authUser = useAuthUser();
  const { t, } = useTranslation('common');
  return (
    <AppContainer>
      <Typography variant="h1" marginBottom={2}>
        {t('dashboardSidebar.users')}
      </Typography>
      <Grid container spacing={2}>
        <Grid xs={12} md={6} container item spacing={2}>
          {authUser?.claimsSet.has(Claim.DashboardChainsActiveChains) && (
            <Grid item xs={12}>
              <ActiveInactiveChains
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.ChainsActiveChains,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.claimsSet.has(Claim.DashboardChainsTop_10Registrations) && (
            <Grid item xs={12}>
              <TopChainsInRegistrations
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.ChainsTop_10Registrations,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid xs={12} md={6} container item spacing={2}>
          {authUser?.claimsSet.has(Claim.DashboardChainsTop_10Replacements) && (
            <Grid item xs={12}>
              <TopChainsInReplacements
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.ChainsTop_10Replacements,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AppContainer>
  );
};

export default Chains;
