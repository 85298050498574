/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, } from 'react';
import * as yup from 'yup';
import { v4, } from 'uuid';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { AssessmentBlockOption, } from 'Utils/options/useOptionsAssessmentBlock';
import { AssessmentBlockTypeEnum, } from 'Apollo/graphql';
import { AssessmentConfig, } from 'Modules/TasksAdmin/store/createTaskStore';
import { NodesObj, NodeKeys, Node, NodeSchemaObj, NodeAnswerRow, AssessmentValues, } from './types';

export const createNodeAnswerRow = (): NodeAnswerRow => ({
  answer: '',
  id: v4(),
});

export const NODES: NodesObj = {
  default: () => ({
    type: null,
  }),
  [AssessmentBlockTypeEnum.Text]: () => ({
    type: null,
    points: null,
    question: '',
  }),
  [AssessmentBlockTypeEnum.Photo]: () => ({
    type: null,
    description: '',
  }),
  [AssessmentBlockTypeEnum.Number]: () => ({
    type: null,
    points: null,
    question: '',
  }),
  [AssessmentBlockTypeEnum.YesNo]: () => ({
    type: null,
    correctAnswer: null,
    points: null,
    question: '',
  }),
  [AssessmentBlockTypeEnum.Checkbox]: () => ({
    type: null,
    answers: [ createNodeAnswerRow(), createNodeAnswerRow(), createNodeAnswerRow(), createNodeAnswerRow(), ],
    correctAnswer: [],
    points: null,
    question: '',
  }),
  [AssessmentBlockTypeEnum.Radio]: () => ({
    type: null,
    answers: [ createNodeAnswerRow(), createNodeAnswerRow(), createNodeAnswerRow(), createNodeAnswerRow(), ],
    correctAnswer: null,
    points: null,
    question: '',
  }),
  [AssessmentBlockTypeEnum.Scale]: () => ({
    type: null,
    points: null,
    question: '',
  }),
};

export const getNodeInitValue = (type: AssessmentBlockOption | null): Node => {
  if (type && Object.prototype.hasOwnProperty.call(NODES, type.id)) return ({
    ...NODES[type.id as NodeKeys](),
    type,
  });
  return NODES.default();
};

export const contentValues: AssessmentValues = {
  nodes: [ NODES.default(), ],
};

const NODES_SCHEMA: NodeSchemaObj = {
  default: () => yup.object().shape({
    type: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
  }),
  [AssessmentBlockTypeEnum.Text]: (assessmentConfig) => yup.object().shape({
    type: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    question: yup.string()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    ...(assessmentConfig.enableCorrectAnswer && {
      points: yup.number()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
  }),
  [AssessmentBlockTypeEnum.Photo]: () => yup.object().shape({
    type: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    description: yup.string()
      .required(INPUT_FORM_ERRORS.REQUIRED),
  }),
  [AssessmentBlockTypeEnum.Number]: (assessmentConfig) => yup.object().shape({
    type: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    question: yup.string()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    ...(assessmentConfig.enableCorrectAnswer && {
      points: yup.number()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
  }),
  [AssessmentBlockTypeEnum.YesNo]: (assessmentConfig) => yup.object().shape({
    type: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    question: yup.string()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    ...(assessmentConfig.enableCorrectAnswer && {
      points: yup.number()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
      correctAnswer: yup.object()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
  }),
  [AssessmentBlockTypeEnum.Checkbox]: (assessmentConfig) => yup.object().shape({
    type: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    question: yup.string()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    answers: yup.array().of(yup.object().shape({
      answer: yup.string()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    })),
    ...(assessmentConfig.enableCorrectAnswer && {
      points: yup.number()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
  }),
  [AssessmentBlockTypeEnum.Radio]: (assessmentConfig) => yup.object().shape({
    type: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    question: yup.string()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    answers: yup.array().of(yup.object().shape({
      answer: yup.string()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    })),
    ...(assessmentConfig.enableCorrectAnswer && {
      points: yup.number()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
      correctAnswer: yup.string()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
  }),
  [AssessmentBlockTypeEnum.Scale]: (assessmentConfig) => yup.object().shape({
    type: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    question: yup.string()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    ...(assessmentConfig.enableCorrectAnswer && {
      points: yup.number()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
  }),
};

const schemaNode = yup.lazy((value: Node, options) => {
  const assessmentConfig: AssessmentConfig = (options.context as AssessmentConfig | undefined) || { enableCorrectAnswer: false, };
  const { type, } = value;
  if (type && Object.prototype.hasOwnProperty.call(NODES_SCHEMA, type.id)) return NODES_SCHEMA[type.id as NodeKeys](assessmentConfig);
  return NODES_SCHEMA.default(assessmentConfig);
});

export const contentValidationSchema = yup.object().shape({
  nodes: yup.array().of(schemaNode as any),
});


export const useInitValues = (assessmentValues: AssessmentValues): AssessmentValues => useMemo<AssessmentValues>(
  () => assessmentValues.nodes.length < 1
    ? { nodes: [ NODES.default(), ], }
    : assessmentValues,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [],
);
