import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { PointsTransactionFragment, UpdatePointsTransactionMutationResult, } from 'Apollo/graphql';
import {
  FormError,
  FormSubmit,
} from 'Components/Form';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import PointsTransactionForm from 'Modules/PointsTransactionAdmin/components/PointsTransactionForm';

interface Props {
  pointsTransaction: PointsTransactionFragment,
  editMutationResult: UpdatePointsTransactionMutationResult,
  onClose: () => void,
};

const View = ({
  pointsTransaction,
  editMutationResult,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('pointsTransactionAdmin');

  return (
    <ModalDrawerBody>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 5,
        }}
      >
        {t('editPointsTransactionModal.info')}
      </Typography>
      <PointsTransactionForm
        pointsTransaction={pointsTransaction}
      />
      <Box mt={4}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <FormSubmit
              size="large"
            >
              {t('editPointsTransactionModal.btnSubmit')}
            </FormSubmit>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              color="inherit"
              onClick={onClose}
            >
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <FormError mt={2} />
      <ErrorAlert
        mt={2}
        error={editMutationResult.error}
      />
    </ModalDrawerBody>
  );
};

export default View;
