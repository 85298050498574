import shallow from 'zustand/shallow';
import { FormProvider, useForm, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import AppContainer from 'Components/AppContainer';
import { CreateTaskStore, ParametersValues, useCreateTaskStore, } from 'Modules/TasksAdmin/store/createTaskStore';
import { parameterValidationSchema, useInitValues, } from './utils';
import View from './View';

const selectStoreData = (s: CreateTaskStore) => ({
  skipPhaseMethod: s.skipPhaseMethod,
  skipPhaseContent: s.skipPhaseContent,
  parametersFormConfig: s.parametersFormConfig,
  parametersValues: s.parametersValues,
  typeValues: s.typeValues,
  methodValues: s.methodValues,
  goBackToPhase: s.goBackToPhase,
  completeParameters: s.completeParametersPhase,
  saveParametersValues: s.saveParametersValues,
});

const PhaseParameters = (): JSX.Element => {
  const {
    skipPhaseMethod,
    parametersFormConfig,
    parametersValues,
    typeValues,
    methodValues,
    goBackToPhase,
    completeParameters,
    saveParametersValues,
  } = useCreateTaskStore(selectStoreData, shallow);

  const initValues = useInitValues(parametersValues, parametersFormConfig);

  const methods = useForm<ParametersValues>({
    defaultValues: initValues,
    resolver: yupResolver(parameterValidationSchema(parametersFormConfig)),
    mode: 'onSubmit',
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    completeParameters(values);
  });

  const handleBack = () => {
    saveParametersValues(methods.getValues());
    goBackToPhase(skipPhaseMethod ? 'type' : 'method');
  };

  return (
    <AppContainer maxWidth="sm">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <View
            parametersFormConfig={parametersFormConfig}
            typeValues={typeValues}
            methodValues={methodValues}
            onBack={handleBack}
          />
        </form>
      </FormProvider>
    </AppContainer>
  );
};

export default PhaseParameters;
