import { getI18n, } from 'react-i18next';
import { AssessmentBlockTypeEnum, CheckboxAssessmentBlock, NumberAssessmentBlock, PhotoAssessmentBlock, RadioAssessmentBlock, ScaleAssessmentBlock, TextAssessmentBlock, YesNoAssessmentBlock, } from 'Apollo/graphql';
import { AssessmentValues, NodeCheckbox, NodeNumber, NodePhotoTask, NodeRadio, NodeScale, NodeText, NodeYesNo, } from '../PhaseAssessment/types';
import { MapAssessmentOptions, } from './types';

type AssessmentBlock =
  CheckboxAssessmentBlock
  | NumberAssessmentBlock
  | RadioAssessmentBlock
  | ScaleAssessmentBlock
  | TextAssessmentBlock
  | YesNoAssessmentBlock
  | PhotoAssessmentBlock;


const mapAssessmentCheckbox = (node: NodeCheckbox, options: MapAssessmentOptions): CheckboxAssessmentBlock => ({
  type: AssessmentBlockTypeEnum.Checkbox,
  question: node.question,
  answers: node.answers.map((a) => a.answer),
  numberOfPoints: node.points || 0,
  requiresCorrectAnswer: options.requiresCorrectAnswer,
  correctAnswerIndices: options.requiresCorrectAnswer
    ? node.answers.reduce(
      (acc, cur, index) => node.correctAnswer.find((ca) => ca === cur.id) ? [ ...acc, index, ] : acc,
      [] as number[],
    )
    : [], 
});

const mapAssessmentNumber = (node: NodeNumber, options: MapAssessmentOptions): NumberAssessmentBlock => ({
  type: AssessmentBlockTypeEnum.Number,
  question: node.question,
  numberOfPoints: node.points || 0,
});

const mapAssessmentRadio = (node: NodeRadio, options: MapAssessmentOptions): RadioAssessmentBlock => ({
  type: AssessmentBlockTypeEnum.Radio,
  question: node.question,
  answers: node.answers.map((a) => a.answer),
  numberOfPoints: node.points || 0,
  requiresCorrectAnswer: options.requiresCorrectAnswer,
  correctAnswerIndex: options.requiresCorrectAnswer
    ? node.answers.reduce(
      (acc, cur, index) => node.correctAnswer === cur.id ? index : acc,
      undefined as number | undefined,
    )
    : undefined, 
});

const mapAssessmentScale = (node: NodeScale, options: MapAssessmentOptions): ScaleAssessmentBlock => ({
  type: AssessmentBlockTypeEnum.Scale,
  question: node.question,
  numberOfPoints: node.points || 0,
});

const mapAssessmentText = (node: NodeText, options: MapAssessmentOptions): TextAssessmentBlock => ({
  type: AssessmentBlockTypeEnum.Text,
  question: node.question,
  numberOfPoints: node.points || 0,
});

const mapAssessmentPhoto = (node: NodePhotoTask, options: MapAssessmentOptions): PhotoAssessmentBlock => ({
  type: AssessmentBlockTypeEnum.Photo,
  description: node.description,
});

const getYesNoCorrect = (answer: boolean | null | undefined): number => {
  if (answer === null || answer === undefined) return 0;
  return answer ? 0 : 1;
};

const mapAssessmentYesNo = (node: NodeYesNo, options: MapAssessmentOptions): YesNoAssessmentBlock => ({
  type: AssessmentBlockTypeEnum.YesNo,
  question: node.question,
  answers: [
    getI18n().t('common:options.yesNo.yes'),
    getI18n().t('common:options.yesNo.no'),
  ],
  numberOfPoints: node.points || 0,
  requiresCorrectAnswer: options.requiresCorrectAnswer,
  correctAnswerIndex: options.requiresCorrectAnswer
    ? getYesNoCorrect(node.correctAnswer?.value)
    : undefined, 
});


export const mapAssessmentVariables = (assessmentValues: AssessmentValues | null | undefined, options: MapAssessmentOptions): { blocks: AssessmentBlock[], } => {
  const blocks: AssessmentBlock[] = [];

  const tmpArray: AssessmentValues['nodes'] = assessmentValues?.nodes || [];
  for (let i = 0; i < tmpArray.length; i++) {
    const node = tmpArray[i];
    
    if (node.type?.id) {
      switch (node.type.id) {
        case AssessmentBlockTypeEnum.Checkbox: {
          blocks.push(mapAssessmentCheckbox(node as NodeCheckbox, options));
          break;
        }
        case AssessmentBlockTypeEnum.Number: {
          blocks.push(mapAssessmentNumber(node as NodeNumber, options));
          break;
        }
        case AssessmentBlockTypeEnum.Radio: {
          blocks.push(mapAssessmentRadio(node as NodeRadio, options));
          break;
        }
        case AssessmentBlockTypeEnum.Scale: {
          blocks.push(mapAssessmentScale(node as NodeScale, options));
          break;
        }
        case AssessmentBlockTypeEnum.Text: {
          blocks.push(mapAssessmentText(node as NodeText, options));
          break;
        }
        case AssessmentBlockTypeEnum.YesNo: {
          blocks.push(mapAssessmentYesNo(node as NodeYesNo, options));
          break;
        }
        case AssessmentBlockTypeEnum.Photo: {
          blocks.push(mapAssessmentPhoto(node as NodePhotoTask, options));
          break;
        }
        default: break;
      }
    }
  }

  return { blocks, };
};
