import FormBlockerProvider from 'Components/Form/FormBlocker/FormBlockerProvider';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { NewsArticlesStore, useNewsArticlesStore, } from 'Modules/NewsArticlesAdmin/store/NewsArticlesStore';
import Logic from './Logic';

const selectModalData = (s: NewsArticlesStore) => s.modalCreateNewsArticle;
const selectClose = (s: NewsArticlesStore) => s.closeModalCreateNewsArticle;

const ModalCreateNewsArticle = (): JSX.Element => {
  const { isOpen, } = useNewsArticlesStore(selectModalData);
  const onClose = useNewsArticlesStore(selectClose);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen}
    >    
      <FormBlockerProvider>
        <Logic
          onClose={onClose}
        />
      </FormBlockerProvider>
    </ModalDrawer>
  );
};

export default ModalCreateNewsArticle;
