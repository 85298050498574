import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSnackbar, } from 'notistack';
import { PromotionFragment, useDeletePromotionMutation, } from 'Apollo/graphql';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import { updatePromotionRemove, } from 'Modules/PromotionsAdmin/utils/promotionsCache';
import View from './View';

interface Props {
  promotion?: PromotionFragment | null,
  onClose: () => void,
};

const Logic = ({
  promotion,
  onClose,
}: Props): JSX.Element => {
  const preservedPromotion = usePreserveValue(promotion);
  const { t, } = useTranslation('promotionsAdmin');
  const { enqueueSnackbar, } = useSnackbar();

  const [ removeMutation, removeMutationResult, ] = useDeletePromotionMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('removePromotionModal.notifRemovedTitle') as string,
        message: t('removePromotionModal.notifRemovedMessage', { name: preservedPromotion.name, }),
        variant: 'common',
      });
      onClose();
    },
    update: updatePromotionRemove,
  });

  const handleSubmit = useCallback(
    () => {
      removeMutation({
        variables: { id: preservedPromotion.id, },
      });
    },
    [ removeMutation, preservedPromotion.id, ],
  );

  return (
    <View
      promotion={preservedPromotion}
      removeMutationResult={removeMutationResult}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default Logic;
