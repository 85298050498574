import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useSnackbar, } from 'notistack';
import { AssortmentCategoriesDocument, useCreateAssortmentMutation, } from 'Apollo/graphql';
import { updateAssortmentCreate, } from 'Modules/AssortmentAdmin/utils/assortmentCache';
import {
  initAssortmentFormValues,
  assortmentFormValidationSchema,
  mapFormToVariables,
} from '../../utils/assortmentForm';
import View from './View';

interface Props {
  onClose: () => void,
};

const Logic = ({
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('assortmentAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const methods = useForm({
    defaultValues: initAssortmentFormValues,
    resolver: yupResolver(assortmentFormValidationSchema),
    mode: 'onTouched',
  });

  const [ createMutation, createMutationResult, ] = useCreateAssortmentMutation({
    refetchQueries: [{
      query: AssortmentCategoriesDocument,
    },],
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('createAssortmentModal.notifCreatedTitle') as string,
        message: t('createAssortmentModal.notifCreatedMessage', { productId: response.createAssortment.id, }),
        variant: 'common',
      });
      onClose();
    },
    onError: () => {
      // TODO: merge form errors
    },
    update: updateAssortmentCreate,
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await createMutation({
      variables: {
        assortmentInput: mapFormToVariables(values),
      },
    });    
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View
          createMutationResult={createMutationResult}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default Logic;
