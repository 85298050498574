import { Fragment, } from 'react';
import shallow from 'zustand/shallow';
import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { ReplacementStore, useReplacementStore, } from "Modules/ReplacementSeller/store/ReplacementStore";
import { CompleteReplacementMutationResult, ReplacementStatus, } from 'Apollo/graphql';
import ProductCard from 'Components/ProductCard';
import { useReplacementStatus, } from 'Tools/useReplacementStatus';
import ErrorAlert from 'Components/Errors/ErrorAlert';

const selectStoreData = (s: ReplacementStore) => ({
  replacement: s.replacement,
  goToPhase: s.goToPhase,
  finishReplacement: s.finishReplacement,
});

interface Props {
  closeTicket: () => void,
  completeMutationResult: CompleteReplacementMutationResult,
}

const View = ({
  closeTicket,
  completeMutationResult,
}: Props) => {
  const { t, } = useTranslation('replacementSeller');
  const {
    replacement,
    goToPhase,
    finishReplacement,
  } = useReplacementStore(selectStoreData, shallow);

  const replacementStatus = useReplacementStatus(replacement.status);

  const isNotComplete = replacement.status !== ReplacementStatus.Completed;

  const handleBack = () => isNotComplete ? goToPhase('replacementSearch') : finishReplacement();

  const products = isNotComplete ? replacement.exchangedProducts : replacement.exchangeProductsWhenComplete;

  return (
    <>
      <Typography
        variant="h1"
        sx={{ mb: 5, }}
      >
        {t('phaseDetail.title')}
      </Typography>

      <Box mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('phaseDetail.labelId')}
            >
              {replacement.id}
            </NotEditableTextClassic>
          </Grid>

          {/* <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('phaseDetail.labelUsername')}
            >
              {replacement.username}
            </NotEditableTextClassic>
          </Grid> */}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h4" color="primary" gutterBottom>
              {t('phaseDetail.labelStatus')}
            </Typography>
            <Chip
              label={replacementStatus.label}
              color={replacementStatus.color}
            />
          </Grid>

          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('phaseDetail.labelCreatedAt')}
            >
              {replacement.createdAt
                ? t('common:date.fullDateTime', { date: new Date(replacement.createdAt), })
                : '-'
              }
            </NotEditableTextClassic>
          </Grid>

          {!!replacement.customer && <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('phaseDetail.labelCustomer')}
            >
              <div>
                {`${replacement.customer?.firstName || ''} ${replacement.customer?.lastName || ''}`}
              </div>
              {replacement.customer?.dateOfBirth && (
                <div>
                  {t('common:date.fullDate', { date: replacement.customer.dateOfBirth, })}
                </div>
              )}
              <div>
                {replacement.customer?.email || ''}
              </div>
              <div>
                {`${replacement.customer?.phonePrefix || ''} ${replacement.customer?.phoneNumber|| ''}`}
              </div>
            </NotEditableTextClassic>
          </Grid>}

          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('phaseDetail.labelCategory')}
            >
              {replacement.category}
            </NotEditableTextClassic>
          </Grid>

          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('phaseDetail.labelProducts')}
            >
              {products.map(product => (
                <Fragment key={product.option.id}>
                  {product.count > 0 && (
                    <ProductCard 
                      title={product.option.name}
                      imageSrc={product.option.image?.publicUrl}
                      count={product.count}
                    />
                  )}
                </Fragment>
   
              ))}
            </NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('phaseDetail.labelReason')}
            >
              {replacement.reason}
            </NotEditableTextClassic>
          </Grid>
        </Grid>
      </Box>

      <ErrorAlert
        mt={2}
        error={completeMutationResult.error}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} lg="auto">
          <Button
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            disabled={completeMutationResult.loading}
            onClick={isNotComplete ? closeTicket : handleBack}
          >
            {isNotComplete ? t('buttons.btnCloseTicket') : t('buttons.btnDone')}
          </Button>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            onClick={handleBack}
          >
            {isNotComplete ? t('buttons.btnBack') : t('buttons.btnSearch')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default View;

