import { useDeleteUserMutation, UserFragment, } from 'Apollo/graphql';
import { updateUserRemove, } from 'Modules/Users/utils/userCache';
import { useSnackbar, } from 'notistack';
import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import View from './View';

interface Props {
  user?: UserFragment | null,
  onCloseRemove: () => void,
  onCloseDetail: () => void,
};

const Logic = ({
  user,
  onCloseRemove,
  onCloseDetail,
}: Props): JSX.Element => {
  const authUser = useAuthUser();
  const preservedUser = usePreserveValue(user);
  const { t, } = useTranslation('users');
  const { enqueueSnackbar, } = useSnackbar();

  const [ removeMutation, removeMutationResult, ] = useDeleteUserMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('removeUserModal.notifRemovedTitle') as string,
        message: t('removeUserModal.notifRemovedMessage', { username: preservedUser.username, }),
        variant: 'common',
      });
      onCloseRemove();
      onCloseDetail();
    },
    update: updateUserRemove,
  });

  const handleSubmit = useCallback(
    () => {
      removeMutation({
        variables: { id: preservedUser.id, },
      });
    },
    [ removeMutation, preservedUser.id, ],
  );

  return (
    <View
      user={preservedUser}
      removeMutationResult={removeMutationResult}
      onClose={onCloseRemove}
      onSubmit={handleSubmit}
    />
  );
};

export default Logic;
