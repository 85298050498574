import { UserFragment, } from 'Apollo/graphql';
import { FormInputAutocomplete, } from 'Components/Form';
import { renderOptionById, } from 'Utils/helpers';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { zodResolver, } from '@hookform/resolvers/zod';
import { z, } from 'zod';
import { Grid, } from '@mui/material';
import { useUserSearchOptions, } from './useUserSearchOptions';
import RenderQr from '../RenderQr';

const userFormSchema = z.object({
  // TODO: FORMS SHOULD HAVE ONLY THE VALUES NOT WHOLE CONFIGURATION
  userId: z.object({
    id: z.string(),
    labelKey: z.string(),
    value: z.string(),
  }),
});

const InputUser = (): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const userOptions = useUserSearchOptions();
  const methods = useForm({
    resolver: zodResolver(userFormSchema),
    mode: 'onTouched',
  });

  const userId = methods.watch('userId');

  return (
    <>
      <Grid item xs={12}>
        <FormProvider {...methods}>
          <form noValidate autoComplete="off">
            <FormInputAutocomplete<UserFragment>
              name="userId"
              label={t('qrModal.inputLabel.user')}
              options={userOptions.options}
              getOptionLabel={userOptions.getOptionLabel}
              isOptionEqualToValue={userOptions.isOptionEqualToValue}
              renderOption={renderOptionById(userOptions.getOptionLabel)}
              filterOptions={userOptions.filterOptions}
              loading={userOptions.loading}
              onInputChange={userOptions.onInputChange}
              noOptionsText={userOptions.noOptionsText}
              required
            />
          </form>
        </FormProvider>
      </Grid>
        {userId && (
      <Grid item xs={12}>
          <RenderQr
            value={`${import.meta.env.VITE_APP_SELF_REG_URL}/?a=${userId.id}`}
          />
      </Grid>
        )}
    </>
  );
};

export default InputUser;
