import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';

export type ForgottenPasswordFormValues = {
  email: string,
};

export const initForgottenPasswordFormValues: ForgottenPasswordFormValues = {
  email: '',
};

export const forgottenPasswordFormValidationSchema = yup.object().shape({
  email: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(2048, INPUT_FORM_ERRORS.STRING_MAX)
    .email(INPUT_FORM_ERRORS.EMAIL),
});

export const mapFormToVariables = (values: ForgottenPasswordFormValues) => ({
  email: values.email,
});
