import { yupResolver, } from '@hookform/resolvers/yup';
import {
  AuthUserFragment,
  CustomerVerificationMethod,
  useCheckIfPhoneIsInUseMutation,
  useCurrentMarketQuery,
  useGetVerificationMethodsQuery,
  useStartCustomerVerificationMutation,
} from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import { useEffect, useMemo, useRef, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { USER_VERIFICATION_OPTIONS, } from 'Utils/options/useOptionsUserVerification';
import { removeWhiteSpaces, } from 'Utils/helpers';
import { throttle, } from 'lodash';
import { CreateReplacementStore, useCreateReplacementStore, } from '../createReplacementStore';
import View from './View';
import { FormConfig, } from './types';
import { formValidationSchema, mapRegisterVariables, } from './utils';

const selectStoreData = (s: CreateReplacementStore) => ({
  replacementEmail: s.replacementEmail,
  customerRegistrationValues: s.customerRegistrationValues,
  customerVerification: s.customerVerification,
  completeCustomerRegistrationPhase: s.completeCustomerRegistrationPhase,
});

interface Props {
  onClose: () => void;
}

const PhaseCustomerRegistration = ({ onClose, }: Props): JSX.Element => {
  const { replacementEmail, customerRegistrationValues, customerVerification, completeCustomerRegistrationPhase, } = useCreateReplacementStore(
    selectStoreData,
    shallow,
  );
  const currentMarket = useCurrentMarketQuery();
  const authUser = useAuthUser();
  const refVerification = useRef<CreateReplacementStore['customerVerification']>(customerVerification);
  const [checkIfPhoneIsInUse, phoneIsInUseResult,] = useCheckIfPhoneIsInUseMutation();
  const verificationMethods = useGetVerificationMethodsQuery().data?.getVerificationMethods.verificationMethods;

  const formConfig = useMemo<FormConfig>(
    () => ({
      enableTitle: !!authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_GENDER_TITLE_INPUT,
      verificationMethods: verificationMethods || null,
      enablePulzeOn: !!authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PULZE_ON,
      enablePhoneDuplicityVerification: !!authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PHONE_NUMBER_DUPLICITY_VERIFICATION,
      currentMarket: currentMarket.data?.currentMarket,
    }),
    [authUser, currentMarket, verificationMethods,],
  );

  const methods = useForm({
    defaultValues: customerRegistrationValues,
    resolver: yupResolver(formValidationSchema(formConfig, phoneIsInUseResult.data?.checkIfPhoneIsInUse?.value)),
    mode: 'onSubmit',
  });
  
  useEffect(() => {
    if ((formConfig.verificationMethods || []).length > 1) {
      methods.setValue('verificationMethod', USER_VERIFICATION_OPTIONS[0]);
    }
  }, [verificationMethods,]);
  

  useEffect(() => {
    const throttledCheckPhone = throttle((phone: string, phonePrefixId: string) => {
      checkIfPhoneIsInUse({ variables: { phoneNumber: phone, phonePrefix: phonePrefixId, }, });
    }, 1000);

    const subscription = methods.watch((value, { name, type, }) => {
      if (value.phone && value.phonePrefix?.id) throttledCheckPhone(value.phone, value.phonePrefix.id);
    });

    return () => {
      throttledCheckPhone.cancel();
      subscription.unsubscribe();
    };
  }, [checkIfPhoneIsInUse, methods, methods.watch,]);

  useEffect(() => {
    const values = methods.getValues();
    if (values.phone && values.phonePrefix?.id && !phoneIsInUseResult.called) {
      checkIfPhoneIsInUse({ variables: { phoneNumber: values.phone, phonePrefix: values.phonePrefix?.id, }, });
    }
  }, []);

  const [startVerificationMutation, startVerificationResult,] = useStartCustomerVerificationMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      completeCustomerRegistrationPhase(
        methods.getValues(),
        {
          ...refVerification.current,
          shouldVerify: true,
          isVerified: false,
        },
        result.startCustomerVerification,
      );
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    if (refVerification.current.isVerified) {
      completeCustomerRegistrationPhase(
        values,
        {
          isVerified: true,
          shouldVerify: [formConfig.verificationMethods || [],].length > 0,
          value: refVerification.current.value,
          method: values.verificationMethod?.id || null,
        },
        { __typename: 'CustomerVerificationStartResultSkipVerification', skipVerification: true, },
      );
    } else {
      const { verificationMethod, phonePrefix, phone, } = values;
      refVerification.current = {
        shouldVerify: true,
        isVerified: false,
        method: verificationMethod?.id ? verificationMethod?.id : verificationMethods ? verificationMethods[0] : null,
        value: verificationMethod?.id === CustomerVerificationMethod.Email ? replacementEmail : `${phonePrefix?.id || ''}${phone}`,
      };

      const method = values.verificationMethod?.id || (verificationMethods ? verificationMethods[0] : null);
      const verificatedValueRaw = method === CustomerVerificationMethod.Email ? replacementEmail : `${phonePrefix?.id || ''}${phone}`;
      const verificatedValue = removeWhiteSpaces(verificatedValueRaw);
      await startVerificationMutation({
        variables: {
          method,
          verificatedValue,
          customerInput: mapRegisterVariables(replacementEmail, values, authUser as AuthUserFragment).customerInput,
          deviceCode: values.deviceCode || null,
        },
      });
    }
  });
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View formConfig={formConfig} replacementEmail={replacementEmail} startVerificationResult={startVerificationResult} onClose={onClose} />
      </form>
    </FormProvider>
  );
};

export default PhaseCustomerRegistration;
