import { yupResolver, } from '@hookform/resolvers/yup';
import { TableVoucherFragment, useUpdateVoucherMutation, VoucherFragment, VoucherType, } from 'Apollo/graphql';
import { useSnackbar, } from 'notistack';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { mapFormToVariables, useInitVoucherValues, voucherFormValidationSchema, } from '../../utils/voucherForm';
import View from './View';

interface Props {
  voucher: VoucherFragment;
  onClose: () => void;
  onDelete: (voucher: TableVoucherFragment | VoucherFragment) => void;
}

const Logic = ({ voucher, onClose, onDelete, }: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const initValues = useInitVoucherValues(voucher);
  const methods = useForm({
    defaultValues: initValues,
    resolver: yupResolver(voucherFormValidationSchema),
    mode: 'onTouched',
  });

  const [editMutation, editMutationResult,] = useUpdateVoucherMutation({
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('detailVoucherModal.notifEditedTitle') as string,
        message: t('detailVoucherModal.notifEditedMessage', { id: response.updateVoucher.id, }),
        variant: 'common',
      });
      onClose();
    },
    onError: () => {
      // TODO: merge form errors
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await editMutation({
      variables: {
        id: voucher.id,
        voucherInput: mapFormToVariables(values),
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View voucher={voucher} editMutationResult={editMutationResult} onClose={onClose} onDelete={onDelete} />
      </form>
    </FormProvider>
  );
};

export default Logic;
