import { ReactNode, useMemo, useCallback, } from 'react';
import type { AnyObject, } from 'Utils/types';
import FormHelperTextClassic from '../components/FormHelperTextClassic';
import InputLabelClassic from '../components/InputLabelClassic';
import { InputProductCountMultipleValueOption, ObjValue, OnChange, } from './types';
import OptionList from './OptionList';

export interface InputProductCountMultipleProps<T extends AnyObject,> {
  value: InputProductCountMultipleValueOption<T>[];
  options: T[];
  getOptionKey: (option: T) => string;
  getOptionLabel: (option: T) => ReactNode;
  getOptionMax?: (option: T) => number | null | undefined;
  onChange: (value: InputProductCountMultipleValueOption<T>[]) => void;
  helperText?: ReactNode;
  error?: boolean;
  loading?: boolean;
  label?: ReactNode;
  required?: boolean;
  noOptionsText?: string;
  totalDisabledPlus?: boolean;
}

const InputProductCountMultiple = <T extends AnyObject,>({
  getOptionKey,
  getOptionLabel,
  getOptionMax,
  options,
  value,
  onChange,
  helperText,
  error,
  loading,
  label,
  required,
  noOptionsText,
  totalDisabledPlus,
}: InputProductCountMultipleProps<T>): JSX.Element => {
  const handleChange = useCallback<OnChange<T>>(
    (changedItem) => {
      const filteredValue = value.filter((o) => getOptionKey(o.option) !== getOptionKey(changedItem.option));
      if (changedItem.count < 1) {
        onChange(filteredValue);
      } else {
        onChange([...filteredValue, changedItem,]);
      }
    },
    [onChange, value, getOptionKey,],
  );
  const objValue = useMemo<ObjValue<T>>(
    () =>
      value.reduce(
        (prev, curr) => ({
          ...prev,
          [getOptionKey(curr.option)]: curr,
        }),
        {},
      ),
    [value, getOptionKey,],
  );

  return (
    <>
      {label && <InputLabelClassic required={required}>{label}</InputLabelClassic>}

      <OptionList
        loading={loading}
        options={options}
        onChange={handleChange}
        objValue={objValue}
        getOptionLabel={getOptionLabel}
        getOptionMax={getOptionMax}
        getOptionKey={getOptionKey}
        noOptionsText={noOptionsText}
        totalDisabledPlus={totalDisabledPlus}
      />

      {helperText && (
        <FormHelperTextClassic sx={{ mb: 1, }} error={error}>
          {helperText}
        </FormHelperTextClassic>
      )}
    </>
  );
};

export default InputProductCountMultiple;
