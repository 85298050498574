import { styled, } from "@mui/material/styles";
import Button from '@mui/material/Button';

const IconButtonSquare = styled(Button)(({ theme, }) => ({
  minHeight: theme.typography.pxToRem(42),
  minWidth: theme.typography.pxToRem(42),
  padding: 0,
}));

export default IconButtonSquare;
