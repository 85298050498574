import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const LogoutColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M19.846 1h-9.692c-.571 0-1.12.232-1.523.644C8.227 2.057 8 2.617 8 3.2v1.1c0 .292.113.572.315.778a1.066 1.066 0 0 0 1.523 0c.202-.206.316-.486.316-.778V3.2h9.692v17.6h-9.692v-1.1c0-.292-.114-.572-.316-.778a1.066 1.066 0 0 0-.761-.322c-.286 0-.56.116-.762.322A1.112 1.112 0 0 0 8 19.7v1.1c0 .584.227 1.143.63 1.556.405.412.953.644 1.524.644h9.692c.571 0 1.12-.232 1.523-.644.404-.413.631-.973.631-1.556V3.2c0-.583-.227-1.143-.63-1.556A2.131 2.131 0 0 0 19.845 1Z"
      fill="#333"
    />
    <path
      d="M7.66 16.68a1.049 1.049 0 0 1-1.508 0l-3.839-3.925A1.106 1.106 0 0 1 2 11.98c0-.29.113-.568.313-.773l3.839-3.926a1.055 1.055 0 0 1 1.472.038 1.107 1.107 0 0 1 .037 1.506l-2.007 2.061h9.276c.284 0 .556.115.757.32.2.206.313.484.313.774 0 .29-.113.569-.313.774-.201.205-.473.32-.757.32H5.654l2.007 2.061a1.106 1.106 0 0 1-.001 1.544Z"
      fill="#333"
    />
  </SvgIcon>
);

export default LogoutColoredIcon;
