import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactivateUserMutationResult, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import FormInputText from 'Components/Form/inputs/FormInputText';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { useTranslation, } from 'react-i18next';

interface Props {
  reactivateMutationResult: ReactivateUserMutationResult;
  onSubmit: () => void;
  onClose: () => void;
}

const View = ({ reactivateMutationResult, onSubmit, onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation('users');

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>{t('reactivateUserModal.title')}</ModalDrawerTitle>
      <ModalDrawerBody>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 5,
          }}
        >
          {t('reactivateUserModal.info')}
        </Typography>
        <Box mt={4}>
          <FormInputText name="username" label={t('user.labelUsername')} required />
        </Box>
        <Box mt={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Button color="warning" variant="contained" size="large" disabled={reactivateMutationResult.loading} onClick={onSubmit}>
                {t('reactivateUserModal.btnSubmit')}
              </Button>
            </Grid>
            <Grid item>
              <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
                {t('common:actions.cancel')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <ErrorAlert mt={2} error={reactivateMutationResult.error} />
      </ModalDrawerBody>
    </>
  );
};

export default View;
