/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseFormReturn, } from 'react-hook-form';
import { ApolloError, } from '@apollo/client';
import { ErrorCodeEnum, FormFieldErrorCodeEnum, } from 'Apollo/graphql';
import { APOLLO_FIELD_ERROR_MAPPER, INPUT_FORM_ERRORS, } from '../constants';
import { InputFormErrorMessage, } from '../types';

type ApolloFieldError = {
  field: string,
  formFieldErrorCode: FormFieldErrorCodeEnum,
  value: string | number
};

type FieldMapper = {
  [key: string]: string | undefined,
};

type MapBadFormDataErrorArgs = {
  apolloError: ApolloError,
  fieldMapper: FieldMapper,
  methods: UseFormReturn<any, any>,
};

const getFieldName = (formError: ApolloFieldError, fieldMapper: FieldMapper): string | null => fieldMapper[formError.field] || null;

const getFieldErrorMessage = (filedName: string, formError: ApolloFieldError, reason?: string): InputFormErrorMessage => {
  // TODO custom parsing errors => if error match fieldName and ErrorType => custom error
  if (APOLLO_FIELD_ERROR_MAPPER[formError.formFieldErrorCode]) {
    return APOLLO_FIELD_ERROR_MAPPER[formError.formFieldErrorCode](reason ?? formError.value);
  }
  return INPUT_FORM_ERRORS.UNKNOWN();
};

export const mapBadFormDataError = ({ apolloError, fieldMapper, methods, }: MapBadFormDataErrorArgs): void => {
  // debugger;
  try {
    if (apolloError.graphQLErrors?.[0]) {
      const gqlError = apolloError.graphQLErrors?.[0];
      const code = gqlError.extensions?.code as string || null;
      const formErrors = (gqlError.extensions?.formErrors || []) as ApolloFieldError[];
      const reason = String(gqlError.extensions?.reason);

      if (code === ErrorCodeEnum.BadFormData && formErrors.length > 0) {
        for (let i = 0; i < formErrors.length; i++) {

          const formError = formErrors[i];
          const filedName = getFieldName(formError, fieldMapper);
          if (filedName) {
            const filedErrorMessage = getFieldErrorMessage(filedName, formError, reason);
            methods.setError(filedName, { message: filedErrorMessage as any, });
          }
        }
      }
    }

  } catch (error) {
    // ignore error
  }
};
