import { useMemo, } from 'react';
import * as yup from 'yup';
import { AssertsShape, TypeOfShape, AnyObject, } from 'yup/lib/object';
import { AssortmentCategoryWithAssortmentOptionFragment, ReplacedAssortmentFragment, } from 'Apollo/graphql';
import { InputFormErrorMessage, INPUT_FORM_ERRORS, } from 'Components/Form';
import { ProductOption, Replacement, } from 'Modules/ReplacementSeller/store/ReplacementStore';
import { codeRegex, } from 'Utils/deviceCodeRegex';

type ProductsFormValues = {
  assortmentCategories: {
    [key: string]: ProductOption[],
  },
  batchCode: string,
};

type RetUseProductsForm = {
  initValues: ProductsFormValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: yup.ObjectSchema<any, AnyObject, TypeOfShape<any>, AssertsShape<any>>,
  structure: AssortmentCategoryWithAssortmentOptionFragment | null,
};

const CUSTOM_PRODUCTS_VALIDATION_MESSAGE = (({ min, }: { min: number, }): InputFormErrorMessage => ({
  key: 'replacementSeller:phaseProducts.errorMinProducts',
  params: { min, },
}));

export const useProductsForm = (
  data: AssortmentCategoryWithAssortmentOptionFragment,
  exchangedProducts: ProductOption[],
  replacement: Replacement,
  checkDeviceCodeMutationResult: boolean,
): RetUseProductsForm => {
  const ret = useMemo<RetUseProductsForm>(
    () => {
      const validationSchema = yup.object().shape({
        assortmentCategories: yup.object().shape({
          [data.id]: yup.array()
            .test({
              params: {
                min: 1,
              },
              message: CUSTOM_PRODUCTS_VALIDATION_MESSAGE,
              test: (value) => {
                const totalCount = (value as ReplacedAssortmentFragment[]).reduce(
                  (prev, curr) => prev + (curr.count || 0) ,
                  0,
                );
                return totalCount > 0;
              },
            }),
        }),
        batchCode: yup.string(),
        ...(data.requiresDeviceCodes && {batchCode: yup.string()
            .required(INPUT_FORM_ERRORS.REQUIRED)
            .matches(codeRegex, INPUT_FORM_ERRORS.INVALID_DEVICE_CODE)
            .test('batchCode', INPUT_FORM_ERRORS.ALREADY_EXIST, () => {
              return !checkDeviceCodeMutationResult;
        }),}),
      });
      return {
        initValues: {
          assortmentCategories: {
            [data.id]: exchangedProducts,
          },
          batchCode:replacement.batchCode,
        },
        validationSchema,
        structure: data,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return ret;
};
