import Typography from '@mui/material/Typography';
import { useTranslation, } from 'react-i18next';
import AppContainer from 'Components/AppContainer';
import FetchData from './Fetchdata';


const EditMyTaxInformation = (): JSX.Element => {
  const { t, } = useTranslation("myProfile");
  return (
    <AppContainer maxWidth="sm">
      <Typography
        variant="h1"
        sx={{
          marginBottom: 1.5,
        }}
      >
        {t('tax.title')}
      </Typography>
      <Typography
        variant="inherit"
        sx={{
          marginBottom: 5,
        }}
      >
        {t('tax.editMyTaxInfo')}
      </Typography>
      <FetchData/>
    </AppContainer>
  );
};

export default EditMyTaxInformation;
