import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useTranslation, } from 'react-i18next';
import { useMemo, } from 'react';
import { splitOfTotalRegistrationsQuery, } from './queries';

const SplitOfTotalRegistrations = ({
  showAddButton,
  showSlug = true,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');
  const query = useMemo(() => splitOfTotalRegistrationsQuery(), []);
  return (
    <Base
      headerText={t('dashboards.registrations.splitOfTotalRegistrations')}
      slug={showSlug ? 'split-of-total-registrations' : undefined}
      showAddButton={showAddButton}
      dashboardLayoutItemName={
        DashboardLayoutItemName.RegistrationsConsumerAgeGroups
      }
    >
      <ChartRenderer
      hideLegend
      orderByDate={false}
        query={query}
        type={ChartType.bar}
        pivotConfig={{
          x: ['dimensions',],
          y: ['measures',],
          fillMissingDates: true,
          joinDateRange: false,
        }}
      />
    </Base>
  );
};

export default SplitOfTotalRegistrations;
