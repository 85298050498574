import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useTranslation, } from 'react-i18next';
import { useMemo, } from 'react';
import { activeInactiveUsersQuery, } from './queries';

const ActiveInactiveUsers = ({
  showAddButton,
  filtration,
  chartFilter,
  showTotal = false,
  showSlug = true,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');
  const query = useMemo(
    () =>
      activeInactiveUsersQuery({
        filters: chartFilter,
      }),
    [chartFilter,],
  );
  return (
    <Base
      headerText={t('dashboards.users.activeInactiveUsers')}
      slug={showSlug ? 'total-users' : undefined}
      showAddButton={showAddButton}
      
      filtration={filtration}
      dashboardLayoutItemName={DashboardLayoutItemName.UsersActiveUsers}
    >
      <ChartRenderer
      showTotal={showTotal}
        query={query}
        type={ChartType.pie}
      />
    </Base>
  );
};

export default ActiveInactiveUsers;
