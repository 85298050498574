import { UserFragment, } from 'Apollo/graphql';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { UserStore, useUserStore, } from 'Modules/Users/store/UserStore';
import Logic from './Logic';

const selectModalData = (s: UserStore) => s.modalEraseAllUserData;
const selectCloseErase = (s: UserStore) => s.closeModalEraseAllUserData;
const selectCloseDetail = (s: UserStore) => s.closeModalDetailUser;

const ModalEraseAllUserData = (): JSX.Element => {
  const { isOpen, user, } = useUserStore(selectModalData);
  const onCloseErase = useUserStore(selectCloseErase);
  const onCloseDetail = useUserStore(selectCloseDetail);

  return (
    <ModalDrawer
      onClose={onCloseErase}
      open={isOpen && !!user}
    >
      <Logic
        user={user as UserFragment}
        onCloseErase={onCloseErase}
        onCloseDetail={onCloseDetail}
      />
    </ModalDrawer>
  );
};

export default ModalEraseAllUserData;
