import Grid from '@mui/material/Grid';
import { PointsTransactionFragment, } from 'Apollo/graphql';
import { FormInputText, } from 'Components/Form';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { valueOrEmpty, } from 'Utils/parsers';
import { useTranslation, } from 'react-i18next';
import { evaluateOperationSymbol, } from '../pages/PointsTransactionAdminPage/PointsTransactionTable/utils';

interface Props {
  pointsTransaction: PointsTransactionFragment;
}

const PointsTransactionForm = ({ pointsTransaction, }: Props): JSX.Element => {
  const { t, } = useTranslation('pointsTransactionAdmin');

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('pointsTransaction.labelPosId')}>{pointsTransaction.posId || '-'}</NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('pointsTransaction.labelChainId')}>{pointsTransaction.chainId || '-'}</NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('pointsTransaction.labelAuthor')}>{pointsTransaction.author.username || '-'}</NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('pointsTransaction.labelId')}>{pointsTransaction.id || '-'}</NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('pointsTransaction.labelReason')}>{pointsTransaction.reason.name}</NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('pointsTransaction.labelCreatedAt')}>
          {t('common:date.fullDate', { date: pointsTransaction.createdAt, }) || '-'}
        </NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('pointsTransaction.labelOperation')}>
          {valueOrEmpty(evaluateOperationSymbol(pointsTransaction.operation))}
        </NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="amount" label={t('pointsTransaction.labelAmount')} required />
      </Grid>
    </Grid>
  );
};

export default PointsTransactionForm;
