import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { topPosInRegistrationsQuery, } from './queries';

const TopPosInRegistrations = ({
  showAddButton,
  filtration,
  chartFilter,
   timeDimensions,
  showSlug = true,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');

  const query = useMemo(
    () =>
      topPosInRegistrationsQuery({
        timeDimensions,
        filters: chartFilter,
      }),
    [timeDimensions, chartFilter,],
  );

  return (
    <Base
      headerText={t('dashboards.pos.topPosInRegistrations')}
      slug={showSlug ? 'top-pos-in-registrations' : undefined}
      showAddButton={showAddButton}
      filtration={filtration}
      
      dashboardLayoutItemName={DashboardLayoutItemName.PointsOfSaleTop_10Registrations}
    >
      <ChartRenderer
        query={query}
        type={ChartType.bar}
      />
    </Base>
  );
};

export default TopPosInRegistrations;
