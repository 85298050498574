/* eslint-disable react/destructuring-assignment */
import { RoleEnum, } from 'Apollo/graphql';
import { HTMLAttributes, ReactNode, } from 'react';


export const getOptionFieldName = <T extends { name: string, },>(o: T): string => o.name;
export const getOptionFieldIdAndName = <T extends { name: string, id: string, },>(o: T): string => `${o.id} - ${o.name}`;
export const getOptionFieldUsername = <T extends { username: string, },>(o: T): string => o.username;
export const getOptionFieldId = <T extends { id: string, },>(o: T): string => o.id;
export const getOptionFieldColor = <T extends { color: string, },>(o: T): string => o.color;

export const areOptionsEqualById = <T extends { id: string | boolean, },>(o: T, v: T): boolean => v.id === o.id;
export const areOptionsEqualByRole = <T extends { role: RoleEnum, },>(o: T, v: T): boolean => v.role === o.role;

export const renderOptionByIdName = <T extends { id: string, name: string, },>(props: HTMLAttributes<HTMLLIElement>, o: T): JSX.Element => (
  <li
    {...props}
    key={o.id}
  >
    {o.name}
  </li>
);

export const renderOptionById = <T extends { id: string, },>(getOptionLabel: (o: T) => ReactNode) => (props: HTMLAttributes<HTMLLIElement>, o: T): JSX.Element => (
  <li
    {...props}
    key={o.id}
  >
    {getOptionLabel(o)}
  </li>
);
export const renderOption = <T extends string>(getOptionLabel: (o: T) => ReactNode) => (props: HTMLAttributes<HTMLLIElement>, o: T): JSX.Element => (
  <li
    {...props}
    key={o}
  >
    {getOptionLabel(o)}
  </li>
);
export const renderOptionByBoolId = <T extends { id: string | boolean, },>(getOptionLabel: (o: T) => ReactNode) => (props: HTMLAttributes<HTMLLIElement>, o: T): JSX.Element => (
  <li
    {...props}
    key={`${!!o.id}`}
  >
    {getOptionLabel(o)}
  </li>
);


export function filterAll <T,>(x: T) { return x; };

export const removeWhiteSpaces = (str: string): string => str.replace(/\s/g, '');
