import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { PurchasesStore, usePurchasesStore, } from 'Modules/RegistrationsAdmin/store/PurchasesStore';
import FetchData from './FetchData';

const selectModalData = (s: PurchasesStore) => s.modalExportPurchase;
const selectClose = (s: PurchasesStore) => s.closeModalExportPurchase;

const ModalExportPurchases = (): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const { isOpen, } = usePurchasesStore(selectModalData);
  const onClose = usePurchasesStore(selectClose);

  return (
    <ModalDrawer onClose={onClose} open={isOpen}>
      <ModalDrawerTitle onClose={onClose}>{t(`common:defaultExport.title`, {name: t('purchases.title'),})}</ModalDrawerTitle>
      <FetchData onClose={onClose} />
    </ModalDrawer>
  );
};

export default ModalExportPurchases;
