import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import Chip, { ChipProps, } from '@mui/material/Chip';
import { TaskCompletionStatusEnum, } from 'Apollo/graphql';
import { getOptionTaskCompletionById, TaskCompletionStatusOption, } from 'Utils/options/useOptionsTaskCompletionStatus';

const StyledChip = styled(
  Chip,
  { shouldForwardProp: (prop) => (prop !== 'taskCompletionStatus'), }
)<{ status: TaskCompletionStatusOption | null, }>(({ theme, status, }) => ({
  ...(status && ({
    color: theme.palette.getContrastText(status.color),
    background: status.color,
  })),
}));

interface Props extends Omit<ChipProps, 'color' | 'label'> {
  taskCompletionStatus?: TaskCompletionStatusEnum,
}

const ChipTaskCompletionStatus = ({
  taskCompletionStatus,
  ...rest
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  const status = getOptionTaskCompletionById(taskCompletionStatus);

  return (
    <StyledChip
      {...rest}
      status={status}
      label={t(status?.labelKey || taskCompletionStatus || 'common:status.unknown')}
    />
  );
};

export default ChipTaskCompletionStatus;
