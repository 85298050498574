import { useMemo, } from 'react';
import { useLocation, } from 'react-router-dom';

export const useIsActualRoute = (route: string, exact: boolean): boolean => {
  const currentPath = useLocation();

  return useMemo(
    () => exact
      ? currentPath.pathname === route
      : currentPath.pathname.startsWith(route),
    [ currentPath, route, exact, ],
  );
};
