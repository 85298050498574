import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';
import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';

export type AlzaPurchaseOption = {
  id: string,
  labelKey: string,
};

const generateAlzaPurchaseOptions = (values: number[]) => values.map((value) => ({id: String(value), labelKey: `${value} Kč`,}));

type GetOptionLabel = (o: AlzaPurchaseOption) => string;
type GetOptionKey = (o: AlzaPurchaseOption) => string;

type UseOptionsGenderTitle = (values: number[]) => {
  options: AlzaPurchaseOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<AlzaPurchaseOption>, 
};

export const useOptionsAlzaVoucherPurchase: UseOptionsGenderTitle = (values: number[]) => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: generateAlzaPurchaseOptions(values),
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
