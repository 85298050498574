import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAuthUser, } from 'Tools/auth';
import Grid from '@mui/material/Grid';
import { PointOfSellDetailFragment, } from 'Apollo/graphql';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';

interface Props {
  pointOfSell: PointOfSellDetailFragment,
  onClose: () => void,
};

const View = ({
  pointOfSell,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('pos');
  const authUser = useAuthUser();

  return (
    <ModalDrawerBody>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('pointOfSell.labelPosId')}
          >
            {pointOfSell.id}
          </NotEditableTextClassic>

        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('pointOfSell.labelPosName')}
          >
            {pointOfSell.name || '-'}
          </NotEditableTextClassic>

        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('pointOfSell.labelChainId')}
          >
            {pointOfSell.chain.id || '-'}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('pointOfSell.labelChainName')}
          >
            {pointOfSell.chain.name || '-'}
          </NotEditableTextClassic>
        </Grid>

        {authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS && (
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('pointOfSell.labelClusterId')}
            >
              {pointOfSell.clusterId || '-'}
            </NotEditableTextClassic>
          </Grid>
        )}

        <Grid item xs={12} >
          <NotEditableTextClassic
            label={t('pointOfSell.labelStreet')}
          >
            {pointOfSell.street || '-'}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12} >
          <NotEditableTextClassic
            label={t('pointOfSell.labelNumber')}
          >
            {pointOfSell.houseNumber || '-'}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12} >
          <NotEditableTextClassic
            label={t('pointOfSell.labelCity')}
          >
            {pointOfSell.city || '-'}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12} >
          <NotEditableTextClassic
            label={t('pointOfSell.labelPostalCode')}
          >
            {pointOfSell.postalCode || '-'}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12} >
          <NotEditableTextClassic
            label={t('pointOfSell.labelSalesRepresentativeId')}
          >
            {pointOfSell.salesRepresentativeEmail || '-'}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12} >
          <NotEditableTextClassic
            label={t('pointOfSell.labelAreaSalesRepresentativeId')}
          >
            {pointOfSell.areaSalesRepresentativeEmail || '-'}
          </NotEditableTextClassic>
        </Grid>

      </Grid>

      <Box mt={4}>
        <Grid container spacing={3}>
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              color="inherit"
              onClick={onClose}
            >
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalDrawerBody>
  );
};

export default View;

