import PrizeModal from 'Components/PrizeWheel/PrizeModal';
import PrizeWheelModal from 'Components/PrizeWheel/PrizeWheelModal';
import React, { useState, } from 'react';
import { useWindowSize, } from 'react-use';
import Confetti from 'react-confetti';
import { useTranslation, } from 'react-i18next';
import { PrizeWheelDocument, PrizeWheelTypeEnum, useGeneratePrizeWheelMutation, } from 'Apollo/graphql';
import { usePrize, } from 'Modules/PrizeWheel/usePrize';

const ChristmasPrizeWheelPage = () => {
  const { t, } = useTranslation('prizeWheel');
  const { width, height, } = useWindowSize();
  const [prizeWheelModalVisible, setPrizeWheelModalVisible,] = useState(true);
  const [prizeModalVisible, setPrizeModalVisible,] = useState(false);
  const [confettiVisible, setConfettiVisible,] = useState(false);
  const [ prize, setPrizeValue,]  = usePrize();

  const [generatePrizeWheel,] = useGeneratePrizeWheelMutation({
    onCompleted: (data) => {
      if (data.generatePrizeWheel?.value) {
        setPrizeValue(data.generatePrizeWheel.value);
      }
    },
    refetchQueries: [PrizeWheelDocument,],
    variables: { type: PrizeWheelTypeEnum.Xmas_2023, },
  });

  return (
    <>
      {confettiVisible && (
        <Confetti
          width={width}
          height={height}
          style={{ zIndex: 10000, }}
          numberOfPieces={500}
          recycle={false}
          onConfettiComplete={() => {
            setPrizeWheelModalVisible(false);
            setPrizeModalVisible(true);
          }}
        />
      )}
      <PrizeWheelModal
        prizeWheelModalVisible={prizeWheelModalVisible}
        setConfettiVisible={setConfettiVisible}
        heading={t('christmas.prizeWheelLabel')}
        prizeWheelMutation={generatePrizeWheel}
        prizeNumber={prize.prizeNumber}
      />
      <PrizeModal visible={prizeModalVisible} prize={prize} />
    </>
  );
};
export default ChristmasPrizeWheelPage;
