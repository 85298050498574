import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { SearchCustomerByEmailQueryResult, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { FormInputText, FormSubmit, } from 'Components/Form';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import { SearchedCustomerByEmail, } from './types';

interface Props {
  searchedCustomerByEmail: SearchedCustomerByEmail | null;
  searchCustomerByEmailResult: SearchCustomerByEmailQueryResult;
  createTicketRegister: () => void;
  createTicketReplacementRegistration: () => void;
  onClose: () => void;
}

const View = ({
  searchedCustomerByEmail,
  searchCustomerByEmailResult,
  createTicketRegister,
  createTicketReplacementRegistration,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  const showSubmit = !searchedCustomerByEmail;

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>{t('createReplacementModal.phaseInit.title')}</ModalDrawerTitle>

      <ModalDrawerBody>
        <Typography variant="body1" sx={{ mb: 3, }}>
          {showSubmit || searchedCustomerByEmail?.isValid
            ? t('createReplacementModal.phaseInit.info')
            : t('createReplacementModal.phaseInit.infoRegisterCustomer')}
        </Typography>
        <Box mb={4}>
          <FormInputText
            autoFocus
            name="email"
            label={t('createReplacementModal.phaseInit.labelEmail')}
            disabled={searchCustomerByEmailResult.loading}
          />
        </Box>
        {showSubmit ? (
          <>
            <Box mb={2}>
              <FormSubmit size="large" color="primary" sx={{ width: { xs: '100%', lg: 'unset', }, }}>
                {t('createReplacementModal.phaseInit.btnVerify')}
              </FormSubmit>
            </Box>
            <Box mb={2}>
              <Button
                size="large"
                color="primary"
                sx={{ width: { xs: '100%', lg: 'unset', marginTop: '1rem', }, }}
                onClick={createTicketReplacementRegistration}
              >
                {t('createReplacementModal.phaseInit.btnReplacementRegistration')}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Button size="large" color="primary" sx={{ width: { xs: '100%', lg: 'unset', }, }} onClick={createTicketRegister}>
              {searchedCustomerByEmail.isValid && searchedCustomerByEmail.customer
                ? t('createReplacementModal.phaseInit.btnCreateTicket')
                : t('createReplacementModal.phaseInit.btnRegister')}
            </Button>
            {!searchedCustomerByEmail.isValid && !!searchedCustomerByEmail.customer && (
              <Button
                size="large"
                color="primary"
                sx={{ width: { xs: '100%', lg: 'unset', marginTop: '1rem', }, }}
                onClick={createTicketReplacementRegistration}
              >
                {t('createReplacementModal.phaseInit.btnReplacementRegistration')}
              </Button>
            )}
          </>
        )}
        <ErrorAlert sx={{ mt: 2, }} error={searchCustomerByEmailResult.error} />
      </ModalDrawerBody>
    </>
  );
};

export default View;
