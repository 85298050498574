import { useTranslation, } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ChainFragment, PointOfSellOptionFragment, PointsTransactionSortableFields, useChainsQuery, } from 'Apollo/graphql';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import TableCellSortable from 'Components/Table/TableCellSortable';
import { useOptionsPointsTransactionReason, PointsTransactionReasonOption, } from 'Utils/options/usePointsTransactionReason';
import { areOptionsEqualById, getOptionFieldId, renderOptionById, renderOption } from 'Utils/helpers';
import { useSearchOptionsPos, } from 'Utils/options/useSearchOptionsPos';

const COL_WIDTHS = [
  {},
  { width: '200px', },
  {},
  {},
  { width: '200px', },
  {},
  {},
  { width: '45px', },
];


const PointsTransactionsTableHeader = (): JSX.Element => {
  const { t, } = useTranslation('pointsTransactionAdmin');
  const pointsTransactionReasonOptions = useOptionsPointsTransactionReason();
  const chainOptions = useChainsQuery();
  const posOptions = useSearchOptionsPos();

  return (
    <TableHead>
      <TableRow>
        <TableCellSortable style={COL_WIDTHS[0]} name={PointsTransactionSortableFields.PosId}>
          {t('pointsTransactions.table.labelPosId')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[1]}>
          {t('pointsTransactions.table.labelAuthor')}
        </TableCell>
        <TableCellSortable style={COL_WIDTHS[2]} name={PointsTransactionSortableFields.ChainId}>
          {t('pointsTransactions.table.labelChainId')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[3]}>
          {t('pointsTransactions.table.labelId')}
        </TableCell>
        <TableCellSortable style={COL_WIDTHS[4]} name={PointsTransactionSortableFields.Reason}>
          {t('pointsTransactions.table.labelReason')}
        </TableCellSortable>
        <TableCellSortable style={COL_WIDTHS[5]} name={PointsTransactionSortableFields.CreatedAt}>
          {t('pointsTransactions.table.labelCreatedAt')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[6]}>{t('pointsTransactions.table.labelOperationAmount')}</TableCell>
        <TableCell style={COL_WIDTHS[7]} />
      </TableRow>
      <TableRow>
        <TableCell>
          <TableFilterAutocomplete<PointOfSellOptionFragment>
            name="pos"
            options={posOptions.options}
            getOptionLabel={posOptions.getOptionLabel}
            isOptionEqualToValue={posOptions.isOptionEqualToValue}
            renderOption={renderOptionById(posOptions.getOptionLabel)}
            filterOptions={posOptions.filterOptions}
            loading={posOptions.loading}
            onInputChange={posOptions.onInputChange}
            noOptionsText={posOptions.noOptionsText}
          />
        </TableCell>
        <TableCell>
          <TableFilterText name="author" />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<ChainFragment>
            name="chain"
            options={chainOptions.data?.chains || []}
            getOptionLabel={getOptionFieldId}
            isOptionEqualToValue={areOptionsEqualById}
            renderOption={renderOptionById(getOptionFieldId)}
          />
        </TableCell>
        <TableCell>
          <TableFilterText name="id" />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<PointsTransactionReasonOption>
            name="reason"
            getOptionLabel={pointsTransactionReasonOptions.getOptionLabel}
            isOptionEqualToValue={pointsTransactionReasonOptions.isOptionEqualToValue}
            options={pointsTransactionReasonOptions.options}
            renderOption={renderOption(pointsTransactionReasonOptions.getOptionLabel)}
          />
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default PointsTransactionsTableHeader;
