import AppContainer from 'Components/AppContainer';
import { Grid, Typography, } from '@mui/material';
import TotalNumberOfPoints from 'Components/Dashboard/PointsAndTasks/TotalNumberOfPoints';
import PointsCategories from 'Components/Dashboard/PointsAndTasks/PointsCategory';
import NumberOfActiveTasks from 'Components/Dashboard/PointsAndTasks/NumberOfActiveTasks';
import NumberOfTaskCompletions from 'Components/Dashboard/PointsAndTasks/NumberOfTaskCompletions';
import { Claim, DashboardLayoutItemName, useUserDashboardLayoutQuery, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import { useTranslation, } from 'react-i18next';

const PointsAndTasks = () => {
  const { data, } = useUserDashboardLayoutQuery();
  const authUser = useAuthUser();
  const { t,} = useTranslation('common');
  return (
    <AppContainer>
      <Typography variant="h1" marginBottom={2}>{t('dashboardSidebar.tasksAndPoints')}</Typography>
      <Grid container spacing={2}>
        <Grid xs={12} md={6} container item spacing={2}>
          {authUser?.claimsSet.has(Claim.DashboardPointsAndTasksTotalNumberOfPoints) && (
            <Grid item xs={12}>
              <TotalNumberOfPoints
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.PointsAndTasksTotalNumberOfPoints,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.claimsSet.has(Claim.DashboardPointsAndTasksPointsCategory) && (
            <Grid item xs={12}>
              <PointsCategories
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.PointsAndTasksPointsCategory,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid xs={12} md={6} container item spacing={2}>
          {authUser?.claimsSet.has(Claim.DashboardPointsAndTasksNumberOfActiveTasks) && (
            <Grid item xs={12}>
              <NumberOfActiveTasks
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.PointsAndTasksNumberOfActiveTasks,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.claimsSet.has(Claim.DashboardPointsAndTasksNumberOfTasksCompletions) && (
            <Grid item xs={12}>
              <NumberOfTaskCompletions
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.PointsAndTasksNumberOfTasksCompletions,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AppContainer>
  );
};

export default PointsAndTasks;
