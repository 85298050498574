import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useSnackbar, } from 'notistack';
import { useCreateNewsArticleMutation, } from 'Apollo/graphql';
import { updateNewsArticleCreate, } from '../../utils/newsArticlesCache';
import {
  initNewsArticleFormValues,
  newsArticleFormValidationSchema,
  mapFormToVariables,
} from '../../utils/newsArticleForm';
import View from './View';

interface Props {
  onClose: () => void,
};

const Logic = ({
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('newsArticlesAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const methods = useForm({
    defaultValues: initNewsArticleFormValues,
    resolver: yupResolver(newsArticleFormValidationSchema),
    mode: 'onTouched',
  });
  const [ createMutation, createMutationResult, ] = useCreateNewsArticleMutation({
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('createNewsArticleModal.notifCreatedTitle') as string,
        message: t('createNewsArticleModal.notifCreatedMessage', { id: response.createNewsArticle.id, }),
        variant: 'common',
      });
      onClose();
    },
    onError: () => {
      // TODO: merge form errors
    },
    update: updateNewsArticleCreate,
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await createMutation({
      variables: {
        newsArticleInput: mapFormToVariables(values),
      },
    });    
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View
          createMutationResult={createMutationResult}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default Logic;
