import { KeyboardEventHandler, useCallback, useMemo, useRef, } from 'react';
import { withReact, Slate, } from 'slate-react';
import { createEditor, Descendant, } from 'slate';
import { withHistory, } from 'slate-history';
import { Editor, OnChange, RenderElement, RenderLeaf, } from './types';
import { emptyValueRichText, } from './tools';
import { onEditorKeyDown, } from './onEditorKeyDown';
import Leaf from './Leaf';
import Element from './Element';
import EditorLayout from './EditorLayout';

export interface RichTextProps {
  initValue: Descendant[] | null,
  onChange?: OnChange,
  readOnly?: boolean,
  placeholder?: string,
  error?: boolean,
};

const RichText = ({
  initValue,
  onChange,
  readOnly,
  placeholder,
  error,
}: RichTextProps): JSX.Element => {
  const editorRef = useRef<Editor>();
  if (!editorRef.current) editorRef.current = withHistory(withReact(createEditor()));
  const editor = editorRef.current;

  const initialValueEditor = useMemo<Descendant[]>(
    () => {
      if (initValue && initValue.length > 0) return initValue;
      return emptyValueRichText as Descendant[];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderLeaf = useCallback<RenderLeaf>(
    (props) => <Leaf {...props} />,
    [],
  );

  const renderElement = useCallback<RenderElement>(
    (props) => <Element {...props} />,
    [],
  );

  const handleEditorKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (event) => onEditorKeyDown(editor, event),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleEditorChange = useCallback<(value: Descendant[]) => void>(
    (value) => {
      const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
      if (isAstChange && onChange) {
        onChange(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ onChange, ],
  );

  return (
    <Slate
      editor={editor}
      value={initialValueEditor}
      onChange={handleEditorChange}
    >
      <EditorLayout
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onEditorKeyDown={handleEditorKeyDown}
        readOnly={readOnly}
        placeholder={placeholder}
        error={error}
      />
    </Slate>
  );
};

export default RichText;
