import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CustomerVerificationMethod, } from 'Apollo/graphql';
import { FormInputGroupRadio, FormInputPhoneNumber, FormSubmit, } from 'Components/Form';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { UserVerificationOption, useOptionsUserVerification, } from 'Utils/options/useOptionsUserVerification';
import { useEffect, useMemo, } from 'react';
import { useFormContext, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';

interface Props {
  verificationMethods: CustomerVerificationMethod[];
  email: string;
  goBack: () => void;
}

const ChooseVerificationMethodForm = ({ verificationMethods, email, goBack, }: Props): JSX.Element => {
  const { t, } = useTranslation('registrationSeller');
  const userVerificationOptions = useOptionsUserVerification();
  const options = useMemo(
    () => verificationMethods.map((option) => userVerificationOptions.options.find((userVerificationOption) => userVerificationOption.id === option)),
    [verificationMethods, userVerificationOptions.options,],
  );
  const { watch, setValue, } = useFormContext();

  const verificationMethod: UserVerificationOption = watch('verificationMethod');

  useEffect(() => {
    if (verificationMethods.length === 1) {
      setValue('verificationMethod', userVerificationOptions.options[0]);
    }
  }, [verificationMethods, setValue, userVerificationOptions.options,]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
      <Typography variant="h1" sx={{ mb: 1.5, }}>
        {t('registrationSeller:phaseChooseVerificationMethod.title')}
      </Typography>
        <Typography variant="body1" sx={{ mb: 3, }}>
          {t('registrationSeller:phaseChooseVerificationMethod.info')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormInputGroupRadio<UserVerificationOption>
          name="verificationMethod"
          label={t('registrationSeller:phaseRegisterCustomer.form.labelVerificationMethod')}
          options={(options as UserVerificationOption[]) || []}
          getOptionKey={userVerificationOptions.getOptionKey}
          getOptionLabel={userVerificationOptions.getOptionLabel}
          required
        />
      </Grid>
      {verificationMethod?.id === CustomerVerificationMethod.Email && (
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('phaseRegisterCustomer.form.labelEmail')}>{email}</NotEditableTextClassic>
        </Grid>
      )}
      {verificationMethod?.id === CustomerVerificationMethod.Sms && (
        <Grid item xs={12}>
          <FormInputPhoneNumber namePhone="phone" required namePrefix="phonePrefix" label={t('phaseRegisterCustomer.form.labelPhone')} id="nope" />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormSubmit color="primary" variant="contained" size="large" sx={{ width: { xs: '100%', lg: 'unset', }, }}>
          {t('phasePurchase.submit')}
        </FormSubmit>
      </Grid>
      <Grid item xs={12}>
        <Button size="large" variant="outlined" color="primary" sx={{ width: { xs: '100%', lg: 'unset', }, }} onClick={goBack}>
          {t('common:actions.back')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChooseVerificationMethodForm;
