import { memo, } from 'react';
import { useTranslation, } from 'react-i18next';
import InputAutocomplete, { InputAutocompleteProps, } from 'Components/Inputs/InputAutocomplete';
import { ChangeTableFilter, } from 'Utils/types';
import { useTableContext, } from '../hooks/useTableContext';

interface NoContextProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<InputAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'value' | 'onChange'>  {
  name: string,
  value: InputAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['value'],
  onChangeFilter: ChangeTableFilter,
}

const TableFilterAutocompleteNoContext = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  value,
  onChangeFilter,
  name,
  placeholder,
  ...rest
}: NoContextProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  const { t, } = useTranslation();
  const handleChange = (newValue: InputAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['value']) => onChangeFilter(name, newValue);

  return (
    <InputAutocomplete<T, Multiple, DisableClearable, FreeSolo>
      {...rest}
      size="small"
      placeholder={placeholder || t('common:tableFilters.placeholder')}
      value={value}
      onChange={handleChange}
    />
  );
};

const MemoizedInput = memo(TableFilterAutocompleteNoContext) as typeof TableFilterAutocompleteNoContext;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY_ARRAY: any = [];

interface Props<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<InputAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'value' | 'onChange'>  {
  name: string,
}

const TableFilterAutocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  multiple,
  ...rest
}: Props<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  const {
    filter,
    onChangeFilter,
  } = useTableContext(); 

  const value = multiple
    ? filter?.[name] || (EMPTY_ARRAY)
    : filter?.[name] || null;

  return (
    <MemoizedInput<T, Multiple, DisableClearable, FreeSolo>
      {...rest}
      name={name}
      multiple={multiple}
      value={value}
      onChangeFilter={onChangeFilter}
    />
  );
};

export default TableFilterAutocomplete;
