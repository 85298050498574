import { ReactNode, } from 'react';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { styled, } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import ButtonBase from '@mui/material/ButtonBase';
import TableCell, { TableCellProps, } from '@mui/material/TableCell';
import ThreeDotsIcon from 'Utils/svg/ThreeDotsIcon';

const StyledTableCell = styled(TableCell)(() => ({
  position: 'relative',
}));

const StyledButtonBase = styled(ButtonBase)(({ theme, }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0.75, 0.5),
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  width: 45,
  backgroundColor: 'inherit',
  transition: theme.transitions.create([
    'background-color',
  ]),

  '&:hover': {
    backgroundColor: '#f7f7f8',
  },

  '& > .MuiSvgIcon-root': {
    fontSize: '1.75rem',
  },
}));

interface Props extends Omit<TableCellProps, 'children'> {
  children: ReactNode,
};

const TableCellActions = ({
  children,
  ...rest
}: Props): JSX.Element => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'demoPopper', });

  return (
    <StyledTableCell
      {...rest}
      padding="none"
    >
      <StyledButtonBase
        {...bindTrigger(popupState)}
      >
        <ThreeDotsIcon />
      </StyledButtonBase>
      <Menu
        {...bindMenu(popupState)}
        onClick={() => popupState.close()}
      >
        {children}
      </Menu>
    </StyledTableCell>
  );
};

export default TableCellActions;
