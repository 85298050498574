import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { AlzaPurchaseOption, } from 'Utils/options/useOptionsAlzaVoucherPurchase';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({value: yup.object().required(INPUT_FORM_ERRORS.REQUIRED),});

type AlzaPurchaseFormType = {
    value: AlzaPurchaseOption | null
}; 

export const alzaPurchaseDefaultValues:AlzaPurchaseFormType={
    value: null,
};
