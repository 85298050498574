import { Stack, styled, } from '@mui/material';
import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useTranslation, } from 'react-i18next';
import { useMemo, } from 'react';
import { totalNumberOfPointsMinusQuery, totalNumberOfPointsPlusQuery, } from './queries';

const StyledHeader = styled('h3')(({ theme, }) => ({
  margin: 0,
  fontSize: 16,
}));

const TotalNumberOfPoints = ({
  showAddButton,
  
  filtration,
  chartFilter,
  showSlug = true,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');

  const plusQuery = useMemo(
    () =>
      totalNumberOfPointsPlusQuery({
        filters: chartFilter,
      }),
    [chartFilter, ],
  );

  const minusQuery = useMemo(
    () =>
      totalNumberOfPointsMinusQuery({
        filters: chartFilter,
      }),
    [chartFilter, ],
  );
  return (
    <Base
      slug={showSlug ? 'number-of-points' : undefined}
      showAddButton={showAddButton}
      
      filtration={filtration}
      dashboardLayoutItemName={DashboardLayoutItemName.PointsAndTasksTotalNumberOfPoints}
    >
      <Stack alignItems="center">
        <StyledHeader>
          {t('dashboards.pointsAndTasks.totalNumberOfPointsReceived')}
        </StyledHeader>
        <ChartRenderer
          query={plusQuery}
          type={ChartType.number}
        />
      </Stack>
      <Stack alignItems="center">
        <StyledHeader>
          {t('dashboards.pointsAndTasks.totalNumberOfPointsWrittenOff')}
        </StyledHeader>
        <ChartRenderer
          query={minusQuery}
          type={ChartType.number}
        />
      </Stack>
    </Base>
  );
};

export default TotalNumberOfPoints;
