import { useCallback, } from 'react';
import { styled, } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';
import { useTableContext, } from './hooks/useTableContext';

const StyledContainer = styled('div')(({ theme, }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '6px 24px',
  minHeight: 54,
  backgroundColor: '#f7f7f8',
  borderBottomLeftRadius: 'inherit',
  borderBottomRightRadius: 'inherit',

  '& .MuiGrid-container .MuiGrid-item:first-of-type, & .MuiGrid-container .MuiGrid-item:last-child': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  '& .MuiContainer-root': {
    height: '100%',
  },

  '& .MuiPagination-ul': {
    justifyContent: 'center',
  },
}));

const TablePagination = (): JSX.Element => {
  const {
    limit,
    offset,
    count = 1,
    onChangeData,
  } = useTableContext();

  const pageCount = Math.ceil(count / limit) || 1;
  const currentPage = offset === 0 ? 1 : Math.trunc(offset / limit) + 1;

  const handleChange = useCallback(
    (e: React.ChangeEvent<unknown>, newPage: number) => {
      onChangeData('offset', (newPage - 1) * limit);
    },
    [ limit, onChangeData, ],
  );

  return (
    <StyledContainer>
      <Grid container spacing={1} alignItems="center">
        <Grid item sm={3} />
        <Grid item xs={12} md={6}>
          <Pagination
            size="small"
            color="primary"
            count={pageCount}
            shape="rounded"
            page={currentPage}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={3} textAlign="right" />
      </Grid>
    </StyledContainer>
  );
};


export default TablePagination;
