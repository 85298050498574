import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TopPanel from 'Components/AppLayout/TopPanel';

const LoadingScreen = (): JSX.Element => {
  return (
    <>
      <TopPanel />
      <Toolbar />
      <Box
        component="main"
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={10}
        mb={10}
      >
        <CircularProgress
          size={40}
        />
      </Box>
    </>
  );
};

export default LoadingScreen;
