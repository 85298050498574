import Grid from '@mui/material/Grid';
import ExportQrCodes from '../ExportQrCodes';
import InputPos from './InputPos';

interface Props {
  showPosSelector: boolean;
}

const View = ({ showPosSelector, }: Props): JSX.Element => {
  return (
    <Grid container spacing={3} sx={{ pt:'24px', }}>
      {showPosSelector && <InputPos />}
      <ExportQrCodes type="brandAmbassador" />
    </Grid>
  );
};

export default View;
