import shallow from 'zustand/shallow';
import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { NewsArticlesStore, useNewsArticlesStore, } from 'Modules/NewsArticlesAdmin/store/NewsArticlesStore';
import FetchData from './FetchData';

const selectStoreData = (s: NewsArticlesStore) => ({
  isOpen: s.modalDetailNewsArticle.isOpen,
  newsArticle: s.modalDetailNewsArticle.newsArticle,
  onClose: s.closeModalDetailNewsArticle,
});

const ModalDetailNewsArticle = (): JSX.Element => {
  const {
    isOpen,
    newsArticle,
    onClose,
  } = useNewsArticlesStore(selectStoreData, shallow);
  const { t, } = useTranslation('newsArticlesAdmin');

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!newsArticle}
    >
      <ModalDrawerTitle
        onClose={onClose}
      >
        {t('detailNewsArticleModal.title')}
      </ModalDrawerTitle>
      <FetchData
        newsArticle={newsArticle}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalDetailNewsArticle;
