import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import { DatePicker, DatePickerProps, } from '@mui/x-date-pickers/DatePicker';
import CalendarIcon from 'Utils/svg/CalendarIcon';
import InputTextClassic, { InputTextClassicProps, } from './InputTextClassic';

export interface InputDateClassicProps extends Omit<DatePickerProps<Date, Date>, 'renderInput'> {
  placeholder?: string,
  label?: ReactNode,
  helperText?: ReactNode,
  error?: boolean,
  required?: boolean,
  inputProps?: Omit<InputTextClassicProps, 'onChange' | 'value' | 'label' | 'error' | 'helperText'>
}

const InputDateClassic = ({
  value,
  onChange,
  placeholder,
  error,
  required,
  inputFormat,
  helperText,
  ...rest  
}: InputDateClassicProps): JSX.Element => {
  const { t, } = useTranslation();
  const handleChange = (newValue: Date | null) => onChange(newValue);
  const dateInputFormat = t('common:inputDateTime.inputFormat', 'dd.MM.yyyy');

  return (
    <DatePicker
      {...rest}
      inputFormat={inputFormat || dateInputFormat}
      value={value}
      onChange={handleChange}
      components={{
        OpenPickerIcon: CalendarIcon,
      }}
      renderInput={(ip) => (
        <InputTextClassic
          ref={ip.inputRef}
          className={ip.InputProps?.className}
          endAdornment={ip.InputProps?.endAdornment}
          startAdornment={ip.InputProps?.startAdornment}
          InputLabelProps={ip.InputLabelProps}
          inputProps={ip.inputProps}
          id={ip.id}
          disabled={ip.disabled}
          fullWidth={ip.fullWidth}
          label={ip.label}
          error={error}
          required={required}
          helperText={helperText}
          placeholder={placeholder}
          sx={{
            '& .MuiSvgIcon-fontSizeMedium': {
              fontSize: {
                xs: '1.5rem',
                lg: '1.25rem',
              },
            },
            '& .MuiInputAdornment-positionEnd': {
              height: 'unset',
              paddingRight: '8px',
              marginLeft: 0,
              top: {
                xs: 'calc(50% - 12px)',
                lg: 'calc(50% - 12px)',
              },
            },
            '& .MuiIconButton-edgeEnd': {
              marginRight: 0,
              color: 'primary.main',
              width: {
                xs: '28px',
                lg: '24px',
              },
              height: {
                xs: '28px',
                lg: '24px',
              },
            },
          }}
        />
      )}
    />
  );
};

export default InputDateClassic;
