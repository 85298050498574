import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const TrashIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      d="M9.05 5.617h5.74c.269 0 .409.167.381.475-.089.98-.18 1.959-.275 2.938-.241 2.498-.484 4.997-.728 7.495-.087.906-.709 1.473-1.644 1.474H5.48c-.938 0-1.565-.569-1.65-1.47-.3-3.224-.604-6.45-.909-9.68-.023-.242-.047-.485-.06-.728-.017-.345.128-.487.507-.488H9.05v-.016Zm2.454 6.395V8.724c0-.41-.232-.665-.58-.656-.348.01-.572.265-.573.651v6.548c-.001.084.01.168.03.25.031.124.106.234.212.31a.565.565 0 0 0 .369.101c.335-.012.543-.232.544-.6l-.002-3.316Zm-5.009-.008v3.317c0 .37.203.591.536.607.382.018.62-.237.62-.681V8.754c0-.424-.24-.695-.596-.687-.355.008-.561.255-.562.675l.002 3.262ZM8.99 4.507H1.369c-.535 0-.883-.314-.868-.771.016-.43.329-.714.829-.719.85-.008 1.7 0 2.551 0h.957c.208 0 .3-.085.298-.286 0-.319-.01-.638.015-.955.08-1 .834-1.732 1.873-1.754 1.314-.03 2.629-.03 3.943 0 1.05.021 1.808.757 1.887 1.769.021.29.025.58.012.87-.013.27.097.362.373.36 1.14-.01 2.28-.01 3.42 0 .527 0 .851.306.842.76a.722.722 0 0 1-.24.526.77.77 0 0 1-.555.202c-.696.007-1.392 0-2.087 0L8.99 4.507Zm.007-1.494h1.997c.232 0 .336-.087.327-.316-.01-.252.006-.505 0-.757-.013-.297-.15-.435-.448-.436-1.245-.005-2.49-.005-3.733 0-.3 0-.439.138-.452.432-.011.241.007.485 0 .728-.006.243.087.354.348.351.649-.009 1.304-.001 1.96-.002Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default TrashIcon;
