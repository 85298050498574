import { CustomerRegistrationFragment, } from 'Apollo/graphql';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationsAdmin/store/RegistrationStore';
import Logic from './Logic';

const selectModalData = (s: RegistrationStore) => s.modalEraseAllCustomerData;
const selectCloseErase = (s: RegistrationStore) => s.closeModalEraseAllCustomerData;
const selectCloseDetail = (s: RegistrationStore) => s.closeModalDetailRegistration;

const ModalEraseAllCustomerData = (): JSX.Element => {
  const { isOpen, registration, } = useRegistrationStore(selectModalData);
  const onCloseErase = useRegistrationStore(selectCloseErase);
  const onCloseDetail = useRegistrationStore(selectCloseDetail);

  return (
    <ModalDrawer
      onClose={onCloseErase}
      open={isOpen && !!registration}
    >
      <Logic
        customer={registration as CustomerRegistrationFragment}
        onCloseErase={onCloseErase}
        onCloseDetail={onCloseDetail}
      />
    </ModalDrawer>
  );
};

export default ModalEraseAllCustomerData;
