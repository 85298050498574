import AppContainer from 'Components/AppContainer';
import { Grid, Typography, } from '@mui/material';

import TotalNumberOfAvailableGiftCodes from 'Components/Dashboard/ImportedPlGiftCodes/TotalNumberOfAvailableGiftCodes';
import TotalNumberOfSentGiftCodes from 'Components/Dashboard/ImportedPlGiftCodes/TotalNumberOfSentGiftCodes';
import { Claim, DashboardLayoutItemName, useUserDashboardLayoutQuery, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import { useTranslation, } from 'react-i18next';

const ImportedPlGiftCodes = () => {
  const { data, } = useUserDashboardLayoutQuery();
  const authUser = useAuthUser();
  const { t,} = useTranslation('common');
  return (
    <AppContainer>
      <Typography variant="h1" marginBottom={2}>{t('dashboardSidebar.giftCodes')}</Typography>
      <Grid container spacing={2}>
        <Grid xs={12} md={6} container item spacing={2}>
          {authUser?.claimsSet.has(Claim.DashboardGiftCodesTotalNumberOfAvailableGiftCodes) && (
            <Grid item xs={12}>
              <TotalNumberOfAvailableGiftCodes
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.GiftCodesTotalNumberOfAvailableGiftCodes,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid xs={12} md={6} container item spacing={2}>
          {authUser?.claimsSet.has(Claim.DashboardGiftCodesTotalNumberOfSendGiftCodesByChain) && (
            <Grid item xs={12}>
              <TotalNumberOfSentGiftCodes
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.GiftCodesTotalNumberOfSendGiftCodesByChain,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AppContainer>
  );
};

export default ImportedPlGiftCodes;
