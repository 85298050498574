import { Navigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAuthRedirectRoute, useAuthLogin, useAuthUser } from 'Tools/auth';
import {
  initLoginOtherFormValues,
  loginOtherFormValidationSchema,
  mapFormToVariables,
} from './utils';
import View from './View';

const LoginPage = () => {
  const authUser = useAuthUser();

  const methods = useForm({
    defaultValues: initLoginOtherFormValues,
    resolver: yupResolver(loginOtherFormValidationSchema),
    mode: 'onTouched',
  });

  const [login, { error }] = useAuthLogin();

  const handleSubmit = methods.handleSubmit(async (values) => {
    mapFormToVariables(values);
    await login({
      variables: mapFormToVariables(values),
    });
  });

  if (authUser) {
    return <Navigate to={getAuthRedirectRoute(authUser)} />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <View error={error} />
      </form>
    </FormProvider>
  );
};

export default LoginPage;
