import { styled, } from '@mui/material/styles';
import { PhotoContentBlockFragment, } from 'Apollo/graphql';
import ImageAsync from 'Components/ImageAsync';
import { BlockProps, } from '../types';

const ImageContainer = styled('div')(() => ({
  position: 'relative',
  maxWidth: '100%',
  paddingTop: '56.25%',

  '& > img': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const BlockPhoto = ({
  block,
}: BlockProps<PhotoContentBlockFragment>): JSX.Element => {
  return (
    <ImageContainer>
      <ImageAsync src={block.file.publicUrl} />
    </ImageContainer>
  );
};

export default BlockPhoto;
