import { styled, } from '@mui/material/styles';
import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CloseIcon from 'Utils/svg/CloseIcon';
import SidebarMenuMobileLink from 'Components/AppLayout/components/SidebarMenuMobileLink';
import { isPermitted, useAuthLogout, useAuthUser, } from 'Tools/auth';
import SidebarMenuMobileButton from 'Components/AppLayout/components/SidebarMenuMobileButton';
import LogoutColoredIcon from 'Utils/svg/LogoutColoredIcon';
import { Claim, PrizeWheelTypeEnum, usePointsOfSellQuery, usePrizeWheelQuery, } from 'Apollo/graphql';
import { SELLER_MENU_ITEMS, } from './constants';

const CloseButton = styled(Button)(() => ({
  fontSize: 16,
  lineHeight: '22px',
  '& .MuiButton-endIcon .MuiSvgIcon-root': {
    fontSize: 18,
  },
}));

interface Props {
  onClose: () => void;
}

const MobileMenuContent = ({ onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation();
  const authUser = useAuthUser();
  const authLogout = useAuthLogout();

  const { data: prizeWheelXmas2023, } = usePrizeWheelQuery({
    variables: {
      type: PrizeWheelTypeEnum.Xmas_2023,
    },
    fetchPolicy: 'network-only',
    skip: !authUser?.claimsSet.has(Claim.GeneratePrizeWheelXmas_2023),
  });

  const handleClick = () => onClose();
  return (
    <Box p={3}>
      <Box textAlign="right" mb={4}>
        <CloseButton onClick={onClose} variant="text" color="inherit" endIcon={<CloseIcon />}>
          {t('common:actions.close')}
        </CloseButton>
      </Box>
      <Grid container spacing={4}>
        {SELLER_MENU_ITEMS.filter((item) => {
          if (item.id === 'xmasWheel2023') {
            const hasPrize = prizeWheelXmas2023?.prizeWheel !== null;
            const hasPermission = authUser?.claimsSet.has(Claim.GeneratePrizeWheelXmas_2023);

            if (hasPrize || !hasPermission) {
              return false;
            }
          }

          return true;
        }).map((item) => {
          if (!authUser?.pointOfSell?.createsReplacements && authUser?.pointOfSell !== null && item.id === 'replacements') {
            return <div key={item.id} />;
          }
          return (
            <SidebarMenuMobileLink
              key={item.id}
              route={item.route}
              isAllowed={isPermitted(authUser, item.claims, item.flag, item.posFlag)}
              onClick={handleClick}
            >
              {t(item.textKey)}
            </SidebarMenuMobileLink>
          );
        })}
      </Grid>

      <Box mt={3}>
        <SidebarMenuMobileButton icon={<LogoutColoredIcon />} onClick={authLogout.logout} disabled={authLogout.loading}>
          {t('common:sellerSidebar.btnLogout')}
        </SidebarMenuMobileButton>
      </Box>
    </Box>
  );
};

export default MobileMenuContent;
