import { useTranslation, } from 'react-i18next';
import { useState, useCallback, useRef, memo, } from 'react';
import { useDebounce, } from 'Tools/useDebounce';
import InputInt from 'Components/Inputs/InputInt';
import { useTableContext, } from '../hooks/useTableContext';

interface Props {
  name: string,
  value: number | null,
  onChange: (name: string, value: number | null) => void,
  placeholder?: string,
}

const TableFilterIntNoContext = memo(({
  name,
  value,
  onChange,
  placeholder,
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  const [ filterValue, setFilterValue, ] = useState<number | null>(value);
  const refFilterValue = useRef<number | null>(value);

  const cbTimer = useCallback(
    () => onChange(name, refFilterValue.current),
    [ name, refFilterValue, onChange, ],
  );

  const { startTimeout, endTimeout, } = useDebounce(cbTimer, 700);

  const handleChange = (val: number | null) => {
    setFilterValue(val);
    refFilterValue.current = val;
    startTimeout();
  };

  const handleBlur = () => {
    endTimeout();
    onChange(name, refFilterValue.current);
  };

  return (
    <InputInt
      size="small"
      value={filterValue}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder || t('common:tableFilters.placeholder')}
      fullWidth
    />
  );
});

const TableFilterInt = ({
  name,
  ...rest
}: Omit<Props, 'value' | 'onChange'>): JSX.Element => {
  const {
    filter,
    onChangeFilter,
  } = useTableContext(); 

  const value = filter?.[name] || null;

  return (
    <TableFilterIntNoContext
      {...rest}
      name={name}
      value={value}
      onChange={onChangeFilter}
    />
  );
};

export default TableFilterInt;
