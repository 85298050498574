import { useCallback, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { TaskTypeEnum, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';
import { isOptionAvailable, } from './utils';

export type TaskTypeOption = {
  id: TaskTypeEnum,
  labelKey: string,
};

export const TASK_TYPE_OPTIONS: TaskTypeOption[] = [
  {
    id: TaskTypeEnum.Obligatory,
    labelKey: 'tasksAdmin:options.taskType.OBLIGATORY',
  },
  {
    id: TaskTypeEnum.Additional,
    labelKey: 'tasksAdmin:options.taskType.ADDITIONAL',
  },
  {
    id: TaskTypeEnum.Elearning,
    labelKey: 'tasksAdmin:options.taskType.ELEARNING',
  },
];

type GetOptionLabel = (o: TaskTypeOption) => string;
type GetOptionKey = (o: TaskTypeOption) => string;
type FilterOptions = {
  [key in TaskTypeEnum]?: boolean
};
type UseOptionsTaskType = (filterOptions?: FilterOptions) => {
  options: TaskTypeOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<TaskTypeOption>, 
};

export const getOptionTaskTypeById = (id: TaskTypeEnum): TaskTypeOption | null => (
  TASK_TYPE_OPTIONS.find((value)=> value.id === id) || null
);

export const useOptionsTaskType: UseOptionsTaskType = (filterOptions) => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  const options = useMemo<TaskTypeOption[]>(
    () => filterOptions
      ? TASK_TYPE_OPTIONS.filter((o) => isOptionAvailable(filterOptions[o.id]))
      : TASK_TYPE_OPTIONS,
    [ filterOptions, ],
  );

  return {
    options,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
