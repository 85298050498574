import { PurchaseDetailFragment, usePurchaseQuery, } from 'Apollo/graphql';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import View from './View';

interface Props {
  purchase: PurchaseDetailFragment | null,
  onClose: () => void,
};

const FetchData = ({
  purchase,
  onClose,
}: Props): JSX.Element => {
  const preservedPurchase = usePreserveValue(purchase);
  const { loading, error, data, } = usePurchaseQuery({
    variables: {id: preservedPurchase?.id,},});

  if (loading) return (
    <ModalDrawerBodyLoading
      onClose={onClose}
    />
  );
  if (error || !data?.purchase) return (
    <ModalDrawerBodyError
      onClose={onClose}
      error={error}
    />
  );
  return (
    <View
      purchase={data.purchase}
      onClose={onClose}
    />
  );
};

export default FetchData;
