import shallow from 'zustand/shallow';
import { useNavigate, } from 'react-router-dom';
import { FormProvider, useForm, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import AppContainer from 'Components/AppContainer';
import { CreateTaskStore, TypeValues, useCreateTaskStore, } from 'Modules/TasksAdmin/store/createTaskStore';
import { ROUTES, } from 'Utils/constants';
import { typeValidationSchema, } from './validationSchema';
import View from './View';

const selectStoreData = (s: CreateTaskStore) => ({
  completeTypePhase: s.completeTypePhase,
  resetStore: s.resetStore,
  initValues: s.typeValues,
});

const PhaseType = (): JSX.Element => {
  const { completeTypePhase, initValues, } = useCreateTaskStore(selectStoreData, shallow);
  const navigate = useNavigate();

  const methods = useForm<TypeValues>({
    defaultValues: initValues,
    resolver: yupResolver(typeValidationSchema),
    mode: 'onSubmit',
  });

  const handleSubmit = methods.handleSubmit((values) => {
    completeTypePhase(values);
  });

  const handleBack = () => {
    navigate(`/${ROUTES.admin}/${ROUTES.tasks}`);
  };

  return (
    <AppContainer maxWidth="sm">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <View onBack={handleBack} />
        </form>
      </FormProvider>
    </AppContainer>
  );
};

export default PhaseType;
