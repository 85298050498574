import { Claim, } from 'Apollo/graphql';
import { ROUTES, } from 'Utils/constants';
import AdministrationColoredIcon from 'Utils/svg/AdministrationColoredIcon';
import AssortmentColoredIcon from 'Utils/svg/AssortmentColoredIcon';
import NewsColoredIcon from 'Utils/svg/NewsColoredIcon';
import PointsTransactionColoredIcon from 'Utils/svg/PointsTransactionColoredIcon';
import POSColoredIcon from 'Utils/svg/POSColoredIcon';
import PromotionColoredIcon from 'Utils/svg/PromotionColoredIcon';
import RegistrationsColoredIcon from 'Utils/svg/RegistrationsColoredIcon';
import ReplacementsColoredIcon from 'Utils/svg/ReplacementsColoredIcon';
import TasksColoredIcon from 'Utils/svg/TasksColoredIcon';
import UsersColoredIcon from 'Utils/svg/UsersColoredIcon';
import VouchersIcon from 'Utils/svg/VouchersIcon';
import ServiceFeedbackColoredIcon from 'Utils/svg/ServiceFeedbackColoredIcon';
import DashboardIcon from 'Utils/svg/DashboardIcon';
import { MenuItem, } from '../types';

const withAdminPrefix = (route: string): string => `/${ROUTES.admin}/${route}`;

export const ADMIN_MENU_ITEMS: MenuItem[] = [
  {
    id: 'dashboard',
    exact: true,
    icon: <DashboardIcon sx={{ color: 'transparent', }} />,
    route: `/${ROUTES.dashboard}`,
    textKey: 'common:adminSidebar.dashboardView',
    claims: [Claim.DashboardsApp,],
    flag: null,
  },
  {
    id: 'users',
    exact: false,
    icon: <UsersColoredIcon />,
    route: withAdminPrefix(ROUTES.users),
    textKey: 'common:adminSidebar.users',
    claims: [Claim.AdminUsersView,],
    flag: null,
  },
  {
    id: 'pos',
    exact: false,
    icon: <POSColoredIcon />,
    route: withAdminPrefix(ROUTES.pointsOfSell),
    textKey: 'common:adminSidebar.pointsOfSale',
    claims: [Claim.AdminPosView,],
    flag: null,
  },
  {
    id: 'assortment',
    exact: false,
    icon: <AssortmentColoredIcon />,
    route: withAdminPrefix(ROUTES.assortment),
    textKey: 'common:adminSidebar.assortment',
    claims: [Claim.AdminAssortmentView,],
    flag: null,
  },

  {
    id: 'promotions',
    exact: false,
    icon: <PromotionColoredIcon />,
    route: withAdminPrefix(ROUTES.promotions),
    textKey: 'common:adminSidebar.promotions',
    claims: [Claim.AdminPromotionsView,],
    flag: 'FEATURE_FLAG_ENABLE_PROMOTIONS',
  },
  // {
  //   id: 'freeTrial',
  //   exact: false,
  //   icon: <FreeTrialColoredIcon />,
  //   route: `/todo`,
  //   textKey: 'common:adminSidebar.freeTrial',
  //   permission: null,
  // },
  {
    id: 'registrations',
    exact: false,
    icon: <RegistrationsColoredIcon />,
    route: withAdminPrefix(ROUTES.registration),
    textKey: 'common:adminSidebar.registrations',
    claims: [Claim.AdminRegistrationsView,],
    flag: null,
  },
  {
    id: 'replacements',
    exact: false,
    icon: <ReplacementsColoredIcon />,
    route: withAdminPrefix(ROUTES.replacement),
    textKey: 'common:adminSidebar.replacements',
    claims: [Claim.AdminReplacementsView,],
    flag: 'FEATURE_FLAG_ENABLE_REPLACEMENTS',
  },
  {
    id: 'tasks',
    exact: false,
    icon: <TasksColoredIcon />,
    route: withAdminPrefix(ROUTES.tasks),
    textKey: 'common:adminSidebar.tasks',
    claims: [Claim.AdminTasksView,],
    flag: null,
  },
  {
    id: 'pointsTransaction',
    exact: false,
    icon: <PointsTransactionColoredIcon />,
    route: withAdminPrefix(ROUTES.pointsTransaction),
    textKey: 'common:adminSidebar.pointsTransaction',
    claims: [Claim.AdminPointsView,],
    flag: 'FEATURE_FLAG_ENABLE_POINTS',
  },
  {
    id: 'news',
    exact: false,
    icon: <NewsColoredIcon />,
    route: withAdminPrefix(ROUTES.news),
    textKey: 'common:adminSidebar.news',
    claims: [Claim.AdminNewsView,],
    flag: null,
  },
  {
    id: 'vouchers',
    exact: false,
    icon: <VouchersIcon />,
    route: withAdminPrefix(ROUTES.vouchers),
    textKey: 'common:adminSidebar.vouchers',
    claims: [Claim.AdminVouchersView,],
    flag: null,
  },
  // {
  //   id: 'serviceFeedback',
  //   exact: false,
  //   icon: <ServiceFeedbackColoredIcon />,
  //   route: `/todo`,
  //   textKey: 'common:adminSidebar.serviceFeedback',
  //   permission: null,
  // },
  // {
  //   id: 'support',
  //   exact: false,
  //   icon: <SupportColoredIcon />,
  //   route: `/todo`,
  //   textKey: 'common:adminSidebar.support',
  //   permission: null,
  // },
  // {
  //   id: 'contentManagement',
  //   exact: false,
  //   icon: <ContentManagementColoredIcon />,
  //   route: `/todo`,
  //   textKey: 'common:adminSidebar.contentManagement',
  //   permission: null,
  // },
  {
    id: 'sellerApp',
    exact: true,
    icon: <AdministrationColoredIcon />,
    route: '/',
    textKey: 'common:adminSidebar.sellerApp',
    claims: [Claim.SellerApp,],
    flag: null,
  },
];
