import { VoucherQuery, } from 'Apollo/graphql';
import AppContainer from 'Components/AppContainer';
import SodexoView from './SodexoView';

interface Props {
  data: VoucherQuery;
}

const StandartLogic: React.FC<Props> = ({ data, }) => {
  return (
    <AppContainer maxWidth="sm">
      <SodexoView data={data} />
    </AppContainer>
  );
};

export default StandartLogic;
