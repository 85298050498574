import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormInputGroupRadio, FormSubmit, } from 'Components/Form';
import { useTranslation, } from 'react-i18next';
import { TaskMethodOption, useOptionsTaskMethod, } from 'Utils/options/useOptionTaskMethod';

type Props = {
  onBack: () => void;
};

const filterMethods = {
  PHOTO: false,
};

const View = ({
  onBack,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const taskMethodOptions = useOptionsTaskMethod(filterMethods);

  return (
    <>
      <Typography variant="h1" mb={1.5}>
        {t('creation.methodPhase.title')}
      </Typography>
      <Typography mb={5}>
        {t('creation.methodPhase.taskMethodDescription')}
      </Typography>

      <Box mb={4}>
        <FormInputGroupRadio<TaskMethodOption>
          name="method"
          label={t(`creation.methodPhase.chooseMethod`)}
          options={taskMethodOptions.options}
          getOptionKey={taskMethodOptions.getOptionKey}
          getOptionLabel={taskMethodOptions.getOptionLabel}
          required
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item>
          <FormSubmit
            size="large"
            variant="contained"
            color="primary"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
          >
            {t('common:actions.confirm')}
          </FormSubmit>
        </Grid>
        <Grid item>
          <Button
            size="large"
            variant="outlined"
            color="inherit"
            onClick={onBack}
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
          >
            {t('common:actions.cancel')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default View;
