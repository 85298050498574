import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AppContainer from 'Components/AppContainer';
import PlusIcon from 'Utils/svg/PlusIcon';
import { PromotionsStore, usePromotionsStore, } from 'Modules/PromotionsAdmin/store/PromotionsStore';
import { useAuthUser, } from 'Tools/auth/hooks/useAuthUser';
import { Claim, } from 'Apollo/graphql';
import Promotions from './Promotions';

const selectOpenCreateModal = (s: PromotionsStore) => s.openModalCreatePromotion;

const AssortmentsTable = (): JSX.Element => { 
  const { t, } = useTranslation('promotionsAdmin');
  const openCreateModal = usePromotionsStore(selectOpenCreateModal);
  const authUser=useAuthUser();

  return (
    <AppContainer>

      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <Typography
            variant="h1"
          >
            {t('promotions.title')}
          </Typography>
        </Grid>
        {authUser?.claimsSet.has(Claim.ManagePromotion) && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={openCreateModal}
            >
              {t('promotions.bntAdd')}
            </Button>
          </Grid>
        )}
      </Grid>
      
      <Promotions />

    </AppContainer>
  );
};

export default AssortmentsTable;
