import { useAvailableVoucherTypesQuery, useVoucherPurchaseTableQuery, } from 'Apollo/graphql';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import { useVoucherPurchaseStore, VoucherPurchaseStore, } from 'Modules/Vouchers/store/VoucherPurchaseStore';
import shallow from 'zustand/shallow';
import { mapVariablesVoucherPurchase, } from '../utils';
import VoucherPurchasesTableHeader from './PurchasesTableHeader';
import VoucherPurchasesTableRow from './PurchasesTableRow';


const selectStoreData = (s: VoucherPurchaseStore) => ({
  tableVoucherPurchases: s.tableVoucherPurchases,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,  
  onDetail: s.openModalDetailVoucherPurchase,
});

const VoucherPurchases = (): JSX.Element => {
  const {
    onDetail,
    tableVoucherPurchases,
    changeFilter,
    changeSort,
    changeData,
  } = useVoucherPurchaseStore(selectStoreData, shallow);
  const { loading, error, data, } = useVoucherPurchaseTableQuery({
    variables: mapVariablesVoucherPurchase(tableVoucherPurchases),
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={7}
      rows={data?.voucherPurchaseList.data.length || 0}
      loading={loading}
      error={error}
      limit={tableVoucherPurchases.data.limit}
      sortField={tableVoucherPurchases.data.sortField}
      sortOrder={tableVoucherPurchases.data.sortOrder}
      offset={tableVoucherPurchases.data.offset}
      count={data?.voucherPurchaseList.pagination.count}
      filter={tableVoucherPurchases.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <VoucherPurchasesTableHeader />
      <TableBody>
        {(data?.voucherPurchaseList.data || []).map((row) => (
          <VoucherPurchasesTableRow
            key={row.id}
            row={row}
            onDetail={onDetail}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default VoucherPurchases;
