import { useMemo, } from 'react';
import * as yup from 'yup';
import { Descendant, } from 'slate';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { ChainFragment, FileFragment, NewsArticleFragment, NewsArticleInput, } from 'Apollo/graphql';
import { isRichTextEmpty, } from 'Components/RichText/utils';
import { richTextToString, stringToRichText, } from 'Components/RichText/tools';

export type NewsArticleFormValues = {
  title: string,
  description: string,
  image: FileFragment | null,
  video: FileFragment | null,
  text: Descendant[] | null,
  chains: ChainFragment[],
  notificationsVisible: boolean;
};

export const initNewsArticleFormValues: NewsArticleFormValues = {
  title: '',
  description: '',
  image: null,
  video: null,
  text: null,
  chains: [],
  notificationsVisible: false,
};

export const useInitNewsArticleValues = (newsArticle: NewsArticleFragment): NewsArticleFormValues => useMemo<NewsArticleFormValues>(
  () => ({
    title: newsArticle.title,
    description: newsArticle.description,
    image: newsArticle.image || null,
    video: newsArticle.video || null,
    text: stringToRichText(newsArticle.text),
    chains: newsArticle.chains,
    notificationsVisible: newsArticle.notificationsVisible,
  }),
  [ newsArticle, ],
);

export const newsArticleFormValidationSchema = yup.object().shape({
  title: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  description: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(2047, INPUT_FORM_ERRORS.STRING_MAX),
  image: yup.object()
    .nullable()
    .required(INPUT_FORM_ERRORS.REQUIRED),
  video: yup.object()
    .nullable(),
  text: yup.mixed()
    .nullable()
    .test({
      message: INPUT_FORM_ERRORS.REQUIRED,
      test: (value) => !isRichTextEmpty(value as Descendant[] | null),
    }),
  chains: yup.array(),
});

export const mapFormToVariables = (values: NewsArticleFormValues): NewsArticleInput => ({
  title: values.title,
  description: values.description,
  imageFileId: values.image?.id || '',
  videoFileId: values.video?.id || undefined,
  text: richTextToString(values.text),
  chainIds: values?.chains?.map(chain => chain.id) || [],
  notificationsVisible: values.notificationsVisible,
});
