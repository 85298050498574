import { UserFragment, } from 'Apollo/graphql';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { UserStore, useUserStore, } from 'Modules/Users/store/UserStore';
import Logic from './Logic';

const selectModalData = (s: UserStore) => s.modalRemoveUser;
const selectCloseRemove = (s: UserStore) => s.closeModalRemoveUser;
const selectCloseDetail = (s: UserStore) => s.closeModalDetailUser;

const ModalRemoveUser = (): JSX.Element => {
  const { isOpen, user, } = useUserStore(selectModalData);
  const onCloseRemove = useUserStore(selectCloseRemove);
  const onCloseDetail = useUserStore(selectCloseDetail);


  return (
    <ModalDrawer
      onClose={onCloseRemove}
      open={isOpen && !!user}
    >
      <Logic
        user={user as UserFragment}
        onCloseRemove={onCloseRemove}
        onCloseDetail={onCloseDetail}
      />
    </ModalDrawer>
  );
};

export default ModalRemoveUser;
