import { useUserTaxInfoQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import LoadingBox from 'Components/LoadingBox';
import Logic from './Logic';

const FetchData = (): JSX.Element => {
  const { data, loading, error, } = useUserTaxInfoQuery({ fetchPolicy: 'cache-and-network', });

  if (loading) {
    return <LoadingBox />;
  }

  if (error || !data) return <ErrorAlert forceError error={error} />;

  return <Logic data={data.userTaxInfo} />;
};

export default FetchData;
