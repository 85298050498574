import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CreateTaskMutationResult, } from 'Apollo/graphql';
import { AssessmentConfig, MethodValues, TypeValues, } from 'Modules/TasksAdmin/store/createTaskStore';
import { FormSubmit, FormError, } from 'Components/Form';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import AppContainer from 'Components/AppContainer';
import FormInputs from './FormInputs';

interface Props {
  assessmentConfig: AssessmentConfig,
  typeValues: TypeValues,
  methodValues: MethodValues,
  createTaskMutationResult: CreateTaskMutationResult,
  onBack: () => void,
};

const Layout = ({
  assessmentConfig,
  typeValues,
  methodValues,
  createTaskMutationResult,
  onBack,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const type = typeValues.type ? t(typeValues.type.labelKey, typeValues.type.id) : '';
  const method = methodValues.method ? t(methodValues.method.labelKey, methodValues.method.id) : '';

  return (
    <AppContainer maxWidth="md">
      <Typography
        variant="h1"
        sx={{ mb: 1.5, }}
      >
        {t('creation.assessmentPhase.title', { type, method, })}
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 4, }}
      >
        {t('creation.assessmentPhase.info', { type, method, })}
      </Typography>
      
      <Box>
        <FormInputs
          assessmentConfig={assessmentConfig}
        />
      </Box>

      <Grid container spacing={2} sx={{ marginTop: 3,}}>
        <Grid item xs={12} lg="auto">
          <FormSubmit
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
          >
            {t('creation.assessmentPhase.btnSubmit')}
          </FormSubmit>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            onClick={onBack}
          >
            {t('common:actions.back')}
          </Button>
        </Grid>
      </Grid>

      <FormError mt={2} />
      <ErrorAlert
        mt={2}
        error={createTaskMutationResult.error}
      />
    </AppContainer>
  );
};

export default Layout;
