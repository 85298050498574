import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const MenuIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 29 18"
    fill="none"
    {...props}
    style={{ width: "unset", }}
  >
    <g clipPath="url(#menuIcon-a)" fill="currentColor">
      <path d="M14.01 10.007H1.211c-.755 0-1.22-.401-1.204-1.015.016-.614.471-.981 1.204-.981h25.59c.51 0 .914.132 1.125.64a.872.872 0 0 1-.253 1.083 1.527 1.527 0 0 1-.867.267c-4.269.01-8.534.006-12.797.006ZM14.01 18H1.105c-.866 0-1.392-.802-.94-1.487.154-.23.481-.369.754-.491a1.44 1.44 0 0 1 .544-.018h25.441c.644.018 1.091.417 1.1.98 0 .603-.44 1.016-1.108 1.016H14.01ZM13.97 2.014H1.13c-.875 0-1.405-.78-.97-1.468C.31.31.632.166.905.04c.178-.044.363-.051.543-.022h25.639a1.003 1.003 0 0 1 .63 1.701.98.98 0 0 1-.699.285c-1.665.01-3.332 0-4.998 0l-8.05.01Z" />
    </g>
    <defs>
      <clipPath id="menuIcon-a">
        <path d="M0 0h28.009v18H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default MenuIcon;
