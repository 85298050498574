import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const ArrowFullDownIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 18 14"
    fill="none"
    {...props}
    style={{ width: "unset", }}
  >
    <path
      d="M9.032.003h6.625c.764 0 1.28.36 1.467 1.014a1.346 1.346 0 0 1-.157 1.107 7283.463 7283.463 0 0 1-5.053 8.35c-.569.942-1.128 1.885-1.708 2.82-.58.934-1.839.949-2.418 0-1.388-2.27-2.76-4.548-4.135-6.825-.862-1.425-1.725-2.85-2.586-4.276-.293-.48-.337-.975-.062-1.467C1.279.234 1.738 0 2.307 0l6.725.003Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ArrowFullDownIcon;
