import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { FileFragment, ImportUsersMutationVariables, } from 'Apollo/graphql';

export type ImportUsersFormValues = {
  file: FileFragment | null,
};
type ImportBadFormDataFieldMapper = {
  [key in keyof ImportUsersMutationVariables]: undefined | keyof ImportUsersFormValues
};

export const defaultImportUsersFormValues: ImportUsersFormValues = {
  file: null,
};

export const importUsersValidationSchema = yup.object().shape({
  file: yup.object()
    .nullable()
    .required(INPUT_FORM_ERRORS.REQUIRED),
});

export const importBadFormDataFieldMapper: ImportBadFormDataFieldMapper = {
  fileId: 'file',
};
