import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationsAdmin/store/RegistrationStore';
import FetchData from './FetchData';

const selectModalData = (s: RegistrationStore) => s.modalExportRegistration;
const selectClose = (s: RegistrationStore) => s.closeModalExportRegistration;

const ModalExportRegistrations = (): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const { isOpen, } = useRegistrationStore(selectModalData);
  const onClose = useRegistrationStore(selectClose);

  return (
    <ModalDrawer onClose={onClose} open={isOpen}>
      <ModalDrawerTitle onClose={onClose}>{t(`common:defaultExport.title`, {name: t('registrations.title'),})}</ModalDrawerTitle>
      <FetchData onClose={onClose} />
    </ModalDrawer>
  );
};

export default ModalExportRegistrations;
