import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const PromotionColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M11.764 22.997a10.975 10.975 0 0 1-10.76-11.238C1.17 5.47 6.33.723 12.514 1.013c6.07.275 10.742 5.307 10.474 11.496a10.963 10.963 0 0 1-11.224 10.488Zm.529-1.38a9.623 9.623 0 1 0-.733-19.232c-5.405.276-9.5 4.816-9.167 10.184a9.599 9.599 0 0 0 9.9 9.048Z"
      fill="#000"
    />
    <path
      d="M12.691 14.83a2.295 2.295 0 1 1 4.588-.112 2.295 2.295 0 0 1-4.588.112Zm1.38-.014a.927.927 0 0 0 .906.928.948.948 0 0 0 .928-.912.93.93 0 0 0-.913-.922.913.913 0 0 0-.923.906h.002ZM11.312 8.866a2.294 2.294 0 1 1-4.588 0 2.294 2.294 0 0 1 4.588 0Zm-2.282.916a.916.916 0 0 0 .905-.922.931.931 0 0 0-.924-.912.945.945 0 0 0-.912.918.927.927 0 0 0 .931.91v.006ZM8.045 16.559a.725.725 0 0 1-.675-.422.69.69 0 0 1 .127-.828c.437-.45.884-.894 1.328-1.336l6.442-6.453c.198-.199.397-.398.713-.389a.727.727 0 0 1 .652.422.671.671 0 0 1-.039.706c-.07.102-.15.197-.24.283-2.55 2.555-5.102 5.108-7.656 7.661-.179.183-.365.36-.652.356Z"
      fill="#D47E5F"
    />
  </SvgIcon>
);

export default PromotionColoredIcon;
