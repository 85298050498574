import { INPUT_FORM_ERRORS, } from 'Components/Form';
import * as yup from 'yup';
// import { PurchasedProductsForGqlDbOptionFragment, } from 'Apollo/graphql';
import { MarketEnum, } from 'Apollo/graphql';
import { REGEX_PHONE_NUMBER, } from 'Utils/constants';
import type { FormConfig, } from './types';

export const formValidationSchema = (formConfig: FormConfig,phoneIsDuplicate: boolean | undefined) =>
  yup.object().shape({
    ...(formConfig.enableTitle && {
      title: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
    }),
    firstName: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
    lastName: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
    dateOfBirth: yup
      .date()
      .required(INPUT_FORM_ERRORS.REQUIRED)
      .typeError(INPUT_FORM_ERRORS.DATE_FORMAT)
      .test('dateOfBirth', INPUT_FORM_ERRORS.DATE_MIN_18_YEARS, (dateOfBirth) => {
        const cutoff = new Date();
        cutoff.setFullYear(cutoff.getFullYear() - 18);
        return dateOfBirth ? dateOfBirth <= cutoff : false;
      })
      .nullable(),
    phone: yup
      .string()
      .required(INPUT_FORM_ERRORS.REQUIRED)
      .max(25, INPUT_FORM_ERRORS.STRING_MAX)
      .matches(REGEX_PHONE_NUMBER, INPUT_FORM_ERRORS.PHONE)
      .test('phone', (value, { createError, path, }) => {
        if (!formConfig.enablePhoneDuplicityVerification) return true;
        if (phoneIsDuplicate)           return createError({
          message: INPUT_FORM_ERRORS.ALREADY_EXIST,
        });
        return true;
      }),
    phonePrefix: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
    purchasedProducts: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
    previouslyOwnedDevices: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
    acceptedTermsAndConditions: yup.boolean().oneOf([true,], INPUT_FORM_ERRORS.REQUIRED),
    acceptedPulzeCare: yup.boolean().test('acceptedPulzeCare', INPUT_FORM_ERRORS.REQUIRED, (acceptedPulzeCare) => {
      if (formConfig.currentMarket !== MarketEnum.Cz) {
        return acceptedPulzeCare || false;
      }
      return true;
    }),
    ...(formConfig.enablePulzeOn && { acceptedPulzeOn: yup.boolean().test('enablePulzeOn', INPUT_FORM_ERRORS.REQUIRED, (enablePulzeOn) => {
      if (formConfig.currentMarket !== MarketEnum.Cz) {
        return enablePulzeOn || false;
      }
      return true;
    }),}),
    ...((formConfig.verificationMethods || []).length > 1 && {
      verificationMethod: yup.object()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
  });
