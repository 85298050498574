import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { PointsTransactionStore, UsePointsTransactionStore, } from 'Modules/PointsTransactionAdmin/store/PointsTransactionStore';
import FetchData from './FetchData';

const selectModalData = (s: PointsTransactionStore) => s.modalEditPointsTransaction;
const selectClose = (s: PointsTransactionStore) => s.closeModalEditPointsTransaction;

const ModalEditPointsTransaction = (): JSX.Element => {
  const { isOpen, pointsTransaction, } = UsePointsTransactionStore(selectModalData);
  const onClose = UsePointsTransactionStore(selectClose);
  const { t, } = useTranslation('pointsTransactionAdmin');

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!pointsTransaction}
    >
      <ModalDrawerTitle
        onClose={onClose}
      >
        {t('editPointsTransactionModal.title')}
      </ModalDrawerTitle>
      <FetchData
        pointsTransaction={pointsTransaction}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalEditPointsTransaction;
