import { yupResolver, } from '@hookform/resolvers/yup';
import { PromotionFragment, PromotionsListDocument, useUpdatePromotionMutation, } from 'Apollo/graphql';
import { useSnackbar, } from 'notistack';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import moment from 'moment';
import { mapFormToVariables, promotionFormValidationSchema, useInitPromotionValues, } from '../../utils/promotionForm';
import View from './View';

interface Props {
  promotion: PromotionFragment;
  onClose: () => void;
}

const Logic = ({ promotion, onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation('promotionsAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const authUser = useAuthUser();
  const initValues = useInitPromotionValues(promotion, authUser);
  const methods = useForm({
    defaultValues: initValues,
    resolver: yupResolver(promotionFormValidationSchema),
    mode: 'onTouched',
  });
  const formValues = methods.watch();
  const [editMutation, editMutationResult,] = useUpdatePromotionMutation({
    refetchQueries: [
      {
        query: PromotionsListDocument,
      },
    ],
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('editPromotionModal.notifEditedTitle') as string,
        message: t('editPromotionModal.notifEditedMessage', { promotionId: response.updatePromotion.id, }),
        variant: 'common',
      });
      onClose();
    },
    onError: () => {
      // TODO: merge form errors
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    const validFrom = new Date(moment(values.validFrom).utc(true).set({ hour: 0, minute: 0, second: 0, millisecond: 0, }).format());
    const validTo = new Date(moment(values.validTo).utc(true).set({ hour: 0, minute: 0, second: 0, millisecond: 0, }).format());
    await editMutation({
      variables: {
        id: promotion.id,
        promotionInput: mapFormToVariables({ ...values, validFrom, validTo, assortmentCategory: formValues.assortmentCategory, }, authUser),
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View promotion={promotion} editMutationResult={editMutationResult} onClose={onClose} />
      </form>
    </FormProvider>
  );
};

export default Logic;
