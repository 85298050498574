import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { UserStore, useUserStore, } from 'Modules/Users/store/UserStore';
import FetchData from './FetchData';

const selectModalData = (s: UserStore) => s.modalEditUser;
const selectClose = (s: UserStore) => s.closeModalEditUser;

const ModalEditUser = (): JSX.Element => {
  const { t, } = useTranslation('users');
  const { isOpen, user, } = useUserStore(selectModalData);
  const onClose = useUserStore(selectClose);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!user}
    >
      <ModalDrawerTitle
        onClose={onClose}
      >
        {t('editUserModal.title')}
      </ModalDrawerTitle>
      <FetchData
        user={user}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalEditUser;
