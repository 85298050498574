import * as yup from 'yup';
import { ErrorCodeEnum, ResetPasswordInput, } from 'Apollo/graphql';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { ApolloCustomGqlErrors, } from 'Components/Errors/types';

export type NewPasswordFormValues = {
  newPassword: string,
  confirmPassword: string,
};

export const initNewPasswordFormValues: NewPasswordFormValues = {
  newPassword: '',
  confirmPassword: '',
};

export const newPasswordFormValidationSchema = yup.object().shape({
  newPassword: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED),
  confirmPassword: yup.string()
    .oneOf([yup.ref('newPassword'), null, ], INPUT_FORM_ERRORS.PASSWORD_MATCH)
    .required(INPUT_FORM_ERRORS.REQUIRED),
});

export const mapFormToVariables = (values: NewPasswordFormValues, resetCode?: string): ResetPasswordInput => ({
  newPassword: values.newPassword,
  resetCode: resetCode || '',
});

export const CUSTOM_RESET_PASSWORD_GQL_ERRORS: ApolloCustomGqlErrors = {
  [ErrorCodeEnum.NotFound]: (gqlError, t) => ({
    title: null,
    text: t('auth:resetPassword.resetForm.errorLinkAlreadyExpired'),
  }),
};
