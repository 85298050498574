import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const VouchersIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 18"
    fill="none"
    {...props}
  >
    <path
      d="M7.098 12.67a1.577 1.577 0 1 1 3.153-.078 1.577 1.577 0 0 1-3.153.077Zm.947-.01a.636.636 0 0 0 .623.636.65.65 0 0 0 .637-.626.638.638 0 0 0-.627-.632.625.625 0 0 0-.634.622h.001ZM6.15 8.575a1.575 1.575 0 1 1-3.15 0 1.575 1.575 0 0 1 3.15 0Zm-1.566.63a.629.629 0 0 0 .621-.635.64.64 0 0 0-.635-.626.649.649 0 0 0-.626.631.637.637 0 0 0 .64.624v.005ZM3.908 13.857a.498.498 0 0 1-.463-.29.473.473 0 0 1 .087-.568c.3-.309.606-.614.911-.917l4.423-4.431c.137-.137.273-.273.49-.267a.5.5 0 0 1 .448.29.46.46 0 0 1-.027.485c-.048.07-.103.134-.164.194-1.751 1.754-3.504 3.507-5.258 5.26-.122.126-.25.248-.447.244Z"
      fill="#D47E5F"
    />
    <path
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      d="M16.25 4.25h-6.5M16.25 7.25h-3.5"
      fill="none"
    />
    <rect
      x={0.9}
      y={1.4}
      width={18.2}
      height={15.2}
      rx={1.1}
      stroke="#000"
      strokeWidth={1.8}
      fill="none"
    />
  </SvgIcon>
);

export default VouchersIcon;
