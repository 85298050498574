import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const PromotionNotificationIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon viewBox="0 0 74 74" fill="none" {...props}>
    <path
      d="M36.211 73.6581C31.3932 73.5638 26.6415 72.5189 22.2286 70.5834C17.8157 68.6479 13.8286 65.86 10.496 62.3795C7.16334 58.8991 4.55091 54.7947 2.80866 50.302C1.06641 45.8093 0.228628 41.0168 0.343388 36.1995C0.895197 15.2353 18.0932 -0.587789 38.7079 0.377877C58.9409 1.29756 74.5157 18.068 73.6236 38.701C73.2108 48.2676 69.0597 57.2899 62.0631 63.8273C55.0665 70.3647 45.7835 73.8947 36.211 73.6581ZM37.9722 69.0597C42.2138 68.9461 46.3907 67.9922 50.261 66.2531C54.1314 64.5141 57.6183 62.0245 60.5198 58.9284C63.4213 55.8324 65.6798 52.1916 67.1644 48.2166C68.649 44.2417 69.3303 40.0118 69.1688 35.7717C69.0073 31.5317 68.0062 27.3658 66.2235 23.5154C64.4409 19.6649 61.912 16.2064 58.7834 13.34C55.6548 10.4737 51.9886 8.25653 47.9972 6.81693C44.0057 5.37732 39.7684 4.74388 35.5304 4.95328C17.5139 5.87297 3.86117 21.0063 4.97399 38.8987C5.44848 47.2387 9.16581 55.0625 15.3317 60.6982C21.4977 66.334 29.6232 69.3349 37.9722 69.0597Z"
      fill="#D47E5F"
    />
    <path
      d="M39.3002 46.4331C39.2751 45.4128 39.4545 44.3977 39.8277 43.4478C40.2009 42.4978 40.7603 41.6321 41.4731 40.9016C42.1859 40.1711 43.0376 39.5906 43.9782 39.1943C44.9187 38.7979 45.929 38.5938 46.9496 38.5938C47.9702 38.5938 48.9806 38.7979 49.9211 39.1943C50.8616 39.5906 51.7133 40.1711 52.4261 40.9016C53.1389 41.6321 53.6984 42.4978 54.0716 43.4478C54.4447 44.3977 54.6241 45.4128 54.5991 46.4331C54.5501 48.4294 53.7227 50.3274 52.2935 51.722C50.8643 53.1166 48.9465 53.8973 46.9496 53.8973C44.9527 53.8973 43.0349 53.1166 41.6057 51.722C40.1765 50.3274 39.3491 48.4294 39.3002 46.4331ZM43.8986 46.3871C43.8984 47.1948 44.2144 47.9705 44.7791 48.5481C45.3438 49.1257 46.1122 49.4592 46.9197 49.4772C47.7261 49.4614 48.4958 49.1377 49.0712 48.5726C49.6466 48.0074 49.9841 47.2436 50.0145 46.4377C50.0074 45.6299 49.6853 44.8569 49.1167 44.2831C48.5481 43.7094 47.7779 43.3803 46.9703 43.3659C46.5698 43.3617 46.1723 43.4365 45.8008 43.5862C45.4292 43.7358 45.0908 43.9573 44.805 44.238C44.5193 44.5186 44.2917 44.853 44.1353 45.2217C43.979 45.5905 43.897 45.9865 43.894 46.3871H43.8986Z"
      fill="#D47E5F"
    />
    <path
      d="M34.7025 26.5534C34.7025 28.0647 34.2547 29.5421 33.4156 30.7991C32.5766 32.056 31.3839 33.0362 29.9881 33.6158C28.5924 34.1954 27.0562 34.3484 25.5736 34.0556C24.0909 33.7627 22.7283 33.0372 21.6577 31.9704C20.5872 30.9037 19.8567 29.5437 19.5585 28.0621C19.2603 26.5806 19.4079 25.0438 19.9824 23.646C20.557 22.2482 21.5328 21.052 22.7868 20.2084C24.0407 19.3648 25.5165 18.9117 27.0278 18.9063C29.0592 18.9038 31.0086 19.7078 32.4476 21.1417C33.8866 22.5755 34.6976 24.522 34.7025 26.5534ZM27.0967 29.6068C27.9042 29.5971 28.6748 29.2679 29.2402 28.6914C29.8055 28.1149 30.1194 27.3379 30.1133 26.5304C30.0966 25.7224 29.7653 24.9527 29.19 24.3851C28.6146 23.8175 27.8406 23.4967 27.0324 23.4909C26.2311 23.5187 25.4706 23.8509 24.9059 24.42C24.3411 24.989 24.0146 25.752 23.9928 26.5534C24.0085 27.365 24.3429 28.1377 24.9237 28.7048C25.5045 29.2718 26.2851 29.5875 27.0967 29.5838V29.6068Z"
      fill="#D47E5F"
    />
    <path
      d="M23.8211 52.2003C23.3498 52.2107 22.8857 52.0829 22.4859 51.8327C22.0861 51.5826 21.7681 51.2208 21.5709 50.7921C21.3295 50.3497 21.2402 49.8401 21.3167 49.3419C21.3933 48.8436 21.6314 48.3844 21.9944 48.0352C23.4505 46.5341 24.9396 45.053 26.4189 43.5817C33.5756 36.4112 40.7335 29.2406 47.8925 22.07C48.5543 21.4073 49.2161 20.7446 50.2685 20.7744C50.7264 20.7792 51.1735 20.9137 51.5583 21.1624C51.943 21.411 52.2496 21.7636 52.4426 22.1794C52.6429 22.545 52.737 22.9594 52.7142 23.3758C52.6914 23.7922 52.5526 24.1938 52.3136 24.5353C52.0789 24.8742 51.8118 25.1894 51.5161 25.4764C43.0158 33.9923 34.5077 42.5048 25.992 51.0141C25.3963 51.6238 24.7775 52.2169 23.8211 52.2003Z"
      fill="#D47E5F"
    />
    <circle cx="36.9997" cy="37.0026" r="33.6667" stroke="#D47E5F" strokeWidth="6" fill='none'/>
  </SvgIcon>
);

export default PromotionNotificationIcon;
