import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useSnackbar, } from 'notistack';
import { FileFragment, TaskCompletionReviewFragment, useReviewTaskCompletionMutation, } from 'Apollo/graphql';
import { getOptionTaskCompletionById, } from 'Utils/options/useOptionsTaskCompletionStatus';
import { reviewTaskValidationSchema, ReviewTaskValues, } from './utils';
import Layout from './Layout';

interface Props {
  taskCompletion: TaskCompletionReviewFragment,
  taskCompletionFiles: FileFragment[],
};

const Logic = ({
  taskCompletion,
  taskCompletionFiles,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  
  const methods = useForm<ReviewTaskValues>({
    defaultValues: { status: getOptionTaskCompletionById(taskCompletion.status), },
    resolver: yupResolver(reviewTaskValidationSchema),
    mode: 'onTouched',
  });

  const [ reviewMutation, reviewMutationResult, ] = useReviewTaskCompletionMutation({
    onCompleted: (response) => {
      methods.setValue('status', getOptionTaskCompletionById(response.reviewTaskCompletion.status));
      enqueueSnackbar({
        title: t('review.notifSuccessTitle') as string,
        message: t('review.notifSuccessMessage', {
          status: t(getOptionTaskCompletionById(response.reviewTaskCompletion.status)?.labelKey || response.reviewTaskCompletion.status),
        }),
        variant: 'common',
      });
    },
    onError: () => {
      enqueueSnackbar({
        title: t('review.notifErrorTitle') as string,
        message: t('review.notifErrorMessage'),
        variant: 'common',
      });
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await reviewMutation({
      variables: {
        status: values.status!.id,
        taskCompletionId: taskCompletion.id,
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Layout
          taskCompletion={taskCompletion}
          taskCompletionFiles={taskCompletionFiles}
          reviewMutationResult={reviewMutationResult}
        />
      </form>
    </FormProvider>
  );
};

export default Logic;
