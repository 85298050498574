import { yupResolver, } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Palette, useTheme, } from '@mui/material/styles';
import {
  Claim,
  ReplacementDetailFragment,
  ReplacementStatus,
  ReplacementTableFragment,
  ReplacementsDocument,
  useUpdateReplacementMutation,
} from 'Apollo/graphql';
import { FormInputAutocomplete, } from 'Components/Form';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import LinkButton from 'Components/LinkButton';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ProductCard from 'Components/ProductCard';
import { mapVariables, } from 'Modules/ReplacementAdmin/pages/ReplacementAdminPage/ReplacementTable/utils';
import { useReplacementStore, } from 'Modules/ReplacementAdmin/store/ReplacementStore';
import { useAuthUser, } from 'Tools/auth';
import { useReplacementStatus, } from 'Tools/useReplacementStatus';
import { ReplacementStatusOption, useOptionsReplacementStatus, } from 'Utils/options/useOptionsReplacementStatus';
import { useSnackbar, } from 'notistack';
import { Fragment, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { formatDate, replacementFormValidationSchema, useInitReplacementValues, } from '../../utils/replacementForm';

interface Props {
  replacement: ReplacementDetailFragment;
  onClose: () => void;
  onOpenRemove: (replacement: ReplacementDetailFragment | ReplacementTableFragment) => void;
}
interface CustomPalette extends Palette {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const View = ({ replacement, onClose, onOpenRemove, }: Props): JSX.Element => {
  const theme = useTheme();

  const { t, } = useTranslation('replacementAdmin');
  const { enqueueSnackbar, } = useSnackbar();

  const user = useAuthUser();
  const chip = useReplacementStatus(replacement.status);

  const products =
    replacement.status !== ReplacementStatus.Completed ? replacement.replacedAssortments : replacement.replacedAssortmentsWhenCompleted;

  const customer = replacement.customer !== null ? replacement.customer : replacement.replacementRegistration;

  const handleOpenRemove = () => onOpenRemove(replacement);

  const showSensitiveData = user?.claimsSet.has(Claim.ReadSensitiveData);
  const replacementStatusOptions = useOptionsReplacementStatus();
  const initValues = useInitReplacementValues(replacement);

  const methods = useForm({
    defaultValues: initValues,
    resolver: yupResolver(replacementFormValidationSchema),
    mode: 'onTouched',
  });

  const [editMutation,] = useUpdateReplacementMutation({
    refetchQueries: [
      {
        query: ReplacementsDocument,
        variables: mapVariables(useReplacementStore.getState().tableReplacement),
      },
    ],
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('editReplacementModal.notifEditedTitle') as string,
        message: t('editReplacementModal.notifEditedMessage', { replacementId: response.updateReplacement?.id, }),
        variant: 'common',
      });
      onClose();
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await editMutation({
      variables: {
        replacementUpdateInput: {
          id: replacement.id,
          status: values.status?.id as ReplacementStatus,
        },
      },
    });
    onClose();
  });
  const doneButtonType = user?.claimsSet.has(Claim.UpdateReplacement) ? 'submit' : 'button';

  return (
    <ModalDrawerBody>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <NotEditableTextClassic label={t('replacement.labelReplacementId')}>{replacement.id}</NotEditableTextClassic>
            </Grid>
            <Grid item xs={12}>
              <NotEditableTextClassic label={t('replacement.labelUsername')}>{replacement.author.username}</NotEditableTextClassic>
            </Grid>
            <Grid item xs={12}>
              <NotEditableTextClassic label={t('replacement.labelCreatedAt')}>
                {t('common:date.fullDate', { date: formatDate(replacement.createdAt), })}
              </NotEditableTextClassic>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            {user?.claimsSet.has(Claim.UpdateReplacement) ? (
              <Grid item xs={12}>
                <FormInputAutocomplete<ReplacementStatusOption>
                  name="status"
                  getOptionLabel={replacementStatusOptions.getOptionLabel}
                  isOptionEqualToValue={replacementStatusOptions.isOptionEqualToValue}
                  options={replacementStatusOptions.options}
                  label={t('replacement.labelStatus')}
                  disableClearable
                  overwriteStyles={{
                    '& .MuiInputBase-root': {
                      background: (theme.palette as CustomPalette)[chip.color || 'default']?.main,
                      border: 0,
                      color: theme.palette.common.white,
                      width: theme.spacing(17),
                      height: theme.spacing(4),
                    },
                    '& svg': {
                      color: theme.palette.common.white,
                      height: theme.spacing(1.25),
                      width: theme.spacing(1.25),
                    },
                    '& .MuiInputBase-root .MuiInputBase-input': {
                      padding: `0px ${theme.spacing(1)} 0px ${theme.spacing(1)}`,
                    },
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <NotEditableTextClassic label={t('replacement.labelStatus')}>
                  <Chip label={chip.label} color={chip.color} />
                </NotEditableTextClassic>
              </Grid>
            )}

            <Grid item xs={12}>
              <NotEditableTextClassic label={t('replacement.labelCompletor')}>{replacement.completor?.username || '-'}</NotEditableTextClassic>
            </Grid>

            <Grid item xs={12}>
              <NotEditableTextClassic label={t('replacement.labelPosId')}>{replacement.posId || '-'}</NotEditableTextClassic>
            </Grid>

            <Grid item xs={12}>
              <NotEditableTextClassic label={t('replacement.labelCompletedAt')}>
                {t('common:date.fullDate', { date: formatDate(replacement.completedAt), }) || '-'}
              </NotEditableTextClassic>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            {showSensitiveData && (
              <Grid item xs={12}>
                <NotEditableTextClassic label={t('replacement.labelCustomer')}>
                  <div>{`${customer?.firstName || ''} ${customer?.lastName || ''}`}</div>
                  <div>{customer?.email || ''}</div>
                  <div>{`${customer?.phonePrefix || ''} ${customer?.phoneNumber || ''}`}</div>
                </NotEditableTextClassic>
              </Grid>
            )}

            <Grid item xs={12}>
              <NotEditableTextClassic label={t('replacement.labelCategory')}>{replacement.category.name || '-'}</NotEditableTextClassic>
            </Grid>

            <Grid item xs={12}>
              <NotEditableTextClassic label={t('replacement.labelProducts')}>
                {products.map((product) => (
                  <Fragment key={product.assortmentId}>
                    {product.count > 0 && (
                      <ProductCard title={product.assortment.name} imageSrc={product.assortment.image?.publicUrl} count={product.count} />
                    )}
                  </Fragment>
                ))}
              </NotEditableTextClassic>
            </Grid>

            <Grid item xs={12}>
              <NotEditableTextClassic label={t('replacement.labelReason')}>{replacement.reason || '-'}</NotEditableTextClassic>
            </Grid>
            <Grid item xs={12}>
              <NotEditableTextClassic label={t('createReplacementModal.phaseCreateTicket.labelNote')}>
                {replacement.note || '-'}
              </NotEditableTextClassic>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <Box mt={4}>
        <Grid container spacing={3}>
          {user?.claimsSet.has(Claim.DeleteReplacement) && (
            <Grid item xs={12}>
              <LinkButton type="button" variant="body1" onClick={handleOpenRemove} color="secondary" disabled={false}>
                {t('replacement.btnRemove')}
              </LinkButton>
            </Grid>
          )}
          <Grid item>
            <Button size="large" variant="outlined" color="inherit" type={doneButtonType} onClick={onClose}>
              {t('common:actions.done')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalDrawerBody>
  );
};

export default View;
