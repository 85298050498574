import { useTranslation, } from 'react-i18next';
import { Link as RouterLink, } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ForgottenPasswordMutationResult, } from 'Apollo/graphql';
import { FormInputText, FormSubmit, } from 'Components/Form';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { ROUTES, } from 'Utils/constants';

interface Props {
  forgotMutationResult: ForgottenPasswordMutationResult,
}

const FormView = ({
  forgotMutationResult,
}: Props): JSX.Element => {
  const { t, } = useTranslation('auth');

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        marginBottom={3}
      >
        {t('forgottenPassword.forgottenForm.title')}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 5,
        }}
      >
        {t('forgottenPassword.forgottenForm.info')}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormInputText
            name="email"
            autoComplete="email"
            label={t('forgottenPassword.forgottenForm.labelEmail')}
            required
          />
        </Grid>
      </Grid>

      <Box mt={4}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} lg="auto">
            <FormSubmit
              size="large"
              sx={{ width: { xs: '100%', lg: 'unset', }, }}
              disabled={forgotMutationResult.loading}
            >
              {t('forgottenPassword.forgottenForm.btnSubmit')}
            </FormSubmit>
          </Grid>
          <Grid item xs={12} lg="auto">
            <Button
              component={RouterLink}
              to={`/${ROUTES.login}`}
              size="large"
              sx={{ width: { xs: '100%', lg: 'unset', }, }}
              variant="outlined"
              color="primary"
            >
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ErrorAlert
        sx={{ mt: 2, }}
        error={forgotMutationResult.error}
      />
    </>
  );
};

export default FormView;
