import { styled, } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import { SIDEBAR_WIDTH, } from 'Utils/constants';

const Sidebar = styled(Drawer)(({ theme, }) => ({
  position: 'relative',
  width: SIDEBAR_WIDTH,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: SIDEBAR_WIDTH,
    boxSizing: 'border-box',
  },
}));

export default Sidebar;
