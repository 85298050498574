import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { UserStore, useUserStore, } from 'Modules/Users/store/UserStore';
import Layout from './Layout';

const selectModalData = (s: UserStore) => s.modalImportUsers;
const selectClose = (s: UserStore) => s.closeModalImportUsers;

const ModalImportUsers = (): JSX.Element => {
  const { isOpen, } = useUserStore(selectModalData);
  const onClose = useUserStore(selectClose);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen}
    >
      <Layout
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalImportUsers;
