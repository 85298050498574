import { ReactNode, } from 'react';
import Grid from '@mui/material/Grid';
import { ContentBlockTypeEnum, PhotoContentBlockFragment, VideoContentBlockFragment, TextContentBlockFragment, } from 'Apollo/graphql';
import { BlockProps, } from './types';
import BlockText from './blocks/BlockText';
import BlockPhoto from './blocks/BlockPhoto';
import BlockVideo from './blocks/BlockVideo';

type BlockTypes = {
  [key in ContentBlockTypeEnum]: (props: BlockProps) => ReactNode | null
};

const BLOCK_TYPES: BlockTypes = {
  [ContentBlockTypeEnum.Text]: (props) => <BlockText {...props as BlockProps<TextContentBlockFragment>} />,
  [ContentBlockTypeEnum.Photo]: (props) => <BlockPhoto {...props as BlockProps<PhotoContentBlockFragment>} />,
  [ContentBlockTypeEnum.Video]: (props) => <BlockVideo {...props as BlockProps<VideoContentBlockFragment>} />,
};

const Blocks = (props: BlockProps): JSX.Element | null => {
  const { block: { type, }, } = props;

  if (type && Object.prototype.hasOwnProperty.call(BLOCK_TYPES, type)) return (
    <Grid item xs={12}>{BLOCK_TYPES[type](props)}</Grid>
  );
  return null;
};

export default Blocks;
