import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const LogoIconCz = (props: SvgIconProps): JSX.Element => {
    return (
      <SvgIcon viewBox="0 0 146 50" fill="none" {...props} style={{ width: 'unset', }}>
        <path fill="url(#a)" d="M0 0h146v50H0z" />
        <defs>
          <pattern id="a" patternContentUnits="objectBoundingBox" width={1} height={1}>
            <use xlinkHref="#b" transform="matrix(.00242 0 0 .00705 0 .006)" />
          </pattern>
          <image id="b" width={414} height={140} href="/images/logos/dev_logo.png" />
        </defs>
      </SvgIcon>
    );
  };

export default LogoIconCz;
