import shallow from 'zustand/shallow';
import { usePointsTransactionsQuery } from 'Apollo/graphql';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import { PointsTransactionStore, UsePointsTransactionStore } from 'Modules/PointsTransactionAdmin/store/PointsTransactionStore';
import PointsTransactionsTableRow from './PointsTransactionTableRow';
import PointsTransactionsTableHeader from './PointsTransactionTableHeader';
import { mapVariables } from './utils';

const selectStoreData = (s: PointsTransactionStore) => ({
  onDetail: s.openModalDetailPointsTransaction,
  onRemove: s.openModalRemovePointsTransaction,
  onEdit: s.openModalEditPointsTransaction,
  tablePointsTransactions: s.tablePointsTransaction,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,
});

const PointsTransactions = (): JSX.Element => {
  const { onDetail, onRemove, onEdit, tablePointsTransactions, changeFilter, changeSort, changeData } = UsePointsTransactionStore(
    selectStoreData,
    shallow,
  );

  const { loading, error, data } = usePointsTransactionsQuery({
    variables: mapVariables(tablePointsTransactions),
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1300px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={8}
      rows={data?.pointsTransactions.data.length || 0}
      loading={loading}
      error={error}
      limit={tablePointsTransactions.data.limit}
      sortField={tablePointsTransactions.data.sortField}
      sortOrder={tablePointsTransactions.data.sortOrder}
      offset={tablePointsTransactions.data.offset}
      count={data?.pointsTransactions.pagination.count}
      filter={tablePointsTransactions.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <PointsTransactionsTableHeader />
      <TableBody>
        {(data?.pointsTransactions.data || []).map((row) => (
          <PointsTransactionsTableRow key={row.id} row={row} onDetail={onDetail} onRemove={onRemove} onEdit={onEdit} />
        ))}
      </TableBody>
    </Table>
  );
};

export default PointsTransactions;
