import { useGetAvailableAlzaVoucherPurchaseOptionsQuery, } from 'Apollo/graphql';
import AppContainer from 'Components/AppContainer';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import LoadingBox from 'Components/LoadingBox';
import AlzaView from './AlzaView';

const AlzaLogic = (): JSX.Element => {
  
  const { data, loading, error, } = useGetAvailableAlzaVoucherPurchaseOptionsQuery();

  if (loading) {
    return (
      <AppContainer maxWidth="sm">
        <LoadingBox />
      </AppContainer>
    );
  }

  if (error || !data?.getAvailableAlzaPurchaseOptions)
    return (
      <AppContainer maxWidth="sm">
        <ErrorAlert forceError error={error} />
      </AppContainer>
    );

  return (
    <AppContainer maxWidth="sm">
      <AlzaView data={data}/>
    </AppContainer>
  );
};

export default AlzaLogic;
