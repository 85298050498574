import shallow from 'zustand/shallow';
import { ReactNode, useEffect, } from 'react';
import { CreateTaskPhase, CreateTaskStore, useCreateTaskStore, } from 'Modules/TasksAdmin/store/createTaskStore';
import PhaseContent from './PhaseContent';
import PhaseMethod from './PhaseMethod';
import PhaseParameters from './PhaseParameters';
import PhaseType from './PhaseType';
import PhaseAssessment from './PhaseAssessment';

type PhaseComponents = {
  [key in CreateTaskPhase]: () => ReactNode
};

const PHASE_COMPONENTS: PhaseComponents = {
  type: () => <PhaseType />,
  method: () => <PhaseMethod />,
  parameters: () => <PhaseParameters />,
  content: () => <PhaseContent />,
  assessment: () => <PhaseAssessment />,
};

const selectStoreData = (s: CreateTaskStore) => ({
  phase: s.phase,
  resetStore: s.resetStore,
});

const CreateTaskPage = (): JSX.Element => {
  const { phase, resetStore, } = useCreateTaskStore(selectStoreData, shallow);

  useEffect(
    () => {
      return () => resetStore();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(
    () => window.scrollTo({ top: 0, }),
    [ phase, ],
  );

  return <>{PHASE_COMPONENTS[phase]()}</>;
};

export default CreateTaskPage;
