/* eslint-disable react/default-props-match-prop-types */
import { styled, } from '@mui/material/styles';
import Drawer, { DrawerProps, } from '@mui/material/Drawer';

interface CustomDrawerProps extends Omit<DrawerProps, 'onClose'> {
  onClose?: () => void;
}

export const handleOnClose =
  (onClose?: () => void): DrawerProps['onClose'] =>
  (_, reason) => {
    if (onClose && reason !== 'backdropClick') onClose();
  };
const WIDTH = 640;

const StyledModalDrawer = styled(Drawer)(() => ({
  width: WIDTH,
  maxWidth: '100%',
  '& .MuiDrawer-paper': {
    width: WIDTH,
    maxWidth: '100%',
    boxSizing: 'border-box',
  },
}));

const ModalDrawer = ({ onClose, ...rest }: CustomDrawerProps) => {
  return <StyledModalDrawer {...rest} onClose={handleOnClose(onClose)} />;
};

ModalDrawer.defaultProps = {
  anchor: 'right',
  keepMounted: false,
};

export default ModalDrawer;
