import { MouseEventHandler, } from 'react';
import { useTranslation, } from 'react-i18next';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import { Claim, NewsArticleTableFragment, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellText from 'Components/Table/TableCellText';

interface Props {
  row: NewsArticleTableFragment
  onEdit: (newsArticle: NewsArticleTableFragment) => void,
  onRemove: (newsArticle: NewsArticleTableFragment) => void,
  onDetail: (newsArticle: NewsArticleTableFragment) => void,
};

const NewsArticlesTableRow = ({
  row,
  onEdit,
  onRemove,
  onDetail,
}: Props): JSX.Element => {
  const { t, } = useTranslation('newsArticlesAdmin');
  const user = useAuthUser();
  const showChainIDs = user?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;

  const handleRemove: MouseEventHandler<HTMLLIElement> = () => {
    onRemove(row);
  };

  const handleEdit: MouseEventHandler<HTMLLIElement> = () => {
    onEdit(row);
  };

  const handleDetail: MouseEventHandler<HTMLLIElement> = () => {
    onDetail(row);
  };

  return (
    <TableRow key={row.id}>
      <TableCellText>
        {row.id || '-'}
      </TableCellText>
      <TableCellText>
        {row.title || '-'}
      </TableCellText>
      <TableCellText>
        {row.description || '-'}
      </TableCellText>
      <TableCellText>
        {row.createdAt ? t('common:date.fullDate', { date: new Date(row.createdAt), }) : '-'}
      </TableCellText>
      {showChainIDs &&
      <TableCellText>
        {row.chains.map(c => c.id).join(', ') || '-'}
      </TableCellText>}
      <TableCellText>
        {row.authorId}
      </TableCellText>
      <TableCellActions>
        <MenuItem
          onClick={handleDetail}
        >
          {t('newsArticles.table.btnDetail')}
        </MenuItem>
        {user?.claimsSet.has(Claim.ManageNews) && (
          <MenuItem
            onClick={handleEdit}
          >
            {t('newsArticles.table.btnEdit')}
          </MenuItem>
        )}
        {user?.claimsSet.has(Claim.ManageNews) && (
          <MenuItem
            onClick={handleRemove}
          >
            {t('newsArticles.table.btnRemove')}
          </MenuItem>
        )}
      </TableCellActions>
    </TableRow>
  );
};

export default NewsArticlesTableRow;
