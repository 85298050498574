import { useTranslation, } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AssortmentCategoryOptionFragment, useAssortmentCategoryOptionsQuery, AssortmentSortableFields, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldName, renderOptionByIdName, } from 'Utils/helpers';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import TableCellSortable from 'Components/Table/TableCellSortable';

const COL_WIDTHS = [
  {},
  {},
  {},
  { width: '90px', },
  { width: '35%', },
  { width: '45px', },
];

const AssortmentsTableHeader = (): JSX.Element => {
  const { t, } = useTranslation('assortmentAdmin');
  const categoriesOptionsResult = useAssortmentCategoryOptionsQuery({ fetchPolicy: 'cache-and-network', });

  return (
    <TableHead>
      <TableRow>
        <TableCellSortable
          style={COL_WIDTHS[0]}
          name={AssortmentSortableFields.Id}
        >
          {t('assortments.table.labelProductId')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[1]}
          name={AssortmentSortableFields.CategoryId}
        >
          {t('assortments.table.labelCategory')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[2]}
          name={AssortmentSortableFields.Name}
        >
          {t('assortments.table.labelName')}
        </TableCellSortable>
        <TableCell
          style={COL_WIDTHS[3]}
        >
          {t('assortments.table.labelPhoto')}
        </TableCell>
        <TableCellSortable
          style={COL_WIDTHS[4]}
          name={AssortmentSortableFields.Description}
        >
          {t('assortments.table.labelDescription')}
        </TableCellSortable>
        <TableCell
          style={COL_WIDTHS[5]}
        />
      </TableRow>
      <TableRow>
        <TableCell>
          <TableFilterText
            name="id"
          />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<AssortmentCategoryOptionFragment>
            name="category"
            getOptionLabel={getOptionFieldName}
            isOptionEqualToValue={areOptionsEqualById}
            renderOption={renderOptionByIdName}
            options={categoriesOptionsResult.data?.assortmentCategories || []}
            loading={categoriesOptionsResult.loading}
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="name"
          />
        </TableCell>
        <TableCell />
        <TableCell>
          <TableFilterText
            name="description"
          />
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default AssortmentsTableHeader;
