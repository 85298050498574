import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { CustomerVerificationMethod, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type UserVerificationVariant = 'sms' | 'email';

export type UserVerificationOption = {
  id: CustomerVerificationMethod,
  labelKey: string,
};

export const USER_VERIFICATION_OPTIONS: UserVerificationOption[] = [
  {
    id: CustomerVerificationMethod.Email,
    labelKey: 'common:options.userVerificationOption.email',
  },
  {
    id: CustomerVerificationMethod.Sms,
    labelKey: 'common:options.userVerificationOption.sms',
  },
];

export const getUserVerificationMethodById = (id: CustomerVerificationMethod | null): UserVerificationOption | null => {
  if (!id) return null;
  return USER_VERIFICATION_OPTIONS.find((o) => o.id === id) || null;
};

type GetOptionLabel = (o: UserVerificationOption) => string;
type GetOptionKey = (o: UserVerificationOption) => string;

type UseOptionsUserVerification = () => {
  options: UserVerificationOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<UserVerificationOption>, 
};

export const useOptionsUserVerification: UseOptionsUserVerification = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: USER_VERIFICATION_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
