import { ReactNode, } from 'react';
import { styled, } from '@mui/material/styles';
import MuiTable, { TableProps, } from '@mui/material/Table';
import MuiTableContainer from '@mui/material/TableContainer';
import Paper, { PaperProps, } from '@mui/material/Paper';
import TablePagination from './TablePagination';
import { TableContextValue, TableContext, } from './TableContext';

const StyledPaper = styled(Paper)(({ theme, }) => ({
  border: '1px solid #D4D4D4',

  '& .MuiTableContainer-root': {
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  },

  '& .MuiTable-root': {
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  },
}));

interface Props extends TableContextValue {
  children: ReactNode,
  sx?: PaperProps['sx'],
  sxTable?: TableProps['sx'],
}

const Table = ({
  sx,
  sxTable,
  children,
  ...rest
}: Props): JSX.Element => {
  return (
    <TableContext.Provider
      value={rest}
    >
      <StyledPaper
        elevation={0}
        sx={sx}
      >
        <MuiTableContainer>
          <MuiTable
            sx={sxTable}
          >
            {children}
          </MuiTable>
        </MuiTableContainer>
        <TablePagination />
      </StyledPaper>
    </TableContext.Provider>
  );
};

export default Table;
