import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { PurchasesStore, usePurchasesStore, } from 'Modules/RegistrationsAdmin/store/PurchasesStore';
import FetchData from './FetchData';

const selectModalData = (s: PurchasesStore) => s.modalDetailPurchase;
const selectClose = (s: PurchasesStore) => s.closeModalDetailPurchase;

const ModalDetailRegistration = (): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const { isOpen, purchase, } = usePurchasesStore(selectModalData);
  const onClose = usePurchasesStore(selectClose);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!purchase}
    >
      <ModalDrawerTitle
        onClose={onClose}
      >
        {t('detailPurchase.title')}
      </ModalDrawerTitle>
      <FetchData
        purchase={purchase}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalDetailRegistration;
