import { styled, } from '@mui/material/styles';
import { TableCell, } from '@mui/material';

const TableCellText = styled(TableCell)(({ theme, }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export default TableCellText;
