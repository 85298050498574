import shallow from 'zustand/shallow';
import { usePromotionsQuery, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import { PromotionsStore, usePromotionsStore, } from 'Modules/PromotionsAdmin/store/PromotionsStore';
import PromotionsTableRow from './PromotionsTableRow';
import PromotionsTableHeader from './PromotionsTableHeader';
import { mapVariables, } from './utils';

const selectStoreData = (s: PromotionsStore) => ({
  onEdit: s.openModalEditPromotion,
  onRemove: s.openModalRemovePromotion,
  tablePromotions: s.tablePromotions,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,
});

const Promotions = (): JSX.Element => {
  const authUser = useAuthUser();
  const {
    onEdit,
    onRemove,
    tablePromotions,
    changeFilter,
    changeSort,
    changeData,
  } = usePromotionsStore(selectStoreData, shallow);

  const { loading, error, data, } = usePromotionsQuery({
    variables: mapVariables(tablePromotions, authUser),
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={10}
      rows={data?.promotions.data.length || 0}
      loading={loading}
      error={error}
      limit={tablePromotions.data.limit}
      sortField={tablePromotions.data.sortField}
      sortOrder={tablePromotions.data.sortOrder}
      offset={tablePromotions.data.offset}
      count={data?.promotions.pagination.count}
      filter={tablePromotions.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <PromotionsTableHeader
        authUser={authUser}
      />
      <TableBody>
        {(data?.promotions.data || []).map((row) => (
          <PromotionsTableRow
            key={row.id}
            row={row}
            authUser={authUser}
            onEdit={onEdit}
            onRemove={onRemove}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default Promotions;
