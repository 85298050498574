import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DeletePromotionMutationResult, PromotionFragment, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';

interface Props {
  promotion: PromotionFragment,
  removeMutationResult: DeletePromotionMutationResult,
  onSubmit: () => void,
  onClose: () => void,
};

const View = ({
  promotion,
  removeMutationResult,
  onSubmit,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('promotionsAdmin');

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>
        {t('removePromotionModal.title')}
      </ModalDrawerTitle>
      <ModalDrawerBody>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 5,
          }}
        >
          {t('removePromotionModal.info', { name: promotion.name, })}
        </Typography>

        <Grid container spacing={2} mt={2} alignItems="center">
          <Grid item>
            <Button
              color="error"
              variant="contained"
              size="large"
              disabled={removeMutationResult.loading}
              onClick={onSubmit}
            >
              {t('removePromotionModal.btnSubmit')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              color="inherit"
              onClick={onClose}
            >
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>

        <ErrorAlert
          mt={2}
          error={removeMutationResult.error}
        />
      </ModalDrawerBody>
    </>
  );
};

export default View;
