import { UserTaxInformationFragment, } from 'Apollo/graphql';
import { createPhonePrefixOption, PhonePrefixOption, } from 'Utils/options/useOptionsPhonePrefix';
import { useMemo, } from 'react';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { REGEX_PHONE_NUMBER, } from 'Utils/constants';
import * as yup from 'yup';

export type PITTaxFormValues = {
  taxNumber: string;
  email: string;
  accountNumber: string;
  phonePrefix: PhonePrefixOption | null;
  phoneNumber: string;
};

export type InvoiceTaxFormValues = {
  firstName: string;
  lastName: string;
  city: string;
  postCode: string;
  street: string;
  taxOffice: string;
  taxNumber: string;
  email: string;
  accountNumber: string;
  phonePrefix: PhonePrefixOption | null;
  phoneNumber: string;
};

const POST_CODE_REGEX = /[0-9]{2}-[0-9]{3}/g;
const NUMBER_ONLY_REGEX = /^[0-9]+$/g;


export const usePITTaxFormInitValues = (taxInfo: UserTaxInformationFragment | null | undefined): PITTaxFormValues =>
  useMemo<PITTaxFormValues>(
    () => ({
      taxNumber: String(taxInfo?.taxNumber || ''),
      email: taxInfo?.email || '',
      accountNumber: String(taxInfo?.accountNumber || ''),
      phonePrefix: createPhonePrefixOption(taxInfo?.phonePrefix || ''),
      phoneNumber: taxInfo?.phoneNumber || '',
      RODOConsent: taxInfo?.RODOConsent || false,
    }),
    [taxInfo,],
  );

export const useInvoiceTaxFormInitValues = (taxInfo: UserTaxInformationFragment | null | undefined): PITTaxFormValues =>
  useMemo<PITTaxFormValues>(
    () => ({
      taxNumber: String(taxInfo?.taxNumber || ''),
      email: taxInfo?.email || '',
      accountNumber: String(taxInfo?.accountNumber || ''),
      phonePrefix: createPhonePrefixOption(taxInfo?.phonePrefix || ''),
      phoneNumber: taxInfo?.phoneNumber || '',
      firstName: taxInfo?.firstName || '',
      lastName: taxInfo?.lastName || '',
      city: taxInfo?.city || '',
      postCode: taxInfo?.postCode || '',
      street: taxInfo?.street || '',
      taxOffice: taxInfo?.taxOffice || '',
      RODOConsent: taxInfo?.RODOConsent || false,
    }),
    [taxInfo,],
  );

export const taxInformationValidationSchemaPIT = yup.object().shape({
  taxNumber: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).matches(NUMBER_ONLY_REGEX,INPUT_FORM_ERRORS.ONLY_NUMBERS).max(11, INPUT_FORM_ERRORS.STRING_MAX),
  email: yup.string().email(INPUT_FORM_ERRORS.EMAIL).required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
  accountNumber: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).matches(NUMBER_ONLY_REGEX,INPUT_FORM_ERRORS.ONLY_NUMBERS).max(26, INPUT_FORM_ERRORS.STRING_MAX),
  phonePrefix: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
  phoneNumber: yup
    .string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .matches(REGEX_PHONE_NUMBER, INPUT_FORM_ERRORS.PHONE)
    .max(25, INPUT_FORM_ERRORS.STRING_MAX),
  RODOConsent: yup.boolean()
  .oneOf([ true, ], INPUT_FORM_ERRORS.REQUIRED),
});

export const taxInformationValidationSchemaInvoice = yup.object().shape({
  accountNumber: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).matches(NUMBER_ONLY_REGEX,INPUT_FORM_ERRORS.ONLY_NUMBERS).max(26, INPUT_FORM_ERRORS.STRING_MAX),
  taxNumber: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).matches(NUMBER_ONLY_REGEX,INPUT_FORM_ERRORS.ONLY_NUMBERS).max(11, INPUT_FORM_ERRORS.STRING_MAX),
  firstName: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
  lastName: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
  city: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
  postCode: yup
    .string()
    .matches(POST_CODE_REGEX, INPUT_FORM_ERRORS.UNKNOWN) // TODO
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  street: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
  taxOffice: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
  email: yup.string().email(INPUT_FORM_ERRORS.EMAIL).required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
  phonePrefix: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
  phoneNumber: yup
    .string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .matches(REGEX_PHONE_NUMBER, INPUT_FORM_ERRORS.PHONE)
    .max(25, INPUT_FORM_ERRORS.STRING_MAX),
  RODOConsent: yup.boolean()
  .oneOf([ true, ], INPUT_FORM_ERRORS.REQUIRED),
});
