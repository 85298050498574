import { Navigate } from 'react-router-dom';
import { useAuthUser } from 'Tools/auth';
import { AuthUserFragment } from 'Apollo/graphql';
import { ADMIN_MENU_ITEMS } from 'Components/Layouts/LayoutAdmin/constants';
import { intersection } from 'lodash';

const pickAdminRedirect = (authUser: AuthUserFragment | null) => {
  const possibleRoute = ADMIN_MENU_ITEMS.find((item) => {
    return (
      item.claims === undefined ||
      (intersection(authUser?.claims ?? [], item.claims ?? []).length > 0 &&
        item.id !== 'dashboard')
    );
  });
  return `${possibleRoute?.route}`;
};

const DashboardAdmin = (): JSX.Element | null => {
  const authUser = useAuthUser();

  return <Navigate to={pickAdminRedirect(authUser)} />;
};

export default DashboardAdmin;
