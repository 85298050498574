import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import {
  RegistrationStore,
  useRegistrationStore,
} from 'Modules/RegistrationsAdmin/store/RegistrationStore';
import { useTranslation, } from 'react-i18next';
import { FormProvider, useForm, } from 'react-hook-form';
import { zodResolver, } from '@hookform/resolvers/zod';
import { z, } from 'zod';
import { useAuthUser, } from 'Tools/auth';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import { Box, Button, Typography, } from '@mui/material';
import PosView from './PosView/PosView';
import BAView from './BrandAmbasadorView/BrandAmbasadorView';
import QrCodeTypeForm, { QrCodeType, } from './QrCodeTypeForm';
import UserView from './UserView/UserView';

const selectClose = (s: RegistrationStore) => s.closeModalQrRegistration;
const selectOpen = (s: RegistrationStore) => s.modalQrRegistration;

const qrCodeTypeFormSchema = z.object({
  // TODO: FORMS SHOULD HAVE ONLY THE VALUES NOT WHOLE CONFIGURATION
  type: z.object({
    id: z.nativeEnum(QrCodeType),
    labelKey: z.string(),
    value: z.nativeEnum(QrCodeType),
  }),
});

const ModalQrCode = (): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const { isOpen, } = useRegistrationStore(selectOpen);
  const onClose = useRegistrationStore(selectClose);

  const methods = useForm({
    resolver: zodResolver(qrCodeTypeFormSchema),
    mode: 'onTouched',
  });

  const qrCodeType = methods.watch('type');
  const authUser = useAuthUser();
  const showPosSelector =
    authUser?.featureFlagsObject
      .FEATURE_FLAG_ENABLE_BRAND_AMBASSADOR_WITH_POS_QR_REGISTRATION || false;

  return (
    <ModalDrawer onClose={onClose} open={isOpen}>
      <ModalDrawerTitle onClose={onClose}>
        {t('qrModal.title')}
      </ModalDrawerTitle>
      <ModalDrawerBody>
        <Typography sx={{ pb: '24px', }}>{t('qrModal.description')}</Typography>
        <FormProvider {...methods}>
          <form noValidate autoComplete="off">
            <QrCodeTypeForm />
          </form>
        </FormProvider>
        {qrCodeType?.value === QrCodeType.brandAmbassador && (
          <BAView showPosSelector={showPosSelector} />
        )}
        {qrCodeType?.value === QrCodeType.pos && <PosView />}
        {qrCodeType?.value === QrCodeType.user && <UserView />}
        <Box mt={4}>
          <Button
            size="large"
            variant="outlined"
            color="inherit"
            onClick={onClose}
          >
            {t('common:actions.cancel')}
          </Button>
        </Box>
      </ModalDrawerBody>
    </ModalDrawer>
  );
};

export default ModalQrCode;
