import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import ImageAsync from 'Components/ImageAsync';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useCallback, useState, } from 'react';
import FullscreenImageOverlay from 'Components/FullscreenImageOverlay';

const CardContainer = styled(Card)(({ theme, }) => ({
  borderRadius: theme.spacing(0.5),
  marginBottom: theme.spacing(4),
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(2, 1.5),
}));

const ImageContainer = styled('div')(({ theme, }) => ({
  position: 'relative',
  width: '100%',
  marginBottom: theme.spacing(2),

  '& > img': {
    display: 'block',
  },
}));

interface Props {
  title: React.ReactNode;
  imageSrc?: string;
  date: React.ReactNode;
  children: React.ReactNode;
  showExpandImage?: boolean;
}

const CardLayout = ({
  title,
  imageSrc,
  date,
  children,
  showExpandImage = false,
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  const [isImageExpanded, setIsImageExpanded,] = useState(false);

  const openFullScreeenImageOverlay = useCallback(() => {
    setIsImageExpanded(true);
  }, [setIsImageExpanded,]);

  const closeFullScreenImageOverlay = useCallback(() => {
    setIsImageExpanded(false);
  }, [setIsImageExpanded,]);

  return (
    <CardContainer>
      <ImageContainer onClick={openFullScreeenImageOverlay}>
        <ImageAsync src={imageSrc} />
        {showExpandImage && (
          <FullscreenIcon
            sx={{
              zIndex: 5,
              position: 'absolute',
              bottom: '2%',
              right: '2%',
              color: 'black',
            }}
          />
        )}
        <FullscreenImageOverlay
          src={imageSrc}
          open={isImageExpanded}
          onClose={closeFullScreenImageOverlay}
        />
      </ImageContainer>
      <Typography variant="body1" color="gray" marginBottom={1}>
        {t('common:date.fullDate', { date: new Date(date as string), }) || '-'}
      </Typography>
      <Typography variant="h3" color="primary" marginBottom={1}>
        {title}
      </Typography>
      {children}
    </CardContainer>
  );
};

export default CardLayout;
