import { SvgIconProps, } from '@mui/material/SvgIcon';
import { isCzechMarket, isHungarianMarket, isPolishMarket, } from 'Utils/market';
import LogoIconCz from './LogoIcons/LogoIconCz';
import LogoIconEn from './LogoIcons/LogoIconEn';
import LogoIconHu from './LogoIcons/LogoIconHu';
import LogoIconPl from './LogoIcons/LogoIconPl';

const LogoIcon = (props: SvgIconProps): JSX.Element => {
  if (isCzechMarket) {
    return <LogoIconCz {...props} />;
  }
  if (isPolishMarket) {
    return <LogoIconPl {...props} />;
  }
  if (isHungarianMarket) {
    return <LogoIconHu {...props} />;
  }
  return <LogoIconEn {...props} />;
};

export default LogoIcon;
