import LoadingBox from 'Components/LoadingBox';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import { useEffect, } from 'react';
import { CreateReplacementStore, } from '../../../createReplacementStore';

interface Props {
  completeCustomerVerificationPhase: (verification: CreateReplacementStore['customerVerification']) => void;
}
const SkipVerification = ({ completeCustomerVerificationPhase, }: Props): JSX.Element => {
  useEffect(() => {
    completeCustomerVerificationPhase({ shouldVerify: false, isVerified: true, method: null, value: null, });
  }, [completeCustomerVerificationPhase,]);
  return (
    <ModalDrawerBody>
      <LoadingBox />
    </ModalDrawerBody>
  );
};

export default SkipVerification;
