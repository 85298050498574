import { WheelData, } from "react-custom-roulette/dist/components/Wheel/types";

// with this we are able to change order of items in the wheel (be returns order 100-200-500-wish, but we need 100-500-wish-200)
export const prizeValueMap: Map<string, number> = new Map([
  ['1', 1,],
  ['2', 4,],
  ['3', 2,],
  ['4', 3,],
]);

export const data: WheelData[] = [
  {
    image: {
      uri: '/images/xmas-wheel/cz/100.svg',
      sizeMultiplier: 0.6,
    },
  },
  {
    image: {
      uri: '/images/xmas-wheel/cz/500.svg',
      sizeMultiplier: 0.6,
    },
  },
  {
    image: {
      uri: '/images/xmas-wheel/cz/wish.svg',
      sizeMultiplier: 0.6,
    },
  },
  {
    image: {
      uri: '/images/xmas-wheel/cz/200.svg',
      sizeMultiplier: 0.6,
    },
  },
  {
    image: {
      uri: '/images/xmas-wheel/cz/100.svg',
      sizeMultiplier: 0.6,
    },
  },
  {
    image: {
      uri: '/images/xmas-wheel/cz/500.svg',
      sizeMultiplier: 0.6,
    },
  },
  {
    image: {
      uri: '/images/xmas-wheel/cz/wish.svg',
      sizeMultiplier: 0.6,
    },
  },
  {
    image: {
      uri: '/images/xmas-wheel/cz/200.svg',
      sizeMultiplier: 0.6,
    },
  },
];
