import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { AssessmentBlockTypeEnum, FileFragment, PhotoAssessmentBlockFragment, TaskCompletionReviewFragment, } from 'Apollo/graphql';
import ImageAsync from 'Components/ImageAsync';
import EyeIcon from 'Utils/svg/EyeIcon';

const ImageContainer = styled('div')(({ theme, }) => ({
  position: 'relative',
  maxWidth: '100%',
  paddingTop: '56.25%',
  marginBottom: theme.spacing(1),

  '& > img': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

type PhotoItem = {
  id:  number,
  file: FileFragment | null,
  description: string | null, 
};

interface Props {
  taskCompletion: TaskCompletionReviewFragment,
  taskCompletionFiles: FileFragment[],
};

const PhotoList = ({
  taskCompletion,
  taskCompletionFiles,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');

  const list = useMemo<PhotoItem[]>(
    () => {
      const ret: PhotoItem[] = [];

      let filesIndex = 0;
      for (let i = 0; i < (taskCompletion.task.assessment?.blocks || []).length; i++) {
        const element = taskCompletion.task.assessment?.blocks[i];
        
        if (element?.type === AssessmentBlockTypeEnum.Photo) {
          ret.push({
            id: i,
            file: taskCompletionFiles?.[filesIndex] || null,
            description: (element as PhotoAssessmentBlockFragment).description,
          });
          filesIndex++;
        };
      }

      return ret;
    },
    [ taskCompletion.task.assessment, taskCompletionFiles, ],
  );

  return (
    <Grid container spacing={2}>
      {list.map((item) => (
        <Grid key={item.id} item xs={12}>
          {item.description && (
            <Typography mb={1}>
              {item.description}
            </Typography>
          )}
          {item.file?.publicUrl && (
            <>
              <ImageContainer>
                <ImageAsync src={item.file.publicUrl} />
              </ImageContainer>
              <Button
                startIcon={<EyeIcon />}
                component="a"
                href={item.file.publicUrl}
                rel="noopener noreferrer"
                target="_blank"
                variant="contained"
                color="neutral"
              >
                {t('review.btnViewImage')}
              </Button>
            </>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default PhotoList;
