import { useMemo, } from 'react';
import { styled, } from '@mui/material/styles';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import { MyPointsTransactionsQuery, PointsTransactionOperation, PointsTransactionReason, } from 'Apollo/graphql';
import TransActionChunk from 'Modules/MyPointsSeller/components/TransactionChunk';
import MonthBox from './MonthBox';

const CardContainer = styled(Paper)(() => ({
  elevation: 1,
  pt: 3,
  pb: 3,
  pl: 2,
  pr: 2,
}));

type PointsTransaction = {
  __typename?: 'PointsTransaction' | undefined;
  reason: PointsTransactionReason;
  amount: number;
  operation: PointsTransactionOperation;
  updatedAt: string;
  id: string;
};

type Props = {
  data: MyPointsTransactionsQuery;
};
type dataObject = {
  id: string;
  month: number;
  year: number;
  exampleDate: string;
  dataArray: PointsTransaction[];
};
const parseMonthAndYear = (date: string) => {
  const parsedDate = new Date(date);
  return {
    month: parsedDate.getMonth(),
    year: parsedDate.getFullYear(),
  };
};

const processData = (data: PointsTransaction[], dataIndex = 0, objectArray: dataObject[] = [], objectArrayIndex: number = 0): dataObject[] => {
  if (!data[dataIndex]) {
    return processData(data, dataIndex + 1, objectArray, objectArrayIndex);
  }
  const { month, year, } = parseMonthAndYear(data[dataIndex].updatedAt);
  if (data.length === 1) {
    return [
      {
        id: data[0].id,
        month,
        year,
        exampleDate: data[0].updatedAt,
        dataArray: data,
      },
    ];
  }
  if (data.length === dataIndex + 1) {
    return objectArray;
  }
  if (dataIndex === 0) {
    const firstObject = {
      id: `${month}${year}`,
      month,
      year,
      exampleDate: data[dataIndex].updatedAt,
      dataArray: [data[0],],
    };
    return processData(data, dataIndex + 1, [...objectArray, firstObject,], objectArrayIndex);
  }
  if (month === objectArray[objectArrayIndex].month && year === objectArray[objectArrayIndex].year) {
    const newDataArray = [...objectArray[objectArrayIndex].dataArray, data[dataIndex],];
    const newObjectArray = objectArray;
    newObjectArray[objectArrayIndex].dataArray = newDataArray;
    return processData(data, dataIndex + 1, newObjectArray, objectArrayIndex);
  }
  const newObject = {
    id: `${month}${year}`,
    month,
    year,
    exampleDate: data[dataIndex].updatedAt,
    dataArray: [data[dataIndex],],
  };
  const newArray = [...objectArray, newObject,];
  return processData(data, dataIndex + 1, newArray, objectArrayIndex + 1);
};

const View = ({ data, }: Props): JSX.Element => {
  const myPointTransactions = data.myPointsTransactions.data;
  const processedData = useMemo(() => {
    if (myPointTransactions.length < 1) return [];
    return processData(myPointTransactions);
  }, [myPointTransactions,]);

  return (
    <CardContainer>
      <List subheader={<li />}>
        {processedData.map((object) => (
          <li key={object.id}>
            <ul style={{ padding: 0, margin: 0, listStyle: 'none', }}>
              <MonthBox dateString={object.exampleDate} />
              {object.dataArray.map((item) => (
                <TransActionChunk key={item.id} data={item} />
              ))}
            </ul>
          </li>
        ))}
      </List>
    </CardContainer>
  );
};

export default View;
