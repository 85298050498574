import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CreateNewsArticleMutationResult, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import {
  FormError,
  FormSubmit,
} from 'Components/Form';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import NewsArticleForm from 'Modules/NewsArticlesAdmin/components/NewsArticleForm';

interface Props {
  createMutationResult: CreateNewsArticleMutationResult,
  onClose: () => void,
};

const View = ({
  createMutationResult,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('newsArticlesAdmin');

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>
        {t('createNewsArticleModal.title')}
      </ModalDrawerTitle>
      <ModalDrawerBody>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 5,
          }}
        >
          {t('createNewsArticleModal.info')}
        </Typography>
        <NewsArticleForm />
        <Box mt={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <FormSubmit
                size="large"
              >
                {t('createNewsArticleModal.btnSubmit')}
              </FormSubmit>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                color="inherit"
                onClick={onClose}
              >
                {t('common:actions.cancel')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <FormError mt={2} />
        <ErrorAlert
          mt={2}
          error={createMutationResult.error}
        />
      </ModalDrawerBody>
    </>
  );
};

export default View;
