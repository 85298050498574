import { useForm, FormProvider, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import { ReplacementDetailFragment, ReplacementStatus, ReplacementTableFragment, ReplacementsDocument, useUpdateReplacementMutation, } from 'Apollo/graphql';
import { useReplacementStore, } from 'Modules/ReplacementAdmin/store/ReplacementStore';
import { mapVariables, } from 'Modules/ReplacementAdmin/pages/ReplacementAdminPage/ReplacementTable/utils';
import { useSnackbar, } from 'notistack';
import { useTranslation, } from 'react-i18next';
import {
  replacementFormValidationSchema, useInitReplacementValues,
} from '../../utils/replacementForm';
import View from './View';

interface Props {
  replacement: ReplacementDetailFragment,
  onClose: () => void,
  onOpenRemove: (replacement: ReplacementDetailFragment | ReplacementTableFragment) => void,
};

const Logic = ({
  replacement,
  onClose,
	onOpenRemove,
}: Props): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const initValues = useInitReplacementValues(replacement);
  const methods = useForm({
    defaultValues: initValues,
    resolver: yupResolver(replacementFormValidationSchema),
    mode: 'onTouched',
  });

  const [ editMutation, ] = useUpdateReplacementMutation({
    refetchQueries: [{
      query: ReplacementsDocument,
      variables: mapVariables(useReplacementStore.getState().tableReplacement),
    },],
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('editReplacementModal.notifEditedTitle') as string,
        message: t('editReplacementModal.notifEditedMessage', { replacementId: response.updateReplacement?.id, }),
        variant: 'common',
      });
      onClose();
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await editMutation({
      variables: {
        replacementUpdateInput: {
					id: replacement.id,
					status: values.status?.id as ReplacementStatus,
				},
      },
    });    
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View
          isDirty={methods.formState.isDirty}
          replacement={replacement}
					onOpenRemove={onOpenRemove}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default Logic;