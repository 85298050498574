import { OrderBy, PaginationInput, ReplacementFilterInput, ReplacementSortInput, ReplacementSortableFields, } from 'Apollo/graphql';
import { ReplacementStore, } from 'Modules/ReplacementAdmin/store/ReplacementStore';

export const mapVariables = (storeData: ReplacementStore['tableReplacement']): {
  filter?: ReplacementFilterInput,
  pagination?: PaginationInput,
  sort?: ReplacementSortInput,
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    id: storeData.filter.id 
      ? { contains: storeData.filter.id, }
      : undefined,
    categoryId: storeData.filter.category
      ? { equals: storeData.filter.category.id, }
      : undefined,
    reason: storeData.filter.reason
      ? { contains: storeData.filter.reason, }
      : undefined,
    status: storeData.filter.status
      ? { equals: storeData.filter.status.id, }
      : undefined,
    author: {
      username: storeData.filter.username
      ? { contains: storeData.filter.username, }
      : undefined,
    },
    ...(storeData.filter.completor && ({completor: {
        username: storeData.filter.completor
        ? { contains: storeData.filter.completor, }
        : null,
      },})),
    posId: storeData.filter.pos?.id
      ? { equals: storeData.filter.pos.id, }
      : undefined,
    chainId: storeData.filter.chain?.id
      ? { equals: storeData.filter.chain.id, }
      : undefined,
  },
  sort: storeData.data.sortField && storeData.data.sortOrder
    ? {
      field: storeData.data.sortField,
      order: storeData.data.sortOrder,}
      : {field: ReplacementSortableFields.CreatedAt, order: OrderBy.Desc,},
});
