import { AuthUserFragment, PaginationInput, PointOfSellFilterInput, PointOfSellSortInput, } from 'Apollo/graphql';
import { PointOfSellStore, } from 'Modules/PointOfSell/store/PointOfSellStore';

export const mapVariables = (storeData: PointOfSellStore['tablePointOfSell'], authUser: AuthUserFragment | null): {
  filter?: PointOfSellFilterInput,
  pagination?: PaginationInput,
  sort?: PointOfSellSortInput,
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    id: storeData.filter.pos 
      ? { equals: storeData.filter.pos.id, }
      : undefined,
    name: storeData.filter.posName 
      ? { contains: storeData.filter.posName, }
      : undefined,
    chainId: storeData.filter.chain 
      ? { equals: storeData.filter.chain.id, }
      : undefined,
    chain: storeData.filter.chain 
      ? { name: { contains: storeData.filter.chain.name, },}
      : undefined,
    clusterId: authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS && storeData.filter.cluster
      ? { equals: storeData.filter.cluster.id, }
      : undefined,
    street: storeData.filter.street 
      ? { contains: storeData.filter.street, }
      : undefined,
    houseNumber: storeData.filter.number 
      ? { contains: storeData.filter.number, }
      : undefined,
    city: storeData.filter.city 
      ? { contains: storeData.filter.city, }
      : undefined,
    postalCode: storeData.filter.postalCode 
      ? { contains: storeData.filter.postalCode, }
      : undefined,
  },
  sort: storeData.data.sortField && storeData.data.sortOrder
    ? {
      field: storeData.data.sortField,
      order: storeData.data.sortOrder,
    } : undefined,
});
