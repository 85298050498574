import { useCallback, useState, } from 'react';
import { useApolloClient, } from '@apollo/client';
import { useLogoutMutation, } from 'Apollo/graphql';
import { AuthStore, useAuthStore, } from '../store/authStore';
import { setOfflineRefreshToken, } from '../offlineRefreshTokenStore';

const selectSetAccessToken = (s: AuthStore) => s.setAccessToken;

type UseAuthLogoutRet = {
  loading: boolean,
  logout: () => Promise<void>,
};

export const useAuthLogout = (): UseAuthLogoutRet => {
  const setAccessToken = useAuthStore(selectSetAccessToken);
  const apolloClient = useApolloClient();

  const [logoutMutation, { loading }] = useLogoutMutation({
    fetchPolicy: 'no-cache',
    onCompleted: async () => {
      setOfflineRefreshToken(null);
      setAccessToken(null);
      await apolloClient.clearStore();
      await apolloClient.resetStore();
    },
    onError: async () => {
      setOfflineRefreshToken(null);
      setAccessToken(null);
      await apolloClient.clearStore();
      await apolloClient.resetStore();
    },
  });

  const logout = useCallback(async () => {
    logoutMutation();
  }, [logoutMutation]);

  return {
    loading,
    logout,
  };
};
