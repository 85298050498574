import { AuthUserFragment, ManageMyProfileInput, } from "Apollo/graphql";
import { useMemo, } from "react";
import { createPhonePrefixOption, PhonePrefixOption, } from "Utils/options/useOptionsPhonePrefix";
import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { REGEX_PHONE_NUMBER, } from 'Utils/constants';

export type ManageMyProfileFormValues = {
  firstName: string,
  lastName: string,
  email: string,
  phonePrefix: PhonePrefixOption | null,
  phoneNumber: string,
};

export const mapFormEditToVariables = (values: ManageMyProfileFormValues): ManageMyProfileInput => ({
  firstName: values.firstName,
  lastName: values.lastName,
  email: values.email,
  phonePrefix: values.phonePrefix?.id || '',
  phoneNumber: values.phoneNumber,
});

export const useInitUserValues = (user: AuthUserFragment): ManageMyProfileFormValues => useMemo<ManageMyProfileFormValues>(
  () => ({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phonePrefix: createPhonePrefixOption(user.phonePrefix),
    phoneNumber: user.phoneNumber,
  }),
  [ user, ],
);

export const profileEditFormValidationSchema = yup.object().shape({
  firstName: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  lastName: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  email: yup.string()
    .email(INPUT_FORM_ERRORS.EMAIL)
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  phonePrefix: yup.object()
    .nullable()
    .required(INPUT_FORM_ERRORS.REQUIRED),
  phoneNumber: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .matches(REGEX_PHONE_NUMBER, INPUT_FORM_ERRORS.PHONE)
    .max(25, INPUT_FORM_ERRORS.STRING_MAX),
});
