import { ReactNode, useCallback, useMemo, useState, } from 'react';
import { FormBlockerContext, } from './FormBlockerContext';

interface Props {
  children: ReactNode;
}

const FormBlockerProvider = ({ children, }: Props): JSX.Element => {
  const [ state, setState, ] = useState<number>(0);
  const addBlocker = useCallback(
    () => setState((s) => s + 1),
    [],
  );
  const removeBlocker = useCallback(
    () => setState((s) => s - 1),
    [],
  );
  
  const value = useMemo(
    () => ({
      blockerCount: state,
      isBlocked: state > 0,
      addBlocker,
      removeBlocker,
    }),
    [ state, removeBlocker, addBlocker, ],
  );

  return (
    <FormBlockerContext.Provider
      value={value}
    >
      {children}
    </FormBlockerContext.Provider>
  );
};

export default FormBlockerProvider;
