import { yupResolver, } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { AvailableVoucherType, AvailableVoucherTypesQueryResult, VoucherType, } from 'Apollo/graphql';
import { FormInputGroupRadio, FormSubmit, } from 'Components/Form';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import { getOptionFieldId, } from 'Utils/helpers';
import { useState, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import CreateAlzaVoucher from './CreateAlzaVoucher';
import CreateStandart from './CreateStandart';
import { voucherTypeValidationSchema, } from './helpers';
import CreateSodexoVoucher from './CreateSodexoVoucher';

interface Props {
  availableTypes: AvailableVoucherTypesQueryResult;
  onClose: () => void;
}

const VoucherTypeDirector = ({ availableTypes, onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const [type, setType,] = useState<AvailableVoucherType | null>(null);
  const methods = useForm({
    defaultValues: { type: null, },
    resolver: yupResolver(voucherTypeValidationSchema),
    mode: 'onTouched',
  });
  const handleSubmit = methods.handleSubmit(async (values) => {
    setType(() => (values.type ? values.type : null));
  });
  if (type?.id === VoucherType.Regular) {
    return <CreateStandart onClose={onClose} />;
  }
  if (type?.id === VoucherType.Alza) {
    return <CreateAlzaVoucher onClose={onClose} />;
  }
  if (type?.id === VoucherType.Sodexo) {
    return <CreateSodexoVoucher onClose={onClose} />;
  }
  return (
    <ModalDrawerBody>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <FormInputGroupRadio<AvailableVoucherType>
            name="type"
            getOptionLabel={getOptionFieldId}
            getOptionKey={(o) => o.labelKey}
            options={availableTypes.data?.getAvailableVoucherTypes || []}
            loading={availableTypes.loading}
            label={t('vouchers.form.type')}
          />
          <Box mt={4}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <FormSubmit size="large">{t('createVoucherModal.btnSubmit')}</FormSubmit>
              </Grid>
              <Grid item>
                <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
                  {t('common:actions.cancel')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </ModalDrawerBody>
  );
};

export default VoucherTypeDirector;
