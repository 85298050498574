import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link as LinkRouter, } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import AppContainer from 'Components/AppContainer';
import ModalDeleteTask from 'Modules/TasksAdmin/modals/ModalDeleteTask';
import ModalExportTask from 'Modules/TasksAdmin/modals/ModalExportTask';
import { useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { ROUTES, } from 'Utils/constants';
import PlusIcon from 'Utils/svg/PlusIcon';
import Tasks from './General/GeneralTasks';
import ObligatoryTasks from './Obligatory/ObligatoryTasks';

const TasksAdminPage = (): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const [value, setValue,] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <AppContainer>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2} paddingBottom={2}>
      <Grid item>
        <Typography variant="h1">{value === 0 ? t('tasks.title') : t('obligatoryTasks.title')}</Typography>
      </Grid>
      <Grid item>
        <Button
          component={LinkRouter}
          to={`/${ROUTES.admin}/${ROUTES.tasks}/${ROUTES.create}`}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<PlusIcon />}
        >
          {t('tasks.btnCreate')}
        </Button>
      </Grid>
    </Grid>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t('tasks.title')} />
          <Tab label={t('obligatoryTasks.title')} />
        </Tabs>
        {value === 0 ? <Tasks /> : <ObligatoryTasks />}
      </AppContainer>
      <ModalDeleteTask />
      <ModalExportTask />
    </>
  );
};

export default TasksAdminPage;
