import { ReplacementFragment, } from 'Apollo/graphql';
import { Replacement, } from 'Modules/ReplacementSeller/store/ReplacementStore';

export const mapReplacementToReplacementState = (
  replacement?: ReplacementFragment | null
): Replacement => ({
  id: replacement?.id || null,
  username: replacement?.author.username || null,
  status: replacement?.status || null,
  createdAt: replacement?.createdAt || null,
  updatedAt: replacement?.updatedAt || null,
  category: replacement?.category.name || null,
  categoryItems: replacement?.category || null,
  customer: replacement?.customer || null,
  replacementRegistration: replacement?.replacementRegistration || null,
  reason: replacement?.reason || null,
  exchangedProducts: replacement?.replacedAssortments.map(assortment => ({
    count: assortment.count,
    option: assortment.assortment,
  })) || [],
  exchangeProductsWhenComplete: replacement?.replacedAssortmentsWhenCompleted.map(assortment => ({
    count: assortment.count,
    option: assortment.assortment,
  })) || [],
  address: replacement?.address || '',
  batchCode: replacement?.replacedAssortments[0].batchCode || '',
});
