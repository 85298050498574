import { Voucher, } from 'Apollo/graphql';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { useVoucherStore, VoucherStore, } from 'Modules/Vouchers/store/VoucherStore';
import Logic from './Logic';

const selectModalData = (s: VoucherStore) => s.modalRemoveVoucher;
const selectClose = (s: VoucherStore) => s.closeModalRemoveVoucher;
const selectCloseDetail = (s: VoucherStore) => s.closeModalDetailVoucher;

const ModalRemoveVoucher = (): JSX.Element => {
  const { isOpen, voucher, } = useVoucherStore(selectModalData);
  const onClose = useVoucherStore(selectClose);
  const onCloseLogic = useVoucherStore(selectCloseDetail);
  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!voucher}
    >
      <Logic
        voucher={voucher as Voucher}
        onClose={onClose}
        onCloseLogic={onCloseLogic}
      />
    </ModalDrawer>
  );
};

export default ModalRemoveVoucher;
