import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSnackbar, } from 'notistack';
import { useEraseAllUserDataPermanentlyMutation, UserFragment, } from 'Apollo/graphql';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import { updateAllUserDataRemove, } from 'Modules/Users/utils/userCache';
import View from './View';

interface Props {
  user?: UserFragment | null,
  onCloseErase: () => void,
  onCloseDetail: () => void,
};

const Logic = ({
  user,
  onCloseErase,
  onCloseDetail,
}: Props): JSX.Element => {
  const preservedUser = usePreserveValue(user);
  const { t, } = useTranslation('users');
  const { enqueueSnackbar, } = useSnackbar();

  const [ removeMutation, removeMutationResult, ] = useEraseAllUserDataPermanentlyMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('eraseAllUserDataModal.notifRemovedTitle') as string,
        message: t('eraseAllUserDataModal.notifRemovedMessage', { username: preservedUser.username, }),
        variant: 'common',
      });
      onCloseErase();
      onCloseDetail();
    },
    update: updateAllUserDataRemove,
  });

  const handleSubmit = useCallback(
    () => {
      removeMutation({
        variables: { id: preservedUser.id, },
      });
    },
    [ removeMutation, preservedUser.id, ],
  );

  return (
    <View
      user={preservedUser}
      removeMutationResult={removeMutationResult}
      onClose={onCloseErase}
      onSubmit={handleSubmit}
    />
  );
};

export default Logic;
