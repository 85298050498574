/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, } from 'react';
import * as yup from 'yup';
import { Descendant, } from 'slate';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { ContentBlockOption, } from 'Utils/options/useOptionsContentBlock';
import { ContentBlockTypeEnum, } from 'Apollo/graphql';
import { isRichTextEmpty, } from 'Components/RichText/utils';
import { NodesObj, NodeKeys, Node, NodeSchemaObj, ContentValues, } from './types';

export const NODES: NodesObj = {
  default: () => ({
    type: null,
  }),
  [ContentBlockTypeEnum.Text]: () => ({
    type: null,
    value: null,
  }),
  [ContentBlockTypeEnum.Photo]: () => ({
    type: null,
    value: null,
  }),
  [ContentBlockTypeEnum.Video]: () => ({
    type: null,
    value: null,
  }),
};

export const getNodeInitValue = (type: ContentBlockOption | null): Node => {
  if (type && Object.prototype.hasOwnProperty.call(NODES, type.id)) return ({
    ...NODES[type.id as NodeKeys](),
    type,
  });
  return NODES.default();
};

const NODES_SCHEMA: NodeSchemaObj = {
  default: () => yup.object().shape({
    type: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
  }),
  [ContentBlockTypeEnum.Text]: () => yup.object().shape({
    type: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    value: yup.mixed()
      .nullable()
      .test({
        message: INPUT_FORM_ERRORS.REQUIRED,
        test: (value) => !isRichTextEmpty(value as Descendant[] | null),
      }),
  }),
  [ContentBlockTypeEnum.Photo]: () => yup.object().shape({
    type: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    value: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
  }),
  [ContentBlockTypeEnum.Video]: () => yup.object().shape({
    type: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    value: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
  }),
};

const schemaNode = yup.lazy((value: Node) => {
  const { type, } = value;
  if (type && Object.prototype.hasOwnProperty.call(NODES_SCHEMA, type.id)) return NODES_SCHEMA[type.id as NodeKeys]();
  return NODES_SCHEMA.default();
});

export const contentValidationSchema = yup.object().shape({
  nodes: yup.array().of(schemaNode as any),
});

export const useInitValues = (contentValues: ContentValues): ContentValues => useMemo<ContentValues>(
  () => contentValues.nodes.length < 1
    ? { nodes: [ NODES.default(), ], }
    : contentValues,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [],
);
