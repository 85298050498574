import { useTranslation, } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AuthUserFragment, ChainFragment, PointOfSellOptionFragment, useChainsQuery, UserSortableFields, } from 'Apollo/graphql';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import TableCellSortable from 'Components/Table/TableCellSortable';
import { useOptionsUserRoles, UserRolesOption, } from 'Utils/options/useOptionsUserRoles';
import { areOptionsEqualById, getOptionFieldId, renderOptionById, } from 'Utils/helpers';
import { useSearchOptionsPos, } from 'Utils/options/useSearchOptionsPos';

const COL_WIDTHS = [
  { width: '220px', },
  { width: '150px', },
  { width: '150px', },
  { width: '150px', },
  { width: '200px', },
  { width: '110px', },
  { width: '150px', },
  { width: '125px', },
  { width: '125px', },
  { width: '45px', },
];

interface Props {
  authUser: AuthUserFragment | null,
};

const UsersTableHeader = ({
  authUser,
}: Props): JSX.Element => {
  const { t, } = useTranslation('users');
  const userRolesOptions = useOptionsUserRoles();
  const chainOptions = useChainsQuery();
  const posOptions = useSearchOptionsPos();

  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;
  const showPosIDs = authUser?.featureFlagsObject.FEATURE_FLAG_SHOW_POS_IDS_IN_USER_DASHBOARD;

  return (
    <TableHead>
      <TableRow>
        <TableCellSortable
          style={COL_WIDTHS[0]}
          name={UserSortableFields.Username}
        >
          {t('users.table.labelUsername')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[1]}
          name={UserSortableFields.PosId}
        >
          {t('users.table.labelPosId')}
        </TableCellSortable>
        {showPosIDs && (
          <TableCell
            style={COL_WIDTHS[2]}
          >
            {t('users.table.labelPosIds')}
          </TableCell>
        )}
        {showChainIDs &&
          <TableCellSortable
            style={COL_WIDTHS[3]}
            name={UserSortableFields.ChainId}
          >
            {t('users.table.labelChainId')}
          </TableCellSortable>
        }
        <TableCellSortable
          style={COL_WIDTHS[4]}
          name={UserSortableFields.Role}
        >
          {t('users.table.labelRole')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[5]}
          name={UserSortableFields.FirstName}
        >
          {t('users.table.labelFirstName')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[6]}
          name={UserSortableFields.LastName}
        >
          {t('users.table.labelLastName')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[7]}
          name={UserSortableFields.PhoneNumberWithPrefix}
        >
          {t('users.table.labelPhone')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[8]}
          name={UserSortableFields.WantsToBeDeleted}
        >
          {t('users.table.labelWantsToBeDeleted')}
        </TableCellSortable>
        <TableCell
          style={COL_WIDTHS[9]}
        />
      </TableRow>
      <TableRow>
        <TableCell>
          <TableFilterText
            name="username"
          />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<PointOfSellOptionFragment>
            name="pos"
            options={posOptions.options}
            getOptionLabel={posOptions.getOptionLabel}
            isOptionEqualToValue={posOptions.isOptionEqualToValue}
            renderOption={renderOptionById(posOptions.getOptionLabel)}
            filterOptions={posOptions.filterOptions}
            loading={posOptions.loading}
            onInputChange={posOptions.onInputChange}
            noOptionsText={posOptions.noOptionsText}
          />
        </TableCell>
        {showPosIDs && (
          <TableCell />
        )}
        {showChainIDs &&
        <TableCell>
          <TableFilterAutocomplete<ChainFragment>
            name="chain"
            options={chainOptions.data?.chains || []}
            getOptionLabel={getOptionFieldId}
            isOptionEqualToValue={areOptionsEqualById}
            renderOption={renderOptionById(getOptionFieldId)}
          />
        </TableCell>}
        <TableCell>
          <TableFilterAutocomplete<UserRolesOption>
            name="role"
            options={userRolesOptions.options}
            getOptionLabel={userRolesOptions.getOptionLabel}
            isOptionEqualToValue={userRolesOptions.isOptionEqualToValue}
            renderOption={renderOptionById(userRolesOptions.getOptionLabel)}
            loading={userRolesOptions.loading}
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="firstName"
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="lastName"
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="phone"
          />
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default UsersTableHeader;
