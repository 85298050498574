import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const AdministrationColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx={8.571} cy={5.143} r={5.143} fill="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.857 24a5.143 5.143 0 1 0 0-10.285 5.143 5.143 0 0 0 0 10.286Zm.924-6.413-.924-2.843-.924 2.843h-2.989l2.419 1.756-.924 2.843 2.418-1.757 2.419 1.757-.924-2.843 2.418-1.756h-2.99Z"
      fill="#D47E5F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.71 20.59h7.86v.005h4.018A5.976 5.976 0 0 1 12 18.001c0-1.876.86-3.55 2.209-4.65-1.178-1.092-2.644-1.797-4.41-2.088a7.7 7.7 0 0 0-5.336.996C1.69 13.924.175 16.45.005 19.814c-.032.635.094.777.705.777Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.248 14.232s-1.39 2.95-1.39 5.134c0 .292 3.347.372 3.347.024 0-2.22-1.391-5.158-1.391-5.158l.96-.755C9.773 12.767 8.53 12 8.53 12s-1.245.755-1.245 1.477l.962.755Z"
      fill="#D47E5F"
    />
  </SvgIcon>
);

export default AdministrationColoredIcon;
