import { useMemo, } from 'react';
import { TextContentBlockFragment, } from 'Apollo/graphql';
import RichText from 'Components/RichText';
import { stringToRichText, } from 'Components/RichText/tools';
import { BlockProps, } from '../types';

const BlockText = ({
  block,
}: BlockProps<TextContentBlockFragment>): JSX.Element => {
  const initValue = useMemo(
    () => stringToRichText(block.text),
    [ block.text, ],
  );

  return (
    <RichText
      initValue={initValue}
      readOnly
    />
  );
};

export default BlockText;
