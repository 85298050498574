import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { createUploadLink } from 'apollo-upload-client';
import { getAccessToken } from 'Tools/auth';
import { isDevelopment } from 'Utils/environment';
import * as Sentry from '@sentry/react';
import { onError } from '@apollo/client/link/error';
import { SentryLink } from 'apollo-link-sentry';
import { AuthTokenFragment } from './graphql';
import {
  fetchRefresh,
  handleRefreshCompleted,
  handleRefreshError,
  isAccessTokenValidOrUndefined,
} from './utils';

const SentryReportLink = onError(
  ({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, extensions }) =>
        Sentry.captureMessage(message, {
          level: 'error',
          extra: {
            extensions,
          },
        }),
      );
    if (networkError) {
      Sentry.captureException(networkError, {
        extra: {
          response,
        },
      });
    }
  },
);

const createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const httpTerminatingLink = createUploadLink({
    uri: import.meta.env.VITE_APP_HTTP_LINK,
    credentials: 'include',
  });

  const httpAuthLink = new ApolloLink((operation, forward) => {
    const accessToken = getAccessToken();
    operation.setContext(({ headers = {} }) => ({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
    }));
    return forward(operation);
  });

  const refreshLink = new TokenRefreshLink<AuthTokenFragment>({
    accessTokenField: 'refreshToken',
    isTokenValidOrUndefined: isAccessTokenValidOrUndefined,
    fetchAccessToken: fetchRefresh,
    handleFetch: handleRefreshCompleted,
    handleError: handleRefreshError,
  });

  const combinedLinks = from([
    new SentryLink(),
    SentryReportLink,
    refreshLink,
    httpAuthLink,
    httpTerminatingLink,
  ]);

  const apolloClient = new ApolloClient({
    link: combinedLinks,
    connectToDevTools: isDevelopment(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
      },
    },
    cache: new InMemoryCache({
      possibleTypes: {
        ContentBlock: [
          'PhotoContentBlock',
          'VideoContentBlock',
          'TextContentBlock',
        ],
        AssessmentBlock: [
          'CheckboxAssessmentBlock',
          'NumberAssessmentBlock',
          'PhotoAssessmentBlock',
          'RadioAssessmentBlock',
          'ScaleAssessmentBlock',
          'TextAssessmentBlock',
          'YesNoAssessmentBlock',
        ],
      },
      typePolicies: {
        Query: {
          fields: {
            assortments: {
              keyArgs: false,
              merge: (existing, incoming) => incoming,
            },
            promotions: {
              keyArgs: false,
              merge: (existing, incoming) => incoming,
            },
            customers: {
              keyArgs: false,
              merge: (existing, incoming) => incoming,
            },
            purchases: {
              keyArgs: false,
              merge: (existing, incoming) => incoming,
            },
            pointsOfSell: {
              keyArgs: false,
              merge: (existing, incoming) => incoming,
            },
            users: {
              keyArgs: false,
              merge: (existing, incoming) => incoming,
            },
            pointsTransactions: {
              keyArgs: false,
              merge: (existing, incoming) => incoming,
            },
            replacements: {
              keyArgs: false,
              merge: (existing, incoming) => incoming,
            },
            newsArticles: {
              keyArgs: false,
              merge: (existing, incoming) => incoming,
            },
            vouchers: {
              keyArgs: false,
              merge: (existing, incoming) => incoming,
            },
          },
        },
        File: {
          keyFields: false,
        },
      },
    }),
  });

  return apolloClient;
};

export const apolloClient = createApolloClient();
