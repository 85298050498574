import { useState, } from 'react';
import AppContainer from 'Components/AppContainer';
import FormLogic from './FormLogic';
import PasswordChangedView from './PasswordChangedView';

const ResetPasswordPage = (): JSX.Element => {
  const [ isChangedPassword, setChangedPassword, ] = useState<boolean>(false);
  const changePasswordCompleted = () => setChangedPassword(true); 

  return (
    <AppContainer maxWidth="sm">
      {isChangedPassword ? (
        <PasswordChangedView />
      ) : (
        <FormLogic
          changePasswordCompleted={changePasswordCompleted}
        />
      )}
    </AppContainer>
  );
};

export default ResetPasswordPage;
