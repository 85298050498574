import { yupResolver, } from '@hookform/resolvers/yup';
import { CurrentUserQuery, useManageMyProfileMutation, } from 'Apollo/graphql';
import { useSnackbar, } from 'notistack';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { mapFormEditToVariables, profileEditFormValidationSchema, useInitUserValues, } from '../utils/editMyProfileForm';
import View from './View';

type Props = {
    data: CurrentUserQuery;
};

const Logic = ({
    data,
}: Props): JSX.Element => {
  const { t, } = useTranslation('myProfile');
  const {currentUser,} = data;
  const initValues = useInitUserValues(currentUser);
  const { enqueueSnackbar, } = useSnackbar();
  const methods = useForm({
    defaultValues: initValues,
    resolver: yupResolver(profileEditFormValidationSchema),
    mode: 'onTouched',
  });

  const [ editMutation, editMutationResult, ] = useManageMyProfileMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('editedMessageTitle') as string,
        message: t('editedMessage') as string,
        variant: 'common',
      });
    },
    onError: () => {
      // TODO: merge form errors
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await editMutation({
      variables: {
        manageMyProfileInput: mapFormEditToVariables(values),
      },
    });    
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View
          editMutationResult={editMutationResult}
        />
      </form>
    </FormProvider>
  );
};

export default Logic;
