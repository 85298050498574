import shallow from 'zustand/shallow';
import { useCompleteReplacementMutation, } from 'Apollo/graphql';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementSeller/store/ReplacementStore';
import View from './View';
import { mapReplacementToVariables, } from './utils';
import { mapReplacementToReplacementState, } from '../utils';

const selectStoreData = (s: ReplacementStore) => ({
  goToPhase: s.goToPhase,
  replacement: s.replacement,
  updateReplacement: s.updateReplacement,
});

const ReplacementLogistic = () => {
  const {
    goToPhase,
    replacement,
    updateReplacement,
  } = useReplacementStore(selectStoreData, shallow);

  const [ completeMutation, completeMutationResult, ] = useCompleteReplacementMutation({
    onCompleted: ({ completeReplacement, }) => {
      goToPhase('replacementClosed');
      updateReplacement(mapReplacementToReplacementState(completeReplacement));
    },
  });

  const handleCloseTicket = () => {
    completeMutation({
      variables: {
        replacementInput: mapReplacementToVariables(replacement),
      },
    });    
  };

  return (
    <View
      closeTicket={handleCloseTicket}
      completeMutationResult={completeMutationResult}
    />
  );
};

export default ReplacementLogistic;
