import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { ErrorCodeEnum, LoginMutationVariables, } from 'Apollo/graphql';
import { ApolloCustomGqlErrors, } from 'Components/Errors/types';

export type LoginStandardFormValues = {
  name: string,
  password: string,
};

export const initLoginOtherFormValues: LoginStandardFormValues = {
  name: '',
  password: '',
};

export const loginOtherFormValidationSchema = yup.object().shape({
  name: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(2048, INPUT_FORM_ERRORS.STRING_MAX),
  password: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(2048, INPUT_FORM_ERRORS.STRING_MAX),
});

export const mapFormToVariables = (values: LoginStandardFormValues): LoginMutationVariables => ({
  loginInput: {
    username: values.name,
    password: values.password,
  },
});

export const CUSTOM_LOGIN_GQL_ERRORS: ApolloCustomGqlErrors = {
  [ErrorCodeEnum.BadLoginInput]: (gqlError, t) => ({
    title: null,
    text: t('auth:login.errorInvalidPasswordOrUsername'),
  }),
};
