import ModalDetailPointOfSell from 'Modules/PointOfSell/modals/ModalDetailPointOfSell';
import ModalExportPointOfSells from 'Modules/PointOfSell/modals/ModalExportPointOfSell';
import ModalImportPos from 'Modules/PointOfSell/modals/ModalImportPos';
import PointOfSellTable from './PointOfSellTable';

const PointOfSellPage = (): JSX.Element => {
  return (
    <>
      <PointOfSellTable />

      <ModalExportPointOfSells />
      <ModalImportPos />
      <ModalDetailPointOfSell />
    </>
  );
};

export default PointOfSellPage;
