import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { styled, } from '@mui/material/styles';
import { PromotionAvailabilityFragment, } from 'Apollo/graphql';
import IconButtonSquare from 'Components/IconButtonSquare';
import { PromotionCategoryPurchaseObject, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import KeyboardArrowUpIcon from 'Utils/svg/KeyboardArrowUpIcon';
import MinusIcon from 'Utils/svg/MinusIcon';
import PlusIcon from 'Utils/svg/PlusIcon';
import type { AnyObject, } from 'Utils/types';
import { MouseEventHandler, ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import { ObjValue, OnChange, } from './types';

const StyleCard = styled(Card)(({ theme, }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  marginBottom: 1,
  borderRadius: 0,

  '& .MuiTypography-root': {
    fontSize: 16,
    lineHeight: '22px',
  },
}));

const StyledTypography = styled(Typography)(({ theme, }) => ({
  marginRight: theme.spacing(1),
  userSelect: 'none',
}));

const StyleValueContainer = styled('div')(({ theme, }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: 130,
}));

const StyledValue = styled('span')(({ theme, }) => ({
  marginRight: theme.spacing(0.25),
  marginLeft: theme.spacing(0.25),
  fontSize: 18,
  lineHeight: '22px',
  letterSpacing: '0.25px',
  userSelect: 'none',
  minWidth: 42,
  textAlign: 'center',
}));

const StyledMax = styled('span')(() => ({
  fontSize: 12,
  userSelect: 'none',
  color: '#808080',
}));

interface Props<T extends AnyObject,> {
  option: T;
  onChange: OnChange<T>;
  objValue: ObjValue<T>;
  getOptionLabel: (o: T) => ReactNode;
  getOptionMax?: (o: T) => number | null | undefined;
  getOptionKey: (o: T) => string;
  completePurchasePhase: () => void;
  setActivePromotionPurchase: (index: number, id: string) => void;
  promotionCategoryPurchaseList: PromotionCategoryPurchaseObject;
  handleChangeInPromotionPurchaseForm: (option: PromotionAvailabilityFragment, toggle: "add" | "remove") => void;
}
type ButtonProps = {
  id: string;
  index: number;
  isActive: boolean;
};

const evaluateInputExistence = (index: number,promotionCategoryPurchaseList:PromotionCategoryPurchaseObject,id:string) => {
  const promotionCategory = promotionCategoryPurchaseList[id];
  return promotionCategory[index].isValid;
};

const ItemInput = <T extends AnyObject,>({
  option,
  objValue,
  onChange,
  getOptionLabel,
  getOptionMax,
  getOptionKey,
  completePurchasePhase,
  setActivePromotionPurchase,
  promotionCategoryPurchaseList,
  handleChangeInPromotionPurchaseForm,
}: Props<T>): JSX.Element => {
  const { t, } = useTranslation();
  const max = getOptionMax?.(option);
  const label = getOptionLabel(option);
  const count: number = objValue[getOptionKey(option)]?.count || 0;
  const isMaxActive = max !== undefined && max !== null;
  const disabledPlus: boolean = isMaxActive && (count || 0) >= max;
  const disabledMinus: boolean = !count;

  const { id, } = (option as unknown as PromotionAvailabilityFragment).promotion;

  // detail button indexing
  const objKeys = Object.keys(objValue);
  const currentIndex = objKeys.findIndex((element)=> element === id);

  let totalCount = 0;
  for (let i = 0; i < currentIndex; i++) {
    totalCount += objValue[objKeys[i]]?.count || 0;
  }

  const handlePlus: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (!disabledPlus) {
      handleChangeInPromotionPurchaseForm(option as unknown as PromotionAvailabilityFragment,"add");
      onChange({
        option,
        count: count + 1,
      });
    }
  };
  const handleMinus: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    if (!disabledMinus) {
      handleChangeInPromotionPurchaseForm(option as unknown as PromotionAvailabilityFragment,"remove");
      onChange({
        option,
        count: count - 1,
      });
    }
  };

  const result: ButtonProps[] = [];
  for (let i = 0; i < count; i++) {
    const isActive = evaluateInputExistence(i,promotionCategoryPurchaseList,id);
    result.push({
      id,
      index: i,
      isActive,
    });}

  const handlePromotionDetail = (index: number) => {
    setActivePromotionPurchase(index, id);
    completePurchasePhase();
  };
  

  return (
    <>
      <StyleCard>
        <StyledTypography variant="subtitle1" color="primary">
          {label}
        </StyledTypography>
        <StyleValueContainer>
          <IconButtonSquare color="primary" onClick={handleMinus} disabled={disabledMinus}>
            <MinusIcon />
          </IconButtonSquare>
          <StyledValue>
            {count}
            {isMaxActive && <StyledMax>{`/${max < 0 ? 0 : max}`}</StyledMax>}
          </StyledValue>
          <IconButtonSquare color="secondary" onClick={handlePlus} disabled={disabledPlus}>
            <PlusIcon />
          </IconButtonSquare>
        </StyleValueContainer>
      </StyleCard>

      {result.map((props) => (
        <StyleCard key={`${props.id}${props.index}`}>
          <StyledTypography variant="subtitle1" color="primary">
            {`${t('registrationSeller:phasePurchase.selectPromotionProducts')} ${totalCount + props.index + 1}`}
          </StyledTypography>

          <IconButtonSquare
            key={`${props.id}${props.index}`}
            color={props.isActive ? 'success' : 'error'}
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              handlePromotionDetail(props.index);
            }}
          >
            <KeyboardArrowUpIcon sx={{transform: "rotate(90deg)",}} />
          </IconButtonSquare>
        </StyleCard>
      ))}
    </>
  );
};

export default ItemInput;
