import { useTranslation, } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { NewsArticleSortableFields, useChainsQuery, ChainFragment, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, renderOptionById, } from 'Utils/helpers';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import TableCellSortable from 'Components/Table/TableCellSortable';
import { useAuthUser, } from 'Tools/auth';

const COL_WIDTHS = [
  { width: '150px', },
  { width: '150px', },
  { width: '250px', },
  { width: '190px', },
  { width: '150px', },
  { width: '130px', },
  { width: '45px', },
];

const NewsArticlesTableHeader = (): JSX.Element => {
  const { t, } = useTranslation('newsArticlesAdmin');
  
  const chainsResult = useChainsQuery({ fetchPolicy: 'cache-and-network', });

  const authUser = useAuthUser();
  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;

  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={COL_WIDTHS[0]}
        >
          {t('newsArticles.table.labelNewsArticleId')}
        </TableCell>
        <TableCellSortable
          style={COL_WIDTHS[1]}
          name={NewsArticleSortableFields.Title}
        >
          {t('newsArticles.table.labelTitle')}
        </TableCellSortable>
        <TableCell
          style={COL_WIDTHS[2]}
        >
          {t('newsArticles.table.labelDescription')}
        </TableCell>
        <TableCellSortable
          style={COL_WIDTHS[3]}
          name={NewsArticleSortableFields.CreatedAt}
        >
          {t('newsArticles.table.labelCreatedAt')}
        </TableCellSortable>
        {showChainIDs &&
        <TableCell
          style={COL_WIDTHS[4]}
        >
          {t('newsArticles.table.labelChainId')}
        </TableCell>}
        <TableCellSortable
          style={COL_WIDTHS[5]}
          name={NewsArticleSortableFields.AuthorId}
        >
          {t('newsArticles.table.labelAuthorId')}
        </TableCellSortable>
        <TableCell
          style={COL_WIDTHS[6]}
        />
      </TableRow>
      <TableRow>
        <TableCell>
          <TableFilterText
            name="newsArticleId"
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="title"
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="description"
          />
        </TableCell>
        <TableCell />
        {showChainIDs &&
        <TableCell>
          <TableFilterAutocomplete<ChainFragment>
            name="chainId"
            getOptionLabel={getOptionFieldId}
            isOptionEqualToValue={areOptionsEqualById}
            renderOption={renderOptionById(getOptionFieldId)}
            options={chainsResult.data?.chains || []}
            loading={chainsResult.loading}
          />
        </TableCell>}
        <TableCell>
          <TableFilterText
            name="authorId"
          />
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default NewsArticlesTableHeader;
