import { Stack, } from '@mui/material';
import { styled, } from '@mui/material/styles';
import {
  ChainsQueryVariables,
  DashboardLayoutItemName,
  useAddItemToUserDashboardLayoutMutation,
  useRemoveItemFromUserDashboardLayoutMutation,
} from 'Apollo/graphql';
import Filtration from 'Utils/Charts/Filtration';
import { OptionTypes, } from 'Utils/Charts/types';
import PlusIcon from 'Utils/svg/PlusIcon';
import { useSnackbar, } from 'notistack';
import { useTranslation, } from 'react-i18next';

const StyledBase = styled('div')<{ isOnPage?: boolean }>(
  ({ theme, isOnPage = false, }) => ({
    padding: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(2.5),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    minHeight: 392,
    maxHeight: isOnPage ? 'unset' : 392,
    [theme.breakpoints.up('md')]: {
      aspectRatio: 'unset',
    },
    width: '100%',
  }),
);
const StyledHeader = styled('h3')(({ theme, }) => ({
  margin: 0,
  fontSize: 16,
  textWrap: 'nowrap',
}));
const StyledSubHeader = styled('span')(({ theme, }) => ({
  margin: 0,
  fontSize: 14,
  fontWeight: 600,
  color: theme.palette.text.secondary,
}));
const StyledLink = styled('a')(({ theme, }) => ({
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: theme.palette.text.secondary,
  transition: 'color 0.2s',
  fontSize: 14,
  fontWeight: 600,
  '&:hover': {
    color: '#C37D5A',
  },
}));
const StyledButton = styled('button')(({ theme, }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  padding: 0,
  transition: 'opacity 0.2s',
  '&:hover': {
    opacity: 0.4,
  },
}));

const StyledStack = styled(Stack)<{ filtration: boolean }>(
  ({ theme, filtration, }) => ({
    display: 'flex',
    flexDirection: filtration ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 24,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  }),
);

const StyledFiltrationWrapper = styled(Stack)(({ theme, }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
  },
}));

interface Props {
  children: React.ReactNode;
  headerText?: string;
  headerSubText?: string;
  showAddButton?: boolean | null;
  slug?: string;
  filtration?: OptionTypes[];
  dashboardLayoutItemName?: DashboardLayoutItemName;
  chainsVariables?: ChainsQueryVariables;
}

const Base = ({
  children,
  headerText,
  headerSubText,
  showAddButton = null,
  slug,
  filtration,
  dashboardLayoutItemName,
  chainsVariables,
}: Props) => {
  const { t, } = useTranslation('dashboards');

  const [removeItemFromUserDashboardLayoutMutate,] =
    useRemoveItemFromUserDashboardLayoutMutation({
      refetchQueries: ['UserDashboardLayout',],
    });

  const [addItemToUserDashboardLayoutMutate,] =
    useAddItemToUserDashboardLayoutMutation({
      refetchQueries: ['UserDashboardLayout',],
    });

    const { enqueueSnackbar, } = useSnackbar();

  return (
    <StyledBase isOnPage={!!filtration}>
      {(headerText || filtration || showAddButton !== null) && (
        <StyledStack filtration={!!filtration}>
          <Stack>
            <StyledHeader>{headerText}</StyledHeader>
            {StyledSubHeader && (
              <StyledSubHeader>{headerSubText}</StyledSubHeader>
            )}
          </Stack>
          {showAddButton && (
            <StyledButton
              type="button"
              onClick={() => {
                if (!dashboardLayoutItemName) return;
                addItemToUserDashboardLayoutMutate({
                  variables: {
                    dashboardItemId: dashboardLayoutItemName,
                  },
                  onCompleted: () => {
                    enqueueSnackbar({
                      title: t('dashboards.snackbar.dashboardAdded') as string,
                      variant: 'common',
                    });
                  },
                  onError: () => {
                    enqueueSnackbar({
                      title: t('dashboards.snackbar.error') as string,
                      variant: 'common',
                    });
                  },
                });
              }}
            >
              <PlusIcon style={{ color: '#00000099', }} />
            </StyledButton>
          )}
          {showAddButton === false && (
            <StyledButton
              type="button"
              onClick={() => {
                if (!dashboardLayoutItemName) return;
                removeItemFromUserDashboardLayoutMutate({
                  variables: {
                    dashboardItemId: dashboardLayoutItemName,
                  },
                  onCompleted: () => {
                    enqueueSnackbar({
                      title: t('dashboards.snackbar.dashboardRemoved') as string,
                      variant: 'common',
                    });
                  },
                  onError: () => {
                    enqueueSnackbar({
                      title: t('dashboards.snackbar.error') as string,
                      variant: 'common',
                    });
                  },
                });
              }}
            >
              <PlusIcon
                style={{ transform: 'rotate(45deg)', color: '#00000099', }}
              />
            </StyledButton>
          )}
          {filtration && (
            <StyledFiltrationWrapper>
              {filtration.map((filter) => (
                <Filtration key={filter} options={filter} chainsVariables={chainsVariables} />
              ))}
            </StyledFiltrationWrapper>
          )}
        </StyledStack>
      )}
      {children}
      <Stack direction="row" justifyContent="flex-end">
        {slug && (
          <StyledLink href={`/dashboard/${slug}`}>
            {t('dashboards.readMore')}
          </StyledLink>
        )}
      </Stack>
    </StyledBase>
  );
};

export default Base;
