import { useObligatoryTasksQuery, } from 'Apollo/graphql';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import { ObligatoryTaskStore, useObligatoryTaskStore, } from 'Modules/TasksAdmin/store/ObligatoryTaskStore';
import shallow from 'zustand/shallow';
import { mapVariablesObligatory, } from '../utils';
import TasksTableHeader from './ObligatoryTasksTableHeader';
import TasksTableRow from './ObligatoryTasksTableRow';

const selectStoreData = (s: ObligatoryTaskStore) => ({
  tableTasks: s.tableTasks,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,
});

const ObligatoryTasks = (): JSX.Element => {
  const { tableTasks, changeFilter, changeSort, changeData, } = useObligatoryTaskStore(selectStoreData, shallow);

  const { loading, error, data, } = useObligatoryTasksQuery({
    variables: mapVariablesObligatory(tableTasks),
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={8}
      rows={data?.taskCompletions.data.length || 0}
      loading={loading}
      error={error}
      limit={tableTasks.data.limit}
      sortField={tableTasks.data.sortField}
      sortOrder={tableTasks.data.sortOrder}
      offset={tableTasks.data.offset}
      count={data?.taskCompletions.pagination.count}
      filter={tableTasks.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <TasksTableHeader />
      <TableBody>
        {(data?.taskCompletions.data || []).map((row) => (
          <TasksTableRow key={row.id} row={row} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ObligatoryTasks;
