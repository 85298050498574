import { Link as RouterLink, } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';
import { ApolloError, } from '@apollo/client';
import MuiLink from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { FormInputText, FormSubmit, } from 'Components/Form';
import AppContainer from 'Components/AppContainer';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { ROUTES, } from 'Utils/constants';
import { CUSTOM_LOGIN_GQL_ERRORS, } from './utils';

interface Props {
  error?: ApolloError | Error | null,
}

const View = ({
  error,
}: Props): JSX.Element => {
  const { t, } = useTranslation('auth');

  return (
    <AppContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h1"
        marginBottom={3}
      >
        {t('login.title')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormInputText
            name="name"
            autoComplete="username"
            label={t('login.labelUser')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputText
            name="password"
            type="password"
            autoComplete="current-password"
            label={t('login.labelPassword')}
            required
          />
        </Grid>
      </Grid>
      <Box mt={0.5} textAlign="right">
        <MuiLink
          component={RouterLink}
          to={`/${ROUTES.forgottenPassword}`}
        >
          {t('login.linkForgottenPassword')}
        </MuiLink>
      </Box>
      <ErrorAlert
        mt={2}
        error={error}
        apolloCustomGqlErrors={CUSTOM_LOGIN_GQL_ERRORS}
      />
      <Box mt={4}>
        <FormSubmit
          size="large"
          sx={{
            width: { xs: '100%', lg: 'unset', },
          }}
        >
          {t('login.bntSubmit')}
        </FormSubmit>
      </Box>
    </AppContainer>
  );
};

export default View;
