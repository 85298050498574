import { FileFragment, } from 'Apollo/graphql';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import * as yup from 'yup';

export type SodexoVoucherFormValues = {
  name: string;
  description: string;
  image: FileFragment | null;
  notificationsVisible: boolean
};

export const initSodexoVoucherFormValues: SodexoVoucherFormValues = {
  name: '',
  description: '',
  image: null,
  notificationsVisible: false,
};
export const sodexoVoucherFormValidationSchema = yup.object().shape({
  name: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
  description: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(2000, INPUT_FORM_ERRORS.STRING_MAX),
  image: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
  notificationsVisible: yup.boolean().required(INPUT_FORM_ERRORS.REQUIRED),
});
