import { AssessmentMethodEnum, } from 'Apollo/graphql';
import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type TaskAssessmentMethodOption = {
  id: AssessmentMethodEnum,
  labelKey: string,
};

export const TASK_ASSESSMENT_METHOD_OPTIONS: TaskAssessmentMethodOption[] = [
  {
    id: AssessmentMethodEnum.Photo,
    labelKey: 'tasksAdmin:options.taskMethod.PHOTO',
  },
  {
    id: AssessmentMethodEnum.Survey,
    labelKey: 'tasksAdmin:options.taskMethod.SURVEY',
  },
  {
    id: AssessmentMethodEnum.Test,
    labelKey: 'tasksAdmin:options.taskMethod.TEST',
  },
];

type GetOptionLabel = (o: TaskAssessmentMethodOption) => string;
type GetOptionKey = (o: TaskAssessmentMethodOption) => string;

type UseOptionsAssessmentMethodCompletion = () => {
  options: TaskAssessmentMethodOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<TaskAssessmentMethodOption>, 
};

export const useOptionsAssessmentMethod: UseOptionsAssessmentMethodCompletion = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: TASK_ASSESSMENT_METHOD_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
