import { Query, } from '@cubejs-client/core';
import { QueryParams, } from '../types';

export const totalNumberOfSentGiftCodesQuery = ({
  timeDimensions,
  filters = [],
}: QueryParams): Query => ({
  measures: ['imported_pl_gift_code.count',],
  timeDimensions: [
    {
      dateRange: timeDimensions?.[0]?.dateRange,
      dimension:
        timeDimensions?.[0]?.dimension || 'imported_pl_gift_code.sent_at',
      granularity: timeDimensions?.[0]?.granularity || 'year',
    },
  ],
  filters: [
    ...filters,
    {
      member: 'imported_pl_gift_code.status',
      operator: 'equals',
      values: ['requested',],
    },
  ],
});

export const totalNumberOfAvailableGiftCodesQuery = (): Query => ({
  measures: ['imported_pl_gift_code.count',],
  dimensions: ['imported_pl_gift_code.pl_gift_chain_id',],
  timeDimensions: [
    {
      dimension: 'imported_pl_gift_code.expiration_date',
      granularity: 'day',
    },
  ],
  filters: [
    {
      member: 'imported_pl_gift_code.status',
      operator: 'equals',
      values: ['available',],
    },
    {
      member: 'imported_pl_gift_code.expiration_date',
      operator: 'afterOrOnDate',
      values: [new Date().toISOString().split('T')[0],],
    },
  ],
});
