import { useTranslation, } from 'react-i18next';
import CardItem from './CardItem';

type FaqListItem = {
  faqSubtitle: string;
  faqInfo: string;
  id: string;
};

const CreateFaqList = (): JSX.Element | null => {
  const { t, i18n, } = useTranslation();
  const transKey = 'supportSeller:faqObjectList';
  const list =
    i18n.exists(transKey)
    ? (t(transKey, { returnObjects: true, }) as Array<FaqListItem>).filter(item => !item.faqSubtitle.includes('TODO:'))
    : null;

  if (!Array.isArray(list)) return null;

  return (
    <div>
      {list.map((item) => {
        return (
          <CardItem title={item.faqSubtitle} key={item.id}>
            {t(item.faqInfo)}
          </CardItem>
        );
      })}
      </div>
  );
};
export default CreateFaqList;
