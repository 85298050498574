import { PointsTransactionFragment, usePointsTransactionQuery, } from 'Apollo/graphql';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import Logic from './Logic';

interface Props {
  pointsTransaction?: PointsTransactionFragment | null,
  onClose: () => void,
};

const FetchData = ({
  pointsTransaction,
  onClose,
}: Props): JSX.Element => {
  const preservedPointsTransactions = usePreserveValue(pointsTransaction);

  const { loading, error, data, } = usePointsTransactionQuery({
    variables: { id: preservedPointsTransactions.id, },
    fetchPolicy: 'network-only',
  });

  if (loading) return (
    <ModalDrawerBodyLoading
      onClose={onClose}
    />
  );
  if (error || !data?.pointsTransaction) return (
    <ModalDrawerBodyError
      onClose={onClose}
      error={error}
    />
  );
  return (
    <Logic
      pointsTransaction={data.pointsTransaction}
      onClose={onClose}
    />
  );
};

export default FetchData;
