import { CustomerVerificationMethod, } from 'Apollo/graphql';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { REGEX_PHONE_NUMBER, } from 'Utils/constants';
import { UserVerificationOption, } from 'Utils/options/useOptionsUserVerification';
import * as yup from 'yup';

export const formValidationSchema = () =>
  yup.object().shape({
    verificationMethod: yup.object().required(INPUT_FORM_ERRORS.REQUIRED),
    phone: yup
      .string()
      .when('verificationMethod', (verificationMethod:UserVerificationOption) =>
        verificationMethod.id === CustomerVerificationMethod.Sms ? (
          yup
            .string()
            .required(INPUT_FORM_ERRORS.REQUIRED)
            .max(25, INPUT_FORM_ERRORS.STRING_MAX)
            .matches(REGEX_PHONE_NUMBER, INPUT_FORM_ERRORS.PHONE)
        ) : (
          yup
            .string()
            .nullable()
            .notRequired()
        ),
      ),
  });
