import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const RegistrationsColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#registrations-colored)" transform="translate(-.001)">
      <path
        d="M16.366 11.164c2.969 0 5.443 2.475 5.427 5.434a5.424 5.424 0 0 1-10.848 0c-.025-2.963 2.438-5.431 5.421-5.434zm-2.125 6.238v.007h.719c.425.007.575.156.581.59v1.436c.009.542.346.903.828.9.48-.004.81-.365.817-.912v-1.311c0-.578.125-.701.686-.703.448 0 .897.007 1.344 0 .547-.01.9-.346.892-.83-.008-.483-.365-.82-.907-.825h-1.375c-.514 0-.639-.125-.641-.653 0-.47.007-.938 0-1.407-.011-.51-.335-.839-.81-.844-.475-.005-.821.333-.831.855-.01.48 0 .958 0 1.437 0 .479-.144.61-.62.612-.5 0-1-.01-1.5 0a.819.819 0 0 0-.802.832.801.801 0 0 0 .779.812c.28.018.561.004.843.004z"
        fill="#d47e5f"
      />
      <path
        d="M4.08 5.965a3.958 3.958 0 1 1 3.962 3.943 3.964 3.964 0 0 1-3.963-3.943ZM5.98 15.75H2.73c-.449 0-.618-.241-.474-.666.928-2.73 3.375-4.432 6.25-4.332a5.86 5.86 0 0 1 2.616.75c.375.205.43.455.157.783a6.518 6.518 0 0 0-1.426 2.908c-.095.473-.19.558-.663.558z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="registrations-colored">
        <path fill="#fff" transform="translate(2.21 2)" d="M0 0h19.584v20H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default RegistrationsColoredIcon;
