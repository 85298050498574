import { ChangeEventHandler, } from 'react';
import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AnyObject, } from 'Utils/types';
import LoadingBox from 'Components/LoadingBox';
import { Radio, } from '../../Radio';
import { GetOptionKey, GetOptionLabel, } from './types';

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '1rem',
  },
}));

export interface InputGroupRadioClassicProps<T extends AnyObject,> {
  id: string,
  onChange: ChangeEventHandler<HTMLInputElement>,
  loading?:boolean,
  valueKey: string | number | null,
  options: T[],
  getOptionKey: GetOptionKey<T>,
  getOptionLabel: GetOptionLabel<T>,
}

const OptionList = <T extends AnyObject,>({
  id,
  valueKey,
  options,
  onChange,
  getOptionLabel,
  getOptionKey,
  loading,
}: InputGroupRadioClassicProps<T>): JSX.Element => {
  const { t, } = useTranslation();

  if (loading) {
    return <LoadingBox />;
  };

  if (options.length < 1) {
    return (
      <Typography
        variant="body1"
        color="text.secondary"
      >
        {t('common:radioGroup.noOptions')}
      </Typography>
    );
  };
  
  return (
    <RadioGroup
      name={id}
      value={valueKey}
      onChange={onChange}
    >
      {options.map((o) => (
        <StyledFormControlLabel
          key={getOptionKey(o)}
          value={getOptionKey(o)}
          control={<Radio />}
          label={getOptionLabel(o)}
        />
      ))}        
    </RadioGroup>
  );
};

export default OptionList;
