import { useMemo, } from 'react';
import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import {  PointsTransactionFragment, PointsTransactionInput, PointsTransactionOperation, } from 'Apollo/graphql';
import { createOperationOption, PointsTransactionOperationOption, } from 'Utils/options/useOptionsOperation';

export type PointsTransactionFormValues = {
  operation: PointsTransactionOperationOption | null,
  amount: number,
};

export const useInitPointsTransactionValues = (pointsTransaction: PointsTransactionFragment): PointsTransactionFormValues => useMemo<PointsTransactionFormValues>(
  () => ({
    operation: createOperationOption(pointsTransaction.operation),
    amount: pointsTransaction.amount,
  }),
  [ pointsTransaction, ],
);

export const pointsTransactionFormValidationSchema = yup.object().shape({
  amount: yup.number()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .min(0, INPUT_FORM_ERRORS.INT_MIN)
    .nullable(),
  operation: yup.object()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .nullable(),
});

export const mapFormToVariables = (values: PointsTransactionFormValues): PointsTransactionInput => ({
  operation: values.operation?.id as PointsTransactionOperation,
  amount: values.amount,
});
