import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { TaskTypeEnum, useSellerTasksQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import LoadingBox from 'Components/LoadingBox';
import { sellerTaskFilter, } from './utils';
import CardItem from './taskCard';

type Props = {
  taskType: TaskTypeEnum,
};

const TaskList = ({
  taskType,
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  const { loading, data, error, } = useSellerTasksQuery({
    variables: sellerTaskFilter(taskType),
    fetchPolicy: 'cache-and-network',
  });

  if (loading && !data?.tasksList) return <LoadingBox mt={6} mb={4} />;
  if (error || !data?.tasksList) return <ErrorAlert mb={2} mt={4} forceError error={error} />;
  if (data.tasksList.data.length === 0) return (
    <Box pt={4} pb={4}>
      <Typography
        variant="body0"
        color="textSecondary"
      >
        {t('tasksSeller:taskList.generic.noTasks')}
      </Typography>
    </Box>
  );
  return (
    <Stack spacing={3} mt={3}>
      {data.tasksList.data.map((task) => (
        <CardItem
          key={task.id}
          data={task}
        />
      ))}
    </Stack>
  );
};

export default TaskList;
