/* eslint-disable no-underscore-dangle */
import { AuthUserFragment, useRegisterCustomerMutation, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import shallow from 'zustand/shallow';
import { mapRegisterVariables, } from '../PhaseCustomerRegistration/utils';
import { CreateReplacementStore, useCreateReplacementStore, } from '../createReplacementStore';
import EmailAndSmsVerification from './VerificationOptions/EmailAndSmsVerification';
import GrpcEmailVerification from './VerificationOptions/GrpcEmailVerification';
import SkipVerification from './VerificationOptions/SkipVerification';

const selectStoreData = (s: CreateReplacementStore) => ({
  customerVerificationResult: s.customerVerificationResult,
  goBackToPhase: s.goBackToPhase,
  completeCustomerVerificationPhase: s.completeCustomerVerificationPhase,
  storeVerification: s.customerVerification,
  customer: s.customer,
  replacementEmail: s.replacementEmail,
  registrationValues: s.customerRegistrationValues,
  setCustomerVerification: s.setCustomerVerification,
});
interface Props {
  onClose: () => void;
}

const PhaseCustomerVerification = ({ onClose, }: Props): JSX.Element => {
  const {
    registrationValues,
    replacementEmail,
    goBackToPhase,
    completeCustomerVerificationPhase,
    customerVerificationResult,
    customer,
    storeVerification,
    setCustomerVerification,
  } = useCreateReplacementStore(selectStoreData, shallow);
  const authUser = useAuthUser();
  const [registerCustomerMutation, registerCustomerResult,] = useRegisterCustomerMutation({
    variables: mapRegisterVariables(replacementEmail, registrationValues, authUser as AuthUserFragment),
    onCompleted: (data) => {
      if (data?.registerCustomer?.__typename === 'Customer') {
        completeCustomerVerificationPhase(storeVerification, data.registerCustomer);
      }
    },
  });

  const completeVerification = (verification: CreateReplacementStore['customerVerification']) => {
    setCustomerVerification(verification);
    registerCustomerMutation();
  };
  if (customerVerificationResult?.__typename === 'CustomerVerificationStartResultSkipVerification' && customerVerificationResult.skipVerification) {
    return <SkipVerification completeCustomerVerificationPhase={completeVerification} />;
  }

  if (customerVerificationResult?.__typename === 'CustomerVerificationStartResultEmailGrpc') {
    return (
      <GrpcEmailVerification
        completeCustomerVerificationPhase={completeVerification}
        goBackToPhase={goBackToPhase}
        verification={customerVerificationResult}
        onClose={onClose}
      />
    );
  }
  if (
    customerVerificationResult?.__typename === 'CustomerVerificationStartResultSms' ||
    customerVerificationResult?.__typename === 'CustomerVerificationStartResultEmail'
  ) {
    const verification: CreateReplacementStore['customerVerification'] = {
      ...storeVerification,
      method: customerVerificationResult.method,
      value: customerVerificationResult.verificatedValue,
    };
    return (
      <EmailAndSmsVerification
        completeCustomerVerificationPhase={completeVerification}
        goBackToPhase={goBackToPhase}
        verification={verification}
        customer={customer}
        method={customerVerificationResult.method}
        onClose={onClose}
        customerRegistrationValues={registrationValues}
      />
    );
  }
  return <SkipVerification completeCustomerVerificationPhase={completeVerification} />;
};

export default PhaseCustomerVerification;
