import Box from '@mui/material/Box';
import { NewsArticleSortableFields, OrderBy, useNewsArticlesListQuery, } from "Apollo/graphql";
import ErrorAlert from "Components/Errors/ErrorAlert";
import SkeletonItem from 'Modules/NewsArticlesSeller/components/SkeletonItem';
import CardItem from './CardItem';

const NewsArticlesList = (): JSX.Element => {
  const { loading, error, data, } = useNewsArticlesListQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      sort: {
        field:NewsArticleSortableFields.CreatedAt,
        order:OrderBy.Desc,
      },
    },
  });

  if (loading) return (
    <SkeletonItem />
  );

  if (error || !data?.newsArticles) return (
    <ErrorAlert
      forceError
      error={error}
    />
  );

  return (
    <Box flexDirection="column">
      {data.newsArticles.data.map(newsArticle => (
        <CardItem key={newsArticle.id} newsArticle={newsArticle} />
      ))}
    </Box>
  );
};

export default NewsArticlesList;
