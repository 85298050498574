import { Fragment, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useTheme, } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Claim, ReplacementDetailFragment, ReplacementStatus, ReplacementTableFragment, } from 'Apollo/graphql';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import LinkButton from 'Components/LinkButton';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import ProductCard from 'Components/ProductCard';
import { useAuthUser, } from 'Tools/auth';
import { ReplacementStatusOption, useOptionsReplacementStatus, } from 'Utils/options/useOptionsReplacementStatus';
import { FormInputAutocomplete, } from 'Components/Form';
import { useReplacementStatus, } from 'Tools/useReplacementStatus';
import { Palette, } from '@mui/material/styles/createPalette';
import { useWatch, } from 'react-hook-form';
import { ReplacementFormValues, } from 'Modules/ReplacementAdmin/utils/replacementForm';
import { formatDate, } from '../../utils/replacementForm';

interface CustomPalette extends Palette {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface Props {
  replacement: ReplacementDetailFragment;
  onOpenRemove: (replacement: ReplacementDetailFragment | ReplacementTableFragment) => void;
  onClose: () => void;
  isDirty: boolean;
}

const View = ({ replacement, onOpenRemove, onClose, isDirty, }: Props): JSX.Element => {
  const theme = useTheme();
  const { t, } = useTranslation('replacementAdmin');
  const replacementStatusOptions = useOptionsReplacementStatus();

  const formValues = useWatch() as unknown as ReplacementFormValues;

  const user = useAuthUser();

  const chip = useReplacementStatus(formValues.status?.id as ReplacementStatus);

  const products =
    replacement.status !== ReplacementStatus.Completed ? replacement.replacedAssortments : replacement.replacedAssortmentsWhenCompleted;

  const customer = replacement.customer !== null ? replacement.customer : replacement.replacementRegistration;

  const handleOpenRemove = () => onOpenRemove(replacement);

  const showSensitiveData = user?.claimsSet.has(Claim.ReadSensitiveData);

  const handleDone = isDirty ? undefined : () => onClose();
  const doneButtonType = isDirty ? 'submit' : 'button';

  return (
    <ModalDrawerBody>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('replacement.labelReplacementId')}>{replacement.id}</NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('replacement.labelUsername')}>{replacement.author.username}</NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('replacement.labelCreatedAt')}>
            {t('common:date.fullDate', { date: formatDate(replacement.createdAt), })}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <FormInputAutocomplete<ReplacementStatusOption>
            name="status"
            getOptionLabel={replacementStatusOptions.getOptionLabel}
            isOptionEqualToValue={replacementStatusOptions.isOptionEqualToValue}
            options={replacementStatusOptions.options}
            label={t('replacement.labelStatus')}
            disableClearable
            overwriteStyles={{
              '& .MuiInputBase-root': {
                background: (theme.palette as CustomPalette)[chip.color || 'default']?.main,
                border: 0,
                color: theme.palette.common.white,
                width: theme.spacing(24),
                height: theme.spacing(4),
              },
              '& svg': {
                color: theme.palette.common.white,
                height: theme.spacing(1.25),
                width: theme.spacing(1.25),
              },
              '& .MuiInputBase-root .MuiInputBase-input': {
                padding: `0px ${theme.spacing(1)} 0px ${theme.spacing(1)}`,
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic label={t('replacement.labelCompletor')}>{replacement.completor?.username || '-'}</NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic label={t('replacement.labelPosId')}>{replacement.posId || '-'}</NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic label={t('replacement.labelCompletedAt')}>
            {t('common:date.fullDate', { date: formatDate(replacement.completedAt), }) || '-'}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        {showSensitiveData && (
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('replacement.labelCustomer')}>
                  <div>{`${customer?.firstName || ''} ${customer?.lastName || ''}`}</div>
                  <div>{customer?.email || ''}</div>
                  <div>{`${customer?.phonePrefix || ''} ${customer?.phoneNumber || ''}`}</div>
            </NotEditableTextClassic>
          </Grid>
        )}

        <Grid item xs={12}>
          <NotEditableTextClassic label={t('replacement.labelCategory')}>{replacement.category.name || '-'}</NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic label={t('replacement.labelProducts')}>
            {products.map((product) => (
              <Fragment key={product.assortmentId}>
                {product.count > 0 && (
                  <ProductCard title={product.assortment.name} imageSrc={product.assortment.image?.publicUrl} count={product.count} />
                )}
              </Fragment>
            ))}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic label={t('replacement.labelReason')}>{replacement.reason || '-'}</NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('createReplacementModal.phaseCreateTicket.labelNote')}>{replacement.note || '-'}</NotEditableTextClassic>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Grid container spacing={3}>
          {user?.claimsSet.has(Claim.DeleteReplacement) && (
            <Grid item xs={12}>
              <LinkButton type="button" variant="body1" onClick={handleOpenRemove} color="secondary" disabled={false}>
                {t('replacement.btnRemove')}
              </LinkButton>
            </Grid>
          )}
          <Grid item>
            <Button onClick={handleDone} type={doneButtonType} size="large" variant="outlined" color="inherit">
              {t('common:actions.done')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalDrawerBody>
  );
};

export default View;
