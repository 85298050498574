import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSnackbar, } from 'notistack';
import { AssortmentCategoriesDocument, AssortmentFragment, useDeleteAssortmentMutation, } from 'Apollo/graphql';
import { updateAssortmentRemove, } from 'Modules/AssortmentAdmin/utils/assortmentCache';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import View from './View';

interface Props {
  assortment?: AssortmentFragment | null,
  onClose: () => void,
};

const Logic = ({
  assortment,
  onClose,
}: Props): JSX.Element => {
  const preservedAssortment = usePreserveValue(assortment);
  const { t, } = useTranslation('assortmentAdmin');
  const { enqueueSnackbar, } = useSnackbar();

  const [ removeMutation, removeMutationResult, ] = useDeleteAssortmentMutation({
    refetchQueries: [{
      query: AssortmentCategoriesDocument,
    },],
    onCompleted: () => {
      enqueueSnackbar({
        title: t('removeAssortmentModal.notifRemovedTitle') as string,
        message: t('removeAssortmentModal.notifRemovedMessage', { productId: preservedAssortment.id, }),
        variant: 'common',
      });
      onClose();
    },
    update: updateAssortmentRemove,
  });

  const handleSubmit = useCallback(
    () => {
      removeMutation({
        variables: { id: preservedAssortment.id, },
      });
    },
    [ assortment, removeMutation, ],
  );

  return (
    <View
      assortment={preservedAssortment}
      removeMutationResult={removeMutationResult}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default Logic;
