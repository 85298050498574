import NewsArticlesTable from './NewsArticlesTable';
import ModalRemoveNewsArticle from '../../modals/ModalRemoveNewsArticle';
import ModalCreateNewsArticle from '../../modals/ModalCreateNewsArticle';
import ModalEditNewsArticle from '../../modals/ModalEditNewsArticle';
import ModalDetailNewsArticle from '../../modals/ModalDetailNewsArticle';

const NewsArticlesAdminPage = () => {
  return (
    <>
      <NewsArticlesTable />
      
      <ModalCreateNewsArticle />
      <ModalRemoveNewsArticle />
      <ModalEditNewsArticle />
      <ModalDetailNewsArticle />
    </>
  );
};

export default NewsArticlesAdminPage;
