import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useTranslation, } from 'react-i18next';

import { useMemo, } from 'react';
import { totalNumberOfSentGiftCodesQuery, } from './queries';

const TotalNumberOfSentGiftCodes = ({
  showAddButton,

  filtration,
  chartFilter,
  showSlug = true,
  timeDimensions,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');

  const query = useMemo(
    () =>
      totalNumberOfSentGiftCodesQuery({
        timeDimensions,
        filters: chartFilter,
      }),
    [chartFilter, timeDimensions,],
  );

  return (
    <Base
      headerText={t('dashboards.giftCodes.totalNumberOfSentGiftCodes')}
      slug={showSlug ? 'total-number-of-sent-gift-codes' : undefined}
      showAddButton={showAddButton}
      filtration={filtration}
      dashboardLayoutItemName={DashboardLayoutItemName.GiftCodesTotalNumberOfSendGiftCodesByChain}
      chainsVariables={{ usesImportedCodes: true, }}
    >
      <ChartRenderer
        query={query}
        pivotConfig={{
          x: ['imported_pl_gift_code.sent_at',],
          y: ['measures',],
          fillMissingDates: true,
          joinDateRange: false,
        }}
        type={ChartType.bar}
      />
    </Base>
  );
};

export default TotalNumberOfSentGiftCodes;
