import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { PointOfSellStore, usePointOfSellStore, } from 'Modules/PointOfSell/store/PointOfSellStore';
import FetchData from './FetchData';

const selectModalData = (s: PointOfSellStore) => s.modalExportPointOfSell;
const selectClose = (s: PointOfSellStore) => s.closeModalExportPointOfSell;

const ModalExportPointOfSells = (): JSX.Element => {
  const { t, } = useTranslation('pos');
  const { isOpen, } = usePointOfSellStore(selectModalData);
  const onClose = usePointOfSellStore(selectClose);

  return (
    <ModalDrawer onClose={onClose} open={isOpen}>
      <ModalDrawerTitle onClose={onClose}>{t(`common:defaultExport.title`, {name: t('pointsOfSell.title'),})}</ModalDrawerTitle>
      <FetchData onClose={onClose} />
    </ModalDrawer>
  );
};

export default ModalExportPointOfSells;
