import Grid from '@mui/material/Grid';
import { AssortmentCategoryOptionFragment, AssortmentOptionFragment, useAssortmentCategoryOptionsReplacementsQuery, useAssortmentOptionsListQuery, } from 'Apollo/graphql';
import { FormInputAutocomplete, FormInputProductCountMultiple, FormInputText, } from 'Components/Form';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { areOptionsEqualById, getOptionFieldId, getOptionFieldName, renderOptionById, renderOptionByIdName, } from 'Utils/helpers';
import { ReplacementReasonOption, useOptionsReplacementReason, } from 'Utils/options/useOptionsReplacementReason';
import { useEffect, } from 'react';
import { useFormContext, useWatch, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { CreateReplacementStore, ProductOption, } from '../createReplacementStore';

interface Props {
  customer: CreateReplacementStore['customer'],
}

const CreateTicketForm = ({
  customer,
}: Props): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  const { control, setValue, } = useFormContext();

  const category = useWatch({ name: 'category', control, }) as AssortmentCategoryOptionFragment | null;
  const reason = useWatch({ name: 'reason', control, }) as ReplacementReasonOption | null;
  const productList = useWatch({ name: 'products', control, }) as ProductOption[];
  useEffect(
    () => {
      setValue('products', []);
      setValue('reason',null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ category, ],
  );
  useEffect(
    () => {
      setValue('reasonText', '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ reason?.id, ],
  );

  const categoriesOptionsResult = useAssortmentCategoryOptionsReplacementsQuery({ fetchPolicy: 'cache-and-network', });
  const replacementReasonResult = useOptionsReplacementReason(category?.name);
  const productsOptionsResult = useAssortmentOptionsListQuery({
    skip: !category,
    variables: category ? {
      filter: {
        categoryId: { equals: category.id, },
      },
    } : undefined,
  });

  const productOptionMax = productList.length > 0 ? ()=>0 : undefined;
  return (
    <Grid container spacing={2} mb={3}>
      <Grid item xs={12}>
        {!!customer && (
          <NotEditableTextClassic label={t('createReplacementModal.phaseCreateTicket.labelCustomer')}>
            <div>{customer?.email}</div>
            <div>{`${customer?.firstName}${customer?.lastName}`}</div>
            <div>{`${customer?.phonePrefix}${customer?.phoneNumber}`}</div>
          </NotEditableTextClassic>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormInputAutocomplete
          label={t('createReplacementModal.phaseCreateTicket.labelCategory')}
          name="category"
          getOptionLabel={getOptionFieldName}
          isOptionEqualToValue={areOptionsEqualById}
          renderOption={renderOptionByIdName}
          loading={categoriesOptionsResult.loading}
          options={categoriesOptionsResult.data?.assortmentCategoriesReplacement || []}
          placeholder={t('common:select.placeholder')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputProductCountMultiple<AssortmentOptionFragment>
          label={t('createReplacementModal.phaseCreateTicket.labelProducts')}
          required
          name="products"
          options={productsOptionsResult.data?.assortmentsList.data || []}
          getOptionKey={getOptionFieldId}
          getOptionLabel={getOptionFieldName}
          loading={productsOptionsResult.loading}
          getOptionMax={category?.requiresDeviceCodes ? productOptionMax : undefined}
          noOptionsText={t('createReplacementModal.phaseCreateTicket.productsNoCategorySelected')}
        />
      </Grid>
      {category?.requiresDeviceCodes && <Grid item xs={12}>
        <FormInputText name="batchCode" label={t('createReplacementModal.phaseCreateTicket.batchCode')} />
      </Grid>}
      <Grid item xs={12}>
        <FormInputAutocomplete<ReplacementReasonOption>
          label={t('createReplacementModal.phaseCreateTicket.labelReason')}
          name="reason"
          getOptionLabel={replacementReasonResult.getOptionLabel}
          isOptionEqualToValue={replacementReasonResult.isOptionEqualToValue}
          renderOption={renderOptionById(replacementReasonResult.getOptionLabel)}
          options={replacementReasonResult.options}
          placeholder={t('common:select.placeholder')}
          groupBy={replacementReasonResult.groupBy}
          required
        />
      </Grid>
      {reason?.requireTextInput && (
        <Grid item xs={12}>
          <FormInputText label={t('createReplacementModal.phaseCreateTicket.labelReasonText')} name="reasonText" required />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormInputText label={t('createReplacementModal.phaseCreateTicket.labelNote')} name="note" multiline minRows={5} />
      </Grid>
    </Grid>
  );
};

export default CreateTicketForm;
