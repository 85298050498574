import { styled, } from '@mui/material/styles';
import { Link, } from 'react-router-dom';
import LogoIcon from 'Utils/svg/LogoIcon';

const StyledContainer = styled('div')(({ theme, }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  scale: 'inherit',
  '& .MuiSvgIcon-root': {
    marginLeft: 20,
    height: 60,
  },

  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

interface Props {
  to: string,
}

const SidebarLogo = ({
  to,
}: Props): JSX.Element => (
  <StyledContainer>
    <Link
      to={to}
    >
      <LogoIcon />
    </Link>
  </StyledContainer>
);

export default SidebarLogo;
