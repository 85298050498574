import {
  NotificationType,
  NotificationsForCurrentUserDocument,
  NotificationsForCurrentUserQuery,
  useMarkAsVisitedMutation,
  useMarkPopUpAsSeenMutation,
  useNotificationsForCurrentUserQuery,
} from 'Apollo/graphql';
import { useApolloClient, } from '@apollo/client';
import { useCallback, useMemo, } from 'react';
import * as changeCase from 'change-case';
import { CamelCase, } from 'type-fest';
import { useAuthUser, } from './auth';

export const useNotifications = () => {
  const authUser = useAuthUser();
  const client = useApolloClient();

  const { data, } = useNotificationsForCurrentUserQuery({
    skip: !authUser,
  });

  const [markAsVisitedMutation,] = useMarkAsVisitedMutation({
    refetchQueries: [NotificationsForCurrentUserDocument,],
  });
  const [markPopUpAsSeenMutation,] = useMarkPopUpAsSeenMutation();

  const { notificationsForCurrentUser, unreadNotificationsByCategory, } = useMemo(() => {
    return {
      notificationsForCurrentUser: data?.notificationsForCurrentUser ?? [],
      unreadNotificationsByCategory: data?.unreadNotificationsByCategory ?? {
        assortment: 0,
        newsArticle: 0,
        promotion: 0,
        task: 0,
        voucher: 0,
      },
    };
  }, [data?.notificationsForCurrentUser, data?.unreadNotificationsByCategory,]);

  const removeFromCache = useCallback(
    (idToRemove: string, categoryPascalCase: NotificationType) => {
      const category = changeCase.camelCase(categoryPascalCase) as CamelCase<NotificationType>;

      const optimisticNotificationsForCurrentUser = notificationsForCurrentUser.filter(({ id, }) => id !== idToRemove);
      const newCategoryValue = unreadNotificationsByCategory[category];

      const optimisticUnreadNotificationsByCategory = {
        ...unreadNotificationsByCategory,
        [category]: newCategoryValue,
      };

      client.cache.writeQuery<NotificationsForCurrentUserQuery>({
        query: NotificationsForCurrentUserDocument,
        data: {
          notificationsForCurrentUser: optimisticNotificationsForCurrentUser,
          unreadNotificationsByCategory: optimisticUnreadNotificationsByCategory,
        },
      });
    },
    [client.cache, notificationsForCurrentUser, unreadNotificationsByCategory,],
  );

  const dismissNotification = useCallback(
    (dismissedId: string, type: NotificationType | undefined | null) => {
      if (type) {
        removeFromCache(dismissedId, type);
        markPopUpAsSeenMutation({
          variables: { markPopUpAsSeenInput: { notificationId: dismissedId, }, },
        });
      }
    },
    [markPopUpAsSeenMutation, removeFromCache,],
  );

  const visitDetail = useCallback(
    (visitedId: string, type: NotificationType | undefined | null) => {
      if (type) {
        removeFromCache(visitedId, type);
        markAsVisitedMutation({
          variables: {
            markAsVisitedInput: { notificationId: visitedId, },
          },
        });
      }
    },
    [markAsVisitedMutation, removeFromCache,],
  );

  return {
    data,
    notifications: notificationsForCurrentUser,
    unreadNotificationsByCategory,
    dismissNotification,
    visitDetail,
  };
};

export default useNotifications;