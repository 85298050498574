import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const EmotionNotificationIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon viewBox="0 0 88 74" fill="none" {...props}>
    <path
      d="M31.3683 52.9744C31.3458 52.0595 31.5067 51.1494 31.8413 50.2976C32.1759 49.4459 32.6775 48.6696 33.3166 48.0147C33.9557 47.3597 34.7195 46.8392 35.5628 46.4838C36.4061 46.1284 37.312 45.9453 38.2271 45.9453C39.1422 45.9453 40.0481 46.1284 40.8914 46.4838C41.7348 46.8392 42.4985 47.3597 43.1376 48.0147C43.7767 48.6696 44.2783 49.4459 44.6129 50.2976C44.9475 51.1494 45.1084 52.0595 45.0859 52.9744C45.042 54.7643 44.3001 56.4662 43.0186 57.7167C41.7371 58.9671 40.0176 59.6671 38.2271 59.6671C36.4366 59.6671 34.717 58.9671 33.4355 57.7167C32.1541 56.4662 31.4122 54.7643 31.3683 52.9744ZM35.4914 52.9331C35.4912 53.6574 35.7746 54.3529 36.2809 54.8708C36.7873 55.3887 37.4762 55.6877 38.2003 55.7039C38.9233 55.6897 39.6135 55.3995 40.1294 54.8927C40.6454 54.386 40.9479 53.7011 40.9752 52.9785C40.9688 52.2542 40.68 51.5611 40.1702 51.0466C39.6604 50.5322 38.9698 50.2371 38.2456 50.2243C37.8865 50.2204 37.5302 50.2875 37.197 50.4217C36.8639 50.5559 36.5604 50.7545 36.3042 51.0062C36.0479 51.2578 35.8439 51.5576 35.7037 51.8882C35.5635 52.2189 35.49 52.574 35.4873 52.9331H35.4914Z"
      fill="#D47E5F"
    />
    <path
      d="M27.2428 35.1536C27.2428 36.5087 26.8413 37.8334 26.0889 38.9605C25.3366 40.0875 24.2672 40.9664 23.0157 41.4861C21.7642 42.0058 20.3868 42.143 19.0574 41.8804C17.728 41.6178 16.5062 40.9672 15.5463 40.0108C14.5864 39.0543 13.9314 37.8349 13.6641 36.5064C13.3967 35.178 13.529 33.8001 14.0442 32.5468C14.5594 31.2934 15.4343 30.2209 16.5587 29.4645C17.683 28.7081 19.0062 28.3018 20.3613 28.2969C22.1828 28.2947 23.9307 29.0156 25.221 30.3013C26.5113 31.5869 27.2384 33.3322 27.2428 35.1536ZM20.4232 37.8914C21.1471 37.8827 21.8381 37.5876 22.345 37.0706C22.8519 36.5537 23.1334 35.857 23.1279 35.133C23.1129 34.4085 22.8159 33.7184 22.3 33.2095C21.7842 32.7005 21.0901 32.4128 20.3654 32.4076C19.647 32.4326 18.9651 32.7305 18.4587 33.2407C17.9524 33.7509 17.6596 34.435 17.6401 35.1536C17.6541 35.8813 17.9539 36.5742 18.4747 37.0826C18.9955 37.5911 19.6954 37.8742 20.4232 37.8708V37.8914Z"
      fill="#D47E5F"
    />
    <path
      d="M17.4824 58.1396C17.0598 58.1489 16.6436 58.0343 16.2852 57.81C15.9267 57.5857 15.6415 57.2614 15.4647 56.8769C15.2482 56.4803 15.1682 56.0233 15.2368 55.5766C15.3054 55.1298 15.519 54.7181 15.8445 54.405C17.15 53.0591 18.4853 51.731 19.8116 50.4118C26.2287 43.9824 32.6467 37.5529 39.0657 31.1235C39.6592 30.5293 40.2526 29.9351 41.1962 29.9618C41.6067 29.9661 42.0077 30.0867 42.3527 30.3097C42.6976 30.5326 42.9726 30.8487 43.1456 31.2215C43.3252 31.5494 43.4095 31.921 43.3891 32.2943C43.3686 32.6677 43.2442 33.0278 43.0299 33.334C42.8195 33.6378 42.58 33.9204 42.3148 34.1778C34.6931 41.8135 27.0644 49.4462 19.4289 57.076C18.8948 57.6226 18.3399 58.1545 17.4824 58.1396Z"
      fill="#D47E5F"
    />
    <line x1="71.206" y1="16.3272" x2="42.9119" y2="16.3272" stroke="#D47E5F" strokeWidth="6.52941" strokeLinecap="round" />
    <line x1="71.206" y1="29.3819" x2="55.9707" y2="29.3819" stroke="#D47E5F" strokeWidth="6.52941" strokeLinecap="round" />
    <rect x="4.38835" y="3.91765" width="79.2235" height="66.1647" rx="4.78824" stroke="#D47E5F" strokeWidth="7.83529" fill='none'/>
  </SvgIcon>
);

export default EmotionNotificationIcon;
