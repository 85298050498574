import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { REGEX_PHONE_NUMBER, } from 'Utils/constants';
import * as yup from 'yup';

export const formValidationSchema = () => yup.object().shape({
  email: yup.string().email(INPUT_FORM_ERRORS.EMAIL).required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
  firstName: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  lastName: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
  phoneNumber: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(25, INPUT_FORM_ERRORS.STRING_MAX)
    .matches(REGEX_PHONE_NUMBER, INPUT_FORM_ERRORS.PHONE),
  phonePrefix: yup.object()
    .nullable()
    .required(INPUT_FORM_ERRORS.REQUIRED),
  address: yup.string()
    .nullable()
    .max(125, INPUT_FORM_ERRORS.STRING_MAX),
});
