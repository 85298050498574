import { styled, } from '@mui/material/styles';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { format, } from 'date-fns';
import { useTranslation, } from 'react-i18next';
import { localeObject, LocalObject, } from 'Utils/locale';

type Props = {
  dateString: string;
};

const MonthBarBox = styled(ListSubheader)(() => ({
  backgroundColor: '#E6E6E6',
  paddingTop: 10,
  paddingBottom: 10,
}));

const MonthBox = ({ dateString, }: Props): JSX.Element => {
  const {
    i18n: { language, },
  } = useTranslation('common');
  const date = new Date(dateString);
  const monthName = format(date, 'LLLL', { locale: localeObject[language as keyof LocalObject], });
  return (
    <MonthBarBox>
      <Typography color="black">{`${monthName} ${date.getFullYear()}`}</Typography>
    </MonthBarBox>
  );
};

export default MonthBox;
