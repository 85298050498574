import { ReactNode, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';

import { INPUT_FORM_ERROR_KEYS, } from '../constants';
import { InputFormErrorMessage, } from '../types';

type InputError = {
  isError: boolean,
  text?: ReactNode,
};

export const useFormInputStatus = (
  errorMessage?: InputFormErrorMessage | null,
  helperText?: ReactNode,
): InputError => {
  const { t, } = useTranslation();
  return useMemo(
    () => {
      try {
        if (errorMessage) {
          return {
            isError: true,
            text: t([ errorMessage?.key, INPUT_FORM_ERROR_KEYS.unknown, ], errorMessage?.params),
          };
        }
        return {
          isError: false,
          text: helperText,
        };
      } catch {
        return {
          isError: true,
          text: t(INPUT_FORM_ERROR_KEYS.unknown),
        };
      }
    },
    [ errorMessage, helperText, t, ],
  );
};
