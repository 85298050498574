import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AssortmentCategoryWithAssortmentOptionFragment, AssortmentOptionFragment, PromotionWithLimitOptionFragment, } from 'Apollo/graphql';
import { FormInputProductCountMultiple, } from 'Components/Form';
import { useWatch, } from 'react-hook-form';
import { getOptionFieldId, getOptionFieldName, } from 'Utils/helpers';

interface Props {
  structure: {
    assortmentCategories: AssortmentCategoryWithAssortmentOptionFragment[];
  };
  promotion: PromotionWithLimitOptionFragment;
}
type AssortmentFormValue = {
  option: AssortmentOptionFragment;
  count: number;
};
type FormValues = {
  values: { assortmentCategories: { [index: string]: AssortmentFormValue[] } };
};
const PromotionPurchaseForm = ({ structure: { assortmentCategories, }, promotion, }: Props): JSX.Element => {
  const formValues = useWatch() as unknown as FormValues;
  const getOptionMax = (ac: AssortmentCategoryWithAssortmentOptionFragment, index: number) => {
    const totalInCategory = formValues.values?.assortmentCategories[ac.id]
      ? formValues.values.assortmentCategories[ac.id].reduce((acc: number, cur: AssortmentFormValue) => acc + cur.count, 0)
      : 0;
    const categoryMax = promotion.promotionAssortmentCategoriesForPurchases[index].count;
    return totalInCategory >= categoryMax;
  };
  return (
    <>
      {assortmentCategories.map((ac, index) =>
        ac.items.length > 0 ? (
          <Box key={ac.id} mb={4}>
            <>
              <Typography variant="h3" color="primary" sx={{ mb: 2.5, }}>
                {`${ac.name} ${
                  formValues.values?.assortmentCategories[ac.id]
                    ? formValues.values.assortmentCategories[ac.id].reduce((acc: number, cur: AssortmentFormValue) => acc + cur.count, 0)
                    : 0
                }/${promotion.promotionAssortmentCategoriesForPurchases[index].count}`}
              </Typography>
              <FormInputProductCountMultiple<AssortmentOptionFragment>
                name={`values.assortmentCategories.${ac.id}`}
                getOptionKey={getOptionFieldId}
                getOptionLabel={getOptionFieldName}
                options={ac.items || []}
                totalDisabledPlus={getOptionMax(ac, index)}
              />
            </>
          </Box>
        ) : null,
      )}
    </>
  );
};

export default PromotionPurchaseForm;
