import { AssortmentFragment, useAssortmentQuery, } from 'Apollo/graphql';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import Logic from './Logic';

interface Props {
  assortment?: AssortmentFragment | null,
  onClose: () => void,
};

const FetchData = ({
  assortment,
  onClose,
}: Props): JSX.Element => {
  const preservedAssortments = usePreserveValue(assortment);

  const { loading, error, data, } = useAssortmentQuery({
    variables: { id: preservedAssortments.id, },
    fetchPolicy: 'network-only',
  });

  if (loading) return (
    <ModalDrawerBodyLoading
      onClose={onClose}
    />
  );
  if (error || !data?.assortment) return (
    <ModalDrawerBodyError
      onClose={onClose}
      error={error}
    />
  );
  return (
    <Logic
      assortment={data.assortment}
      onClose={onClose}
    />
  );
};

export default FetchData;
