import { useCurrentUserQuery, AuthUserFragment, Claim, } from 'Apollo/graphql';
import { AuthStore, useAuthStore, } from '../store/authStore';

const selectAccessToken = (s: AuthStore) => s.accessToken;

export const useAuthUser = (): AuthUserFragment & { claimsSet: Set<Claim>, roleInfo: { canCreateUserRoles: string[] } } | null => {
  const accessToken = useAuthStore(selectAccessToken);
  const { data, } = useCurrentUserQuery({
    fetchPolicy: 'cache-only',
  });
  const claims = new Set(data?.currentUser.claims ?? []);
  const roleInfo = {
    canCreateUserRoles: [...claims]
      .filter(claim => claim.startsWith('CREATE_USER_'))
      .map(claim => claim.replace('CREATE_USER_', ''))
  };

  const user = data ? {
    ...data.currentUser,
    claimsSet: claims,
    roleInfo,
  } : null;

  return accessToken
    ? user
    : null;
};
