import { useTranslation, } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AuthUserFragment, ChainFragment, ClusterFragment, PointOfSellOptionFragment, PointOfSellSortableFields, useChainsQuery, useClustersQuery, } from 'Apollo/graphql';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import TableCellSortable from 'Components/Table/TableCellSortable';
import { useSearchOptionsPos, } from 'Utils/options/useSearchOptionsPos';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import { areOptionsEqualById, getOptionFieldId, getOptionFieldName, renderOptionById, } from 'Utils/helpers';

const COL_WIDTHS = [
  { width: '220px', },
  { width: '180px', },
  { width: '220px', },
  { width: '220px', },
  { width: '220px', },
  { width: '220px', },
  { width: '100px', },
  { width: '190px', },
  { width: '150px', },
  { width: '45px', },
];

interface Props {
  authUser: AuthUserFragment | null,
}

const PointOfSellTableHeader = ({
  authUser,
}: Props): JSX.Element => {
  const { t, } = useTranslation('pos');
  const chainOptions = useChainsQuery();
  const clusterOptions = useClustersQuery();
  const posOptions = useSearchOptionsPos();

  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;
  const showClusterIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS;

  return (
    <TableHead>
      <TableRow>
        <TableCellSortable
          style={COL_WIDTHS[0]}
          name={PointOfSellSortableFields.Id}
        >
          {t('pointsOfSell.table.labelPosId')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[1]}
          name={PointOfSellSortableFields.Name}
        >
          {t('pointsOfSell.table.labelPosName')}
        </TableCellSortable>
        {showChainIDs && (
          <TableCellSortable
            style={COL_WIDTHS[2]}
            name={PointOfSellSortableFields.ChainId}
          >
            {t('pointsOfSell.table.labelChainId')}
          </TableCellSortable>
        )}
        <TableCell
          style={COL_WIDTHS[3]}
        >
          {t('pointsOfSell.table.labelChainName')}
        </TableCell>
        {showClusterIDs && (
          <TableCellSortable
            style={COL_WIDTHS[4]}
            name={PointOfSellSortableFields.ClusterId}
          >
            {t('pointsOfSell.table.labelClusterId')}
          </TableCellSortable>
        )}
        <TableCellSortable
          style={COL_WIDTHS[5]}
          name={PointOfSellSortableFields.Street}
        >
          {t('pointsOfSell.table.labelStreet')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[6]}
          name={PointOfSellSortableFields.HouseNumber}
        >
          {t('pointsOfSell.table.labelNumber')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[7]}
          name={PointOfSellSortableFields.City}
        >
          {t('pointsOfSell.table.labelCity')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[8]}
          name={PointOfSellSortableFields.PostalCode}
        >
          {t('pointsOfSell.table.labelPostalCode')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[9]} />
      </TableRow>
      <TableRow>
        <TableCell>
          <TableFilterAutocomplete<PointOfSellOptionFragment>
            name="pos"
            options={posOptions.options}
            getOptionLabel={posOptions.getOptionLabel}
            isOptionEqualToValue={posOptions.isOptionEqualToValue}
            renderOption={renderOptionById(posOptions.getOptionLabel)}
            filterOptions={posOptions.filterOptions}
            loading={posOptions.loading}
            onInputChange={posOptions.onInputChange}
            noOptionsText={posOptions.noOptionsText}
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="posName"
          />
        </TableCell>
        {showChainIDs &&
          <TableCell>
            <TableFilterAutocomplete<ChainFragment>
              name="chain"
              options={chainOptions.data?.chains || []}
              getOptionLabel={getOptionFieldId}
              isOptionEqualToValue={areOptionsEqualById}
              renderOption={renderOptionById(getOptionFieldId)}
            />
          </TableCell>
        }
        <TableCell>
          <TableFilterAutocomplete<ChainFragment>
            name="chain"
            options={chainOptions.data?.chains || []}
            getOptionLabel={getOptionFieldName}
            isOptionEqualToValue={areOptionsEqualById}
            renderOption={renderOptionById(getOptionFieldName)}
          />
        </TableCell>
        {showClusterIDs && (
          <TableCell>
            <TableFilterAutocomplete<ClusterFragment>
              name="cluster"
              options={clusterOptions.data?.clusters || []}
              getOptionLabel={getOptionFieldId}
              isOptionEqualToValue={areOptionsEqualById}
              renderOption={renderOptionById(getOptionFieldId)}
            />
          </TableCell>
        )}
        <TableCell>
          <TableFilterText
            name="street"
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="number"
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="city"
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="postalCode"
          />
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default PointOfSellTableHeader;
