import { useCurrentUserQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import LoadingBox from 'Components/LoadingBox';
import Logic from './Logic';

const FetchData = (): JSX.Element => {
  const { data, loading, error, } = useCurrentUserQuery();
  if (loading) {
    return (
        <LoadingBox />
    );
  }

  if (error || !data?.currentUser)
    return (
        <ErrorAlert forceError error={error} />
    );

  return (
      <Logic data={data}/>
  );
};

export default FetchData;
