import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementAdmin/store/ReplacementStore';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import Layout from './Layout';

const selectModalData = (s: ReplacementStore) => s.modalExportReplacement;
const selectClose = (s: ReplacementStore) => s.closeModalExportReplacement;

const ModalExportReplacements = (): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  const { isOpen, } = useReplacementStore(selectModalData);
  const onClose = useReplacementStore(selectClose);

  return (
    <ModalDrawer onClose={onClose} open={isOpen}>
      <ModalDrawerTitle onClose={onClose}>{t(`common:defaultExport.title`, { name: t('replacements.title'), })}</ModalDrawerTitle>
      <ModalDrawerBody padding-bottom={0}>
        <Layout />
      </ModalDrawerBody>
    </ModalDrawer>
  );
};

export default ModalExportReplacements;
