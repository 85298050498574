import { format, } from 'date-fns';
import i18n from 'i18next';
import { initReactI18next, } from 'react-i18next';
import { getResources, } from './utils';

const {
  resources,
  lang,
} = getResources();

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: lang,
    fallbackLng: lang,
    interpolation: {
      escapeValue: false,
      format: (value, pattern) => {
        if (pattern && value instanceof Date) {
          try {
            return format(value, pattern);
          } catch {
            return value;
          }
        };
        return value;
      },
    },
  });
