import { MouseEventHandler, ReactNode, } from 'react';
import { useSlate, } from 'slate-react';
import { CustomText, } from '../types';
import { isMarkActive, toggleMark, } from '../utils';
import ToggleButton from './ToggleButton';

interface Props {
  format: keyof Omit<CustomText, 'text'>,
  icon: ReactNode,
};

const preventDefault = (e: Pick<MouseEvent, 'preventDefault'> | Pick<TouchEvent, 'preventDefault'>): void => e.preventDefault();

const ToggleLeaf = ({
  format,
  icon,
}: Props): JSX.Element => {
  const editor = useSlate();
  const isSelected = isMarkActive(editor, format);
  const handleChange: MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    toggleMark(editor, format);
  };

  return (
    <ToggleButton
      value="check"
      selected={isSelected}
      onChange={handleChange}
      onMouseDown={preventDefault}
      onTouchStart={preventDefault}
    >
      {icon}
    </ToggleButton>
  );
};

export default ToggleLeaf;
