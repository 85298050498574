import { useFormContext, useController, } from 'react-hook-form';
import { AnyObject, } from 'Utils/types';
import InputGroupCheckboxClassic, { InputGroupCheckboxClassicProps, } from 'Components/Inputs/InputGroupCheckboxClassic';
import { useFormInputStatus, } from '../utils/useFormInputStatus';
import { InputFormErrorMessage, } from '../types';

interface Props<T extends AnyObject,> extends Omit<InputGroupCheckboxClassicProps<T>, 'value' | 'onChange' | 'id' | 'error' > {
  name: string,
}

const FormInputGroupCheckbox = <T extends AnyObject,>({
  name,
  helperText,
  ...rest
}: Props<T>): JSX.Element => {
  const { control, } = useFormContext();
  const {
    field: { value, onBlur: onBlurField, onChange: onChangeField, },
    fieldState: { error, },
  } = useController({
    name,
    control,
  });

  const handleChange: InputGroupCheckboxClassicProps<T>['onChange'] = (newValue) => {
    onChangeField(newValue);
    onBlurField();
  };

  const inputStatus = useFormInputStatus(
    error?.message as InputFormErrorMessage | undefined,
    helperText,
  );

  return (
    <InputGroupCheckboxClassic<T>
      {...rest}
      id={name}
      value={value}
      onChange={handleChange}
      error={inputStatus.isError}
      helperText={inputStatus.text}
    />
  );
};

export default FormInputGroupCheckbox;
