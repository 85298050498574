import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  ExportTypesEnum,
  useExportBrandAmbassadorsMutation,
  useExportUsersQrCodesMutation,
} from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import ExportTypeForm from 'Components/Export/ExportTypeForm';
import { exportTypeSchema } from 'Components/Export/helpers';
import LoadingBox from 'Components/LoadingBox';
import { EXPORT_TYPE_OPTIONS } from 'Utils/options/useOptionsExportTypes';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Type = 'brandAmbassador' | 'user';
type Props = {
  type: Type;
};

const useExportQrCodesMutation = (type: Type) => {
  const [getBA, dataBA] = useExportBrandAmbassadorsMutation({
    fetchPolicy: 'no-cache',
  });

  const [getUsers, dataUsers] = useExportUsersQrCodesMutation({
    fetchPolicy: 'no-cache',
  });

  if (type === 'brandAmbassador') {
    return [
      getBA,
      dataBA,
      dataBA.data?.exportBaQrCodes.file.publicUrl,
    ] as const;
  }

  if (type === 'user') {
    return [
      getUsers,
      dataUsers,
      dataUsers.data?.exportUsersQrCodes.file.publicUrl,
    ] as const;
  }

  throw new Error('Invalid type');
};

const ExportQrCodes = ({ type }: Props): JSX.Element => {
  const { t } = useTranslation('registrationsAdmin');

  const [getFile, { error, loading }, fileUrl] = useExportQrCodesMutation(type);

  const [chosenType, setChosenType] = useState<ExportTypesEnum | null>(null);

  const methods = useForm({
    defaultValues: { type: EXPORT_TYPE_OPTIONS[0] },
    resolver: yupResolver(exportTypeSchema),
    mode: 'onTouched',
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    setChosenType(values.type.id);
  });

  useEffect(() => {
    if (chosenType) {
      getFile({ variables: { type: chosenType } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenType]);

  return (
    <Grid item>
      {error && chosenType && (
        <Box mt={4}>
          <ErrorAlert error={error} />
        </Box>
      )}
      {loading && chosenType && (
        <Box mt={4}>
          <LoadingBox />
        </Box>
      )}
      {fileUrl && chosenType && (
        <Box mt={4}>
          <Button
            component="a"
            href={fileUrl}
            download
            size="large"
            variant="contained"
            color="primary"
          >
            {t('common:actions.download')}
          </Button>
        </Box>
      )}
      {!chosenType && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <ExportTypeForm />
          </form>
        </FormProvider>
      )}
    </Grid>
  );
};

export default ExportQrCodes;
