import { Visibility, VisibilityOff, } from '@mui/icons-material';
import FormControl, { FormControlProps, } from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { InputBaseProps, } from '@mui/material/InputBase';
import {
  ChangeEventHandler,
  FocusEventHandler, forwardRef, HTMLAttributes, InputHTMLAttributes, KeyboardEventHandler, ReactNode, useState,
} from 'react';
import FormHelperTextClassic from './components/FormHelperTextClassic';
import InputBaseClassic from './components/InputBaseClassic';
import InputLabelClassic from './components/InputLabelClassic';

export interface InputTextClassicProps {
  id?: string,
  name?: string,
  className?: string,
  type?: InputHTMLAttributes<HTMLInputElement>['type'],
  value?: string,
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  autoComplete?: InputHTMLAttributes<HTMLInputElement>['autoComplete'],
  placeholder?: string,
  multiline?: boolean,
  rows?: number,
  minRows?: number,
  maxRows?: number,
  autoFocus?: boolean,
  error?: boolean,
  required?: boolean,
  label?: ReactNode,
  helperText?: ReactNode,
  endAdornment?: ReactNode,
  startAdornment?: ReactNode,
  disabled?: boolean,
  fullWidth?: boolean,
  inputProps?: InputHTMLAttributes<HTMLInputElement>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InputLabelProps?: Omit<HTMLAttributes<HTMLLabelElement>, 'color'>,
  sx?: FormControlProps['sx'],
  // color?:
  // margin?:
  // size?: 
  // sx?:
  // events - onFocus, ...
};

// TODO: classNames
const InputTextClassic = forwardRef((
  {
    id,
    name,
    className,
    type,
    error,
    required,
    label,
    autoComplete,
    helperText,
    endAdornment,
    startAdornment,
    disabled,
    fullWidth,
    inputProps,
    InputLabelProps,
    placeholder,
    value,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    multiline,
    rows,
    maxRows,
    minRows,
    autoFocus,
    sx,
  }: InputTextClassicProps,
  ref: InputBaseProps['ref'],
): JSX.Element => {
  const [showPassword, setShowPassword,] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl
      id={id}
      disabled={disabled}
      error={error}
      required={required}
      sx={sx}
      // color={color}
      fullWidth={fullWidth}
      // margin={margin}
    >
      {label && (
        <InputLabelClassic
          htmlFor={id}
          shrink
          color="primary"
          {...InputLabelProps}
        >
          {label}
        </InputLabelClassic>
      )}
      <InputBaseClassic
        autoFocus={autoFocus}
        id={id}
        name={name}
        ref={ref}
        type={showPassword ? 'text' : type}
        className={className}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        endAdornment={type!=='password' ? endAdornment :     (
          <InputAdornment position="end" sx={{marginRight:2.5,}}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
}
        startAdornment={startAdornment}
        inputProps={inputProps}
        placeholder={placeholder}
        multiline={multiline}
        rows={rows}
        autoComplete={autoComplete || "off"}
        minRows={minRows}
        maxRows={maxRows}
        onBlur={onBlur}
      />
      {(helperText) && (
        <FormHelperTextClassic
          error={error}
        >
          {helperText}
        </FormHelperTextClassic>
      )}
    </FormControl>
  );
});

export default InputTextClassic;
