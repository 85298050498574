import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { SIDEBAR_WIDTH } from 'Utils/constants';
import TopPanel from './TopPanel';
import MobileMenu from './MobileMenu';
import Footer from './Footer';
import Sidebar from './Sidebar';

const Main = styled(Box)<{ isMatchingLg: boolean }>(({ isMatchingLg }) => ({
  flexGrow: 1,
  width: '100%',
  height: '100%',
  ...(isMatchingLg && {
    maxWidth: `calc(100% - ${SIDEBAR_WIDTH}px)`,
  }),
}));

interface Props {
  toHome: string;
  isMatchingLg: boolean;
  isOpenMobileMenu: boolean;
  sidebarContent: ReactNode;
  mobileMenuContent: ReactNode;
  onOpenMobileMenu: () => void;
  onCloseMobileMenu: () => void;
}

const AppLayout = ({
  toHome,
  isMatchingLg,
  isOpenMobileMenu,
  sidebarContent,
  mobileMenuContent,
  onOpenMobileMenu,
  onCloseMobileMenu,
}: Props): JSX.Element => {
  return (
    <Box display="flex">
      {isMatchingLg ? (
        <Sidebar variant="permanent" anchor="left">
          {sidebarContent}
        </Sidebar>
      ) : (
        <MobileMenu isOpen={isOpenMobileMenu} onClose={onCloseMobileMenu}>
          {mobileMenuContent}
        </MobileMenu>
      )}
      <Main
        component="main"
        isMatchingLg={isMatchingLg}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="100vh"
      >
        {!isMatchingLg && (
          <>
            <TopPanel to={toHome} onOpenMobileMenu={onOpenMobileMenu} />
            <Toolbar />
          </>
        )}
        <Outlet />
        {isMatchingLg ? (
          <Sidebar variant="permanent" anchor="left">
            {sidebarContent}
          </Sidebar>
        ) : (
          <MobileMenu isOpen={isOpenMobileMenu} onClose={onCloseMobileMenu}>
            {mobileMenuContent}
          </MobileMenu>
        )}
        <Footer />
      </Main>
    </Box>
  );
};

export default AppLayout;
