import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { totalNumberOfAvailableGiftCodesQuery, } from './queries';

const TotalNumberOfAvailableGiftCodes = ({
  showAddButton,
  filtration,
  showSlug = true,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');

  const query = useMemo(() => totalNumberOfAvailableGiftCodesQuery(), []);
  return (
    <Base
      headerText={t('dashboards.giftCodes.totalNumberOfAvailableGiftCodes')}
      slug={showSlug ? 'total-number-of-available-gift-codes' : undefined}
      showAddButton={showAddButton}

      filtration={filtration}
      dashboardLayoutItemName={
        DashboardLayoutItemName.GiftCodesTotalNumberOfAvailableGiftCodes
      }
      chainsVariables={{ usesImportedCodes: true, }}
    >
      <ChartRenderer
        query={query}
        type={ChartType.bar}
        pivotConfig={{
          x: ['imported_pl_gift_code.pl_gift_chain_id',],
          y: ['measures',],
          fillMissingDates: false,
          joinDateRange: false,
        }}
        hideNullValues
        noDataMessage={t('dashboards.giftCodes.noData')}
      />
    </Base>
  );
};

export default TotalNumberOfAvailableGiftCodes;
