import { ContentBlockTypeEnum, PhotoContentBlock, TextContentBlock, VideoContentBlock, } from 'Apollo/graphql';
import { richTextToString, } from 'Components/RichText/tools';
import { ContentValues, NodeText, NodePhoto, NodeVideo, } from '../PhaseContent/types';

type ContentBlockPhoto = Omit<PhotoContentBlock, 'file'>;
type ContentBlockVideo = Omit<VideoContentBlock, 'file'>;
type ContentBlock = ContentBlockPhoto | ContentBlockVideo | TextContentBlock;

const mapContentBlockText = (node: NodeText): TextContentBlock => ({
  type: ContentBlockTypeEnum.Text,
  text: richTextToString(node.value),
});

const mapContentBlockPhoto = (node: NodePhoto): ContentBlockPhoto => ({
  type: ContentBlockTypeEnum.Photo,
  fileId: node.value?.id as string,
});

const mapContentBlockVideo = (node: NodeVideo): ContentBlockVideo => ({
  type: ContentBlockTypeEnum.Video,
  fileId: node.value?.id as string,
});

export const mapContentVariables = (contentValues?: ContentValues | null): { blocks: ContentBlock[], } => {
  const blocks: ContentBlock[] = [];

  const tmpArray: ContentValues['nodes'] = contentValues?.nodes || [];
  for (let i = 0; i < tmpArray.length; i++) {
    const node = tmpArray[i];
    
    if (node.type?.id) {
      switch (node.type.id) {
        case ContentBlockTypeEnum.Photo: {
          blocks.push(mapContentBlockPhoto(node as NodePhoto));
          break;
        }
        case ContentBlockTypeEnum.Video: {
          blocks.push(mapContentBlockVideo(node as NodeVideo));
          break;
        }
        case ContentBlockTypeEnum.Text: {
          blocks.push(mapContentBlockText(node as NodeText));
          break;
        }
        default: break;
      }
    }
  }

  return { blocks, };
};
