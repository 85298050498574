import { ResultSet, } from '@cubejs-client/core';
import { useDeepCompareMemo, } from 'use-deep-compare';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import { useTranslation, } from 'react-i18next';
import { PivotConfig, } from './types';
import { formatValue, } from './utils';
import getTranslationText from './getTranslationText';

const StyledBase = styled('div')(({ theme, }) => ({
  padding: theme.spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(2.5),
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  width: '100%',
}));

const StyledTableCell = styled(TableCell)(({ theme, }) => ({
  fontWeight: 600,
}));

const StyledTableRow = styled(TableRow)(({ theme, }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledSummaryRow = styled(TableRow)(({ theme, }) => ({
  borderTop: `1px solid black`,
  '& *': {
    fontWeight: 600,
  },
}));

const TableRenderer = ({
  resultSet,
  pivotConfig,
  hideNullValues,
}: {
  resultSet: ResultSet;
  pivotConfig: PivotConfig;
  hideNullValues?: boolean;
}) => {
  const granularity =
    resultSet.query()?.timeDimensions?.[0]?.granularity ?? 'year';
  const { t, } = useTranslation('dashboards');
  const [tableColumns, dataSource,] = useDeepCompareMemo(() => {
    const columns = resultSet.tableColumns(pivotConfig);
    return [columns, resultSet.tablePivot(pivotConfig),];
  }, [resultSet, pivotConfig,]);
  const columnSummaries: Record<string, string> = {};
  dataSource.forEach((row) => {
    tableColumns.forEach((column) => {
      const valueAsANumber = Number(row[column.key]);
      if (!Number.isNaN(valueAsANumber)) {
        columnSummaries[column.key] =
          (columnSummaries[column.key] ?? 0) + valueAsANumber;
      }
    });
  });
  return (
    <StyledBase>
      <Table>
        <TableHead>
          <TableRow>
            {tableColumns.map((column) => {
              if (column.key === 'measures') {
                return null;
              }
              const value = getTranslationText(t, column.title);
              return (
                <StyledTableCell key={column.dataIndex}>
                  {column.key === null
                    ? t('dashboards:dashboards.unknown')
                    : value}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSource.map((row, index) => {
            if (Object.values(row).some((r) => r === null && hideNullValues)) {
              return null;
            }
            return (
              // disabled eslint rule because row does not have a specific unique key
              // eslint-disable-next-line react/no-array-index-key
              <StyledTableRow key={index}>
                {tableColumns.map((column) => {
                  if (column.key === 'measures') {
                    return null;
                  }
                  const value = getTranslationText(t, String(row[column.key]));
                  return (
                    <TableCell key={column.dataIndex}>
                      {formatValue(String(value), {
                        granularity,
                        undefinedValue: t('dashboards:dashboards.unknown'),
                      })}
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
          <StyledSummaryRow>
            {tableColumns.map((column, index) => {
              if (column.key === 'measures') {
                return null;
              }
              if (index === 0) {
                return (
                  <TableCell>{t('dashboards:dashboards.summary')}</TableCell>
                );
              }
              return (
                <TableCell key={column.dataIndex}>
                  {columnSummaries[column.key] !== undefined
                    ? formatValue(String(columnSummaries[column.key]), {
                        granularity,
                        undefinedValue: t('dashboards:dashboards.unknown'),
                      })
                    : null}
                </TableCell>
              );
            })}
          </StyledSummaryRow>
        </TableBody>
      </Table>
    </StyledBase>
  );
};

export default TableRenderer;
