import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const AssortmentColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M11.784 16.547v4.831c0 .45-.087.501-.502.327-2.85-1.198-5.7-2.394-8.552-3.59-.572-.24-.73-.463-.73-1.088V7.723c0-.458.072-.51.494-.332 1.26.53 2.516 1.069 3.778 1.593a.417.417 0 0 1 .29.447c-.005.718-.005 1.436 0 2.155 0 .476.262.775.646.773.384-.001.653-.308.653-.775v-1.502c0-.31.102-.38.386-.262 1.093.45 2.18.917 3.266 1.365.22.09.261.25.261.467.01 1.631.01 3.261.01 4.895zM13.09 16.472v-4.833c0-.284.101-.423.355-.537a1525.09 1525.09 0 0 0 7.884-3.584c.598-.274.663-.237.663.411 0 3.027-.007 6.053.008 9.078 0 .6-.25.937-.784 1.18-2.544 1.142-5.078 2.305-7.616 3.462-.424.194-.508.143-.51-.34v-4.837zm3.911-.236v.655c.01.414.285.717.642.714.356-.003.644-.308.653-.715.01-.315 0-.63 0-.947 0-.655 0-.635.644-.742a.587.587 0 0 0 .386-.287.494.494 0 0 0-.093-.433 28.255 28.255 0 0 0-1.257-1.277c-.238-.228-.448-.225-.687.006-.422.408-.843.818-1.236 1.253a.515.515 0 0 0-.088.45c.065.132.261.217.42.29a.692.692 0 0 0 .29.008c.235-.008.338.106.333.337-.005.23-.007.46-.005.688z"
      fill="#000"
    />
    <path
      d="M12.002 2.202c.124.03.247.066.367.11.936.407 1.87.816 2.799 1.236.11.071.21.16.294.262-.09.093-.161.224-.27.275-2.643 1.24-5.289 2.471-7.938 3.694a.586.586 0 0 1-.42.012 207.57 207.57 0 0 1-3.397-1.419c-.1-.043-.232-.168-.231-.254.001-.087.13-.22.225-.262a1791.28 1791.28 0 0 1 8.21-3.562 2.74 2.74 0 0 1 .36-.092ZM20.95 6.143a2.33 2.33 0 0 1-.354.262 544.26 544.26 0 0 1-3.935 1.805c-1.274.581-2.552 1.151-3.82 1.745a.912.912 0 0 1-.828.02c-.905-.393-1.821-.763-2.728-1.155-.11-.048-.19-.174-.283-.262.095-.093.175-.219.29-.271 1.758-.826 3.528-1.64 5.284-2.457.836-.393 1.675-.773 2.506-1.18a.73.73 0 0 1 .677-.007c.93.42 1.868.82 2.8 1.234.136.08.267.168.392.266z"
      fill="#d47e5f"
    />
  </SvgIcon>
);

export default AssortmentColoredIcon;
