import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import { isHungarianMarket, } from 'Utils/market';
import SupportBox from './SupportBox';


const CallBox = (): JSX.Element => {
  const { t, } = useTranslation('supportSeller');
  return (
    <Box mb={3}>
      <SupportBox title={t('techBoxTitle')} number={t('techNumber')} timeWindow={t('techTimeWindow')} numberAbsolute={`${t('phonePrefix')}${t('techNumber').replace(/\s/g, '')}`} />
      {!isHungarianMarket && <SupportBox title={t('busBoxTitle')} number={t('busNumber')} timeWindow={t('busTimeWindow')} numberAbsolute={`${t('phonePrefix')}${t('busNumber').replace(/\s/g, '')}`} />}
    </Box>
  );
};

export default CallBox;
