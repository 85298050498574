import Grid from '@mui/material/Grid';
import InputUser from './InputUser';
import ExportQrCodes from '../ExportQrCodes';

const View = (): JSX.Element => {
  return (
    <Grid container spacing={3} sx={{ pt: '24px', }}>
      <InputUser />
      <ExportQrCodes type="user" />
    </Grid>
  );
};

export default View;
