import { MutationResult, } from '@apollo/client/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { CreateOrUpdateUserTaxInfoMutation, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import FormError from 'Components/Form/components/FormError';
import FormSubmit from 'Components/Form/components/FormSubmit';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { ROUTES, } from 'Utils/constants';
import EditMyTaxInformationInvoice from '../components/EditMyTaxInformationInvoice';
import EditMyTaxInformationPIT from '../components/EditMyTaxInformationPIT';

type Props = {
  editMutationResult: MutationResult<CreateOrUpdateUserTaxInfoMutation>;
  formIsInvoice: boolean;
};

const View = ({ editMutationResult,formIsInvoice, }: Props): JSX.Element => {
  const { t, } = useTranslation('myProfile');
  const navigate = useNavigate();
  const handleBack = () => navigate(`/${ROUTES.myProfile}`);

  return (
    <>
      {formIsInvoice ? <EditMyTaxInformationInvoice /> : <EditMyTaxInformationPIT/>}
      <Box mt={4}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <FormSubmit
              size="large"
            >
              {t('btnEditInfo')}
            </FormSubmit>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              color="inherit"
              onClick={handleBack}
            >
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <FormError mt={2} />
      <ErrorAlert
        mt={2}
        error={editMutationResult.error}
      />
    </>
  );
};

export default View;
