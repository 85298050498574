import { ResultSet, } from '@cubejs-client/core';
import {
  Chart,
  Axis,
  Tooltip,
  Geom,
  Coordinate,
  Interval,
  Legend,
} from 'bizcharts';
import { useDeepCompareMemo, } from 'use-deep-compare';
import { useTranslation, } from 'react-i18next';
import { PivotConfig, } from './types';
import { formatValue, } from './utils';
import getTranslationText from './getTranslationText';

const stackedChartData = (resultSet: ResultSet) => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray, }) =>
      yValuesArray.map(([yValues, m,]) => ({
        // @ts-ignore
        x: resultSet.axisValuesString(xValues, ', '),
        // @ts-ignore
        color: resultSet.axisValuesString(yValues, ', '),
        // @ts-ignore
        measure: m && Number.parseFloat(m),
      })),
    )
    .reduce((a, b) => a.concat(b), []);
  return data;
};

const BarChartRenderer = ({
  resultSet,
  pivotConfig,
  hideNullValues = true,
  hideUnknownValues = false,
  orderByDate = true,
  hideLegend = false,
}: {
  resultSet: ResultSet;
  pivotConfig: PivotConfig | null;
  hideNullValues?: boolean;
  hideUnknownValues?: boolean;
  orderByDate?: boolean;
  hideLegend?: boolean;
}) => {
  const { t, } = useTranslation('dashboards');
  const granularity =
    resultSet.query()?.timeDimensions?.[0]?.granularity ?? 'year';
  const data = useDeepCompareMemo(
    () => stackedChartData(resultSet),
    [resultSet.serialize(),],
  );
  const filteredData = data.filter((d) => {
    if (hideUnknownValues && (d.x === 'null' || d.x === '∅')) {
      return false;
    }
    if (hideNullValues && (d.measure === null || d.measure === 0)) {
      return false;
    }
    return true;
  });
  const orderedData = filteredData
    .sort((a, b) => b.measure - a.measure)
    .slice(0, 10);
  console.log(filteredData);
  if (!pivotConfig) {
    return (
      <Chart
        scale={{
          x: {
            tickCount: 5,
          },
        }}
        autoFit
        height={392}
        padding={[20, 20, hideLegend ? 20 : 50, 110,]}
        data={orderedData.map((d) => ({
          color: getTranslationText(t, d.color),
          measure: d.measure,
          x: formatValue(d.x, {
            undefinedValue: t('dashboards.unknown'),
            granularity,
          }),
        }))}
        forceFit
      >
        <Coordinate reflect="y" transpose />
        <Legend name="name" visible={false} />
        <Interval position="x*measure" color={['color', "#FF7000",]} />
        <Axis name="measure" position="right" />
      </Chart>
    );
  }
  if (pivotConfig) {
    const stacked = !(pivotConfig.x || []).includes('measures');
    return (
      <Chart
        legend={false}
        scale={{
          x: {
            tickCount: 5,
          },
        }}
        autoFit
        height={392}
        padding={[0, 20, hideLegend ? 20 : 50, 60,]}
        data={
          orderByDate
            ? filteredData
              .sort(
                (a, b) => new Date(a.x).getTime() - new Date(b.x).getTime(),
              )
              .map((d) => ({
                color: getTranslationText(t, d.color),
                measure: d.measure,
                x: formatValue(d.x, {
                  undefinedValue: t('dashboards.unknown'),
                  granularity,
                }),
              }))
            : data.map((d) => ({
              color: getTranslationText(t, d.color),
              measure: d.measure,
              x: formatValue(d.x, {
                undefinedValue: t('dashboards.unknown'),
                granularity,
              }),
            }))
        }
        forceFit
      >
        <Axis name="x" />
        <Axis name="measure" />
        <Tooltip />
        <Geom
          type="interval"
          position="x*measure"
          color={['color', ['#96704F', '#FF7000', '#413530',],]}
          adjust={stacked ? 'stack' : 'dodge'}
        />
        {hideLegend && <Legend name="color" visible={false} />}
      </Chart>
    );
  }
  return null;
};

export default BarChartRenderer;
