import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuIcon from 'Utils/svg/MenuIcon';

const StyledButtonMenu = styled(ButtonBase)(({ theme, }) => ({
  padding: theme.spacing(0.5, 0.75),
  borderRadius: theme.shape.borderRadius,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  
  '& > .MuiTypography-button': {
    marginRight: theme.spacing(2),
  },

  '& .MuiSvgIcon-root': {
    height: 18,
  },
}));

interface Props {
  onClick: () => void,
}

const ButtonMenu = ({
  onClick,
}: Props): JSX.Element => {
  const { t, } = useTranslation('common');

  return (
    <StyledButtonMenu
      onClick={onClick}
    >
      <Typography
        component="span"
        variant="button"
      >
        {t('topPanel.menuButton')}
      </Typography>
      <span>
        <Badge color="secondary" variant="dot">
          <MenuIcon />
        </Badge>
      </span>
    </StyledButtonMenu>
  );
};

export default ButtonMenu;
