import { OrderBy, TableVoucherPurchaseFragment, VoucherPurchaseSortableFieldsEnum, } from 'Apollo/graphql';
import { StatusOption, } from 'Utils/options/useOptionsVoucherPurchaseStatus';
import { ChangeTableData, ChangeTableFilter, ChangeTableSort, } from 'Utils/types';
import create from 'zustand';

export type TableVoucherPurchaseFilter = {
  status: StatusOption | null;
  voucherId: string;
  voucherName: string;
  user: string;
};

export type VoucherPurchaseStore = {
  modalDetailVoucherPurchase: {
    isOpen: boolean;
    voucherPurchase: TableVoucherPurchaseFragment | null;
  };
  tableVoucherPurchases: {
    data: {
      sortField: VoucherPurchaseSortableFieldsEnum | null;
      sortOrder: OrderBy | null;
      limit: number;
      offset: number;
    };
    filter: TableVoucherPurchaseFilter;
  };

  openModalDetailVoucherPurchase: (voucherPurchase: TableVoucherPurchaseFragment) => void;
  closeModalDetailVoucherPurchase: () => void;
  changeTableData: ChangeTableData;
  changeTableFilter: ChangeTableFilter;
  changeTableSort: ChangeTableSort;
};

export const useVoucherPurchaseStore = create<VoucherPurchaseStore>((set) => ({
  modalDetailVoucherPurchase: {
    isOpen: false,
    voucherPurchase: null,
  },
  tableVoucherPurchases: {
    data: {
      sortField: null,
      sortOrder: null,
      limit: 10,
      offset: 0,
    },
    filter: {
      status: null,
      voucherId: '',
      voucherName: '',
      user: '',
    },
  },

  openModalDetailVoucherPurchase: (voucherPurchase) =>
    set({
      modalDetailVoucherPurchase: {
        isOpen: true,
        voucherPurchase,
      },
    }),
  closeModalDetailVoucherPurchase: () =>
    set((state) => ({
      modalDetailVoucherPurchase: {
        ...state.modalDetailVoucherPurchase,
        isOpen: false,
      },
    })),

  /**
   * VoucherPurchase table
   */
  changeTableData: (name, value) =>
    set((state) => ({
      tableVoucherPurchases: {
        ...state.tableVoucherPurchases,
        data: {
          ...state.tableVoucherPurchases.data,
          [name]: value,
        },
      },
    })),
  changeTableFilter: (name, value) =>
    set((state) => ({
      tableVoucherPurchases: {
        ...state.tableVoucherPurchases,
        data: {
          ...state.tableVoucherPurchases.data,
          offset: 0,
        },
        filter: {
          ...state.tableVoucherPurchases.filter,
          [name]: value,
        },
      },
    })),
  changeTableSort: (sortField, sortOrder) =>
    set((state) => ({
      tableVoucherPurchases: {
        ...state.tableVoucherPurchases,
        data: {
          ...state.tableVoucherPurchases.data,
          sortField: sortField as VoucherPurchaseSortableFieldsEnum,
          sortOrder,
        },
      },
    })),
}));
