import { useParams, } from 'react-router-dom';
import { useForm, FormProvider, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useResetPasswordMutation, } from 'Apollo/graphql';
import {
  initNewPasswordFormValues,
  newPasswordFormValidationSchema,
  mapFormToVariables,
} from './utils';
import FormView from './FormView';

interface Props {
  changePasswordCompleted: () => void,
}

const FormLogic = ({
  changePasswordCompleted,
}: Props): JSX.Element => {
  const { resetCode, } = useParams();

  const methods = useForm({
    defaultValues: initNewPasswordFormValues,
    resolver: yupResolver(newPasswordFormValidationSchema),
    mode: 'onTouched',
  });

  const [resetMutation, resetMutationResult, ] = useResetPasswordMutation({
    onCompleted: () => {
      changePasswordCompleted();
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await resetMutation({
      variables: {
        resetPasswordInput: mapFormToVariables(values, resetCode),
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <FormView
          resetMutationResult={resetMutationResult}
        />
      </form>
    </FormProvider>
  );
};

export default FormLogic;
