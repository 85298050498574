import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { PromotionsStore, usePromotionsStore, } from 'Modules/PromotionsAdmin/store/PromotionsStore';
import Logic from './Logic';

const selectModalData = (s: PromotionsStore) => s.modalCreatePromotion;
const selectClose = (s: PromotionsStore) => s.closeModalCreatePromotion;

const ModalCreatePromotion = (): JSX.Element => {
  const { isOpen, } = usePromotionsStore(selectModalData);
  const onClose = usePromotionsStore(selectClose);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen}
    >
      <Logic
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalCreatePromotion;
