import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { ReplacementReasonOption, } from 'Utils/options/useOptionsReplacementReason';
import * as yup from 'yup';
import { ProductOption, } from '../createReplacementStore';

export const createTickerValidationSchema = () =>
  yup.object().shape({
    category: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
    products: yup.array().test({
      message: INPUT_FORM_ERRORS.REQUIRED,
      test: (value) => {
        const totalCount = (value as ProductOption[]).reduce((prev, curr) => prev + (curr.count || 0), 0);
        return totalCount > 0;
      },
    }),
    reason: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
    reasonText: yup.string().when('reason', {
      is: (reason: ReplacementReasonOption | null) => reason?.requireTextInput,
      then: yup.string().max(525, INPUT_FORM_ERRORS.STRING_MAX).required(INPUT_FORM_ERRORS.REQUIRED),
    }),
    note: yup.string(),
    batchCode: yup.string().nullable(),
  });
