import { Replacement, } from 'Modules/ReplacementSeller/store/ReplacementStore';
import { CompleteReplacementInput, } from 'Apollo/graphql';

export const mapReplacementToVariables = (
  replacement: Replacement,
): CompleteReplacementInput => ({
  replacementId: replacement.id || '',
  replacedAssortmentsWhenCompleted: replacement.exchangedProducts.map(product => ({
    assortmentId: product.option.id || '',
    count: product.count,
    batchCode: replacement.batchCode,
  })),
});
