import moment from 'moment';
import { ChartType, } from './types';

const dateIso8601Regexp =
  /^\d{4}-(?:0[1-9]|1[0-2])-(?:[0-2][1-9]|[1-3]0|3[01])T(?:[0-1][0-9]|2[0-3])(?::[0-6]\d)(?::[0-6]\d)?(?:\.\d{3})?(?:[+-][0-2]\d:[0-5]\d|Z)?$/;

function localizeTimestamp(input: string, granularity?: string): string {
  let format;

  switch (granularity) {
    case 'day':
      format = 'DD/MM/YYYY';
      break;
    case 'week':
      format = 'DD/MM/YYYY';
      break;
    case 'month':
      format = 'MM/YYYY';
      break;
    case 'year':
      format = 'YYYY';
      break;
    default:
      break;
  }

  const date = new Date(input);
  if (format) {
    return moment(date).format(format);
  }
  return date.toLocaleDateString();
}

const formatValue = (
  value: string,
  options: {
    undefinedValue: string;
    granularity?: string;
    pieType?: ChartType;
  },
) => {
  if (dateIso8601Regexp.test(value)) {
    return localizeTimestamp(value, options.granularity);
  }
  if (value === 'null' || value === '∅') {
    return options.undefinedValue;
  }
  // ignoring pie charts because the legend works differently to other charts
  if (options.pieType !== ChartType.pie) {
    const numberOfSpaces = value.split(' ').length - 1;
    if (numberOfSpaces === 2) {
      return value.replace(' ', '\n');
    }
    if (numberOfSpaces > 2) {
      const words = value.split(' ');
      words[1] += '\n';
      words[3] += '\n';
      return words.join(' ');
    }
  }
  return value;
};

export { formatValue, };
