import { useState, } from 'react';
import AppContainer from 'Components/AppContainer';
import FormLogic from './FormLogic';
import SendView from './SendView';
import { ForgottenPasswordEmail, } from './types';

const ForgottenPasswordPage = (): JSX.Element => {
  const [ forgottenPasswordEmail, setForgottenPasswordEmail, ] = useState<ForgottenPasswordEmail | null>(null);

  return (
    <AppContainer maxWidth="sm">
      {forgottenPasswordEmail ? (
        <SendView
          forgottenPasswordEmail={forgottenPasswordEmail}
        />
      ) : (
        <FormLogic
          setForgottenPasswordEmail={setForgottenPasswordEmail}
        />
      )}
    </AppContainer>
  );
};

export default ForgottenPasswordPage;
