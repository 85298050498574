import { ApolloError, } from '@apollo/client/errors';
import { PreviouslyOwnedDevicesOptionFragment, usePreviouslyOwnedDevicesOptionsQuery, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

type GetOptionLabel = (o: PreviouslyOwnedDevicesOptionFragment) => string;
type GetOptionKey = (o: PreviouslyOwnedDevicesOptionFragment) => string;

type UseOptionsPreviouslyOwnedDevice = () => {
  options: PreviouslyOwnedDevicesOptionFragment[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<PreviouslyOwnedDevicesOptionFragment>, 
  loading:boolean,
  error: ApolloError | undefined,
};

export const useOptionsPreviouslyOwnedDevice: UseOptionsPreviouslyOwnedDevice = () => {
  const getOptionLabel = (o: PreviouslyOwnedDevicesOptionFragment) => o.label;
  const { loading, error, data, } = usePreviouslyOwnedDevicesOptionsQuery();

  return {
    options: data?.previouslyOwnedDevicesOptions.options || [],
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
    loading,
    error,
  };
};
