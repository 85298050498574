import { useRedeemGiftCodeMutation, } from 'Apollo/graphql';
import shallow from 'zustand/shallow';
import { CzGiftCodeStoreStore, useCzGiftCodeStore, } from 'Modules/GiftCodeSeller/store/CzGiftCodeStore';
import View from './View';

const selectStoreData = (s: CzGiftCodeStoreStore) => ({
  completeDetailPhase: s.completeDetailPhase,
  giftCode: s.giftCode,
  resetStore: s.resetStore,
});

const PhaseDetail = (): JSX.Element => {
  const {
    completeDetailPhase,
    giftCode,
    resetStore,
  } = useCzGiftCodeStore(selectStoreData, shallow);

  const [redeemGiftCodeMutate, { error, },] = useRedeemGiftCodeMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result.redeemGiftCode) {
        completeDetailPhase();
      }
    },
  });

  const applyGiftCode = () => {
    redeemGiftCodeMutate({
      variables: {
        redeemGiftCodeInput: {
          code: giftCode?.code ?? '',
        },
      },
    });
  };

  return (
    <View
      giftCode={giftCode}
      applyCode={applyGiftCode}
      error={error}
      resetStore={resetStore}
    />
  );
};

export default PhaseDetail;
