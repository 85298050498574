import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';

export type PhaseInitFormValues = {
  email: string,
};

export const initPhaseInitFormValues: PhaseInitFormValues = {
  email: '',
};

export const phaseInitFormValidationSchema = yup.object().shape({
  email: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .max(255, INPUT_FORM_ERRORS.STRING_MAX)
    .email(INPUT_FORM_ERRORS.EMAIL),
});

type InitPhaseBadFormDataFieldMapper = {
  email: undefined | keyof PhaseInitFormValues
};

export const initPhaseBadFormDataFieldMapper: InitPhaseBadFormDataFieldMapper = {
  email: 'email',
};
