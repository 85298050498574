import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import { ObligatoryTaskReviewFragment, } from 'Apollo/graphql';
import ChipTaskCompletionStatus from 'Components/ChipTaskCompletionStatus';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellBlock from 'Components/Table/TableCellBlock';
import TableCellText from 'Components/Table/TableCellText';
import { useTranslation, } from 'react-i18next';
import { Link as LinkRouter, } from 'react-router-dom';
import { ROUTES, } from 'Utils/constants';
import { valueOrEmpty, } from 'Utils/parsers';

interface Props {
  row: ObligatoryTaskReviewFragment;
}
const TasksTableRow = ({ row, }: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');

  return (
    <TableRow key={row.id}>
      <TableCellText>{valueOrEmpty(row.task.id)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.task.name)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.task.description)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.author.username)}</TableCellText>
      <TableCellBlock>
        <ChipTaskCompletionStatus taskCompletionStatus={row.status}  />
      </TableCellBlock>
      <TableCellText>{valueOrEmpty(row.task.pointsForCompletion)}</TableCellText>
      <TableCellText>{t('common:date.fullDate', { date: new Date(row.updatedAt), })}</TableCellText>
      <TableCellActions>
        <MenuItem component={LinkRouter} to={`/${ROUTES.admin}/${ROUTES.tasks}/${ROUTES.review}/${row.id}`}>
          {t('obligatoryTasks.btnReview')}
        </MenuItem>
      </TableCellActions>
    </TableRow>
  );
};

export default TasksTableRow;
