import { useMemo, } from 'react';
import { ApolloError, } from '@apollo/client';
import { usePhonePrefixesInfoQuery, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, getOptionFieldName, } from 'Utils/helpers';

export type PhonePrefixOption = {
  id: string,
  name: string,
};

export const createPhonePrefixOption = (prefix: string): PhonePrefixOption => ({
  id: prefix.replace("+", ""),
  name: prefix,
});

type GetOptionLabel = (o: PhonePrefixOption) => string;
type GetOptionKey = (o: PhonePrefixOption) => string;

type UseOptionsPhonePrefix = () => {
  defaultValue: PhonePrefixOption | null,
  loading: boolean,
  error: ApolloError | undefined,
  options: PhonePrefixOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<PhonePrefixOption>,
};

export const useOptionsPhonePrefix: UseOptionsPhonePrefix = () => {
  const { loading, error, data, } = usePhonePrefixesInfoQuery();
  const options = useMemo<PhonePrefixOption[]>(
    () => {
      const uniquePrefixes = new Set<string>((data?.phonePrefixesInfo.countryCodesAndPhonePrefixes || []).map((o) => o.callingCode));
      return Array.from(uniquePrefixes).map((o) => createPhonePrefixOption(o));
    },
    [data,],
  );

  const defaultValue = data?.phonePrefixesInfo.defaultPhonePrefix
    ? createPhonePrefixOption(data.phonePrefixesInfo.defaultPhonePrefix.replace(/^[A-Z]{2}-/gm, '+'))
    : null;

  return {
    defaultValue,
    loading,
    error,
    options,
    getOptionLabel: getOptionFieldName,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
