import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { PointsTransactionOperation, PointsTransactionReason, UserPointsQuery, } from 'Apollo/graphql';
import TransActionChunk from '../components/TransactionChunk';
import MonthBox from './MonthBox';

type PointsTransaction = {
  __typename?: 'PointsTransaction' | undefined;
  reason: PointsTransactionReason;
  amount: number;
  operation: PointsTransactionOperation;
  updatedAt: string;
  id: string;
};
type Props = {
  data: UserPointsQuery;
};

type DataObject = {
  id: string;
  month: number;
  year: number;
  exampleDate: string;
  dataArray: PointsTransaction[];
};

const processData = (transactions: PointsTransaction[]): DataObject[] => {
  const dataMap = new Map<string, DataObject>();

  transactions.forEach(transaction => {
    const date = new Date(transaction.updatedAt);
    const month = date.getMonth();
    const year = date.getFullYear();
    const id = `${month}${year}`;

    if (!dataMap.has(id)) {
      dataMap.set(id, {
        id,
        month,
        year,
        exampleDate: transaction.updatedAt,
        dataArray: [],
      });
    }

    dataMap.get(id)!.dataArray.push(transaction);
  });

  return Array.from(dataMap.values());
};

const TransactionList = ({ data, }: Props): JSX.Element => {
  const { t, } = useTranslation('myPoints');
  const processedData = useMemo(() => {
    if (data?.pointsTransactions.data.length < 1) return [];
    return processData(data?.pointsTransactions.data);
  }, [data,]);

  if (data.pointsTransactions.data.length < 1) return <Typography color="textSecondary">{t('users:detailUserModal.noTransactions')}</Typography>;

  return (
    <List subheader={<li />}>
      {processedData.map((object) => (
        <li key={object.id}>
          <ul style={{ padding: 0, margin: 0, listStyle: 'none', }}>
            <MonthBox dateString={object.exampleDate} />
            {object.dataArray.map((item) => (
              <TransActionChunk key={item.id} data={item} />
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
};

export default TransactionList;
