import Typography from '@mui/material/Typography';
import AppContainer from 'Components/AppContainer';
import { useTranslation, } from 'react-i18next';
import FetchData from './Fetchdata';

const EditMyProfile = (): JSX.Element => {
  const { t, } = useTranslation("myProfile");
  return (
    <AppContainer maxWidth="sm">
      <Typography
        variant="h1"
        sx={{
          marginBottom: 1.5,
        }}
      >
        {t('title')}
      </Typography>
      <Typography
        variant="inherit"
        sx={{
          marginBottom: 5,
        }}
      >
        {t('editMyProfileInfo')}
      </Typography>
      <FetchData/>
    </AppContainer>
  );
};

export default EditMyProfile;
