import { MutationResult, } from '@apollo/client/react/types/types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CheckGiftCodeMutation, } from 'Apollo/graphql';
import { FormInputText, FormSubmit, } from 'Components/Form';
import { useTranslation, } from 'react-i18next';

interface Props {
  checkGiftCodeResult: MutationResult<CheckGiftCodeMutation>;
}

const View = ({ checkGiftCodeResult, }: Props): JSX.Element => {
  const { t, } = useTranslation('giftCodeSeller');

  return (
    <>
      <Typography variant="h1" sx={{ mb: 1.5, }}>
        {t('form.verificationTitle')}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3, }}>
        {t('form.verificationInfo')}
      </Typography>

      <Box mb={4}>
        <FormInputText autoFocus name="giftCode" label={t('form.giftCodeNumber')} disabled={checkGiftCodeResult.loading} />
      </Box>

      <FormSubmit size="large" color="primary" sx={{ width: { xs: '100%', lg: 'unset', }, }}>
        {t('form.btnVerify')}
      </FormSubmit>
    </>
  );
};

export default View;
