import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CardLayout from './CardLayout';

const SkeletonItem = () => {
  return (
    <CardLayout
      title={(
        <Skeleton
          variant="text"
          width={190}
        />
      )}
    >
      <Typography variant="h2" component="p" marginBottom={1}>
        <Skeleton
          variant="text"
          width="60%"
        />
      </Typography>
      <Typography>
        <Skeleton
          variant="text"
          width="100%"
        />
        <Skeleton
          variant="text"
          width="100%"
        />
        <Skeleton
          variant="text"
          width="40%"
        />
      </Typography>
    </CardLayout>
  );
};

export default SkeletonItem;
