import { Box, Button, Dialog, DialogContent, IconButton, Stack, Typography, styled, } from '@mui/material';
import React from 'react';
import { useTranslation, } from 'react-i18next';

import { NotificationType, } from 'Apollo/graphql';
import useNotifications from 'Tools/useNotifications';
import CloseIcon from 'Utils/svg/CloseIcon';

import { useNavigate, useSearchParams, } from 'react-router-dom';
import NewsNotificationIcon from 'Utils/svg/NewsNotificationIcon';
import EmotionNotificationIcon from 'Utils/svg/EmotionNotificationIcon';
import PromotionNotificationIcon from 'Utils/svg/PromotionNotificationIcon';
import AssortmentNotificationIcon from 'Utils/svg/AssortmentNotificationIcon';
import TasksNotificationIcon from 'Utils/svg/TasksNotificationIcon';

const getDetailUrl = ({ type, entityId, }: { type: NotificationType; entityId: string }) => {
  const notificationTypeUrl: { [key in NotificationType]: string } = {
    Assortment: '/assortment?noPopUp=true',
    NewsArticle: `/news/${entityId}?noPopUp=true`,
    Promotion: '/promotions?noPopUp=true',
    Task: `/tasks/${entityId}?noPopUp=true`,
    Voucher: `/myPoints/${entityId}?noPopUp=true`,
  };

  return notificationTypeUrl[type];
};

export const Image = styled('img')(() => ({
  maxHeight: 350,
  maxWidth: 350,
}));

type NotificationIconType = {
  type: NotificationType | null | undefined;
};

const NotificationIcon = ({ type, }: NotificationIconType) => {
  switch (type) {
    case NotificationType.Assortment: {
      return <AssortmentNotificationIcon sx={{ fontSize: '5rem', }} />;
    }
    case NotificationType.NewsArticle: {
      return <NewsNotificationIcon sx={{ fontSize: '5rem', }} />;
    }
    case NotificationType.Promotion: {
      return <PromotionNotificationIcon sx={{ fontSize: '5rem', }} />;
    }
    case NotificationType.Task: {
      return <TasksNotificationIcon sx={{ fontSize: '5rem', }} />;
    }
    case NotificationType.Voucher: {
      return <EmotionNotificationIcon sx={{ fontSize: '5rem', }} />;
    }
    default: {
      return null;
    }
  }
};

const NotificationPopUpModal: React.FC = () => {
  const { t, } = useTranslation('common');
  const [searchParams,] = useSearchParams();
  const { notifications, dismissNotification, visitDetail, } = useNotifications();
  const navigate = useNavigate();

  const notification = notifications?.[0];

  if (!notification || searchParams.get('noPopUp')) {
    return null;
  }

  const disableSubmitButton = false;

  const handleClose = () => {
    dismissNotification(notification.id, notification.content.type);
  };

  return (
    <Dialog open onClose={() => {}} sx={{ backdropFilter: 'blur(8px)', }} maxWidth="sm" fullWidth>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: '50px', }}>
        <Stack spacing="10px" sx={{ alignItems: 'center', }}>
          <Box>
            <NotificationIcon type={notification.content.type} />
          </Box>
          <Box>
            <Typography variant="h1" color="secondary">
              {notification?.content.title}
            </Typography>
          </Box>
          <Box>
            <Typography variant="footer">{notification.content.description}</Typography>
          </Box>
          <Box>{notification?.content.imageUrl && <Image src={notification?.content.imageUrl} alt={notification?.content.title} />}</Box>
          <Button
            disabled={disableSubmitButton}
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
              navigate(getDetailUrl({ type: notification.content.type, entityId: notification.content.entityId, }));
              visitDetail(notification.id, notification.content.type);
            }}
            sx={{ paddingX: '60px', borderRadius: '25px', alignSelf: 'center', }}
          >
            {t('notification.btnSubmit')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationPopUpModal;
