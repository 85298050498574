import { BinaryFilter, Query, } from '@cubejs-client/core';

export enum DateRangeEnum {
  today = 'Today',
  yesterday = 'Yesterday',
  thisWeek = 'This Week',
  lastWeek = 'Last Week',
  thisMonth = 'This Month',
  lastMonth = 'Last Month',
  thisYear = 'This Year',
  lastYear = 'Last Year',
}

export type CustomDateRange = DateRangeEnum | [string, string,];

export interface CustomDateRangeProps {
  dateRange?: CustomDateRange;
}

export type PivotConfig = {
  x: string[];
  y: string[];
  fillMissingDates: boolean;
  joinDateRange: boolean;
};

export enum ChartType {
  pie = 'pie',
  number = 'number',
  table = 'table',
  bar = 'bar',
  doubleNumber = 'doubleNumber',
}

export enum LegendPosition {
  top = 'top',
  left = 'left',
  right = 'right',
  bottom = 'bottom',
}

export enum OptionTypes {
  source = 'source',
  products = 'products',
  time = 'time',
  category = 'category',
  role = 'role',
  chain = 'chain',
}

export type ChartBaseProps = {
  showAddButton?: boolean | null;
  showFiltration?: boolean;
  showSlug?: boolean;
  filtration?: OptionTypes[];
  chartFilter?: BinaryFilter[] | Query['filters'];
  timeDimensions?: Query['timeDimensions'];
  showTotal?: boolean
};
