import { useParams, } from 'react-router-dom';
import { VoucherType, useVoucherQuery, } from 'Apollo/graphql';
import LoadingBox from 'Components/LoadingBox';
import AppContainer from 'Components/AppContainer';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { Box, } from '@mui/material';
import AlzaLogic from './Alza/AlzaLogic';
import StandartLogic from './Standart/StandartLogic';
import SodexoLogic from './Sodexo/SodexoLogic';


const VoucherDetialWithQuery: React.FC = () => {
  const { voucherId, } = useParams();
  const { loading, error, data, } = useVoucherQuery({
    variables: { id: voucherId ?? '', },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoadingBox />;
  }

  if (error || !data?.voucher)
    return (
      <AppContainer maxWidth="sm">
        <ErrorAlert forceError error={error} />
      </AppContainer>
    );

  if (data?.voucher.type === VoucherType.Regular) {
    return <StandartLogic data={data} />;
  }

  if (data?.voucher.type === VoucherType.Sodexo) {
    return <SodexoLogic data={data}/>;
  }

  return <Box>{`Unknown type ${data.voucher.type}`}</Box>;
};

const VoucherDetail: React.FC = () => {
  const { voucherId, } = useParams();

  if (voucherId === 'alza') {
    return <AlzaLogic />;
  }

  return <VoucherDetialWithQuery/>;
};

export default VoucherDetail;
