import { useMemo, } from 'react';
import { SellerYesNoAssessmentBlockFragment, } from 'Apollo/graphql';
import { FormInputGroupRadio, } from 'Components/Form';
import { getOptionFieldId, getOptionFieldName, } from 'Utils/helpers';
import { AssessmentOption, } from '../../types';
import NodeLabel from '../NodeLabel';
import { NodeProps, } from '../types';
import { buildNodeInputName, } from '../utils';

const NodeYesNo = ({
  index,
  block,
  assessmentConfig,
}: NodeProps<SellerYesNoAssessmentBlockFragment>): JSX.Element => {
  const options = useMemo<AssessmentOption[]>(
    () => block.answers.map((answer, answerIndex) => ({
      id: answerIndex.toString(),
      value: answerIndex,
      name: answer,
    })),
    [ block.answers, ],
  );

  return (
    <FormInputGroupRadio<AssessmentOption>
      name={buildNodeInputName(index, 'value')}
      label={(
        <NodeLabel
          index={index}
          question={block.question}
          numberOfPoints={block.numberOfPoints}
          assessmentConfig={assessmentConfig}
        />
      )}
      options={options}
      getOptionKey={getOptionFieldId}
      getOptionLabel={getOptionFieldName}
    />
  );
};

export default NodeYesNo;
