import { Query, } from '@cubejs-client/core';
import { zodResolver, } from '@hookform/resolvers/zod';
import { Grid, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import ChartDetailLayout from 'Components/Dashboard/ChartDetailLayout';
import PurchasedProduct from 'Components/Dashboard/Registrations/PurchasedProduct';
import { purchasedProductQuery, } from 'Components/Dashboard/Registrations/queries';
import { useMemo, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartType, OptionTypes, } from 'Utils/Charts/types';
import useDashboardFilter from 'Utils/Charts/UseDashboardFilter';
import { TimeValidationSchema, } from 'Utils/Charts/ValidationSchemas';
import { z, } from 'zod';

type ValidationSchema = z.infer<typeof TimeValidationSchema>;

const PurchasedProductsPage = () => {
  const { t, } = useTranslation('dashboards');
  const filtration = [OptionTypes.time,];
  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(TimeValidationSchema),
    defaultValues: {
      date: 'This year',
    },
  });
  const watch = methods.watch();

  const { filtersArray, dateRange, } = useDashboardFilter({}, watch.date);

  const query = useMemo(
    () =>
      purchasedProductQuery({
        timeDimensions: [
          {
            dateRange,
          },
        ],
        showNullValues: true,
        filters: filtersArray,
      }),
    [dateRange, filtersArray,],
  );

  return (
    <AppContainer>
      <ChartDetailLayout pageTitle={t('common:dashboardSidebar.registrations')}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <PurchasedProduct
                showSlug={false}
                filtration={filtration}
                chartFilter={filtersArray}
                timeDimensions={query.timeDimensions}
              />
            </FormProvider>
          </Grid>
          <Grid item xs={12}>
            <ChartRenderer
              query={query}
              type={ChartType.table}
              pivotConfig={{
                x: ['dimensions',],
                y: ['measures',],
                fillMissingDates: true,
                joinDateRange: false,
              }}
            />
          </Grid>
        </Grid>
      </ChartDetailLayout>
    </AppContainer>
  );
};

export default PurchasedProductsPage;
