import { PaginationInput, TaskCompletionFilterInput, TaskCompletionSortInput, TaskFilterInput, TaskSortInput, } from 'Apollo/graphql';
import { TaskStore, } from 'Modules/TasksAdmin/store/GeneralTasksStore';
import { ObligatoryTaskStore, } from 'Modules/TasksAdmin/store/ObligatoryTaskStore';

export const mapVariablesTask = (
  storeData: TaskStore['tableTasks'],
): {
  filter?: TaskFilterInput;
  pagination?: PaginationInput;
  sort?: TaskSortInput;
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    id: storeData.filter.id ? { contains: storeData.filter.id, } : undefined,
    type: storeData.filter.type?.id ? { contains: storeData.filter.type.id, } : undefined,
    assessmentMethod: storeData.filter.assessmentMethod?.id ? { contains: storeData.filter.assessmentMethod?.id, } : undefined,
    renewal: storeData.filter.renewal?.id ? { contains: storeData.filter.renewal?.id, } : undefined,
    name: storeData.filter.name ? { contains: storeData.filter.name, } : undefined,
    pointsForCompletion: storeData.filter.pointsForCompletion ? { equals: storeData.filter.pointsForCompletion, } : undefined,
    pointsLimit: storeData.filter.pointsLimit ? { equals: storeData.filter.pointsLimit, } : undefined,
    typeOfCompletion: storeData.filter.typeOfCompletion ? { contains: storeData.filter.typeOfCompletion, } : undefined,
    attemptLimit: storeData.filter.attemptLimit ? { equals: storeData.filter.attemptLimit, } : undefined,
    chainIds: storeData.filter.chainId?.id ? { has: storeData.filter.chainId.id, } : undefined,
    author: storeData.filter.author ? { username: { contains: storeData.filter.author, }, } : undefined,
    isNowAvailable: storeData.filter.isNowAvailable ? storeData.filter.isNowAvailable.value : undefined,
  },
  sort:
    storeData.data.sortField && storeData.data.sortOrder
      ? {
          field: storeData.data.sortField,
          order: storeData.data.sortOrder,
        }
      : undefined,
});

export const mapVariablesObligatory = (
  storeData: ObligatoryTaskStore['tableTasks'],
): {
  filter?: TaskCompletionFilterInput;
  pagination?: PaginationInput;
  sort?: TaskCompletionSortInput;
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    id: storeData.filter.id ? { contains: storeData.filter.id, } : undefined,
    task: {
      type: {equals: "OBLIGATORY",},
      name: storeData.filter.name ? { contains: storeData.filter.name, } : undefined,
      id: storeData.filter.taskId ? { contains: storeData.filter.taskId, } : undefined,
      description: storeData.filter.description ? { contains: storeData.filter.description, } : undefined,
      pointsForCompletion: storeData.filter.pointsForCompletion ? { equals: storeData.filter.pointsForCompletion, } : undefined,
    },
    author: storeData.filter.author ? { username: { contains: storeData.filter.author, }, } : undefined,
    status: storeData.filter.status ? { equals: storeData.filter.status.id, } : undefined,

  },
  sort:
    storeData.data.sortField && storeData.data.sortOrder
      ? {
          field: storeData.data.sortField,
          order: storeData.data.sortOrder,
        }
      : undefined,
});
