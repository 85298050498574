import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const GiftCodeIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
        d="M1.308 7.719h15.389v.32c0 2.61-.025 5.22.011 7.83.017 1.285-.914 2.135-2.139 2.13-3.71-.015-7.421-.015-11.131 0-1.223 0-2.159-.846-2.14-2.13.036-2.61.01-5.22.01-7.83v-.32Zm7.725 2.751c-.278-.265-.5-.498-.744-.708a2.116 2.116 0 0 0-2.848.114c-.78.777-.843 2.057-.098 2.868.947 1.029 1.922 2.024 2.898 3.022a1.02 1.02 0 0 0 1.524 0c.962-.984 1.923-1.97 2.857-2.983.762-.827.685-2.114-.13-2.906a2.113 2.113 0 0 0-2.893-.01c-.194.186-.365.386-.566.603ZM4.456 4.069a2.628 2.628 0 0 1-1.066-.97C2.56 1.687 3.39.074 5.03.013 6.67-.047 7.91.736 8.82 2.07c.054.08.098.163.147.245.274-.343.51-.712.817-1.01C10.751.376 11.88-.171 13.26.05a1.943 1.943 0 0 1 1.658 1.776c.086.834-.475 1.78-1.257 2.118a.263.263 0 0 0-.147.128h2.826c.642 0 .923.285.926.914v.8a.77.77 0 0 1-.768.788H1.486a.746.746 0 0 1-.742-.746c-.011-.283 0-.57 0-.853 0-.617.287-.9.915-.904h2.135l.662-.001Zm9.129-1.415c-.8-.616-1.6-.507-2.4-.228-.8.278-1.371.732-1.396 1.057 1.12-.152 2.247-.198 3.329-.534.152-.045.28-.174.467-.295Zm-5.39.865.034-.097a.614.614 0 0 0-.082-.136 3.82 3.82 0 0 0-2.104-1.051c-.562-.08-1.116-.038-1.612.432.13.097.271.179.42.243.344.106.695.19 1.05.254.761.13 1.528.237 2.294.355Z"
        fill="#000"
    />
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.582 4.087H1.42c.074-.01.153-.017.239-.017h2.135l.662-.001a2.628 2.628 0 0 1-1.066-.97C2.561 1.687 3.39.074 5.03.013 6.67-.047 7.91.736 8.82 2.07c.037.055.07.112.103.169l.045.076c.085-.107.166-.216.247-.325.18-.24.358-.48.57-.684C10.75.374 11.879-.172 13.26.049a1.943 1.943 0 0 1 1.658 1.776c.085.834-.476 1.78-1.257 2.118a.263.263 0 0 0-.148.128H16.34c.088 0 .169.005.244.016Zm-5.397-1.661c.8-.28 1.6-.388 2.4.228-.063.041-.12.083-.172.122-.104.077-.194.143-.295.173-.794.247-1.613.337-2.434.427-.298.033-.597.066-.895.107.025-.325.597-.779 1.396-1.057Zm-2.956.996-.034.097-.707-.106c-.53-.08-1.06-.159-1.588-.25a8.932 8.932 0 0 1-1.049-.253 2.067 2.067 0 0 1-.42-.243c.496-.47 1.05-.512 1.612-.432a3.82 3.82 0 0 1 2.104 1.051c.033.042.06.087.082.136Z"
        fill="#D47E5F"
    />
  </SvgIcon>
);

export default GiftCodeIcon;
