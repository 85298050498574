import shallow from 'zustand/shallow';
import { usePointsOfSellQuery, } from 'Apollo/graphql';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import { PointOfSellStore, usePointOfSellStore, } from 'Modules/PointOfSell/store/PointOfSellStore';
import { useAuthUser, } from 'Tools/auth';
import PointOfSellTableHeader from './PointOfSellTableHeader';
import PointOfSellTableRow from './PointOfSellTableRow';
import { mapVariables, } from './utils';

const selectStoreData = (s: PointOfSellStore) => ({
  onDetail: s.openModalDetailPointOfSell,
  tablePointOfSell: s.tablePointOfSell,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,
});

const PointsOfSell = (): JSX.Element => {
  const authUser = useAuthUser();
  const {
    onDetail,
    tablePointOfSell,
    changeFilter,
    changeSort,
    changeData,
  } = usePointOfSellStore(selectStoreData, shallow);

  const { loading, error, data, } = usePointsOfSellQuery({
    variables: mapVariables(tablePointOfSell, authUser),
    fetchPolicy: 'cache-and-network',
  });

  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS ? 0 : 1;
  const showClusterIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS ? 0 : 1;
  const colSpan = 10 - showChainIDs - showClusterIDs;

  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={colSpan}
      rows={data?.pointsOfSell.data.length || 0}
      loading={loading}
      error={error}
      limit={tablePointOfSell.data.limit}
      sortField={tablePointOfSell.data.sortField}
      sortOrder={tablePointOfSell.data.sortOrder}
      offset={tablePointOfSell.data.offset}
      count={data?.pointsOfSell.pagination.count}
      filter={tablePointOfSell.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <PointOfSellTableHeader
        authUser={authUser}
      />
      <TableBody>
        {(data?.pointsOfSell.data || []).map((row) => (
          <PointOfSellTableRow
            key={row.id}
            authUser={authUser}
            row={row}
            onDetail={onDetail}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default PointsOfSell;
