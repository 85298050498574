import shallow from 'zustand/shallow';
import { ReactNode, useEffect, } from 'react';
import { FillTaskPhase, FillTaskStore, useFillTaskStore, } from 'Modules/TasksSeller/store/fillTaskStore';
import PhaseContent from './PhaseContent';
import PhaseAssessment from './PhaseAssessment';
import PhaseResult from './PhaseResult';

type PhaseComponents = {
  [key in FillTaskPhase]: () => ReactNode
};

const PHASE_COMPONENTS: PhaseComponents = {
  content: () => <PhaseContent />,
  assessment: () => <PhaseAssessment />,
  result: () => <PhaseResult />,
};

const selectStoreData = (s: FillTaskStore) => ({
  phase: s.phase,
});

const Phases = (): JSX.Element => {
  const { phase, } = useFillTaskStore(selectStoreData, shallow);

  useEffect(
    () => window.scrollTo({ top: 0, }),
    [ phase, ],
  );

  return <>{PHASE_COMPONENTS[phase]()}</>;
};

export default Phases;
