import { useTranslation, } from 'react-i18next';
import { Link as RouterLink, } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { ROUTES, } from 'Utils/constants';

const LoadingSkeleton = (): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  
  return (
    <>

      <Typography variant="body1" mb={4}>
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="35%" />
      </Typography>
      
      <Paper
        sx={{ p: 2, mb: 3, }}
      >
        <Grid container spacing={3}>
          {[ ...new Array(5), ].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={index} item xs={12}>
              <Typography variant="body0" mb={1}>
                <Skeleton variant="text" width="40%" />
              </Typography>
              <Typography variant="body1">
                <Skeleton variant="text" width="80%" />
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Button
        component={RouterLink}
        to={`/${ROUTES.admin}/${ROUTES.tasks}`}
        size="large"
        variant="outlined"
        color="inherit"
      >
        {t('detail.btnClose')}
      </Button>
    
    </>
  );
};

export default LoadingSkeleton;
