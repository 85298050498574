import create from 'zustand';
import { AssortmentCategoryOptionFragment, ChainFragment, OrderBy, PointOfSellOptionFragment, ReplacementDetailFragment, ReplacementSortableFields, ReplacementTableFragment, } from 'Apollo/graphql';
import { ChangeTableFilter, ChangeTableSort, ChangeTableData, } from 'Utils/types';
import { ReplacementStatusOption, } from 'Utils/options/useOptionsReplacementStatus';

export type TableReplacementFilter = {
  id: string,
  category: AssortmentCategoryOptionFragment | null,
  reason: string,
  status: ReplacementStatusOption | null,
  username: string,
  completor: string | null,
  pos: PointOfSellOptionFragment | null,
  chain: ChainFragment | null,
};

export type ReplacementStore = {
  modalCreateReplacement: {
    isOpen: boolean,
  },
  modalExportReplacement: {
    isOpen: boolean,
  },
  modalRemoveReplacement: {
    isOpen: boolean,
    replacement: ReplacementTableFragment | ReplacementDetailFragment | null,
  },
  modalDetailReplacement: {
    isOpen: boolean,
    replacement: ReplacementTableFragment | null,
  },
  modalEditReplacement: {
    isOpen: boolean,
    replacement: ReplacementTableFragment | null,
  },
  tableReplacement: {
    data: {
      sortField: ReplacementSortableFields | null,
      sortOrder: OrderBy | null,
      limit: number,
      offset: number,
    },
    filter: TableReplacementFilter,
  },
  openModalCreateReplacement: () => void,
  closeModalCreateReplacement: () => void,
  openModalExportReplacement: () => void,
  closeModalExportReplacement: () => void,
  openModalRemoveReplacement: (replacement: ReplacementTableFragment | ReplacementDetailFragment) => void,
  closeModalRemoveReplacement: () => void,
  openModalDetailReplacement: (replacement: ReplacementTableFragment) => void,
  closeModalDetailReplacement: () => void,
  openModalEditReplacement: (replacement: ReplacementTableFragment) => void,
  closeModalEditReplacement: () => void,
  changeTableData: ChangeTableData,
  changeTableFilter: ChangeTableFilter,
  changeTableSort: ChangeTableSort,
};

export const useReplacementStore = create<ReplacementStore>((set) => ({
  modalCreateReplacement: {
    isOpen: false,
  },
  modalExportReplacement: {
    isOpen: false,
  },
  modalRemoveReplacement: {
    isOpen: false,
    replacement: null,
  },
  modalDetailReplacement: {
    isOpen: false,
    replacement: null,
  },
  modalEditReplacement: {
    isOpen: false,
    replacement: null,
  },
  tableReplacement: {
    data: {
      sortField: null,
      sortOrder:  null,
      limit: 10,
      offset: 0,
    },
    filter: {  
      id: '',
      category: null,
      reason: '',
      status: null,
      username: '',
      completor: null,
      pos: null,
      chain: null,
    },
  },
  /*
  * Modal Create
  */
  openModalCreateReplacement: () => set({
    modalCreateReplacement: {
      isOpen: true,
    },
  }),
  closeModalCreateReplacement: () => set(() => ({
    modalCreateReplacement: {
      isOpen: false,
    },
  })),
  /*
  * Modal Export
  */
  openModalExportReplacement: () => set({
    modalExportReplacement: {
      isOpen: true,
    },
  }),
  closeModalExportReplacement: () => set(() => ({
    modalExportReplacement: {
      isOpen: false,
    },
  })),
  /*
  * Modal Remove
  */
  openModalRemoveReplacement: (replacement) => set({
    modalRemoveReplacement: {
      isOpen: true,
      replacement,
    },
  }),
  closeModalRemoveReplacement: () => set((state) => ({
    modalRemoveReplacement: {
      ...state.modalDetailReplacement,
      isOpen: false,
    },
  })),
  /*
  * Modal Detail
  */
  openModalDetailReplacement: (replacement) => set({
    modalDetailReplacement: {
      isOpen: true,
      replacement,
    },
  }),
  closeModalDetailReplacement: () => set((state) => ({
    modalDetailReplacement: {
      ...state.modalDetailReplacement,
      isOpen: false,
    },
  })),
    /*
  * Modal Edit
  */
  openModalEditReplacement: (replacement) => set({
    modalEditReplacement: {
      isOpen: true,
      replacement,
    },
  }),
  closeModalEditReplacement: () => set((state) => ({
    modalEditReplacement: {
      ...state.modalEditReplacement,
      isOpen: false,
    },
  })),
  /**
   * Replacement table
   */
   changeTableData: (name, value) => set((state) => ({
    tableReplacement: {
      ...state.tableReplacement,
      data: {
        ...state.tableReplacement.data,
        [name]: value,
      },
    },
   })),
   changeTableFilter: (name, value) => set((state) => ({
    tableReplacement: {
      ...state.tableReplacement,
      data: {
        ...state.tableReplacement.data,
        offset: 0,
      },
      filter: {
        ...state.tableReplacement.filter,
        [name]: value,
      },
    },
   })), 
   changeTableSort: (sortField, sortOrder) => set((state) => ({
    tableReplacement: {
      ...state.tableReplacement,
      data: {
        ...state.tableReplacement.data,
        sortField: sortField as ReplacementSortableFields,
        sortOrder,
      },
    },
   })),
}));
