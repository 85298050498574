import { yupResolver, } from '@hookform/resolvers/yup';
import { useCreateReplacementMutation, useCreateReplacementRegistrationMutation, } from 'Apollo/graphql';
import { updateReplacementCreate, } from 'Modules/ReplacementAdmin/utils/replacementsCache';
import { useEffect, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import shallow from 'zustand/shallow';
import { CreateReplacementStore, CreateTicketValues, useCreateReplacementStore, } from '../createReplacementStore';
import { createTickerValidationSchema, } from './utils';
import View from './View';

const selectStoreData = (s: CreateReplacementStore) => ({
  customer: s.customer,
  createTicketValues: s.createTicketValues,
  completeReplacement: s.completeReplacement,
  replacementRegistration: s.replacementRegistration,
  replacementEmail: s.replacementEmail,
  goBackToPhase: s.goBackToPhase,
});

interface Props {
  onClose: () => void;
}

const PhaseCreateTicket = ({ onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation();
  const { customer, createTicketValues, completeReplacement, replacementRegistration, goBackToPhase, replacementEmail, } = useCreateReplacementStore(
    selectStoreData,
    shallow,
  );

  const methods = useForm<CreateTicketValues>({
    defaultValues: createTicketValues,
    resolver: yupResolver(createTickerValidationSchema()),
    mode: 'onSubmit',
  });
  
  useEffect(() => {
    const subscription = methods.watch((value, { name, type, }) => {
      if (name ==='category') {
        methods.resetField('batchCode');
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch,]);

  const [createReplacement, createReplacementMutation,] = useCreateReplacementMutation({
    onCompleted: (response) => {
      completeReplacement(response.createReplacement);
    },
    onError: () => {
      // TODO: merge form errors
    },
    update: updateReplacementCreate,
  });
  const [createReplacementRegistration, createReplacementRegistrationMutation,] = useCreateReplacementRegistrationMutation({
    onError: () => {
      // TODO: merge form errors
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    let replacementRegistrationId;
    if (replacementRegistration) {
      replacementRegistrationId = await createReplacementRegistration({
        variables: {
          replacementRegistrationInput: {
            firstName: replacementRegistration.firstName,
            lastName: replacementRegistration.lastName,
            phoneNumber: replacementRegistration.phoneNumber,
            email: replacementEmail || replacementRegistration.email,
            phonePrefix: replacementRegistration.phonePrefix?.id || '',
          },
        },
      });
    }

    await createReplacement({
      variables: {
        replacementInput: {
          note: values.note,
          address: values.address || replacementRegistration.address,
          categoryId: values.category?.id || '',
          customerId: customer?.id || '',
          replacementRegistrationId: replacementRegistrationId?.data?.createReplacementRegistration.id || '',
          reason: values.reason?.requireTextInput
            ? `${t(values.reason.groupKey)} / ${values.reasonText}`
            : `${t(values.reason?.groupKey || values?.reason?.id || '')} / ${t(values.reason?.labelKey || values?.reason?.id || '')}`,
          replacedAssortments: values.products.map((p) => ({
            assortmentId: p.option.id,
            count: p.count,
            batchCode: values.batchCode || null,
          })),
        },
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View
          customer={customer}
          onClose={onClose}
          createReplacementMutation={createReplacementMutation}
          replacementRegistration={replacementRegistration}
          goBackToPhase={goBackToPhase}
        />
      </form>
    </FormProvider>
  );
};

export default PhaseCreateTicket;
