import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const FileHelpIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 40 38"
    fill="none"
    {...props}
    style={{ width: "unset", }}
  >
    <path
      d="M40 12.51c-.069 3.54-1.185 6.475-3.491 8.882a3994.724 3994.724 0 0 1-12.983 13.494c-.676.7-1.434.852-2.125.462a1.59 1.59 0 0 1-.764-.813 1.673 1.673 0 0 1-.06-1.133c.155-.448.408-.853.74-1.182 4.068-4.245 8.106-8.505 12.225-12.689 2.387-2.423 3.12-6.926 1.195-10.295-2.484-4.347-7.56-5.717-11.734-3.122-.763.475-1.412 1.188-2.052 1.847A6409.804 6409.804 0 0 0 4.8 24.69c-2.465 2.558-2.124 6.51.721 8.587 1.88 1.372 4.699 1.232 6.413-.457 2.227-2.194 4.376-4.487 6.55-6.729 2.503-2.592 5.003-5.189 7.5-7.792.211-.216.425-.433.616-.665.367-.421.563-.974.547-1.542a2.245 2.245 0 0 0-.631-1.507c-.85-.863-2.134-.974-2.985-.132-1.686 1.669-3.306 3.4-4.966 5.105-1.292 1.336-2.57 2.682-3.873 4.007-.673.69-1.525.792-2.189.338-.974-.691-1.06-1.9-.175-2.83 1.271-1.346 2.573-2.676 3.862-4.014 1.663-1.724 3.305-3.477 4.999-5.166a5.446 5.446 0 0 1 3.773-1.657 5.429 5.429 0 0 1 3.84 1.485c2.334 2.152 2.52 5.958.325 8.26a2071.79 2071.79 0 0 1-14.43 14.972c-3.084 3.167-7.805 3.379-11.342.652-3.24-2.5-4.323-7.41-2.413-11.213a9.546 9.546 0 0 1 1.6-2.281A3052.299 3052.299 0 0 1 19.75 4.242C24.126-.282 30.646-.773 35.543 3.147 38.52 5.52 39.878 8.809 40 12.51Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default FileHelpIcon;
