import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSnackbar, } from 'notistack';
import { CustomerRegistrationFragment, useEraseAllCustomerDataPermanentlyMutation, } from 'Apollo/graphql';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import { updateAllCustomerEraseAllData, } from 'Modules/RegistrationsAdmin/utils/registrationCache';
import View from './View';

interface Props {
  customer?: CustomerRegistrationFragment | null,
  onCloseErase: () => void,
  onCloseDetail: () => void,
};

const Logic = ({
  customer,
  onCloseErase,
  onCloseDetail,
}: Props): JSX.Element => {
  const preservedCustomer = usePreserveValue(customer);
  const { t, } = useTranslation('registrationsAdmin');
  const { enqueueSnackbar, } = useSnackbar();

  const [ removeMutation, removeMutationResult, ] = useEraseAllCustomerDataPermanentlyMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('eraseAllCustomerDataModal.notifRemovedTitle') as string,
        message: t('eraseAllCustomerDataModal.notifRemovedMessage', { customerName: `${preservedCustomer.firstName} ${preservedCustomer.lastName}`, }),
        variant: 'common',
      });
      onCloseErase();
      onCloseDetail();
    },
    update: updateAllCustomerEraseAllData,
  });

  const handleSubmit = useCallback(
    () => {
      removeMutation({
        variables: { id: preservedCustomer.id, },
      });
    },
    [ removeMutation, preservedCustomer.id, ],
  );

  return (
    <View
      customer={preservedCustomer}
      removeMutationResult={removeMutationResult}
      onClose={onCloseErase}
      onSubmit={handleSubmit}
    />
  );
};

export default Logic;
