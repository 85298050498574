import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import {  TableVoucherPurchaseFragment, useVoucherPurchaseQuery, } from 'Apollo/graphql';
import Logic from './Logic';

interface Props {
  voucherPurchase?: TableVoucherPurchaseFragment | null;
  onClose: () => void;
}

const FetchData = ({ voucherPurchase, onClose, }: Props): JSX.Element => {
  const preservedVoucherPurchase = usePreserveValue(voucherPurchase);

  const { loading, error, data, } = useVoucherPurchaseQuery({
    variables: { id: preservedVoucherPurchase?.id, },
    fetchPolicy: 'network-only',
  });

  if (loading) return <ModalDrawerBodyLoading onClose={onClose} />;
  if (error || !data?.voucherPurchase) return <ModalDrawerBodyError onClose={onClose} error={error} />;

  return <Logic voucherPurchase={data.voucherPurchase} onClose={onClose} />;
};

export default FetchData;
