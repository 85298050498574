import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { useVoucherStore, VoucherStore, } from 'Modules/Vouchers/store/VoucherStore';
import shallow from 'zustand/shallow';
import FetchData from './FetchData';


const selectStoreData = (s: VoucherStore) => ({
  selectModalData: s.modalDetailVoucher,
  selectClose: s.closeModalDetailVoucher,
  onDelete: s.openModalRemoveVoucher,
});

const ModalDetailVoucher = (): JSX.Element => {
  const {
    onDelete,
    selectModalData,
    selectClose,
  } = useVoucherStore(selectStoreData, shallow);
  const { isOpen, voucher, } = selectModalData;
  const onClose = selectClose;
  const { t, } = useTranslation('vouchersAdmin');

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!voucher}
    >
      <ModalDrawerTitle
        onClose={onClose}
      >
        {t('detailVoucherModal.title')}
      </ModalDrawerTitle>
      <FetchData
        voucher={voucher}
        onClose={onClose}
        onDelete={onDelete}
      />
    </ModalDrawer>
  );
};

export default ModalDetailVoucher;
