import { ReactNode, } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from 'Utils/svg/CloseIcon';

interface Props {
  children?: ReactNode,
  onClose?: () => void,
}

const ModalDrawerTitle = ({
  children,
  onClose,
}: Props): JSX.Element => {
  return (
    <Box
      pt={6}
      pl={6}
      pr={6}
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Typography
        variant="h1"
        component="h1"
        sx={{
          flexGrow: 1,
          maxWidth: "100%",
        }}
      >
        {children}
      </Typography>
      {onClose && (
        <IconButton
          onClick={onClose}
          color="inherit"
          sx={{
            marginTop: -0.5,
            '& .MuiSvgIcon-root': {
              fontSize: '1.125rem',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};


export default ModalDrawerTitle;
