import { useState, useCallback, } from 'react';
import { useApolloClient, ApolloError, MutationOptions, } from '@apollo/client';
import {
  CurrentUserQuery,
  CurrentUserDocument,
  LoginByQrCodeMutation,
  MutationLoginByQrCodeArgs,
  LoginByQrCodeDocument,
  Claim,
} from '../../../Apollo/graphql';
import { setAccessToken, } from '../store/authStore';
import { setOfflineRefreshToken, } from '../offlineRefreshTokenStore';

type UseAuthLogin = [
  (options: Omit<MutationOptions<LoginByQrCodeMutation, MutationLoginByQrCodeArgs>, 'mutation' | 'fetchPolicy'>
  ) => Promise<void>,
  {
    loading: boolean,
    error: ApolloError | Error | null
  },
];

export const useAuthLoginQrCode = (): UseAuthLogin => {
  const [ loading, setLoading, ] = useState<boolean>(true);
  const [ error, setError, ] = useState<ApolloError | Error | null>(null);
  const client = useApolloClient();

  const loginQrCode = useCallback(
    async (options = {}) => {
      try {
        setLoading(true);
        setError(null);

        const responseLoginQrCode = await client.mutate<LoginByQrCodeMutation, MutationLoginByQrCodeArgs>({
          ...options,
          mutation: LoginByQrCodeDocument,
          fetchPolicy: 'no-cache',
        });

        const {
          user,
          token,
        } = responseLoginQrCode.data?.loginByQrCode || {};

        if (
          !user
          || !token?.accessToken
          || (!user.claims.includes(Claim.AdminApp) && !user.claims.includes(Claim.SellerApp))
        ) throw new Error('invalid response: no user or token or no permission for admin / seller part of app');
        setAccessToken(token.accessToken);
        setOfflineRefreshToken(token.logoutRefreshToken);
        
        client.cache.writeQuery<CurrentUserQuery>({
          query: CurrentUserDocument,
          data: {
            currentUser: user,
          },
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        setAccessToken(null);
        setOfflineRefreshToken(null);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setError(err as any);
        // TOTO - remove current user from cache
        // apolloClient.cache.evict({
        //   id: 'ROOT_QUERY',
        //   fieldName: 'currentUser',
        // });
        // apolloClient.cache.gc();
      }
    }, [ client, ]
  );

  return [ loginQrCode, { loading, error, }, ];
};
