/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloCache, DefaultContext, MutationUpdaterFunction, Reference, } from '@apollo/client';
import { DeletePointsTransactionMutation, Exact, } from 'Apollo/graphql';

type UpdatePointsTransactionRemove = MutationUpdaterFunction<DeletePointsTransactionMutation, Exact<{
  id: string;
}>, DefaultContext, ApolloCache<any>> | undefined;

export const updatePointsTransactionRemove: UpdatePointsTransactionRemove = (cache, { data, }) => {
  try {
    if (data?.deletePointsTransaction) {
      cache.modify({
        fields: {
          pointsTransactions: (existing, { readField, }) => ({
            ...existing,
            data: existing.data.filter((itemRef: Reference) => readField('id', itemRef) !== data.deletePointsTransaction.id),
          }),
        },
      });
    }
  } catch {
    // ignore error
  }
};
