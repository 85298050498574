import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { FormError, FormSubmit, } from 'Components/Form';
import { StartCustomerVerificationMutationResult, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import { FormConfig, } from './types';
import CustomerRegistrationForm from './CustomerRegistrationForm';

interface Props {
  formConfig: FormConfig,
  replacementEmail: string,
  startVerificationResult: StartCustomerVerificationMutationResult,
  onClose: () => void,
}

const View = ({
  formConfig,
  replacementEmail,
  startVerificationResult,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>
        {t('createReplacementModal.phaseCustomerRegistration.title')}
      </ModalDrawerTitle>
      <ModalDrawerBody>
        <Typography
          variant="body1"
          sx={{ mb: 3, }}
        >
          {t('createReplacementModal.phaseCustomerRegistration.info')}
        </Typography>

        <Box
          mb={4}
        >
          <CustomerRegistrationForm
            formConfig={formConfig}
            registrationEmail={replacementEmail}
          />
        </Box>

        <FormError
          mb={2}
        />

        <ErrorAlert
          mb={2}
          error={startVerificationResult.error}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} lg="auto">
            <FormSubmit
              color="primary"
              variant="contained"
              size="large"
              sx={{ width: { xs: '100%', lg: 'unset', }, }}
            >
              {t('createReplacementModal.phaseCustomerRegistration.submit')}
            </FormSubmit>
          </Grid>
        </Grid>
      </ModalDrawerBody>
    </>
  );
};

export default View;
