import { useFormContext, useController, } from 'react-hook-form';
import InputScaleClassic, { InputScaleClassicProps, } from 'Components/Inputs/InputScaleClassic';
import { useFormInputStatus, } from '../utils/useFormInputStatus';
import { InputFormErrorMessage, } from '../types';

interface Props extends Omit<InputScaleClassicProps, 'value' | 'onChange' | 'error'> {
  name: string,
}

const FormInputScale = ({
  name,
  helperText,
  ...rest
}: Props): JSX.Element => {
  const { control, } = useFormContext();
  const {
    field: { value, onBlur: onBlurField, onChange: onChangeField, },
    fieldState: { error, },
  } = useController({
    name,
    control,
  });

  const onChange: InputScaleClassicProps['onChange'] = (newValue) => {
    onChangeField(newValue);
    onBlurField();
  };

  const inputStatus = useFormInputStatus(
    error?.message as InputFormErrorMessage | undefined,
    helperText,
  );

  return (
    <InputScaleClassic
      {...rest}
      value={value}
      onChange={onChange}
      error={inputStatus.isError}
      helperText={inputStatus.text}
    />
  );
};

export default FormInputScale;
