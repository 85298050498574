import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { UserStore, useUserStore, } from 'Modules/Users/store/UserStore';
import FetchUserData from './FetchUserData';

const selectModalData = (s: UserStore) => s.modalExportUsers;
const selectClose = (s: UserStore) => s.closeModalExportUsers;

const ModalExportUsers = (): JSX.Element => {
  const { t, } = useTranslation('users');
  const { isOpen, } = useUserStore(selectModalData);
  const onClose = useUserStore(selectClose);

  return (
    <ModalDrawer onClose={onClose} open={isOpen}>
      <ModalDrawerTitle onClose={onClose}>{t(`common:defaultExport.title`, {name: t('users.title'),})}</ModalDrawerTitle>
      <FetchUserData onClose={onClose} />
    </ModalDrawer>
  );
};

export default ModalExportUsers;
