import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import { useTranslation, } from 'react-i18next';
import { GeneralTaskFragment, } from 'Apollo/graphql';
import View from './View';

interface Props {
  task: GeneralTaskFragment | null,
  onClose: () => void;
}

const FetchData = ({
  task,
  onClose,
}: Props): JSX.Element => {
  const preservedTask = usePreserveValue(task);
  const { t, } = useTranslation('tasksAdmin');



  return (
    <>
      <ModalDrawerTitle onClose={onClose}>{t('exportTask.title', { name: preservedTask.name, })}</ModalDrawerTitle>

      <View
        onClose={onClose}
      />
    </>
  );
};

export default FetchData;
