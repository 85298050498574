import { AssessmentMethodEnum, CreateTaskMutationVariables, RenewalEnum, TaskTypeEnum, TypeOfCompletionEnum, } from 'Apollo/graphql';
import { MethodValues, ParametersValues, TypeValues, } from 'Modules/TasksAdmin/store/createTaskStore';
import { AssessmentValues, } from '../PhaseAssessment/types';
import { ContentValues, } from '../PhaseContent/types';
import { mapAssessmentVariables, } from './mapAssessmentVariables';
import { mapContentVariables, } from './mapContentVariables';
import { MapAssessmentOptions, } from './types';

export const mapCreateTaskVariables = (
  typeValues: TypeValues,
  methodValues: MethodValues,
  parametersValues: ParametersValues,
  contentValues: ContentValues | null | undefined,
  assessmentValues: AssessmentValues | null | undefined,
  options: MapAssessmentOptions,
): CreateTaskMutationVariables => {
  return {
    taskInput: {
      // TypeValues
      type: typeValues.type?.id as TaskTypeEnum,
      
      // MethodValues
      assessmentMethod: methodValues.method?.id as AssessmentMethodEnum,
      
      // ParametersValues
      name: parametersValues.name,
      description: parametersValues.description,
      attemptLimit: parametersValues.attemptLimit || 0,
      chainIds: parametersValues.chains?.map((ch) => ch.id) || [],
      pointsForCompletion: parametersValues.pointsForCompletion || 0,
      pointsLimit: parametersValues.pointsLimit || 0,
      renewal: parametersValues.renewal?.id || RenewalEnum.None,
      typeOfCompletion: parametersValues.typeOfCompletion?.id as TypeOfCompletionEnum,
      validFrom: parametersValues.validFrom,
      validTo: parametersValues.validTo,

      // ContentValues
      content: mapContentVariables(contentValues),

      // AssessmentValues
      assessment: mapAssessmentVariables(assessmentValues, options),
      notificationsVisible: parametersValues.notificationsVisible,
    },
  };
};
