import { useEffect, useRef, useCallback, } from 'react';


type UseDebounceRet = {
  startTimeout: () => void,
  endTimeout: () => void,
};


export const useDebounce = (
  cb: () => void,
  interval: number = 700,
): UseDebounceRet => {
  const timeoutId = useRef<number | null>(null);


  // clear
  const handleClearTimeout = () => {
    if (timeoutId?.current) {
      window.clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
  };


  // done
  const handleDoneTimeout = useCallback(
    () => {
      handleClearTimeout();
      if (cb) cb();
    },
    [ cb, ],
  );


  // start
  const handleStartTimeout = useCallback(
    () => {
      handleClearTimeout();
      timeoutId.current = window.setTimeout(handleDoneTimeout, interval);
    },
    [ interval, handleDoneTimeout, ],
  );


  // unmount
  useEffect(() => handleClearTimeout, []);


  return {
    startTimeout: handleStartTimeout,
    endTimeout: handleClearTimeout,
  };
};
