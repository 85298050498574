import { yupResolver, } from '@hookform/resolvers/yup/dist/yup';
import { useCheckDeviceCodeMutation, } from 'Apollo/graphql';
import LoadingBox from 'Components/LoadingBox';
import DeviceCodeForm from 'Modules/RegistrationSeller/Components/DeviceCodeForm';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { useCallback, useEffect, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { throttle, } from 'lodash';
import { formValidationSchema, } from '../../../Components/DeviceCodeFormConfig';

const selectStoreData = (s: RegistrationStore) => ({
  deviceCodeRequiredList: s.deviceCodeRequiredList,
  completeDeviceCodesPhase: s.completeDeviceCodesPhase,
  deviceCodeIndex: s.deviceCodeIndex,
  inputDeviceCode: s.inputDeviceCode,
  goBackInDeviceCodes: s.goBackInDeviceCodes,
  goBackToPhase: s.goBackToPhase,
  deviceCodeRequiredListPromotions: s.deviceCodeRequiredPromotionsList,
});

const PhaseDeviceCodes = (): JSX.Element => {
  const {
    deviceCodeRequiredList,
    completeDeviceCodesPhase,
    deviceCodeIndex,
    inputDeviceCode,
    goBackToPhase,
    goBackInDeviceCodes,
    deviceCodeRequiredListPromotions,
  } = useRegistrationStore(selectStoreData, shallow);
  const basicDeviceCodeCount = deviceCodeRequiredListPromotions ? deviceCodeRequiredListPromotions.length : 0;
  const [checkDeviceCodeMutation, checkDeviceCodeMutationResult,] = useCheckDeviceCodeMutation();
  const onlyDeviceCodes = deviceCodeRequiredList.map((item) => item.deviceCode).filter((code, index) => index !== deviceCodeIndex);

  const methods = useForm({
    defaultValues: { deviceCode: deviceCodeRequiredList[deviceCodeIndex]?.deviceCode, },
    resolver: yupResolver(formValidationSchema(checkDeviceCodeMutationResult.data, onlyDeviceCodes)),
    mode: 'onSubmit',
  });
  useEffect(() => {
    const throttledCheckDeviceCode = throttle((deviceCode: string) => {
      checkDeviceCodeMutation({ variables: { deviceCode, includeShortRegistration: true, }, });
    }, 1000);

    const subscription = methods.watch((value, { name, type, }) => {
      if (value.deviceCode) throttledCheckDeviceCode(value.deviceCode);
    });

    return () => {
      throttledCheckDeviceCode.cancel();
      subscription.unsubscribe();
    };
  }, [checkDeviceCodeMutation, methods, methods.watch,]);

  const handleGoBack = useCallback(() => {
    if (deviceCodeIndex === 0) goBackToPhase('purchase');
    goBackInDeviceCodes();
    methods.resetField('deviceCode');
  }, [goBackInDeviceCodes, goBackToPhase, methods, deviceCodeIndex,]);

  const handleSubmit = methods.handleSubmit((values) => {
    if (deviceCodeIndex === deviceCodeRequiredList.length - 1) {
      completeDeviceCodesPhase();
    }
    inputDeviceCode(deviceCodeIndex, values.deviceCode);
    methods.resetField('deviceCode');
  });

  useEffect(() => {
    methods.setValue('deviceCode', deviceCodeRequiredList[deviceCodeIndex]?.deviceCode);
  }, [deviceCodeIndex,]);

  useEffect(() => {
    if (deviceCodeRequiredList.length < 1) {
      completeDeviceCodesPhase();
    }
  });
  if (deviceCodeRequiredList.length < 1) {
    return <LoadingBox />;
  }
  const assortmentName = deviceCodeRequiredList[deviceCodeIndex]?.name as string;
  const assortmentImageUrl = deviceCodeRequiredList[deviceCodeIndex]?.publicUrl as string;
  const currentDeviceCodeIndex = deviceCodeIndex + 1;
  const totalDeviceCodeCount = deviceCodeRequiredList.length + basicDeviceCodeCount;
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <DeviceCodeForm
          assortmentName={assortmentName}
          assortmentImageUrl={assortmentImageUrl}
          currentDeviceCodeIndex={currentDeviceCodeIndex}
          totalDeviceCodeCount={totalDeviceCodeCount}
          handleGoBack={handleGoBack}
        />
      </form>
    </FormProvider>
  );
};

export default PhaseDeviceCodes;
