import { ExportTypesEnum } from 'Apollo/graphql';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { areOptionsEqualById } from 'Utils/helpers';

export type ExportTypeOption = {
  id: ExportTypesEnum;
  labelKey: string;
  value: boolean;
};

export const EXPORT_TYPE_OPTIONS: ExportTypeOption[] = [
  {
    id: ExportTypesEnum.Xlsx,
    labelKey: 'common:export.form.xlsx',
    value: true,
  },
  {
    id: ExportTypesEnum.Csv,
    labelKey: 'common:export.form.csv',
    value: false,
  },
];

type GetOptionLabel = (o: ExportTypeOption) => string;
type GetOptionKey = (o: ExportTypeOption) => ExportTypesEnum;

type UseExportTypeOptions = () => {
  options: ExportTypeOption[];
  getOptionLabel: GetOptionLabel;
  getOptionKey: GetOptionKey;
  isOptionEqualToValue: typeof areOptionsEqualById<ExportTypeOption>;
};

export const useOptionsExportTypes: UseExportTypeOptions = () => {
  const { t } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>((o) => t(o.labelKey), [t]);

  return {
    options: EXPORT_TYPE_OPTIONS,
    getOptionLabel,
    getOptionKey: (option: ExportTypeOption) => option.id,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
