import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const MinusIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path stroke="currentColor" strokeWidth={2} d="M1.75 12h20.5" />
  </SvgIcon>
);

export default MinusIcon;
