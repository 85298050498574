import { ReactNode, ChangeEvent, } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, } from 'Components/Checkbox';
import FormHelperTextClassic from './components/FormHelperTextClassic';

type onChange = (newValue: boolean) => void; 

export interface InputCheckboxProps {
  value: boolean,
  onChange: onChange,
  label: ReactNode,
  required?: boolean,
  disabled?: boolean,
  error?: boolean,
  helperText?: ReactNode,
};

const InputCheckbox = ({
  value,
  onChange,
  label,
  required,
  disabled,
  error,
  helperText,
}: InputCheckboxProps): JSX.Element => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange(checked);
  };

  return (
    <>
      <FormControlLabel
        disabled={disabled}
        value={value}
        label={required ? <>{label}*</> : label}
        sx={{ alignItems: 'flex-start', }}
        control={
          <Checkbox
            sx={{
              marginTop: -1,
            }}
            checked={value}
            onChange={handleChange}
          />
        }
      />
      {helperText && <FormHelperTextClassic error={error}>{helperText}</FormHelperTextClassic>}
    </>
  );
};

export default InputCheckbox;
