import { ChangeEventHandler, ReactNode, } from 'react';
import FormControl from '@mui/material/FormControl';
import { AnyObject, } from 'Utils/types';
import InputLabelClassic from '../components/InputLabelClassic';
import FormHelperTextClassic from '../components/FormHelperTextClassic';
import { GetOptionKey, GetOptionLabel, OnChange, } from './types';
import OptionList from './OptionList';

export interface InputGroupRadioClassicProps<T extends AnyObject,> {
  id: string,
  label: ReactNode,
  value: T | null,
  options: T[],
  onChange: OnChange<T>,
  getOptionLabel: GetOptionLabel<T>,
  getOptionKey: GetOptionKey<T>,
  required?: boolean,
  helperText?: ReactNode,
  error?: boolean,
  loading?:boolean,
}

const InputGroupRadioClassic = <T extends AnyObject,>({
  id,
  label,
  value,
  options,
  onChange,
  getOptionLabel,
  getOptionKey,
  required,
  helperText,
  error,
  loading,
}: InputGroupRadioClassicProps<T>): JSX.Element => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(options.find((o) => getOptionKey(o) === e.target.value) || null);
  };

  const valueKey = value ? getOptionKey(value) : null;
  
  return (
    <FormControl>
      <InputLabelClassic
        id={id}
        required={required}
      >
        {label}
      </InputLabelClassic>
     
      <OptionList
        id={id}
        onChange={handleChange}
        loading={loading}
        valueKey={valueKey}
        options={options}
        getOptionKey={getOptionKey}
        getOptionLabel={getOptionLabel}
      />

      {(helperText) && (
        <FormHelperTextClassic
          error={error}
        >
          {helperText}
        </FormHelperTextClassic>
      )}
    </FormControl>
  );
};

export default InputGroupRadioClassic;
