import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled, } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { CurrentUserQuery, } from 'Apollo/graphql';
import IconButtonSquare from 'Components/IconButtonSquare';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import LinkButton from 'Components/LinkButton';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { useAuthUser, } from 'Tools/auth';
import { ROUTES, } from 'Utils/constants';
import KeyboardArrowUpIcon from 'Utils/svg/KeyboardArrowUpIcon';

type Props = {
  data: CurrentUserQuery;
};

const TransactionButtonBox = styled(Box)(({ theme, }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  padding: theme.spacing(1.5, 2),
}));

const View = ({ data, }: Props): JSX.Element => {
  const { t, } = useTranslation('myProfile');
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const handleToEdit = () => navigate(`/${ROUTES.myProfile}/${ROUTES.editMyProfile}`);
  const handleToEditTaxInfo = () => navigate(`/${ROUTES.myProfile}/${ROUTES.editMyTaxInfo}`);
  const handleOpenRemove = () => navigate(`/${ROUTES.myProfile}/${ROUTES.deleteMyProfile}`);  
  const { currentUser, } = data;
  return (
    <>
      <Typography variant="h1" mb={1.5}>
        {t('title')}
      </Typography>
      <Typography variant="inherit" mb={6}>
        {t('description')}
      </Typography>
      <Paper sx={{ marginBottom: 4, }} elevation={1}>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('firstName')}>{currentUser.firstName}</NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('lastName')}>{currentUser.lastName}</NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('email')}>{currentUser.email}</NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('phoneNumber')}>{currentUser.phoneNumberWithPrefix}</NotEditableTextClassic>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ marginBottom: 6, }} elevation={1}>
        <TransactionButtonBox>
          <Typography variant="h3" color="primary">
            {t('btnEditProfile')}
          </Typography>
          <IconButtonSquare variant="outlined" onClick={handleToEdit}>
            <KeyboardArrowUpIcon sx={{transform: "rotate(90deg)",}} />
          </IconButtonSquare>
        </TransactionButtonBox>
      </Paper>
      {authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_TAX_INFORMATION && (
        <Paper sx={{ marginBottom: 6, }} elevation={1}>
          <TransactionButtonBox>
            <Typography variant="h3" color="primary">
              {t('btnEditTaxInformation')}
            </Typography>
            <IconButtonSquare variant="outlined" onClick={handleToEditTaxInfo}>
              <KeyboardArrowUpIcon sx={{transform: "rotate(90deg)",}} />
            </IconButtonSquare>
          </TransactionButtonBox>
        </Paper>
      )}
      <Divider sx={{ marginBottom: 4, }} />
      <Box mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('username')}>{currentUser.username}</NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('posId')}>{currentUser.pointOfSell?.id}</NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('posName')}>{currentUser.pointOfSell?.name}</NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('chainId')}>{currentUser.chainId}</NotEditableTextClassic>
          </Grid>
        </Grid>
      </Box>
      <LinkButton type="button" variant="body1" onClick={handleOpenRemove} color="secondary" disabled={false}>
        {t('btnDeleteProfile')}
      </LinkButton>
    </>
  );
};

export default View;
