import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { GenderTitle, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type GenderTitleOption = {
  id: string,
  labelKey: string,
};

export const GENDER_TITLE_OPTIONS: GenderTitleOption[] = [
  {
    id: GenderTitle.Mr,
    labelKey: 'common:options.genderTitle.mr',
  },
  {
    id: GenderTitle.Mrs,
    labelKey: 'common:options.genderTitle.mrs',
  },
];

type GetOptionLabel = (o: GenderTitleOption) => string;
type GetOptionKey = (o: GenderTitleOption) => string;

type UseOptionsGenderTitle = () => {
  options: GenderTitleOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<GenderTitleOption>, 
};

export const useOptionsGenderTitle: UseOptionsGenderTitle = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: GENDER_TITLE_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
