import Divider from '@mui/material/Divider';
import { AssortmentOptionFragment, } from 'Apollo/graphql';
import { InputProductCountMultipleValueOption, } from 'Components/Inputs/InputProductCountMultiple/types';
import ProductCard from 'Components/ProductCard';
import { RegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { useMemo, } from 'react';
import { purchasedPromotionsToProductView, } from './utils';

interface Props {
  purchase: RegistrationStore['purchase'],
  promotionCategoryPurchaseList: RegistrationStore['promotionCategoryPurchaseList']
}

const PurchasedProducts = ({ purchase: { assortmentCategories, }, promotionCategoryPurchaseList, }: Props): JSX.Element => {
  const assortments = useMemo(() => {
    const ret: InputProductCountMultipleValueOption<AssortmentOptionFragment>[] = [];
    const categoryKeys = Object.keys(assortmentCategories);

    for (let i = 0; i < categoryKeys.length; i++) {
      const items = assortmentCategories[categoryKeys[i]];

      for (let j = 0; j < items.length; j++) {
        const assortmentValue = items[j];
        if (assortmentValue.count > 0) ret.push(assortmentValue);
      }
    }

    return ret;
  }, [assortmentCategories,]);

  const promotions = useMemo(() => purchasedPromotionsToProductView(promotionCategoryPurchaseList), [promotionCategoryPurchaseList,]);

  return (
    <>
      {promotions.map((promotionData, index) => (
        <>
          <Divider sx={{ mt: '1rem', mb: '1rem', }} />
          <ProductCard
            // eslint-disable-next-line react/no-array-index-key
            key={`${promotionData.promotion.name}${index}`}
            title={promotionData.promotion.name}
            imageSrc={promotionData.promotion.image?.publicUrl}
            count={1}
          />
          {promotionData.assortmentsWithDeviceCodes?.map((assortment) => (
            <ProductCard
              color="#ececec"
              key={assortment.deviceCode}
              title={assortment.option.name}
              imageSrc={assortment.option.image?.publicUrl}
              count={1}
              deviceCode={assortment.deviceCode}
            />
          ))}
          {promotionData.assortmentsWithoutDeviceCodes?.map((assortment) => (
            <ProductCard
              color="#ececec"
              key={assortment.assortmentId}
              title={assortment.option.name}
              imageSrc={assortment.option.image?.publicUrl}
              count={assortment.count}
            />
          ))}
        </>
      ))}
      {assortments.map((o) => (
        <ProductCard key={o.option.id} title={o.option.name} imageSrc={o.option.image?.publicUrl || undefined} count={o.count} />
      ))}
    </>
  );
};

export default PurchasedProducts;
