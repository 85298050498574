import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const ChristmasPrizeIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon viewBox="0 0 61 56" fill="none" {...props} style={{ width: 'unset', }}>
    <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8811_94109)">
        <path d="M30.4834 4.36719L52.7685 17.2757V43.0927L30.4834 56.0012L8.19824 43.0927V17.2757L30.4834 4.36719Z" fill="#B47555" />
        <mask
          id="mask0_8811_94109"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="8"
          y="4"
          width="45"
          height="52"
        >
          <path d="M30.4834 4.36719L52.7685 17.2757V43.0927L30.4834 56.0012L8.19824 43.0927V17.2757L30.4834 4.36719Z" fill="#D8B70B" />
        </mask>
        <g mask="url(#mask0_8811_94109)">
          <rect x="16.1289" y="30.6953" width="6.74955" height="21.4298" fill="#DBC2B9" />
        </g>
        <path d="M30.4717 4.36719L52.7568 17.2757V43.0927L30.4717 56.0012V4.36719Z" fill="#A66B4D" />
        <mask
          id="mask1_8811_94109"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="30"
          y="4"
          width="23"
          height="52"
        >
          <path d="M30.4717 4.36719L52.7568 17.2757V43.0927L30.4717 56.0012V4.36719Z" fill="#C6A80E" />
        </mask>
        <g mask="url(#mask1_8811_94109)">
          <rect x="38.7402" y="30.6953" width="6.74955" height="21.0923" fill="#DBC2B9" />
        </g>
        <rect width="26.1118" height="26.1118" transform="matrix(0.865031 -0.501718 0.865031 0.501718 7.91309 17.0938)" fill="#D79574" />
        <path d="M16.4663 12.1406L19.822 10.1943L23.1778 8.24798L45.7653 21.3488L39.0539 25.2414L16.4663 12.1406Z" fill="#DBC2B9" />
        <path d="M45.0903 12.4766L41.7346 10.5302L38.3789 8.58392L15.7913 21.6847L22.5028 25.5774L45.0903 12.4766Z" fill="#DBC2B9" />
        <g filter="url(#filter0_d_8811_94109)">
          <path d="M7.91236 26.349L7.91237 17.0885L30.4999 30.1893L30.4999 39.4498L7.91236 26.349Z" fill="#B47555" />
          <mask
            id="mask2_8811_94109"
            style={{
              maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x="7"
            y="17"
            width="24"
            height="23"
          >
            <path d="M7.91236 26.349L7.91237 17.0885L30.4999 30.1893L30.4999 39.4498L7.91236 26.349Z" fill="#729119" />
          </mask>
          <g mask="url(#mask2_8811_94109)">
            <rect x="15.791" y="20.7266" width="6.74955" height="16.0302" fill="#DBC2B9" />
          </g>
          <path d="M53.0881 26.349L53.0881 17.0885L30.5006 30.1893L30.5006 39.4498L53.0881 26.349Z" fill="#A66B4D" />
          <mask
            id="mask3_8811_94109"
            style={{
              maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x="30"
            y="17"
            width="24"
            height="23"
          >
            <path d="M53.0876 26.349L53.0876 17.0885L30.5001 30.1893L30.5001 39.4498L53.0876 26.349Z" fill="#C6A80E" />
          </mask>
          <g mask="url(#mask3_8811_94109)">
            <rect x="39.0767" y="20.7266" width="6.74955" height="16.0302" fill="#DBC2B9" />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_8811_94109"
          x="3.9126"
          y="17.0859"
          width="53.1753"
          height="30.3672"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8811_94109" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8811_94109" result="shape" />
        </filter>
        <clipPath id="clip0_8811_94109">
          <rect width="60" height="60" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default ChristmasPrizeIcon;
