import { useMemo, } from 'react';
import { useTranslation, TFunction, } from 'react-i18next';
import { ChipProps, } from '@mui/material/Chip';
import { ReplacementStatus, } from 'Apollo/graphql';

type ReplacementStatusValue = {
  label: string,
  color: ChipProps['color'],
};

type Statuses = {
  [key in ReplacementStatus | 'default']: {
    labelKey: string,
    color: ChipProps['color'],
  } 
};

const STATUSES: Statuses = {
  [ReplacementStatus.Completed]: {
    labelKey: 'common:status.completed',
    color: 'success',
  },
  [ReplacementStatus.Expired]: {
    labelKey: 'common:status.expired',
    color: 'error',
  },
  [ReplacementStatus.InProgress]: {
    labelKey: 'common:status.inProgress',
    color: 'warning',
  },
  default: {
    labelKey: 'common:status.unknown',
    color: 'default',
  }, 
};

const getStatus = (type: keyof Statuses, t: TFunction): ReplacementStatusValue => ({
  color: STATUSES[type].color,
  label: t(STATUSES[type].labelKey),
});

export const useReplacementStatus = (status: ReplacementStatus | null): ReplacementStatusValue => {
  const { t, } = useTranslation();
  const ret = useMemo<ReplacementStatusValue>(
    () => {
      if (status && Object.prototype.hasOwnProperty.call(STATUSES, status)) {
        return getStatus(status, t);
      }
      return getStatus('default', t);
    },
    [ status, t, ],
  );

  return ret;
};

