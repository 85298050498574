/* eslint-disable @typescript-eslint/no-explicit-any */
import { AssortmentCategoryWithAssortmentOptionFragment, PromotionWithLimitOptionFragment, } from 'Apollo/graphql';
import { InputFormErrorMessage, } from 'Components/Form';
import { InputProductCountMultipleValueOption, } from 'Components/Inputs/InputProductCountMultiple/types';
import { AssortmentCategories, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { useMemo, } from 'react';
import { AnyObject, } from 'Utils/types';
import * as yup from 'yup';

type RetUsePromotionPurchaseForm = {
  initValues: {
    values: {
      assortmentCategories: AssortmentCategories;
    };
  };
  validationSchema?: any;
  structure: { assortmentCategories: AssortmentCategoryWithAssortmentOptionFragment[] };
};

const getProductCount = (options: InputProductCountMultipleValueOption<AnyObject>[]): number => options?.reduce((prev, cur) => prev + cur.count, 0);
type FormType = {
  values: { assortmentCategories: AssortmentCategories };
};
const TEST_ERROR_SOME_PRODUCTS = (promotion: PromotionWithLimitOptionFragment) => ({
  message: (): InputFormErrorMessage => ({ key: 'registrationSeller:phasePurchase.errorQuantityNotMatching', }),
  test: (values: any) => {
    const assortmentCategories = values.values.assortmentCategories as any;
    if (!assortmentCategories) return true;
    const categoriesKeys = Object.keys(assortmentCategories);
    let count = 0;
    for (let i = 0; i < categoriesKeys.length; i++) {
      const promotionCategoryCount =
        promotion.promotionAssortmentCategoriesForPurchases.find((category) => category.assortmentCategoryId === categoriesKeys[i])?.count || 0;
      const category = assortmentCategories[categoriesKeys[i]];
      const productCount = getProductCount(category);
      count += productCount === promotionCategoryCount ? 0 : 1;
    }
    return count === 0;
  },
});

export const usePromotionPurchaseForm = (
  data: AssortmentCategories,
  assortmentCategoryOptions: AssortmentCategoryWithAssortmentOptionFragment[],
  promotion: PromotionWithLimitOptionFragment,
): RetUsePromotionPurchaseForm => {
  const ret = useMemo<RetUsePromotionPurchaseForm>(
    () => {
      const validationSchema = yup
        .object()
        .shape({
          values: yup.object(),
        })
        .test(TEST_ERROR_SOME_PRODUCTS(promotion));

      return {
        initValues: {
          values: { assortmentCategories: data, },
        },
        validationSchema,
        structure: { assortmentCategories: assortmentCategoryOptions, },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return ret;
};
