import Grid from '@mui/material/Grid';
import { FormInputGroupRadio } from 'Components/Form';
import { useTranslation } from 'react-i18next';
import { getOptionFieldId } from 'Utils/helpers';

export enum QrCodeType {
  'brandAmbassador' = 'brandAmbassador',
  'pos' = 'pos',
  'user' = 'user',
}

export type QrCodeTypeOption = {
  id: QrCodeType;
  labelKey: string;
  value: QrCodeType;
};

const QrCodeTypeForm = (): JSX.Element => {
  const { t } = useTranslation('registrationsAdmin');

  const options = [
    {
      id: QrCodeType.brandAmbassador,
      labelKey: t('qrModal.type.brandAmbassador'),
      value: QrCodeType.brandAmbassador,
    },
    {
      id: QrCodeType.pos,
      labelKey: t('qrModal.type.pos'),
      value: QrCodeType.pos,
    },
  ];

  if ((import.meta.env.VITE_APP_MARKET === 'CZ')) {
    options.push({
      id: QrCodeType.user,
      labelKey: t('qrModal.type.user'),
      value: QrCodeType.user,
    });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormInputGroupRadio<QrCodeTypeOption>
          name="type"
          label={t('qrModal.inputLabel.type')}
          options={options}
          getOptionKey={getOptionFieldId}
          getOptionLabel={(o) => o.labelKey}
          required
        />
      </Grid>
    </Grid>
  );
};

export default QrCodeTypeForm;
