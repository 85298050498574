import { INPUT_FORM_ERRORS } from 'Components/Form';
import { PointsTransactionOperationOption } from 'Utils/options/useOptionsOperation';
import { PointsTransactionReasonOption } from 'Utils/options/usePointsTransactionReason';
import * as yup from 'yup';

export type CreatePointsTransactionForm = {
  amount: number;
  reason: string;
  operation: PointsTransactionOperationOption | null;
  username: string;
};
export const pointsTransactionCreateFormValidationSchema = yup.object().shape({
  amount: yup.number().required(INPUT_FORM_ERRORS.REQUIRED).min(0, INPUT_FORM_ERRORS.INT_MIN).nullable(),
  operation: yup.object().required(INPUT_FORM_ERRORS.REQUIRED).nullable(),
  reason: yup.string().required(INPUT_FORM_ERRORS.REQUIRED),
  username: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX).nullable(),
});
