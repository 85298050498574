import { useCallback, } from 'react';
import { useNavigate, } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useForm, FormProvider, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useCreateTaskMutation, } from 'Apollo/graphql';
import { ROUTES, } from 'Utils/constants';
import { CreateTaskStore, useCreateTaskStore, } from 'Modules/TasksAdmin/store/createTaskStore';
import { enqueueSnackbar, } from 'notistack';
import { useTranslation, } from 'react-i18next';
import { mapCreateTaskVariables, } from '../utils/mapCreateTaskVariables';
import Layout from './Layout';
import { contentValidationSchema, useInitValues, } from './utils';
import { AssessmentValues, } from './types';

const selectStoreData = (s: CreateTaskStore) => ({
  skipPhaseContent: s.skipPhaseContent,
  assessmentConfig: s.assessmentConfig,
  typeValues: s.typeValues,
  methodValues: s.methodValues,
  assessmentValues: s.assessmentValues,
  goBackToPhase: s.goBackToPhase,
  completeAssessmentPhase: s.completeAssessmentPhase,
  saveAssessmentValues: s.saveAssessmentValues,
});

const PhaseAssessment = (): JSX.Element => {
  const { t, } = useTranslation("tasksAdmin");
  const navigate = useNavigate();
  const {
    skipPhaseContent,
    assessmentConfig,
    typeValues,
    methodValues,
    assessmentValues,
    goBackToPhase,
    completeAssessmentPhase,
    saveAssessmentValues,
  } = useCreateTaskStore(selectStoreData, shallow);

  const initValues = useInitValues(assessmentValues);
  const methods = useForm<AssessmentValues>({
    defaultValues: initValues,
    resolver: yupResolver(contentValidationSchema),
    context: { ...assessmentConfig, },
  });

  const [ createTaskMutation, createTaskMutationResult, ] = useCreateTaskMutation(
    {
      onCompleted: (response) => {
        enqueueSnackbar({
          title: t('creation.notifCreatedTitle') as string,
          message: t('creation.notifCreatedMessage', { taskId: response.createTask.id, }),
          variant: 'common',
        });
      },
      onError: () => {
        // TODO: merge form errors
      },
    }
  );

  const handleBack = useCallback(
    () => {
      saveAssessmentValues(methods.getValues());
      goBackToPhase(skipPhaseContent ? 'parameters' : 'content');
    },
    [ skipPhaseContent, goBackToPhase, saveAssessmentValues, ],
  );

  const handleSubmit = methods.handleSubmit(async (values) => {
    saveAssessmentValues(values);
    await createTaskMutation({
      variables: mapCreateTaskVariables(
        typeValues,
        methodValues,
        useCreateTaskStore.getState().parametersValues,
        useCreateTaskStore.getState().contentValues,
        values,
        { requiresCorrectAnswer: assessmentConfig.enableCorrectAnswer, }
      ),
      onCompleted: (response) => {
        completeAssessmentPhase(values);
        navigate(`/${ROUTES.admin}/${ROUTES.tasks}/${response.createTask.id}`);
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Layout
          typeValues={typeValues}
          methodValues={methodValues}
          assessmentConfig={assessmentConfig}
          createTaskMutationResult={createTaskMutationResult}
          onBack={handleBack}
        />
      </form>
    </FormProvider>
  );
};

export default PhaseAssessment;
