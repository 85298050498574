import { useTranslation, } from 'react-i18next';
import { UserOptionFragment, useUsersOptionsQuery, } from 'Apollo/graphql';
import { FormInputAutocomplete, } from 'Components/Form';
import { areOptionsEqualById, getOptionFieldUsername, renderOptionById, } from 'Utils/helpers';

const InputUser = (): JSX.Element => {
  const { t, } = useTranslation('registrationSeller');

  const { loading, data, } = useUsersOptionsQuery({
    fetchPolicy: 'cache-and-network', // TODO: cache should be different for table and this options
  });

  return (
    <FormInputAutocomplete<UserOptionFragment>
      name="user"
      options={data?.users.data || []}
      getOptionLabel={getOptionFieldUsername}
      isOptionEqualToValue={areOptionsEqualById}
      renderOption={renderOptionById(getOptionFieldUsername)}
      loading={loading}
      label={t('phaseInformationVerification.labelUser')}
      required
    />
  );
};

export default InputUser;
