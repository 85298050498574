import { useFormContext, useController, } from 'react-hook-form';
import InputCheckbox, { InputCheckboxProps, } from 'Components/Inputs/InputCheckbox';
import { useFormInputStatus, } from '../utils/useFormInputStatus';
import { InputFormErrorMessage, } from '../types';

interface Props extends Omit<InputCheckboxProps, 'value' | 'onChange' | 'error' | 'id' > {
  name: string,
}

const FormInputCheckbox = ({
  name,
  helperText,
  ...rest
}: Props): JSX.Element => {
  const { control, } = useFormContext();
  const {
    field: { value, onBlur: onBlurField, onChange: onChangeField, },
    fieldState: { error, },
  } = useController({
    name,
    control,
  });

  const handleChange: InputCheckboxProps['onChange'] = (newValue) => {
    onChangeField(newValue);
    onBlurField();
  };

  const inputStatus = useFormInputStatus(
    error?.message as InputFormErrorMessage | undefined,
    helperText,
  );

  return (
    <InputCheckbox
      {...rest}
      value={value}
      onChange={handleChange}
      error={inputStatus.isError}
      helperText={inputStatus.text}
    />
  );
};

export default FormInputCheckbox;
