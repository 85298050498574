import { styled, } from '@mui/material/styles';
import { StepIconProps, } from '@mui/material/StepIcon';
import CheckIcon from 'Utils/svg/CheckIcon';

const StepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState, }) => ({
    
    display: 'flex',
    height: 22,
    alignItems: 'center',
    color: theme.palette.common.white,
    ...(ownerState.active && {
      color: theme.palette.secondary.main,
    }),
    '& .StepIcon-completed': {
      color: theme.palette.secondary.main,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 10,
      color: theme.palette.common.white,
    },
    '& .StepIcon-circle': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 16,
      height: 16,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    '& .StepIcon-innerCircle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#E0E0E7',
      ...(ownerState.active && {
        backgroundColor: theme.palette.common.white,
      }),
    },
  }),
);

const StepIcon = ({
  active,
  completed,
  className,
}: StepIconProps): JSX.Element => {
  return (
    <StepIconRoot ownerState={{ active, }} className={className}>
      {completed ? (
        <div className="StepIcon-completed StepIcon-circle">
          <CheckIcon className="StepIcon-completedIcon" />
        </div>
      ) : (
        <div className="StepIcon-circle">
          <div className="StepIcon-innerCircle" />
        </div>
      )}
    </StepIconRoot>
  );
};

export default StepIcon;
