import { CustomerVerificationMethod, CustomerVerificationStartResultEmailGrpc, } from 'Apollo/graphql';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { useTranslation, } from 'react-i18next';
import { CreateReplacementPhase, CreateReplacementStore, } from '../../../createReplacementStore';
import CheckGrpcVerification from './CheckGrpcVerification';

interface Props {
  verification: CustomerVerificationStartResultEmailGrpc;
  completeCustomerVerificationPhase: (verification: CreateReplacementStore['customerVerification']) => void;
  goBackToPhase: (phase: CreateReplacementPhase) => void;
  onClose: () => void;
}
const GrpcEmailVerification = ({ verification, completeCustomerVerificationPhase, goBackToPhase, onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation();

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>{t('registrationSeller:phaseCustomerVerification.title')}</ModalDrawerTitle>
      <ModalDrawerBody>
        <CheckGrpcVerification
          onComplete={() =>
            completeCustomerVerificationPhase({
              shouldVerify: false,
              isVerified: true,
              method: CustomerVerificationMethod.Email,
              value: verification.globalApiGrpcVerification.verificatedValue,
            })
          }
          onGoBack={() => goBackToPhase('customerRegistration')}
          startedVerification={(verification as CustomerVerificationStartResultEmailGrpc).globalApiGrpcVerification}
        />
      </ModalDrawerBody>
    </>
  );
};

export default GrpcEmailVerification;
