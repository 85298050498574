import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const CrossOutlinedIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...props}
    fill="none"
  >
    <path
      d="m14.283 13.95 1.373 1.374a.671.671 0 0 1 .136.806c-.133.262-.41.43-.693.364a1.015 1.015 0 0 1-.455-.248c-.899-.882-1.786-1.778-2.679-2.67-.184-.184-.19-.184-.368-.005L8.909 16.26c-.205.205-.443.308-.732.218a.683.683 0 0 1-.317-1.11c.038-.044.081-.082.122-.123l2.638-2.637c.182-.182.182-.193.005-.37L7.936 9.55c-.165-.165-.27-.354-.241-.597a.656.656 0 0 1 .445-.579.668.668 0 0 1 .703.14c.048.041.09.09.134.134l2.626 2.626c.164.165.183.165.344.003l2.7-2.7c.207-.206.447-.306.733-.216a.682.682 0 0 1 .314 1.111c-.037.043-.081.08-.121.121l-2.663 2.663c-.148.148-.148.173-.003.32l1.375 1.374z"
      fill="currentColor"
      stroke="currentColor"
    />
    <rect
      x={1}
      y={1}
      width={22}
      height={22}
      rx={11}
      stroke="currentColor"
      fill="none"
      strokeWidth={2}
    />
  </SvgIcon>
);

export default CrossOutlinedIcon;
