import { ApolloError, } from '@apollo/client';
import { BoxProps, } from '@mui/material/Box';
import AlertTitle from '@mui/material/AlertTitle';
import ErrorAlertWrapper from './ErrorAlertWrapper';
import { useHumanFriendlyError, } from './useHumanFriendlyError';
import { ApolloCustomGqlErrors, CustomErrorMessage, } from './types';

interface Props extends Omit<BoxProps, 'children'> {
  forceError?: boolean,
  error?: Error | ApolloError | null,
  customError?: CustomErrorMessage | null,
  apolloCustomGqlErrors?: ApolloCustomGqlErrors, 
}

const ErrorAlert = ({
  error,
  forceError,
  customError,
  apolloCustomGqlErrors,
  ...boxProps
}: Props): JSX.Element | null => {
  const humanFriendlyError = useHumanFriendlyError({
    forceError,
    error,
    customError,
    apolloCustomGqlErrors,
  });

  if (!humanFriendlyError) return null;
  return (
    <ErrorAlertWrapper
      {...boxProps}
    >
      {humanFriendlyError.title && (
        <AlertTitle>
          {humanFriendlyError.title}
        </AlertTitle>
      )}
      {humanFriendlyError.text}
    </ErrorAlertWrapper>
  );
};

export default ErrorAlert;
