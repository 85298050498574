import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const CalendarIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 26 26"
    fill="none"
    {...props}
  >
    <path
      d="M12.987 24.745H3.928c-1.373 0-2.173-.79-2.173-2.156V6.326c0-1.349.8-2.139 2.15-2.143h1.137c.44 0 .619.183.623.627v1.91c0 .554.262.869.716.88.455.012.75-.307.75-.88V2.312c0-.796.678-1.278 1.36-.978a.867.867 0 0 1 .57.787c.022.526.017 1.052.023 1.577 0 .448.038.484.481.484h5.653c.562 0 .718.155.72.718v1.836c0 .568.27.863.768.859.427 0 .696-.328.696-.858V2.295c0-.788.698-1.264 1.376-.954a.88.88 0 0 1 .556.797c.018.54.025 1.077.017 1.616 0 .31.103.44.427.44a73.57 73.57 0 0 1 2.348 0c1.306 0 2.117.8 2.119 2.102V22.67c0 1.275-.823 2.084-2.094 2.086l-9.164-.01zm.016-1.95h8.432c.742 0 .86-.115.86-.84v-11.09c0-.694-.12-.813-.806-.813H4.515c-.688 0-.804.116-.804.813v11.09c0 .717.12.84.825.84z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CalendarIcon;
