import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const NewsColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon viewBox="0 0 84 75" fill="none" {...props}>
    <g clipPath="url(#clip0_8282_62688)">
      <path
        d="M0.885152 35.1103V7.46779C0.885152 2.61822 3.57269 0.0673496 8.73194 0.0625C25.2135 0.0625 41.695 0.0625 58.1765 0.0625C63.2433 0.0625 65.9565 2.61821 65.977 7.38534C65.977 11.5075 66.0233 15.6296 65.977 19.7469C65.977 20.9011 66.3522 21.2018 67.5187 21.1484C69.5737 21.065 71.6318 21.0812 73.6851 21.1969C76.2394 21.3453 78.6371 22.409 80.3879 24.1704C82.1388 25.9318 83.1105 28.2579 83.1043 30.673C83.1489 41.5393 83.1489 52.4071 83.1043 63.2766C83.0851 66.1818 81.8775 68.9676 79.7335 71.0523C77.5896 73.1371 74.6751 74.3596 71.5988 74.4646C70.8331 74.5034 70.0572 74.4986 69.2915 74.4986C50.71 74.4986 32.1301 74.4986 13.552 74.4986C8.2078 74.4986 4.19447 72.326 1.98997 67.7092C1.29863 66.1473 0.949043 64.4697 0.962229 62.7771C0.833762 53.5387 0.885152 44.3245 0.885152 35.1103ZM59.1169 36.4294C59.1169 27.254 59.1169 18.0819 59.1169 8.91297C59.1169 7.05558 58.6904 6.59487 56.8559 6.59002C41.2308 6.59002 25.604 6.59002 9.97551 6.59002C8.25405 6.59002 7.74017 7.07497 7.74017 8.69473C7.74017 26.7157 7.74017 44.7368 7.74017 62.7578C7.74017 66.007 9.83162 67.9516 13.2797 67.9516C28.0106 67.9516 42.7416 67.9516 57.4725 67.9516C59.1066 67.9516 59.6719 67.2582 59.4766 65.7305C59.2809 64.3735 59.1693 63.0068 59.1426 61.6375C59.1066 53.2574 59.122 44.8531 59.1169 36.4488V36.4294ZM76.2544 47.0354C76.2544 41.7834 76.2544 36.5361 76.2544 31.2889C76.2544 28.8641 74.9133 27.5935 72.4158 27.5741C71.2185 27.5741 70.0212 27.5741 68.8188 27.5741C66.3882 27.5741 65.9822 27.9524 65.9822 30.2414C65.9822 40.9395 65.9822 51.6377 65.9822 62.3358C65.9762 62.9416 66.0244 63.5466 66.1261 64.1447C66.3351 65.1401 66.8792 66.0451 67.6774 66.7249C68.4756 67.4048 69.4851 67.8232 70.5556 67.9177C73.8752 68.165 76.2082 66.0991 76.239 62.7771C76.2853 57.5493 76.2544 52.3021 76.2544 47.0548V47.0354Z"
        fill="#D47E5F"
      />
      <path
        d="M15.8429 27.508C15.8429 25.0493 15.8429 22.5857 15.8429 20.1221C15.8429 17.3967 17.4873 15.7866 20.3393 15.7575C23.8901 15.7187 27.441 15.7139 30.9918 15.7575C33.7564 15.7915 35.4779 17.4306 35.4933 20.0251C35.5173 24.9523 35.5173 29.8795 35.4933 34.8066C35.4933 37.5466 33.7359 39.1712 30.8376 39.1712C27.4153 39.2003 23.9929 39.1712 20.5602 39.1712C17.549 39.1712 15.8635 37.566 15.8378 34.7484C15.8223 32.3576 15.8429 29.9328 15.8429 27.508Z"
        fill="#D47E5F"
      />
      <path
        d="M34.2216 50.3961H17.9114C15.4397 50.3961 14.6072 49.6007 14.5918 47.2535C14.5918 44.8287 15.4294 43.9219 17.8497 43.9219C28.8123 43.9219 39.7748 43.9219 50.7374 43.9219C51.9501 43.9219 53.1783 44.0868 53.6716 45.2507C54.2317 46.5843 54.3961 48.1216 53.4609 49.3098C53.1328 49.6335 52.74 49.8927 52.3052 50.0725C51.8704 50.2523 51.4021 50.3491 50.9275 50.3573C45.3572 50.4397 39.7868 50.3961 34.2216 50.3961Z"
        fill="#D47E5F"
      />
      <path
        d="M29.9411 63.3036C25.8712 63.3036 21.8065 63.3036 17.7418 63.3036C15.3574 63.3036 14.5918 62.4792 14.5918 60.0253C14.5918 57.6975 15.3883 56.8828 17.7521 56.8828C25.9295 56.8828 34.1034 56.8828 42.274 56.8828C44.6532 56.8828 45.4342 57.7169 45.424 60.1611C45.424 62.4647 44.5966 63.3036 42.2637 63.3085C38.1579 63.3133 34.0469 63.3036 29.9411 63.3036Z"
        fill="#D47E5F"
      />
      <path
        d="M48.1023 31.0097C49.2123 31.0097 50.3274 30.9709 51.4322 31.0097C51.7554 31.002 52.0772 31.0544 52.3789 31.1641C52.6807 31.2737 52.9566 31.4384 53.191 31.6487C53.4253 31.859 53.6134 32.1109 53.7445 32.3898C53.8757 32.6688 53.9473 32.9694 53.9553 33.2745C54.0199 33.9196 54.0199 34.5691 53.9553 35.2143C53.9338 35.83 53.6546 36.4126 53.1787 36.8344C52.7029 37.2562 52.0693 37.4828 51.4168 37.4645C49.1506 37.5081 46.8844 37.4645 44.6183 37.4645C44.322 37.4646 44.028 37.4153 43.7498 37.319C42.2442 36.8001 41.6121 35.2046 42.0386 33.0659C42.1452 32.4632 42.4835 31.9187 42.9888 31.5366C43.4942 31.1546 44.131 30.9618 44.7776 30.9952C45.8875 30.9709 46.9975 30.9952 48.1126 30.9952L48.1023 31.0097Z"
        fill="#D47E5F"
      />
      <path
        d="M47.9146 24.5043C46.8046 24.5043 45.6895 24.5431 44.5847 24.5043C42.9968 24.4413 42.0821 23.5344 42.0153 22.0505C41.9871 21.527 41.9871 21.0026 42.0153 20.4792C42.0976 19.0971 42.9763 18.166 44.4254 18.132C46.8234 18.0706 49.2215 18.0706 51.6196 18.132C52.229 18.1255 52.8166 18.3457 53.2552 18.7451C53.6938 19.1445 53.948 19.6908 53.9628 20.2658C54.0245 20.867 54.0348 21.4719 53.9937 22.0747C53.9012 23.6072 53.0173 24.4462 51.4243 24.4995C50.2219 24.5431 49.0656 24.5043 47.9146 24.5043Z"
        fill="#D47E5F"
      />
    </g>
    <defs>
      <clipPath id="clip0_8282_62688">
        <rect width="82.25" height="74.4167" fill="white" transform="translate(0.875 0.09375)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default NewsColoredIcon;
