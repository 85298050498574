import { yupResolver, } from '@hookform/resolvers/yup/dist/yup';
import { useCheckDeviceCodeMutation, } from 'Apollo/graphql';
import LoadingBox from 'Components/LoadingBox';
import DeviceCodeForm from 'Modules/RegistrationSeller/Components/DeviceCodeForm';
import { formValidationSchema, } from 'Modules/RegistrationSeller/Components/DeviceCodeFormConfig';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { throttle, } from 'lodash';
import { useCallback, useEffect, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import shallow from 'zustand/shallow';

const selectStoreData = (s: RegistrationStore) => ({
  deviceCodeRequiredList: s.deviceCodeRequiredPromotionsList,
  completeDeviceCodesPromotionPhase: s.completeDeviceCodesPromotionPhase,
  deviceCodeIndex: s.deviceCodePromotionsIndex,
  inputDeviceCode: s.inputDeviceCodePromotion,
  goBackInDeviceCodes: s.goBackInDeviceCodesPromotion,
  goBackToPhase: s.goBackToPhase,
  deviceCodeRequiredListForCount: s.deviceCodeRequiredList,
});

const PhaseDeviceCodesPromotion = (): JSX.Element => {
  const {
    deviceCodeRequiredList,
    completeDeviceCodesPromotionPhase,
    deviceCodeIndex,
    inputDeviceCode,
    goBackToPhase,
    goBackInDeviceCodes,
    deviceCodeRequiredListForCount,
  } = useRegistrationStore(selectStoreData, shallow);
  const basicDeviceCodeCount = deviceCodeRequiredListForCount ? deviceCodeRequiredListForCount.length : 0;

  const [checkDeviceCodeMutation, checkDeviceCodeMutationResult,] = useCheckDeviceCodeMutation();
  const onlyDeviceCodes = deviceCodeRequiredList.map((item) => item.assortment.deviceCode || '').filter((code, index) => index !== deviceCodeIndex);
  const methods = useForm({
    defaultValues: { deviceCode: deviceCodeRequiredList[deviceCodeIndex]?.assortment.deviceCode || '', },
    resolver: yupResolver(formValidationSchema(checkDeviceCodeMutationResult.data, onlyDeviceCodes)),
    mode: 'onSubmit',
  });

  useEffect(() => {
    const throttledCheckDeviceCode = throttle((deviceCode: string) => {
      checkDeviceCodeMutation({ variables: { deviceCode, includeShortRegistration: true, }, });
    }, 1000);

    const subscription = methods.watch((value, { name, type, }) => {
      if (value.deviceCode) throttledCheckDeviceCode(value.deviceCode);
    });

    return () => {
      throttledCheckDeviceCode.cancel();
      subscription.unsubscribe();
    };
  }, [checkDeviceCodeMutation, methods, methods.watch,]);

  const handleGoBack = useCallback(() => {
    if (deviceCodeIndex === 0) goBackToPhase('deviceCodes');
    goBackInDeviceCodes();
    methods.resetField('deviceCode');
  }, [goBackInDeviceCodes, goBackToPhase, methods, deviceCodeIndex,]);

  const handleSubmit = methods.handleSubmit((values) => {
    if (deviceCodeIndex === deviceCodeRequiredList.length - 1) {
      completeDeviceCodesPromotionPhase();
    }
    inputDeviceCode(deviceCodeIndex, values.deviceCode || '');
    methods.resetField('deviceCode');
  });

  useEffect(() => {
    methods.setValue('deviceCode', deviceCodeRequiredList[deviceCodeIndex]?.assortment.deviceCode || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceCodeIndex,]);

  useEffect(() => {
    if (deviceCodeRequiredList.length < 1) {
      completeDeviceCodesPromotionPhase();
    }
  });
  if (deviceCodeRequiredList.length < 1) {
    return <LoadingBox />;
  }
  const assortmentName = deviceCodeRequiredList[deviceCodeIndex]?.assortment.option.name as string;
  const imageUrl = deviceCodeRequiredList[deviceCodeIndex]?.assortment.option.image?.publicUrl as string;
  const currentDeviceCodeIndex = deviceCodeIndex + basicDeviceCodeCount + 1;
  const totalDeviceCodeCount = deviceCodeRequiredList.length + basicDeviceCodeCount;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <DeviceCodeForm
          assortmentName={assortmentName}
          assortmentImageUrl={imageUrl}
          currentDeviceCodeIndex={currentDeviceCodeIndex}
          totalDeviceCodeCount={totalDeviceCodeCount}
          handleGoBack={handleGoBack}
        />
      </form>
    </FormProvider>
  );
};

export default PhaseDeviceCodesPromotion;
