import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const PlusIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#plus-a)">
      <path
        d="M7.25 13.252H3.73c-.752 0-1.307-.548-1.3-1.26a1.267 1.267 0 0 1 1.113-1.229c.097-.008.195-.012.292-.01h6.916c.403 0 .429-.026.429-.418V3.333c0-.705.44-1.235 1.085-1.32a1.264 1.264 0 0 1 1.404 1.116c.009.097.012.194.01.292v6.956c0 .334.043.374.37.374h7.062c.758 0 1.327.552 1.319 1.263a1.253 1.253 0 0 1-1.117 1.226 2.6 2.6 0 0 1-.291.01h-6.978c-.317 0-.364.046-.364.36v7.063c0 .704-.44 1.235-1.085 1.32a1.252 1.252 0 0 1-1.404-1.115 2.866 2.866 0 0 1-.01-.291v-6.959c0-.334-.041-.376-.368-.376H7.25Z"
        fill="#currentColor"
      />
    </g>
    <defs>
      <clipPath id="plus-a">
        <path fill="#currentColor" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default PlusIcon;
