import ModalCreatePromotion from 'Modules/PromotionsAdmin/modals/ModalCreatePromotion';
import ModalEditPromotion from 'Modules/PromotionsAdmin/modals/ModalEditPromotion';
import ModalRemovePromotion from 'Modules/PromotionsAdmin/modals/ModalRemovePromotion';
import PromotionsTable from './PromotionsTable';

const PromotionsAdminPage = () => {
  return (
    <>
      <PromotionsTable />

      <ModalCreatePromotion />
      <ModalRemovePromotion />
      <ModalEditPromotion />
    </>
  );
};

export default PromotionsAdminPage;
