import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const AssortmentNotificationIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon viewBox="0 0 76 74" fill="none" {...props}>
    <path
      d="M37.1846 54.1716C37.1846 60.2518 37.1846 66.3337 37.1846 72.4172C37.1846 74.1146 36.8589 74.3076 35.2897 73.6494C24.526 69.1264 13.7607 64.6083 2.99371 60.0951C0.832419 59.1895 0.235352 58.3433 0.235352 55.9828C0.235352 44.271 0.235352 32.5593 0.235352 20.8475C0.235352 19.1155 0.506746 18.9225 2.10058 19.5905C6.86233 21.5947 11.6043 23.6286 16.371 25.6081C16.721 25.7175 17.0219 25.9459 17.2219 26.2539C17.4219 26.562 17.5084 26.9304 17.4665 27.2956C17.4467 30.0074 17.4467 32.7209 17.4665 35.4361C17.4665 37.2324 18.4534 38.3607 19.9041 38.3558C21.3548 38.3508 22.3713 37.1928 22.3713 35.4262C22.3713 33.5358 22.3713 31.6454 22.3713 29.755C22.3713 28.5872 22.7562 28.3199 23.827 28.7653C27.9571 30.4627 32.0626 32.2294 36.1631 33.9218C36.9971 34.2632 37.15 34.867 37.15 35.6835C37.1846 41.8445 37.1846 48.0007 37.1846 54.1716Z"
      fill="#D47E5F"
    />
    <path
      d="M42.1172 53.8915C42.1172 47.8096 42.1172 41.7228 42.1172 35.641C42.1172 34.5671 42.4972 34.0425 43.4544 33.612C53.3924 29.1286 63.3173 24.617 73.2289 20.0775C75.4889 19.0432 75.7356 19.1818 75.7356 21.6314C75.7356 33.0627 75.706 44.4891 75.7652 55.9155C75.7652 58.177 74.8228 59.4538 72.8046 60.3693C63.1972 64.6845 53.6244 69.0739 44.0416 73.4436C42.4379 74.176 42.1221 73.983 42.1172 72.1619C42.1172 66.0684 42.1172 59.9783 42.1172 53.8915ZM56.886 53.0008C56.886 53.8272 56.886 54.6487 56.886 55.4751C56.9206 57.0388 57.9617 58.182 59.3088 58.1721C60.656 58.1622 61.7415 57.0091 61.7761 55.4701C61.8155 54.2824 61.7761 53.0898 61.7761 51.8972C61.7761 49.4229 61.7761 49.4971 64.2088 49.0913C64.5138 49.0236 64.8011 48.892 65.0519 48.7052C65.3027 48.5185 65.5114 48.2808 65.6644 48.0076C65.7369 47.7268 65.7431 47.433 65.6822 47.1494C65.6214 46.8659 65.4953 46.6005 65.3141 46.3745C63.7943 44.7069 62.1906 43.1183 60.5671 41.5496C59.6691 40.6886 58.8746 40.7034 57.9716 41.5744C56.3778 43.1134 54.7889 44.6623 53.3036 46.3053C53.1206 46.5438 52.9954 46.8218 52.938 47.1173C52.8806 47.4128 52.8926 47.7176 52.973 48.0076C53.2197 48.5025 53.9599 48.8241 54.557 49.1012C54.9159 49.1886 55.2893 49.1987 55.6524 49.1309C56.5406 49.1012 56.9304 49.5318 56.9107 50.4027C56.8909 51.2737 56.886 52.1397 56.891 53.0008H56.886Z"
      fill="#D47E5F"
    />
    <path
      d="M38.0049 0C38.4756 0.108442 38.9387 0.247291 39.3915 0.415683C42.9246 1.94976 46.4527 3.49373 49.9611 5.08224C50.3811 5.35121 50.7558 5.68527 51.0714 6.07197C50.7358 6.42332 50.4644 6.91819 50.0499 7.11118C40.0725 11.7926 30.0802 16.4427 20.0731 21.0614C19.5685 21.2711 19.0046 21.2869 18.4891 21.1059C14.2126 19.364 9.93609 17.5776 5.65956 15.7466C5.27961 15.5833 4.78123 15.1131 4.78617 14.7865C4.7911 14.4599 5.27962 13.9601 5.6349 13.7968C15.9611 9.28694 26.2971 4.80348 36.643 0.34641C37.0886 0.200174 37.5437 0.0844343 38.0049 0Z"
      fill="#D47E5F"
    />
    <path
      d="M71.8043 14.8792C71.3907 15.2507 70.943 15.5821 70.4671 15.8689C65.5326 18.1552 60.5784 20.4266 55.6045 22.6832C50.7934 24.8804 45.9675 27.033 41.1761 29.2748C40.6979 29.5324 40.1659 29.6734 39.6232 29.6863C39.0805 29.6992 38.5425 29.5836 38.0526 29.349C34.633 27.8644 31.174 26.4689 27.7495 24.9892C27.3301 24.8061 27.034 24.3311 26.6787 23.9995C27.0389 23.6482 27.3399 23.1731 27.7741 22.9751C34.4159 19.8575 41.0972 16.7844 47.7291 13.6965C50.8871 12.2119 54.0551 10.7768 57.1934 9.24269C57.5857 9.03208 58.0228 8.91942 58.4678 8.91425C58.9127 8.90908 59.3524 9.01156 59.7494 9.213C63.2628 10.7966 66.8057 12.3108 70.324 13.8746C70.8405 14.1739 71.3352 14.5096 71.8043 14.8792Z"
      fill="#D47E5F"
    />
  </SvgIcon>
);

export default AssortmentNotificationIcon;
