/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloCache, DefaultContext, MutationUpdaterFunction, Reference, } from '@apollo/client';
import { CreateReplacementInput, CreateReplacementMutation, DeleteReplacementMutation, Exact, ReplacementFragmentDoc, } from 'Apollo/graphql';

type UpdateReplacementRemove =
  | MutationUpdaterFunction<
      DeleteReplacementMutation,
      Exact<{
        id: string;
      }>,
      DefaultContext,
      ApolloCache<any>
    >
  | undefined;

export const updateReplacementRemove: UpdateReplacementRemove = (cache, { data, }) => {
  try {
    if (data?.deleteReplacement) {
      cache.modify({
        fields: {
          replacements: (existing, { readField, }) => ({
            ...existing,
            data: existing.data.filter((itemRef: Reference) => readField('id', itemRef) !== data.deleteReplacement.id),
          }),
        },
      });
    }
  } catch {
    // ignore error
  }
};

type UpdateReplacementCreate =
  | MutationUpdaterFunction<
      CreateReplacementMutation,
      Exact<{
        replacementInput: CreateReplacementInput;
      }>,
      DefaultContext,
      ApolloCache<any>
    >
  | undefined;

export const updateReplacementCreate: UpdateReplacementCreate = (cache, { data, }) => {
  try {
    if (data?.createReplacement) {
      const newRef = cache.writeFragment({
        id: cache.identify(data.createReplacement),
        data: data.createReplacement,
        fragment: ReplacementFragmentDoc,
        fragmentName: data.createReplacement.__typename,
      });
      cache.modify({
        fields: {
          replacements: (existing) => ({
            ...existing,
            data: [...existing.data, newRef,],
          }),
        },
      });
    }
  } catch {
    // ignore error
  }
};
