import { useTranslation, } from 'react-i18next';
import ImageAsync from 'Components/ImageAsync';
import Button, { ButtonProps, } from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, } from '@mui/material/styles';
import { FileFragment, } from 'Apollo/graphql';
import TrashIcon from 'Utils/svg/TrashIcon';

const ValueWrapper = styled('div')(({ theme, }) => ({
  marginBottom: theme.spacing(2),
  position: 'relative',
  maxWidth: '100%',
  paddingTop: '56.25%',

  '& > img': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  '& > div': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface Props {
  open: () => void;
  value?: FileFragment;
  loading?: boolean;
  buttonProps?: ButtonProps,
  disabledAll?: boolean,
  handleRemove?: () => void,
}

const UploadButton = ({
  open,
  value,
  loading,
  buttonProps,
  disabledAll,
  handleRemove,
}: Props): JSX.Element => {
  const { t, } = useTranslation('common');

  return (
    <div>
      {(value || loading) && (
        <ValueWrapper>
          {loading ? (
            <div>
              <CircularProgress size={37} />
            </div>
          ) : (
            <ImageAsync
              src={value!.publicUrl}
            />
          )}
        </ValueWrapper>
      )}
       <Grid container spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="neutral"
            {...buttonProps}
            disabled={loading || buttonProps?.disabled}
            onClick={open}
          >
            {buttonProps?.children}
          </Button>
        </Grid>
        {value && (
          <Grid item>
            <Button
              startIcon={<TrashIcon />}
              variant="contained"
              color="neutral"
              disabled={disabledAll}
              onClick={handleRemove}
            >
              {t('inputFile.btnRemove')}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default UploadButton;
