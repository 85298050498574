import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import FetchAvailablePurchases from './FetchAvailablePurchases';

const PhasePurchase = (): JSX.Element => {
  const { t, } = useTranslation('registrationSeller');

  return (
    <>
      <Typography
        variant="h1"
        sx={{ mb: 1.5, }}
      >
        {t('phasePurchase.title')}
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 5, }}
      >
        {t('phasePurchase.info')}
      </Typography>
      <FetchAvailablePurchases />
    </>
  );
};

export default PhasePurchase;
