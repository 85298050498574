import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { PointsTransactionOperation, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type PointsTransactionOperationOption = {
  id: PointsTransactionOperation,
  labelKey: string,
};

export const POINTS_TRANSACTION_OPERATION_OPTIONS: PointsTransactionOperationOption[] = [
  {
    id: PointsTransactionOperation.Plus,
    labelKey: 'pointsTransactionAdmin:editPointsTransactionModal.options.plus',
  },
  {
    id: PointsTransactionOperation.Minus,
    labelKey: 'pointsTransactionAdmin:editPointsTransactionModal.options.minus',
  },
];

export const createOperationOption = (operation: PointsTransactionOperation): PointsTransactionOperationOption | null => {
  const optionResult = POINTS_TRANSACTION_OPERATION_OPTIONS.find(o => o.id === operation);
  if (optionResult) return optionResult;
  return null;
};

type GetOptionLabel = (o: PointsTransactionOperationOption) => string;
type GetOptionKey = (o: PointsTransactionOperationOption) => string;

type UseOptionsPointsTransactionOperation = () => {
  options: PointsTransactionOperationOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<PointsTransactionOperationOption>, 
};

export const useOptionsPointsTransactionOperation: UseOptionsPointsTransactionOperation = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: POINTS_TRANSACTION_OPERATION_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
