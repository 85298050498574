import shallow from 'zustand/shallow';
import { Link as RouterLink, } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ROUTES, } from 'Utils/constants';
import { FillTaskStore, useFillTaskStore, } from 'Modules/TasksSeller/store/fillTaskStore';
import AppContainer from 'Components/AppContainer';
import TaskContentBlocks from 'Components/TaskContent';

const selectStoreData = (s: FillTaskStore) => ({
  task: s.taskResult.data!,
  finishContent: s.finishContent,
});

const PhaseContent = (): JSX.Element => {
  const { t, } = useTranslation('tasksSeller');
  const {
    task,
    finishContent,
  } = useFillTaskStore(selectStoreData, shallow);

  const handleContinue = () => finishContent();

  return (
    <AppContainer maxWidth="sm">

      <Typography
        component="h1"
        variant="h1"
        mb={1.5}
      >
        {task.name}
      </Typography>
      <Typography
        variant="body1"
        mb={4}
      >
        {t('fill.content.info')}
      </Typography>

      <Paper
        sx={{
          pt: 4,
          pr: 2,
          pb: 4,
          pl: 2,
          mb: 4,
        }}
      >
        <TaskContentBlocks
          contentBlocks={task.content?.blocks || []}
        />
      </Paper>

      <Grid container spacing={1.5}>
        <Grid item xs={12} lg="auto">
          <Button
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            onClick={handleContinue}
          >
            {t('fill.content.btnContinue')}
          </Button>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button
            component={RouterLink}
            to={`/${ROUTES.tasks}`}
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            variant="outlined"
            color="primary"
          >
            {t('common:actions.back')}
          </Button>
        </Grid>
      </Grid>

    </AppContainer>
  );
};

export default PhaseContent;
