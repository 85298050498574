import Box from '@mui/material/Box';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { AssortmentOptionFragment, PromotionAvailabilityFragment, PurchaseProductsAvailabilityFragment, } from 'Apollo/graphql';
import { FormInputProductCountMultiple, } from 'Components/Form';
import { getOptionFieldId, getOptionFieldName, } from 'Utils/helpers';
import FormInputProductCountMultiplePromotion from 'Modules/RegistrationSeller/Components/PromotionInput/FormInputProductCountMultiplePromotion';
import { PromotionCategoryPurchaseObject, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { useCallback, } from 'react';
import { useFormContext, useWatch, } from 'react-hook-form';
import PromotionsRemainingCount from './PromotionsRemainingCount';
import { FormConfig, } from './types';
import { isGlobalLimitEnabled, } from './utils';

interface Props {
  structure: PurchaseProductsAvailabilityFragment,
  formConfig: FormConfig,
  handleSubmitForPromotion: ()=>void,
  setActivePromotionPurchase: (index: number, id: string) => void,
  promotionCategoryPurchaseList: PromotionCategoryPurchaseObject,
  handleChangeInPromotionPurchaseForm: (option: PromotionAvailabilityFragment,toggle: "add" | "remove") => void
};

const getPromotionKey = (o: PromotionAvailabilityFragment) => o.promotion.id; 
const getPromotionLabel = (o: PromotionAvailabilityFragment) => o.promotion.name;

const PurchaseForm = ({
  structure: {
    promotionsAvailability,
    assortmentCategories,
    remainingPromotionsGlobalCount,
  },
  formConfig,
  handleSubmitForPromotion,
  setActivePromotionPurchase,
  promotionCategoryPurchaseList,
  handleChangeInPromotionPurchaseForm,
}: Props): JSX.Element => {
  const { t, } = useTranslation('registrationSeller');
  const showPromotions = !!(formConfig.enablePromotions && promotionsAvailability.length);

  const { control, } = useFormContext();
  const value = useWatch({ name: 'values.promotions', control, });

  const getPromotionMax = useCallback((o: PromotionAvailabilityFragment) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentPromotion = value.find((v: any) => v.option.promotion.id === o.promotion.id);
    const count = currentPromotion ? currentPromotion.count : 0;


    const globalLimit = isGlobalLimitEnabled(remainingPromotionsGlobalCount) ? remainingPromotionsGlobalCount : Infinity;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const purchasedPromotions = value.reduce((acc: number, v: any) => acc + v.count, 0);

    const maxLabel = count + Math.min(
      globalLimit - purchasedPromotions,
      o.remainingCount - count,
    );

    return maxLabel;
  }, [value, remainingPromotionsGlobalCount,]);
  

  return (
    <>
      
      {showPromotions && (
        <Box mb={4}>
          <Typography
            variant="h3"
            color="primary"
            sx={{ mb: 2.5, }}
          >
            {t('phasePurchase.subtitlePromotions')}
          </Typography>
          <PromotionsRemainingCount max={remainingPromotionsGlobalCount} />
          <FormInputProductCountMultiplePromotion<PromotionAvailabilityFragment>
            name="values.promotions"
            triggerFields="values"
            getOptionKey={getPromotionKey}
            getOptionLabel={getPromotionLabel}
            getOptionMax={getPromotionMax}
            options={promotionsAvailability}
            setActivePromotionPurchase={setActivePromotionPurchase}
            completePurchasePhase={handleSubmitForPromotion}
            promotionCategoryPurchaseList={promotionCategoryPurchaseList}
            handleChangeInPromotionPurchaseForm={handleChangeInPromotionPurchaseForm}
          />
        </Box>
      )}
      
      {assortmentCategories.map((ac) => ac.items.length > 0 ? (
        <Box key={ac.id} mb={4}>
          <Typography
            variant="h3"
            color="primary"
            sx={{ mb: 2.5, }}
          >
            {ac.name}
          </Typography>
          <FormInputProductCountMultiple<AssortmentOptionFragment>
            name={`values.assortmentCategories.${ac.id}`}
            triggerFields="values"
            getOptionKey={getOptionFieldId}
            getOptionLabel={getOptionFieldName}
            options={ac.items}
          />
        </Box>
      ) : null)}
    </>
  );
};

export default PurchaseForm;
