import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { topPosInReplacementsQuery, } from './queries';

const TopPosInReplacements = ({
  showAddButton,
  filtration,
  chartFilter,
   timeDimensions,
  showSlug = true,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');
  const query = useMemo(
    () =>
      topPosInReplacementsQuery({
        timeDimensions,
        filters: chartFilter,
      }),
    [chartFilter, timeDimensions,],
  );
  return (
    <Base
      headerText={t('dashboards.pos.topPosInReplacements')}
      slug={showSlug ? 'top-pos-in-replacements' : undefined}
      showAddButton={showAddButton}
      filtration={filtration}
      dashboardLayoutItemName={DashboardLayoutItemName.PointsOfSaleTop_10Replacements}
    >
      <ChartRenderer
        query={query}
        type={ChartType.bar}
      />
    </Base>
  );
};

export default TopPosInReplacements;
