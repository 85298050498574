import { useMyPointsBalanceQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import LoadingBox from 'Components/LoadingBox';
import View from './View';

const Balance = (): JSX.Element => {
  const { error, loading, data, } = useMyPointsBalanceQuery({
    fetchPolicy: 'cache-and-network',
  });
  if (loading) return <LoadingBox />;
  if (error || !data?.myPointsBalance) return <ErrorAlert forceError error={error} />;
  return <View data={data.myPointsBalance} />;
};
export default Balance;
