import { useTranslation, } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { FullTaskFragment, } from 'Apollo/graphql';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { TaskTypeCompletionOption, } from 'Utils/options/useOptionTaskCompletionType';
import { TaskRenewalOption, } from 'Utils/options/useOptionTaskRenewal';
import { valueOrEmpty, } from 'Utils/parsers';

interface Props {
  task: FullTaskFragment,
  completionTypeOption: TaskTypeCompletionOption | null,
  renewalOption: TaskRenewalOption | null,
};

const TaskInfo = ({
  task,
  completionTypeOption,
  renewalOption,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');

  return (
    <Paper sx={{ p: 2, mb: 4, }}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('parameters.labelId')}
          >
            {task.id}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('parameters.labelName')}
          >
            {task.name}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('parameters.labelDescription')}
          >
            <div style={{ whiteSpace: 'pre-wrap', }}>
              {task.description}
            </div>
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('parameters.labelTypeOfCompletion')}
          >
            {completionTypeOption ? t(completionTypeOption.labelKey, completionTypeOption.id) : '-'}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('parameters.labelPointsForCompletion')}
          >
            {valueOrEmpty(task.pointsForCompletion)}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('parameters.labelPointsLimit')}
          >
            {valueOrEmpty(task.pointsLimit)}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('parameters.labelValidFrom')}
          >
            {task.validFrom ? t('common:date.fullDate', { date: new Date(task.validFrom), }) : '-'}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('parameters.labelValidTo')}
          >
            {task.validTo ? t('common:date.fullDate', { date: new Date(task.validTo), }) : '-'}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('parameters.labelChainIds')}
          >
            {task.chains.length > 0 ? (
              <Grid container spacing={0.5}>
                {task.chains.map((chain) => (
                  <Grid key={chain.id} item>
                    <Chip
                      label={chain.id}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              '-'
            )}
          </NotEditableTextClassic>
        </Grid>

        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('parameters.labelRenewal')}
          >
            {renewalOption?.labelKey ? t(renewalOption?.labelKey, renewalOption.id) : '-'}
          </NotEditableTextClassic>
        </Grid>

      </Grid>     
    </Paper>
  );
};

export default TaskInfo;
