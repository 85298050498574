import { ApolloError, } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { CUSTOM_LOGIN_QR_GQL_ERRORS, } from './utils';

interface Props {
  loading: boolean,
  error?: Error | ApolloError | null,
}

const StateView = ({
  loading,
  error,
}: Props): JSX.Element | null => {
  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={40} />
      </Box>  
    );
  }

  if (error) {
    return (
      <Box>
        <ErrorAlert
          forceError
          error={error}
          apolloCustomGqlErrors={CUSTOM_LOGIN_QR_GQL_ERRORS}
        />
      </Box>
    );
  }

  return null;
};

export default StateView;
