import { PointsTransactionFragment, } from 'Apollo/graphql';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { PointsTransactionStore, UsePointsTransactionStore, } from 'Modules/PointsTransactionAdmin/store/PointsTransactionStore';
import Logic from './Logic';

const selectModalData = (s: PointsTransactionStore) => s.modalRemovePointsTransaction;
const selectClose = (s: PointsTransactionStore) => s.closeModalRemovePointsTransaction;
const selectCloseDetail = (s: PointsTransactionStore) => s.closeModalDetailPointsTransaction;

const ModalRemovePointsTransaction = (): JSX.Element => {
  const { isOpen, pointsTransaction, } = UsePointsTransactionStore(selectModalData);
  const onClose = UsePointsTransactionStore(selectClose);
  const onCloseDetail = UsePointsTransactionStore(selectCloseDetail);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!pointsTransaction}
    >
      <Logic
        pointsTransaction={pointsTransaction as PointsTransactionFragment}
        onClose={onClose}
        onCloseDetail={onCloseDetail}
      />
    </ModalDrawer>
  );
};

export default ModalRemovePointsTransaction;
