import { useTranslation, } from 'react-i18next';
import { Link as RouterLink, } from 'react-router-dom';
import { useSnackbar, } from 'notistack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useForgottenPasswordMutation, } from 'Apollo/graphql';
import LinkButton from 'Components/LinkButton';
import { ROUTES, } from 'Utils/constants';
import { ForgottenPasswordEmail, } from './types';

interface Props {
  forgottenPasswordEmail: ForgottenPasswordEmail,
};

const SendView = ({
  forgottenPasswordEmail,
}: Props): JSX.Element => {
  const { t, } = useTranslation('auth');
  const { enqueueSnackbar, } = useSnackbar();
  const { email, } = forgottenPasswordEmail;

  const [ forgotMutation, forgotMutationResult, ] = useForgottenPasswordMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('forgottenPassword.notifSendTitle') as string,
        message: t('forgottenPassword.notifSendMessage', { email, }),
        variant: 'common',
      });
    },
    onError: () => {
      enqueueSnackbar({
        title: t('forgottenPassword.notifSendErrorTitle') as string,
        message: t('forgottenPassword.notifSendErrorMessage', { email, }),
        variant: 'common',
      });
    },
  });

  const resendEmail = () => {
    forgotMutation({
      variables: { email, },
    });
  };

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        marginBottom={3}
      >
        {t('forgottenPassword.linkSend.title')}
      </Typography>
      <Typography
        variant="body1"
        marginBottom={2}
      >
        {t('forgottenPassword.linkSend.info', { email, })}
      </Typography>

      <Typography
        variant="body1"
        marginBottom={2}
      >
        {t('forgottenPassword.linkSend.secondInfo')}
      </Typography>

      <LinkButton
        type="submit"
        variant="body1"
        color="secondary"
        disabled={forgotMutationResult.loading}
        onClick={resendEmail}
      >
        {t('forgottenPassword.linkSend.btnResend')}
      </LinkButton>

      <Box mt={4}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} lg="auto">
            <Button
              component={RouterLink}
              to={`/${ROUTES.login}`}
              sx={{ width: { xs: '100%', lg: 'unset', }, }}
              size="large"
              variant="contained"
              color="primary"
            >
              {t('forgottenPassword.linkSend.btnDone')}
            </Button>
          </Grid>
          <Grid item xs={12} lg="auto">
            <Button
              component={RouterLink}
              to={`/${ROUTES.login}`}
              sx={{ width: { xs: '100%', lg: 'unset', }, }}
              size="large"
              variant="outlined"
              color="primary"
            >
              {t('common:actions.back')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SendView;
