import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import shallow from 'zustand/shallow';
import { PointsTransactionStore, UsePointsTransactionStore, } from 'Modules/PointsTransactionAdmin/store/PointsTransactionStore';
import FetchData from './FetchData';

const selectStoreData = (s: PointsTransactionStore) => ({
  selectClose: s.closeModalDetailPointsTransaction,
  onRemove: s.openModalRemovePointsTransaction,
  detail: s.modalDetailPointsTransaction,
});

const ModalDetailPointsTransaction = (): JSX.Element => {
  const { selectClose, detail, onRemove, } = UsePointsTransactionStore(selectStoreData, shallow);
  const { t, } = useTranslation('pointsTransactionAdmin');

  return (
    <ModalDrawer onClose={selectClose} open={detail.isOpen && !!detail.pointsTransaction}>
      <ModalDrawerTitle onClose={selectClose}>{t('detailPointsTransactionModal.title')}</ModalDrawerTitle>
      <FetchData pointsTransaction={detail.pointsTransaction} onRemove={onRemove} onClose={selectClose} />
    </ModalDrawer>
  );
};

export default ModalDetailPointsTransaction;
