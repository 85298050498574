import { useMemo, } from 'react';
import {
  BinaryFilter,
  DateRange,
  TimeDimensionGranularity,
} from '@cubejs-client/core';
import moment from 'moment';

type FilterObjWithMultipleValues = {
  values: { id: string; name: string }[] | undefined;
  filterMember: string;
  operator: BinaryFilter['operator'];
};
type FilterObj = {
  value: { id: string; name: string } | undefined | null;
  filterMember: string;
  operator: BinaryFilter['operator'];
};

type Filters = {
  posIds?: FilterObjWithMultipleValues;
  chainIds?: FilterObjWithMultipleValues;
  userRoleId?: FilterObj;
  categoryId?: FilterObj;
  productId?: FilterObj;
};

const granularityMap = {
  'All time': undefined,
  'This year': 'month',
  'This month': 'day',
  'This week': 'day',
  Today: 'day',
  Yesterday: 'day',
  'Last 7 days': 'day',
  'Last month': 'day',
  'Last week': 'day',
  'Last year': 'month',
};

const useDashboardFilter = (
  filters: Filters,
  date?: string | string[] | undefined,
) => {
  const filtersArray = useMemo(() => {
    const array: BinaryFilter[] = [];
    Object.values(filters).forEach((filter) => {
      let values: string[] = [];
      if ('values' in filter && filter.values) {
        values = filter.values.map((value) => value.id);
      } else if ('value' in filter && filter.value) {
        values = [filter.value.id,];
      }

      if (!values.length) {
        return;
      }

      array.push({
        member: filter.filterMember,
        operator: 'equals',
        values,
      });
    });

    return array;
  }, [filters,]);

  const dateRange: DateRange | undefined = useMemo(() => {
    if (Array.isArray(date)) {
      const dateFrom = date[0];
      const dateTo = date[1];
      if (dateFrom && dateTo) {
        return [
          moment(dateFrom).format('YYYY-MM-DD'),
          moment(dateTo).format('YYYY-MM-DD'),
        ];
      }
      if (dateFrom) {
        return [
          moment(dateFrom).format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ];
      }
      if (dateTo) {
        return [
          moment(0).format('YYYY-MM-DD'),
          moment(dateTo).format('YYYY-MM-DD'),
        ];
      }
      return undefined;
    }
    if (date === 'All time') {
      return undefined;
    }
    return date;
  }, [date,]);

  const granularity: TimeDimensionGranularity | undefined = useMemo(() => {
    if (Array.isArray(date)) {
      if (Math.abs(moment(date[0]).diff(moment(date[1]), 'days')) < 31) {
        return 'day';
      }
      if (Math.abs(moment(date[0]).diff(moment(date[1]), 'days')) > 30) {
        return 'week';
      }
      return 'month';
    }
    return granularityMap[date as keyof typeof granularityMap] as
      | TimeDimensionGranularity
      | undefined;
  }, [date,]);

  return { filtersArray, dateRange, granularity, };
};

export default useDashboardFilter;
