import { ReactNode, } from 'react';
import { styled, } from '@mui/material/styles';
import MUILink from '@mui/material/Link';

const StyledLink = styled(MUILink)<{
  component: string,
  disabled?: boolean,
}>(({ theme, disabled, }) => ({
  fontSize: 18,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '22px',
  letterSpacing: '0.25px',
  display: 'inline-flex',
  alignItems: 'center',
  gap: 4,

  ...(disabled && ({
    color: theme.palette.action.disabled,
    textDecoration: 'none',
    cursor: 'inherit',
  })),
}));

interface Props {
  children: ReactNode,
  icon: ReactNode,
  disabled?: boolean,
  onClick?: () => void,
  isAllowed?: boolean,
};

const SidebarMenuMobileButton = ({
  icon,
  disabled,
  children,
  onClick,
  isAllowed,
}: Props): JSX.Element | null => {
  if (isAllowed === false) return null;
  
  return (
    <StyledLink
      component="button"
      onClick={onClick}
      disabled={disabled}
      color="inherit"
      underline="none"
    >
      {icon && icon}
      <span>{children}</span>
    </StyledLink>
  );
};

export default SidebarMenuMobileButton;
