import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { ImportPointsOfSellMutationResult, } from 'Apollo/graphql';
import { FormInputFileUpload, FormSubmit, } from 'Components/Form';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { CSV_MAX_SIZE, } from 'Utils/constants';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';

interface Props {
  onClose: () => void,
  importMutationResult: ImportPointsOfSellMutationResult,
};

const FormView = ({
  onClose,
  importMutationResult,
}: Props): JSX.Element => {
  const { t, } = useTranslation('pos');

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>
        {t('importPosModal.importForm.title')}
      </ModalDrawerTitle>
      <ModalDrawerBody>
        <Typography
          variant="body1"
        >
          {t('importPosModal.importForm.info')}
        </Typography>
        
        <Box mt={4}>
          <FormInputFileUpload
            label={t('importPosModal.importForm.labelFile')}
            name="file"
            fileType="csv"
            maxSize={CSV_MAX_SIZE}
          />
        </Box>

        {importMutationResult.data && (
          <Box mt={2}>
            <NotEditableTextClassic
              label={t('importPosModal.importForm.changes')}
            >
              <Typography variant="h4">
                {t('importPosModal.importForm.numberOfErrors')}
              </Typography>
              <Typography marginBottom={2}>
                {importMutationResult.data.importPointsOfSell.numberOfErrors}
              </Typography>
              <Button
                color="neutral"
                component="a"
                download
                href={importMutationResult.data.importPointsOfSell.reportFile.publicUrl}
              >
                {t('importPosModal.importForm.btnDownload')}
              </Button>
            </NotEditableTextClassic>
          </Box>
        )}

        <Box mt={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <FormSubmit
                size="large"
              >
                {t('importPosModal.importForm.btnSubmit')}
              </FormSubmit>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                color="inherit"
                onClick={onClose}
              >
                {t('common:actions.cancel')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <ErrorAlert
          mt={2}
          error={importMutationResult.error}
        />
      </ModalDrawerBody>
    </>
  );
};

export default FormView;
