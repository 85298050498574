import { styled, } from '@mui/material/styles';
import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import Grid from '@mui/material/Grid';
import { ReactNode, } from 'react';
import { CsvExportFragment, } from 'Apollo/graphql';

export const ColleagueWrapper = styled(Stack)(({ theme, }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

interface Props {
  children?: ReactNode;
  exportPointsOfSell: CsvExportFragment;
  onClose: () => void;
}

const View = ({ children, exportPointsOfSell, onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation();

  return (
    <ModalDrawerBody>
      {children}
      <Box mt={2}>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Button component="a" href={exportPointsOfSell.file.publicUrl} download size="large" variant="contained" color="primary">
              {t('common:actions.download')}
            </Button>
          </Grid>
          <Grid item>
            <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalDrawerBody>
  );
};

export default View;
