import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import { TableVoucherPurchaseFragment, } from 'Apollo/graphql';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellText from 'Components/Table/TableCellText';
import { MouseEventHandler, } from 'react';
import { useTranslation, } from 'react-i18next';
import { findVoucherPurchaseStatusOption, useVoucherPurchaseStatusOption, } from 'Utils/options/useOptionsVoucherPurchaseStatus';
import { valueOrEmpty, } from 'Utils/parsers';

interface Props {
  row: TableVoucherPurchaseFragment;
  onDetail: (voucherPurchase: TableVoucherPurchaseFragment) => void;
}
const VoucherPurchasesTableRow = ({ row, onDetail, }: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');

  const handleDetail: MouseEventHandler<HTMLLIElement> = () => {
    onDetail(row);
  };
  const voucherPurchaseStatusOption = findVoucherPurchaseStatusOption(row.status);

  return (
    <TableRow key={row.id}>
      <TableCellText>{t(voucherPurchaseStatusOption.labelKey)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.voucherId)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.voucher.name)}</TableCellText>
      <TableCellText>{t('common:date.fullDate', { date: new Date(row.voucher.validFrom), })} </TableCellText>
      <TableCellText>{t('common:date.fullDate', { date: new Date(row.voucher.validTo), })} </TableCellText>
      <TableCellText>{valueOrEmpty(row.user.username)}</TableCellText>
      <TableCellActions>
        <MenuItem onClick={handleDetail}>{t('purchases.table.btnDetail')}</MenuItem>
      </TableCellActions>
    </TableRow>
  );
};

export default VoucherPurchasesTableRow;
