import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const ErrorFilledIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0Zm0 10.667a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm0-7.334a.667.667 0 0 0-.656.547l-.01.12v4.667l.01.12a.667.667 0 0 0 1.312 0l.01-.12V4l-.01-.12A.667.667 0 0 0 8 3.333Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ErrorFilledIcon;
