import { useMemo, } from 'react';
import { getAuthRedirectRoute, } from '../utils/getAuthRedirectRoute';
import { useAuthUser, } from './useAuthUser';

export const useAuthRedirectRoute = (defaultRedirectTo?: string): string => {
  const authUser = useAuthUser();
  
  return useMemo<string>(
    () => getAuthRedirectRoute(authUser, defaultRedirectTo),
    [ authUser, defaultRedirectTo, ],
  );
};
