import { useTranslation, } from 'react-i18next';
import { Typography, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import { useDelayedResetNotificationCategoryCount, } from 'Tools/useDelayedResetNotificationCategoryCount';
import { NotificationType, } from 'Apollo/graphql';
import PromotionsList from './PromotionsList';

const PromotionsSellerPage = (): JSX.Element => {
  const { t, } = useTranslation('promotionsSeller');

  useDelayedResetNotificationCategoryCount(NotificationType.Promotion);

  return (
    <AppContainer maxWidth="sm">
      <Typography
        variant="h1"
        marginBottom={3}
      >
        {t('promotionsTitle')}
      </Typography>
      <PromotionsList />
    </AppContainer>
  );
};

export default PromotionsSellerPage;
