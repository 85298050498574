import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { VoucherPurchaseSortableFieldsEnum, } from 'Apollo/graphql';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import TableCellSortable from 'Components/Table/TableCellSortable';
import { useTranslation, } from 'react-i18next';
import { renderOptionById, } from 'Utils/helpers';
import { StatusOption, useVoucherPurchaseStatusOption, } from 'Utils/options/useOptionsVoucherPurchaseStatus';

const COL_WIDTHS = [
  { width: '180px', },
  { width: '140px', },
  { width: '200px', },
  { width: '180px', },
  { width: '180px', },
  { width: '120px', },
  { width: '45px', },
];
const VoucherPurchasesTableHeader = (): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const voucherPurchaseStatusOptions = useVoucherPurchaseStatusOption();
  return (
    <TableHead>
      <TableRow>
        <TableCellSortable style={COL_WIDTHS[0]} name={VoucherPurchaseSortableFieldsEnum.Status}>
          {t('purchases.table.status')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[1]}>{t('purchases.table.id')}</TableCell>
        <TableCell style={COL_WIDTHS[2]}>{t('purchases.table.name')}</TableCell>
        <TableCellSortable style={COL_WIDTHS[3]} name={VoucherPurchaseSortableFieldsEnum.ValidFrom}>
          {t('purchases.table.validFrom')}
        </TableCellSortable>
        <TableCellSortable style={COL_WIDTHS[4]} name={VoucherPurchaseSortableFieldsEnum.ValidTo}>
          {t('purchases.table.validTo')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[5]}>{t('purchases.table.username')}</TableCell>
        <TableCell style={COL_WIDTHS[6]} />
      </TableRow>
      <TableRow>
        <TableCell>
          <TableFilterAutocomplete<StatusOption>
            name="status"
            getOptionLabel={voucherPurchaseStatusOptions.getOptionLabel}
            isOptionEqualToValue={voucherPurchaseStatusOptions.isOptionEqualToValue}
            renderOption={renderOptionById(voucherPurchaseStatusOptions.getOptionLabel)}
            options={voucherPurchaseStatusOptions.options}
          />
        </TableCell>
        <TableCell>
          <TableFilterText name="voucherId" />
        </TableCell>
        <TableCell>
          <TableFilterText name="voucherName" />
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell>
          <TableFilterText name="user" />
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default VoucherPurchasesTableHeader;
