import { Trans, useTranslation, } from 'react-i18next';
import Link from '@mui/material/Link';
import { PRIVACY_POLICY_LINK, } from 'Utils/constants';

const TermsAndConditions = (): JSX.Element => {
  const {
    i18n: { language, },
  } = useTranslation();

  return (
    <Trans
      i18nKey="common:acceptTermsAndConditions.form.titleAcceptedTermsAndConditions"
      components={{
        linkTerms: <Link href={`/tos/${language}/termsOfUse.pdf`} rel="noopener noreferrer" target="_blank" />,
        linkPolicy: <Link href={PRIVACY_POLICY_LINK} rel="noopener noreferrer" target="_blank" />,
      }}
    />
  );
};

export default TermsAndConditions;
