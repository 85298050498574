import { useEffect, useState, } from 'react';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementAdmin/store/ReplacementStore';
import { mapVariables, } from 'Modules/ReplacementAdmin/pages/ReplacementAdminPage/ReplacementTable/utils';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ExportTypesEnum, useExportReplacementsMutation, } from 'Apollo/graphql';
import { ExportReplacementOptions, } from 'Utils/options/useOptionsExportReplacements';
import Button from '@mui/material/Button';
import { useTranslation, } from 'react-i18next';
import { FormProvider, useForm, } from 'react-hook-form';
import { EXPORT_TYPE_OPTIONS, } from 'Utils/options/useOptionsExportTypes';
import { yupResolver, } from '@hookform/resolvers/yup';
import { exportTypeSchema, } from 'Components/Export/helpers';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ExportTypeForm from 'Components/Export/ExportTypeForm';
import DownloadButton from './Download';

interface Props {
  withAssortments: ExportReplacementOptions;
}
const selectClose = (s: ReplacementStore) => s.closeModalExportReplacement;

const FetchData = ({ withAssortments, }: Props): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');

  const [exportMutation, { loading, error, data, called, },] = useExportReplacementsMutation({
    fetchPolicy: 'no-cache',
  });

  const [chosenType, setChosenType,] = useState<ExportTypesEnum | null>(null);

  const methods = useForm({
    defaultValues: { type: EXPORT_TYPE_OPTIONS[0], },
    resolver: yupResolver(exportTypeSchema),
    mode: 'onTouched',
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    setChosenType(values.type.id);
  });

  useEffect(() => {
    if (chosenType) {
      const storeState = useReplacementStore.getState().tableReplacement;
      const { filter, sort, } = mapVariables(storeState);
      const exportOptions = { withAssortmentsWhenCompleted: withAssortments.id === 'completed', };
      exportMutation({ variables: { filter, sort, exportOptions, type: chosenType, }, });
    }
  }, [withAssortments, exportMutation, chosenType,]);
  const onClose = useReplacementStore(selectClose);

  if (loading || (!called && chosenType))
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} mt={2} mb={2}>
          <CircularProgress size={40} />
        </Box>
        <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
          {t('common:actions.cancel')}
        </Button>
      </>
    );

  if (error || (!data?.exportReplacements && chosenType))
    return (
      <>
        <ErrorAlert mt={2} mb={2} forceError error={error} />
        <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
          {t('common:actions.cancel')}
        </Button>
      </>
    );
  if (data?.exportReplacements && chosenType) return <DownloadButton exportReplacements={data.exportReplacements} />;
  return (
    <ModalDrawerBody>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <ExportTypeForm onClose={onClose} />
        </form>
      </FormProvider>
    </ModalDrawerBody>
  );
};

export default FetchData;
