import { Grid, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import ChartDetailLayout from 'Components/Dashboard/ChartDetailLayout';
import GenderTitle from 'Components/Dashboard/Registrations/GenderTitle';
import { genderTitleQuery, } from 'Components/Dashboard/Registrations/queries';
import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartType, } from 'Utils/Charts/types';

const GenderTitlePage = () => {
  const { t, } = useTranslation('dashboards');
  const query = useMemo(
    () =>
      genderTitleQuery(),
    [],
  );
  return (
    <AppContainer>
      <ChartDetailLayout pageTitle={t('common:dashboardSidebar.registrations')}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GenderTitle showTotal showSlug={false} />
          </Grid>
          <Grid item xs={12}>
            <ChartRenderer
              query={query}
              type={ChartType.table}
              pivotConfig={{
                x: ['dimensions',],
                y: ['measures',],
                fillMissingDates: true,
                joinDateRange: false,
              }}
            />
          </Grid>
        </Grid>
      </ChartDetailLayout>
    </AppContainer>
  );
};

export default GenderTitlePage;
