/* eslint-disable no-underscore-dangle */
import { RegistrationPhase, RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import shallow from 'zustand/shallow';
import LoadingBox from 'Components/LoadingBox';
import { useCancelInProgressVerificationMutation, } from 'Apollo/graphql';
import EmailAndSmsVerification from './VerificationOptions/EmailAndSmsVerification';
import GrpcEmailVerification from './VerificationOptions/GrpcEmailVerification';
import SkipVerification from './VerificationOptions/SkipVerification';

const selectStoreData = (s: RegistrationStore) => ({
  customerVerificationResult: s.customerVerificationResult,
  goBackToPhase: s.goBackToPhase,
  completeCustomerVerificationPhase: s.completeCustomerVerificationPhase,
  storeVerification: s.verification,
  customer: s.customer,
  customerRegistration: s.customerRegistration,
  resetVerification: s.resetVerification,
});
const PhaseCustomerVerification = (): JSX.Element => {
  const { goBackToPhase, completeCustomerVerificationPhase, customerVerificationResult, customer, storeVerification, customerRegistration, resetVerification} = useRegistrationStore(
    selectStoreData,
    shallow,
  );

  const [cancelInProgressVerificationMutation] = useCancelInProgressVerificationMutation({
    onCompleted: () => resetVerification()
  })

  const goBackToPhaseWithCancelInProgressVerification = (phase: RegistrationPhase) => {
    if (customerVerificationResult !== null && 'verificatedValue' in customerVerificationResult) {
      cancelInProgressVerificationMutation({
        variables: {
          cancelInProgressVerification: {
            emailOrPhone: customerVerificationResult.verificatedValue,
          },
        },
      });
    }

    goBackToPhase(phase);
  };

  const goBack = () => goBackToPhaseWithCancelInProgressVerification(customer?.id ? 'chooseVerificationMethod' : 'customerRegistration');
  if (
    customerVerificationResult?.__typename === 'CustomerVerificationStartResultSkipVerification' &&
    customerVerificationResult.skipVerification
  ) {
    return <SkipVerification completeCustomerVerificationPhase={completeCustomerVerificationPhase} />;
  }

  if (customerVerificationResult?.__typename === 'CustomerVerificationStartResultEmailGrpc') {
    return (
      <GrpcEmailVerification
        completeCustomerVerificationPhase={completeCustomerVerificationPhase}
        goBackToPhase={goBack}
        verification={customerVerificationResult}
      />
    );
  }
  if (
    customerVerificationResult?.__typename === 'CustomerVerificationStartResultSms' ||
    customerVerificationResult?.__typename === 'CustomerVerificationStartResultSmsGrpc' ||
    customerVerificationResult?.__typename === 'CustomerVerificationStartResultEmail'
  ) {
    const verification:RegistrationStore['verification'] = {...storeVerification,method: customerVerificationResult.method,value:customerVerificationResult.verificatedValue,};
    return (
      <EmailAndSmsVerification
        completeCustomerVerificationPhase={completeCustomerVerificationPhase}
        goBackToPhase={goBackToPhaseWithCancelInProgressVerification}
        goBack={goBack}
        verification={verification}
        customer={customer}
        method={customerVerificationResult.method}
        customerRegistration={customerRegistration}
      />
    );
  }
  return <LoadingBox/>;
};

export default PhaseCustomerVerification;
