import { styled, } from '@mui/material/styles';
import { useTranslation, } from 'react-i18next';
import { AssessmentConfig, } from 'Modules/TasksSeller/store/fillTaskStore';

const Points = styled('span')(({ theme, }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.grey[600],
}));

interface Props {
  index: number,
  question: string,
  numberOfPoints?: number,
  assessmentConfig: AssessmentConfig,
};

const NodeLabel = ({
  index,
  question,
  numberOfPoints,
  assessmentConfig: {
    enablePoints,
  },
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksSeller');

  return (
    <>
      <span>
        {`${index + 1}. ${question}`}
      </span>
      {enablePoints && (
        <>
          {' '}
          <Points>
            {t('fill.assessment.points', { points: numberOfPoints || 0, })}
          </Points>
        </>
      )}
    </>
  );
};

export default NodeLabel;
