import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type ExportReplacementOptions = { id: 'created' | 'completed', labelKey: string, };

export const REPLACEMENT_EXPORT_OPTIONS: ExportReplacementOptions[] = [
  {
    id: 'created',
    labelKey: 'replacementAdmin:exportReplacementModal.assortmentOptionCreated',
  },
  {
    id: 'completed',
    labelKey: 'replacementAdmin:exportReplacementModal.assortmentOptionCompleted',
  },
];

type GetOptionLabel = (o: ExportReplacementOptions) => string;
type GetOptionKey = (o: ExportReplacementOptions) => string;

type UseOptionsExportReplacements = () => {
  options: ExportReplacementOptions[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<ExportReplacementOptions>, 
};

export const useOptionsExportReplacements: UseOptionsExportReplacements = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: REPLACEMENT_EXPORT_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
