import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { OrderBy, PointsTransactionSortableFields, useMyPointsTransactionsQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import LoadingBox from 'Components/LoadingBox';
import View from './View';

const Transactions = (): JSX.Element => {
  const { t, } = useTranslation('myPoints');

  const { error, loading, data, } = useMyPointsTransactionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { 
      pagination: { offset: 0, limit: 4, }, 
      sort:{
        field: PointsTransactionSortableFields.CreatedAt,
        order: OrderBy.Desc,
      },
    },
  });

  if (loading) return <LoadingBox />;
  if (error || !data?.myPointsTransactions) return <ErrorAlert forceError error={error} />;
  if (data.myPointsTransactions.pagination.count < 1) return (
    <Box
      pt={3}
      pb={3}
      pl={2}
      pr={2}
    >
      <Typography color="textSecondary">{t('transaction.noTransactions')}</Typography>
    </Box>
  );
  return <View data={data} />;
};
export default Transactions;
