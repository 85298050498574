import shallow from 'zustand/shallow';
import { useNewsArticlesQuery, } from 'Apollo/graphql';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import { NewsArticlesStore, useNewsArticlesStore, } from 'Modules/NewsArticlesAdmin/store/NewsArticlesStore';
import { useAuthUser, } from 'Tools/auth';
import { mapVariables, } from './utils';
import NewsArticlesTableHeader from './NewsArticlesTableHeader';
import NewsArticlesTableRow from './NewsArticlesTableRow';

const selectStoreData = (s: NewsArticlesStore) => ({
  onEdit: s.openModalEditNewsArticle,
  onRemove: s.openModalRemoveNewsArticle,
  onDetail: s.openModalDetailNewsArticle,
  tableNewsArticles: s.tableNewsArticles,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,
});

const NewsArticles = (): JSX.Element => {
  const {
    onEdit,
    onRemove,
    onDetail,
    tableNewsArticles,
    changeFilter,
    changeSort,
    changeData,
  } = useNewsArticlesStore(selectStoreData, shallow);

  const { loading, error, data, } = useNewsArticlesQuery({
    variables: mapVariables(tableNewsArticles),
    fetchPolicy: 'cache-and-network',
  });

  const authUser = useAuthUser();
  const showChainIDs = +!authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;

  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={7-showChainIDs}
      rows={data?.newsArticles.data.length || 0}
      loading={loading}
      error={error}
      limit={tableNewsArticles.data.limit}
      sortField={tableNewsArticles.data.sortField}
      sortOrder={tableNewsArticles.data.sortOrder}
      offset={tableNewsArticles.data.offset}
      count={data?.newsArticles.pagination.count}
      filter={tableNewsArticles.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <NewsArticlesTableHeader />
      <TableBody>
        {(data?.newsArticles.data || []).map((row) => (
          <NewsArticlesTableRow
            key={row.id}
            row={row}
            onEdit={onEdit}
            onRemove={onRemove}
            onDetail={onDetail}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default NewsArticles;
