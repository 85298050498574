import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const SupportColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M5.58 12.627c0 1.223-.032 2.448.008 3.671.044 1.335-1.11 1.946-2.231 1.363-2.718-1.414-3.185-4.97-.878-6.926a3.69 3.69 0 0 1 .963-.595c.275-.114.314-.264.31-.517-.028-1.443.235-2.829.918-4.107 1.31-2.455 3.329-3.95 6.066-4.4 2.286-.375 4.381.16 6.223 1.566 2.167 1.662 3.234 3.893 3.303 6.622a.896.896 0 0 1 0 .172c-.062.364.075.554.422.72 1.493.737 2.278 1.99 2.315 3.648.034 1.602-.688 2.832-2.064 3.673a.507.507 0 0 0-.21.371c.06 2.494-1.85 3.786-3.779 3.858-.572.022-1.146 0-1.718.03a.592.592 0 0 0-.39.192 1.874 1.874 0 0 1-2.265.554 1.868 1.868 0 0 1-.828-.824 1.886 1.886 0 0 1-.18-1.157c.066-.42.279-.804.599-1.083.32-.279.727-.435 1.15-.44a1.822 1.822 0 0 1 1.505.704.617.617 0 0 0 .413.204c.72-.015 1.452.01 2.153-.12 1.112-.204 1.613-.986 1.487-2.123a.378.378 0 0 0-.087-.214c-.32-.323-.37-.726-.37-1.152 0-2.285.008-4.57 0-6.856-.008-2.336-.954-4.193-2.868-5.526-2.71-1.885-6.599-1.246-8.608 1.4a6.593 6.593 0 0 0-1.367 4.119c.007 1.054.01 2.112.007 3.173Z"
      fill="#000"
    />
    <ellipse cx={13.187} cy={20.831} rx={2.155} ry={2.169} fill="#D47E5F" />
  </SvgIcon>
);

export default SupportColoredIcon;
