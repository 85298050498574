import { NewsArticleFragment, NewsArticleTableFragment, useNewsArticleQuery, } from 'Apollo/graphql';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import Logic from './Logic';

interface Props {
  newsArticle?: NewsArticleTableFragment | null,
  onClose: () => void,
  onOpenRemove: (newsArticle: NewsArticleFragment | NewsArticleTableFragment) => void,
};

const FetchData = ({
  newsArticle,
  onClose,
  onOpenRemove,
}: Props): JSX.Element => {
  const preservedNewsArticle = usePreserveValue(newsArticle);

  const { loading, error, data, } = useNewsArticleQuery({
    variables: { id: preservedNewsArticle.id, },
    fetchPolicy: 'network-only',
  });

  if (loading) return (
    <ModalDrawerBodyLoading
      onClose={onClose}
    />
  );
  if (error || !data?.newsArticle) return (
    <ModalDrawerBodyError
      onClose={onClose}
      error={error}
    />
  );
  return (
    <Logic
      newsArticle={data.newsArticle}
      onClose={onClose}
      onOpenRemove={onOpenRemove}
    />
  );
};

export default FetchData;
