import { SvgIcon, SvgIconProps, } from '@mui/material';

const DashboardChainsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7073 3.5009C11.0372 3.86723 11.5067 4.07617 12.0001 4.07617C12.4927 4.07617 12.9631 3.86723 13.293 3.5009L14.1288 2.57529C14.6222 2.02757 15.4074 1.85063 16.0885 2.13249C16.7695 2.41524 17.199 3.09544 17.1617 3.83166L17.0976 5.07647C17.0727 5.56906 17.2568 6.04831 17.6053 6.39686C17.9539 6.7454 18.434 6.93035 18.9266 6.90545L20.1714 6.84232C20.9068 6.80587 21.5852 7.23621 21.867 7.91641C22.148 8.59661 21.9711 9.38084 21.4251 9.87432L20.4986 10.711C20.1332 11.0409 19.9242 11.5112 19.9242 12.0038C19.9242 12.4964 20.1332 12.9668 20.4986 13.2967L21.4251 14.1325C21.972 14.6259 22.1498 15.4119 21.867 16.0921C21.5852 16.7732 20.905 17.2036 20.1688 17.1653L18.924 17.1013C18.4323 17.0764 17.9521 17.2614 17.6036 17.609C17.255 17.9576 17.0701 18.4368 17.095 18.9294L17.159 20.1742C17.1946 20.9087 16.7651 21.5871 16.0849 21.8681C15.4056 22.1499 14.6222 21.9739 14.1288 21.4288L13.293 20.5032C12.9631 20.1369 12.4927 19.9288 12.0001 19.9288C11.5067 19.9288 11.0372 20.1369 10.7073 20.5032L9.87152 21.4288C9.37715 21.9721 8.59559 22.1473 7.91717 21.8663C7.23874 21.5853 6.80929 20.9096 6.84307 20.176L6.90709 18.9312C6.93288 18.4377 6.74793 17.9567 6.3985 17.6073C6.04817 17.2587 5.56714 17.0747 5.07366 17.1013L3.82886 17.1653C3.09353 17.2018 2.41333 16.7723 2.13236 16.0913C1.8505 15.4102 2.02833 14.6259 2.57515 14.1325L3.50165 13.2967C3.86709 12.9668 4.07604 12.4964 4.07604 12.0038C4.07604 11.5112 3.86709 11.0409 3.50165 10.711L2.57515 9.87432C2.03277 9.37995 1.85761 8.59839 2.13858 7.92086C2.41955 7.24244 3.09442 6.81298 3.82797 6.84677L5.07278 6.9099C5.56625 6.93568 6.04728 6.75074 6.39583 6.4013C6.74527 6.05187 6.92932 5.57084 6.90265 5.07736L6.84307 3.83166C6.80929 3.09811 7.23874 2.42236 7.91717 2.14139C8.59559 1.86041 9.37715 2.03558 9.87152 2.57885L10.7073 3.5009Z"
        stroke="black"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33228 15.3398H17.3352"
        stroke="#D47E5F"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6672 8.6722H7.99973V15.3408H10.6672V8.6722Z"
        stroke="#D47E5F"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0021 11.3397H13.3346V15.3408H16.0021V11.3397Z"
        stroke="#D47E5F"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default DashboardChainsIcon;
