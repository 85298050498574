import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import QRCode from 'react-qr-code';
import { Button, } from '@mui/material';
import { QrCode2, } from '@mui/icons-material';
import ClipboardCopy from '../../../../Components/ClipboardButton';

interface Props {
  value: string;
}

const RenderQr = ({ value, }: Props): JSX.Element => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <QRCode value={value} id="QRCode" />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={() => {
            const svg = document.getElementById('QRCode');
            if (svg) {
              const svgData = new XMLSerializer().serializeToString(svg);
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const img = new Image();
              img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx?.drawImage(img, 0, 0);
                const pngFile = canvas.toDataURL('image/png');
                const downloadLink = document.createElement('a');
                downloadLink.download = 'QRCode';
                downloadLink.href = `${pngFile}`;
                downloadLink.click();
              };
              img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
            }
          }}
          sx={{ display: 'flex', gap: '4px', }}
        >
          <QrCode2 />
          Download QR
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{ textDecoration: 'underline', wordBreak: 'break-all', }}
        >
          {value}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ClipboardCopy copyText={value} />
      </Grid>
    </Grid>
  );
};

export default RenderQr;
