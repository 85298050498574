import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import { Claim, TableVoucherFragment, VoucherType, } from 'Apollo/graphql';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellText from 'Components/Table/TableCellText';
import { useAuthUser, } from 'Tools/auth';
import { valueOrEmpty, } from 'Utils/parsers';
import { MouseEventHandler, } from 'react';
import { useTranslation, } from 'react-i18next';

interface Props {
  row: TableVoucherFragment;
  onDetail: (voucher: TableVoucherFragment) => void;
  onDelete: (voucher: TableVoucherFragment) => void;
  availableTypes:
    | {
        __typename?: 'AvailableVoucherType' | undefined;
        id: VoucherType;
        labelKey: string;
      }[]
    | undefined;
}
const VouchersTableRow = ({ row, onDetail, onDelete, availableTypes, }: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const authUser = useAuthUser();

  const handleDelete: MouseEventHandler<HTMLLIElement> = () => {
    onDelete(row);
  };

  const handleDetail: MouseEventHandler<HTMLLIElement> = () => {
    onDetail(row);
  };
  const type = availableTypes?.find((typ) => typ.id === row.type);

  return (
    <TableRow key={row.id}>
      <TableCellText>{valueOrEmpty(row.id)}</TableCellText>
      <TableCellText>{valueOrEmpty(type?.labelKey)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.name)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.description)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.priceFiat)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.pricePoints)}</TableCellText>
      <TableCellText>{valueOrEmpty(row.number)}</TableCellText>
      <TableCellText>{t('common:date.fullDate', { date: new Date(row.validFrom), })} </TableCellText>
      <TableCellText>{t('common:date.fullDate', { date: new Date(row.validTo), })} </TableCellText>
      <TableCellText>{t('common:date.fullDate', { date: new Date(row.createdAt), })} </TableCellText>
      <TableCellText>{valueOrEmpty(row.author.username)}</TableCellText>
      <TableCellActions>
        <MenuItem onClick={handleDetail}>{t('vouchers.table.btnDetail')}</MenuItem>
        {authUser?.claimsSet.has(Claim.DeletePurchase) && <MenuItem onClick={handleDelete}>{t('vouchers.table.btnDelete')}</MenuItem>}
      </TableCellActions>
    </TableRow>
  );
};

export default VouchersTableRow;
