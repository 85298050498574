import { useCallback, useMemo, } from 'react';
import { usePointsTransactionReasonQuery, } from 'Apollo/graphql';

// TODO: all forms should be checked and refactored so it store only values of the form not labels etc.
export type PointsTransactionReasonOption = string;

type GetOptionLabel = (o: PointsTransactionReasonOption) => string;
type GetOptionKey = (o: PointsTransactionReasonOption) => string;
type GetLabelForId = (id: string) => string;

type UseOptionsPointsTransactionReason = () => {
  options: PointsTransactionReasonOption[];
  getOptionLabel: GetOptionLabel;
  getOptionKey: GetOptionKey;
  getLabelForId: GetLabelForId;
  isOptionEqualToValue: (o: string, v: string) => boolean;
};

export const useOptionsPointsTransactionReason: UseOptionsPointsTransactionReason = () => {
  const { data, loading, } = usePointsTransactionReasonQuery({
    fetchPolicy: 'no-cache',
  });
  const options = useMemo(() => {
    if (loading || !data) {
      return [];
    }

    return data.reasons.map(({ name, code, }) => {
      return code;
    });
  }, [data, loading,]);

  const getOptionLabel: GetOptionLabel = useCallback((option: string) => {
    return data?.reasons.find(reason => reason.code === option)?.name ?? '';
  }, [data?.reasons,]);


  const getLabelForId = (id: string) => data?.reasons.find(reason => reason.code === id)?.name || '';
  return {
    options,
    getOptionLabel,
    getOptionKey: (o => o),
    isOptionEqualToValue: (o, v) => o === v,
    getLabelForId,
  };
};
