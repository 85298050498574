import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const BoldIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    {...props}
    fill="none"
  >
    <path
      fill="none"
      d="M5.584 10.168v-6.5h4.225c1.79 0 3.24 1.455 3.24 3.25s-1.45 3.25-3.24 3.25H5.584Zm0 0h5.535c1.79 0 3.24 1.455 3.24 3.25s-1.45 3.25-3.24 3.25H5.584v-6.5Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default BoldIcon;
