import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FormInputGroupRadio, FormSubmit } from 'Components/Form';
import { ExportTypeOption, useOptionsExportTypes } from 'Utils/options/useOptionsExportTypes';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose?: () => void;
}
const ExportTypeForm = ({ onClose }: Props): JSX.Element => {
  const { t } = useTranslation('common');
  const exportTypeOptions = useOptionsExportTypes();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormInputGroupRadio<ExportTypeOption>
          name="type"
          label={t('export.form.title')}
          options={exportTypeOptions.options}
          getOptionKey={exportTypeOptions.getOptionKey}
          getOptionLabel={exportTypeOptions.getOptionLabel}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormSubmit color="primary" variant="contained" size="large" sx={{ width: { xs: '100%', lg: 'unset' } }}>
          {t('actions.download')}
        </FormSubmit>
      </Grid>
      {!!onClose && (
        <Grid item>
          <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
            {t('common:actions.cancel')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ExportTypeForm;
