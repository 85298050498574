import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabelClassic from '../components/InputLabelClassic';
import FormHelperTextClassic from '../components/FormHelperTextClassic';

export interface InputScaleClassicProps {
  value: number | null,
  onChange: (value: number | null) => void,
  error?: boolean,
  label?: ReactNode,
  helperText?: ReactNode,
  required?: boolean,
};

const OPTIONS = [ 1, 2, 3, 4, 5, ];

const InputScaleClassic = ({
  value,
  onChange,
  error,
  label,
  helperText,
  required,
}: InputScaleClassicProps): JSX.Element => {
  const { t, } = useTranslation('common');

  return (
    <>
      {label && (
        <InputLabelClassic
          shrink
          color="primary"
          required={required}
        >
          {label}
        </InputLabelClassic>
      )}
      <Box
        display="flex"
        justifyContent="left"
        alignItems="center"
        width="100%"
        maxWidth="550px"
        gap={0.5}
      >
        {OPTIONS.map((option) => {
          const isSelected = value === option;
          return (
            <Box
              key={option}
              flexGrow={1}
            >
              <Button
                style={{ minWidth: 'unset', }}
                fullWidth
                size="small"
                variant={isSelected ? 'contained' : 'outlined'}
                color={isSelected ? 'primary' : 'neutral'}
                onClick={() => onChange(isSelected ? null : option)}
              >
                {option}
              </Button>
            </Box>
          );
        })}
      </Box>
      {(helperText) && (
        <FormHelperTextClassic
          error={error}
        >
          {helperText}
        </FormHelperTextClassic>
      )}
    </>
  );
};

export default InputScaleClassic;
