import { useParams, } from 'react-router-dom';
import { useTaskFullDetailQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import LoadingSkeleton from './LoadingSkeleton';
import Layout from './Layout';

const FetchData = (): JSX.Element => {
  const { taskId, } = useParams();

  const { loading, error, data, } = useTaskFullDetailQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: taskId as string,
    },
  });

  if (loading && !data?.task) return <LoadingSkeleton />;
  if (error || !data?.task) return (
    <ErrorAlert
      forceError
      error={error}
    />
  );
  return (
    <Layout
      task={data.task}
    />
  );
};

export default FetchData;
