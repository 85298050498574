/* eslint-disable @typescript-eslint/no-explicit-any */
import { PurchaseProductsAvailabilityFragment, } from 'Apollo/graphql';
import { InputFormErrorMessage, } from 'Components/Form';
import { InputProductCountMultipleValueOption, } from 'Components/Inputs/InputProductCountMultiple/types';
import { PromotionCategoryPurchaseObject, RegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { AnyObject, } from 'Utils/types';
import { useMemo, } from 'react';
import * as yup from 'yup';
import { FormConfig, } from './types';

type RetUsePurchaseForm = {
  initValues: {
    values: RegistrationStore['purchase'];
  } 
  validationSchema?: any;
  structure: PurchaseProductsAvailabilityFragment;
};

export const validatePromotionPurchaseList = (promotionCategoryPurchaseList: PromotionCategoryPurchaseObject): boolean => {
  const storeKeys = Object.keys(promotionCategoryPurchaseList);
  for (let i = 0; i < storeKeys?.length; i++) {
    for (let j = 0; j < promotionCategoryPurchaseList[storeKeys[i]].length; j++) {
      if (promotionCategoryPurchaseList[storeKeys[i]][j]?.isValid === false) return false;
    }
  }
  return true;
};

const CUSTOM_PROMOTION_VALIDATION_MESSAGE = ({ max, }: { max: number }): InputFormErrorMessage => ({
  key: 'registrationSeller:phasePurchase.errorMaxPromotions',
  params: { max, },
});

const getProductCount = (options: InputProductCountMultipleValueOption<AnyObject>[]): number => options.reduce(
  (prev, cur) => prev + cur.count,
  0,
);

export const isGlobalLimitEnabled = (remainingPromotionsGlobalCount: number | undefined | null): remainingPromotionsGlobalCount is number  => {
  return typeof remainingPromotionsGlobalCount === 'number';
};

const TEST_ERROR_SOME_PRODUCTS = {
  message: (): InputFormErrorMessage => ({ key: 'registrationSeller:phasePurchase.errorAtLeastOne', }),
  test: (values: any) => {
    const { promotions, assortmentCategories, } = values as RegistrationStore['purchase'];
    const categoriesKeys = Object.keys(assortmentCategories);

    let count = promotions.length;
    for (let i = 0; i < categoriesKeys.length; i++) {
      const category = assortmentCategories[categoriesKeys[i]];
      count += getProductCount(category);
    }

    return count > 0;
  },
};

export const usePurchaseForm = (
  data: PurchaseProductsAvailabilityFragment,
  purchasePhase: RegistrationStore['purchase'],
  formConfig: FormConfig,
  promotionCategoryPurchaseList: PromotionCategoryPurchaseObject,
): RetUsePurchaseForm => {
  const ret = useMemo<RetUsePurchaseForm>(
    () => {
      const { remainingPromotionsGlobalCount, assortmentCategories, } = data;
      const validationSchema = yup.object().shape({
        values: yup
          .object()
          .shape({
            promotions: yup.array().when([], {
              is: () => formConfig.enablePromotions && typeof remainingPromotionsGlobalCount === 'number',
              then: yup.array().test({
                params: {
                  max: remainingPromotionsGlobalCount,
                },
                message: CUSTOM_PROMOTION_VALIDATION_MESSAGE,
                test: (value) => {
                  const totalCount = (value || [] as {count: number}[]).reduce((prev, curr) => prev + curr.count, 0);
                  if (remainingPromotionsGlobalCount) {
                    return totalCount <= remainingPromotionsGlobalCount;
                  }
                  return true;
                },
              }),
            }),
          })
          .test(TEST_ERROR_SOME_PRODUCTS)
          .test({
            message: (): InputFormErrorMessage => ({ key: 'registrationSeller:phasePurchase.errorNotAllPromotionsHavePurchases', }),
            test: (values: any) => {
              return validatePromotionPurchaseList(promotionCategoryPurchaseList);
            },
          }),
      });

      return {
        initValues: {
          values: {
            promotions: formConfig.enablePromotions ? purchasePhase.promotions : [],
            assortmentCategories: assortmentCategories.reduce(
              (prev, curr) => ({
                ...prev,
                [curr.id]: purchasePhase.assortmentCategories?.[curr.id] || [],
              }),
              {},
            ),
          },
        },
        validationSchema,
        structure: data,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [promotionCategoryPurchaseList, data, purchasePhase, formConfig.enablePromotions,],
  );

  return ret;
};
