import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { FormError, FormSubmit, } from 'Components/Form';
import { StartCustomerVerificationMutationResult, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { FormConfig, } from './types';
import CustomerRegistrationForm from './CustomerRegistrationForm';

interface Props {
  formConfig: FormConfig,
  registrationEmail: string,
  startVerificationResult: StartCustomerVerificationMutationResult,
  onGoBack: () => void,
}

const View = ({
  formConfig,
  registrationEmail,
  startVerificationResult,
  onGoBack,
}: Props): JSX.Element => {
  const { t, } = useTranslation('registrationSeller');

  return (
    <>
      <Typography
        variant="h1"
        sx={{ mb: 1.5, }}
      >
        {t('phaseRegisterCustomer.title')}
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 3, }}
      >
        {t('phaseRegisterCustomer.info')}
      </Typography>

      <Box
        mb={4}
      >
        <CustomerRegistrationForm
          formConfig={formConfig}
          registrationEmail={registrationEmail}
        />
      </Box>

      <FormError
        mb={2}
      />

      <ErrorAlert
        mb={2}
        error={startVerificationResult.error}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} lg="auto">
          <FormSubmit
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
          >
            {t('phaseRegisterCustomer.submit')}
          </FormSubmit>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            onClick={onGoBack}
          >
            {t('common:actions.back')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default View;
