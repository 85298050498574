import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { PointsTransactionOperation, PointsTransactionReason, useCreatePointsTransactionMutation } from 'Apollo/graphql';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import PointsTransactionCreateForm from 'Modules/PointsTransactionAdmin/components/PointsTransactionCreateForm';
import { PointsTransactionStore, UsePointsTransactionStore } from 'Modules/PointsTransactionAdmin/store/PointsTransactionStore';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import shallow from 'zustand/shallow';
import { CreatePointsTransactionForm, pointsTransactionCreateFormValidationSchema } from './utils';

const selectStoreData = (s: PointsTransactionStore) => ({
  selectClose: s.closeModalCreatePointsTransaction,
  modalCreatePointsTransaction: s.modalCreatePointsTransaction,
});

const ModalCreatePointsTransaction = (): JSX.Element => {
  const { selectClose, modalCreatePointsTransaction } = UsePointsTransactionStore(selectStoreData, shallow);
  const [createPointsTransactionMutation, result] = useCreatePointsTransactionMutation({});
  const { t } = useTranslation('pointsTransactionAdmin');
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<CreatePointsTransactionForm>({
    resolver: yupResolver(pointsTransactionCreateFormValidationSchema),
    mode: 'onTouched',
  });
  const handleSubmit = methods.handleSubmit(async (values) => {
    await createPointsTransactionMutation({
      variables: {
        pointsTransactionInput: {
          amount: values.amount,
          operation: values.operation?.id as PointsTransactionOperation,
          reasonCode: values.reason,
          username: values.username,
        },
      },
      onError: (error) => {
        enqueueSnackbar({
          message: error.message,
          variant: 'error',
        });
      },
      onCompleted: (response) => {
        if (response.createPointsTransaction.id) {
          enqueueSnackbar({
            title: t('createPointsTransactionModal.notifCreatedTitle') as string,
            message: t('createPointsTransactionModal.notifCreatedMessage', { productId: response.createPointsTransaction.id }),
            variant: 'common',
          });
          selectClose();
        } else {
          enqueueSnackbar({
            message: t('createPointsTransactionModal.notifCreatedError'),
            variant: 'error',
          });
        }
      },
    });
  });

  return (
    <ModalDrawer onClose={selectClose} open={modalCreatePointsTransaction.isOpen}>
      <ModalDrawerTitle onClose={selectClose}>{t('createPointsTransactionModal.title')}</ModalDrawerTitle>
      <ModalDrawerBody>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <PointsTransactionCreateForm loading={result.loading} />
          </form>
        </FormProvider>
        <Box mt={4}>
          <Button size="large" variant="outlined" color="primary" onClick={selectClose}>
            {t('common:actions.cancel')}
          </Button>
        </Box>
      </ModalDrawerBody>
    </ModalDrawer>
  );
};

export default ModalCreatePointsTransaction;
