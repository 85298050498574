import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useSnackbar, } from 'notistack';
import { useCreateUserMutation, } from 'Apollo/graphql';
import { updateUserCreate, } from 'Modules/Users/utils/userCache';
import { mapBadFormDataError, } from 'Components/Form';
import { useAuthUser, } from 'Tools/auth';
import {
  initUserCreateFormValues,
  userCreateFormValidationSchema,
  mapFormCreateToVariables,
  UserCreateFormValues,
  createBadFormDataFieldMapper,
} from '../../utils/userForm';
import View from './View';

interface Props {
  onClose: () => void,
};

const Logic = ({
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('users');
  const authUser = useAuthUser();
  const { enqueueSnackbar, } = useSnackbar();
  const methods = useForm({
    defaultValues: initUserCreateFormValues,
    resolver: yupResolver(userCreateFormValidationSchema),
    mode: 'onTouched',
    context: {
      authUser,
    },
  });

  const [ createMutation, createMutationResult, ] = useCreateUserMutation({
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('createUserModal.notifCreatedTitle') as string,
        message: t('createUserModal.notifCreatedMessage', { userId: response.createUser.username, }),
        variant: 'common',
      });
      onClose();
    },
    onError: (responseError) => {
      mapBadFormDataError({
        apolloError: responseError,
        fieldMapper: createBadFormDataFieldMapper,
        methods,
      });
    },
    update: updateUserCreate,
  });

  const handleSubmit = methods.handleSubmit(async (values: UserCreateFormValues) => {
    await createMutation({
      variables: {
        userInput: mapFormCreateToVariables(values, authUser),
      },
    });    
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View
          createMutationResult={createMutationResult}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default Logic;
