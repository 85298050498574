import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { genderTitleQuery, } from './queries';

const GenderTitle = ({
  showAddButton,
  showSlug = true,
  showTotal,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');
  const query = useMemo(
    () =>
      genderTitleQuery(),
    [],
  );
  return (
    <Base
      headerText={t('dashboards.registrations.genderTitle')}
      slug={showSlug ? 'gender-title' : undefined}
      showAddButton={showAddButton}
      
      dashboardLayoutItemName={DashboardLayoutItemName.RegistrationsConsumerTitleGender}
    >
      <ChartRenderer
        showTotal={showTotal}
        query={query}
        type={ChartType.pie}
      />
    </Base>
  );
};

export default GenderTitle;
