import shallow from 'zustand/shallow';
import { useReplacementsQuery, } from 'Apollo/graphql';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementAdmin/store/ReplacementStore';
import { useAuthUser, } from 'Tools/auth';
import ReplacementTableRow from './ReplacementTableRow';
import ReplacementTableHeader from './ReplacementTableHeader';
import { mapVariables, } from './utils';

const selectStoreData = (s: ReplacementStore) => ({
  onRemove: s.openModalRemoveReplacement,
  onDetail: s.openModalDetailReplacement,
  onEdit: s.openModalEditReplacement,
  tableReplacement: s.tableReplacement,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,
});

const Replacements = (): JSX.Element => {
  const {
    onRemove,
    onDetail,
    onEdit,
    tableReplacement,
    changeFilter,
    changeSort,
    changeData,
  } = useReplacementStore(selectStoreData, shallow);

  const { loading, error, data, } = useReplacementsQuery({
    variables: mapVariables(tableReplacement),
    fetchPolicy: 'cache-and-network',
  });
  
  const authUser = useAuthUser();
  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS ? 0 : 1;

  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={10-showChainIDs}
      rows={data?.replacements.data.length || 0}
      loading={loading}
      error={error}
      limit={tableReplacement.data.limit}
      sortField={tableReplacement.data.sortField}
      sortOrder={tableReplacement.data.sortOrder}
      offset={tableReplacement.data.offset}
      count={data?.replacements.pagination.count}
      filter={tableReplacement.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <ReplacementTableHeader />
      <TableBody>
        {(data?.replacements.data || []).map((row) => (
          <ReplacementTableRow
            key={row.id}
            row={row}
            onRemove={onRemove}
            onDetail={onDetail}
            onEdit={onEdit}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default Replacements;
