import { OrderBy, PaginationInput, PointsTransactionFilterInput, PointsTransactionOperation, PointsTransactionSortInput, PointsTransactionSortableFields, } from 'Apollo/graphql';
import { PointsTransactionStore, } from 'Modules/PointsTransactionAdmin/store/PointsTransactionStore';

export const evaluateOperationSymbol = (operation: PointsTransactionOperation) => {
  switch (operation) {
    case PointsTransactionOperation.Minus:
      return '-';
    case PointsTransactionOperation.Plus:
      return '+';
    default:
      return '';
    }
};

export const mapVariables = (storeData: PointsTransactionStore['tablePointsTransaction']): {
  filter?: PointsTransactionFilterInput,
  pagination?: PaginationInput,
  sort?: PointsTransactionSortInput,
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    author: storeData.filter.author
      ? { username: {contains: storeData.filter.author,},}
      : undefined,
    posId: storeData.filter.pos?.id ? { equals: storeData.filter.pos.id, } : undefined,
    chainId: storeData.filter.chain?.id ? { equals: storeData.filter.chain.id, } : undefined,
    id: storeData.filter.id ? {contains: storeData.filter.id,} : undefined,
    operation: storeData.filter.operation?.id ? {equals: storeData.filter.operation?.id,} : undefined,
    reason: storeData.filter.reason ? { equals: storeData.filter.reason, } : undefined,
  },
  sort: storeData.data.sortField && storeData.data.sortOrder
    ? {
      field: storeData.data.sortField,
      order: storeData.data.sortOrder,
    } : {field: PointsTransactionSortableFields.CreatedAt, order: OrderBy.Desc,},

});
