import { MarketEnum, } from 'Apollo/graphql';

export const GENERIC_CODE_POSITION = '/images/registration/deviceCodePosition.png';
export const GENERIC_CODE_POSITION_PL = '/images/registration/deviceCodePositionPl.png';
export const GENERIC_CODE_POSITION_CZ = '/images/registration/deviceCodePositionCz.png';
export const GENERIC_CODE_POSITION_HU = '/images/registration/deviceCodePositionHu.png';

export const positionUrl = (countryCode: MarketEnum | undefined): string => {
  switch (countryCode) {
    case MarketEnum.Cz:
      return GENERIC_CODE_POSITION_CZ;
    case MarketEnum.Pl:
      return GENERIC_CODE_POSITION_PL;
    case MarketEnum.Hu:
      return GENERIC_CODE_POSITION_HU;
    default:
      return GENERIC_CODE_POSITION;
  }
};
