import { FocusEventHandler, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useController, useFormContext, } from 'react-hook-form';
import InputAutocompleteClassic from 'Components/Inputs/InputAutocompleteClassic';
import { InputFormErrorMessage, useFormInputStatus, } from 'Components/Form';
import { AssessmentBlockOption, useOptionsAssessmentBlock, } from 'Utils/options/useOptionsAssessmentBlock';
import { renderOptionById, } from 'Utils/helpers';
import { CreateTaskStore, useCreateTaskStore, } from 'Modules/TasksAdmin/store/createTaskStore';
import { AssessmentBlockTypeEnum, TaskTypeEnum, } from 'Apollo/graphql';
import { buildNodeInputName, } from './utils';
import { NodeProps, } from './types';

interface Props {
  index: number,
  onChangeType: NodeProps['onChangeType'],
}

const selectStoreData = (s: CreateTaskStore) => ({
  typeValues: s.typeValues,
});

const FormInputNodeType = ({
  index,
  onChangeType,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const { control, } = useFormContext();
  const { field: { value, onBlur: onBlurField, }, fieldState: { error, }, } = useController({
    name: buildNodeInputName(index, 'type'),
    control,
  });

  const {
    typeValues,
  } = useCreateTaskStore(selectStoreData);

  const taskContentTypes = useOptionsAssessmentBlock();
  const options = useMemo(
    () => {
      const typeIsObligatory = typeValues.type?.id === TaskTypeEnum.Obligatory;
      return taskContentTypes.options.filter((option) => {
        if (typeIsObligatory) return option.id === AssessmentBlockTypeEnum.Photo;
        return option.id !== AssessmentBlockTypeEnum.Photo;
      });
    },
    [ taskContentTypes.options, typeValues.type, ],
  );
  const handleChange = (e: unknown, newValue: AssessmentBlockOption | null) => {
    onChangeType(index, newValue);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    onBlurField();
  };

  const inputStatus = useFormInputStatus(
    error?.message as InputFormErrorMessage | undefined,
  );

  return (
    <InputAutocompleteClassic<AssessmentBlockOption>
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      getOptionLabel={taskContentTypes.getOptionLabel}
      isOptionEqualToValue={taskContentTypes.isOptionEqualToValue}
      options={options}
      label={t('assessment.labelType')}
      renderOption={renderOptionById(taskContentTypes.getOptionLabel)}
      placeholder={t('common:select.placeholder')}
      helperText={inputStatus.text}
      error={inputStatus.isError}
      required
    />
  );
};

export default FormInputNodeType;
