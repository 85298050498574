import { useTasksQuery, } from 'Apollo/graphql';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import { TaskStore, useTaskStore, } from 'Modules/TasksAdmin/store/GeneralTasksStore';
import { useTranslation, } from 'react-i18next';
import shallow from 'zustand/shallow';
import { mapVariablesTask, } from '../utils';
import TasksTableHeader from './GeneralTasksTableHeader';
import TasksTableRow from './GeneralTasksTableRow';


const selectStoreData = (s: TaskStore) => ({
  tableTasks: s.tableTasks,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,
  onDelete: s.openModalDeleteTask,
  onExport: s.openModalExportTask,
});

const Tasks = (): JSX.Element => {
  const {
    tableTasks,
    changeFilter,
    changeSort,
    changeData,
    onDelete,
    onExport,
  } = useTaskStore(selectStoreData, shallow);
  const { loading, error, data, } = useTasksQuery({
    variables: mapVariablesTask(tableTasks),
    fetchPolicy: 'cache-and-network',
  });

  return (
  
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={16}
      rows={data?.tasks.data.length || 0}
      loading={loading}
      error={error}
      limit={tableTasks.data.limit}
      sortField={tableTasks.data.sortField}
      sortOrder={tableTasks.data.sortOrder}
      offset={tableTasks.data.offset}
      count={data?.tasks.pagination.count}
      filter={tableTasks.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <TasksTableHeader />
      <TableBody>
        {(data?.tasks.data || []).map((row) => (
          <TasksTableRow
            key={row.id}
            row={row}
            onDelete={onDelete}
            onExport={onExport}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default Tasks;
