import Grid from '@mui/material/Grid';
import BoldIcon from 'Utils/svg/BoldIcon';
import ItalicIcon from 'Utils/svg/ItalicIcon';
import UnderlineIcon from 'Utils/svg/UnderlineIcon';
import OrderedListIcon from 'Utils/svg/OrderedListIcon';
import UnOrderedListIcon from 'Utils/svg/UnOrderedListIcon';
import H1Icon from 'Utils/svg/H1Icon';
import H2Icon from 'Utils/svg/H2Icon';
import QuoteIcon from 'Utils/svg/QuoteIcon';
import ToggleLeaf from './ToggleLeaf';
import ToggleElement from './ToggleElement';

const Toolbar = (): JSX.Element => {
  return (
    <Grid container spacing={0.5} mb={2}>
      <Grid item>
        <ToggleLeaf
          format="bold"
          icon={<BoldIcon />}
        />
      </Grid>
      <Grid item>
        <ToggleLeaf
          format="italic"
          icon={<ItalicIcon />}
        />
      </Grid>
      <Grid item>
        <ToggleLeaf
          format="underline"
          icon={<UnderlineIcon />}
        />
      </Grid>
      <Grid item>
        <ToggleElement
          format="numbered-list"
          icon={<OrderedListIcon />}
        />
      </Grid>
      <Grid item>
        <ToggleElement
          format="bulleted-list"
          icon={<UnOrderedListIcon />}
        />
      </Grid>
      <Grid item>
        <ToggleElement
          format="heading-one"
          icon={<H1Icon />}
        />
      </Grid>
      <Grid item>
        <ToggleElement
          format="heading-two"
          icon={<H2Icon />}
        />
      </Grid>
      <Grid item>
        <ToggleLeaf
          format="code"
          icon={<QuoteIcon />}
        />
      </Grid>
    </Grid>
  );
};

export default Toolbar;
