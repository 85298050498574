import { useAvailableVoucherTypesQuery, useVoucherTableQuery, } from 'Apollo/graphql';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import { useVoucherStore, VoucherStore, } from 'Modules/Vouchers/store/VoucherStore';
import shallow from 'zustand/shallow';
import { mapVariablesVoucher, } from '../utils';
import VouchersTableHeader from './VouchersTableHeader';
import VouchersTableRow from './VouchersTableRow';

const selectStoreData = (s: VoucherStore) => ({
  tableVouchers: s.tableVouchers,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,
  onDetail: s.openModalDetailVoucher,
  onDelete: s.openModalRemoveVoucher,
});

const Vouchers = (): JSX.Element => {
  const { onDetail, onDelete, tableVouchers, changeFilter, changeSort, changeData, } = useVoucherStore(selectStoreData, shallow);

  const { loading, error, data, } = useVoucherTableQuery({
    variables: mapVariablesVoucher(tableVouchers),
    fetchPolicy: 'cache-and-network',
  });
  const availableTypes = useAvailableVoucherTypesQuery();

  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={11}
      rows={data?.vouchers.data.length || 0}
      loading={loading}
      error={error}
      limit={tableVouchers.data.limit}
      sortField={tableVouchers.data.sortField}
      sortOrder={tableVouchers.data.sortOrder}
      offset={tableVouchers.data.offset}
      count={data?.vouchers.pagination.count}
      filter={tableVouchers.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <VouchersTableHeader availableTypes={availableTypes.data?.getAvailableVoucherTypes} />
      <TableBody>
        {(data?.vouchers.data || []).map((row) => (
          <VouchersTableRow
            key={row.id}
            row={row}
            onDetail={onDetail}
            onDelete={onDelete}
            availableTypes={availableTypes.data?.getAvailableVoucherTypes}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default Vouchers;
