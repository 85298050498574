import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AssortmentOptionFragment, AssortmentCategoryWithAssortmentOptionFragment, useCurrentMarketQuery, } from 'Apollo/graphql';
import { FormInputProductCountMultiple, FormInputText, } from 'Components/Form';
import { getOptionFieldId, getOptionFieldName, } from 'Utils/helpers';
import { useFormContext, useWatch, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import ImageAsync from 'Components/ImageAsync';
import { styled, } from '@mui/material/styles';
import { positionUrl, } from 'Utils/deviceCodeImages';

const ImageWrapper = styled('div')(() => ({
  position: 'relative',
  minHeight: 200,
  width: '100%',
  '& > img': {
    position: 'relative',
  },
}));
interface Props {
  structure: AssortmentCategoryWithAssortmentOptionFragment | null,
};

const PurchaseForm = ({
  structure,
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  const { control, setValue,  } = useFormContext();
  const productList = useWatch({ name: `assortmentCategories.${structure?.id}`, control, }) as AssortmentOptionFragment[];
  const productOptionMax = productList?.length > 0 ? ()=>0 : undefined;
  const currentMarket = useCurrentMarketQuery();
  const deviceCodeImageUrl = positionUrl(currentMarket.data?.currentMarket);

  return (
    <div>
      {structure && structure.items.length > 0 ? (
        <Box mb={4}>
          <Typography
            variant="h3"
            color="primary"
            sx={{ mb: 2.5, }}
          >
            {structure.name}
          </Typography>
          <FormInputProductCountMultiple<AssortmentOptionFragment>
            name={`assortmentCategories.${structure.id}`}
            getOptionKey={getOptionFieldId}
            getOptionLabel={getOptionFieldName}
            options={structure.items || []}
            getOptionMax={structure?.requiresDeviceCodes ? productOptionMax : undefined}
          />
          {structure?.requiresDeviceCodes && <>
        <FormInputText sx={{mt:3,}}name="batchCode" label={t('replacementAdmin:createReplacementModal.phaseCreateTicket.deviceCode')} required />
        <Typography variant="body1" sx={{ mt: 3, mb: 3, }}>
          {t('replacementAdmin:createReplacementModal.phaseCreateTicket.deviceCodeInfo')}
        </Typography>
        <ImageWrapper>
          <ImageAsync src={deviceCodeImageUrl} />
        </ImageWrapper>
        </>}
        </Box>
      ) : null}  
    </div>
  );
};

export default PurchaseForm;
