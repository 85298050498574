import { useCallback, useEffect, useState, } from 'react';
import shallow from 'zustand/shallow';
import { useForm, FormProvider, useWatch, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useSearchCustomerByEmailLazyQuery, } from 'Apollo/graphql';
import { mapBadFormDataError, } from 'Components/Form';
import { CreateReplacementStore, useCreateReplacementStore, } from '../createReplacementStore';
import { initPhaseBadFormDataFieldMapper, phaseInitFormValidationSchema, PhaseInitFormValues, } from './utils';
import { SearchedCustomerByEmail, } from './types';
import View from './View';

const selectStoreData = (s: CreateReplacementStore) => ({
  replacementEmail: s.replacementEmail,
  completeInitPhase: s.completeInitPhase,
  initiateReplacementRegistration: s.initiateReplacementRegistration,
});

interface Props {
  onClose: () => void,
}

const PhaseInit = ({
  onClose,
}: Props): JSX.Element => {
  const {
    replacementEmail,
    completeInitPhase,
    initiateReplacementRegistration,
  } = useCreateReplacementStore(selectStoreData, shallow);

  const methods = useForm<PhaseInitFormValues>({
    defaultValues: { email: replacementEmail, },
    resolver: yupResolver(phaseInitFormValidationSchema),
    mode: 'onSubmit',
  });
  const [ searchedCustomerByEmail, setSearchedCustomerByEmail, ] = useState<SearchedCustomerByEmail | null>(null);

  const emailValue = useWatch({ control: methods.control, name: 'email', });
  useEffect(
    () => {
        setSearchedCustomerByEmail(null);
    },
    [emailValue,],
  );

  const [ searchCustomerByEmailQuery, searchCustomerByEmailResult, ] = useSearchCustomerByEmailLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result.searchCustomerByEmail) {
        setSearchedCustomerByEmail({
          isValid: true,
          customer: result.searchCustomerByEmail.customer || null,
          isVerifiedByGlobalApi: result.searchCustomerByEmail.isVerifiedByGlobalApi,
        });
      }
      else {
        setSearchedCustomerByEmail({
          isValid: false,
          customer: null,
          isVerifiedByGlobalApi: false,
        });
      }
    },
    onError: (responseError) => {
      mapBadFormDataError({
        apolloError: responseError,
        fieldMapper: initPhaseBadFormDataFieldMapper,
        methods,
      });
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await searchCustomerByEmailQuery({
      variables: {
        email: values.email,
      },
    });
  });

  const handleCreateTicketRegister = useCallback(
    () => {
      completeInitPhase(
        searchedCustomerByEmail?.customer ? {
          id: searchedCustomerByEmail.customer.id,
          dateOfBirth: searchedCustomerByEmail.customer.dateOfBirth ? new Date(searchedCustomerByEmail.customer.dateOfBirth) : null,
          email: searchedCustomerByEmail.customer.email,
          firstName: searchedCustomerByEmail.customer.firstName,
          lastName: searchedCustomerByEmail.customer.lastName,
          phoneNumber: searchedCustomerByEmail.customer.phoneNumber,
          phonePrefix: searchedCustomerByEmail.customer.phonePrefix,
        } : null,
        emailValue,
        searchedCustomerByEmail?.isVerifiedByGlobalApi || false,
      );
    },
    [ completeInitPhase, searchedCustomerByEmail, emailValue, ],
  );
  const createTicketReplacementRegistration = useCallback(
    () => {
      initiateReplacementRegistration(emailValue);
    },
    [ initiateReplacementRegistration, emailValue,],
  );
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <View
          searchedCustomerByEmail={searchedCustomerByEmail}
          searchCustomerByEmailResult={searchCustomerByEmailResult}
          createTicketRegister={handleCreateTicketRegister}
          createTicketReplacementRegistration={createTicketReplacementRegistration}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default PhaseInit;
