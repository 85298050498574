import { ReactNode, } from 'react';
import Drawer from '@mui/material/Drawer';


interface Props {
  children: ReactNode,
  isOpen: boolean,
  onClose: () => void,
};


const drawerWidth = 240;


const MobileMenu = ({
  children,
  isOpen,
  onClose,
}: Props): JSX.Element => {
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      sx={{
        width: drawerWidth,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      {children}
    </Drawer>
  );
};


export default MobileMenu;
