import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { TaskStore, useTaskStore, } from 'Modules/TasksAdmin/store/GeneralTasksStore';
import shallow from 'zustand/shallow';
import Logic from './Logic';

const selectStoreData = (s: TaskStore) => ({
  selectModalData: s.modalDeleteTask,
  onClose: s.closeModalDeleteTask,
});

const ModalDeleteTask = (): JSX.Element => {
  const {
    selectModalData,
    onClose,
  } = useTaskStore(selectStoreData, shallow);
  const { isOpen, task,} = selectModalData;

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!task}
    >
      <Logic
        task={task}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalDeleteTask;
