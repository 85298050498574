import { Outlet, } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import TopPanel from './TopPanel';
import Footer from './Footer';

const AppLayoutNoUser = (): JSX.Element => {
  return (
    <Box 
      component="main"
      display="flex"
      flexDirection="column"
      justifyContent='space-between'
      minHeight='100vh'
    >
      <div>
        <TopPanel />
        <Toolbar />
        <Outlet />
      </div>
      <Footer />    
    </Box>
  );
};

export default AppLayoutNoUser;
