import { ReplacementDetailFragment, } from 'Apollo/graphql';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { REPLACEMENT_STATUS_OPTIONS, ReplacementStatusOption, } from 'Utils/options/useOptionsReplacementStatus';
import { useMemo, } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { isHungarianMarket, } from 'Utils/market';

export type ReplacementFormValues = {
  status: ReplacementStatusOption | null;
};

export const initReplacementFormValues: ReplacementFormValues = {
  status: null,
};

export const replacementFormValidationSchema = yup.object().shape({
  status: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
});

export const useInitReplacementValues = (replacement: ReplacementDetailFragment): ReplacementFormValues =>
  useMemo<ReplacementFormValues>(
    () => ({
      status: REPLACEMENT_STATUS_OPTIONS.filter((item) => item.id === replacement.status)[0],
    }),
    [replacement,],
  );

export const formatDate = (date: Date | undefined | null) => {
  if (!date) {
    return '';
  }
  const format = isHungarianMarket ? 'YYYY.MM.DD HH:MM:SS' : 'DD.MM.YYYY HH:MM:SS';
  return moment(date).format(format);
};
