import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { useTableContext, } from './hooks/useTableContext';

const StyledTableBody = styled(MuiTableBody)(({ theme, }) => ({
  position: 'relative',
}));

const LoaderContainer = styled(MuiTableRow)(({ theme, }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 10,
  background: '#0000001a',

  '& .MuiTableCell-root': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

interface Props {
  children: ReactNode,
}

const TableBody = ({
  children,
}: Props): JSX.Element => {
  const {
    loading,
    error,
    colSpan,
    rows,
  } = useTableContext();
  const { t, } = useTranslation();

  if (error && !loading) return (
    <StyledTableBody>
      <MuiTableRow>
        <MuiTableCell
          colSpan={colSpan}
          padding="none"
        >
          <ErrorAlert
            sx={{
              '& .MuiAlert-root': {
                padding: '30px 24px',
              },
            }}
            forceError
            error={error}
          />
        </MuiTableCell>
      </MuiTableRow>
    </StyledTableBody>
  );

  return (
    <StyledTableBody>
      {(rows < 1) ? (
        <MuiTableRow>
          <MuiTableCell
            colSpan={colSpan}
          >
            <Typography
              marginTop={4}
              marginBottom={4}
              textAlign="center"
            >
              {t('common:table.noData')}
            </Typography>
          </MuiTableCell>
        </MuiTableRow>
      ) : (
        children
      )}
      <Fade in={loading}>
        <LoaderContainer>
          <MuiTableCell colSpan={6}>
            <CircularProgress size={25} />
          </MuiTableCell>
        </LoaderContainer>
      </Fade>
    </StyledTableBody>
  );
};


export default TableBody;
