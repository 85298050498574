import { VoucherPurchaseStatus, } from 'Apollo/graphql';
import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type StatusOption = {
  id: VoucherPurchaseStatus,
  labelKey: string,
};

export const VOUCHER_PURCHASE_STATUS_OPTIONS: StatusOption[] = [
  {
    id: VoucherPurchaseStatus.Denied,
    labelKey: 'vouchersAdmin:purchases.denied',
  },
  {
    id: VoucherPurchaseStatus.Redeemed,
    labelKey: 'vouchersAdmin:purchases.redeemed',
  },
  {
    id: VoucherPurchaseStatus.Requested,
    labelKey: 'vouchersAdmin:purchases.requested',
  },
];

export const findVoucherPurchaseStatusOption = (status: VoucherPurchaseStatus)=> VOUCHER_PURCHASE_STATUS_OPTIONS.find((option)=>option.id===status) as StatusOption;

type GetOptionLabel = (o: StatusOption) => string;
type GetOptionKey = (o: StatusOption) => string;

type UseVoucherPurchaseStatusOption = () => {
  options: StatusOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<StatusOption>, 
};

export const useVoucherPurchaseStatusOption: UseVoucherPurchaseStatusOption = () => {
  const { t, } = useTranslation();
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: VOUCHER_PURCHASE_STATUS_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
