import Img from 'react-cool-img';
import { styled, } from '@mui/material/styles';

const StyledImage = styled(Img)(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  objectPosition: 'center',
}));

interface Props {
  className?: string,
  src?: string | null,
  placeholder?: string | null,
  error?: string | null,
}

const ImageAsync = ({
  className,
  src,
  placeholder,
  error,
}: Props): JSX.Element => {
  return (
    <StyledImage
      className={className}
      src={src}
      placeholder={placeholder}
      error={error}
    />
  );
};

export default ImageAsync;
