interface Props {
  videoSrc?: string
}

const VideoPlayer = ({ videoSrc, }: Props) => (
  <video
    width="100%"
    controls
    style={{ borderRadius: '4px', }}
  >
    <source src={videoSrc} type="video/mp4"/>
    <track kind="captions" />
  </video>
);

export default VideoPlayer;
