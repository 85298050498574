import Grid from '@mui/material/Grid';
import { FormInputCheckbox, FormInputPhoneNumber, FormInputText, } from 'Components/Form';
import { useTranslation, } from 'react-i18next';


const EditMyTaxInformationPIT = (): JSX.Element => {
  const { t, } = useTranslation('myProfile');
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormInputText
          name="taxNumber"
          label={t('formPIT.NIP')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="email"
          label={t('formPIT.email')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputPhoneNumber
          namePrefix="phonePrefix"
          namePhone="phoneNumber"
          label={t('formPIT.phone')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="accountNumber"
          label={t('formPIT.accountNumber')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputCheckbox name="RODOConsent" required label={t('formInvoice.RODOConsent')} />
      </Grid>
    </Grid>
  );
};

export default EditMyTaxInformationPIT;
