import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSnackbar, } from 'notistack';
import { ReplacementDetailFragment, ReplacementTableFragment, useDeleteReplacementMutation, } from 'Apollo/graphql';
import { updateReplacementRemove, } from 'Modules/ReplacementAdmin/utils/replacementsCache';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import View from './View';

interface Props {
  replacement?: ReplacementTableFragment | ReplacementDetailFragment | null,
  onCloseRemove: () => void,
  onCloseDetail: () => void,
  onCloseEdit: () => void,
};

const Logic = ({
  replacement,
  onCloseRemove,
  onCloseDetail,
  onCloseEdit,
}: Props): JSX.Element => {
  const preservedReplacement = usePreserveValue(replacement);
  const { t, } = useTranslation('replacementAdmin');
  const { enqueueSnackbar, } = useSnackbar();

  const [ removeMutation, removeMutationResult, ] = useDeleteReplacementMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('removeReplacementModal.notifRemovedTitle') as string,
        message: t('removeReplacementModal.notifRemovedMessage', { replacementId: preservedReplacement.id, }),
        variant: 'common',
      });
      onCloseRemove();
      onCloseDetail();
      onCloseEdit();
    },
    update: updateReplacementRemove,
  });

  const handleSubmit = useCallback(
    () => {
      removeMutation({
        variables: { id: preservedReplacement.id, },
      });
    },
    [ removeMutation, preservedReplacement.id, ],
  );

  return (
    <View
      replacement={preservedReplacement}
      removeMutationResult={removeMutationResult}
      onClose={onCloseRemove}
      onSubmit={handleSubmit}
    />
  );
};

export default Logic;
