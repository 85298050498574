import { yupResolver, } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled, } from '@mui/material/styles';
import { SodexoPurchaseErrorEnum, VoucherQuery, useCreateSodexoVoucherPurchaseMutation, } from 'Apollo/graphql';
import { FormError, FormInputInt, FormInputText, } from 'Components/Form';
import FormSubmit from 'Components/Form/components/FormSubmit';
import ImageAsync from 'Components/ImageAsync';
import { ROUTES, } from 'Utils/constants';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { useState, } from 'react';
import { sodexoPurchaseDefaultValues, validationSchema, } from './utils';

type Props = {
  data: VoucherQuery;
};

const Item = styled(Paper)(({ theme, }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.5, 2),
  marginBottom: '1rem',
}));

const StyledChip = styled(Chip)(({ theme, }) => ({
  color: 'white',
  background: theme.palette.primary.main,
  fontVariant: 'h1',
  alignSelf: 'flex-end',
  marginBottom: '1rem',
}));

const SodexoView: React.FC<Props> = ({ data, }) => {
  const { t, } = useTranslation('myPoints');
  const navigate = useNavigate();
  const backToPoints = () => navigate(`/${ROUTES.myPoints}`);

  const [isAddressValid, setIsAddressValid,] = useState<boolean|null>(null);
  
  const form = useForm({
    defaultValues: sodexoPurchaseDefaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
    
  });
  

  const [createMutation, response,] = useCreateSodexoVoucherPurchaseMutation({
    onCompleted: () => {},
    onError: () => {
      // TODO: merge form errors
    },
  });

  const handleSubmit = form.handleSubmit(async (values, event) => {
    const {valid,} = window.Foxentry.formValidation(event?.target);
    
    if (valid) { 
      await createMutation({
          variables: {
            input: {
              voucherId: data.voucher.id,
              voucherPriceFiat: values.voucherPriceFiat ?? 0,
              voucherAddress:  values.streetAndStreetNumber,
              voucherAddressCity: values.city,
              voucherAddressRecipientName: values.name,
              voucherAddressPostalCode: values.postalCode,
              notificationsVisible: false,
            },
          },
      });
    } else {
      setIsAddressValid(false);
    }
  });

  if (response.data?.createSodexoVoucherPurchase?.success) {
    return (
      <>
        <Typography variant="h1" mb={1.5}>
          {t('voucher.detailTitlePurchased')}
        </Typography>
        <Typography variant="inherit" mb={4}>
          {t('voucher.sodexo.detailDescriptionPurchased')}
        </Typography>
        <Button size="large" fullWidth variant="contained" color="primary" onClick={backToPoints}>
          {t('common:actions.done')}
        </Button>
      </>
    );
  }
  if (response.data?.createSodexoVoucherPurchase?.reason === SodexoPurchaseErrorEnum.Noemail) {
    return (
      <>
        <Typography variant="h1" mb={1.5}>
          {t('voucher.detailTitlePurchaseFailed')}
        </Typography>
        <Typography variant="inherit" mb={4}>
          {t('voucher.sodexo.missingEmail')}
        </Typography>
        <Button size="large" fullWidth variant="contained" color="primary" onClick={backToPoints}>
          {t('common:actions.done')}
        </Button>
      </>
    );
  }
  if (response.data?.createSodexoVoucherPurchase?.reason === SodexoPurchaseErrorEnum.Novouchers) {
    return (
      <>
        <Typography variant="h1" mb={1.5}>
          {t('voucher.detailTitlePurchaseFailed')}
        </Typography>
        <Typography variant="inherit" mb={4}>
          {t('voucher.noVouchers')}
        </Typography>
        <Button size="large" fullWidth variant="contained" color="primary" onClick={backToPoints}>
          {t('common:actions.done')}
        </Button>
      </>
    );
  }

  if (response.data?.createSodexoVoucherPurchase?.reason === SodexoPurchaseErrorEnum.Nopoints) {
    return (
      <>
        <Typography variant="h1" mb={1.5}>
          {t('voucher.detailTitlePurchaseFailed')}
        </Typography>
        <Typography variant="inherit" mb={4}>
          {t('voucher.sodexo.noPoints')}
        </Typography>
        <Button size="large" fullWidth variant="contained" color="primary" onClick={backToPoints}>
          {t('common:actions.done')}
        </Button>
      </>
    );
  }

  const voucherPriceFiat = form.watch('voucherPriceFiat') ?? 0;

  return (
    <>
      <Typography variant="h1" mb={1.5}>
        {t('voucher.detailTitle')}
      </Typography>
      <Item>
        <StyledChip label={`${voucherPriceFiat} ${t('voucher.points')}`} />
        <ImageAsync src={data.voucher.image?.publicUrl} />
        <Box display="flex" justifyContent="space-between" flexDirection="column">
          <Typography mt={2} variant="h1" color="primary">
            {data.voucher.name}
          </Typography>
          <Typography mt={2} mb={2} variant="inherit">
            {data.voucher.description}
          </Typography>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit} noValidate autoComplete="off" onChange={()=> {
              setIsAddressValid(null);
            }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormInputInt name="voucherPriceFiat" label={t('voucher.sodexo.voucherPriceFiatInput')} required />
                </Grid>
                <Grid item xs={12}>
                  <FormInputText name="name" label={t('voucher.sodexo.voucherAddressRecipient')} required />
                </Grid>
                <Grid item xs={12}>
                  <FormInputText name="streetAndStreetNumber" label={t('voucher.sodexo.voucherAddressInput')} required />
                </Grid>
                <Grid item xs={12}>
                  <FormInputText name="city" label={t('voucher.sodexo.voucherAddressCity')} required />
                </Grid>
                <Grid item xs={12}>
                  <FormInputText name="postalCode" label={t('voucher.sodexo.voucherAddressPostalCode')} required />
                </Grid>
                <Grid item xs={12} marginBottom={1} marginTop={1}>
                  <Box display="flex" justifyContent="flex-end">
                    <FormSubmit size="large">{t('voucher.purchase')}</FormSubmit>
                  </Box>
                </Grid>
              </Grid>
              <FormError mt={2} />
              <ErrorAlert mt={2} error={response.error} 
              customError={isAddressValid === false ? {text: t('common:errors.given_address_not_valid_text'), title: t('common:errors.given_address_not_valid_title'),} : undefined}
              />
            </form>
          </FormProvider>
        </Box>
      </Item>
      <Button size="large" fullWidth variant="outlined" color="inherit" onClick={backToPoints}>
        {t('common:actions.back')}
      </Button>
    </>
  );
};

export default SodexoView;
