import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useSnackbar, } from 'notistack';
import { NewsArticleFragment, NewsArticleTableFragment, useUpdateNewsArticleMutation, } from 'Apollo/graphql';
import {
  newsArticleFormValidationSchema,
  mapFormToVariables,
  useInitNewsArticleValues,
} from '../../utils/newsArticleForm';
import View from './View';

interface Props {
  newsArticle: NewsArticleFragment,
  onClose: () => void,
  onOpenRemove: (newsArticle: NewsArticleFragment | NewsArticleTableFragment) => void,
};

const Logic = ({
  newsArticle,
  onClose,
  onOpenRemove,
}: Props): JSX.Element => {
  const { t, } = useTranslation('newsArticlesAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const initValues = useInitNewsArticleValues(newsArticle);
  const methods = useForm({
    defaultValues: initValues,
    resolver: yupResolver(newsArticleFormValidationSchema),
    mode: 'onTouched',
  });

  const [ editMutation, editMutationResult, ] = useUpdateNewsArticleMutation({
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('editNewsArticleModal.notifEditedTitle') as string,
        message: t('editNewsArticleModal.notifEditedMessage', { id: response.updateNewsArticle.id, }),
        variant: 'common',
      });
      onClose();
    },
    onError: () => {
      // TODO: merge form errors
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await editMutation({
      variables: {
        id: newsArticle.id,
        newsArticleInput: mapFormToVariables(values),
      },
    });    
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View
          newsArticle={newsArticle}
          editMutationResult={editMutationResult}
          onClose={onClose}
          onOpenRemove={onOpenRemove}
        />
      </form>
    </FormProvider>
  );
};

export default Logic;
