import { useState, } from 'react';
import { ImportState, } from './types';
import FormLogic from './FormLogic';
import SuccessImport from './SuccessImport';

interface Props {
  onClose: () => void,
};

const Layout = ({
  onClose,
}: Props): JSX.Element => {
  const [ importedState, setImportState, ] = useState<ImportState | null>(null);
  
  return (importedState?.isImported) ? (
    <SuccessImport
      importState={importedState}
      onClose={onClose}
    />
  ) : (
    <FormLogic
      setImportState={setImportState}
      onClose={onClose}
    />
  );
};

export default Layout;
