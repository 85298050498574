import { Claim, } from 'Apollo/graphql';
import { ROUTES, } from 'Utils/constants';
import AdministrationColoredIcon from 'Utils/svg/AdministrationColoredIcon';
import GiftCodesIcon from 'Utils/svg/GiftCodesIcon';
import POSColoredIcon from 'Utils/svg/POSColoredIcon';
import UsersColoredIcon from 'Utils/svg/UsersColoredIcon';
import ReplacementsColoredIcon from 'Utils/svg/ReplacementsColoredIcon';
import RegistrationsColoredIcon from 'Utils/svg/RegistrationsColoredIcon';
import TasksColoredIcon from 'Utils/svg/TasksColoredIcon';
import DashboardChainsIcon from 'Utils/svg/DashboardChainsIcon';
import DashboardPersonalDashboardIcon from 'Utils/svg/DashboardPersonalDashboardIcon';
import { MenuItem, } from '../types';

export const DASHBOARD_MENU_ITEMS: MenuItem[] = [
  {
    id: 'personalDashboard',
    exact: true,
    icon: <DashboardPersonalDashboardIcon sx={{ color: 'transparent', }} />,
    route: `/${ROUTES.dashboard}`,
    textKey: 'common:dashboardSidebar.personalDashboard',
    claims: [Claim.AdminApp,],
    flag: null,
  },
  {
    id: 'users',
    exact: false,
    icon: <UsersColoredIcon />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardUsers}`,
    textKey: 'common:dashboardSidebar.users',
    claims: [
      Claim.DashboardUsersUserRoles,
      Claim.DashboardUsersActiveUsers,
      Claim.DashboardUsersTotalNumberOfUsers,
    ],
    flag: null,
  },
  {
    id: 'chains',
    exact: false,
    icon: <DashboardChainsIcon sx={{ color: 'transparent', }} />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardChains}`,
    textKey: 'common:dashboardSidebar.chains',
    claims: [
      Claim.DashboardChainsTop_10Replacements,
      Claim.DashboardChainsTop_10Registrations,
      Claim.DashboardChainsActiveChains,
      Claim.DashboardChainsTotalNumberOfChains,
    ],
    flag: null,
  },
  {
    id: 'pos',
    exact: false,
    icon: <POSColoredIcon />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardPos}`,
    textKey: 'common:dashboardSidebar.pos',
    claims: [
      Claim.DashboardPointsOfSaleTop_10Registrations,
      Claim.DashboardPointsOfSaleTop_10Replacements,
      Claim.DashboardPointsOfSaleTotalNumberOfPos,
      Claim.DashboardPointsOfSaleActivePos,
    ],
    flag: null,
  },
  {
    id: 'registrations',
    exact: false,
    icon: <RegistrationsColoredIcon />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardRegistrations}`,
    textKey: 'common:dashboardSidebar.registrations',
    claims: [
      Claim.DashboardRegistrationsMethodOfVerification,
      Claim.DashboardRegistrationsPurchasedProduct,
      Claim.DashboardRegistrationsConsumerAgeGroups,
      Claim.DashboardRegistrationsPreviouslyOwnedDevice,
      Claim.DashboardRegistrationsReDoi,
      Claim.DashboardRegistrationsTop_10Cities,
      Claim.DashboardRegistrationsConsumerTitleGender,
      Claim.DashboardRegistrationsTotalNumberOfRePurchases,
      Claim.DashboardRegistrationsTotalNumberOfRegistrations,
    ],
    flag: null,
  },
  {
    id: 'replacements',
    exact: false,
    icon: <ReplacementsColoredIcon />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardReplacements}`,
    textKey: 'common:dashboardSidebar.replacements',
    claims: [
      Claim.DashboardReplacementsTotalNumberOfReplacements,
      Claim.DashboardReplacementsTop_10ReasonsForReplacement,
    ],
    flag: null,
  },
  {
    id: 'tasksAndPoints',
    exact: false,
    icon: <TasksColoredIcon />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardTasksAndPoints}`,
    textKey: 'common:dashboardSidebar.tasksAndPoints',
    claims: [
      Claim.DashboardPointsAndTasksNumberOfTasksCompletions,
      Claim.DashboardPointsAndTasksNumberOfActiveTasks,
      Claim.DashboardPointsAndTasksPointsCategory,
      Claim.DashboardPointsAndTasksTotalNumberOfPoints,
    ],
    flag: null,
  },
  {
    id: 'giftCodes',
    exact: false,
    icon: <GiftCodesIcon />,
    route: `/${ROUTES.dashboard}/${ROUTES.dashboardGiftCodes}`,
    textKey: 'common:dashboardSidebar.giftCodes',
    claims: [
      Claim.DashboardGiftCodesTotalNumberOfSendGiftCodesByChain,
      Claim.DashboardGiftCodesTotalNumberOfAvailableGiftCodes,
    ],
    flag: 'FEATURE_FLAG_ENABLE_GIFT_CODES_DASHBOARD',
  },
  {
    id: 'administration',
    exact: false,
    icon: <AdministrationColoredIcon />,
    route: `/${ROUTES.admin}`,
    textKey: 'common:sidebar.administration',
    claims: [Claim.AdminApp,],
    flag: null,
  },
];
