import { darken } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from 'Utils/svg/CloseIcon';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
  points: number;
  headingIcon: ReactNode;
};

const PointsContent = ({ points, headingIcon }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation('prizeWheel');
  const handleClose = (): void => {
    navigate('/');
  };

  return (
    <Dialog open onClose={() => {}} sx={{ backdropFilter: 'blur(8px)' }} maxWidth="sm" fullWidth>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          padding: '50px',
        }}
      >
        <Stack spacing="10px" sx={{ alignItems: 'center' }}>
          <Box>{headingIcon}</Box>
          <Box>
            <Typography variant="h1">{t('prize.heading')}</Typography>
          </Box>
          <Box>
            <Typography variant="footer">{t('prize.points', { points })}</Typography>
          </Box>
          <Button
            type="submit"
            variant="xmasWheel"
            size="medium"
            onClick={() => {
              navigate('/myPoints');
            }}
            sx={{
              position: 'unset',
              alignSelf: 'center',
              fontWeight: '700',
              padding: '0.875rem 2.5rem',
              marginY: '1.25rem',
            }}
          >
            {t('prize.button')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PointsContent;
