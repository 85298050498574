import { ReactNode, } from 'react';
import TableCell, { TableCellProps, } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { OrderBy, } from 'Apollo/graphql';
import { useTableContext, } from './hooks/useTableContext';

interface Props extends TableCellProps {
  children: ReactNode,
  name: string,
};

const TableCellSortable = ({
  children,
  name,
  ...rest
}: Props): JSX.Element => {
  const {
    sortField,
    sortOrder,
    onChangeSort,
  } = useTableContext();

  const isActive = (name === sortField);

  const handleClick = () => {
    const newOrder: OrderBy = isActive
      ? (sortOrder === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc)
      : OrderBy.Asc;
      onChangeSort(name, newOrder);
  };

  return (
    <TableCell {...rest}>
      <TableSortLabel
        active={isActive}
        direction={sortOrder || undefined}
        onClick={handleClick}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

export default TableCellSortable;
