import { BinaryOperator, Query, } from '@cubejs-client/core';
import { QueryParams, } from 'Components/Dashboard/types';

export const topPosInRegistrationsQuery = ({
  timeDimensions,
  filters = [],
}: QueryParams): Query => {
  const query = {
    timeDimensions: [
      {
        dimension: 'customer.createdat',
        dateRange: timeDimensions?.[0].dateRange,
      },
    ],
    order: {
      'customer.total_registrations': 'desc' as const,
    },
    measures: ['customer.total_registrations',],
    dimensions: ['point_of_sell.name',],
    filters: [
      ...filters,
      {
        member: 'customer.posId',
        operator: 'notEquals' as const,
        values: ['GLOBAL-API',],
      },
    ],
  };
  return query;
};

export const totalNumberOfPosQuery = ({
  filters = [],
}: QueryParams): Query => ({
  measures: ['point_of_sell.count',],
  timeDimensions: [
    {
      dimension: 'point_of_sell.created_at',
    },
  ],
  filters,
});

export const topPosInReplacementsQuery = ({
  timeDimensions,
  filters = [],
  showNullValues = false,
}: QueryParams): Query => {
  const query = {
    order: {
      'replacement.count': 'desc' as const,
    },
    measures: ['replacement.count',],
    timeDimensions: [
      {
        dimension: 'replacement.createdat',
        dateRange: timeDimensions?.[0]?.dateRange,
      },
    ],
    dimensions: ['replacement.point_of_sell__name',],
    filters,
  };
  if (!showNullValues) {
    // ignored because the filter is generated by cubejs and is valid but typescript doesn't recognize it
    // @ts-ignore
    query.filters?.push({
      member: 'replacement.point_of_sell__name',
      operator: 'set' as BinaryOperator,
    });
  }
  return query;
};

export const activeInactivePosQuery = (): Query => ({
  timeDimensions: [
    {
      dimension: 'point_of_sell.created_at',
    },
  ],
  measures: ['point_of_sell.count',],
  dimensions: ['point_of_sell.isActive',],
  order: {
    'point_of_sell.count': 'desc',
  },
});
