import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AppContainer from 'Components/AppContainer';
import React from 'react';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { ROUTES, } from 'Utils/constants';

type Props = {
  removalMutation: () => void;
};

const DeleteForm = ({ removalMutation, }: Props): JSX.Element => {
  const navigate = useNavigate();
  const backToProfile = () => navigate(`/${ROUTES.myProfile}`);
  const { t, } = useTranslation('myProfile');
  return (
    <AppContainer maxWidth="sm">
      <Typography
        variant="h1"
        sx={{
          marginBottom: 1.5,
        }}
      >
        {t('deleteMyProfile.title')}
      </Typography>
      <Typography
        variant="inherit"
        sx={{
          marginBottom: 5,
        }}
      >
        {t('deleteMyProfile.description')}
      </Typography>
      <Button sx={{ marginRight: 2, }} variant="contained" color="error" onClick={() => removalMutation()}>
        {t('deleteMyProfile.btnDelete')}
      </Button>
      <Button variant="outlined" color="inherit" onClick={backToProfile}>
        {t('common:actions.cancel')}
      </Button>
    </AppContainer>
  );
};

export default DeleteForm;
