import { INPUT_FORM_ERRORS, } from 'Components/Form';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  voucherPriceFiat: yup
    .number()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .min(100, INPUT_FORM_ERRORS.INT_MIN)
    .test(
      'multiple-of-100',
      () => {
        return INPUT_FORM_ERRORS.MULTIPLE_OF({ number: 100, });
      },
      (value) => {
        if (typeof value !== 'number') {
          return false;
        }
        if (value % 100 !== 0) {
          return false;
        }
        return true;
      },
    ),
    name: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(2047, INPUT_FORM_ERRORS.STRING_MAX),
    streetAndStreetNumber: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(2047, INPUT_FORM_ERRORS.STRING_MAX),
    city: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(2047, INPUT_FORM_ERRORS.STRING_MAX),
    postalCode: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(2047, INPUT_FORM_ERRORS.STRING_MAX),
});

type SodexoPurchaseFormType = {
  voucherPriceFiat: number | null;
  name: string;
  streetAndStreetNumber: string;
  city: string;
  postalCode: string;
};

export const sodexoPurchaseDefaultValues: SodexoPurchaseFormType = {
  voucherPriceFiat: 100,
  name: '',
  streetAndStreetNumber: '',
  city: '',
  postalCode: '',
};
