import { AssortmentSortInput, PaginationInput, AssortmentFilterInput, } from 'Apollo/graphql';
import { AssortmentStore, } from 'Modules/AssortmentAdmin/store/AssortmentStore';

export const mapVariables = (storeData: AssortmentStore['tableAssortments']): {
  filter?: AssortmentFilterInput,
  pagination?: PaginationInput,
  sort?: AssortmentSortInput,
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    id: storeData.filter.id 
      ? { contains: storeData.filter.id, }
      : undefined,
    name: storeData.filter.name
      ? { contains: storeData.filter.name, }
      : undefined,
    categoryId: storeData.filter.category?.id
      ? { equals: storeData.filter.category?.id, }
      : undefined,
    description: storeData.filter.description
      ? { contains: storeData.filter.description, }
      : undefined,
  },
  sort: storeData.data.sortField && storeData.data.sortOrder
    ? {
      field: storeData.data.sortField,
      order: storeData.data.sortOrder,
    } : undefined,
});
