import { useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { FeatureFlagObject, FeatureFlagObjectFragment, NotificationType, TaskTypeEnum, } from 'Apollo/graphql';
import AppContainer from 'Components/AppContainer';
import { useAuthUser, } from 'Tools/auth';
import { useDelayedResetNotificationCategoryCount, } from 'Tools/useDelayedResetNotificationCategoryCount';
import TaskList from './TaskList';

type TabComponent = {
  flag: keyof FeatureFlagObject;
  section: TaskTypeEnum;
  label: 'tasksSeller:taskList.obligatory.title' | 'tasksSeller:taskList.additional.title' | 'tasksSeller:taskList.eLearning.title';
};

const taskTypeArray: TabComponent[] = [
  { flag: 'FEATURE_FLAG_ENABLE_OBLIGATORY_TASKS', section: TaskTypeEnum.Obligatory, label: 'tasksSeller:taskList.obligatory.title', },
  { flag: 'FEATURE_FLAG_ENABLE_ADDITIONAL_TASKS', section: TaskTypeEnum.Additional, label: 'tasksSeller:taskList.additional.title', },
  { flag: 'FEATURE_FLAG_ENABLE_ELEARNING_TASKS', section: TaskTypeEnum.Elearning, label: 'tasksSeller:taskList.eLearning.title', },
];

const TasksSellerPage = (): JSX.Element => {
  const { t, } = useTranslation();
  const authUser = useAuthUser();

  useDelayedResetNotificationCategoryCount(NotificationType.Task);

  const [ activeTab, setActiveTab, ] = useState<number>(0);
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => setActiveTab(newValue);
  const availableTypes = taskTypeArray.filter((type) => authUser?.featureFlagsObject[type.flag as keyof FeatureFlagObjectFragment]);

  return (
    <AppContainer maxWidth="sm">

      <Typography variant="h1" mb={1.5}>
        {t('tasksSeller:taskList.generic.title')}
      </Typography>
      <Typography mb={3}>{t('tasksSeller:taskList.generic.description')}</Typography>

      <Tabs value={activeTab} onChange={handleChange}>
        {availableTypes.map((type) => (
          <Tab key={type.label} label={t(type.label)} />
        ))}
      </Tabs>

      <TaskList taskType={availableTypes[activeTab].section} />

    </AppContainer>
  );
};

export default TasksSellerPage;
