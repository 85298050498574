import { useTranslation, } from 'react-i18next';
import { useWatch, useFormContext, } from 'react-hook-form';
import { Link as RouterLink, } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { FileFragment, ReviewTaskCompletionMutationResult, TaskCompletionReviewFragment, } from 'Apollo/graphql';
import { ROUTES, } from 'Utils/constants';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { TaskCompletionStatusOption, useOptionsTaskCompletionStatus, } from 'Utils/options/useOptionsTaskCompletionStatus';
import { getOptionFieldColor, } from 'Utils/helpers';
import { FormError, FormInputButtonSelect, FormSubmit, } from 'Components/Form';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { getOptionTaskMethodById, } from 'Utils/options/useOptionTaskMethod';
import { getOptionTaskTypeById, } from 'Utils/options/useOptionTaskType';
import PhotoList from './PhotoList';

interface Props {
  taskCompletion: TaskCompletionReviewFragment,
  taskCompletionFiles: FileFragment[],
  reviewMutationResult: ReviewTaskCompletionMutationResult,
};

const Layout = ({
  taskCompletion,
  taskCompletion: {
    task,
    author,
    status,
  },
  taskCompletionFiles,
  reviewMutationResult,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const { control, } = useFormContext();
  const taskCompletionStatus = useOptionsTaskCompletionStatus();
  
  const formStatus: TaskCompletionStatusOption | null = useWatch({ name: 'status', control, });
  const isStatusChanged = (status || null) !== (formStatus?.id || null);

  return (
    <>
      <Typography variant="body1" mb={4}>
        {t('review.info', {
          method: t(getOptionTaskMethodById(task.assessmentMethod)?.labelKey || task.assessmentMethod),
          type: t(getOptionTaskTypeById(task.type)?.labelKey || task.type),
        })}
      </Typography>

      <Paper
        sx={{ p: 2, mb: 4, }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('review.labelUserName')}
            >
              {author.username}
            </NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('review.labelPosId')}
            >
              {author.posId || "-"}
            </NotEditableTextClassic>
          </Grid>
        </Grid>
      </Paper>
      
      <Paper
        sx={{ p: 2, mb: 4, }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('review.labelName')}
            >
              {task.name}
            </NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('review.labelDescription')}
            >
              <div style={{ whiteSpace: 'pre-wrap', }}>
                {task.description}
              </div>
            </NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('review.labelPhotos')}
            >
              <PhotoList
                taskCompletion={taskCompletion}
                taskCompletionFiles={taskCompletionFiles}
              />
            </NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <FormInputButtonSelect<TaskCompletionStatusOption>
              name="status"
              required
              label={t('review.labelStatus')}
              options={taskCompletionStatus.options}
              getOptionKey={taskCompletionStatus.getOptionKey}
              getOptionLabel={taskCompletionStatus.getOptionLabel}
              getOptionColor={getOptionFieldColor}
            />
          </Grid>
        </Grid>
      </Paper>
      
      <Grid container spacing={1} alignItems="center">
        {isStatusChanged && (
          <Grid item>
            <FormSubmit
              size="large"
            >
              {t('review.btnSubmit')}
            </FormSubmit>
          </Grid>
        )}
        <Grid item>
          <Button
            component={RouterLink}
            to={`/${ROUTES.admin}/${ROUTES.tasks}`}
            size="large"
            variant="outlined"
            color="inherit"
          >
            {t('detail.btnClose')}
          </Button>
        </Grid>
      </Grid>

      <FormError mt={2} />
      <ErrorAlert
        mt={2}
        error={reviewMutationResult.error}
      />
    </>
  );
};

export default Layout;
