import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { useUsesLatestVersionQuery } from "Apollo/graphql";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function NewVersionAvailableModal() {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { t } = useTranslation('versionCheck');

  useUsesLatestVersionQuery({
    variables: {
      usedVersion: String(import.meta.env.VITE_APP_VERSION),
    },
    pollInterval: 1000 * 60 * 15,
    skip: dialogOpen,
    onCompleted: (data) => {
      setDialogOpen(!data.usesLatestVersion)
    }
  })

  return <Dialog open={dialogOpen} onClose={() => { }} sx={{ backdropFilter: 'blur(8px)', }}>
    <DialogContent sx={{ padding: '50px', }}>
      <Box>
        <Typography variant="h1" color="secondary" textAlign="center">
          {t('message')}
        </Typography>
      </Box>
    </DialogContent>
  </Dialog>

}
