import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useSnackbar, } from 'notistack';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { useChangeUserPasswordMutation, UserFragment, } from 'Apollo/graphql';
import * as yup from 'yup';
import View from './View';

const schema = yup.object().shape({
  newPassword: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED),
});

interface Props {
  user: UserFragment,
  onClose: () => void,
};

const Logic = ({
  user,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('users');
  const { enqueueSnackbar, } = useSnackbar();
  const methods = useForm({
    defaultValues: {
      newPassword: '',
    },
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  const [ changePasswordMutation, changePasswordMutationResult, ] = useChangeUserPasswordMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('changePasswordModal.notifChangeTitle') as string,
        message: t('changePasswordModal.notifChangeMessage'),
        variant: 'common',
      });
      onClose();
    },
    onError: () => {
      // TODO: merge form errors
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await changePasswordMutation({
      variables: {
        input: {
          userId: user.id,
          newPassword: values.newPassword,
        },
      },
    });    
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <View
          changePasswordMutationResult={changePasswordMutationResult}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default Logic;
