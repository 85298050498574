import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import shallow from 'zustand/shallow';
import { useVoucherPurchaseStore, VoucherPurchaseStore, } from 'Modules/Vouchers/store/VoucherPurchaseStore';
import FetchData from './FetchData';


const selectStoreData = (s: VoucherPurchaseStore) => ({
  selectModalData: s.modalDetailVoucherPurchase,
  selectClose: s.closeModalDetailVoucherPurchase,
});

const ModalDetailVoucherPurchase = (): JSX.Element => {
  const {
    selectModalData,
    selectClose,
  } = useVoucherPurchaseStore(selectStoreData, shallow);
  const { isOpen, voucherPurchase, } = selectModalData;
  const onClose = selectClose;
  const { t, } = useTranslation('vouchersAdmin');

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !! voucherPurchase}
    >
      <ModalDrawerTitle
        onClose={onClose}
      >
        {t('purchases.form.title')}
      </ModalDrawerTitle>
      <FetchData
        voucherPurchase={voucherPurchase}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalDetailVoucherPurchase;
