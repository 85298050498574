/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { AssessmentBlockTypeEnum, SubmitTaskCompletionMutationVariables, } from 'Apollo/graphql';
import { UseInitForm, Node, NodesObj, NodeKeys, NodeSchemaObj, AssessmentValues, } from './types';

const NODES_SCHEMA: NodeSchemaObj = {
  [AssessmentBlockTypeEnum.Text]: () => yup.object().shape({
    value: yup.string()
      .required(INPUT_FORM_ERRORS.REQUIRED),
  }),
  [AssessmentBlockTypeEnum.Number]: () => yup.object().shape({
    value: yup.number()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
  }),
  [AssessmentBlockTypeEnum.YesNo]: () => yup.object().shape({
    value: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
  }),
  [AssessmentBlockTypeEnum.Checkbox]: () => yup.object().shape({
    value: yup.array()
      .required(INPUT_FORM_ERRORS.REQUIRED)
      .min(1, INPUT_FORM_ERRORS.REQUIRED),
  }),
  [AssessmentBlockTypeEnum.Radio]: () => yup.object().shape({
    value: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
  }),
  [AssessmentBlockTypeEnum.Scale]: () => yup.object().shape({
    value: yup.number()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
  }),
  [AssessmentBlockTypeEnum.Photo]: () => yup.object().shape({
    value: yup.object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
  }),
};

const schemaNode = yup.lazy((value: Node) => {
  const { type, } = value;
  if (type && Object.prototype.hasOwnProperty.call(NODES_SCHEMA, type)) return NODES_SCHEMA[type as NodeKeys]();
  return yup.mixed();
});

export const assessmentValidationSchema = yup.object().shape({
  nodes: yup.array().of(schemaNode as any),
});

const NODES_OBJ: NodesObj = {
  TEXT: () => ({
    type: AssessmentBlockTypeEnum.Text,
    value: '',
  }),
  NUMBER: () => ({
    type: AssessmentBlockTypeEnum.Number,
    value: null,
  }),
  YES_NO: () => ({
    type: AssessmentBlockTypeEnum.YesNo,
    value: null,
  }),
  CHECKBOX: () => ({
    type: AssessmentBlockTypeEnum.Checkbox,
    value: [],
  }),
  RADIO: () => ({
     type: AssessmentBlockTypeEnum.Radio,
     value: null,
  }),
  SCALE: () => ({
    type: AssessmentBlockTypeEnum.Scale,
    value: null,
  }),
  PHOTO: () => ({
    type: AssessmentBlockTypeEnum.Photo,
    value: null,
  }),
};

export const useInitForm: UseInitForm = (assessment) => {
  const nodes: Node[] = [];
  
  for (let i = 0; i < assessment.length; i++) {
    const block = assessment[i];
    
    if (Object.prototype.hasOwnProperty.call(NODES_OBJ, block.type)) {
      nodes.push(NODES_OBJ[block.type as NodeKeys]());
    }
  }

  return {
    initValues: {
      nodes,
    },
  };
};

export const mapVariables = (taskId: string, values: AssessmentValues): SubmitTaskCompletionMutationVariables => ({
  input: {
    taskId,
    answers: values.nodes.map((node) => {
      switch(node.type) {
        case AssessmentBlockTypeEnum.Text:
          return node.value;
        case AssessmentBlockTypeEnum.Number:
          return node.value;
        case AssessmentBlockTypeEnum.Radio:
          return node.value?.value ?? null;
        case AssessmentBlockTypeEnum.Checkbox:
          return node.value.map((o) => o.value);
        case AssessmentBlockTypeEnum.YesNo:
          return node.value?.value ?? null;
        case AssessmentBlockTypeEnum.Scale:
          return node.value;
        case AssessmentBlockTypeEnum.Photo:
          return node.value?.id || null;
        default:
          return null;
      }
    }),
  },
});
