import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { UserStore, useUserStore, } from 'Modules/Users/store/UserStore';
import Logic from './Logic';

const selectModalData = (s: UserStore) => s.modalCreateUser;
const selectClose = (s: UserStore) => s.closeModalCreateUser;

const ModalCreateUser = (): JSX.Element => {
  const { isOpen, } = useUserStore(selectModalData);
  const onClose = useUserStore(selectClose);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen}
    >
      <Logic
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalCreateUser;
