import { useState, } from 'react';
import Button from '@mui/material/Button';
import { useTranslation, } from 'react-i18next';
import { ContentCopy, } from '@mui/icons-material';

interface Props {
  copyText: string;
}

function delay(time: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, time));
}

function ClipboardCopy({ copyText, }: Props) {
  const { t, } = useTranslation('registrationsAdmin');
  const [loading, setLoading,] = useState(false);
  const [completed, setCompleted,] = useState(false);

  const copyTextToClipboard = async (text: string) => {
    try {
      setLoading(true);
      await navigator.clipboard.writeText(text);
      setLoading(false);
      setCompleted(true);
      await delay(1000);
      setCompleted(false);
    } catch {
      // ignore error
      setLoading(false);
    }
  };

  const handleCopyClick = () => copyTextToClipboard(copyText);

  if (completed) {
    return <Button disabled={loading}>{t('qrModal.copied')}</Button>;
  }

  return (
    <Button onClick={handleCopyClick} sx={{ display: 'flex', gap: '4px', }} >
      <ContentCopy />
      {t('qrModal.copy')}
    </Button>
  );
}
export default ClipboardCopy;
