import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useOptionsExportReplacements, } from 'Utils/options/useOptionsExportReplacements';
import FetchData from './FetchData';

const Layout = (): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  const exportOptions = useOptionsExportReplacements();

  return (
    <>
      <Typography variant="body1" color="inherit" marginBottom={2}>
        {t(`common:defaultExport.info`, { name: t('replacements.title'), })}
      </Typography>
      <FetchData withAssortments={exportOptions.options[1]} />
    </>
  );
};

export default Layout;
