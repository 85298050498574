import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const ShortRegistrationsColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 27 27"
    fill="none"
    {...props}
  >
        <g fillRule="evenodd" clipRule="evenodd">
      <path
        d="M78.396 94.296c.962 0 1.765.785 1.76 1.722 0 .457-.187.893-.517 1.215a1.782 1.782 0 0 1-2.485 0 1.703 1.703 0 0 1-.517-1.215c-.008-.94.792-1.722 1.76-1.722zm-.267 1.696c.003.172.092.285.246.285h.507s-.04 0 0 0h.436c.178-.002.292-.11.289-.264-.003-.153-.119-.259-.295-.261h-.445c-.168 0-.208-.04-.208-.206v-.447c-.003-.161-.109-.267-.263-.267-.154 0-.267.106-.27.272-.002.151 0 .304 0 .455"
        fill="#cf7b62"
        transform="matrix(3.77953 0 0 3.77953 -278.95 -345.404)"
      />
      <path
        d="M74.414 92.648c0-.248.073-.491.213-.697a1.297 1.297 0 0 1 1.316-.54c.249.048.478.167.66.341a1.24 1.24 0 0 1-.19 1.934 1.308 1.308 0 0 1-1.618-.153 1.245 1.245 0 0 1-.379-.885zM75.03 95.75h-1.013c-.187 0-.244-.077-.195-.212.3-.864 1.094-1.405 2.027-1.374.297.013.589.095.848.238.122.066.14.145.051.248a2.033 2.033 0 0 0-.462.922c-.03.15-.062.177-.216.177z"
        transform="matrix(3.77953 0 0 3.77953 -278.95 -345.404)"
        fill="#000"
      />
    </g>
  </SvgIcon>
);

export default ShortRegistrationsColoredIcon;
