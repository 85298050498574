import { useRequestSelfRemovalMutation, } from 'Apollo/graphql';
import AppContainer from 'Components/AppContainer';
import { useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import DeleteForm from './DeleteForm';
import Success from './Success';

const DeleteMyProfile = (): JSX.Element => {
  const { t, } = useTranslation('myProfile');
  const [state, setState,] = useState(0);
  const [removalMutation, removalMutationResult,] = useRequestSelfRemovalMutation({
    onCompleted: (response) => {
      setState(() => 1);
    },
  });
  return <AppContainer maxWidth="sm">{state === 0 ? <DeleteForm removalMutation={removalMutation} /> : <Success />}</AppContainer>;
};

export default DeleteMyProfile;
