import { SvgIcon, SvgIconProps, } from '@mui/material';

const DashboardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6664 12.4619C10.6673 12.9428 10.2789 13.3321 9.79979 13.333H2.86665C2.63643 13.333 2.41511 13.2415 2.25244 13.0779C2.09067 12.9144 1.99912 12.693 2.00001 12.4619V2.87198C1.99912 2.64087 2.09067 2.41954 2.25333 2.25688C2.41599 2.09333 2.63732 2.00089 2.86754 2L9.80068 2.01244C10.2798 2.01422 10.6673 2.40443 10.6664 2.88353V12.4619Z"
        stroke="black"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9958 7.14517C21.9923 7.62071 21.6047 8.00381 21.1283 8.00026H14.1961C13.9676 8.00203 13.7481 7.91226 13.5854 7.75226C13.4227 7.59227 13.3303 7.37361 13.3294 7.14517V2.86706C13.3321 2.39241 13.7187 2.0102 14.1943 2.01287L21.1265 2.00042C21.6038 1.99687 21.9914 2.37997 21.9958 2.85551V7.14517Z"
        stroke="black"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6664 21.1422C10.6647 21.3716 10.5731 21.5902 10.4095 21.752C10.2469 21.9111 10.0264 22.0018 9.79712 22L2.86486 21.9884C2.38932 21.9902 2.00267 21.6071 2 21.1316V16.8579C2.00089 16.6286 2.09244 16.4099 2.25599 16.249C2.41866 16.0881 2.63821 15.9984 2.86664 16.0001H9.79979C10.2762 15.9984 10.6638 16.3815 10.6664 16.8579V21.1422Z"
        stroke="black"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1966 21.9997C13.9655 21.9997 13.745 21.9073 13.5824 21.7437C13.4197 21.5793 13.3282 21.3589 13.329 21.1287V11.5387C13.3282 11.3085 13.4197 11.0872 13.5824 10.9236C13.7441 10.7592 13.9655 10.6676 14.1957 10.6667H21.1288C21.3599 10.6676 21.5804 10.7592 21.743 10.9236C21.9057 11.0872 21.9964 11.3085 21.9955 11.5387V21.1162C21.9964 21.5953 21.6097 21.9855 21.1297 21.9882L14.1966 21.9997Z"
        stroke="#D47E5F"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default DashboardIcon;
