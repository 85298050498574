import { TableVoucherFragment, VoucherTableDocument, useDeleteVoucherMutation, } from 'Apollo/graphql';
import { useSnackbar, } from 'notistack';
import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import View from './View';

interface Props {
  voucher?: TableVoucherFragment | null;
  onClose: () => void;
  onCloseLogic: () => void;
}

const Logic = ({ voucher, onClose, onCloseLogic, }: Props): JSX.Element => {
  const preservedVoucher = usePreserveValue(voucher);
  const { t, } = useTranslation('vouchersAdmin');
  const { enqueueSnackbar, } = useSnackbar();

  const [removeMutation, removeMutationResult,] = useDeleteVoucherMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('removeVoucherModal.notifRemovedTitle') as string,
        message: t('removeVoucherModal.notifRemovedMessage', { id: preservedVoucher.id, }),
        variant: 'common',
      });
      onCloseLogic();
      onClose();
    },
    refetchQueries: [{ query: VoucherTableDocument, },],
  });

  const handleSubmit = useCallback(() => {
    removeMutation({
      variables: { id: preservedVoucher.id, },
    });
  }, [preservedVoucher, removeMutation,]);

  return <View voucher={preservedVoucher} removeMutationResult={removeMutationResult} onClose={onClose} onSubmit={handleSubmit} />;
};

export default Logic;
