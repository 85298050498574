import ModalCreatePointsTransaction from 'Modules/PointsTransactionAdmin/modals/ModalCreatePointsTransaction';
import ModalDetailPointsTransaction from 'Modules/PointsTransactionAdmin/modals/ModalDetailPointsTransaction';
import ModalEditPointsTransaction from 'Modules/PointsTransactionAdmin/modals/ModalEditPointsTransaction';
import ModalExportPointsTransactions from 'Modules/PointsTransactionAdmin/modals/ModalExportPointsTransaction';
import ModalRemovePointsTransaction from 'Modules/PointsTransactionAdmin/modals/ModalRemovePointsTransaction';
import PointsTransactionsTable from './PointsTransactionTable';

const PointsTransactionAdminPage = (): JSX.Element => {
  return (
    <>
      <PointsTransactionsTable />
      <ModalEditPointsTransaction />
      <ModalDetailPointsTransaction />
      <ModalRemovePointsTransaction />
      <ModalExportPointsTransactions />
      <ModalCreatePointsTransaction />
    </>
  );
};

export default PointsTransactionAdminPage;
