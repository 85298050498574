import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const QuoteIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    {...props}
    fill="none"
  >
    <path
      d="m18.292 7.269-.02-.14-.022.005a3.75 3.75 0 1 0-3.666 4.534c.186 0 .364-.028.542-.054-.058.193-.117.39-.212.566-.095.257-.243.48-.39.704-.124.242-.342.406-.502.614-.167.202-.396.336-.576.503-.178.175-.41.263-.595.386-.194.111-.362.233-.542.292l-.45.185-.394.164.403 1.616.498-.12.574-.143c.226-.041.466-.155.735-.26.264-.119.571-.198.856-.389.287-.181.618-.333.91-.576.282-.251.623-.469.875-.787.275-.299.546-.612.757-.969.244-.34.41-.713.585-1.082.158-.37.286-.747.39-1.113.198-.735.286-1.434.32-2.031.028-.599.012-1.096-.023-1.456a5.84 5.84 0 0 0-.053-.45Zm-9.166 0-.021-.14-.022.005a3.75 3.75 0 1 0-3.666 4.534c.186 0 .364-.028.542-.054-.058.193-.117.39-.212.566-.095.257-.243.48-.39.704-.124.242-.341.406-.501.614-.168.202-.396.336-.577.503-.178.175-.41.263-.595.386-.193.111-.362.233-.542.292l-.449.185-.394.162.403 1.616.498-.12c.159-.04.353-.086.574-.142.226-.042.467-.156.735-.26.264-.12.572-.199.857-.39.286-.181.617-.333.909-.576.282-.251.623-.469.875-.787.275-.298.546-.612.757-.968.244-.34.41-.713.585-1.083.159-.369.286-.746.39-1.113a10.06 10.06 0 0 0 .32-2.03 10.38 10.38 0 0 0-.023-1.457 6.315 6.315 0 0 0-.053-.447Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default QuoteIcon;
