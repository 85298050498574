import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled, } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SellerVoucherFragment, } from 'Apollo/graphql';
import IconButtonSquare from 'Components/IconButtonSquare';
import ImageAsync from 'Components/ImageAsync';
import LinkButton from 'Components/LinkButton';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { ROUTES, } from 'Utils/constants';
import { valueOrEmpty, } from 'Utils/parsers';
import KeyboardArrowUpIcon from 'Utils/svg/KeyboardArrowUpIcon';

type Props = {
  data: SellerVoucherFragment;
};

const Item = styled(Paper)(({ theme, }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.5, 2),
}));

const StyledChip = styled(Chip)(({ theme, }) => ({
  color: 'white',
  background: theme.palette.primary.main,
  fontVariant: 'h1',
  alignSelf: 'flex-end',
}));

const VoucherBox = ({ data, }: Props): JSX.Element => {
  const { t, } = useTranslation('myPoints');
  const dateFormatted = valueOrEmpty(`${t('common:date.fullDate', { date: new Date(data.validTo), })}`);
  const navigate = useNavigate();
  const handleDetail = () => navigate(`/${ROUTES.myPoints}/${data.id}`);

  return (
    <Item>
      {/* <StyledImage src={data.image?.publicUrl}/> */}
      <ImageAsync src={data.image?.publicUrl} />
      <Box mt={2} mb={2} display="flex" justifyContent="center" flexDirection="column">
        <Typography variant="h2" color="primary" alignSelf='center'>
          {data.name}
        </Typography>
        <Typography alignSelf='center' variant="inherit">{`${t('voucher.expires')}: ${dateFormatted}`}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="row">
        <StyledChip label={`${data.pricePoints} ${t('voucher.points')}`} />
        <Box>
          <LinkButton alignSelf="flex-start" type="button" variant="h3" onClick={handleDetail} color="primary" disabled={false} underline="none">
            {t('voucher.viewDetail')}
          </LinkButton>
          <IconButtonSquare sx={{ marginLeft: '1rem', }} variant="outlined" onClick={handleDetail}>
            <KeyboardArrowUpIcon sx={{transform: "rotate(90deg)",}} />
          </IconButtonSquare>
        </Box>
      </Box>
    </Item>
  );
};
export default VoucherBox;
