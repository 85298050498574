import { Trans, } from 'react-i18next';

const AlreadyVerified = (): JSX.Element => {
  return (
    <Trans
      i18nKey="registrationSeller:phaseInit.labelAlreadyRegistered"
    />
  );
};

export default AlreadyVerified;
