import { ReactNode, useEffect, useMemo, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { AdapterDateFns, } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider as MuiLocalizationProvider, PickersLocaleText, } from '@mui/x-date-pickers';
import { enGB, cs, pl, hu } from 'date-fns/locale';
import { getLocalText, } from './getLocalText';
import { z } from 'zod';

const locales = { enGB, cs, pl, hu } as const

const envLocale = import.meta.env.VITE_APP_LANG
// TODO: move away
const parsedEnvLocale = z.enum(['enGB', 'cs', 'pl', 'hu']).parse(envLocale === 'en' ? 'enGB' : envLocale === 'cz' ? 'cs' : envLocale)

interface Props {
  children: ReactNode,
};

const LocalizationProvider = ({
  children,
}: Props): JSX.Element => {
  const { t, } = useTranslation('common');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const localeText = useMemo<PickersLocaleText<any>>(
    () => getLocalText(t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <MuiLocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locales[parsedEnvLocale]}
      localeText={localeText}
    >
      {children}
    </MuiLocalizationProvider>
  );
};

export default LocalizationProvider;
