import { useTranslation, } from 'react-i18next';
import { FormInputFileUpload, } from 'Components/Form';
import Grid from '@mui/material/Grid';
import { IMAGE_MAX_SIZE, } from 'Utils/constants';
import { NodeProps, } from '../types';
import { buildNodeInputName, } from '../utils';
import FormInputNodeType from '../FormInputNodeType';
import NodeLayout from '../NodeLayout';

const NodePhoto = ({
  index,
  onRemove,
  onChangeType,
}: NodeProps): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');

  return (
    <NodeLayout
      index={index}
      onRemove={onRemove}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInputNodeType
            index={index}
            onChangeType={onChangeType}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputFileUpload
            name={buildNodeInputName(index, 'value')}
            label={t('content.labelPhoto')}
            fileType="image"
            maxSize={IMAGE_MAX_SIZE}
            required
          />
        </Grid>
      </Grid>
    </NodeLayout>
  );
};

export default NodePhoto;
