import create from 'zustand';
import {
  AssessmentBlockTypeEnum,
  AssessmentMethodEnum,
  SellerFillTaskDocument,
  SellerFillTaskFragment,
  SellerFillTaskQuery,
  SellerFillTaskQueryVariables,
  SubmitTaskCompletionResultFragment,
  TaskTypeEnum,
} from 'Apollo/graphql';
import { apolloClient, } from 'Apollo';
import { ApolloError, } from '@apollo/client';
import { isTaskPointsEnabledMarket, } from 'Utils/market';

export type FillTaskPhase = 'content' | 'assessment'| 'result';

export type AssessmentConfig = {
  enablePoints: boolean,
};

type FillTaskStoreState = {
  taskResult: {
    loading: boolean,
    error: Error | ApolloError | null,
    data: SellerFillTaskFragment | null,
  },
  phase: FillTaskPhase,
  skipPhaseContent: boolean,
  assessmentConfig: AssessmentConfig,
  assessmentResult: SubmitTaskCompletionResultFragment | null,
};

export type FillTaskStore = FillTaskStoreState & {
  fetchTask: (id?: string) => void,
  resetStore: () => void,
  goToPhase: (phase: FillTaskPhase) => void,
  finishContent: () => void,
  finishAssessment: (result: SubmitTaskCompletionResultFragment) => void,
};

export const INIT_STATE: FillTaskStoreState = {
  taskResult: {
    loading: true,
    error: null,
    data: null,
  },
  phase: 'assessment',
  skipPhaseContent: false,
  assessmentConfig: {
    enablePoints: false,
  },
  assessmentResult: null,
};

export const useFillTaskStore = create<FillTaskStore>((set) => ({
  ...INIT_STATE,
  fetchTask: async (id) => {
    try {
      set({
        taskResult: {
          loading: true,
          error: null,
          data: null,
        },
      });
      if (!id) throw new Error('invalid id');
      const result = await apolloClient.query<SellerFillTaskQuery, SellerFillTaskQueryVariables>({
        query: SellerFillTaskDocument,
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      });

      const { type, assessmentMethod, } = result.data.task;

      set({
        taskResult: {
          loading: false,
          error: null,
          data: result.data.task,
        },
        skipPhaseContent: type !== TaskTypeEnum.Elearning,
        phase: type === TaskTypeEnum.Elearning ? 'content' : 'assessment',
        assessmentConfig: {
          enablePoints: isTaskPointsEnabledMarket && assessmentMethod === AssessmentMethodEnum.Test,
        },
      });
    } catch (errorCatch) {
      set({
        taskResult: {
          loading: false,
          error: errorCatch as Error,
          data: null,
        },
      });
    }
  },
  resetStore: () => set({
    ...INIT_STATE,
  }),
  goToPhase: (phase) => set({
    phase,
  }),
  finishContent: () => set({
    phase: 'assessment',
  }),
  finishAssessment: (result) => set({
    phase: 'result',
    assessmentResult: result,
  }),
}));
