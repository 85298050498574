import { ReactNode, } from 'react';
import Box from '@mui/material/Box';

interface Props {
  children?: ReactNode,
}

const ModalDrawerBody = ({
  children,
}: Props): JSX.Element => {
  return (
    <Box
      pt={3}
      pl={6}
      pr={6}
      pb={6}
    >
      <Box
        sx={{
          maxWidth: 360,
          marginRight: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};


export default ModalDrawerBody;
