import { ReactNode, useEffect, } from 'react';
import AppContainer from 'Components/AppContainer';
import { ReplacementPhase, ReplacementStore, useReplacementStore, } from 'Modules/ReplacementSeller/store/ReplacementStore';
import { useAuthUser, } from 'Tools/auth';
import SearchReplacement from './SearchReplacement';
import DetailReplacement from './DetailReplacement';
import FormReplacement from './FormReplacement';
import ClosedReplacement from './ClosedReplacement';
import ReplacementLogistic from './ReplacementLogistic';

const PHASE_COMPONENTS: { [key in ReplacementPhase]: ReactNode } = {
  replacementSearch: <SearchReplacement />,
  replacementDetail: <DetailReplacement />,
  replacementForm: <FormReplacement />,
  replacementClosed: <ClosedReplacement />,
  replacementLogistic: <ReplacementLogistic />,
};

const selectPhase = (s: ReplacementStore) => s.phase;
const selectLeavePage = (s: ReplacementStore) => s.leavePage;

const ReplacementSellerPage = () => {
  const phase = useReplacementStore(selectPhase);
  const leavePage = useReplacementStore(selectLeavePage);
  const authUser = useAuthUser();
  
  useEffect(
    () => {
      return () => leavePage();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <AppContainer maxWidth="sm">
      {PHASE_COMPONENTS[phase]}
    </AppContainer>
  );
};

export default ReplacementSellerPage;
