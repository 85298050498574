import { useTranslation, } from 'react-i18next';
import { Link as RouterLink, } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ResetPasswordMutationResult, } from 'Apollo/graphql';
import { FormInputText, FormSubmit, } from 'Components/Form';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { ROUTES, } from 'Utils/constants';
import { CUSTOM_RESET_PASSWORD_GQL_ERRORS, } from './utils';

interface Props {
  resetMutationResult: ResetPasswordMutationResult,
}
const FormView = ({
  resetMutationResult,
}: Props): JSX.Element => {
  const { t, } = useTranslation('auth');

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        marginBottom={3}
      >
        {t('resetPassword.resetForm.title')}
      </Typography>
      <Typography
        variant="body1"
        marginBottom={2}
      >
        {t('resetPassword.resetForm.info')}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormInputText
            name="newPassword"
            type="password"
            label={t('resetPassword.resetForm.labelNewPassword')}
            required
            id="new-password"
            autoComplete="new-password"
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputText
            name="confirmPassword"
            type="password"
            label={t('resetPassword.resetForm.labelConfirmPassword')}
            required
            id="new-password-confirm"
            autoComplete="new-password-confirm"
          />
        </Grid>
      </Grid>

      <Box mt={4}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} lg="auto">
            <FormSubmit
              size="large"
              color="primary"
              variant="contained"
              sx={{ width: { xs: '100%', lg: 'unset', }, }}
            >
              {t('resetPassword.resetForm.btnSubmit')}
            </FormSubmit>
          </Grid>
          <Grid item xs={12} lg="auto">
            <Button
              component={RouterLink}
              to={`/${ROUTES.login}`}
              size="large"
              sx={{ width: { xs: '100%', lg: 'unset', }, }}
              variant="outlined"
              color="primary"
            >
              {t('resetPassword.resetForm.btnBackToLogin')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ErrorAlert
        sx={{ mt: 2, }}
        error={resetMutationResult.error}
        apolloCustomGqlErrors={CUSTOM_RESET_PASSWORD_GQL_ERRORS}
      />
    </>
  );
};

export default FormView;
