import { FormFieldErrorCodeEnum, } from 'Apollo/graphql';
import { InputFormErrorMessage, } from './types';

export const INPUT_FORM_ERROR_KEYS = {
  unknown: 'common:inputErrors.unknown',
  required: 'common:inputErrors.required',
  deviceCode: 'common:inputErrors.deviceCode',
  alreadyExists: 'common:inputErrors.alreadyExists',
  email: 'common:inputErrors.email',
  stringMin: 'common:inputErrors.stringMin',
  stringMax: 'common:inputErrors.stringMax',
  intMin: 'common:inputErrors.intMin',
  intMax: 'common:inputErrors.intMax',
  dateFormat: 'common:inputErrors.dateFormat',
  fileTypeGeneral: 'common:inputErrors.fileTypeGeneral',
  fileType: 'common:inputErrors.fileType',
  fileStructure: 'common:inputErrors.fileStructure',
  fileSizeMax: 'common:inputErrors.fileSizeMax',
  fileSizeMin: 'common:inputErrors.fileSizeMin',
  fileCountMax: 'common:inputErrors.fileCountMax',
  fileUploadError: 'common:inputErrors.fileUploadError',
  dateMinToday: 'common:inputErrors.dateMinToday',
  dateMin18Years: 'common:inputErrors.dateMin18Years',
  dateStartBefore: 'common:inputErrors.dateStartBefore',
  passwordMatch: 'common:inputErrors.passwordMatch',
  phone: 'common:inputErrors.phone',
  onlyNumbers: 'common:inputErrors.onlyNumbers',
  deviceCodeInvalidInMarket: 'common:inputErrors.deviceCodeInvalidInMarket',
  giftCodeInvalid: 'common:inputErrors.giftCodeInvalid',
  checkboxIsMandatory: 'common:inputErrors.checkboxIsMandatory',
  multipleOf: 'common:inputErrors.multipleOf',
  phoneNumberNotValid: 'common:inputErrors.phoneNumberNotValid',
};

export const INPUT_FORM_ERRORS = {
  ONLY_NUMBERS: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.onlyNumbers,
  }),
  UNKNOWN: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.unknown,
  }),
  REQUIRED: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.required,
  }),
  INVALID_DEVICE_CODE: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.deviceCode,
  }),
  ALREADY_EXIST: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.alreadyExists,
  }),
  EMAIL: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.email,
  }),
  EMAIL_WITH_REASON: (reason: string): InputFormErrorMessage => ({
    key:`common:errors.${reason}`,
  }),
  STRING_MIN: ({ min, }: { min: number }): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.stringMin,
    params: { min, },
  }),
  STRING_MAX: ({ max, }: { max: number }): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.stringMax,
    params: { max, },
  }),
  INT_MIN: ({ min, }: { min: number }): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.intMin,
    params: { min, },
  }),
  INT_MAX: ({ max, }: { max: number }): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.intMax,
    params: { max, },
  }),
  DATE_FORMAT: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.dateFormat,
  }),
  DATE_MIN_TODAY: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.dateMinToday,
  }),
  FILE_TYPE: ({ fileTypes, }: { fileTypes?: string }): InputFormErrorMessage =>
    fileTypes
      ? {
          key: INPUT_FORM_ERROR_KEYS.fileType,
          params: { fileTypes, },
        }
      : {
          key: INPUT_FORM_ERROR_KEYS.fileTypeGeneral,
        },
  FILE_STRUCTURE: ({ structureError, }: { structureError: string }): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.fileStructure,
    params: { structureError, },
  }),
  FILE_SIZE_MAX: ({ maxSize, }: { maxSize: string }): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.fileSizeMax,
    params: { maxSize, },
  }),
  FILE_SIZE_MIN: ({ minSize, }: { minSize: string }): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.fileSizeMin,
    params: { min: minSize, },
  }),
  FILE_COUNT_MAX: ({ countMax, }: { countMax: number }): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.fileCountMax,
    params: { max: countMax, },
  }),
  FILE_UPLOAD_ERROR: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.fileUploadError,
  }),
  PASSWORD_MATCH: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.passwordMatch,
  }),
  PHONE: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.phone,
  }),
  DATE_MIN_18_YEARS: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.dateMin18Years,
  }),
  DATE_START_BEFORE: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.dateStartBefore,
  }),
  DEVICE_CODE_INVALID_IN_MARKET: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.deviceCodeInvalidInMarket,
  }),
  GIFT_CODE: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.giftCodeInvalid,
  }),
  CHECKBOX_IS_MANDATORY: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.checkboxIsMandatory,
  }),
  MULTIPLE_OF: ({ number, }: { number: number }): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.multipleOf,
    params: { number, },
  }),
  PHONE_NUMBER_NOT_VALID: (): InputFormErrorMessage => ({
    key: INPUT_FORM_ERROR_KEYS.phoneNumberNotValid,
  }),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const APOLLO_FIELD_ERROR_MAPPER: { [key in FormFieldErrorCodeEnum]: (value: any) => InputFormErrorMessage } = {
  [FormFieldErrorCodeEnum.Unknown]: () => INPUT_FORM_ERRORS.UNKNOWN(),
  [FormFieldErrorCodeEnum.AlreadyExists]: () => INPUT_FORM_ERRORS.ALREADY_EXIST(),
  [FormFieldErrorCodeEnum.FileType]: () => INPUT_FORM_ERRORS.FILE_TYPE({}),
  [FormFieldErrorCodeEnum.FileInternalStructure]: (value) => INPUT_FORM_ERRORS.FILE_STRUCTURE({ structureError: value, }),
  [FormFieldErrorCodeEnum.FileCountMax]: (value) => INPUT_FORM_ERRORS.UNKNOWN(), // TODO params from BE - max files
  [FormFieldErrorCodeEnum.FileSizeMax]: (value) => INPUT_FORM_ERRORS.UNKNOWN(), // TODO params from BE - max size
  // backwards compatibility - EMAIL is used on places where is expected with no arguments
  [FormFieldErrorCodeEnum.InvalidEmail]: (reason?: string) => reason ? INPUT_FORM_ERRORS.EMAIL_WITH_REASON(reason) : INPUT_FORM_ERRORS.EMAIL(),
  [FormFieldErrorCodeEnum.InvalidCode]: () => INPUT_FORM_ERRORS.GIFT_CODE(),
};
