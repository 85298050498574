import { useTranslation, } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { UserPointsQuery, } from 'Apollo/graphql';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import CollapsibleItem from 'Modules/Users/components/CollapsibleItem';
import TransactionList from './TransactionList';

interface Props {
    data: UserPointsQuery,
};

const View = ({ data, }: Props): JSX.Element => {
  const { t, } = useTranslation('users');

  return (
    <CollapsibleItem label={t('user.labelUserPoints')}>
      <Stack spacing={2}>
        <NotEditableTextClassic
          label={t('user.labelBalance')}
        >
          {`${data?.pointsBalance.available} ${t('common:points')}`}
        </NotEditableTextClassic>
        <NotEditableTextClassic
          label={t('user.labelTransactions')}
        >
          <TransactionList data={data} />
        </NotEditableTextClassic>           
      </Stack>
    </CollapsibleItem>
  );
};
export default View;
