import { ReactNode, ForwardRefExoticComponent, } from 'react';
import { Link, LinkProps, } from 'react-router-dom';
import { SxProps, Theme, styled, } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ButtonBase from '@mui/material/ButtonBase';
import type { MenuItem, } from 'Components/Layouts/types';

const StyledButtonBase = styled(ButtonBase)<{
  component: ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>;
  to: string;
}>(({ theme, }) => ({
  padding: theme.spacing(1, 3.25),
  minHeight: 90,
  background: theme.palette.common.white,
  width: '100%',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: 16,
  lineHeight: '22px',
  letterSpacing: '0.25px',
  borderRadius: theme.shape.borderRadius,
  borderColor: '#D9D9D9',
  borderStyle: 'solid',
  borderWidth: 1,
  transition: theme.transitions.create(['background-color',]),

  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },

  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    fontSize: 30,
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
    lineHeight: '24px',
    minHeight: 64,
    color: theme.palette.secondary.main,
    justifyContent: 'flex-start',
  },
}));

interface Props {
  item: MenuItem;
  children: ReactNode;
  isAllowed: boolean;
  sx?: SxProps<Theme>;
}

export default ({ item: { route, icon, }, children, isAllowed, sx, }: Props): JSX.Element | null => {
  if (isAllowed === false) return null;
  return (
    <Grid item xs={12} sm={6}>
      <StyledButtonBase component={Link} to={route} sx={sx}>
        {icon}
        {children}
      </StyledButtonBase>
    </Grid>
  );
};
