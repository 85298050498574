import { AvailableVoucherType, VoucherType, } from 'Apollo/graphql';


type GetOptionLabel = (o: AvailableVoucherType) => string;
type GetOptionKey = (o: AvailableVoucherType) => VoucherType;

const isOptionEqualToEnumValue = (a:AvailableVoucherType,b:AvailableVoucherType) => a.id === b.id;
type UseAvailableVoucherTypes = (options:AvailableVoucherType[]) => {
  options: AvailableVoucherType[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof isOptionEqualToEnumValue ,
};

export const useAvailableVoucherTypes: UseAvailableVoucherTypes = (options:AvailableVoucherType[]) => {
  return {
    options,
    getOptionLabel: (o:AvailableVoucherType) => o.labelKey,
    getOptionKey: (o:AvailableVoucherType) => o.id,
    isOptionEqualToValue: isOptionEqualToEnumValue,
  };
};
