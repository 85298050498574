import { ReactNode, useEffect, } from 'react';
import shallow from 'zustand/shallow';
import PhaseCreateTicket from './PhaseCreateTicket';
import PhaseCustomerRegistration from './PhaseCustomerRegistration';
import PhaseCustomerRegistrationComplete from './PhaseCustomerRegistrationComplete';
import PhaseCustomerVerification from './PhaseCustomerVerification';
import PhaseInit from './PhaseInit';
import PhaseReplacementRegistration from './PhaseReplacementRegistration';
import PhaseTicketCompleted from './PhaseTicketCompleted';
import { CreateReplacementPhase, CreateReplacementStore, useCreateReplacementStore, } from './createReplacementStore';

type PhaseComponents = {
  [key in CreateReplacementPhase]: (onClose: () => void) => ReactNode
};

const PHASE_COMPONENTS: PhaseComponents = {
  init: (onClose) => <PhaseInit onClose={onClose} />,
  customerRegistration: (onClose) => <PhaseCustomerRegistration onClose={onClose} />,
  customerVerification: (onClose) => <PhaseCustomerVerification onClose={onClose} />,
  customerRegistrationComplete: (onClose) => <PhaseCustomerRegistrationComplete onClose={onClose} />,
  createTicket: (onClose) => <PhaseCreateTicket onClose={onClose} />,
  ticketCompleted: (onClose) => <PhaseTicketCompleted onClose={onClose} />,
  replacementRegistration:(onClose) => <PhaseReplacementRegistration onClose={onClose} />, // TODO

};

const selectStoreData = (s: CreateReplacementStore) => ({
  phase: s.phase,
  resetStore: s.resetStore,
});

interface Props {
  onClose: () => void,
};

const Layout = ({
  onClose,
}: Props): JSX.Element => {
  const { phase, resetStore, } = useCreateReplacementStore(selectStoreData, shallow);

  useEffect(
    () => {
      return () => resetStore();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <>{PHASE_COMPONENTS[phase](onClose)}</>;
};

export default Layout;
