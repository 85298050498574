import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, } from 'Components/Checkbox';
import { CorrectAnswerIndexesObj, } from './types';

interface Props {
  index: number,
  answer: string,
  correctAnswerIndexesObject: CorrectAnswerIndexesObj,
  showAssessmentCorrectAnswers: boolean,
}

const noop = () => {};

// TODO read only checkbox and formControlLabel
const AnswerRow = ({
  index,
  answer,
  correctAnswerIndexesObject,
  showAssessmentCorrectAnswers,
}: Props): JSX.Element => {
  const isCorrectAnswer = Object.prototype.hasOwnProperty.call(correctAnswerIndexesObject, index);

  return (
    <Grid container item spacing={2}>
      <Grid item xs={showAssessmentCorrectAnswers ? 9 : 12} display="flex" alignItems="center">
        <Typography
          component="span"
          mr={0.75}
        >
          {`${index + 1}.`}
        </Typography>
        <Typography
          component="span"
        >
          {answer}
        </Typography>
      </Grid>
      {showAssessmentCorrectAnswers && (
        <Grid item xs={3} display="flex" alignItems="center">
          <FormControlLabel
            value={index}
            label={`${index + 1}.`}
            control={(
              <Checkbox
                checked={isCorrectAnswer}
                onChange={noop}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AnswerRow;
