import { useTranslation, } from 'react-i18next';
import { Typography, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import { NotificationType, } from 'Apollo/graphql';
import { useDelayedResetNotificationCategoryCount, } from 'Tools/useDelayedResetNotificationCategoryCount';
import NewsArticlesList from './NewsArticlesList';

const NewsArticlesListPage = () => {
  const { t, } = useTranslation('newsArticlesSeller');

  useDelayedResetNotificationCategoryCount(NotificationType.NewsArticle);

  return (
    <AppContainer maxWidth="sm">
      <Typography
        variant="h1"
        marginBottom={3}
      >
        {t('newsArticlesTitle')}
      </Typography>
      <NewsArticlesList />
    </AppContainer>
  );
};

export default NewsArticlesListPage;
