import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import Grid from '@mui/material/Grid';
import { ExportTaskCompletionsMutationResult, ExportTypesEnum, useExportTaskCompletionsMutation, } from 'Apollo/graphql';
import { FormProvider, useForm } from 'react-hook-form';
import ExportTypeForm from 'Components/Export/ExportTypeForm';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { exportTypeSchema } from 'Components/Export/helpers';
import { EXPORT_TYPE_OPTIONS } from 'Utils/options/useOptionsExportTypes';
import { useTaskStore } from 'Modules/TasksAdmin/store/GeneralTasksStore';

interface Props {
  onClose: () => void;
}

const View = ({
  onClose,
}: Props): JSX.Element => {
  const { task, } = useTaskStore(s => s.modalExportTask);

  const { t, } = useTranslation('tasksAdmin');
  const [exportMutation, { loading, error, data },] = useExportTaskCompletionsMutation({
    fetchPolicy: 'no-cache',
  });

  const [chosenType, setChosenType,] = useState<ExportTypesEnum | null>(null);

  const methods = useForm({
    defaultValues: { type: EXPORT_TYPE_OPTIONS[0], },
    resolver: yupResolver(exportTypeSchema),
    mode: 'onTouched',
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    setChosenType(values.type.id);
  });

  useEffect(
    () => {
      if (chosenType && task?.id) {
        exportMutation({
          variables: {
            filter: {
              id: { equals: task.id },
            },
            exportType: chosenType,
          },
        });
      }
    },
    [chosenType],
  );

  if (loading) return (
    <ModalDrawerBodyLoading onClose={onClose} />
  );

  if (error || (!data?.exportTaskCompletions && chosenType))
    return (
      <ModalDrawerBodyError onClose={onClose} error={error} />
    );


  if (data?.exportTaskCompletions && chosenType) {
    return (
      <ModalDrawerBody>
        <Typography variant="body1" color="inherit">
          {t('exportTask.info')}
        </Typography>
        <Box mt={2}>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Button component="a" href={data.exportTaskCompletions.file.publicUrl} download size="large" variant="contained" color="primary">
                {t('common:actions.download')}
              </Button>
            </Grid>
            <Grid item>
              <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
                {t('common:actions.cancel')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </ModalDrawerBody>
    );
  }

  return (
    <ModalDrawerBody>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <ExportTypeForm onClose={onClose} />
        </form>
      </FormProvider>
    </ModalDrawerBody>
  )
};

export default View;
