import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DetailVoucherPurchaseFragment, UpdateVoucherPurchaseMutationResult, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { FormError, FormSubmit, } from 'Components/Form';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import VoucherPurchaseForm from 'Modules/Vouchers/components/VoucherPurchaseForm';
import { useTranslation, } from 'react-i18next';

interface Props {
  voucherPurchase: DetailVoucherPurchaseFragment;
  editMutationResult: UpdateVoucherPurchaseMutationResult;
  onClose: () => void;
}

const View = ({ voucherPurchase, editMutationResult, onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');

  return (
    <ModalDrawerBody>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 5,
        }}
      >
        {t('purchases.form.info')}
      </Typography>
      <VoucherPurchaseForm voucherPurchase={voucherPurchase} />
      <Box mt={4}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <FormSubmit size="large">{t('purchases.form.btnSubmit')}</FormSubmit>
          </Grid>
          <Grid item>
            <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <FormError mt={2} />
      <ErrorAlert mt={2} error={editMutationResult.error} />
    </ModalDrawerBody>
  );
};

export default View;
