import { Query, } from '@cubejs-client/core';
import { QueryParams, } from '../types';

export const totalNumberOfUsersQuery = ({
  filters = [],
}: QueryParams): Query => ({
  measures: ['user.count',],
  timeDimensions: [
    {
      dimension: 'user.createdat',
    },
  ],
  filters,
});

export const usersByRolesQuery = ({ filters = [], }: QueryParams): Query => ({
  measures: ['user.count',],
  order: {
    'user.count': 'desc',
  },
  dimensions: ['user.role',],
  filters,
});

export const activeInactiveUsersQuery = ({
  filters = [],
}: QueryParams): Query => ({
  measures: ['user.count',],
  timeDimensions: [
    {
      dimension: 'user.createdat',
    },
  ],
  order: {
    'user.count': 'asc',
  },
  dimensions: ['user.hascreatedpurchase',],
  filters,
});
