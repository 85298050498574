import { styled, } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';


const InputBaseClassic = styled(InputBase)(({ theme, }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: '#D9D9D9',

  '&.Mui-error': {
    borderColor: theme.palette.error.main,
  },
  '&.Mui-focused': {
    borderColor: '#333333',
  },
  '&.Mui-focused.Mui-error': {
    borderColor: theme.palette.error.dark,
  },

  '& .MuiInputBase-input': {
    padding: '20px 15px',
    position: 'relative',
    fontSize: '1rem',
    lineHeight: '1.375em',
    height: 'unset',
    fontWeight: theme.typography.fontWeightRegular,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
    ]),
  }, 

  [theme.breakpoints.up('lg')]: {
    '& .MuiInputBase-input': {
      padding: '12px 15px',
    },
  },
}));


export default InputBaseClassic;
