import { useMemo, useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { AssessmentMethodEnum, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';
import { isOptionAvailable, } from './utils';

export type TaskMethodOption = {
  id: AssessmentMethodEnum;
  labelKey: string;
};

export const TASK_METHOD_OPTIONS: TaskMethodOption[] = [
  {
    id: AssessmentMethodEnum.Survey,
    labelKey: 'tasksAdmin:options.taskMethod.SURVEY',
  },
  {
    id: AssessmentMethodEnum.Test,
    labelKey: 'tasksAdmin:options.taskMethod.TEST',
  },
  {
    id: AssessmentMethodEnum.Photo,
    labelKey: 'tasksAdmin:options.taskMethod.PHOTO',
  },
];

type GetOptionLabel = (o: TaskMethodOption) => string;
type GetOptionKey = (o: TaskMethodOption) => string;
type FilterOptions = {
  [key in AssessmentMethodEnum]?: boolean
};
type UseOptionsTaskMethod = (filterOptions?: FilterOptions) => {
  options: TaskMethodOption[];
  getOptionLabel: GetOptionLabel;
  getOptionKey: GetOptionKey;
  isOptionEqualToValue: typeof areOptionsEqualById<TaskMethodOption>;
};

export const getOptionTaskMethodById = (id: AssessmentMethodEnum): TaskMethodOption | null => (
  TASK_METHOD_OPTIONS.find((value)=> value.id === id) || null
);

export const useOptionsTaskMethod: UseOptionsTaskMethod = (filterOptions) => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  const options = useMemo<TaskMethodOption[]>(
    () => filterOptions
      ? TASK_METHOD_OPTIONS.filter((o) => isOptionAvailable(filterOptions[o.id]))
      : TASK_METHOD_OPTIONS,
    [ filterOptions, ],
  );

  return {
    options,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
