import { yupResolver, } from '@hookform/resolvers/yup';
import { useCheckGiftCodeMutation, } from 'Apollo/graphql';
import { FormProvider, useForm, } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { CzGiftCodeStoreStore, useCzGiftCodeStore, } from 'Modules/GiftCodeSeller/store/CzGiftCodeStore';
import { phaseInitFormValidationSchema, PhaseInitFormValues, } from './utils';
import View from './View';

const selectStoreData = (s: CzGiftCodeStoreStore) => ({
  completeInitPhase: s.completeInitPhase,
});

const PhaseInit = (): JSX.Element => {
  const { completeInitPhase, } = useCzGiftCodeStore(selectStoreData, shallow);

  const methods = useForm<PhaseInitFormValues>({
    defaultValues: { giftCode: '', },
    resolver: yupResolver(phaseInitFormValidationSchema),
    mode: 'onSubmit',
  });

  const [checkGiftCodeMutate, checkGiftCodeResult,] = useCheckGiftCodeMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result.checkGiftCode) {
        completeInitPhase(result.checkGiftCode);
      }
    },
    onError: (responseError) => {
      if (responseError.message === 'This discount code is invalid')
        methods.setError('giftCode', {
          //FIXME: Very bad!
          message: { key: 'giftCodeSeller:form.invalidGiftCodeError' } as any,
        })
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await checkGiftCodeMutate({
      variables: {
        checkGiftCodeInput: {
          code: values.giftCode,
        },
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <View checkGiftCodeResult={checkGiftCodeResult} />
      </form>
    </FormProvider>
  );
};

export default PhaseInit;
