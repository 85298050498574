import { CustomerRegistrationFragment, useCustomerQuery, } from 'Apollo/graphql';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import View from './View';

interface Props {
  registration: CustomerRegistrationFragment | null,
  onClose: () => void,
};

const FetchData = ({
  registration,
  onClose,
}: Props): JSX.Element => {
  const preservedRegistration = usePreserveValue(registration);
  const { loading, error, data, } = useCustomerQuery({
    variables: {id: preservedRegistration?.id,},});

  if (loading) return (
    <ModalDrawerBodyLoading
      onClose={onClose}
    />
  );
  if (error || !data?.customer) return (
    <ModalDrawerBodyError
      onClose={onClose}
      error={error}
    />
  );
  return (
    <View
      customer={data.customer}
      onClose={onClose}
    />
  );
};

export default FetchData;
