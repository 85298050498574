import { useTranslation, } from 'react-i18next';
import { PointOfSellOptionFragment,} from 'Apollo/graphql';
import { FormInputAutocomplete, } from 'Components/Form';
import { renderOptionById, } from 'Utils/helpers';
import { useSearchOptionsPos, } from 'Utils/options/useSearchOptionsPos';

const InputPos = (): JSX.Element => {
  const { t, } = useTranslation('registrationSeller');

  const posOptions = useSearchOptionsPos();

  return (
    <FormInputAutocomplete<PointOfSellOptionFragment>
      name="pos"
      label={t('phaseInformationVerification.labelPos')}
      options={posOptions.options}
      getOptionLabel={posOptions.getOptionLabel}
      isOptionEqualToValue={posOptions.isOptionEqualToValue}
      renderOption={renderOptionById(posOptions.getOptionLabel)}
      filterOptions={posOptions.filterOptions}
      loading={posOptions.loading}
      onInputChange={posOptions.onInputChange}
      noOptionsText={posOptions.noOptionsText}
      required
    />
  );
};

export default InputPos;
