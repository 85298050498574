import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { ApolloError, } from '@apollo/client';
import { ApolloCustomGqlErrors, CustomErrorMessage, } from './types';
import { translateError, } from './utils';
import { parseApolloError, } from './parseApolloError';
import { DEFAULT_ERROR, } from './constants';

type Args = {
  forceError?: boolean,
  error?: Error | ApolloError | null,
  customError?: CustomErrorMessage | null,
  apolloCustomGqlErrors?: ApolloCustomGqlErrors,
};

export const useHumanFriendlyError = ({
  forceError,
  error,
  customError,
  apolloCustomGqlErrors,
}: Args): CustomErrorMessage | null => {
  const { t, } = useTranslation();

  const readableError = useMemo<CustomErrorMessage | null>(
    () => {
      try {
        if (customError) return customError;
        if (!error && !forceError) return null;
        if (error instanceof ApolloError) {
          const parsedApolloError = parseApolloError({
            apolloError: error,
            apolloCustomGqlErrors,
            t,
          });
          if (parsedApolloError) return parsedApolloError;
        }
        return translateError(DEFAULT_ERROR, t);
      } catch {
        return translateError(DEFAULT_ERROR, t);
      }    
    },
    [ forceError, error, customError, t, ]
  );

  return readableError;
};
