import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { TaskCompletionStatusEnum, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type TaskCompletionStatusOption = {
  id: TaskCompletionStatusEnum,
  labelKey: string,
  color: string,
};

export const TASK_COMPLETION_STATUS_OPTIONS: TaskCompletionStatusOption[] = [
  {
    id: TaskCompletionStatusEnum.InReview,
    labelKey: 'common:options.taskCompletionStatus.IN_REVIEW',
    color: '#F1E3B3',
  },
  {
    id: TaskCompletionStatusEnum.Denied,
    labelKey: 'common:options.taskCompletionStatus.DENIED',
    color: '#E56E6E',
  },
  {
    id: TaskCompletionStatusEnum.Approved,
    labelKey: 'common:options.taskCompletionStatus.APPROVED',
    color: '#4CB476',
  },
  {
    id: TaskCompletionStatusEnum.New,
    labelKey: 'common:options.taskCompletionStatus.NEW',
    color: '#ebebeb',
  },

];

type GetOptionLabel = (o: TaskCompletionStatusOption) => string;
type GetOptionKey = (o: TaskCompletionStatusOption) => string;

type UseOptionsTaskCompletionStatus = () => {
  options: TaskCompletionStatusOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<TaskCompletionStatusOption>, 
};

const TASK_COMPLETION_STATUS_OPTIONS_OBJ = TASK_COMPLETION_STATUS_OPTIONS.reduce(
  (acc, cur) => ({ ...acc, [cur.id]: cur, }),
  {} as {[key in TaskCompletionStatusEnum]: TaskCompletionStatusOption},
);

export const getOptionTaskCompletionById = (id?: TaskCompletionStatusEnum | null): TaskCompletionStatusOption | null => {
  if (!id) return null;
  return TASK_COMPLETION_STATUS_OPTIONS_OBJ[id] || null;
};

export const useOptionsTaskCompletionStatus: UseOptionsTaskCompletionStatus = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: TASK_COMPLETION_STATUS_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
