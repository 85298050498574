import { useEffect, useState } from 'react';
import { ExportTypesEnum, useExportPurchasesMutation } from 'Apollo/graphql';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { usePurchasesStore } from 'Modules/RegistrationsAdmin/store/PurchasesStore';
import { mapVariablesPurchases } from 'Modules/RegistrationsAdmin/pages/RegistrationAdminPage/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { EXPORT_TYPE_OPTIONS } from 'Utils/options/useOptionsExportTypes';
import { yupResolver } from '@hookform/resolvers/yup';
import { exportTypeSchema } from 'Components/Export/helpers';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ExportTypeForm from 'Components/Export/ExportTypeForm';
import View from './View';

interface Props {
  onClose: () => void;
}

const FetchData = ({ onClose }: Props): JSX.Element => {
  const { t } = useTranslation('registrationsAdmin');
  const [exportMutation, { loading, error, data }] = useExportPurchasesMutation({
    fetchPolicy: 'no-cache',
  });
  const [chosenType, setChosenType] = useState<ExportTypesEnum | null>(null);

  useEffect(() => {
    if (chosenType) {
      const storeState = usePurchasesStore.getState().tablePurchases;
      const { filter, sort } = mapVariablesPurchases(storeState);
      exportMutation({ variables: { filter, sort, type: chosenType } });
    }
  }, [chosenType]);

  const methods = useForm({
    defaultValues: { type: EXPORT_TYPE_OPTIONS[0] },
    resolver: yupResolver(exportTypeSchema),
    mode: 'onTouched',
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    setChosenType(values.type.id);
  });

  if (loading && chosenType)
    return (
      <ModalDrawerBodyLoading onClose={onClose}>
        <Typography variant="body1" color="inherit">
          {t(`common:defaultExport.info`, { name: t('purchases.title') })}
        </Typography>
      </ModalDrawerBodyLoading>
    );

  if ((error || !data?.exportPurchases) && chosenType)
    return (
      <ModalDrawerBodyError onClose={onClose} error={error}>
        <Typography variant="body1" color="inherit">
          {t(`common:defaultExport.info`, { name: t('purchases.title') })}
        </Typography>
      </ModalDrawerBodyError>
    );

  if (data?.exportPurchases && chosenType)
    return (
      <View exportPurchases={data.exportPurchases} onClose={onClose}>
        <Typography variant="body1" color="inherit">
          {t(`common:defaultExport.info`, { name: t('purchases.title') })}
        </Typography>
      </View>
    );
  return (
    <ModalDrawerBody>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <ExportTypeForm onClose={onClose} />
        </form>
      </FormProvider>
    </ModalDrawerBody>
  );
};

export default FetchData;
