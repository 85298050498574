import { SyntheticEvent, FocusEventHandler, CSSProperties, useEffect, } from 'react';
import { useFormContext, useController, } from 'react-hook-form';
import { AutocompleteValue, } from '@mui/material';
import InputAutocompleteClassic, { InputAutocompleteClassicProps, } from 'Components/Inputs/InputAutocompleteClassic';
import { useFormInputStatus, } from '../utils/useFormInputStatus';
import { InputFormErrorMessage, } from '../types';

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<InputAutocompleteClassicProps<T, Multiple, DisableClearable, FreeSolo>, 'value' | 'onChange' | 'name' | 'renderInput'> {
  name: string,
  triggerFields?: string | string[],
  overwriteStyles?: {
    [key: string]: CSSProperties;
  }
};

const FormInputSelect = <
  T,
  Multiple extends boolean = false,
  DisableClearable extends boolean=true,
  FreeSolo extends boolean = false,
>({
  name,
  helperText,
  triggerFields,
  onBlur,
  overwriteStyles,
  multiple,
  ...rest
}: Props<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  const { control, trigger, } = useFormContext();
  const {
    field: { value, onBlur: onBlurField, onChange, },
    fieldState: { error, },
  } = useController({
    name,
    control,
  });

  const handleChange = (e: SyntheticEvent<Element, Event>, newValue: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>) => {
    onChange(newValue);
    if (triggerFields) {
      trigger(triggerFields);
    }
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    onBlurField();
    onBlur?.(e);
  };

  const inputStatus = useFormInputStatus(
    error?.message as InputFormErrorMessage | undefined,
    helperText,
  );

  return (
    <InputAutocompleteClassic<T, Multiple, DisableClearable, FreeSolo>
      id={name}
      value={value}
      helperText={inputStatus.text}
      error={inputStatus.isError}
      onBlur={handleBlur}
      onChange={handleChange}
      overwriteStyles={overwriteStyles}
      multiple={multiple || undefined}
      {...rest}
    />
  );
};

export default FormInputSelect;
