import { InputFormErrorMessage, } from 'Components/Form/types';
import { useFormInputStatus, } from 'Components/Form/utils/useFormInputStatus';
import { PromotionCategoryPurchaseObject, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { useController, useFormContext, } from 'react-hook-form';
import { AnyObject, } from 'Utils/types';
import { PromotionAvailabilityFragment, } from 'Apollo/graphql';
import InputProductCountMultiplePromotion, { InputProductCountMultipleProps, } from '.';
import { InputProductCountMultipleValueOption, } from './types';


interface Props<T extends AnyObject,> extends Omit<InputProductCountMultipleProps<T>, 'value' | 'onChange' | 'error' > {
  name: string,
  triggerFields?: string | string[],
  completePurchasePhase: () => void,
  setActivePromotionPurchase: (index: number, id: string) => void;
  promotionCategoryPurchaseList: PromotionCategoryPurchaseObject;
  handleChangeInPromotionPurchaseForm: (option: PromotionAvailabilityFragment, toggle: "add" | "remove") => void;
};

const FormInputProductCountMultiplePromotion = <T extends AnyObject,>({
  name,
  helperText,
  triggerFields,
  ...rest
}: Props<T>): JSX.Element => {
  const { control, trigger, } = useFormContext();
  const {
    field: { value, onBlur: onBlurField, onChange, },
    fieldState: { error, },
  } = useController({
    name,
    control,
  });

  const handleChange = (newValue: InputProductCountMultipleValueOption<T>[]) => {
    onChange(newValue);
    onBlurField();
    if (triggerFields) trigger(triggerFields);
  };

  const inputStatus = useFormInputStatus(
    error?.message as InputFormErrorMessage | undefined,
    helperText,
  );
  return (
    <InputProductCountMultiplePromotion<T>
      {...rest}
      value={value || []}
      onChange={handleChange}
      error={inputStatus.isError}
      helperText={inputStatus.text}
    />
  );
};

export default FormInputProductCountMultiplePromotion;
