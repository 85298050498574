import { Button, useTheme, } from '@mui/material';
import { GeneratePrizeWheelMutationHookResult, } from 'Apollo/graphql';
import React, { useMemo, useState, } from 'react';
import { Wheel, } from 'react-custom-roulette';
import { useTranslation, } from 'react-i18next';

import { isCzechMarket, isHungarianMarket, } from 'Utils/market';
import { data as czData, } from './data/xmas_2023_cz';
import { data as huData, } from './data/xmas_2023_hu';

type Props = {
  setConfettiVisible: (visible: boolean) => void;
  prizeWheelMutation: GeneratePrizeWheelMutationHookResult[0];
  prizeNumber: number;
};

type SpinButtonProps = {
  handleSpinClick: () => void;
};

const SpinButton = ({ handleSpinClick, }: SpinButtonProps) => {
  const { t, } = useTranslation('prizeWheel');
  const [startSpinButtonVisible, setStartSpinButtonVisible,] = useState(true);
  const theme = useTheme();

  return (
    <Button
      type="submit"
      variant="xmasWheel"
      size="medium"
      onClick={() => {
        handleSpinClick();
        setStartSpinButtonVisible(false);
      }}
      sx={{
        position: 'revert',
        marginTop: '4rem',
        padding: '0.875rem 2.5rem',
        transform: 'scale(calc(1/0.77))',
        fontSize: '1.125rem',
        lineHeight: 'normal',
        visibility: startSpinButtonVisible ? 'unset' : 'hidden',
        [theme.breakpoints.up('sm')]: {
          marginTop: '2rem',
          fontSize: '0.75rem',
        },
        [theme.breakpoints.down('sm')]: {
          marginTop: '1rem',
          fontSize: '0.75rem',
        },
      }}
    >
      {t('spinWheelButton')}
    </Button>
  );
};

const PrizeWheel = ({ setConfettiVisible, prizeWheelMutation, prizeNumber, }: Props) => {
  const [mustSpin, setMustSpin,] = useState(false);

  const data = useMemo(() => {
    if (isCzechMarket) {
      return czData;
    }
    if (isHungarianMarket) {
      return huData;
    }
    throw new Error('Invalid market');
  }, []);

  const handleSpinClick = async () => {
    await prizeWheelMutation();
    setMustSpin(true);
  };

  return (
    <>
      <div
        style={{
          position: 'relative',
          maxWidth: '500px',
          overflow: 'hidden',
        }}
      >
        <img
          src="/images/xmas-wheel/prize_wheel_overlay.svg"
          alt=""
          style={{
            position: 'absolute',
            zIndex: 6,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
          }}
        />
        <div
          style={{
            // the pointer placed under the 45 deg angle - to make it work as intended (with pointer on top) we need to rotate it back
            transform: 'rotate(-45deg) scale(0.88)',
          }}
        >
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={data}
            outerBorderColor="#f2f2f2"
            outerBorderWidth={0}
            innerBorderColor="#f2f2f2"
            innerBorderWidth={0}
            radiusLineColor="#dedede"
            radiusLineWidth={1}
            textColors={['#ffffff', '#000000',]}
            fontSize={50}
            textDistance={85}
            perpendicularText
            backgroundColors={['#BD100F', 'white',]}
            onStopSpinning={() => {
              setConfettiVisible(true);
            }}
            pointerProps={{
              style: {
                // we use overlay on top of the wheel
                display: 'none',
              },
            }}
          />
        </div>
      </div>
      <SpinButton handleSpinClick={handleSpinClick} />
    </>
  );
};

export default React.memo(PrizeWheel, (prevProps, nextProps) => prevProps.prizeNumber === nextProps.prizeNumber);
