import Grid from '@mui/material/Grid';
import { FormInputPhoneNumber, FormInputText, } from 'Components/Form';
import { useTranslation, } from 'react-i18next';

const CustomerRegistrationForm = (): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormInputText name="email" required label={t('createReplacementModal.phaseCustomerRegistration.form.labelEmail')} />
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="firstName" required label={t('createReplacementModal.phaseCustomerRegistration.form.labelFirstName')} />
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="lastName" required label={t('createReplacementModal.phaseCustomerRegistration.form.labelLastName')} />
      </Grid>
      <Grid item xs={12}>
        <FormInputPhoneNumber
          namePhone="phoneNumber"
          required
          namePrefix="phonePrefix"
          label={t('createReplacementModal.phaseCustomerRegistration.form.labelPhone')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="address" label={t('createReplacementModal.phaseCustomerRegistration.form.labelAddress')} />
      </Grid>
    </Grid>
  );
};

export default CustomerRegistrationForm;
