import { useState, } from 'react';
import { styled, } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import IconButtonSquare from 'Components/IconButtonSquare';
import KeyboardArrowUpIcon from 'Utils/svg/KeyboardArrowUpIcon';
import KeyboardArrowDownIcon from 'Utils/svg/KeyboardArrowDownIcon';

export const RootContainer = styled(Box)(({ theme, }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  paddingTop: theme.spacing(2),
}));

export const BoxCollapsed = styled(Box)(({ theme, }) => ({
  paddingTop: theme.spacing(2),
}));

interface Props {
  label: string
  children: React.ReactNode
}

const CollapsibleItem = ({
  label,
  children,
}: Props) => {
  const [ isOpen, setIsOpen, ] = useState(false);

  const handleToggle = () => setIsOpen(s => !s);

  return (
    <RootContainer>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography color="primary" variant="h4">
          {label}
        </Typography>
        <IconButtonSquare
          variant={isOpen ? 'contained' : 'outlined' }
          onClick={handleToggle}
        >
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButtonSquare>
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <BoxCollapsed>
          {children}
        </BoxCollapsed>
      </Collapse>
    </RootContainer>
  );
};

export default CollapsibleItem;
