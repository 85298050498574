import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import ImageAsync from './ImageAsync';

const StyledCard = styled(Card)(({ theme, }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
  padding: theme.spacing(1, 2),
  marginBottom: 1,
  minHeight: `calc(${theme.spacing(2)} + 45px)`,
  backgroundColor: 'white',
}));

const ImageContainer = styled('div')(() => ({
  height: 45,
  minHeight: 45,
  width: 70,
  minWidth: 70,
  marginRight: 8,
}));

const PiecesContainer = styled(Typography)(() => ({
  marginLeft: 'auto',
  fontSize: '1rem',
  lineHeight: '1.35em',
  letterSpacing: '0.25px',
}));

interface Props {
  title: ReactNode;
  imageSrc?: string;
  count?: number;
  deviceCode?: string | null;
  color?: string
}

const ProductCard = ({ title, imageSrc, count, deviceCode,color, }: Props): JSX.Element => {
  const showCount = count !== undefined;
  const { t, } = useTranslation();

  return (
    <StyledCard sx={{backgroundColor:color,}}>
      {imageSrc && (
        <ImageContainer>
          <ImageAsync src={imageSrc} />
        </ImageContainer>
      )}
      <Box display="flex" flexDirection="column">
        <Typography variant="h4" component="div" color="primary">
          {title}
        </Typography>
        {deviceCode && deviceCode.length > 0 && (
            <Typography variant="body0" component="div" color="inherit">
              {deviceCode}
            </Typography>
        )}
      </Box>

      {showCount && <PiecesContainer variant="body1">{t('common:productCard.countShortcut', { count, })}</PiecesContainer>}
    </StyledCard>
  );
};

export default ProductCard;
