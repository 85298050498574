import { FocusEventHandler } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import InputTextClassic, { InputTextClassicProps } from 'Components/Inputs/InputTextClassic';
import { useFormInputStatus } from '../utils/useFormInputStatus';
import { InputFormErrorMessage } from '../types';

interface Props extends Omit<InputTextClassicProps, 'value' | 'onChange' | 'error' | 'name'> {
  name: string;
}

const FormInputText = ({ name, id, helperText, onBlur, ...rest }: Props): JSX.Element => {
  const { control } = useFormContext();
  const {
    field: { value, onBlur: onBlurField, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    onBlurField();
    onBlur?.(e);
  };

  const inputStatus = useFormInputStatus(error?.message as InputFormErrorMessage | undefined, helperText);

  return (
    <InputTextClassic
      {...rest}
      id={id || name}
      name={id || name}
      value={value}
      onChange={onChange}
      onBlur={handleBlur}
      error={inputStatus.isError}
      helperText={inputStatus.text}
    />
  );
};

export default FormInputText;
