import shallow from 'zustand/shallow';
import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import { usePurchaseProductsAvailabilityQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import LoadingBox from 'Components/LoadingBox';
import Logic from './Logic';

const selectStoreData = (s: RegistrationStore) => ({
  registrationEmail: s.registrationEmail,
  purchase: s.purchase,
  goBackToPhase: s.goBackToPhase,
  completePurchasePhase: s.completePurchasePhase,
  setActivePromotionPurchase: s.setActivePromotionPurchase,
  promotionCategoryPurchaseList: s.promotionCategoryPurchaseList,
  isShortRegistration: s.isShortRegistration,
  handleChangeInPromotionPurchaseForm: s.handleChangeInPromotionPurchaseForm,
});

const FetchAvailablePurchases = (): JSX.Element => {
  const { t, } = useTranslation();
  const {
    registrationEmail,
    purchase,
    goBackToPhase,
    completePurchasePhase,
    setActivePromotionPurchase,
    promotionCategoryPurchaseList,
    isShortRegistration,
    handleChangeInPromotionPurchaseForm,
  } = useRegistrationStore(selectStoreData, shallow);

  const { loading, error, data, } = usePurchaseProductsAvailabilityQuery({
    fetchPolicy: 'no-cache',
    variables: {
      customerEmail: registrationEmail,
    },
  });

  const handleBack = () => goBackToPhase('init');

  if (loading) {
    return (
      <>
        <LoadingBox mb={4} />
        <Button
          size="large"
          variant="outlined"
          sx={{ width: { xs: '100%', lg: 'unset', }, }}
          onClick={handleBack}
        >
          {t('common:actions.back')}
        </Button>
      </>
    );
  };
  if (error || !data?.purchaseProductsAvailability) {
    return (
      <>
        <ErrorAlert
          forceError
          error={error}
          mb={4}
        />
        <Button
          size="large"
          variant="outlined"
          sx={{ width: { xs: '100%', lg: 'unset', }, }}
          onClick={handleBack}
        >
          {t('common:actions.back')}
        </Button>
      </>
    );
  };
  
  return (
    <Logic
      purchase={purchase}
      purchaseProductsAvailability={data.purchaseProductsAvailability}
      goBack={handleBack}
      completePurchasePhase={completePurchasePhase}
      setActivePromotionPurchase={setActivePromotionPurchase}
      promotionCategoryPurchaseList={promotionCategoryPurchaseList}
      isShortRegistration={isShortRegistration}
      handleChangeInPromotionPurchaseForm={handleChangeInPromotionPurchaseForm}
    />
  );
};

export default FetchAvailablePurchases;
