import { useFormContext, useController, } from 'react-hook-form';
import InputIntClassic, { InputIntClassicProps, } from 'Components/Inputs/InputIntClassic';
import { useFormInputStatus, } from '../utils/useFormInputStatus';
import { InputFormErrorMessage, } from '../types';

interface Props extends Omit<InputIntClassicProps, 'value' | 'onChange'> {
  name: string,
}

const FormInputInt = ({
  name,
  helperText,
  ...inputProps
}: Props): JSX.Element => {
  const { control, } = useFormContext();

  const {
    field: { value, onBlur, onChange, },
    fieldState: { error, },
  } = useController({
    name,
    control,
  });

  const inputStatus = useFormInputStatus(
    error?.message as InputFormErrorMessage | undefined,
    helperText,
  );

  const handleChangeValue = (newValue: number | null) => {
    onChange(newValue);
  };

  return (
    <InputIntClassic
      {...inputProps}
      value={value as number | null}
      onBlur={onBlur}
      onChange={handleChangeValue}
      error={inputStatus.isError}
      helperText={inputStatus.text}
    />
  );
};

export default FormInputInt;
