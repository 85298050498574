import { Trans, useTranslation, } from 'react-i18next';
import Link from '@mui/material/Link';

const PulzeOn = (): JSX.Element => {
  const { i18n: { language, }, } = useTranslation();
  // TO DO if there will be more languages?
  return (
    <Trans i18nKey="registrationSeller:phaseRegisterCustomer.form.labelAcceptedPulzeOn"
      components={{
        loyalty: (
          <Link
            href="https://www.pulze.com/cs-CZ/vernostni-program-pulze-on/benefity-clenstvi"
            rel="noopener noreferrer"
            target="_blank"
          />
        ),
        conditions: (
          <Link
            href="https://www.pulze.com/cs-CZ/obchodni-podminky"
            rel="noopener noreferrer"
            target="_blank"
          />
        ),
      }}
    />
  );
};

export default PulzeOn;
