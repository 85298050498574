import create from 'zustand';
import { PointsTransactionFragment, PointsTransactionSortableFields, OrderBy, PointOfSellOptionFragment, ChainFragment } from 'Apollo/graphql';
import { ChangeTableFilter, ChangeTableSort, ChangeTableData } from 'Utils/types';
import { PointsTransactionReasonOption } from 'Utils/options/usePointsTransactionReason';
import { PointsTransactionOperationOption } from 'Utils/options/useOptionsOperation';

export type TablePointsTransactionFilter = {
  pos: PointOfSellOptionFragment | null;
  chain: ChainFragment | null;
  id: string;
  author: string;
  reason: PointsTransactionReasonOption | null;
  operation: PointsTransactionOperationOption | null;
};

export type PointsTransactionStore = {
  modalCreatePointsTransaction: {
    isOpen: boolean;
  };
  modalEditPointsTransaction: {
    isOpen: boolean;
    pointsTransaction: PointsTransactionFragment | null;
  };
  modalExportPointsTransaction: {
    isOpen: boolean;
  };
  modalDetailPointsTransaction: {
    isOpen: boolean;
    pointsTransaction: PointsTransactionFragment | null;
  };
  modalRemovePointsTransaction: {
    isOpen: boolean;
    pointsTransaction: PointsTransactionFragment | null;
  };
  tablePointsTransaction: {
    data: {
      sortField: PointsTransactionSortableFields | null;
      sortOrder: OrderBy | null;
      limit: number;
      offset: number;
    };
    filter: TablePointsTransactionFilter;
  };
  openModalCreatePointsTransaction: () => void;
  closeModalCreatePointsTransaction: () => void;
  openModalEditPointsTransaction: (pointsTransaction: PointsTransactionFragment) => void;
  closeModalEditPointsTransaction: () => void;
  openModalDetailPointsTransaction: (pointsTransaction: PointsTransactionFragment) => void;
  closeModalDetailPointsTransaction: () => void;
  openModalRemovePointsTransaction: (pointsTransaction: PointsTransactionFragment) => void;
  closeModalRemovePointsTransaction: () => void;
  openModalExportPointsTransaction: () => void;
  closeModalExportPointsTransaction: () => void;
  changeTableData: ChangeTableData;
  changeTableFilter: ChangeTableFilter;
  changeTableSort: ChangeTableSort;
};

export const UsePointsTransactionStore = create<PointsTransactionStore>((set) => ({
  modalCreatePointsTransaction: {
    isOpen: false,
  },
  modalExportPointsTransaction: {
    isOpen: false,
  },
  modalDetailPointsTransaction: {
    isOpen: false,
    pointsTransaction: null,
  },
  modalEditPointsTransaction: {
    isOpen: false,
    pointsTransaction: null,
  },
  modalRemovePointsTransaction: {
    isOpen: false,
    pointsTransaction: null,
  },
  tablePointsTransaction: {
    data: {
      sortField: null,
      sortOrder: null,
      limit: 10,
      offset: 0,
    },
    filter: {
      pos: null,
      chain: null,
      id: '',
      author: '',
      reason: null,
      operation: null,
    },
  },
  /*
   * Modal Edit
   */
  openModalCreatePointsTransaction: () =>
    set({
      modalCreatePointsTransaction: {
        isOpen: true,
      },
    }),
  closeModalCreatePointsTransaction: () =>
    set({
      modalCreatePointsTransaction: {
        isOpen: false,
      },
    }),
  openModalExportPointsTransaction: () =>
    set({
      modalExportPointsTransaction: {
        isOpen: true,
      },
    }),
  closeModalExportPointsTransaction: () =>
    set({
      modalExportPointsTransaction: {
        isOpen: false,
      },
    }),
  openModalEditPointsTransaction: (pointsTransaction) =>
    set({
      modalEditPointsTransaction: {
        isOpen: true,
        pointsTransaction,
      },
    }),
  closeModalEditPointsTransaction: () =>
    set((state) => ({
      modalEditPointsTransaction: {
        ...state.modalEditPointsTransaction,
        isOpen: false,
      },
    })),
  /*
   * Modal Detail
   */
  openModalDetailPointsTransaction: (pointsTransaction) =>
    set({
      modalDetailPointsTransaction: {
        isOpen: true,
        pointsTransaction,
      },
    }),
  closeModalDetailPointsTransaction: () =>
    set((state) => ({
      modalDetailPointsTransaction: {
        ...state.modalDetailPointsTransaction,
        isOpen: false,
      },
    })),

  /*
   * Modal Remove
   */
  openModalRemovePointsTransaction: (pointsTransaction) =>
    set({
      modalRemovePointsTransaction: {
        isOpen: true,
        pointsTransaction,
      },
    }),
  closeModalRemovePointsTransaction: () =>
    set((state) => ({
      modalRemovePointsTransaction: {
        ...state.modalRemovePointsTransaction,
        isOpen: false,
        point: null,
      },
    })),

  /**
   * Point table
   */
  changeTableData: (name, value) =>
    set((state) => ({
      tablePointsTransaction: {
        ...state.tablePointsTransaction,
        data: {
          ...state.tablePointsTransaction.data,
          [name]: value,
        },
      },
    })),
  changeTableFilter: (name, value) =>
    set((state) => ({
      tablePointsTransaction: {
        ...state.tablePointsTransaction,
        data: {
          ...state.tablePointsTransaction.data,
          offset: 0,
        },
        filter: {
          ...state.tablePointsTransaction.filter,
          [name]: value,
        },
      },
    })),
  changeTableSort: (sortField, sortOrder) =>
    set((state) => ({
      tablePointsTransaction: {
        ...state.tablePointsTransaction,
        data: {
          ...state.tablePointsTransaction.data,
          sortField: sortField as PointsTransactionSortableFields,
          sortOrder,
        },
      },
    })),
}));
