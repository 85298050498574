import create from 'zustand';
import { ChainFragment, ClusterFragment, OrderBy, PromotionFragment, PromotionSortableFields, } from 'Apollo/graphql';
import { ChangeTableFilter, ChangeTableSort, ChangeTableData, } from 'Utils/types';

export type TablePromotionsFilter = {
  id: string,
  name: string,
  description: string,
  offerLimitPerCustomer: number | null,
  chainId: ChainFragment | null
  clusterId: ClusterFragment | null,
};

export type PromotionsStore = {
  modalCreatePromotion: {
    isOpen: boolean,
  },
  modalEditPromotion: {
    isOpen: boolean,
    promotion: PromotionFragment | null,
  },
  modalRemovePromotion: {
    isOpen: boolean,
    promotion: PromotionFragment | null,
  },
  tablePromotions: {
    data: {
      sortField: PromotionSortableFields | null,
      sortOrder: OrderBy | null,
      limit: number,
      offset: number,
    },
    filter: TablePromotionsFilter,
  },
  openModalCreatePromotion: () => void,
  closeModalCreatePromotion: () => void,
  openModalEditPromotion: (promotion: PromotionFragment) => void,
  closeModalEditPromotion: () => void,
  openModalRemovePromotion: (promotion: PromotionFragment) => void,
  closeModalRemovePromotion: () => void,
  changeTableData: ChangeTableData,
  changeTableFilter: ChangeTableFilter,
  changeTableSort: ChangeTableSort,
};

export const usePromotionsStore = create<PromotionsStore>((set) => ({
  modalCreatePromotion: {
    isOpen: false,
  },
  modalEditPromotion: {
    isOpen: false,
    promotion: null,
  },
  modalRemovePromotion: {
    isOpen: false,
    promotion: null,
  },
  tablePromotions: {
    data: {
      sortField: null,
      sortOrder:  null,
      limit: 10,
      offset: 0,
    },
    filter: {  
      id: '',
      name: '',
      description: '',
      offerLimitPerCustomer: null,
      chainId: null,
      clusterId: null,
    },
  },
  /*
   * Modal Create
   */
  openModalCreatePromotion: () => set({
    modalCreatePromotion: {
      isOpen: true,
    },
  }),
  closeModalCreatePromotion: () => set({
    modalCreatePromotion: {
      isOpen: false,
    },
  }),

  /*
   * Modal Edit
   */
  openModalEditPromotion: (promotion) => set({
    modalEditPromotion: {
      isOpen: true,
      promotion,
    },
  }),
  closeModalEditPromotion: () => set((state) => ({
    modalEditPromotion: {
      ...state.modalEditPromotion,
      isOpen: false,
    },
  })),

  /*
   * Modal Remove
   */
  openModalRemovePromotion: (promotion) => set({
    modalRemovePromotion: {
      isOpen: true,
      promotion,
    },
  }),
  closeModalRemovePromotion: () => set((state) => ({
    modalRemovePromotion: {
      ...state.modalRemovePromotion,
      isOpen: false,
      promotion: null,
    },
  })),

  /**
   * Promotions table
   */
   changeTableData: (name, value) => set((state) => ({
    tablePromotions: {
      ...state.tablePromotions,
      data: {
        ...state.tablePromotions.data,
        [name]: value,
      },
    },
   })),
   changeTableFilter: (name, value) => set((state) => ({
    tablePromotions: {
      ...state.tablePromotions,
      data: {
        ...state.tablePromotions.data,
        offset: 0,
      },
      filter: {
        ...state.tablePromotions.filter,
        [name]: value,
      },
    },
   })), 
   changeTableSort: (sortField, sortOrder) => set((state) => ({
    tablePromotions: {
      ...state.tablePromotions,
      data: {
        ...state.tablePromotions.data,
        sortField: sortField as PromotionSortableFields,
        sortOrder,
      },
    },
   })),
}));
