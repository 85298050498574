import { styled, } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { MyPointsTransactionFragment, } from 'Apollo/graphql';
import { evaluateOperationSymbol, } from 'Modules/PointsTransactionAdmin/pages/PointsTransactionAdminPage/PointsTransactionTable/utils';
import { useTranslation, } from 'react-i18next';
import { valueOrEmpty, } from 'Utils/parsers';

type Props = {
  data: MyPointsTransactionFragment;
};

const Item = styled(ListItem)(({ theme,}) => ({
  display: 'block',
  padding: theme.spacing(1.5, 2),
}));

const TransActionChunk = ({ data, }: Props): JSX.Element => {
  const { t, } = useTranslation('myPoints');
  const dateFormatted = valueOrEmpty(`${t('common:date.fullDateTime', { date: new Date(data.updatedAt), })}`);

  return (
    <Item divider>
      <Typography color="textSecondary" mb={0.5}>{dateFormatted}</Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
      >
        <Typography variant="body0">{data.reason.name}</Typography>
        <Typography variant="h3" color={data.operation === 'Plus' ? 'success.main' : 'inherit'}>{`${evaluateOperationSymbol(data.operation)} ${data.amount} ${t(
          'generic.points',
        )}`}</Typography>
      </Box>
    </Item>
  );
};
export default TransActionChunk;
