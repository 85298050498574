import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSnackbar, } from 'notistack';
import { CustomerRegistrationFragment, useDeleteCustomerMutation, } from 'Apollo/graphql';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import { updateRegistrationRemove, } from '../../utils/registrationCache';
import View from './View';

interface Props {
  registration?: CustomerRegistrationFragment | null,
  onClose: () => void,
  onCloseLogic: () => void,
};

const Logic = ({
  registration,
  onClose,
  onCloseLogic,
}: Props): JSX.Element => {
  const preservedRegistration = usePreserveValue(registration);
  const { t, } = useTranslation('registrationsAdmin');
  const { enqueueSnackbar, } = useSnackbar();

  const [ removeMutation, removeMutationResult, ] = useDeleteCustomerMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('removeRegistrationModal.notifRemovedTitle') as string,
        message: t('removeRegistrationModal.notifRemovedMessage', { id: preservedRegistration.id, }),
        variant: 'common',
      });
      onCloseLogic();
      onClose();
    },
    update: updateRegistrationRemove,
  });

  const handleSubmit = useCallback(
    () => {
      removeMutation({
        variables: { id: preservedRegistration.id, },
      });
    },
    [ preservedRegistration, removeMutation, ],
  );

  return (
    <View
      registration={preservedRegistration}
      removeMutationResult={removeMutationResult}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default Logic;
