import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const KeyboardArrowUpIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 27 27"
    fill="none"
    {...props}
  >
    <path
      d="M5.46 17.256c-.03-.358.13-.624.358-.881 2.136-2.406 4.268-4.816 6.397-7.23.748-.85 1.774-.867 2.53-.019a5312.3 5312.3 0 0 0 6.415 7.18c.422.473.504 1.082.208 1.572a1.247 1.247 0 0 1-1.417.537 1.19 1.19 0 0 1-.511-.337 1763.278 1763.278 0 0 1-5.634-6.398c-.266-.303-.313-.303-.578 0l-5.614 6.382a1.216 1.216 0 0 1-2.08-.367 2.164 2.164 0 0 1-.074-.439z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default KeyboardArrowUpIcon;
