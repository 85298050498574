import { useTranslation, } from 'react-i18next';
import { FormInputText, FormInputInt, FormInputAutocomplete, } from 'Components/Form';
import Grid from '@mui/material/Grid';
import { useOptionsYesNo, } from 'Utils/options/useOptionsYesNo';
import { renderOptionById, } from 'Utils/helpers';
import { NodeProps, } from '../types';
import { buildNodeInputName, } from '../utils';
import FormInputNodeType from '../FormInputNodeType';
import NodeLayout from '../NodeLayout';

const NodeYesNo = ({
  index,
  assessmentConfig,
  onRemove,
  onChangeType,
}: NodeProps): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const { enableCorrectAnswer, } = assessmentConfig;
  const optionsYesNo = useOptionsYesNo();

  return (
    <NodeLayout
      index={index}
      onRemove={onRemove}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInputNodeType
            index={index}
            onChangeType={onChangeType}
          />
        </Grid>
        <Grid item xs={12} sm={enableCorrectAnswer ? 9 : 12}>
          <FormInputText
            name={buildNodeInputName(index, 'question')}
            label={t('assessment.labelQuestion')}
            required
          />
        </Grid>
        {enableCorrectAnswer && (
          <>
            <Grid item xs={12} sm={3}>
              <FormInputInt
                name={buildNodeInputName(index, 'points')}
                label={t('assessment.labelPoints')}
                required
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormInputAutocomplete
                name={buildNodeInputName(index, 'correctAnswer')}
                getOptionLabel={optionsYesNo.getOptionLabel}
                isOptionEqualToValue={optionsYesNo.isOptionEqualToValue}
                options={optionsYesNo.options}
                renderOption={renderOptionById(optionsYesNo.getOptionLabel)}
                label={t('assessment.labelCorrectAnswer')}
                placeholder={t('common:select.placeholder')}
                required
              />
            </Grid>
          </>
        )}
      </Grid>
    </NodeLayout>
  );
};

export default NodeYesNo;
