import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const CheckIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.95 3.879a1 1 0 0 0-1.414-1.415l-4.95 4.95-2.122-2.121A1 1 0 0 0 1.05 6.707L3.88 9.535a1 1 0 0 0 1.414 0z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CheckIcon;
