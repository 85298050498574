import { Query, } from '@cubejs-client/core';
import { zodResolver, } from '@hookform/resolvers/zod';
import { Grid, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import ChartDetailLayout from 'Components/Dashboard/ChartDetailLayout';
import { totalNumberOfPointsQuery, } from 'Components/Dashboard/PointsAndTasks/queries';
import TotalNumberOfPoints from 'Components/Dashboard/PointsAndTasks/TotalNumberOfPoints';
import { useMemo, } from 'react';
import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { OptionTypes, ChartType, } from 'Utils/Charts/types';
import useDashboardFilter from 'Utils/Charts/UseDashboardFilter';
import { SourceAndUserRoleValidationSchema, } from 'Utils/Charts/ValidationSchemas';
import { z, } from 'zod';

type ValidationSchema = z.infer<typeof SourceAndUserRoleValidationSchema>;

const TotalPointsPage = () => {
  const { t, } = useTranslation('dashboards');

  const filtration = [OptionTypes.source, OptionTypes.role,];
  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(SourceAndUserRoleValidationSchema),
    defaultValues: {
      chainIds: [],
      posIds: [],
      userRoleId: null,
    },
  });
  const watch = methods.watch();

  const {filtersArray,} = useDashboardFilter({
    chainIds: {
      operator: 'equals',
      values: watch.chainIds ,
      filterMember: 'user.chainid',
    },
    posIds: {
      operator: 'equals',
      values: watch.posIds ,
      filterMember: 'user.posid',
    },
    userRoleId: {
      operator: 'equals',
      value: watch.userRoleId ,
      filterMember: 'user.role',
    },
  });

  const query = useMemo(
    () =>
      totalNumberOfPointsQuery({
        showNullValues: true,
        filters: filtersArray,
      }),
    [filtersArray,],
  );

  return (
    <AppContainer>
      <ChartDetailLayout
        pageTitle={t('common:dashboardSidebar.tasksAndPoints')}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <TotalNumberOfPoints
                showSlug={false}
                filtration={filtration}
                chartFilter={filtersArray}
              />
            </FormProvider>
          </Grid>
          <Grid item xs={12}>
            <ChartRenderer
              query={query}
              type={ChartType.table}
              pivotConfig={{
                x: ['points_transaction.createdat',],
                y: ['measures',],
                fillMissingDates: true,
                joinDateRange: false,
              }}
            />
          </Grid>
        </Grid>
      </ChartDetailLayout>
    </AppContainer>
  );
};

export default TotalPointsPage;
