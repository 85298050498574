import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { PointsTransactionStore, UsePointsTransactionStore } from 'Modules/PointsTransactionAdmin/store/PointsTransactionStore';
import { useTranslation } from 'react-i18next';
import FetchData from './FetchData';

const selectModalData = (s: PointsTransactionStore) => s.modalExportPointsTransaction;
const selectClose = (s: PointsTransactionStore) => s.closeModalExportPointsTransaction;

const ModalExportPointsTransactions = (): JSX.Element => {
  const { t } = useTranslation('pointsTransactionAdmin');
  const { isOpen } = UsePointsTransactionStore(selectModalData);
  const onClose = UsePointsTransactionStore(selectClose);

  return (
    <ModalDrawer onClose={onClose} open={isOpen}>
      <ModalDrawerTitle onClose={onClose}>{t(`common:defaultExport.title`, { name: t('pointsTransactions.title') })}</ModalDrawerTitle>
      <FetchData onClose={onClose} />
    </ModalDrawer>
  );
};

export default ModalExportPointsTransactions;
