import shallow from 'zustand/shallow';
import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementAdmin/store/ReplacementStore';
import FetchData from './FetchData';

const selectStoreData = (s: ReplacementStore) =>({
  isOpen: s.modalDetailReplacement.isOpen,
  replacement: s.modalDetailReplacement.replacement,
  onClose: s.closeModalDetailReplacement,
  onOpenRemove: s.openModalRemoveReplacement,
});

const ModalDetailReplacement = (): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  const {
    isOpen,
    replacement,
    onClose,
    onOpenRemove,
  } = useReplacementStore(selectStoreData, shallow);


  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!replacement}
    >
      <ModalDrawerTitle
        onClose={onClose}
      >
        {t('detailReplacementModal.title')}
      </ModalDrawerTitle>
      <FetchData
        replacement={replacement}
        onClose={onClose}
        onOpenRemove={onOpenRemove}
      />
    </ModalDrawer>
  );
};

export default ModalDetailReplacement;
