import { styled, } from '@mui/material/styles';
import { VideoContentBlockFragment, } from 'Apollo/graphql';
import VideoPlayer from 'Components/VideoPlayer';
import { BlockProps, } from '../types';

const VideoContainer = styled('div')(({ theme, }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
  marginBottom: theme.spacing(2),

  '& > video': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
}));

const BlockVideo = ({
  block,
}: BlockProps<VideoContentBlockFragment>): JSX.Element => {
  return (
    <VideoContainer>
      <VideoPlayer videoSrc={block.file.publicUrl} />
    </VideoContainer>
  );
};

export default BlockVideo;
