import { DashboardLayoutItemName, } from 'Apollo/graphql';
import ActiveInactiveChains from 'Components/Dashboard/Chains/ActiveInactiveChains';
import TopChainsInRegistrations from 'Components/Dashboard/Chains/TopChainsInRegistrations';
import TopChainsInReplacements from 'Components/Dashboard/Chains/TopChainsInReplacements';
import TotalNumberOfAvailableGiftCodes from 'Components/Dashboard/ImportedPlGiftCodes/TotalNumberOfAvailableGiftCodes';
import TotalNumberOfSentGiftCodes from 'Components/Dashboard/ImportedPlGiftCodes/TotalNumberOfSentGiftCodes';
import NumberOfActiveTasks from 'Components/Dashboard/PointsAndTasks/NumberOfActiveTasks';
import NumberOfTaskCompletions from 'Components/Dashboard/PointsAndTasks/NumberOfTaskCompletions';
import PointsCategories from 'Components/Dashboard/PointsAndTasks/PointsCategory';
import TotalNumberOfPoints from 'Components/Dashboard/PointsAndTasks/TotalNumberOfPoints';
import ActiveInactivePos from 'Components/Dashboard/POS/ActiveInactivePos';
import TopPosInRegistrations from 'Components/Dashboard/POS/TopPosInRegistrations';
import TopPosInReplacements from 'Components/Dashboard/POS/TopPosInReplacements';
import SplitOfTotalRegistrations from 'Components/Dashboard/Registrations/SplitOfTotalRegistrations';
import GenderTitle from 'Components/Dashboard/Registrations/GenderTitle';
import MethodOfVerification from 'Components/Dashboard/Registrations/MethodOfVerification';
import PreviouslyOwnedDevice from 'Components/Dashboard/Registrations/PreviouslyOwnedDevice';
import PurchasedProduct from 'Components/Dashboard/Registrations/PurchasedProduct';
import ReDoi from 'Components/Dashboard/Registrations/ReDoi';
import RegistrationsByCities from 'Components/Dashboard/Registrations/RegistrationsByCities';
import TotalNumberOfRegistrations from 'Components/Dashboard/Registrations/TotalNumberOfRegistrations';
import TotalNumberOfRepurchases from 'Components/Dashboard/Registrations/TotalNumberOfRepurchases';
import TopReasonsForReplacement from 'Components/Dashboard/Replacements/TopReasonsForReplacement';
import TotalNumberOfReplacement from 'Components/Dashboard/Replacements/TotalNumberOfReplacements';
import ActiveInactiveUsers from 'Components/Dashboard/Users/ActiveInactiveUsers';
import UsersByRoles from 'Components/Dashboard/Users/UsersByRoles';
import { ChartBaseProps, } from './types';

export const ChartMap: Record<
  DashboardLayoutItemName,
  React.ElementType<ChartBaseProps>
> = {
  CHAINS_ACTIVE_CHAINS: ActiveInactiveChains,
  CHAINS_TOP_10_REGISTRATIONS: TopChainsInRegistrations,
  CHAINS_TOP_10_REPLACEMENTS: TopChainsInReplacements,

  GIFT_CODES_TOTAL_NUMBER_OF_AVAILABLE_GIFT_CODES: TotalNumberOfAvailableGiftCodes,
  GIFT_CODES_TOTAL_NUMBER_OF_SEND_GIFT_CODES_BY_CHAIN: TotalNumberOfSentGiftCodes,

  POINTS_AND_TASKS_NUMBER_OF_ACTIVE_TASKS: NumberOfActiveTasks,
  POINTS_AND_TASKS_NUMBER_OF_TASKS_COMPLETIONS: NumberOfTaskCompletions,
  POINTS_AND_TASKS_POINTS_CATEGORY: PointsCategories,
  POINTS_AND_TASKS_TOTAL_NUMBER_OF_POINTS: TotalNumberOfPoints,

  POINTS_OF_SALE_ACTIVE_POS: ActiveInactivePos,
  POINTS_OF_SALE_TOP_10_REGISTRATIONS: TopPosInRegistrations,
  POINTS_OF_SALE_TOP_10_REPLACEMENTS: TopPosInReplacements,

  REGISTRATIONS_CONSUMER_AGE_GROUPS: SplitOfTotalRegistrations,
  REGISTRATIONS_CONSUMER_TITLE_GENDER: GenderTitle,
  REGISTRATIONS_METHOD_OF_VERIFICATION: MethodOfVerification,
  REGISTRATIONS_PREVIOUSLY_OWNED_DEVICE: PreviouslyOwnedDevice,
  REGISTRATIONS_PURCHASED_PRODUCT: PurchasedProduct,
  REGISTRATIONS_RE_DOI: ReDoi,
  REGISTRATIONS_TOP_10_CITIES: RegistrationsByCities,
  REGISTRATIONS_TOTAL_NUMBER_OF_REGISTRATIONS: TotalNumberOfRegistrations,
  REGISTRATIONS_TOTAL_NUMBER_OF_RE_PURCHASES: TotalNumberOfRepurchases,

  REPLACEMENTS_TOP_10_REASONS_FOR_REPLACEMENT: TopReasonsForReplacement,
  REPLACEMENTS_TOTAL_NUMBER_OF_REPLACEMENTS: TotalNumberOfReplacement,

  USERS_ACTIVE_USERS: ActiveInactiveUsers,
  USERS_USER_ROLES: UsersByRoles,
};
