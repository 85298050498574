import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import { ExportTypesEnum, useExportPointsTransactionsMutation } from 'Apollo/graphql';
import ExportTypeForm from 'Components/Export/ExportTypeForm';
import { exportTypeSchema } from 'Components/Export/helpers';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import { mapVariables } from 'Modules/PointsTransactionAdmin/pages/PointsTransactionAdminPage/PointsTransactionTable/utils';
import { UsePointsTransactionStore } from 'Modules/PointsTransactionAdmin/store/PointsTransactionStore';
import { EXPORT_TYPE_OPTIONS } from 'Utils/options/useOptionsExportTypes';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import View from './View';

interface Props {
  onClose: () => void;
}

const FetchData = ({ onClose }: Props): JSX.Element => {
  const { t } = useTranslation('pointsTransactionAdmin');
  const [exportMutation, { loading, error, data }] = useExportPointsTransactionsMutation({
    fetchPolicy: 'no-cache',
  });

  const [chosenType, setChosenType] = useState<ExportTypesEnum | null>(null);

  const methods = useForm({
    defaultValues: { type: EXPORT_TYPE_OPTIONS[0] },
    resolver: yupResolver(exportTypeSchema),
    mode: 'onTouched',
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    setChosenType(values.type.id);
  });

  useEffect(() => {
    if (chosenType) {
      const storeState = UsePointsTransactionStore.getState().tablePointsTransaction;
      const { filter, sort } = mapVariables(storeState);
      exportMutation({ variables: { filter, sort, exportType: chosenType } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenType]);

  if (loading && chosenType)
    return (
      <ModalDrawerBodyLoading onClose={onClose}>
        <Typography variant="body1" color="inherit">
          {t(`common:defaultExport.info`, { name: t('pointsTransactions.title') })}
        </Typography>
      </ModalDrawerBodyLoading>
    );

  if ((error || !data?.exportPointsTransactions) && chosenType)
    return (
      <ModalDrawerBodyError onClose={onClose} error={error}>
        <Typography variant="body1" color="inherit">
          {t(`common:defaultExport.info`, { name: t('pointsTransactions.title') })}
        </Typography>
      </ModalDrawerBodyError>
    );
  if (data?.exportPointsTransactions && chosenType)
    return (
      <View exportRegistrations={data.exportPointsTransactions} onClose={onClose}>
        <Typography variant="body1" color="inherit">
          {t(`common:defaultExport.info`, { name: t('pointsTransactions.title') })}
        </Typography>
      </View>
    );
  return (
    <ModalDrawerBody>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <ExportTypeForm onClose={onClose} />
        </form>
      </FormProvider>
    </ModalDrawerBody>
  );
};

export default FetchData;
