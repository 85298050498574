/* eslint-disable no-underscore-dangle */
import { RegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import shallow from 'zustand/shallow';
import LoadingBox from 'Components/LoadingBox';
import { CzGiftCodeStoreStore, useCzGiftCodeStore, } from 'Modules/GiftCodeSeller/store/CzGiftCodeStore';
import EmailAndSmsVerification from './VerificationOptions/EmailAndSmsVerification';
import GrpcEmailVerification from './VerificationOptions/GrpcEmailVerification';
import SkipVerification from './VerificationOptions/SkipVerification';

const selectStoreData = (s: CzGiftCodeStoreStore) => ({
  customerVerificationResult: s.customerVerificationResult,
  goBackToPhase: s.goBackToPhase,
  completeCustomerVerificationPhase: s.completeCustomerVerificationPhase,
  storeVerification: s.verification,
  customer: s.customer,
  customerRegistration: s.customerRegistration,
});
const PhaseCustomerVerification = (): JSX.Element => {
  const { goBackToPhase, completeCustomerVerificationPhase, customerVerificationResult, customer, storeVerification, customerRegistration, } =
    useCzGiftCodeStore(selectStoreData, shallow);
  const goBack = () => {
    goBackToPhase('registerCustomer');
  };
  if (customerVerificationResult?.__typename === 'CustomerVerificationStartResultSkipVerification' && customerVerificationResult.skipVerification) {
    return <SkipVerification completeCustomerVerificationPhase={completeCustomerVerificationPhase} />;
  }

  if (customerVerificationResult?.__typename === 'CustomerVerificationStartResultEmailGrpc') {
    return (
      <GrpcEmailVerification
        completeCustomerVerificationPhase={completeCustomerVerificationPhase}
        goBackToPhase={goBack}
        verification={customerVerificationResult}
      />
    );
  }
  if (
    customerVerificationResult?.__typename === 'CustomerVerificationStartResultSms' ||
    customerVerificationResult?.__typename === 'CustomerVerificationStartResultSmsGrpc' ||
    customerVerificationResult?.__typename === 'CustomerVerificationStartResultEmail'
  ) {
    const verification: RegistrationStore['verification'] = {
      ...storeVerification,
      method: customerVerificationResult.method,
      value: customerVerificationResult.verificatedValue,
    };
    return (
      <EmailAndSmsVerification
        completeCustomerVerificationPhase={completeCustomerVerificationPhase}
        goBackToPhase={goBackToPhase}
        goBack={goBack}
        verification={verification}
        customer={customer}
        method={customerVerificationResult.method}
        customerRegistration={customerRegistration}
      />
    );
  }
  return <LoadingBox />;
};

export default PhaseCustomerVerification;
