import { AuthUserFragment, Claim, FeatureFlagObjectFragment, PointOfSell, } from 'Apollo/graphql';

export type ClaimCheckStrategy = 'all' | 'any';
export type PosFlagOption = 'giftCodesEnabled' | 'shortRegistrationEnabled' | 'standardRegistrationEnabled'; 

export const isPermitted = (
  authUser?: AuthUserFragment | null,
  claimsRequired?: Claim[] | null,
  featureFlagRequired?: keyof FeatureFlagObjectFragment | null,
  posFlagRequired?: PosFlagOption | null,
  claimCheckStrategy: ClaimCheckStrategy = 'all',
): boolean => {
  if (!authUser) {
    return false;
  }

  if (!isFeatureFlagEnabled(authUser, featureFlagRequired)) {
    return false;
  }

  if (!hasUserPermission(authUser, claimsRequired, claimCheckStrategy)) {
    return false;
  }

  if (!isPosFlagEnabled(authUser, posFlagRequired)) {
    return false;
  }

  return true;
};

export const isFeatureFlagEnabled = (
  authUser: AuthUserFragment, 
  flag?: keyof FeatureFlagObjectFragment | null
): boolean => {
  return !flag || Boolean(authUser.featureFlagsObject[flag]);
}

export const hasUserPermission = (
  authUser: AuthUserFragment,
  claimsRequired?: Claim[] | null,
  claimsCheckStrategy: ClaimCheckStrategy = 'all',
): boolean => {
  if (authUser.roleCode === 'SUPER_ADMIN' || !claimsRequired) {
    return true;
  }

  return claimsCheckStrategy === 'all'
    ? claimsRequired.every(perm => authUser.claims.includes(perm))
    : claimsRequired.some(perm => authUser.claims.includes(perm));
}

export const isPosFlagEnabled = (
  authUser: AuthUserFragment,
  posFlag?: PosFlagOption | null,
): boolean => {
  if (!posFlag || (!authUser.pointOfSell && (!authUser.pointsOfSell || authUser.pointsOfSell.length === 0))) {
    return true;
  }

  const pointsOfSell = authUser.pointOfSell ? [authUser.pointOfSell] : authUser.pointsOfSell;

  return pointsOfSell?.some((p) => p[posFlag] === true) ?? true;
}
