import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';

const LinkButton = styled(Link)<{
  component?: string;
  disabled: boolean;
}>(({ theme, disabled }) => ({
  ...(disabled && {
    color: theme.palette.action.disabled,
    textDecoration: 'none',
    cursor: 'inherit',
  }),
}));

LinkButton.defaultProps = {
  component: 'button',
};

export default LinkButton;
