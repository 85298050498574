import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import CardLayout from './CardLayout';

const SkeletonItem = () => {
  return (
    <CardLayout
      title={(
        <Skeleton
          variant="text"
          width="60%"
        />
      )}
      date={(
        <Skeleton
          variant="text"
          width="30%"
        />
      )}
    >
      <Typography>
        <Skeleton
          variant="text"
          width="100%"
        />
        <Skeleton
          variant="text"
          width="100%"
        />
        <Skeleton
          variant="text"
          width="40%"
        />
      </Typography>
    </CardLayout>
  );
};

export default SkeletonItem;
