import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { usePointOfSellStore, PointOfSellStore, } from 'Modules/PointOfSell/store/PointOfSellStore';
import Layout from './Layout';

const selectModalData = (s: PointOfSellStore) => s.modalImportPos;
const selectClose = (s: PointOfSellStore) => s.closeModalImportPos;

const ModalImportPos = (): JSX.Element => {
  const { isOpen, } = usePointOfSellStore(selectModalData);
  const onClose = usePointOfSellStore(selectClose);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen}
    >
      <Layout
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalImportPos;
