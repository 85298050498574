import Typography from '@mui/material/Typography';
import { useTranslation, } from 'react-i18next';
import shallow from 'zustand/shallow';
import moment from 'moment';
import { Button, } from '@mui/material';
import { CzGiftCodeStoreStore, useCzGiftCodeStore, } from 'Modules/GiftCodeSeller/store/CzGiftCodeStore';
import { GoBackButton, } from '../utils';

const selectStoreData = (s: CzGiftCodeStoreStore) => ({
  giftCode: s.giftCode,
  resetStore: s.resetStore,
});

const PhaseComplete = (): JSX.Element => {
  const { giftCode, resetStore, } = useCzGiftCodeStore(selectStoreData, shallow);
  const { t, } = useTranslation('giftCodeSeller');
  return (
    <>
      <Typography variant="h1" sx={{ mb: 2, }}>
        {t('form.giftCodeApplied')}
      </Typography>
      <Typography sx={{ mb: 3, }}>{t('form.giftCodeAppliedInfo', { giftCode: giftCode?.code, })}</Typography>
      <Typography sx={{ mb: 3, }}>{moment(new Date()).format('YYYY-MM-DD hh:mm:ss')}</Typography>
      <Button href="/" size="large" color="primary" sx={{ width: { xs: '100%', lg: 'unset', marginRight: '1rem', }, }}>
        {t('form.btnFinish')}
      </Button>
      <GoBackButton goBackFunction={resetStore} translation="giftCodeSeller:form.btnNewSearch" />
    </>
  );
};

export default PhaseComplete;
