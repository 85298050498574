import { useTranslation, } from 'react-i18next';
import Grid from '@mui/material/Grid';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { YesNoAssessmentBlockFragment, } from 'Apollo/graphql';
import { BlockProps, } from '../types';
import BlockLayout from '../BlockLayout';

const BlockYeasNo = ({
  index,
  block,
  showAssessmentCorrectAnswers,
}: BlockProps<YesNoAssessmentBlockFragment>): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const correctAnswer = block.correctAnswerIndex === null || block.correctAnswerIndex === undefined
    ? '-'
    : block.correctAnswerIndex === 0
      ? t('common:options.yesNo.yes')
      : t('common:options.yesNo.no');

  return (
    <BlockLayout
      index={index}
      type={block.type}
    >

      <Grid container spacing={2}>
        <Grid item xs={12} sm={showAssessmentCorrectAnswers ? 9 : 12}>
          <NotEditableTextClassic
            label={t('assessment.labelQuestion')}
          >
            {block.question}
          </NotEditableTextClassic>
        </Grid>
        {showAssessmentCorrectAnswers && (
          <Grid item xs={12} sm={showAssessmentCorrectAnswers ? 3 : 12}>
            <NotEditableTextClassic
              label={t('assessment.labelPoints')}
            >
              {block.numberOfPoints}
            </NotEditableTextClassic>
          </Grid>
        )}
        {showAssessmentCorrectAnswers && (
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('assessment.labelCorrectAnswer')}
            >
              {correctAnswer}
            </NotEditableTextClassic>
          </Grid>
        )}
      </Grid>
      
    </BlockLayout>
  );
};

export default BlockYeasNo;
