import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ModalDrawerBody from './ModalDrawerBody';

interface Props {
  onClose: () => void,
  children?: ReactNode,
};

const ModalDrawerBodyLoading = ({
  onClose,
  children,
}: Props): JSX.Element => {
  const { t, } = useTranslation('assortmentAdmin');

  return (
    <ModalDrawerBody>
      {children}
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}
        mt={5}
        mb={5}
      >
        <CircularProgress size={40} />
      </Box>
      <Button
        size="large"
        variant="outlined"
        color="inherit"
        onClick={onClose}
      >
        {t('common:actions.cancel')}
      </Button>
    </ModalDrawerBody>
  );
};


export default ModalDrawerBodyLoading;
