import { MouseEventHandler, ReactNode, } from 'react';
import { useSlate, } from 'slate-react';
import { CustomElement, } from '../types';
import { isBlockActive, toggleBlock, } from '../utils';
import ToggleButton from './ToggleButton';

interface Props {
  format: CustomElement['type'],
  icon: ReactNode,
};

const preventDefault = (e: Pick<MouseEvent, 'preventDefault'> | Pick<TouchEvent, 'preventDefault'>): void => e.preventDefault();

const ToggleElement = ({
  format,
  icon,
}: Props): JSX.Element => {
  const editor = useSlate();
  const isSelected = isBlockActive(
    editor,
    format,
    // blockType - type (default), align
  );
  const handleChange: MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    toggleBlock(editor, format);
  };

  return (
    <ToggleButton
      value="check"
      selected={isSelected}
      onChange={handleChange}
      onMouseDown={preventDefault}
      onTouchStart={preventDefault}
    >
      {icon}
    </ToggleButton>
  );
};

export default ToggleElement;
