import { RenewalEnum, } from 'Apollo/graphql';
import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type TaskRenewalOption = {
  id: RenewalEnum;
  labelKey: string;
};

export const TASK_RENEWAL_OPTIONS: TaskRenewalOption[] = [
  {
    id: RenewalEnum.None,
    labelKey: 'tasksAdmin:options.renewal.NONE',
  },
  {
    id: RenewalEnum.OneWeek,
    labelKey: 'tasksAdmin:options.renewal.ONE_WEEK',
  },
  {
    id: RenewalEnum.TwoWeeks,
    labelKey: 'tasksAdmin:options.renewal.TWO_WEEKS',
  },
  {
    id: RenewalEnum.OneMonth,
    labelKey: 'tasksAdmin:options.renewal.ONE_MONTH',
  },
];

type GetOptionLabel = (o: TaskRenewalOption) => string;
type GetOptionKey = (o: TaskRenewalOption) => string;

type UseOptionTaskRenewal = () => {
  options: TaskRenewalOption[];
  getOptionLabel: GetOptionLabel;
  getOptionKey: GetOptionKey;
  isOptionEqualToValue: typeof areOptionsEqualById<TaskRenewalOption>;
};

export const getOptionTaskRenewalById = (id: RenewalEnum): TaskRenewalOption | null => (
  TASK_RENEWAL_OPTIONS.find((value)=> value.id === id) || null
);


export const useOptionTaskRenewal: UseOptionTaskRenewal = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>((o) => t(o.labelKey), [t,]);

  return {
    options: TASK_RENEWAL_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
