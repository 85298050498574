import { AssortmentFragment, } from 'Apollo/graphql';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { AssortmentStore, useAssortmentStore, } from 'Modules/AssortmentAdmin/store/AssortmentStore';
import Logic from './Logic';

const selectModalData = (s: AssortmentStore) => s.modalRemoveAssortment;
const selectClose = (s: AssortmentStore) => s.closeModalRemoveAssortment;

const ModalRemoveAssortment = (): JSX.Element => {
  const { isOpen, assortment, } = useAssortmentStore(selectModalData);
  const onClose = useAssortmentStore(selectClose);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!assortment}
    >
      <Logic
        assortment={assortment as AssortmentFragment}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalRemoveAssortment;
