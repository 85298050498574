import { useMemo, } from 'react';
import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { ReplacementStore, } from 'Modules/ReplacementSeller/store/ReplacementStore';

export type SearchPhaseFormValues = {
  replacementId: string,
};

export const searchPhaseInitFormValues: SearchPhaseFormValues = {
  replacementId: '',
};

export const useSearchPhaseInitFormValues = (searchPhase: ReplacementStore['replacement']): SearchPhaseFormValues => useMemo<SearchPhaseFormValues>(
  () => ({
    replacementId: searchPhase.id || '',
  }),
  [ searchPhase.id, ],
);

export const searchPhaseFormValidationSchema = yup.object().shape({
  replacementId: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED),
});
