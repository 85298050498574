import { Editor, Element as SlateElement, Transforms, Descendant, } from 'slate';
import { CustomElement, CustomText, ListTypes, } from './types';

export const isRichTextEmpty = (value?: Descendant[] | null): boolean => {
  if (!value) return true;
  
  for (let i = 0; i < value.length; i++) {
    const node = value[i];
    

    if ((node as CustomText)?.text) {
      return false;
    }
    if ((node as CustomElement)?.children?.length) {
      const isChildrenEmpty = isRichTextEmpty((node as CustomElement).children);
      if (!isChildrenEmpty) return false;
    }
  }
  
  return true;
};

export const isMarkActive = (editor: Editor, format: keyof Omit<CustomText, 'text'>): boolean => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: Editor, format: keyof Omit<CustomText, 'text'>) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isBlockActive = (editor: Editor, format: CustomElement['type'], blockType: keyof CustomElement = 'type'): boolean => {
  const { selection, } = editor;
  if (!selection) return false;

  const [ match, ] = Array.from(
    Editor.nodes(
      editor,
      {
        at: Editor.unhangRange(editor, selection),
        match: (n) => (
          !Editor.isEditor(n)
          && SlateElement.isElement(n)
          && n[blockType] === format
        ),
      },
    ),
  );

  return !!match;
};

const LIST_TYPES: ListTypes = {
  'numbered-list': true,
  'bulleted-list': true,
};
const TEXT_ALIGN_TYPES = {};

const isListType = (field: string): boolean => Object.prototype.hasOwnProperty.call(LIST_TYPES, field);
const isTextAlignType = (field: string): boolean => Object.prototype.hasOwnProperty.call(TEXT_ALIGN_TYPES, field);

export const toggleBlock = (editor: Editor, format: CustomElement['type']) => {
  const isActive = isBlockActive(
    editor,
    format,
    // blockType - type (default), align
  );
  const isList = isListType(format);

  Transforms.unwrapNodes(
    editor, 
    {
      match: (n) => (
        !Editor.isEditor(n)
        && SlateElement.isElement(n)
        && isListType(n.type)
        && !isTextAlignType(format)
      ),
      split: true,
    },
  );

  let newProperties: Partial<SlateElement>;
  if (isTextAlignType(format)) {
    newProperties = {
      // align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [], };
    Transforms.wrapNodes(editor, block);
  }
};
