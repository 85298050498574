import { FocusEventHandler, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useController, useFormContext, } from 'react-hook-form';
import InputAutocompleteClassic from 'Components/Inputs/InputAutocompleteClassic';
import { InputFormErrorMessage, useFormInputStatus, } from 'Components/Form';
import { ContentBlockOption, useOptionsContentBlock, } from 'Utils/options/useOptionsContentBlock';
import { renderOptionById, } from 'Utils/helpers';
import { buildNodeInputName, } from './utils';
import { NodeProps, } from './types';

interface Props {
  index: number,
  onChangeType: NodeProps['onChangeType'],
}

const FormInputNodeType = ({
  index,
  onChangeType,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const { control, } = useFormContext();
  const { field: { value, onBlur: onBlurField, }, fieldState: { error, }, } = useController({
    name: buildNodeInputName(index, 'type'),
    control,
  });
  const taskContentTypes = useOptionsContentBlock();

  const handleChange = (e: unknown, newValue: ContentBlockOption | null) => {
    onChangeType(index, newValue);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    onBlurField();
  };

  const inputStatus = useFormInputStatus(
    error?.message as InputFormErrorMessage | undefined,
  );

  return (
    <InputAutocompleteClassic<ContentBlockOption>
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      getOptionLabel={taskContentTypes.getOptionLabel}
      isOptionEqualToValue={taskContentTypes.isOptionEqualToValue}
      options={taskContentTypes.options}
      label={t('content.labelType')}
      renderOption={renderOptionById(taskContentTypes.getOptionLabel)}
      placeholder={t('common:select.placeholder')}
      helperText={inputStatus.text}
      error={inputStatus.isError}
      required
    />
  );
};

export default FormInputNodeType;
