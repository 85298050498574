import { DetailVoucherPurchaseFragment,  VoucherPurchaseUpdateInput, } from 'Apollo/graphql';
import { INPUT_FORM_ERRORS, } from 'Components/Form/constants';
import { useMemo, } from 'react';
import { findVoucherPurchaseStatusOption, StatusOption, } from 'Utils/options/useOptionsVoucherPurchaseStatus';
import * as yup from 'yup';

export type VoucherPurchaseFormValues = {
  status: StatusOption;
};
export const initVoucherPurchaseFormValues = {
  status: null,
};

export const useInitVoucherPurchaseValues = (voucherPurchase: DetailVoucherPurchaseFragment): VoucherPurchaseFormValues => useMemo<VoucherPurchaseFormValues>(
  () => ({
    status: findVoucherPurchaseStatusOption(voucherPurchase.status),

  }),
  [ voucherPurchase, ],
);

export const voucherPurchaseFormValidationSchema = yup.object().shape({
  status: yup.object().required(INPUT_FORM_ERRORS.REQUIRED),
});

export const mapFormToVariables = ( values: VoucherPurchaseFormValues):VoucherPurchaseUpdateInput => ({
  status: values.status.id,
});
