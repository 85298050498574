import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ROUTES, } from 'Utils/constants';

const PasswordChangedView = (): JSX.Element => {
  const { t, } = useTranslation('auth');
  const navigate = useNavigate();

  const navigateToLogin = () => navigate(ROUTES.login);

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        marginBottom={3}
      >
        {t('resetPassword.passwordChanged.title')}
      </Typography>
      <Typography
        variant="body1"
        marginBottom={2}
      >
        {t('resetPassword.passwordChanged.info')}
      </Typography>

      <Box mt={4}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          sx={{
            width: { xs: '100%', lg: 'unset', },
          }}
          onClick={navigateToLogin}
        >
          {t('resetPassword.passwordChanged.btnDone')}
        </Button>
      </Box>
    </>
  );
};

export default PasswordChangedView;
