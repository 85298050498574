import { PromotionAssortmentCategoryFragment, } from 'Apollo/graphql';
import {
  AssortmentCategories,
  PromotionCategoryPurchaseObject,
  PurchasedAssortmentInStore,
  RegistrationAssortmentValue,
  RegistrationFormPromotionValue,
} from './RegistrationStore';

export type StoreAssortment = {
  count: number;
  assortmentId: string;
  deviceCode: string;
  publicUrl: string | null | undefined;
  name: string;
  id: string;
};

export type StorePromotionAssortment = {
  assortment: PurchasedAssortmentInStore;
  promotionCategoryId?: string;
  indexInPromotions?: number;
  indexInAssortments?: number;
};

export const mapAssortmentInput = (assortmentCategories: { [key: string]: RegistrationAssortmentValue[] }): StoreAssortment[] => {
  const ret: StoreAssortment[] = [];

  const categoryKeys = Object.keys(assortmentCategories);
  for (let i = 0; i < categoryKeys.length; i++) {
    const category = assortmentCategories[categoryKeys[i]];

    for (let j = 0; j < category.length; j++) {
      const {
        count,
        option: {
          id,
          assortmentCategory: { name, requiresDeviceCodes, },
        },
      } = category[j];
      const publicUrl = category[j].option.image?.publicUrl;
      if (count === 1 && requiresDeviceCodes) ret.push({ count, assortmentId: id, deviceCode: '', publicUrl, name, id: `${id}0`, });
      if (count > 1 && requiresDeviceCodes) {
        for (let l = 0; l < count; l++) {
          ret.push({ count: 1, assortmentId: id, deviceCode: '', publicUrl, name, id: `${id}${l}`, });
        }
      }
    }
  }
  const sortedRet = ret.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });
  return sortedRet;
};

export const remapDeviceCodeList = (
  current: StoreAssortment[],
  newEntries: {
    [key: string]: RegistrationAssortmentValue[];
  },
) => {
  const newEntriesMapped = mapAssortmentInput(newEntries);
  if (newEntriesMapped.length < 1) return [];
  return newEntriesMapped.map((newEntry) => {
    const findWithSameId = current.find((item) => item.id === newEntry.id);
    if (findWithSameId) return findWithSameId;
    return newEntry;
  });
};

export const remapPromotionAssortmentList = (
  storedPromotions: PromotionCategoryPurchaseObject,
  newEntries: RegistrationFormPromotionValue[],
): PromotionCategoryPurchaseObject => {
  const remappedPromotions = newEntries.reduce((prev, cur) => {
    const currentId: string = cur.option.promotion.id;
    const currentCount = cur.count;
    const storedList = storedPromotions[currentId] || [];
    if (storedList.length === currentCount) {
      return { ...prev, [currentId]: storedList, };
    }
    const result = [];
    for (let i = 0; i < currentCount; i++) {
      if (storedList[i]) {
        result.push(storedList[i]);
      } else {
        result.push({
          assortmentCategories: {},
          promotion: cur.option.promotion,
        });
      }
    }
    return { ...prev, [currentId]: result, };
  }, {});
  return remappedPromotions;
};

const makeAssortmentFormObjectIntoList = (assortmentCategories: AssortmentCategories) => {
  const keys = Object.keys(assortmentCategories);
  const assortmentListWithoutCodes: PurchasedAssortmentInStore[] = [];
  const assortmentListWithCodes: PurchasedAssortmentInStore[] = [];

  for (let i = 0; i < keys.length; i++) {
    // single category
    const singleAssortmentCategory = assortmentCategories[keys[i]];

    // map category into list of input objects
    for (let j = 0; j < singleAssortmentCategory?.length; j++) {
      const assortment = singleAssortmentCategory[j];
      // condition to map with required device codes
      if (assortment.option.assortmentCategory.requiresDeviceCodes) {
        for (let k = 0; k < assortment.count; k++) {
          assortmentListWithCodes.push({ count: 1, assortmentId: assortment.option.id, deviceCode: undefined, option: assortment.option, });
        }
      } else {
        assortmentListWithoutCodes.push({ count: assortment.count, assortmentId: assortment.option.id, option: assortment.option, });
      }
    }
  }

  return [assortmentListWithoutCodes, assortmentListWithCodes,];
};

const mapExistingCodesOntoNewStructure = (
  existingAssortments: PurchasedAssortmentInStore[],
  newAssortments: PurchasedAssortmentInStore[],
): PurchasedAssortmentInStore[] => {
  // eslint-disable-next-line prefer-const
  let assortmentMagazine = existingAssortments ? [...existingAssortments,] : [];
  const mappedAssortments: PurchasedAssortmentInStore[] = newAssortments.map((assortment) => {
    const assortmentWithExistingDeviceCode = assortmentMagazine.find(
      (existingAssortment) => assortment.assortmentId === existingAssortment.assortmentId,
    );
    const returnValue = {
      assortmentId: assortment.assortmentId,
      count: 1,
      deviceCode: assortmentWithExistingDeviceCode?.deviceCode,
      option: assortment.option,
    };
    assortmentMagazine.filter((existingAssortment) => existingAssortment.deviceCode !== assortmentWithExistingDeviceCode?.deviceCode);
    return returnValue;
  });
  return mappedAssortments;
};

const mapExistingCodesOntoNewStructureFromPurchasePhase = (
  existingAssortments: StorePromotionAssortment[],
  newAssortments: StorePromotionAssortment[],
): StorePromotionAssortment[] => {
  // eslint-disable-next-line prefer-const
  let assortmentMagazine = existingAssortments ? [...existingAssortments,] : [];
  const mappedAssortments: StorePromotionAssortment[] = newAssortments.map((assortment) => {
    const assortmentWithExistingDeviceCode = assortmentMagazine.find(
      (existingAssortment) => assortment.assortment.assortmentId === existingAssortment.assortment.assortmentId,
    );
    const existingDeviceCode = assortmentWithExistingDeviceCode?.assortment.deviceCode;
    const returnValue = {
      ...assortment,
      assortment: {
        ...assortment.assortment,
        deviceCode: existingDeviceCode,
      },
    };
    assortmentMagazine.filter(
      (existingAssortment) => existingAssortment.assortment.deviceCode !== assortmentWithExistingDeviceCode?.assortment.deviceCode,
    );
    return returnValue;
  });
  return mappedAssortments;
};

export const mapOneCode = (existingList: PurchasedAssortmentInStore[], indexInList: number, deviceCode: string) =>
  existingList.map((assortment, index) => {
    if (index === indexInList) return assortment;
    return { ...assortment, deviceCode, };
  });

export const mapCategoriesOntoDeviceCodeList = (
  storedPromotions: PromotionCategoryPurchaseObject,
  existingDeviceCodePromotions: StorePromotionAssortment[],
): StorePromotionAssortment[] => {
  const keysInStorePromotions = Object.keys(storedPromotions);
  const returnList = [];
  for (let i = 0; i < keysInStorePromotions.length; i++) {
    const currentId = keysInStorePromotions[i];
    for (let j = 0; j < storedPromotions[currentId]?.length; j++) {
      const assortmentWithRequiredCodeList = storedPromotions[currentId][j].assortmentsWithDeviceCodes;
      for (let k = 0; k < assortmentWithRequiredCodeList?.length; k++) {
        const assortment = assortmentWithRequiredCodeList[k];
        returnList.push({
          assortment,
          promotionCategoryId: keysInStorePromotions[i],
          indexInPromotions: j,
          indexInAssortments: k,
        });
      }
    }
  }
  const newWithOldList = mapExistingCodesOntoNewStructureFromPurchasePhase(existingDeviceCodePromotions, returnList);
  return newWithOldList;
};

export const remapSingleEntry = (storedPromotions: PromotionCategoryPurchaseObject, newEntries: AssortmentCategories, id: string, index: number) => {
  const promotionListToChange = storedPromotions[id];
  const [assortmentListWithoutCodes, assortmentListWithCodes,] = makeAssortmentFormObjectIntoList(newEntries);
  const remappedList = promotionListToChange.map((promotionPurchase, i) => {
    if (index === i) {
      return {
        assortmentCategories: newEntries,
        promotion: promotionPurchase.promotion,
        assortmentsWithoutDeviceCodes: assortmentListWithoutCodes,
        assortmentsWithDeviceCodes: mapExistingCodesOntoNewStructure(promotionPurchase.assortmentsWithDeviceCodes, assortmentListWithCodes),
        isValid: true,
      };
    }
    return promotionPurchase;
  });
  return { ...storedPromotions, [id]: remappedList, };
};

export const reduceAssortmentCategoryToEmptyStoreValues = (assortmentCategories: PromotionAssortmentCategoryFragment):AssortmentCategories => {
  const keys = Object.keys(assortmentCategories);
  const returnObject: AssortmentCategories = {};
  for (let i = 0; i < keys.length; i++) {
    returnObject[keys[i]] = [];
  }
  return returnObject;
};
