import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import { useNavigate, } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { MyPointsTransactionsQuery, } from 'Apollo/graphql';
import IconButtonSquare from 'Components/IconButtonSquare';
import { ROUTES, } from 'Utils/constants';
import KeyboardArrowUpIcon from 'Utils/svg/KeyboardArrowUpIcon';
import TransActionChunk from '../../../components/TransactionChunk';

type Props = {
  data: MyPointsTransactionsQuery;
};

const TransactionButtonBox = styled(Box)(({ theme, }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  padding: theme.spacing(1.5, 2),
}));

const View = ({ data, }: Props): JSX.Element => {
  const { t, } = useTranslation('myPoints');
  const navigate = useNavigate();
  const handleToTransactionList = () => navigate(`/${ROUTES.myPoints}/${ROUTES.transactions}`);
  return (
    <Box
      pt={0.5}
      pb={0.5}
    >
      {data.myPointsTransactions.data.map((transaction) => <TransActionChunk key={transaction.id} data={transaction} />)}
      <TransactionButtonBox>
        <Typography variant="h3" color="primary">
          {t('transaction.btnViewAll')}
        </Typography>
        <IconButtonSquare variant="outlined" onClick={handleToTransactionList}>
          <KeyboardArrowUpIcon sx={{transform: "rotate(90deg)",}} />
        </IconButtonSquare>
      </TransactionButtonBox>
    </Box>
  );
};
export default View;
