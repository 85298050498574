import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import PhoneIcon from 'Utils/svg/PhoneIcon';
import { styled, } from '@mui/material/styles';
import Link from '@mui/material/Link';

interface Props {
  title: string;
  number: string;
  timeWindow: string;
  numberAbsolute: string;
}

const SupportBoxCard = styled(Card)(({ theme, }) => ({
  background: theme.palette.secondary.main,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  variant: 'elevation',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const SupportBox = ({ title, number, timeWindow, numberAbsolute, }: Props): JSX.Element => {
  return (
    <SupportBoxCard>
      <Box display="flex" flexDirection="column" mr={2}>
        <Typography variant="h3">
          {title}
        </Typography>
        <Link variant="body0" color="inherit" fontWeight="bold" mt={1} href={`tel:${numberAbsolute}`}>
          {number}
        </Link>
        <Typography variant="body0">
          {timeWindow}
        </Typography>
      </Box>
      <Box
        component="a"
        href={`tel:${numberAbsolute}`}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <PhoneIcon
          sx={{ fontSize: '36px', }}
        />
      </Box>
    </SupportBoxCard>
  );
};

export default SupportBox;
