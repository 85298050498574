import { TypeOfCompletionEnum, } from 'Apollo/graphql';
import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type TaskTypeOfCompletionOption = {
  id: TypeOfCompletionEnum;
  labelKey: string;
};

export const TASK_TYPE_OF_COMPLETION_OPTIONS: TaskTypeOfCompletionOption[] = [
  {
    id: TypeOfCompletionEnum.User,
    labelKey: 'tasksAdmin:creation.parameterPhase.typeOfCompletion.user',
  },
  {
    id: TypeOfCompletionEnum.Pos,
    labelKey: 'tasksAdmin:creation.parameterPhase.typeOfCompletion.pos',
  },
  {
    id: TypeOfCompletionEnum.Elearning,
    labelKey: 'tasksAdmin:creation.parameterPhase.typeOfCompletion.elearning',
  },
];

export const getOptionTypeOfCompletionById = (id: TypeOfCompletionEnum): TaskTypeOfCompletionOption | null => TASK_TYPE_OF_COMPLETION_OPTIONS.find(
  (o) => o.id === id
) || null;

type GetOptionLabel = (o: TaskTypeOfCompletionOption) => string;
type GetOptionKey = (o: TaskTypeOfCompletionOption) => string;

type UseOptionTaskTypeOfCompletion = () => {
  options: TaskTypeOfCompletionOption[];
  getOptionLabel: GetOptionLabel;
  getOptionKey: GetOptionKey;
  isOptionEqualToValue: typeof areOptionsEqualById<TaskTypeOfCompletionOption>;
};
export const useOptionTaskTypeOfCompletion: UseOptionTaskTypeOfCompletion = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>((o) => t(o.labelKey), [t,]);

  return {
    options: TASK_TYPE_OF_COMPLETION_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
