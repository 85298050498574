import { ForwardRefExoticComponent, ReactNode, } from 'react';
import Grid from '@mui/material/Grid';
import { SxProps, Theme, styled, } from '@mui/material/styles';
import { Link as RouterLink, LinkProps, } from 'react-router-dom';
import MUILink from '@mui/material/Link';

const StyledLink = styled(MUILink)<{
  component: ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>;
  to: string;
}>(({ theme, }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: 20,
  lineHeight: '24px',
  letterSpacing: '0.25px',
}));

interface Props {
  children: ReactNode;
  route: string;
  onClick?: () => void;
  isAllowed?: boolean;
}

const SidebarMenuMobileLink = ({ children, route, onClick, isAllowed, }: Props): JSX.Element | null => {
  if (isAllowed === false) return null;

  return (
    <Grid item xs={12}>
      <StyledLink component={RouterLink} to={route} color="secondary" underline="none" onClick={onClick}>
        {children}
      </StyledLink>
    </Grid>
  );
};

export default SidebarMenuMobileLink;
