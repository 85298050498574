import shallow from 'zustand/shallow';
import { useUsersQuery, } from 'Apollo/graphql';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import { UserStore, useUserStore, } from 'Modules/Users/store/UserStore';
import { useAuthUser, } from 'Tools/auth';
import { mapVariables, } from '../../../utils/mapVariables';
import UsersTableHeader from './UsersTableHeader';
import UsersTableRow from './UsersTableRow';

const selectStoreData = (s: UserStore) => ({
  onDetail: s.openModalDetailUser,
  onEdit: s.openModalEditUser,
  onRemove: s.openModalRemoveUser,
  onReactivate: s.openModalReactivateUser,
  onErase: s.openModalEraseAllUserData,
  onChangePassword: s.openModalChangePassword,
  tableUsers: s.tableUsers,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,
});

const Users = (): JSX.Element => {
  const authUser = useAuthUser();
  const {
    onDetail,
    onEdit,
    onRemove,
    onErase,
    onChangePassword,
    tableUsers,
    changeFilter,
    changeSort,
    changeData,
  } = useUserStore(selectStoreData, shallow);

  const { loading, error, data, } = useUsersQuery({
    variables: mapVariables(tableUsers),
    fetchPolicy: 'cache-and-network',
  });

  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS ? 0 : 1;
  const showPosIDs = authUser?.featureFlagsObject.FEATURE_FLAG_SHOW_POS_IDS_IN_USER_DASHBOARD ? 0 : 1;
  const colSpan = 10 - showChainIDs - showPosIDs;

  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={colSpan}
      rows={data?.users.data.length || 0}
      loading={loading}
      error={error}
      limit={tableUsers.data.limit}
      sortField={tableUsers.data.sortField}
      sortOrder={tableUsers.data.sortOrder}
      offset={tableUsers.data.offset}
      count={data?.users.pagination.count}
      filter={tableUsers.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <UsersTableHeader
        authUser={authUser}
      />
      <TableBody>
        {(data?.users.data || []).map((row) => (
          <UsersTableRow
            key={row.id}
            row={row}
            authUser={authUser}
            onEdit={onEdit}
            onRemove={onRemove}
            onDetail={onDetail}
            onErase={onErase}
            onChangePassword={onChangePassword}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default Users;
