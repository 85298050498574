import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ChainFragment, TaskSortableFields, useChainsQuery, } from 'Apollo/graphql';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import TableFilterInt from 'Components/Table/filters/TableFilterInt';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import TableCellSortable from 'Components/Table/TableCellSortable';
import { useTranslation, } from 'react-i18next';
import { areOptionsEqualById, getOptionFieldId, renderOptionByBoolId, renderOptionById, } from 'Utils/helpers';
import { IsNowAvailableOption, useOptionsIsNowAvailable, } from 'Utils/options/useOptionsIsNowAvailable';
import { TaskAssessmentMethodOption, useOptionsAssessmentMethod, } from 'Utils/options/useOptionTaskAssessmentMethod';
import { TaskTypeCompletionOption, useOptionsTaskTypeCompletion, } from 'Utils/options/useOptionTaskCompletionType';
import { TaskRenewalOption, useOptionTaskRenewal, } from 'Utils/options/useOptionTaskRenewal';
import { TaskTypeOption, useOptionsTaskType, } from 'Utils/options/useOptionTaskType';

const COL_WIDTHS = [
  { width: '120px', },
  { width: '160px', },
  { width: '120px', },
  { width: '240px', },
  { width: '120px', },
  { width: '120px', },
  { width: '120px', },
  { width: '120px', },
  { width: '150px', },
  { width: '120px', },
  { width: '120px', },
  { width: '120px', },
  { width: '200px', },
  { width: '200px', },
  { width: '180px', },
  { width: '150px', },
  { width: '150px', },
  { width: '45px', },
];

const TasksTableHeader = (): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');

  const chainsResult = useChainsQuery({ fetchPolicy: 'cache-and-network', });

  const taskTypeOptions = useOptionsTaskType();
  const taskCompletionTypeOptions = useOptionsTaskTypeCompletion();
  const taskIsNowAvailableOptions = useOptionsIsNowAvailable();
  const taskAssesmentMethodOptions = useOptionsAssessmentMethod();
  const taskRenewalOptions = useOptionTaskRenewal();


  return (
    <TableHead>
      <TableRow>
        <TableCell style={COL_WIDTHS[0]}>{t('tasks.table.id')}</TableCell>
        <TableCellSortable style={COL_WIDTHS[1]} name={TaskSortableFields.Type}>
          {t('tasks.table.type')}
        </TableCellSortable>
        <TableCellSortable style={COL_WIDTHS[3]} name={TaskSortableFields.Name}>
          {t('tasks.table.name')}
        </TableCellSortable>
        <TableCellSortable style={COL_WIDTHS[4]} name={TaskSortableFields.PointsForCompletion}>
          {t('tasks.table.pointsForCompletion')}
        </TableCellSortable>
        <TableCellSortable style={COL_WIDTHS[5]} name={TaskSortableFields.PointsLimit}>
          {t('tasks.table.pointsLimit')}
        </TableCellSortable>
        <TableCellSortable style={COL_WIDTHS[6]} name={TaskSortableFields.ValidFrom}>
          {t('tasks.table.validFrom')}
        </TableCellSortable>
        <TableCellSortable style={COL_WIDTHS[7]} name={TaskSortableFields.ValidTo}>
          {t('tasks.table.validTo')}
        </TableCellSortable>
        <TableCellSortable style={COL_WIDTHS[8]} name={TaskSortableFields.TypeOfCompletion}>
          {t('tasks.table.typeOfCompletion')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[10]}>{t('tasks.table.assessmentMethod')}</TableCell>
        <TableCell style={COL_WIDTHS[11]}>{t('tasks.table.renewal')}</TableCell>
        <TableCellSortable style={COL_WIDTHS[12]} name={TaskSortableFields.AttemptLimit}>
          {t('tasks.table.attemptLimit')}
        </TableCellSortable>
        <TableCellSortable style={COL_WIDTHS[13]} name={TaskSortableFields.AuthorId}>
          {t('tasks.table.authorId')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[14]}>{t('tasks.table.isNowAvailable')}</TableCell>
        <TableCell style={COL_WIDTHS[15]}>{t('tasks.table.chainIds')}</TableCell>
        <TableCellSortable style={COL_WIDTHS[16]} name={TaskSortableFields.TaskCompletionsCount}>
          {t('tasks.table.taskCompletionsCount')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[17]} />
      </TableRow>
      <TableRow>
        <TableCell>
          <TableFilterText name="id" />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<TaskTypeOption>
            name="type"
            getOptionLabel={taskTypeOptions.getOptionLabel}
            isOptionEqualToValue={taskTypeOptions.isOptionEqualToValue}
            renderOption={renderOptionById(taskTypeOptions.getOptionLabel)}
            options={taskTypeOptions.options}
          />
        </TableCell>
        <TableCell>
          <TableFilterText name="name" />
        </TableCell>
        <TableCell>
          <TableFilterInt name="pointsForCompletion" />
        </TableCell>
        <TableCell>
          <TableFilterInt name="pointsLimit" />
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell>
          <TableFilterAutocomplete<TaskTypeCompletionOption>
            name="typeOfCompletion"
            getOptionLabel={taskCompletionTypeOptions.getOptionLabel}
            isOptionEqualToValue={taskCompletionTypeOptions.isOptionEqualToValue}
            renderOption={renderOptionById(taskCompletionTypeOptions.getOptionLabel)}
            options={taskCompletionTypeOptions.options}
          />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<TaskAssessmentMethodOption>
            name="assessmentMethod"
            getOptionLabel={taskAssesmentMethodOptions.getOptionLabel}
            isOptionEqualToValue={taskAssesmentMethodOptions.isOptionEqualToValue}
            renderOption={renderOptionById(taskAssesmentMethodOptions.getOptionLabel)}
            options={taskAssesmentMethodOptions.options}
          />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<TaskRenewalOption>
            name="renewal"
            getOptionLabel={taskRenewalOptions.getOptionLabel}
            isOptionEqualToValue={taskRenewalOptions.isOptionEqualToValue}
            renderOption={renderOptionById(taskRenewalOptions.getOptionLabel)}
            options={taskRenewalOptions.options}
          />
        </TableCell>
        <TableCell>
          <TableFilterInt name="attemptLimit" />
        </TableCell>
        <TableCell>
          <TableFilterText name="author" />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<IsNowAvailableOption>
            name="isNowAvailable"
            getOptionLabel={taskIsNowAvailableOptions.getOptionLabel}
            isOptionEqualToValue={taskIsNowAvailableOptions.isOptionEqualToValue}
            renderOption={renderOptionByBoolId(taskIsNowAvailableOptions.getOptionLabel)}
            options={taskIsNowAvailableOptions.options}
          />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<ChainFragment>
            name="chainId"
            getOptionLabel={getOptionFieldId}
            isOptionEqualToValue={areOptionsEqualById}
            renderOption={renderOptionById(getOptionFieldId)}
            options={chainsResult.data?.chains || []}
            loading={chainsResult.loading}
          />
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default TasksTableHeader;
