import { useForm, FormProvider, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import { PointsOfSellDocument, useImportPointsOfSellMutation, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import { mapBadFormDataError, } from 'Components/Form';
import { usePointOfSellStore, } from 'Modules/PointOfSell/store/PointOfSellStore';
import { mapVariables, } from 'Modules/PointOfSell/pages/PointOfSellPage/PointOfSellTable/utils';
import { defaultImportPosFormValues, importPosValidationSchema, ImportPosFormValues, importBadFormDataFieldMapper, } from './utils';
import FormView from './FormView';
import { ImportState, } from './types';

interface Props {
  setImportState: (state: ImportState) => void,
  onClose: () => void,
};

const FormLogic = ({
  setImportState,
  onClose,
}: Props): JSX.Element => {
  const authUser = useAuthUser();
  const methods = useForm({
    defaultValues: defaultImportPosFormValues,
    resolver: yupResolver(importPosValidationSchema),
    mode: 'onTouched',
  });

  const [ importMutation, importMutationResult, ] = useImportPointsOfSellMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [{
      query: PointsOfSellDocument,
      variables: mapVariables(usePointOfSellStore.getState().tablePointOfSell, authUser),
    },],
    onCompleted: (response) => {
      if (response.importPointsOfSell.success) {
        setImportState({
          isImported: true,
          result: response.importPointsOfSell,
        });
      }
    },
    onError: (error) => {
      mapBadFormDataError({
        apolloError: error,
        fieldMapper: importBadFormDataFieldMapper,
        methods,
      });
    },
  });

  const handleSubmit = methods.handleSubmit(async (values: ImportPosFormValues) => {
    await importMutation({
      variables: {
        fileId: values.file?.id || '',
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <FormView
          importMutationResult={importMutationResult}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default FormLogic;
