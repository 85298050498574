import { Grid, Stack, styled, Typography, } from '@mui/material';
import {
  DashboardLayoutItemName,
  useUserDashboardLayoutQuery,
} from 'Apollo/graphql';
import AppContainer from 'Components/AppContainer';
import LoadingBox from 'Components/LoadingBox';
import { useTranslation, } from 'react-i18next';
import { ChartMap, } from 'Utils/Charts/ChartMap';

const StyledCard = styled('div')(({ theme, }) => ({
  padding: theme.spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
}));

const StyledImage = styled('img')(() => ({
  maxWidth: 400,
}));

const PersonalizedDashboard = () => {
  const { t, } = useTranslation('dashboards');
  const { loading, error, data, } = useUserDashboardLayoutQuery();

  return (
    <AppContainer>
      <Grid container spacing={2}>
        {loading && <LoadingBox margin="0 auto" />}
        {error && (
          <Typography variant="h1">
            {t('dashboards.errorLoadingUserDashboards')}
          </Typography>
        )}
        <Grid item xs={12}>
          <Stack gap={1} marginBottom={4}>
            <Typography variant="h1">
              {t('dashboards.myDashboards')}
            </Typography>
          </Stack>
          {data?.userDashboardLayout.layout &&
            data?.userDashboardLayout.layout.length < 1 && (
              <StyledCard>
                <Stack display="flex" gap={2}>
                  <Typography variant="h2">
                    {t('dashboards.howToAddPersonalDashboard.title')}
                  </Typography>
                  <Typography variant="body1">
                    {t('dashboards.howToAddPersonalDashboard.text')}
                  </Typography>
                </Stack>
                <StyledImage src="/images/dashboards/guide.png" alt="" />
              </StyledCard>
            )}
        </Grid>
        {data?.userDashboardLayout.layout &&
          data?.userDashboardLayout.layout.map(
            (item: DashboardLayoutItemName) => {
              const ChartComponent = ChartMap[item];
              return (
                <Grid item xs={12} md={6} key={item}>
                  <ChartComponent showAddButton={false} />
                </Grid>
              );
            },
          )}
      </Grid>
    </AppContainer>
  );
};

export default PersonalizedDashboard;
