import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { ChainFragment, TypeOfCompletionEnum, useChainsQuery, } from 'Apollo/graphql';
import { FormInputAutocomplete, FormInputDate, FormInputGroupRadio, FormInputText, } from 'Components/Form';
import FormInputInt from 'Components/Form/inputs/FormInputInt';
import { ParametersFormConfig, } from 'Modules/TasksAdmin/store/createTaskStore';
import { areOptionsEqualById, getOptionFieldId, renderOptionById, } from 'Utils/helpers';
import { useOptionsTaskTypeCompletion, } from 'Utils/options/useOptionTaskCompletionType';
import { TaskRenewalOption, useOptionTaskRenewal, } from 'Utils/options/useOptionTaskRenewal';
import { TaskTypeOfCompletionOption, } from 'Utils/options/useOptionTaskTypeOfCompletion';

type Props = {
  parametersFormConfig: ParametersFormConfig,
};

const FormParameters = ({
  parametersFormConfig,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');

  const taskTypeOfCompletionOptions = useOptionsTaskTypeCompletion();
  const taskTypeOfCompletionOptionsFiltered = useMemo(
    () => taskTypeOfCompletionOptions.options.filter((o) => o.id !== TypeOfCompletionEnum.Elearning),
    [ taskTypeOfCompletionOptions.options, ],
  );
  const taskRenewalOptions = useOptionTaskRenewal();
  const chainsResult = useChainsQuery();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormInputText name="name" label={t(`parameters.labelName`)} required />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          multiline
          minRows={4}
          name="description"
          label={t(`parameters.labelDescription`)}
          required
        />
      </Grid>
      {parametersFormConfig.inputTypeOfCompletion && (
        <Grid item xs={12}>
          <FormInputGroupRadio<TaskTypeOfCompletionOption>
            name="typeOfCompletion"
            label={t(`parameters.labelTypeOfCompletion`)}
            options={taskTypeOfCompletionOptionsFiltered}
            getOptionKey={taskTypeOfCompletionOptions.getOptionKey}
            getOptionLabel={taskTypeOfCompletionOptions.getOptionLabel}
            required
          />
        </Grid>
      )}
      {parametersFormConfig.inputPointsForCompletion && (
        <Grid item xs={12}>
          <FormInputInt name="pointsForCompletion" label={t(`parameters.labelPointsForCompletion`)} required />
        </Grid>
      )}
      {parametersFormConfig.inputPointsLimit && (
        <Grid item xs={12}>
          <FormInputInt name="pointsLimit" label={t(`parameters.labelPointsLimit`)} required />
        </Grid>
      )}
      {parametersFormConfig.inputAttemptLimit && (
        <Grid item xs={12}>
          <FormInputInt name="attemptLimit" label={t(`parameters.labelAttemptLimit`)} required />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormInputDate name="validFrom" label={t(`parameters.labelValidFrom`)} required />
      </Grid>
      <Grid item xs={12}>
        <FormInputDate name="validTo" label={t(`parameters.labelValidTo`)} required />
      </Grid>
      <Grid item xs={12}>
        <FormInputAutocomplete<ChainFragment, true>
          multiple
          name="chains"
          getOptionLabel={getOptionFieldId}
          isOptionEqualToValue={areOptionsEqualById}
          renderOption={renderOptionById(getOptionFieldId)}
          options={chainsResult.data?.chains || []}
          loading={chainsResult.loading}
          label={t('parameters.labelChainIds')}
          placeholder={t('common:select.placeholder')}
        />
      </Grid>
      {parametersFormConfig.inputRenewal && (
        <Grid item xs={12}>
          <FormInputGroupRadio<TaskRenewalOption>
            name="renewal"
            label={t(`parameters.labelRenewal`)}
            options={taskRenewalOptions.options}
            getOptionKey={taskRenewalOptions.getOptionKey}
            getOptionLabel={taskRenewalOptions.getOptionLabel}
            required
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FormParameters;
