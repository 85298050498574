import { useTranslation, } from 'react-i18next';
import { FormInputText, FormInputInt, } from 'Components/Form';
import Grid from '@mui/material/Grid';
import { NodeProps, } from '../types';
import { buildNodeInputName, } from '../utils';
import FormInputNodeType from '../FormInputNodeType';
import NodeLayout from '../NodeLayout';

const NodeText = ({
  index,
  assessmentConfig,
  onRemove,
  onChangeType,
}: NodeProps): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const { enableCorrectAnswer, } = assessmentConfig;

  return (
    <NodeLayout
      index={index}
      onRemove={onRemove}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInputNodeType
            index={index}
            onChangeType={onChangeType}
          />
        </Grid>
        <Grid item xs={12} sm={enableCorrectAnswer ? 9 : 12}>
          <FormInputText
            name={buildNodeInputName(index, 'question')}
            label={t('assessment.labelQuestion')}
            required
          />
        </Grid>
        {enableCorrectAnswer && (
          <Grid item xs={12} sm={3}>
            <FormInputInt
              name={buildNodeInputName(index, 'points')}
              label={t('assessment.labelPoints')}
              required
            />
          </Grid>
        )}
      </Grid>
    </NodeLayout>
  );
};

export default NodeText;
