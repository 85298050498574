import { forwardRef, ReactNode, useCallback, } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps, } from 'notistack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from 'Utils/svg/CloseIcon';

declare module 'notistack' {
  interface VariantOverrides {
    // removes the `warning` variant
    warning: false;     
    // adds `myCustomVariant` variant      
    // myCustomVariant: true;    
    // adds `reportComplete` variant and specifies the
    // "extra" props it takes in options of `enqueueSnackbar`
    common: {         
      title: ReactNode | undefined,
    }
  }
}

interface Props extends CustomContentProps {
  title?: ReactNode,
};

const Notification = forwardRef<HTMLDivElement, Props>((
  {
    id,
    message,
    title,
    // action,
  },
  ref,
): JSX.Element => {
  const { closeSnackbar, } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar,]);

  return (
    <SnackbarContent ref={ref}>
      <Card
        sx={{
          width: '100%',
        }}
      >
        <CardContent
          sx={{
            '&:last-child': {
              padding: 2,
              position: 'relative',
            },
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              '& > .MuiSvgIcon-root': {
                fontSize: '16px',
              },
            }}
            onClick={handleDismiss}
          >
            <CloseIcon />
          </IconButton>
          {title && (
            <Typography
              color="primary"
              gutterBottom
              variant="subtitle1"
              component="div"
              sx={{
                fontSize: '18px',
                marginRight: '20px',
              }}
            >
              {title}
            </Typography>
          )}
          <Typography variant="body1">
            {message}
          </Typography>
        </CardContent>
        {/* {action && (
          <CardActions>
            {action}
          </CardActions>
        )} */}
      </Card>
    </SnackbarContent>
  );
});

export default Notification;
