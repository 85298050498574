import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const UnderlineIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    {...props}
    fill="none"
  >
    <path
      d="M16.094 15.703H3.906a.153.153 0 0 0-.156.149v1.187c0 .082.07.148.156.148h12.188a.153.153 0 0 0 .156-.148v-1.187a.153.153 0 0 0-.156-.149ZM10 14.22c1.355 0 2.629-.53 3.59-1.489a5.037 5.037 0 0 0 1.488-3.59V3.048a.235.235 0 0 0-.234-.235h-1.172a.235.235 0 0 0-.235.235V9.14A3.443 3.443 0 0 1 10 12.578a3.443 3.443 0 0 1-3.438-3.437V3.047a.235.235 0 0 0-.234-.235H5.156a.235.235 0 0 0-.234.235V9.14a5.05 5.05 0 0 0 1.488 3.59A5.038 5.038 0 0 0 10 14.218Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default UnderlineIcon;
