import TextField, { TextFieldProps, } from '@mui/material/TextField';
import { useInputInt, } from './utils/useInputInt';

export interface InputIntClassicProps extends Omit<TextFieldProps, 'onChange' | 'value'> {
  value: number | null,
  onChange: (value: number | null) => void,
}

const InputInt = ({
  value,
  onFocus,
  onBlur,
  onKeyDown,
  onChange,
  ...rest
}: InputIntClassicProps): JSX.Element => {
  const {
    valueInternal,
    onChangeInternal,
    onFocusInternal,
    onBlurInternal,
    onKeyDownInternal,
  } = useInputInt(
    value,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
  );

  return (
    <TextField
      {...rest}
      value={valueInternal}
      onChange={onChangeInternal}
      onFocus={onFocusInternal}
      onBlur={onBlurInternal}
      onKeyDown={onKeyDownInternal}
    />
  );
};

export default InputInt;
