import { ReactNode, } from 'react';
import { useFormContext, useWatch, } from 'react-hook-form';
import { ContentBlockTypeEnum, } from 'Apollo/graphql';
import { ContentBlockOption, } from 'Utils/options/useOptionsContentBlock';
import { NodeKeys, } from '../types';
import { NodeProps, } from './types';
import { buildNodeInputName, } from './utils';
import NodeEmpty from './types/NodeEmpty';
import NodeText from './types/NodeText';
import NodePhoto from './types/NodePhoto';
import NodeVideo from './types/NodeVideo';

type NodeTypes = {
  [key in (NodeKeys)]: (props: NodeProps) => ReactNode
};

const NODE_TYPES: NodeTypes = {
  default: (props) => <NodeEmpty {...props} />,
  [ContentBlockTypeEnum.Text]: (props) => <NodeText {...props} />,
  [ContentBlockTypeEnum.Photo]: (props) => <NodePhoto {...props} />,
  [ContentBlockTypeEnum.Video]: (props) => <NodeVideo {...props} />,
};

const Node = (props: NodeProps): JSX.Element => {
  const { index, } = props;
  const { control, } = useFormContext();
  const type = useWatch({ control, name: buildNodeInputName(index, 'type'), }) as ContentBlockOption;

  if (type && Object.prototype.hasOwnProperty.call(NODE_TYPES, type.id)) return (
    <>{NODE_TYPES[type.id as NodeKeys](props)}</>
  );
  return <>{NODE_TYPES.default(props)}</>;
};

export default Node;
