import { useEffect, } from 'react';
import { useOptionsPhonePrefix, PhonePrefixOption, } from 'Utils/options/useOptionsPhonePrefix';
import InputAutocompleteClassic from '../InputAutocompleteClassic';

interface Props {
  value: PhonePrefixOption | null,
  onChange: (newValue: PhonePrefixOption | null) => void,
  disabled?: boolean,
  error?: boolean,
  id?: string,
};

const Prefix = ({
  value,
  onChange,
  disabled,
  error,
  id,
}: Props): JSX.Element => {
  const {
    defaultValue,
    getOptionLabel,
    isOptionEqualToValue,
    loading,
    options,
  } = useOptionsPhonePrefix();

  useEffect(
    () => {
      if (!value && defaultValue) onChange(defaultValue);
    },
    [ defaultValue, value, onChange, ],
  );

  const handleChange = (e: unknown, newValue: PhonePrefixOption) => {
    onChange(newValue);
  };

  return (
    <InputAutocompleteClassic<PhonePrefixOption, false, true, false>
      value={value as PhonePrefixOption}
      onChange={handleChange}
      autoComplete={false}
      id={id || 'prefix'}
      disableClearable
      disabled={disabled || (loading && options.length < 1)}
      error={error}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      options={options}
    />
  );
};

export default Prefix;
