import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { OrderBy, PointsTransactionSortableFields, useMyPointsTransactionsQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import LoadingBox from 'Components/LoadingBox';
import View from './View';

const FetchData = (): JSX.Element => {
  const { t, } = useTranslation('myPoints');
  const { error, loading, data, } = useMyPointsTransactionsQuery({
    fetchPolicy: 'cache-and-network',
    variables:{
      sort:{
        field: PointsTransactionSortableFields.CreatedAt,
        order: OrderBy.Desc,
    },},
  });

  if (loading) return <LoadingBox />;
  if (error || !data?.myPointsTransactions) return <ErrorAlert forceError error={error} />;
  if (data.myPointsTransactions.data.length < 1) return (
    <Typography>{t('transaction.noTransactions')}</Typography>
  );
  return <View data={data} />;
};

export default FetchData;
