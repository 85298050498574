import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import { ApolloError, } from '@apollo/client';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import ModalDrawerBody from './ModalDrawerBody';

interface Props {
  onClose: () => void,
  children?: ReactNode,
  error?: Error | ApolloError | null,
};

const ModalDrawerBodyError = ({
  onClose,
  error,
  children,
}: Props): JSX.Element => {
  const { t, } = useTranslation('assortmentAdmin');

  return (
    <ModalDrawerBody>
      {children}
      <ErrorAlert
        mb={2}
        forceError
        error={error}
      />
      <Button
        size="large"
        variant="outlined"
        color="inherit"
        onClick={onClose}
      >
        {t('common:actions.cancel')}
      </Button>
    </ModalDrawerBody>
  );
};


export default ModalDrawerBodyError;
