/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloCache, DefaultContext, MutationUpdaterFunction, Reference, } from '@apollo/client';
import { CreatePromotionMutation, DeletePromotionMutation, Exact, PromotionFragmentDoc, PromotionInput, } from 'Apollo/graphql';

type UpdatePromotionRemove = MutationUpdaterFunction<DeletePromotionMutation, Exact<{
  id: string;
}>, DefaultContext, ApolloCache<any>> | undefined;

export const updatePromotionRemove: UpdatePromotionRemove = (cache, { data, }) => {
  try {
    if (data?.deletePromotion) {
      cache.modify({
        fields: {
          promotions: (existing, { readField, }) => ({
            ...existing,
            data: existing.data.filter((itemRef: Reference) => readField('id', itemRef) !== data.deletePromotion.id),
          }),
        },
      });
    }
  } catch {
    // ignore error
  }
};

type UpdatePromotionCreate = MutationUpdaterFunction<CreatePromotionMutation, Exact<{
  promotionInput: PromotionInput;
}>, DefaultContext, ApolloCache<any>> | undefined;

export const updatePromotionCreate: UpdatePromotionCreate = (cache, { data, }) => {
  try {
    if (data?.createPromotion) {
      const newRef = cache.writeFragment({
        id: cache.identify(data.createPromotion),
        data: data.createPromotion,
        fragment: PromotionFragmentDoc,
        fragmentName: data.createPromotion.__typename,
      });
  
      cache.modify({
        fields: {
          promotions: (existing) => ({
            ...existing,
            data: [ ...existing.data, newRef, ],
          }),
        },
      });
    }
  } catch {
    // ignore error
  }
};
