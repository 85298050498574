import { useMemo, } from 'react';
import { Link as RouterLink, } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AssessmentMethodEnum, FullTaskFragment, TaskTypeEnum, } from 'Apollo/graphql';
import { getOptionTaskMethodById, } from 'Utils/options/useOptionTaskMethod';
import { getOptionTaskTypeById, } from 'Utils/options/useOptionTaskType';
import { getOptionTaskCompletionTypeById, } from 'Utils/options/useOptionTaskCompletionType';
import { getOptionTaskRenewalById, } from 'Utils/options/useOptionTaskRenewal';
import { ROUTES, } from 'Utils/constants';
import PaperCollapse from './PaperCollapse';
import TaskInfo from './TaskInfo';
import TaskAssessment from './TaskAssessment';
import TaskContent from './TaskContent';

interface Props {
  task: FullTaskFragment,
};

const Layout = ({
  task,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const {
    methodOption,
    typeOption,
    completionTypeOption,
    renewalOption,
    showAssessment,
    showContent,
    showAssessmentCorrectAnswers,
  } = useMemo(
    () => ({
      methodOption: getOptionTaskMethodById(task.assessmentMethod),
      typeOption: getOptionTaskTypeById(task.type),
      completionTypeOption: getOptionTaskCompletionTypeById(task.typeOfCompletion),
      renewalOption: getOptionTaskRenewalById(task.renewal),
      showAssessment: task.type === TaskTypeEnum.Additional || TaskTypeEnum.Elearning,
      showContent: task.type === TaskTypeEnum.Elearning,
      showAssessmentCorrectAnswers: task.assessmentMethod === AssessmentMethodEnum.Test,
    }),
    [ task, ]
  );

  const methodLabel = methodOption ? t(methodOption.labelKey, methodOption.id) : '';
  const typeLabel = typeOption ? t(typeOption.labelKey, typeOption.id) : '';
  
  return (
    <>

      <Typography variant="body1" mb={4}>
        {t('detail.info', { method: methodLabel, type: typeLabel, })}
      </Typography>

      <TaskInfo
        task={task}
        completionTypeOption={completionTypeOption}
        renewalOption={renewalOption}
      />

      {showContent && (
        <PaperCollapse
          title={t('detail.titleContent', { method: methodLabel, type: typeLabel, })}
        >
          <TaskContent
            task={task}
          />
        </PaperCollapse>
      )}

      {showAssessment && (
        <PaperCollapse
          title={t('detail.titleAssessments', { method: methodLabel, type: typeLabel, })}
        >
          <TaskAssessment
            task={task}
            showAssessmentCorrectAnswers={showAssessmentCorrectAnswers}
          />
        </PaperCollapse>
      )}

      <Button
        component={RouterLink}
        to={`/${ROUTES.admin}/${ROUTES.tasks}`}
        size="large"
        variant="outlined"
        color="inherit"
      >
        {t('detail.btnClose')}
      </Button>

    </>
  );
};

export default Layout;
