import { Box, Dialog, IconButton, Stack, Typography, useTheme, } from '@mui/material';
import Button from '@mui/material/Button';
import { ReactNode, useState, } from 'react';
import { useTranslation, } from 'react-i18next';

import './envelope.css';
import CloseIcon from 'Utils/svg/CloseIcon';
import { useNavigate, } from 'react-router-dom';


type Props = {
  body: ReactNode;
  heading: ReactNode;
};

const WishContent = ({ body, heading, }: Props) => {
  const { t, } = useTranslation('prizeWheel');
  const navigate = useNavigate();
  const theme = useTheme();
  const [isEnvelopeOpen, setIsEnvelopeOpen,] = useState(false);
  const openCssClass = isEnvelopeOpen ? 'open' : '';

  const handleClose = (): void => {
    navigate('/');
  };

  return (
    <Dialog
      open
      onClose={() => {}}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          bgcolor: 'transparent',
          boxShadow: 'revert',
          alignItems: 'center',
          overflowY: 'revert',
        },
        backdropFilter: 'blur(8px)',
      }}
    >
      <div className="wrapper">
        <div className={`lid one ${openCssClass}`} />
        <div className={`lid two ${openCssClass}`} />
        <div className={`envelope ${openCssClass}`} />
        <div className={`letter ${openCssClass}`}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'black',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <Typography variant="h1" sx={{ color: '#6B1A1A', textAlign: 'center', paddingTop: '2.5rem', }}>
              {heading}
            </Typography>
          </Box>

          <Stack
            spacing="10px"
            sx={{
              alignItems: 'center',
              paddingBottom: '100px',
              paddingX: '50px',
              paddingTop: '0.75rem',
              [theme.breakpoints.down('sm')]: {
                paddingX: '16.6%',
                paddingBottom: '5px',
              },
            }}
          >
            <Box sx={{ margin: 'auto', typography:'body0', }}>{body}</Box>
          </Stack>
        </div>
        <Button
      variant="xmasWheel"
          onClick={() => setIsEnvelopeOpen((prev) => !prev)}
        >
          {isEnvelopeOpen ? t('wish.close') : t('wish.open')}
        </Button>
      </div>
    </Dialog>
  );
};

export default WishContent;
