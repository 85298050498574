import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';

export type PhaseCustomerVerificationFormValues = {
  verificationCode: string,
};

export const initPhaseCustomerVerificationFormValues: PhaseCustomerVerificationFormValues = {
  verificationCode: '',
};

export const phaseCustomerVerificationValidationSchema = yup.object().shape({
  verificationCode: yup.string()
    .required(INPUT_FORM_ERRORS.REQUIRED),
});
