import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type ReplacementReasonOption = {
  id: string;
  groupKey: string;
  requireTextInput: boolean;
  labelKey: string;
};


function prepareReplacementReasonOptions(keys: ([string, boolean,] | [string,])[], groupKey: string): ReplacementReasonOption[] {
  return keys.map(([id, requireTextInput = false,]) => ({
    id,
    groupKey,
    requireTextInput,
    labelKey: `common:options.replacementReason.${id}`,
  }));
}

export const PULZE_DEVICE_1_0_REASON_OPTIONS: ReplacementReasonOption[] = [
  ...prepareReplacementReasonOptions(
    [
      ['pulzeAccessoriesNoPredefinedAnswerTextInput', true,],
    ],
    'common:options.replacementReason.pulzeAccessories'
  ),

  ...prepareReplacementReasonOptions(
    [
      ['pulzeChargersChargingCableBroken',],
      ['pulzeChargersChargingCableNotFitting',],
      ['pulzeChargersChargingCableNotWorking',],
      ['pulzeChargersChargingCableOther', true,],
    ],
    'common:options.replacementReason.pulzeChargersChargingCable'
  ),

  ...prepareReplacementReasonOptions(
    [
      ['pulzeChargersWallChargerBroken',],
      ['pulzeChargersWallChargerNotWorking',],
      ['pulzeChargersWallChargerOther', true,],
    ],
    'common:options.replacementReason.pulzeChargersWallCharger'
  ),

  ...prepareReplacementReasonOptions(
    [
      ['pulzeDeviceBatteryBatteryLeaking',],
      ['pulzeDeviceBatteryBodySeparatesFromBattery',],
      ['pulzeDeviceBatteryChargingTimeTooLong',],
      ['pulzeDeviceBatteryLightFlashingIncorrectly',],
      ['pulzeDeviceBatteryNoLight',],
      ['pulzeDeviceBatteryOverheats',],
      ['pulzeDeviceBatterySelfActivation',],
      ['pulzeDeviceBatteryShortBatteryLife',],
      ['pulzeDeviceBatteryWontCharge',],
      ['pulzeDeviceBatteryOthersBatteryTextInput', true,],
    ],
    'common:options.replacementReason.pulzeDeviceBattery'
  ),

  ...prepareReplacementReasonOptions(
    [
      ['pulzeDeviceMechanismButtonNotWorking',],
      ['pulzeDeviceMechanismClog',],
      ['pulzeDeviceMechanismDamagedTopCap',],
      ['pulzeDeviceMechanismErrorCodeLed',],
      ['pulzeDeviceMechanismHeatingElementBroken',],
      ['pulzeDeviceMechanismHeatingElementDoesNotWork',],
      ['pulzeDeviceMechanismHeatingWithoutTopCap',],
      ['pulzeDeviceMechanismLiquidOozingOut',],
      ['pulzeDeviceMechanismNoVapor',],
      ['pulzeDeviceMechanismNoVibrationOrVibrationMotorError',],
      ['pulzeDeviceMechanismTopCapCannotBeRemovedWithIncludedExtractionTool',],
      ['pulzeDeviceMechanismTopCapFallsOut',],
      ['pulzeDeviceMechanismTopCapNotMoving',],
      ['pulzeDeviceMechanismTopCapStickEntry', true,],
      ['pulzeDeviceMechanismOtherDeviceMechanismTextInput', true,],
    ],
    'common:options.replacementReason.pulzeDeviceMechanism'
  ),

  ...prepareReplacementReasonOptions(
    [
      ['pulzePackContentContentDamaged',],
      ['pulzePackContentContentDirty',],
      ['pulzePackContentContentIncorrect',],
      ['pulzePackContentContentMissing',],
      ['pulzePackContentForeignMatter',],
      ['pulzePackContentWrongBrand',],
      ['pulzePackContentOthers', true,],
    ],
    'common:options.replacementReason.pulzePackContent'
  ),

  ...prepareReplacementReasonOptions(
    [
      ['pulzePackMiscellaneousIndividualWrap',],
      ['pulzePackMiscellaneousPackDamaged',],
      ['pulzePackMiscellaneousOthers', true,],
    ],
    'common:options.replacementReason.pulzePackMiscellaneous'
  ),

  ...prepareReplacementReasonOptions(
    [
      ['pulzeShippingCaseMiscellaneousDamagedShippingCase',],
      ['pulzeShippingCaseMiscellaneousOthers', true,],
    ],
    'common:options.replacementReason.pulzeShippingCaseMiscellaneous'
  ),

  ...prepareReplacementReasonOptions(
    [
      ['pulzeShippingCaseContentPacksMissing',],
      ['pulzeShippingCaseContentWrongBrand',],
    ],
    'common:options.replacementReason.pulzeShippingCaseContent'
  ),
];

export const PULZE_DEVICE_2_0_REASON_OPTIONS = PULZE_DEVICE_1_0_REASON_OPTIONS
  .filter(item => item.id !== 'pulzeDeviceMechanismTopCapCannotBeRemovedWithIncludedExtractionTool');

export const REPLACEMENT_REASON_OPTIONS: ReplacementReasonOption[] = [
  /**
   * pulzeDeviceMechanism
   */
  {
    id: 'pulzeDeviceMechanismDevice',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismDevice',
  },
  {
    id: 'pulzeDeviceMechanismButtonNotWorking',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismButtonNotWorking',
  },
  {
    id: 'pulzeDeviceMechanismClog',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismClog',
  },
  {
    id: 'pulzeDeviceMechanismDamagedTopCap',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismDamagedTopCap',
  },
  {
    id: 'pulzeDeviceMechanismErrorCodeLed',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismErrorCodeLed',
  },
  {
    id: 'pulzeDeviceMechanismHeatingElementBroken',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismHeatingElementBroken',
  },
  {
    id: 'pulzeDeviceMechanismHeatingElementDoesNotWork',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismHeatingElementDoesNotWork',
  },
  {
    id: 'pulzeDeviceMechanismHeatingWithoutTopCap',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismHeatingWithoutTopCap',
  },
  {
    id: 'pulzeDeviceMechanismLiquidOozingOut',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismLiquidOozingOut',
  },
  {
    id: 'pulzeDeviceMechanismNoVapor',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismNoVapor',
  },
  {
    id: 'pulzeDeviceMechanismTopCapCannotBeRemovedWithIncludedExtractionTool',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismTopCapCannotBeRemovedWithIncludedExtractionTool',
  },
  {
    id: 'pulzeDeviceMechanismTopCapFallsOut',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismTopCapFallsOut',
  },
  {
    id: 'pulzeDeviceMechanismTopCapNotMoving',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismTopCapNotMoving',
  },
  {
    id: 'pulzeDeviceMechanismTopCapStickEntry',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismTopCapStickEntry',
  },
  {
    id: 'pulzeDeviceMechanismOtherDeviceMechanismTextInput',
    groupKey: 'common:options.replacementReason.pulzeDeviceMechanism',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.pulzeDeviceMechanismOtherDeviceMechanismTextInput',
  },
  /**
   * pulzeDeviceBattery
   */
  {
    id: 'pulzeDeviceBatteryBatteryLeaking',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatteryBatteryLeaking',
  },
  {
    id: 'pulzeDeviceBatteryBodySeparatesFromBattery',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatteryBodySeparatesFromBattery',
  },
  {
    id: 'pulzeDeviceBatteryChargingTimeTooLong',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatteryChargingTimeTooLong',
  },
  {
    id: 'pulzeDeviceBatteryLightFlashingIncorrectly',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatteryLightFlashingIncorrectly',
  },
  {
    id: 'pulzeDeviceBatteryMissingCoding',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatteryMissingCoding',
  },
  {
    id: 'pulzeDeviceBatteryNoLight',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatteryNoLight',
  },
  {
    id: 'pulzeDeviceBatteryOverheats',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatteryOverheats',
  },
  {
    id: 'pulzeDeviceBatterySelfActivation',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatterySelfActivation',
  },
  {
    id: 'pulzeDeviceBatteryShortBatteryLife',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatteryShortBatteryLife',
  },
  {
    id: 'pulzeDeviceBatteryUnreadableCoding',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatteryUnreadableCoding',
  },
  {
    id: 'pulzeDeviceBatteryWontCharge',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatteryWontCharge',
  },
  {
    id: 'pulzeDeviceBatteryWrongCoding',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatteryWrongCoding',
  },
  {
    id: 'pulzeDeviceBatteryOthersBatteryTextInput',
    groupKey: 'common:options.replacementReason.pulzeDeviceBattery',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.pulzeDeviceBatteryOthersBatteryTextInput',
  },
  /**
   * pulzeDeviceConsumerUsage
   */
  {
    id: 'pulzeDeviceConsumerUsageDeepCleaning',
    groupKey: 'common:options.replacementReason.pulzeDeviceConsumerUsage',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceConsumerUsageDeepCleaning',
  },
  {
    id: 'pulzeDeviceConsumerUsageEnvironmentConditions',
    groupKey: 'common:options.replacementReason.pulzeDeviceConsumerUsage',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceConsumerUsageEnvironmentConditions',
  },
  {
    id: 'pulzeDeviceConsumerUsageLockMode',
    groupKey: 'common:options.replacementReason.pulzeDeviceConsumerUsage',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceConsumerUsageLockMode',
  },
  {
    id: 'pulzeDeviceConsumerUsageRecharge',
    groupKey: 'common:options.replacementReason.pulzeDeviceConsumerUsage',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceConsumerUsageRecharge',
  },
  {
    id: 'pulzeDeviceConsumerUsageTopCoverNotCompletelyClosed',
    groupKey: 'common:options.replacementReason.pulzeDeviceConsumerUsage',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.pulzeDeviceConsumerUsageTopCoverNotCompletelyClosed',
  },
  /**
   * idPacks
   */
  {
    id: 'idPacksNoPredefinedAnswerTextInput',
    groupKey: 'common:options.replacementReason.idPacks',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.idPacksNoPredefinedAnswerTextInput',
  },
  /**
   * pulzeAccessories
   */
  {
    id: 'pulzeAccessoriesNoPredefinedAnswerTextInput',
    groupKey: 'common:options.replacementReason.pulzeAccessories',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.pulzeAccessoriesNoPredefinedAnswerTextInput',
  },
];
const ELECTRONIC_CIGARETTE_CARTRIDGE_CZ_REPLACEMENT_REASON_OPTIONS: ReplacementReasonOption[] = [
  // liquid
  {
    id: 'cartridgeLiquidBurntTaste',
    groupKey: 'common:options.replacementReason.liquid',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.liquidReasons.burntTaste',
  },
  {
    id: 'cartridgeLiquidColorChange',
    groupKey: 'common:options.replacementReason.liquid',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.liquidReasons.colorChange',
  },
  {
    id: 'cartridgeLiquidWrongFlavour',
    groupKey: 'common:options.replacementReason.liquid',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.liquidReasons.wrongFlavour',
  },
  {
    id: 'cartridgeLiquidStrongFlavour',
    groupKey: 'common:options.replacementReason.liquid',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.liquidReasons.strongFlavour',
  },
  {
    id: 'cartridgeLiquidWeakFlavour',
    groupKey: 'common:options.replacementReason.liquid',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.liquidReasons.weakFlavour',
  },
  {
    id: 'cartridgeLiquidForeignObject',
    groupKey: 'common:options.replacementReason.liquid',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.liquidReasons.foreignObject',
  },
  {
    id: 'cartridgeLiquidStrongNicotine',
    groupKey: 'common:options.replacementReason.liquid',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.liquidReasons.strongNicotine',
  },
  {
    id: 'cartridgeLiquidWeakNicotine',
    groupKey: 'common:options.replacementReason.liquid',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.liquidReasons.weakNicotine',
  },
  {
    id: 'cartridgeLiquidNoTaste',
    groupKey: 'common:options.replacementReason.liquid',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.liquidReasons.noTaste',
  },
  {
    id: 'cartridgeLiquidNoNicotine',
    groupKey: 'common:options.replacementReason.liquid',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.liquidReasons.noNicotine',
  },
  {
    id: 'cartridgeLiquidNotFullEnough',
    groupKey: 'common:options.replacementReason.liquid',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.liquidReasons.notFullEnough',
  },
  {
    id: 'cartridgeLiquidOther',
    groupKey: 'common:options.replacementReason.liquid',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.liquidReasons.other',
  },
  // packaging

  {
    id: 'cartridgePackagingBlisterHardToOpen',
    groupKey: 'common:options.replacementReason.packaging',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.packagingReasons.blisterHardToOpen',
  },
  {
    id: 'cartridgePackagingDamagedPackaging',
    groupKey: 'common:options.replacementReason.packaging',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.packagingReasons.damagedPackaging',
  },
  {
    id: 'cartridgePackagingDamagedPackagingEshop',
    groupKey: 'common:options.replacementReason.packaging',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.packagingReasons.damagedPackagingEshop',
  },
  {
    id: 'cartridgePackagingWrongPackaging',
    groupKey: 'common:options.replacementReason.packaging',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.packagingReasons.wrongPackaging',
  },
  {
    id: 'cartridgePackagingWrongPackagingEshop',
    groupKey: 'common:options.replacementReason.packaging',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.packagingReasons.wrongPackagingEshop',
  },
  {
    id: 'cartridgePackagingMissingPOD',
    groupKey: 'common:options.replacementReason.packaging',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.packagingReasons.missingPOD',
  },
  {
    id: 'cartridgePackagingOther',
    groupKey: 'common:options.replacementReason.packaging',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.packagingReasons.other',
  },
  // filling
  {
    id: 'cartridgeFillingStuck',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.fillingReasons.stuck',
  },
  {
    id: 'cartridgeFillingDamagedPOD',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.fillingReasons.damagedPOD',
  },
  {
    id: 'cartridgeFillingUnfitPOD',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.fillingReasons.unfitPOD',
  },
  {
    id: 'cartridgeFillingSixBlinks',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.fillingReasons.sixBlinks',
  },
  {
    id: 'cartridgeFillingRedBlink',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.fillingReasons.redBlink',
  },
  {
    id: 'cartridgeFillingLooseConnection',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.fillingReasons.looseConnection',
  },
  {
    id: 'cartridgeFillingLooseMouthpiece',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.fillingReasons.looseMouthpiece',
  },
  {
    id: 'cartridgeFillingLeakInPackage',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.fillingReasons.leakInPackage',
  },
  {
    id: 'cartridgeFillingMouthLeak',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.fillingReasons.mouthLeak',
  },
  {
    id: 'cartridgeFillingNoSteam',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.fillingReasons.noSteam',
  },
  {
    id: 'cartridgeFillingLoosePOD',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.fillingReasons.loosePOD',
  },
  {
    id: 'cartridgeFillingCrookedPOD',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.fillingReasons.crookedPOD',
  },
  {
    id: 'cartridgeFillingOther',
    groupKey: 'common:options.replacementReason.filling',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.fillingReasons.other',
  },
];

const ELECTRONIC_CIGARETTES_CZ_REASON_OPTIONS: ReplacementReasonOption[] = [
  // device
  {
    id: 'batteryLeaking',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.batteryLeaking',
  },
  {
    id: 'caseSeparatesFromBattery',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.caseSeparatesFromBattery',
  },
  {
    id: 'longCharging',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.longCharging',
  },
  {
    id: 'notFitPOD',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.notFitPOD',
  },
  {
    id: 'lightDuringCharging',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.lightDuringCharging',
  },
  {
    id: 'lightDuringUsage',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.lightDuringUsage',
  },
  {
    id: 'lightRedOnce',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.lightRedOnce',
  },
  {
    id: 'lightStayOn',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.lightStayOn',
  },
  {
    id: 'noLight',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.noLight',
  },
  {
    id: 'noLightSmoking',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.noLightSmoking',
  },
  {
    id: 'noSteam',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.noSteam',
  },
  {
    id: 'overHeating',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.overHeating',
  },
  {
    id: 'dropOut',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.dropOut',
  },
  {
    id: 'twisting',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.twisting',
  },
  {
    id: 'powertrapDifficultToUse',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.powertrapDifficultToUse',
  },
  {
    id: 'selfActivation',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.selfActivation',
  },
  {
    id: 'shortLifeBattery',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.shortLifeBattery',
  },
  {
    id: 'noVibration',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.noVibration',
  },
  {
    id: 'noVibrationFirstUsage',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.noVibrationFirstUsage',
  },
  {
    id: 'noCharge',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.noCharge',
  },
  {
    id: 'noChargeFirstUsage',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.noChargeFirstUsage',
  },
  {
    id: 'badLightColor',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.badLightColor',
  },
  {
    id: 'other',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.other',
  },
  // packaging
  {
    id: 'damagedPackage',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.damagedPackage',
  },
  {
    id: 'damagedPackageEshop',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.damagedPackageEshop',
  },
  {
    id: 'wrongPackage',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.wrongPackage',
  },
  {
    id: 'wrongPackageEshop',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.wrongPackageEshop',
  },
  {
    id: 'missingCabel',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.missingCabel',
  },
  {
    id: 'missingDevice',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.missingDevice',
  },
  {
    id: 'other',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.electronicCigarettesCz.other',
  },
];

const BLUEBAR_CZ_REASON_OPTIONS: ReplacementReasonOption[] = [
  // device
  {
    id: 'device_1',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.1',
  },
  {
    id: 'device_2',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.2',
  },
  {
    id: 'device_3',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.3',
  },
  {
    id: 'device_4',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.4',
  },
  {
    id: 'device_5',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.5',
  },
  {
    id: 'device_6',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.6',
  },
  {
    id: 'device_7',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.7',
  },
  {
    id: 'device_8',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.8',
  },
  {
    id: 'device_9',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.9',
  },
  {
    id: 'device_10',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.10',
  },
  {
    id: 'device_11',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.11',
  },
  {
    id: 'device_12',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.12',
  },
  {
    id: 'device_13',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.13',
  },
  {
    id: 'device_14',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.14',
  },
  {
    id: 'device_15',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.15',
  },
  {
    id: 'device_16',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.16',
  },
  {
    id: 'device_17',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.17',
  },
  {
    id: 'device_18',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.18',
  },
  {
    id: 'device_19',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.19',
  },
  {
    id: 'device_20',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.20',
  },
  {
    id: 'device_21',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.21',
  },
  {
    id: 'device_22',
    groupKey: 'common:options.replacementReason.deviceCz',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.bluebarDeviceCz.22',
  },

  // packaging
  {
    id: 'packaging_1',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarPackagingCz.1',
  },
  {
    id: 'packaging_2',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarPackagingCz.2',
  },
  {
    id: 'packaging_3',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarPackagingCz.3',
  },
  {
    id: 'packaging_4',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarPackagingCz.4',
  },
  {
    id: 'packaging_5',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarPackagingCz.5',
  },
  {
    id: 'packaging_6',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarPackagingCz.6',
  },
  {
    id: 'packaging_7',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarPackagingCz.7',
  },
  {
    id: 'packaging_8',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarPackagingCz.8',
  },
  {
    id: 'packaging_9',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarPackagingCz.9',
  },
  {
    id: 'packaging_0',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.bluebarPackagingCz.10',
  },
  {
    id: 'packaging_11',
    groupKey: 'common:options.replacementReason.packagingCz',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.bluebarPackagingCz.11',
  },
];

const ISENZIA_KRABICKY_CZ_REASON_OPTIONS: ReplacementReasonOption[] = [
  {
    id: 'iSenziaOuterDamagedOuter',
    groupKey: 'common:options.replacementReason.iSenziaOuter',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaOuterDamagedOuter',
  },
  {
    id: 'iSenziaOuterDirtyDustyOuter',
    groupKey: 'common:options.replacementReason.iSenziaOuter',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaOuterDirtyDustyOuter',
  },
  {
    id: 'iSenziaOuterIndividualWrapOuter',
    groupKey: 'common:options.replacementReason.iSenziaOuter',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaOuterIndividualWrapOuter',
  },
  {
    id: 'iSenziaOuterOthersOuter',
    groupKey: 'common:options.replacementReason.iSenziaOuter',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.iSenziaOuterOthersOuter',
  },
  {
    id: 'iSenziaOuterPacksMissing',
    groupKey: 'common:options.replacementReason.iSenziaOuter',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaOuterPacksMissing',
  },
  {
    id: 'iSenziaOuterWrongBrandPacket',
    groupKey: 'common:options.replacementReason.iSenziaOuter',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaOuterWrongBrandPacket',
  },

  {
    id: 'iSenziaPackShortPacked',
    groupKey: 'common:options.replacementReason.iSenziaPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaPackShortPacked',
  },
  {
    id: 'iSenziaPackStickDamaged',
    groupKey: 'common:options.replacementReason.iSenziaPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaPackStickDamaged',
  },
  {
    id: 'iSenziaPackStickStuckInPack',
    groupKey: 'common:options.replacementReason.iSenziaPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaPackStickStuckInPack',
  },
  {
    id: 'iSenziaPackWrongBrand',
    groupKey: 'common:options.replacementReason.iSenziaPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaPackWrongBrand',
  },
  {
    id: 'iSenziaPackDamagedPacket',
    groupKey: 'common:options.replacementReason.iSenziaPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaPackDamagedPacket',
  },
  {
    id: 'iSenziaPackDefectivePack',
    groupKey: 'common:options.replacementReason.iSenziaPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaPackDefectivePack',
  },
  {
    id: 'iSenziaPackDirtyDustyPacket',
    groupKey: 'common:options.replacementReason.iSenziaPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaPackDirtyDustyPacket',
  },
  {
    id: 'iSenziaPackFake',
    groupKey: 'common:options.replacementReason.iSenziaPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaPackFake',
  },
  {
    id: 'iSenziaPackFaultyFoil',
    groupKey: 'common:options.replacementReason.iSenziaPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaPackFaultyFoil',
  },
  {
    id: 'iSenziaPackIndividualWrap',
    groupKey: 'common:options.replacementReason.iSenziaPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaPackIndividualWrap',
  },
  {
    id: 'iSenziaPackOthersPack',
    groupKey: 'common:options.replacementReason.iSenziaPack',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.iSenziaPackOthersPack',
  },

  {
    id: 'iSenziaShippingCaseDamagedShippingCase',
    groupKey: 'common:options.replacementReason.iSenziaShippingCase',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaShippingCaseDamagedShippingCase',
  },
  {
    id: 'iSenziaShippingCaseOthersShippingCase',
    groupKey: 'common:options.replacementReason.iSenziaShippingCase',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.iSenziaShippingCaseOthersShippingCase',
  },
  {
    id: 'iSenziaStickHoleInPaste',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickHoleInPaste',
  },
  {
    id: 'iSenziaStickUnderfilled',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickUnderfilled',
  },
  {
    id: 'iSenziaStickCBFluidDropping',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickCBFluidDropping',
  },
  {
    id: 'iSenziaStickCBWrongPosition',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickCBWrongPosition',
  },
  {
    id: 'iSenziaStickCreasedWrinkledTipping',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickCreasedWrinkledTipping',
  },
  {
    id: 'iSenziaStickCrushballDamagedLeaking',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickCrushballDamagedLeaking',
  },
  {
    id: 'iSenziaStickCrushballDifficultToCrush',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickCrushballDifficultToCrush',
  },
  {
    id: 'iSenziaStickCrushballMissing',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickCrushballMissing',
  },
  {
    id: 'iSenziaStickDefectiveFilter',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickDefectiveFilter',
  },
  {
    id: 'iSenziaStickDefectiveTipping',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickDefectiveTipping',
  },
  {
    id: 'iSenziaStickDirtyFilterTipping',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickDirtyFilterTipping',
  },
  {
    id: 'iSenziaStickMeltedPlug',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickMeltedPlug',
  },
  {
    id: 'iSenziaStickTipComeOut',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickTipComeOut',
  },
  {
    id: 'iSenziaStickTipMissing',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickTipMissing',
  },
  {
    id: 'iSenziaStickWrongCBWrongFlavour',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickWrongCBWrongFlavour',
  },
  {
    id: 'iSenziaStickWrongTipping',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickWrongTipping',
  },
  {
    id: 'iSenziaStickOthersFilterTipping',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.iSenziaStickOthersFilterTipping',
  },
  {
    id: 'iSenziaStickHotSenzationLips',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickHotSenzationLips',
  },
  {
    id: 'iSenziaStickHotSenzationMouth',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickHotSenzationMouth',
  },
  {
    id: 'iSenziaStickSpotsDiscoloration',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickSpotsDiscoloration',
  },
  {
    id: 'iSenziaStickStickDoesNotFitToTopCap',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickStickDoesNotFitToTopCap',
  },
  {
    id: 'iSenziaStickTaste',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickTaste',
  },
  {
    id: 'iSenziaStickTippingImprintQuality',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickTippingImprintQuality',
  },
  {
    id: 'iSenziaStickTooLittleFlavour',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickTooLittleFlavour',
  },
  {
    id: 'iSenziaStickTooMuchFlavour',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.iSenziaStickTooMuchFlavour',
  },
  {
    id: 'iSenziaStickOthersStick',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.iSenziaStickOthersStick',
  },
  {
    id: 'iSenziaStickOthersVapingCharacteristics',
    groupKey: 'common:options.replacementReason.iSenziaStick',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.iSenziaStickOthersVapingCharacteristics',
  },
];

const ID_KRABICKY_CZ_REASON_OPTIONS: ReplacementReasonOption[] = [
  {
    id: 'idPacksOuterDamagedOuter',
    groupKey: 'common:options.replacementReason.idPacksOuter',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksOuterDamagedOuter',
  },
  {
    id: 'idPacksOuterDirtyDustyOuter',
    groupKey: 'common:options.replacementReason.idPacksOuter',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksOuterDirtyDustyOuter',
  },
  {
    id: 'idPacksOuterIndividualWrapOuter',
    groupKey: 'common:options.replacementReason.idPacksOuter',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksOuterIndividualWrapOuter',
  },
  {
    id: 'idPacksOuterOthersOuter',
    groupKey: 'common:options.replacementReason.idPacksOuter',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.idPacksOuterOthersOuter',
  },
  {
    id: 'idPacksOuterPacksMissing',
    groupKey: 'common:options.replacementReason.idPacksOuter',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksOuterPacksMissing',
  },
  {
    id: 'idPacksOuterWrongBrandPacket',
    groupKey: 'common:options.replacementReason.idPacksOuter',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksOuterWrongBrandPacket',
  },

  {
    id: 'idPacksPackShortPacked',
    groupKey: 'common:options.replacementReason.idPacksPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksPackShortPacked',
  },
  {
    id: 'idPacksPackStickDamaged',
    groupKey: 'common:options.replacementReason.idPacksPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksPackStickDamaged',
  },
  {
    id: 'idPacksPackStickStuckInPack',
    groupKey: 'common:options.replacementReason.idPacksPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksPackStickStuckInPack',
  },
  {
    id: 'idPacksPackWrongBrand',
    groupKey: 'common:options.replacementReason.idPacksPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksPackWrongBrand',
  },
  {
    id: 'idPacksPackDamagedPacket',
    groupKey: 'common:options.replacementReason.idPacksPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksPackDamagedPacket',
  },
  {
    id: 'idPacksPackDefectivePack',
    groupKey: 'common:options.replacementReason.idPacksPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksPackDefectivePack',
  },
  {
    id: 'idPacksPackDirtyDustyPacket',
    groupKey: 'common:options.replacementReason.idPacksPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksPackDirtyDustyPacket',
  },
  {
    id: 'idPacksPackFake',
    groupKey: 'common:options.replacementReason.idPacksPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksPackFake',
  },
  {
    id: 'idPacksPackFaultyFoil',
    groupKey: 'common:options.replacementReason.idPacksPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksPackFaultyFoil',
  },
  {
    id: 'idPacksPackIndividualWrap',
    groupKey: 'common:options.replacementReason.idPacksPack',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksPackIndividualWrap',
  },
  {
    id: 'idPacksPackOthersPack',
    groupKey: 'common:options.replacementReason.idPacksPack',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.idPacksPackOthersPack',
  },

  {
    id: 'idPacksShippingCaseDamagedShippingCase',
    groupKey: 'common:options.replacementReason.idPacksShippingCase',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksShippingCaseDamagedShippingCase',
  },
  {
    id: 'idPacksShippingCaseOthersShippingCase',
    groupKey: 'common:options.replacementReason.idPacksShippingCase',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.idPacksShippingCaseOthersShippingCase',
  },
  {
    id: 'idPacksShippingCaseOutersMissing',
    groupKey: 'common:options.replacementReason.idPacksShippingCase',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksShippingCaseOutersMissing',
  },
  {
    id: 'idPacksShippingCaseWrongBrandOuter',
    groupKey: 'common:options.replacementReason.idPacksShippingCase',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksShippingCaseWrongBrandOuter',
  },

  {
    id: 'idPacksStickLooseRodFilling',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickLooseRodFilling',
  },
  {
    id: 'idPacksStickLooseTobaccoStrips',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickLooseTobaccoStrips',
  },
  {
    id: 'idPacksStickCBFluidDropping',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickCBFluidDropping',
  },
  {
    id: 'idPacksStickCBWrongPosition',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickCBWrongPosition',
  },
  {
    id: 'idPacksStickCircumferenceGap',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickCircumferenceGap',
  },
  {
    id: 'idPacksStickCrushballDamaged',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickCrushballDamaged',
  },
  {
    id: 'idPacksStickCrushballMissing',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickCrushballMissing',
  },
  {
    id: 'idPacksStickDetachedTip',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickDetachedTip',
  },
  {
    id: 'idPacksStickFaultyJoiningPaper',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickFaultyJoiningPaper',
  },
  {
    id: 'idPacksStickMeltedPlug',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickMeltedPlug',
  },
  {
    id: 'idPacksStickSoftFilter',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickSoftFilter',
  },
  {
    id: 'idPacksStickTipMissing',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickTipMissing',
  },
  {
    id: 'idPacksStickWrongCBWrongFlavour',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickWrongCBWrongFlavour',
  },
  {
    id: 'idPacksStickOthersFilter',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.idPacksStickOthersFilter',
  },
  {
    id: 'idPacksStickHotSenzationLips',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickHotSenzationLips',
  },
  {
    id: 'idPacksStickHotSenzationMouth',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickHotSenzationMouth',
  },
  {
    id: 'idPacksSticSpots',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksSticSpots',
  },
  {
    id: 'idPacksStickStickDoesNotFitToTopCap',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickStickDoesNotFitToTopCap',
  },
  {
    id: 'idPacksStickTaste',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickTaste',
  },
  {
    id: 'idPacksStickTippingImprintQuality',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickTippingImprintQuality',
  },
  {
    id: 'idPacksStickTooLittleFlavour',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickTooLittleFlavour',
  },
  {
    id: 'idPacksStickTooMuchFlavour',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksStickTooMuchFlavour',
  },
  {
    id: 'idPacksSticOpenSeam',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: false,
    labelKey: 'common:options.replacementReason.idPacksSticOpenSeam',
  },
  {
    id: 'idPacksStickOthersStick',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.idPacksStickOthersStick',
  },
  {
    id: 'idPacksStickOthersVapingCharacteristics',
    groupKey: 'common:options.replacementReason.idPacksStick',
    requireTextInput: true,
    labelKey: 'common:options.replacementReason.idPacksStickOthersVapingCharacteristics',
  },
];

const reasonOptions = (categoryName?: string): ReplacementReasonOption[] => {
  if (!categoryName) return [];
  
  if (categoryName === 'PULZE 1.0 zařízení') {
    return PULZE_DEVICE_1_0_REASON_OPTIONS;
  }
  if (categoryName === 'PULZE 2.0 zařízení') {
    return PULZE_DEVICE_2_0_REASON_OPTIONS;
  }
  if (categoryName === 'Elektronické cigarety') {
    return ELECTRONIC_CIGARETTES_CZ_REASON_OPTIONS;
  }
  if (categoryName === 'Elektronické cigarety - Náplně') {
    return ELECTRONIC_CIGARETTE_CARTRIDGE_CZ_REPLACEMENT_REASON_OPTIONS;
  }
  if (categoryName === 'blubar - jednorázové cigarety') {
    return BLUEBAR_CZ_REASON_OPTIONS;
  }
  if (categoryName === 'iD krabičky') {
    return ID_KRABICKY_CZ_REASON_OPTIONS;
  }
  if (categoryName === 'iSenzia krabičky') {
    return ISENZIA_KRABICKY_CZ_REASON_OPTIONS;
  }

  return REPLACEMENT_REASON_OPTIONS;
};

type Selector = (o: ReplacementReasonOption) => string;

type UseOptionsReplacementReason = (categoryName?: string) => {
  options: ReplacementReasonOption[];
  getOptionLabel: Selector;
  getOptionKey: Selector;
  isOptionEqualToValue: typeof areOptionsEqualById<ReplacementReasonOption>;
  groupBy: Selector;
};

export const useOptionsReplacementReason: UseOptionsReplacementReason = (categoryName?: string) => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<Selector>((o) => t(o.labelKey), [t,]);
  const groupBy = useCallback<Selector>((o) => t(o.groupKey), [t,]);
  return {
    options: reasonOptions(categoryName),
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
    groupBy,
  };
};
