import shallow from 'zustand/shallow';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementAdmin/store/ReplacementStore';
import Layout from './Layout';

const selectStoreData = (s: ReplacementStore) => ({
  onClose: s.closeModalCreateReplacement,
  modalData: s.modalCreateReplacement,
});

const ModalCreateReplacement = (): JSX.Element => {
  const { onClose, modalData, } = useReplacementStore(selectStoreData, shallow);

  return (
    <ModalDrawer
      onClose={onClose}
      open={modalData.isOpen}
    >
      <Layout onClose={onClose} />
    </ModalDrawer>
  );
};

export default ModalCreateReplacement;
