import Box from '@mui/material/Box';
import { usePromotionsListQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import CardItem from './CardItem';
import SkeletonItem from './SkeletonItem';

const PromotionsList = (): JSX.Element => {
  const { loading, error, data, } = usePromotionsListQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        isNowAvailable: true,
      },
    },
  });

  if (loading) return <SkeletonItem />;

  if (error || !data?.promotionsList) return <ErrorAlert forceError error={error} />;

  return (
    <Box flexDirection="column">
      {data.promotionsList.data.map((promotion) => (
        <CardItem key={promotion.id} promotion={promotion} />
      ))}
    </Box>
  );
};

export default PromotionsList;
