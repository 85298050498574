import { ReactNode, useState, } from 'react';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButtonSquare from 'Components/IconButtonSquare';
import KeyboardArrowUpIcon from 'Utils/svg/KeyboardArrowUpIcon';
import KeyboardArrowDownIcon from 'Utils/svg/KeyboardArrowDownIcon';

interface Props {
  title: ReactNode,
  children: ReactNode,
};

const PaperCollapse = ({
  title,
  children,
}: Props): JSX.Element => {
  const [ isOpen, setOpen, ] = useState<boolean>(false);
  const handleToggle = () => setOpen((s) => !s);

  return (
    <Paper sx={{ p: 2, mb: 4, }}>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
      >
        <Grid item>
          <Typography
            variant="h3"
            color="primary"
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <IconButtonSquare
            variant={isOpen ? 'contained' : 'outlined' }
            onClick={handleToggle}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButtonSquare>
        </Grid>
      </Grid>
      
      <Collapse in={isOpen}>
        <Box pt={2}>
          {children}
        </Box>
      </Collapse>

    </Paper>
  );
};

export default PaperCollapse;
