import { AuthUserFragment, Claim, RoleEnum, } from 'Apollo/graphql';
import { ROUTES, } from 'Utils/constants';

const REDIRECT_LOGIN = `/${ROUTES.login}`;
const REDIRECT_SELLER = `/`;
const REDIRECT_ADMIN = `/${ROUTES.admin}`;

export const getAuthRedirectRoute = (authUser: AuthUserFragment | null, defaultRedirectTo?: string): string => {
  if (defaultRedirectTo) {
    return defaultRedirectTo;
  }

  if (!authUser) {
    return REDIRECT_LOGIN;
  }

  // exception for pos manager
  if (
    authUser.roleCode === RoleEnum.PosManager ||
    authUser.roleCode === RoleEnum.IndependentPosOwner
  ) {
    return REDIRECT_SELLER;
  }

  if (authUser.claims.includes(Claim.AdminApp)) {
    return REDIRECT_ADMIN;
  }

  return REDIRECT_SELLER;
};
