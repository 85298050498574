import { UserFragment, useUserQuery, } from 'Apollo/graphql';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import Logic from './Logic';

interface Props {
  user?: UserFragment | null,
  onClose: () => void,
};

const FetchData = ({
  user,
  onClose,
}: Props): JSX.Element => {
  const preservedUser = usePreserveValue(user);

  const { loading, error, data, } = useUserQuery({
    variables: { id: preservedUser.id, },
    fetchPolicy: 'network-only',
  });

  if (loading) return (
    <ModalDrawerBodyLoading
      onClose={onClose}
    />
  );
  if (error || !data?.user) return (
    <ModalDrawerBodyError
      onClose={onClose}
      error={error}
    />
  );
  return (
    <Logic
      user={data.user}
      onClose={onClose}
    />
  );
};

export default FetchData;
