import Alert, { AlertProps, } from '@mui/material/Alert';
import Box, { BoxProps, } from '@mui/material/Box';
import ErrorFilledIcon from 'Utils/svg/ErrorFilledIcon';

interface Props extends BoxProps {
  icon?: AlertProps['icon'],
  severity?: AlertProps['severity'],
}

export const ErrorAlertWrapper = ({
  icon = <ErrorFilledIcon />,
  severity = 'error',
  children,
  ...boxProps
}: Props): JSX.Element | null => (
  <Box {...boxProps}>
    <Alert
      severity={severity}
      icon={icon}
    >
      {children}
    </Alert>
  </Box>
);

export default ErrorAlertWrapper;
