import { useCallback, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { RoleEnum, useUserRolesQuery, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';
import { ApolloError, } from '@apollo/client';
import { useAuthUser, } from 'Tools/auth';

export type UserRolesManageOption = {
  id: string,
  labelKey: string,
  requiresChainId: boolean,
  requiresPosId: boolean,
  requiresPosIds: boolean,
};

type GetOptionLabel = (o: UserRolesManageOption) => string;
type GetOptionKey = (o: UserRolesManageOption) => string;

type UseOptionsUserRolesManage = () => {
  options: UserRolesManageOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<UserRolesManageOption>, 
  loading: boolean,
  error?: ApolloError,
};

export const useOptionsUserRolesManage: UseOptionsUserRolesManage = () => {
  const { data, loading, error, } = useUserRolesQuery();
  const authUser = useAuthUser();

  const userRolesOptions: UserRolesManageOption[] = useMemo(
    () => {
      const unfilteredOptions = data?.getUserEditingRolesInfo.map(role => ({
        id: role.roleCode,
        labelKey: `common:options.userRoles.${role.roleCode}`,
        requiresChainId: role.requiresChainId,
        requiresPosId: role.requiresPosId,
        requiresPosIds: role.requiresPosIds,
      })) || [];

      const userAvailableRoles = authUser?.roleInfo.canCreateUserRoles || [];
      const filterMap = new Map(userAvailableRoles.map(role => {
        return [ role, role, ];
      }));
      const filteredOptions = unfilteredOptions.filter((o) => filterMap.has(o.id));
      return filteredOptions;
    },
    [ data?.getUserEditingRolesInfo, authUser, ]
  );

  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey, o.id),
    [ t, ],
  );

  return {
    options: userRolesOptions,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
    loading,
    error,
  };
};
