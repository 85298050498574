import { ReplacementDetailFragment, ReplacementTableFragment, useReplacementDetailQuery, } from 'Apollo/graphql';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import Logic from './Logic';

interface Props {
  replacement?: ReplacementTableFragment | null,
  onClose: () => void,
  onOpenRemove: (replacement: ReplacementDetailFragment | ReplacementTableFragment) => void,
};

const FetchData = ({
  replacement,
  onClose,
  onOpenRemove,
}: Props): JSX.Element => {
  const preservedReplacement = usePreserveValue(replacement);

  const { loading, error, data, } = useReplacementDetailQuery({
    variables: { id: preservedReplacement.id, },
    fetchPolicy: 'network-only',
  });

  if (loading) return (
    <ModalDrawerBodyLoading
      onClose={onClose}
    />
  );
  if (error || !data?.replacement) return (
    <ModalDrawerBodyError
      onClose={onClose}
      error={error}
    />
  );
  return (
    <Logic
      replacement={data.replacement}
      onClose={onClose}
      onOpenRemove={onOpenRemove}
    />
  );
};

export default FetchData;
