import { useTranslation, } from 'react-i18next';
import { ChangeEventHandler, useState, useCallback, useRef, memo, } from 'react';
import TextField from '@mui/material/TextField';
import { useDebounce, } from 'Tools/useDebounce';
import { useTableContext, } from '../hooks/useTableContext';

interface Props {
  name: string,
  value: string,
  onChange: (name: string, value: string) => void,
  placeholder?: string,
}

const TableFilterTextNoContext = memo(({
  name,
  value,
  onChange,
  placeholder,
}: Props): JSX.Element => {
  const { t, } = useTranslation();
  const [ filterValue, setFilterValue, ] = useState<string>(value);
  const refFilterValue = useRef<string>(value);

  const cbTimer = useCallback(
    () => onChange(name, refFilterValue.current.trim()),
    [ name, refFilterValue, onChange, ],
  );

  const { startTimeout, endTimeout, } = useDebounce(cbTimer, 700);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilterValue(e.target.value);
    refFilterValue.current = e.target.value;
    startTimeout();
  };
  const handleBlur = () => {
    endTimeout();
    onChange(name, refFilterValue.current.trim());
  };

  return (
    <TextField
      value={filterValue}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder || t('common:tableFilters.placeholder')}
      variant="outlined"
      size="small"
      fullWidth
    />
  );
});

const TableFilterText = ({
  name,
  ...rest
}: Omit<Props, 'value' | 'onChange'>): JSX.Element => {
  const {
    filter,
    onChangeFilter,
  } = useTableContext(); 

  const value = filter?.[name] || '';

  return (
    <TableFilterTextNoContext
      {...rest}
      name={name}
      value={value}
      onChange={onChangeFilter}
    />
  );
};

export default TableFilterText;
