import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import { styled, darken, } from '@mui/material/styles';
import { usePopupState, bindToggle, bindPopper, } from 'material-ui-popup-state/hooks';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AnyObject, } from 'Utils/types';
import FormHelperTextClassic from './components/FormHelperTextClassic';
import InputLabelClassic from './components/InputLabelClassic';

const StyledButton = styled(
  Button,
  { shouldForwardProp: (prop) => (prop !== 'valueColor'), },
)<{ valueColor?: string, }>(({ theme, valueColor, }) => ({
  ...(valueColor && ({
    backgroundColor: valueColor,
    color: theme.palette.getContrastText(valueColor),
    '&:hover':{
      backgroundColor: darken(valueColor, 0.1),
    },
  })),
}));

export interface InputButtonSelectProps <T extends AnyObject,>{
  value: T | null,
  onChange: (value: T | null) => void,
  options: T[],
  getOptionLabel: (o: T) => ReactNode,
  getOptionKey: (o: T) => string | number, 
  getOptionColor?: (o: T) => string,
  error?: boolean,
  label?: ReactNode,
  helperText?: ReactNode,
  required?: boolean,
};

const InputButtonSelect = <T extends AnyObject,>({
  value,
  onChange,
  options,
  getOptionLabel,
  getOptionKey,
  getOptionColor,
  error,
  label,
  helperText,
  required,
}: InputButtonSelectProps<T>): JSX.Element => {
  const { t, } = useTranslation();
  const popupState = usePopupState({ variant: 'popper', popupId: 'buttonInput', });
  const handleClickAway = () => popupState.close();
  const handleMenuItemClick = (option: T) => () => {
    onChange(option);
    handleClickAway();
  };

  return (
    <div>

      {label && (
        <InputLabelClassic
          shrink
          color="primary"
          required={required}
        >
          {label}
        </InputLabelClassic>
      )}

      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <div style={{ display: 'inline-block', }}>
          <StyledButton
            variant="contained"
            size="large"
            color="inherit"
            valueColor={(value && getOptionColor) ? getOptionColor(value) : undefined}
            endIcon={<KeyboardArrowDownIcon />}
            {...bindToggle(popupState)}
          >
            {value
              ? getOptionLabel(value)
              : t('common:select.placeholder')
            }
          </StyledButton>

          <Popper
            {...bindPopper(popupState)}
            transition
            placement="bottom-start"
          >
            {({ TransitionProps, }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <MenuList>
                    {options.map((o) => (
                      <MenuItem
                        key={getOptionKey(o)}
                        onClick={handleMenuItemClick(o)}
                      >
                        {getOptionLabel(o)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Paper>
              </Fade>
            )}
          </Popper>
        </div>
      </ClickAwayListener>

      {(helperText) && (
        <FormHelperTextClassic
          error={error}
        >
          {helperText}
        </FormHelperTextClassic>
      )}

    </div>
  );
};

export default InputButtonSelect;
