import { AuthUserFragment, useRegisterCustomerMutation, } from 'Apollo/graphql';
import { useEffect, useRef, } from 'react';
import { useAuthUser, } from 'Tools/auth';
import shallow from 'zustand/shallow';
import { CreateReplacementStore, useCreateReplacementStore, } from '../createReplacementStore';
import { mapRegisterVariables, } from '../PhaseCustomerRegistration/utils';
import View from './View';

const selectStoreData = (s: CreateReplacementStore) => ({
  customerRegistrationValues: s.customerRegistrationValues,
  completeCustomerRegistrationCompletePhase: s.completeCustomerRegistrationCompletePhase,
  registerCustomer: s.registerCustomer,
  shouldCreateCustomer: s.shouldCreateCustomer,
  replacementEmail: s.replacementEmail,
});

interface Props {
  onClose: () => void,
};

const PhaseCustomerRegistrationComplete = ({
  onClose,
}: Props): JSX.Element => {
  const {
    customerRegistrationValues,
    completeCustomerRegistrationCompletePhase,
    registerCustomer,
    shouldCreateCustomer,
    replacementEmail,
  } = useCreateReplacementStore(selectStoreData, shallow);
  const isCalled = useRef<boolean>(false);
  const authUser = useAuthUser();

  const [ registerCustomerMutation, registerCustomerResult, ] = useRegisterCustomerMutation({
    onCompleted: (result) => {
      registerCustomer({
        id: result.registerCustomer.id,
        email: result.registerCustomer.email,
        firstName: result.registerCustomer.firstName,
        lastName: result.registerCustomer.lastName,
        phoneNumber: result.registerCustomer.phoneNumber,
        phonePrefix: result.registerCustomer.phoneNumberWithPrefix,
        dateOfBirth: result.registerCustomer.dateOfBirth ? new Date(result.registerCustomer.dateOfBirth) : null,
      });
    },
  });

  useEffect(
    () => {
      if (!isCalled.current) {
        isCalled.current = true;
      }
      if (shouldCreateCustomer) {
        registerCustomerMutation({
          variables: mapRegisterVariables(
            replacementEmail,
            customerRegistrationValues,
            authUser as AuthUserFragment,
          ),
        });
      }    
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <View
      registerCustomerResult={customerRegistrationValues}
      onClose={onClose}
      onVerify={completeCustomerRegistrationCompletePhase}
    />
  );
};

export default PhaseCustomerRegistrationComplete;

