import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const MyProfileColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#myProfile-colored)">
      <path
        d="M11.587 22.705H3.494c-.63 0-.76-.14-.726-.767.175-3.32 1.734-5.814 4.59-7.458a8.215 8.215 0 0 1 5.495-.983c3.273.517 5.543 2.359 6.888 5.362.45 1.026.68 2.135.672 3.256 0 .433-.169.592-.663.594h-8.163v-.004Z"
        fill="#D47E5F"
      />
      <path
        d="M11.595 12.465a5.58 5.58 0 1 1-.025-11.161 5.58 5.58 0 0 1 .025 11.161ZM8.309 8.61c.708 1.65 2.55 2.312 4.274 1.856a3.764 3.764 0 0 0 2.714-3.713c0-.313-.124-.369-.413-.313a8.928 8.928 0 0 1-4.966-.392c-.412-.159-.804-.357-1.198-.555-.44-.224-.545-.206-.672.26a4.77 4.77 0 0 0 .261 2.857Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="myProfile-colored">
        <path fill="#fff" transform="translate(2.65 1)" d="M0 0h18.7v22H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default MyProfileColoredIcon;
