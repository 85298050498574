import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const TasksColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12.91 17.057a5.954 5.954 0 1 1-5.926-5.967 5.973 5.973 0 0 1 5.925 5.967Zm-6.636.85v.171c0 .485.26.79.663.798.379.008.689-.286.701-.733.009-.276.18-.342.363-.434 1.646-.838 1.845-2.977.36-4.078-.763-.568-1.613-.626-2.462-.204-.849.422-1.294 1.137-1.33 2.087-.02.508.387.826.853.701.38-.106.49-.397.524-.756a.981.981 0 0 1 .984-.913 1.002 1.002 0 0 1 1.042.855 1.039 1.039 0 0 1-.69 1.13c-.78.256-1.001.56-1.008 1.376Zm.67 3.721a.936.936 0 0 0 .92-.916.9.9 0 0 0-.89-.901.884.884 0 0 0-.932.887.923.923 0 0 0 .907.929l-.005.001Z"
      fill="#D47E5F"
    />
    <path
      d="M22.991 11.501c0 2.085-.023 4.169.009 6.253.016 1.08-.707 2.078-2.052 2.057-2.222-.032-4.445-.007-6.666-.008-.485 0-.527-.068-.389-.522.097-.317.188-.637.252-.965.056-.276.192-.37.473-.367 1.855.01 3.71 0 5.566 0 .965 0 .974 0 .974-.98V5.528a1.032 1.032 0 0 0-.344-.812 54.971 54.971 0 0 1-1.574-1.584.938.938 0 0 0-.729-.304c-3.172.007-6.345.007-9.517 0-.537 0-.67.138-.67.674v5.804c0 .492-.055.524-.543.462a7.952 7.952 0 0 0-.964-.04c-.23 0-.332-.088-.332-.325 0-2.205-.013-4.421.012-6.63.012-1.034.813-1.753 1.898-1.768.927-.013 1.856 0 2.783 0h7.523a2.185 2.185 0 0 1 1.69.689 68.339 68.339 0 0 0 1.892 1.897 2.187 2.187 0 0 1 .703 1.684c-.004 2.081.005 4.154.005 6.225Z"
      fill="#000"
    />
    <path
      d="M15.19 8.809c-1.064 0-2.13.007-3.193 0a.919.919 0 0 1-.81-1.35.899.899 0 0 1 .45-.405 1.24 1.24 0 0 1 .467-.083c2.061-.006 4.121-.006 6.181 0 .615 0 1.034.378 1.034.906 0 .562-.404.935-1.038.939H15.19v-.007ZM14.303 11.086h1.715c.611.006 1.019.388 1.01.928-.008.539-.413.91-1.023.915-.906 0-1.81.012-2.714-.008a.726.726 0 0 1-.456-.215c-.314-.331-.583-.705-.896-1.038-.184-.198-.137-.337.061-.432.172-.086.36-.136.551-.145.583-.016 1.165-.005 1.752-.005Z"
      fill="#000"
    />
  </SvgIcon>
);

export default TasksColoredIcon;
