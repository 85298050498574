import { PromotionFragment, } from 'Apollo/graphql';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { PromotionsStore, usePromotionsStore, } from 'Modules/PromotionsAdmin/store/PromotionsStore';
import Logic from './Logic';

const selectModalData = (s: PromotionsStore) => s.modalRemovePromotion;
const selectClose = (s: PromotionsStore) => s.closeModalRemovePromotion;

const ModalRemovePromotion = (): JSX.Element => {
  const { isOpen, promotion, } = usePromotionsStore(selectModalData);
  const onClose = usePromotionsStore(selectClose);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!promotion}
    >
      <Logic
        promotion={promotion as PromotionFragment}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalRemovePromotion;
