import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useTranslation, } from 'react-i18next';
import { useMemo, } from 'react';
import { methodOfVerificationQuery, } from './queries';

const MethodOfVerification = ({
  showAddButton,

  filtration,
  chartFilter,
  timeDimensions,
  showSlug = true,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');
  const query = useMemo(
    () =>
      methodOfVerificationQuery({
        timeDimensions,
        filters: chartFilter,
      }),
    [chartFilter, timeDimensions,],
  );
  return (
    <Base
      headerText={t('dashboards.registrations.methodOfVerification')}
      slug={showSlug ? 'method-of-verification' : undefined}
      showAddButton={showAddButton}
      filtration={filtration}
      dashboardLayoutItemName={
        DashboardLayoutItemName.RegistrationsMethodOfVerification
      }
    >
      <ChartRenderer
        query={query}
        pivotConfig={{
          x: ['customer.verificationMethod',],
          y: ['measures',],
          fillMissingDates: true,
          joinDateRange: false,
        }}
        type={ChartType.bar}
      />
    </Base>
  );
};

export default MethodOfVerification;
