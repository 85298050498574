import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type YesNoOption = {
  id: 'yes' | 'no',
  value: boolean,
  labelKey: string,
};

export const YES_NO_OPTIONS: YesNoOption[] = [
  {
    id: 'yes',
    value: true,
    labelKey: 'common:options.yesNo.yes',
  },
  {
    id: 'no',
    value: false,
    labelKey: 'common:options.yesNo.no',
  },
];

type GetOptionLabel = (o: YesNoOption) => string;
type GetOptionKey = (o: YesNoOption) => string;

type UseOptionsYesNo = () => {
  options: YesNoOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<YesNoOption>, 
};

export const useOptionsYesNo: UseOptionsYesNo = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: YES_NO_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
