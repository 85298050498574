import { useCallback, } from 'react';
import { useFormContext, useController, } from 'react-hook-form';
import InputPhoneNumber, { InputPhoneNumberProps, } from 'Components/Inputs/InputPhoneNumber';
import { PhonePrefixOption, } from 'Utils/options/useOptionsPhonePrefix';
import { useFormInputStatus, } from '../utils/useFormInputStatus';
import { InputFormErrorMessage, } from '../types';

interface Props extends Omit<InputPhoneNumberProps, 'valuePrefix' | 'onChangePrefix' | 'valuePhone' | 'onChangePhone' | 'name'> {
  namePhone: string,
  namePrefix: string,
}

const FormInputPhoneNumber = ({
  namePhone,
  namePrefix,
  id,
  helperText,
  ...inputProps
}: Props): JSX.Element => {
  const { control, } = useFormContext();

  const {
    field: { value: valueFieldPhone, onBlur: onBlurFieldPhone, onChange: onChangeFieldPhone, },
    fieldState: { error: errorFieldPhone, },
  } = useController({
    name: namePhone,
    control,
  });

  const {
    field: { value: valueFieldPrefix, onBlur: onBlurFieldPrefix, onChange: onChangeFieldPrefix, },
    fieldState: { error: errorFieldPrefix, },
  } = useController({
    name: namePrefix,
    control,
  });

  const inputStatusPhone = useFormInputStatus(
    errorFieldPhone?.message as InputFormErrorMessage | undefined,
    helperText,
  );
  const inputStatusPrefix = useFormInputStatus(
    errorFieldPrefix?.message as InputFormErrorMessage | undefined,
  );

  const handleChangePhone = useCallback(
    (newValue: string) => {
      onChangeFieldPhone(newValue);
    },
    [ onChangeFieldPhone, ],
  );

  const handleChangePrefix = useCallback(
    (newValue: PhonePrefixOption | null) => {
      onChangeFieldPrefix(newValue);
      onBlurFieldPrefix();
    },
    [ onChangeFieldPrefix, onBlurFieldPrefix, ],
  );

  return (
    <InputPhoneNumber
      {...inputProps}
      valuePhone={valueFieldPhone}
      valuePrefix={valueFieldPrefix}
      onChangePhone={handleChangePhone}
      onChangePrefix={handleChangePrefix}
      onBlurPhone={onBlurFieldPhone}
      name={id || namePhone}
      id={id}
      errorPhone={inputStatusPhone.isError}
      errorPrefix={inputStatusPrefix.isError}
      helperText={inputStatusPrefix.text || inputStatusPhone.text}
    />
  );
};

export default FormInputPhoneNumber;
