import Grid from '@mui/material/Grid';
import { AssessmentConfig, } from 'Modules/TasksSeller/store/fillTaskStore';
import { SellerTaskAssessmentBlockFragment, } from 'Apollo/graphql';
import { useMemoArrayId, } from 'Tools/useMemoArrayId';
import Node from './Node';

interface Props {
  blocks: SellerTaskAssessmentBlockFragment[],
  assessmentConfig: AssessmentConfig,
};

const FormInputs = ({
  blocks,
  assessmentConfig,
}: Props): JSX.Element => {
  const fields = useMemoArrayId(blocks);

  return (
    <Grid container spacing={4}>
      {fields.map((field, index) => (
        <Node
          key={field.id}
          index={index}
          block={field.payload}
          assessmentConfig={assessmentConfig}
        />
      ))}
    </Grid>
  );
};

export default FormInputs;
