import { PointsTransactionFragment, usePointsTransactionQuery, } from 'Apollo/graphql';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import View from './View';

interface Props {
  pointsTransaction?: PointsTransactionFragment | null;
  onRemove: (pointsTransaction: PointsTransactionFragment) => void;
  onClose: () => void;
}

const FetchData = ({ pointsTransaction, onClose, onRemove, }: Props): JSX.Element => {
  const preservedPointsTransactions = usePreserveValue(pointsTransaction);

  const { loading, error, data, } = usePointsTransactionQuery({
    variables: { id: preservedPointsTransactions.id, },
    fetchPolicy: 'network-only',
  });

  if (loading) return <ModalDrawerBodyLoading onClose={onClose} />;
  if (error || !data?.pointsTransaction) return <ModalDrawerBodyError onClose={onClose} error={error} />;
  return <View pointsTransaction={data.pointsTransaction} onRemove={onRemove} onClose={onClose} />;
};

export default FetchData;
