import { Query, } from '@cubejs-client/core';
import { zodResolver, } from '@hookform/resolvers/zod';
import { Grid, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import ChartDetailLayout from 'Components/Dashboard/ChartDetailLayout';
import { topReasonsForReplacementQuery, } from 'Components/Dashboard/Replacements/queries';
import TopReasonsForReplacement from 'Components/Dashboard/Replacements/TopReasonsForReplacement';
import { useMemo, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { OptionTypes, ChartType, } from 'Utils/Charts/types';
import useDashboardFilter from 'Utils/Charts/UseDashboardFilter';
import { TimeAndProductCategoryAndProductValidationSchema, } from 'Utils/Charts/ValidationSchemas';
import { z, } from 'zod';

type ValidationSchema = z.infer<
  typeof TimeAndProductCategoryAndProductValidationSchema
>;

const TopReasonsForReplacementsPage = () => {
  const { t, } = useTranslation('dashboards');

  const filtration = [
    OptionTypes.category,
    OptionTypes.products,
    OptionTypes.time,
  ];
  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(TimeAndProductCategoryAndProductValidationSchema),
    defaultValues: {
      date: 'This year',
      categoryId: null,
      productId: null,
    },
  });
  const watch = methods.watch();

  const { filtersArray, dateRange,} = useDashboardFilter(
    {
      categoryId: {
        operator: 'equals',
        value: watch.categoryId,
        filterMember: 'replacement.replaced_product_category_id',
      },
      productId: {
        operator: 'equals',
        value: watch.productId,
        filterMember: 'replacement.replaced_product_id',
      },
    },
    watch.date,
  );

  const query = useMemo(
    () =>
      topReasonsForReplacementQuery({
        timeDimensions: [
          {
            dateRange,
          },
        ],
        showNullValues: true,
        filters: filtersArray,
      }),
    [dateRange, filtersArray,],
  );
  return (
    <AppContainer>
      <ChartDetailLayout pageTitle={t('common:dashboardSidebar.replacements')}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <TopReasonsForReplacement
                showSlug={false}
                filtration={filtration}
                chartFilter={filtersArray}
                timeDimensions={query.timeDimensions}
              />
            </FormProvider>
          </Grid>
          <Grid item xs={12}>
            <ChartRenderer
              query={query}
              type={ChartType.table}
              pivotConfig={{
                x: ['replacement.createdat',],
                y: ['measures',],
                fillMissingDates: true,
                joinDateRange: false,
              }}
            />
          </Grid>
        </Grid>
      </ChartDetailLayout>
    </AppContainer>
  );
};

export default TopReasonsForReplacementsPage;
