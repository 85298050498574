import { useTranslation, } from 'react-i18next';
import Grid from '@mui/material/Grid';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { PhotoAssessmentBlockFragment, } from 'Apollo/graphql';
import { BlockProps, } from '../types';
import BlockLayout from '../BlockLayout';

const BlockPhoto = ({
  index,
  block,
  showAssessmentCorrectAnswers,
}: BlockProps<PhotoAssessmentBlockFragment>): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');

  return (
    <BlockLayout
      index={index}
      type={block.type}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('assessment.labelDescription')}
          >
            <div style={{ whiteSpace: 'pre-wrap', }}>
              {block.description}
            </div>
          </NotEditableTextClassic>
        </Grid>
      </Grid>
    </BlockLayout>
  );
};

export default BlockPhoto;
