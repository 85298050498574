import { AssortmentCategoryWithAssortmentOptionFragment, } from 'Apollo/graphql';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementSeller/store/ReplacementStore';
import Logic from './Logic';

const selectCategory = (s: ReplacementStore) => s.replacement.categoryItems;

const FormReplacement = (): JSX.Element => {
  const category = useReplacementStore(selectCategory);

  return (
    <Logic
      category={category as AssortmentCategoryWithAssortmentOptionFragment}
    />
  );
};

export default FormReplacement;
