import shallow from 'zustand/shallow';
import { useTranslation, } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementSeller/store/ReplacementStore';

const selectStoreData = (s: ReplacementStore) => ({
  replacement: s.replacement,
  finishReplacement: s.finishReplacement,
});

const ClosedReplacement = () => {
  const { t, } = useTranslation('replacementSeller');

  const {
    replacement,
    finishReplacement,
  } = useReplacementStore(selectStoreData, shallow);

  const handleClick = () => finishReplacement();

  return (
    <>
    <Typography
      variant="h1"
      sx={{ mb: 1.5, }}
    >
      {t('phaseClosed.title')}
    </Typography>
    <Typography
      variant="body1"
      sx={{ mb: 3, }}
    >
      {t('phaseClosed.info', { replacementId: replacement.id, })}
    </Typography>

    <Grid container spacing={2} sx={{ marginTop: 3,}}>
        <Grid item xs={12} lg="auto">
          <Button
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            onClick={handleClick}
          >
            {t('buttons.btnDone')}
          </Button>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            onClick={handleClick}
          >
            {t('buttons.btnSearch')}
          </Button>
        </Grid>
      </Grid>
  </>
  );
};

export default ClosedReplacement;
