import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TaskCompletionSortableFields, } from 'Apollo/graphql';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import TableFilterInt from 'Components/Table/filters/TableFilterInt';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import TableCellSortable from 'Components/Table/TableCellSortable';
import { useTranslation, } from 'react-i18next';
import { renderOptionById, } from 'Utils/helpers';
import { TaskCompletionStatusOption, useOptionsTaskCompletionStatus, } from 'Utils/options/useOptionsTaskCompletionStatus';

const COL_WIDTHS = [
  { width: '120px', },
  { width: '160px', },
  { width: '120px', },
  { width: '120px', },
  { width: '140px', },
  { width: '120px', },
  { width: '120px', },
  { width: '45px', },
];

const TasksTableHeader = (): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');

  const optionsTaskCompletionStatus = useOptionsTaskCompletionStatus();

  return (
    <TableHead>
      <TableRow>
        <TableCellSortable style={COL_WIDTHS[0]} name={TaskCompletionSortableFields.TaskId}>
          {t('obligatoryTasks.table.taskId')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[1]}>{t('obligatoryTasks.table.name')}</TableCell>
        <TableCell style={COL_WIDTHS[2]}>{t('obligatoryTasks.table.description')}</TableCell>
        <TableCellSortable style={COL_WIDTHS[3]} name={TaskCompletionSortableFields.AuthorId}>
          {t('obligatoryTasks.table.username')}
        </TableCellSortable>
        <TableCellSortable style={COL_WIDTHS[4]} name={TaskCompletionSortableFields.Status}>
          {t('obligatoryTasks.table.status')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[5]}>{t('obligatoryTasks.table.pointsForCompletion')}</TableCell>
        <TableCellSortable style={COL_WIDTHS[6]} name={TaskCompletionSortableFields.UpdatedAt}>
          {t('obligatoryTasks.table.updatedAt')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[7]} />
      </TableRow>
      <TableRow>
        <TableCell>
          <TableFilterText name="taskId" />
        </TableCell>
        <TableCell>
          <TableFilterText name="name" />
        </TableCell>
        <TableCell>
          <TableFilterText name="description" />
        </TableCell>
        <TableCell>
          <TableFilterText name="author" />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<TaskCompletionStatusOption>
            name="status"
            getOptionLabel={optionsTaskCompletionStatus.getOptionLabel}
            isOptionEqualToValue={optionsTaskCompletionStatus.isOptionEqualToValue}
            renderOption={renderOptionById(optionsTaskCompletionStatus.getOptionLabel)}
            options={optionsTaskCompletionStatus.options}
          />
        </TableCell>
        <TableCell>
          <TableFilterInt name="pointsForCompletion" />
        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default TasksTableHeader;
