import create from 'zustand';
import { ChangeTableFilter, ChangeTableSort, ChangeTableData, } from 'Utils/types';
import { ChainFragment, GeneralTaskFragment, OrderBy, TaskSortableFields, } from 'Apollo/graphql';
import { ReplacementStatusOption, } from 'Utils/options/useOptionsReplacementStatus';
import { IsNowAvailableOption, } from 'Utils/options/useOptionsIsNowAvailable';
import { TaskAssessmentMethodOption, } from 'Utils/options/useOptionTaskAssessmentMethod';
import { TaskRenewalOption, } from 'Utils/options/useOptionTaskRenewal';

export type TableTasksFilter = {
  id: string;
  type: ReplacementStatusOption | null;
  name: string;
  pointsForCompletion: number | null;
  pointsLimit: number | null;
  typeOfCompletion: string;
  attemptLimit: number | null;
  chainId: ChainFragment | null;
  author: string;
  isNowAvailable: IsNowAvailableOption | null;
  assessmentMethod: TaskAssessmentMethodOption | null
  renewal: TaskRenewalOption | null
};

export type TaskStore = {
  modalDeleteTask: {
    isOpen: boolean;
    task: GeneralTaskFragment | null;
  };
  modalExportTask: {
    isOpen: boolean;
    task: GeneralTaskFragment | null;
  };
  tableTasks: {
    data: {
      sortField: TaskSortableFields | null;
      sortOrder: OrderBy | null;
      limit: number;
      offset: number;
    };
    filter: TableTasksFilter;
  };
  openModalDeleteTask: (task: GeneralTaskFragment) => void;
  closeModalDeleteTask: () => void;
  openModalExportTask: (task: GeneralTaskFragment) => void;
  closeModalExportTask: () => void;
  changeTableData: ChangeTableData;
  changeTableFilter: ChangeTableFilter;
  changeTableSort: ChangeTableSort;
};
export const useTaskStore = create<TaskStore>((set) => ({
  modalDeleteTask: {
    isOpen: false,
    task: null,
  },
  modalExportTask: {
    isOpen: false,
    task: null,
  },
  tableTasks: {
    data: {
      sortField: null,
      sortOrder: null,
      limit: 10,
      offset: 0,
    },
    filter: {
      id: '',
      type: null,
      name: '',
      pointsForCompletion: null,
      pointsLimit: null,
      typeOfCompletion: '',
      attemptLimit: null,
      chainId: null,
      author: '',
      isNowAvailable: null,
      assessmentMethod: null,
      renewal: null,
    },
  },
  /**
   * Remove
   */
  openModalDeleteTask: (task) =>
    set((state) => ({
      modalDeleteTask: {
        isOpen: true,
        task,
      },
    })),
  closeModalDeleteTask: () =>
    set((state) => ({
      modalDeleteTask: {
        ...state.modalDeleteTask,
        isOpen: false,
      },
    })),
  /**
   * Export
   */
  openModalExportTask: (task) =>
    set((state) => ({
      modalExportTask: {
        isOpen: true,
        task,
      },
    })),
  closeModalExportTask: () =>
    set((state) => ({
      modalExportTask: {
        ...state.modalDeleteTask,
        isOpen: false,
      },
    })),
  /**
   * Task table
   */
  changeTableData: (name, value) =>
    set((state) => ({
      tableTasks: {
        ...state.tableTasks,
        data: {
          ...state.tableTasks.data,
          [name]: value,
        },
      },
    })),
  changeTableFilter: (name, value) =>
    set((state) => ({
      tableTasks: {
        ...state.tableTasks,
        data: {
          ...state.tableTasks.data,
          offset: 0,
        },
        filter: {
          ...state.tableTasks.filter,
          [name]: value,
        },
      },
    })),
  changeTableSort: (sortField, sortOrder) =>
    set((state) => ({
      tableTasks: {
        ...state.tableTasks,
        data: {
          ...state.tableTasks.data,
          sortField: sortField as TaskSortableFields,
          sortOrder,
        },
      },
    })),
}));
