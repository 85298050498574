import Grid from '@mui/material/Grid';
import { FormInputPhoneNumber, FormInputText, } from 'Components/Form';
import { useTranslation, } from 'react-i18next';


const EditProfileForm = (): JSX.Element => {
  const { t, } = useTranslation('users');
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormInputText
          name="firstName"
          label={t('user.labelFirstName')}
          required
          autoComplete="new-firstName"
          id="new-firstName"
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="lastName"
          label={t('user.labelLastName')}
          required
          autoComplete="new-lastName"
          id="new-lastName"
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="email"
          label={t('user.labelEmail')}
          required
          autoComplete="new-email"
          id="new-email"
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputPhoneNumber
          namePrefix="phonePrefix"
          namePhone="phoneNumber"
          label={t('user.labelPhone')}
          required
          id="nope"
        />
      </Grid>
    </Grid>
  );
};

export default EditProfileForm;
