/* eslint-disable consistent-return */
import { useEffect, useRef, useState, } from 'react';

export function useThrottleValue<T,>(
  value: T,
  interval = 700,
): T {
  const [ throttledValue, setThrottledValue, ] = useState<T>(value);
  const refLastExecuted = useRef<number>(Date.now());
  const refLastValue = useRef<T>(value);

  useEffect(
    () => {
      const now = Date.now();
      refLastValue.current = value;

      if (now >= refLastExecuted.current + interval) {
        refLastExecuted.current = now;
        setThrottledValue(value);
      }
      else {
        const timerId = setTimeout(
          () => {
            refLastExecuted.current = Date.now();
            setThrottledValue(refLastValue.current);
          },
          refLastExecuted.current + interval - now,
        );
        return () => clearTimeout(timerId);
      }
    },
    [ value, interval, ],
  );

  return throttledValue;
}