import { KeyboardEvent, } from 'react';
import { Editor, } from 'slate';
import isHotkey from 'is-hotkey';
import { toggleMark, } from './utils';
import { CustomText, Hotkeys, } from './types';

export const HOTKEYS: Hotkeys = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

export const onEditorKeyDown = (editor: Editor, event: KeyboardEvent<HTMLDivElement>) => {
  const hotKeys = Object.keys(HOTKEYS);

  for (let i = 0; i < hotKeys.length; i++) {
    const hotkey = hotKeys[i];
    
    if (isHotkey(hotkey, event)) {
      event.preventDefault();
      const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS] as keyof Omit<CustomText, 'text'>;
      toggleMark(editor, mark);
    }
  }
};
