import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const ArrowRightIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    style={{ width: "unset", }}
  >
    <path
      d="M8.331 3.96c.35-.03.61.13.861.358 2.35 2.136 4.704 4.268 7.061 6.397.83.748.847 1.774.019 2.53-2.34 2.136-4.677 4.275-7.013 6.415-.461.422-1.057.504-1.535.208a1.241 1.241 0 0 1-.497-.618 1.276 1.276 0 0 1-.028-.8c.064-.196.178-.372.33-.51 2.078-1.883 4.162-3.761 6.25-5.634.294-.266.294-.313 0-.578L7.543 6.114a1.217 1.217 0 0 1-.356-.517 1.244 1.244 0 0 1 .207-1.203c.135-.164.31-.288.507-.36.14-.04.284-.065.43-.074z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ArrowRightIcon;
