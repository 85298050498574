import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { ContentBlockTypeEnum, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type ContentBlockOption = {
  id: ContentBlockTypeEnum,
  labelKey: string,
};

export const CONTENT_BLOCK_OPTIONS: ContentBlockOption[] = [
  {
    id: ContentBlockTypeEnum.Text,
    labelKey: 'tasksAdmin:options.contentBlock.text',
  },
  {
    id: ContentBlockTypeEnum.Photo,
    labelKey: 'tasksAdmin:options.contentBlock.photo',
  },
  {
    id: ContentBlockTypeEnum.Video,
    labelKey: 'tasksAdmin:options.contentBlock.video',
  },
];

type GetOptionLabel = (o: ContentBlockOption) => string;
type GetOptionKey = (o: ContentBlockOption) => string;

type UseOptionsContentBlock = () => {
  options: ContentBlockOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<ContentBlockOption>, 
};

export const useOptionsContentBlock: UseOptionsContentBlock = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey, o.id),
    [ t, ],
  );

  return {
    options: CONTENT_BLOCK_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
