import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { useVoucherStore, VoucherStore, } from 'Modules/Vouchers/store/VoucherStore';
import { useTranslation, } from 'react-i18next';
import { useAvailableVoucherTypesQuery, } from 'Apollo/graphql';
import VoucherTypeDirector from './VoucherTypeDirector';

const selectModalData = (s: VoucherStore) => s.modalCreateVoucher;
const selectClose = (s: VoucherStore) => s.closeModalCreateVoucher;

const ModalCreateVoucher = (): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const { isOpen, } = useVoucherStore(selectModalData);
  const onClose = useVoucherStore(selectClose);
  const data = useAvailableVoucherTypesQuery();
  return (
    <ModalDrawer onClose={onClose} open={isOpen}>
      <ModalDrawerTitle onClose={onClose}>{t('createVoucherModal.title')}</ModalDrawerTitle>
      <VoucherTypeDirector availableTypes={data} onClose={onClose} />
    </ModalDrawer>
  );
};

export default ModalCreateVoucher;
