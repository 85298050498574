import { useState, useCallback, } from 'react';
import { useApolloClient, ApolloError, MutationOptions, } from '@apollo/client';
import {
  LoginMutation,
  MutationLoginArgs,
  LoginDocument,
  CurrentUserQuery,
  CurrentUserDocument,
  Claim,
} from '../../../Apollo/graphql';
import { setAccessToken, } from '../store/authStore';
import { setOfflineRefreshToken, } from '../offlineRefreshTokenStore';

type UseAuthLogin = [
  (options: Omit<MutationOptions<LoginMutation, MutationLoginArgs>, 'mutation' | 'fetchPolicy'>
  ) => Promise<void>,
  {
    loading: boolean,
    error: ApolloError | Error | null
  },
];

export const useAuthLogin = (): UseAuthLogin => {
  const [ loading, setLoading, ] = useState<boolean>(false);
  const [ error, setError, ] = useState<ApolloError | Error | null>(null);
  const client = useApolloClient();


  const login = useCallback(
    async (options = {}) => {
      try {
        setLoading(true);
        setError(null);

        const responseLogin = await client.mutate<LoginMutation, MutationLoginArgs>({
          ...options,
          mutation: LoginDocument,
          fetchPolicy: 'no-cache',
        });

        const {
          user,
          token,
        } = responseLogin.data?.login || {};

        if (
          user &&
          token?.accessToken &&
          ([Claim.AdminApp, Claim.SellerApp, Claim.DashboardsApp,].some(claim => user.claims.includes(claim)))
        ) {

          setAccessToken(token.accessToken);
          setOfflineRefreshToken(token.logoutRefreshToken);

          client.cache.writeQuery<CurrentUserQuery>({
            query: CurrentUserDocument,
            data: {
              currentUser: user,
            },
          });
          setLoading(false);
        }
        else {
          throw new Error('invalid response: no user or token or no permission for admin / seller part of app');
        }


      } catch (err) {
        setLoading(false);
        setAccessToken(null);
        setOfflineRefreshToken(null);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setError(err as any);
        // TOTO - remove current user from cache
        // apolloClient.cache.evict({
        //   id: 'ROOT_QUERY',
        //   fieldName: 'currentUser',
        // });
        // apolloClient.cache.gc();
      }
    }, [ client, ]
  );

  return [ login, { loading, error, }, ];
};
