import shallow from 'zustand/shallow';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AppContainer from 'Components/AppContainer';
import PlusIcon from 'Utils/svg/PlusIcon';
import { UserStore, useUserStore, } from 'Modules/Users/store/UserStore';
import { useAuthUser, } from 'Tools/auth';
import { Claim, } from 'Apollo/graphql';
import Users from './Users';

const selectStoreData = (s: UserStore) => ({
  openCreateModal: s.openModalCreateUser,
  openImportModal: s.openModalImportUsers,
  openExportModal: s.openModalExportUsers,
  openModalReactivateUser: s.openModalReactivateUser,
});

const UsersTable = (): JSX.Element => {  
  const { t, } = useTranslation('users');
  const { openCreateModal, openImportModal, openExportModal,openModalReactivateUser, } = useUserStore(selectStoreData, shallow);

  const user = useAuthUser();

  return (
    <AppContainer>

      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <Typography
            variant="h1"
          >
            {t('users.title')}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {user?.claimsSet.has(Claim.ImportUser) && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  onClick={openImportModal}
                >
                  {t('users.btnImport')}
                </Button>
              </Grid>
            )}
            {user?.claimsSet.has(Claim.AdminExportData) && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  onClick={openExportModal}
                >
                  {t('users.btnExport')}
                </Button>
              </Grid>
            )}
            {user?.claimsSet.has(Claim.ManageUser) && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  onClick={openCreateModal}
                >
                  {t('users.bntAdd')}
                </Button>
              </Grid>
            )}
            {user?.claimsSet.has(Claim.ReactivateUser) && (
              <Grid item>
                <Button
                  variant="contained"
                  color="warning"
                  size="large"
                  onClick={openModalReactivateUser}
                >
                  {t('reactivateUserModal.title')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      
      <Users />

    </AppContainer>
  );
};

export default UsersTable;
