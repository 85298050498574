import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useSnackbar, } from 'notistack';
import { VoucherTableDocument, useCreateVoucherMutation, } from 'Apollo/graphql';
import { initVoucherFormValues, mapFormToVariables, voucherFormValidationSchema, } from 'Modules/Vouchers/utils/voucherForm';
import View from './View';

interface Props {
  onClose: () => void,
};

const CreateStandart = ({
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const methods = useForm({
    defaultValues: initVoucherFormValues,
    resolver: yupResolver(voucherFormValidationSchema),
    mode: 'onTouched',
  });
  const [ createMutation, createMutationResult, ] = useCreateVoucherMutation({
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('createVoucherModal.notifCreatedTitle') as string,
        message: t('createVoucherModal.notifCreatedMessage', { id: response.createVoucher.id, }),
        variant: 'common',
      });
      onClose();
    },
    onError: () => {
      // TODO: merge form errors
    },
    refetchQueries: [{ query: VoucherTableDocument, },],
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await createMutation({
      variables: {
        voucherInput: mapFormToVariables(values),
      },
    });    
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View
          createMutationResult={createMutationResult}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default CreateStandart;
