import {
  AssortmentCategoryOptionFragment,
  AssortmentOptionFragment,
  CustomerFragment,
  CustomerVerificationMethod,
  CustomerVerificationStartResult,
  PreviouslyOwnedDevicesOptionFragment,
  PurchasedProductsForGqlDbOptionFragment,
  ReplacementFragment,
} from 'Apollo/graphql';
import { InputProductCountMultipleValueOption, } from 'Components/Inputs/InputProductCountMultiple/types';
import { GenderTitleOption, } from 'Utils/options/useOptionsGenderTitle';
import { PhonePrefixOption, } from 'Utils/options/useOptionsPhonePrefix';
import { ReplacementReasonOption, } from 'Utils/options/useOptionsReplacementReason';
import { UserVerificationOption, } from 'Utils/options/useOptionsUserVerification';
import create from 'zustand';

export type CustomerRegistrationValues = {
  title: GenderTitleOption | null;
  firstName: string;
  lastName: string;
  dateOfBirth: Date | null;
  createdAt: Date | null;
  phone: string;
  phonePrefix: PhonePrefixOption | null;
  purchasedProducts: PurchasedProductsForGqlDbOptionFragment | null;
  previouslyOwnedDevices: PreviouslyOwnedDevicesOptionFragment | null;
  acceptedTermsAndConditions: boolean;
  acceptedPulzeCare: boolean;
  acceptedPulzeOn: boolean;
  verificationMethod: UserVerificationOption | null;
  email: string;
  deviceCode: string;
};
export type ProductOption = InputProductCountMultipleValueOption<AssortmentOptionFragment>;
export type CreateTicketValues = {
  category: AssortmentCategoryOptionFragment | null;
  products: ProductOption[];
  reason: ReplacementReasonOption | null;
  reasonText: string;
  note: string;
  batchCode: string;
  address: string;
};
export type CustomerCreateReplacement = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phonePrefix: string;
  dateOfBirth: Date | null;
};
export type CreateReplacementPhase =
  | 'init'
  | 'customerRegistration'
  | 'customerVerification'
  | 'customerRegistrationComplete'
  | 'createTicket'
  | 'ticketCompleted'
  | 'replacementRegistration';

export type ReplacementRegistration = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phonePrefix: PhonePrefixOption | null;
  address: '';
};

type CreateReplacementStoreState = {
  phase: CreateReplacementPhase;
  replacementEmail: string;
  shouldCreateCustomer: boolean;
  customer: CustomerCreateReplacement | null;
  isVerifiedByGlobalApi: boolean;
  customerRegistrationValues: CustomerRegistrationValues;
  customerVerification: {
    shouldVerify: boolean;
    isVerified: boolean;
    method: CustomerVerificationMethod | null;
    value: string | null;
  };
  createTicketValues: CreateTicketValues;
  replacement: ReplacementFragment | null;
  replacementRegistration: ReplacementRegistration;
  customerVerificationResult: CustomerVerificationStartResult | null;
};

export type CreateReplacementStore = CreateReplacementStoreState & {
  resetStore: () => void,
  goBackToPhase: (phase: CreateReplacementPhase) => void,
  registerCustomer: (customer: CreateReplacementStoreState['customer']) => void,
  completeInitPhase: (customer: CustomerCreateReplacement | null, replacementEmail: string,isVerifiedByGlobalApi: boolean) => void,
  initiateReplacementRegistration: (email?:string) => void,
  completeCustomerRegistrationPhase: (
    customerRegistrationValues: CustomerRegistrationValues,
    customerVerification: CreateReplacementStoreState['customerVerification'],
    customerVerificationResult?: CustomerVerificationStartResult,
  ) => void;
  completeCustomerVerificationPhase: (customerVerification: CreateReplacementStoreState['customerVerification'], customer: CustomerFragment) => void;
  completeCustomerRegistrationCompletePhase: () => void;
  completeReplacement: (replacement: ReplacementFragment) => void;
  completeReplacementRegistrationPhase: (replacementRegistration: ReplacementRegistration) => void;
  setCustomerVerification: (customerVerification: CreateReplacementStoreState['customerVerification']) => void;
};

export const INIT_STATE: CreateReplacementStoreState = {
  phase: 'init',
  replacementEmail: '',
  shouldCreateCustomer: true,
  customer: null,
  isVerifiedByGlobalApi: false,
  customerRegistrationValues: {
    title: null,
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    phone: '',
    phonePrefix: null,
    purchasedProducts: null,
    previouslyOwnedDevices: null,
    acceptedTermsAndConditions: false,
    acceptedPulzeCare: false,
    acceptedPulzeOn: false,
    verificationMethod: null,
    createdAt: null,
    email: '',
    deviceCode: '',
  },
  customerVerification: {
    shouldVerify: true,
    isVerified: false,
    method: null,
    value: null,
  },
  createTicketValues: {
    category: null,
    products: [],
    reason: null,
    reasonText: '',
    note: '',
    batchCode: '',
    address: '',
  },
  replacement: null,
  replacementRegistration: {
    firstName: '',
    phoneNumber: '',
    lastName: '',
    phonePrefix: null,
    email: '',
    address: '',
  },
  customerVerificationResult: null,
};

export const useCreateReplacementStore = create<CreateReplacementStore>((set) => ({
  ...INIT_STATE,
  resetStore: () =>
    set({
      ...INIT_STATE,
    }),
  setCustomerVerification: (customerVerification) => set((state) => ({ ...state, customerVerification, })),
  goBackToPhase: (phase) =>
    set({
      phase,
    }),
  registerCustomer: (customer) =>
    set((state) => ({
      shouldCreateUser: false,
      customer,
    })),
  completeInitPhase: (customer, replacementEmail,isVerifiedByGlobalApi) =>
    set((state) => ({
      ...INIT_STATE,
      phase: customer ? 'createTicket' : 'customerRegistration',
      customer,
      shouldCreateUser: !customer,
      replacementEmail,
      isVerifiedByGlobalApi,
    })),
  initiateReplacementRegistration: (replacementEmail) =>
    set((state) => ({
      ...INIT_STATE,
      phase: 'replacementRegistration',
      email: state.replacementEmail,
      replacementRegistration:{...state.replacementRegistration, email: replacementEmail || '',},
    })),
  completeCustomerRegistrationPhase: (customerRegistrationValues, customerVerification, customerVerificationResult) =>
    set((state) => {
      return {
        phase: 'customerVerification',
        customer: {
          id: '',
          email: state.replacementEmail,
          firstName: customerRegistrationValues.firstName,
          lastName: customerRegistrationValues.lastName,
          phoneNumber: customerRegistrationValues.phone,
          phonePrefix: customerRegistrationValues.phonePrefix?.id || '',
          dateOfBirth: customerRegistrationValues.dateOfBirth ? new Date(customerRegistrationValues.dateOfBirth) : null,
        },
        customerRegistrationValues,
        customerVerification,
        customerVerificationResult,
      };
    }),
  completeCustomerVerificationPhase: (customerVerification, customer) =>
    set((state) => ({
      phase: 'customerRegistrationComplete',
      customerVerification,
    })),
  completeCustomerRegistrationCompletePhase: () =>
    set({
      phase: 'createTicket',
    }),
  completeReplacement: (replacement) =>
    set({
      phase: 'ticketCompleted',
      replacement,
    }),
    completeReplacementRegistrationPhase: (replacementRegistration) => set((state) => ({
      phase: 'createTicket',
      replacementRegistration: {
        ...state.replacementRegistration,
        ...replacementRegistration,
      },
      })),
}));
