import {
  CheckGiftCodeMutation,
  CustomerVerificationMethod,
  CustomerVerificationStartResult,
  PreviouslyOwnedDevices,
  PurchasedProductsForGlobalDb,
  RegistrationType,
} from 'Apollo/graphql';
import {
  CustomerRegistrationForm,
  InformationVerificationFormValues,
  RegistrationCustomer,
} from 'Modules/RegistrationSeller/store/RegistrationStore';
import create from 'zustand';

export type CzGiftCodePhase = 'init' | 'detail' | 'registerCustomer' | 'customerVerification' | 'complete';
type CzGiftCodeStoreState = {
  phase: CzGiftCodePhase;
  giftCode: CheckGiftCodeMutation['checkGiftCode'] | null;
  customerEmail?: string | null;
  registrationEmail: string;
  shouldCreateUser: boolean;
  customer: RegistrationCustomer | null;
  customerRegistration: CustomerRegistrationForm;
  verification: {
    shouldVerify: boolean;
    isVerified: boolean;
    method: CustomerVerificationMethod | null;
    value: string | null;
  };
  informationVerification: InformationVerificationFormValues;
  isShortRegistration?: boolean;
  customerVerificationResult: CustomerVerificationStartResult | null;
};

export type CzGiftCodeStoreStore = CzGiftCodeStoreState & {
  completeInitPhase: (result: CheckGiftCodeMutation['checkGiftCode']) => void;
  completeDetailPhase: () => void;
  completeCustomerRegistrationPhase: (
    customerRegistration: CustomerRegistrationForm,
    verification: CzGiftCodeStoreState['verification'],
    customerVerificationResult?: CustomerVerificationStartResult,
  ) => void;
  completeCustomerVerificationPhase: (verification: CzGiftCodeStoreState['verification']) => void;
  setCustomerRegistration: (args: CustomerRegistrationForm) => void;
  resetStore: () => void;
  registerCustomer: () => void;
  goBackToPhase: (phase: CzGiftCodePhase) => void;
};

export const INIT_STATE: CzGiftCodeStoreState = {
  phase: 'init',
  giftCode: null,
  registrationEmail: '',
  shouldCreateUser: false,
  customer: null,
  customerRegistration: {
    title: null,
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    phone: '',
    phonePrefix: null,
    purchasedProducts: null,
    previouslyOwnedDevices: null,
    deviceCode: '',
    acceptedTermsAndConditions: false,
    acceptedPulzeCare: false,
    acceptedPulzeOn: false,
    verificationMethod: null,
  },
  verification: {
    shouldVerify: true,
    isVerified: false,
    method: null,
    value: null,
  },
  informationVerification: {
    pos: null,
    user: null,
    username: '',
  },
  customerVerificationResult: null,
};

export const useCzGiftCodeStore = create<CzGiftCodeStoreStore>((set) => ({
  ...INIT_STATE,
  completeInitPhase: (result) =>
    set((state) => ({
      ...INIT_STATE,
      phase: 'detail',
      registrationEmail: result.customer?.email ?? '',
      giftCode: result,
    })),
  registerCustomer: () =>
    set((state) => ({
      ...state,
      phase: 'registerCustomer',
    })),
  goBackToPhase: (phase: CzGiftCodePhase) =>
    set((state) => ({
      ...state,
      phase,
    })),
  completeCustomerRegistrationPhase: (customerRegistration, verification, customerVerificationResult) =>
    set((state) => {
      return {
        ...state,
        phase: 'customerVerification',
        customer: {
          id: undefined,
          email: state.registrationEmail,
          firstName: customerRegistration.firstName,
          lastName: customerRegistration.lastName,
          phoneNumber: customerRegistration.phone,
          phonePrefix: customerRegistration.phonePrefix?.id || '',
          dateOfBirth: customerRegistration.dateOfBirth ? new Date(customerRegistration.dateOfBirth) : null,
          acceptedPulzeCare: customerRegistration.acceptedPulzeCare,
          acceptedPulzeOn: customerRegistration.acceptedPulzeOn,
          acceptedTermsAndConditions: customerRegistration.acceptedTermsAndConditions,
          previouslyOwnedDevices: customerRegistration.previouslyOwnedDevices?.id as PreviouslyOwnedDevices,
          purchasedProductsForGlobalDB: customerRegistration.purchasedProducts?.id as PurchasedProductsForGlobalDb,
          // FIXME: this should not be there, should be handled by the backend
          registrationType: RegistrationType.Standard,
        },
        customerRegistration,
        verification,
        customerVerificationResult,
      };
    }),
  completeCustomerVerificationPhase: (verification) =>
    set((state) => ({
      ...state,
      phase: 'detail',
      verification,
    })),
  completeDetailPhase: () =>
    set((state) => ({
      ...state,
      phase: 'complete',
    })),
  setCustomerRegistration: (customerRegistration) =>
    set((state) => ({
      ...state,
      customerRegistration,
    })),
  resetStore: () =>
    set((state) => ({
      ...INIT_STATE,
    })),
}));
