import { ReactNode, } from 'react';
import { useFormState, } from 'react-hook-form';
import Button, { ButtonProps, } from '@mui/material/Button';
import { useFormBlockerContext, } from '../FormBlocker/useFormBlockerContext';

interface Props extends Omit<ButtonProps, 'type'> {
  children: ReactNode,
}

const FormSubmit = ({
  disabled,
  children,
  ...rest
}: Props): JSX.Element => {
  const { isSubmitting, } = useFormState();
  const blocker = useFormBlockerContext();

  const isBlockedForm = blocker !== null && blocker.isBlocked;

  return (
    <Button
      {...rest}
      type="submit"
      disabled={disabled || isSubmitting || isBlockedForm}
    >
      {children}
    </Button>
  );
};


export default FormSubmit;
