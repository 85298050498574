import AppContainer from 'Components/AppContainer';
import { ReactNode, useEffect, } from 'react';
import shallow from 'zustand/shallow';
import { CzGiftCodePhase, CzGiftCodeStoreStore, useCzGiftCodeStore, } from 'Modules/GiftCodeSeller/store/CzGiftCodeStore';
import LayoutStepper from './LayoutStepper';
import PhaseComplete from './PhaseComplete';
import PhaseDetail from './PhaseDetail';
import PhaseInit from './PhaseInit';
import PhaseRegisterCustomer from './PhaseRegisterCustomer';
import PhaseCustomerVerification from './PhaseCustomerVerification';

const PHASE_COMPONENTS: { [key in Exclude<CzGiftCodePhase, 'init'>]: ReactNode } = {
  detail: <PhaseDetail />,
  registerCustomer: <PhaseRegisterCustomer />,
  customerVerification: <PhaseCustomerVerification />,
  complete: <PhaseComplete />,
};

const selectStoreData = (s: CzGiftCodeStoreStore) => ({
  phase: s.phase,
  resetStore: s.resetStore,
  giftCode: s.giftCode
});


const CzGiftCodePage = () => {
  const { phase, resetStore, giftCode } = useCzGiftCodeStore(selectStoreData, shallow);

  useEffect(
    () => {
      return () => resetStore();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(() => window.scrollTo({ top: 0, }), [phase,]);
  useEffect(() => {
    if (phase !== 'init' && !giftCode) resetStore()
  }, [phase, giftCode, resetStore])

  return (
    <AppContainer maxWidth="sm">
      {phase === 'init' ? <PhaseInit /> : <LayoutStepper phase={phase}>{PHASE_COMPONENTS[phase]}</LayoutStepper>}
    </AppContainer>
  );
};

export default CzGiftCodePage;
