import { OrderBy, PaginationInput, TaskFilterInput, TaskSortableFields, TaskSortInput, } from 'Apollo/graphql';

export const sellerTaskFilter = (
  type: string,
): {
  filter: TaskFilterInput;
  pagination?: PaginationInput;
  sort: TaskSortInput;
} => ({
  filter: {
    isNowAvailable: true,
    type: { contains: type, },
  },
  sort: {
    field: TaskSortableFields.CreatedAt,
    order: OrderBy.Desc,
  },
});
