import { ReactNode, useEffect, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useDropzone, FileRejection, Accept, } from 'react-dropzone';
import Button, { ButtonProps, } from '@mui/material/Button';
import { FileFragment, useUploadFileMutation, } from 'Apollo/graphql';
import { InputFormErrorMessage, } from 'Components/Form';
import TrashIcon from 'Utils/svg/TrashIcon';
import DropArea from './DropArea';
import { mapFileError, parseFileApolloError, } from './utils';
import InputLabelClassic from '../components/InputLabelClassic';
import FormHelperTextClassic from '../components/FormHelperTextClassic';
import UploadButton from './UploadButton';

type FileType = 'image' | 'csv' | 'video'; 

// types
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const ACCEPT_TYPES: { [key in FileType]: Accept } = {
  image: {
    'image/png': [],
    'image/jpeg': [],
  },
  csv: {
    'text/csv': [],
  },
  video: {
    'video/mp4': [],
  },
};

const FileErrorParams: { [key in FileType]: string | undefined } = {
  image: 'png, jpg, jpeg',
  csv: 'csv',
  video: 'mp4',
};

export interface InputFileUploadClassicProps {
  value: FileFragment,
  onChange: (file: FileFragment | null) => void,
  fileType?: FileType,
  maxSize: number,
  disabled?: boolean,
  type?: 'button' | 'drop-zone',
  buttonProps?: ButtonProps,
  required?: boolean,
  helperText?: ReactNode,
  error?: boolean,
  label?: ReactNode,
  onStartUpload?: () => void,
  onEndUpload?: () => void,
};

const InputFileUploadClassic = ({
  value,
  onChange,
  fileType,
  maxSize,
  required,
  disabled,
  type,
  buttonProps,
  helperText,
  error,
  label,
  onStartUpload,
  onEndUpload,
}: InputFileUploadClassicProps): JSX.Element => {
  const { t, } = useTranslation('common');
  const [ dropZoneErrorMessage, setDropZoneErrorMessage, ] = useState<InputFormErrorMessage | null>(null);
  const [ globalError, setGlobalError, ] = useState<ReactNode | null>(null);
  
  useEffect(
    () => {
      if (error) setGlobalError(helperText);
      else if (dropZoneErrorMessage) setGlobalError(t(dropZoneErrorMessage.key, dropZoneErrorMessage.params));
      else setGlobalError(null);
    },
    // on input error change
    [ error, helperText, ],
  );

  const dropZoneError = (dzError: InputFormErrorMessage | null) => {
    setDropZoneErrorMessage(dzError);
    if (dzError) setGlobalError(t(dzError.key, dzError.params));
    else if (error) setGlobalError(helperText);
    else setGlobalError(null);
  };
  const [ uploadMutation, uploadResult, ] = useUploadFileMutation({
    fetchPolicy: 'no-cache',
    onError: (responseError) => {
      dropZoneError(parseFileApolloError(responseError));
      onEndUpload?.();
    },
    onCompleted: (response) => {
      onChange(response.uploadFile);
      onEndUpload?.();
    }, 
  }); 

  const handleDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    dropZoneError(null);

    if (acceptedFiles.length > 0) {
      onStartUpload?.();
      uploadMutation({
        variables: {
          file: acceptedFiles[0],
        },
      });
    }
    else if (fileRejections.length > 0) {
      const mappedError = mapFileError(
        fileRejections[0],
        {
          fileTypes: fileType ? FileErrorParams[fileType] : undefined,
          maxSize,
        }
      );
      dropZoneError(mappedError);
    }
  };

  const handleRemove = () => {
    onChange(null);
    dropZoneError(null);
  };

  const disabledAll = disabled || uploadResult.loading;

  const {
    open,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: fileType ? ACCEPT_TYPES[fileType] : undefined,
    disabled: disabledAll,
    multiple: false,
    noClick: false,
    maxSize,
    maxFiles: 1,
    onDrop: handleDrop,
  });

  return (
    <>

      <InputLabelClassic
        required={required}
        error={!!globalError}
      >
        {label}
      </InputLabelClassic>

      {(type === 'button') ? (
        <UploadButton
          open={open}
          value={value}
          buttonProps={buttonProps}
          loading={uploadResult.loading}
          disabledAll={disabledAll}
          handleRemove={handleRemove}
        />
      ) : (
        <>
          <DropArea
            value={value}
            loading={uploadResult.loading}
            error={!!globalError}
            disabled={disabledAll}
            onInputClick={open}
            getRootProps={getRootProps}        
            getInputProps={getInputProps}
            isDragActive={isDragActive}
          />
          {value && (
            <Button
              startIcon={<TrashIcon />}
              sx={{
                mt: 1,
              }}
              variant="contained"
              color="neutral"
              disabled={disabledAll}
              onClick={handleRemove}
            >
              {t('inputFile.btnRemove')}
            </Button>
          )}
        </>
      )}

      {(helperText || globalError) && (
        <FormHelperTextClassic
          error={!!globalError}
        >
          {globalError || helperText}
        </FormHelperTextClassic>
      )}

    </>
  );
};

export default InputFileUploadClassic;
