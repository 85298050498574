import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useSnackbar, } from 'notistack';
import { PointsTransactionFragment, useUpdatePointsTransactionMutation, } from 'Apollo/graphql';
import {
  pointsTransactionFormValidationSchema,
  mapFormToVariables,
  useInitPointsTransactionValues,
} from '../../utils/pointsTransactionForm';
import View from './View';

interface Props {
  pointsTransaction: PointsTransactionFragment,
  onClose: () => void,
};

const Logic = ({
  pointsTransaction,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('pointsTransactionAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const initValues = useInitPointsTransactionValues(pointsTransaction);
  const methods = useForm({
    defaultValues: initValues,
    resolver: yupResolver(pointsTransactionFormValidationSchema),
    mode: 'onTouched',
  });

  const [ editMutation, editMutationResult, ] = useUpdatePointsTransactionMutation({
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('editPointsTransactionModal.notifEditedTitle') as string,
        message: t('editPointsTransactionModal.notifEditedMessage', { id: response.updatePointsTransaction.id, }),
        variant: 'common',
      });
      onClose();
    },
    onError: () => {
      // TODO: merge form errors
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await editMutation({
      variables: {
        id: pointsTransaction.id,
        purchaseInput: mapFormToVariables(values),
      },
    });    
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View
          pointsTransaction={pointsTransaction}
          editMutationResult={editMutationResult}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default Logic;
