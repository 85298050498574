import shallow from 'zustand/shallow';
import { useNavigate, } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';

const selectStoreData = (s: RegistrationStore) => ({
  customer: s.customer,
  purchaseVerification: s.purchaseVerification,
  resetStore: s.resetStore,
  isShortRegistration: s.isShortRegistration,
});

const PhaseRegistrationComplete = (): JSX.Element => {
  const { t, } = useTranslation('registrationSeller');
  const navigate = useNavigate();
  const {
    customer,
    purchaseVerification,
    resetStore,
    isShortRegistration,
  } = useRegistrationStore(selectStoreData, shallow);

  const handleDone = () => {
    navigate('/');
    resetStore();
  };
  const handleNewRegistration = () => resetStore(isShortRegistration);

  const createdAt = purchaseVerification?.createdAt
    ? new Date(purchaseVerification?.createdAt)
    : new Date();

  return (
    <>
      <Typography
        variant="h1"
        sx={{ mb: 1.5, }}
      >
        {t('registrationComplete.title')}
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 3, }}
      >
        {isShortRegistration
          ? t('registrationComplete.shortRegistrationInfo')
          : t('registrationComplete.info', { email: customer?.email || '' })}
      </Typography>

      <Typography
        variant="body0"
        component="p"
        sx={{ mb: 3, }}
      >
        {t('common:date.fullDateTime', { date: createdAt, })}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} lg="auto">
          <Button
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            onClick={handleDone}
          >
            {t('registrationComplete.btnDone')}
          </Button>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            onClick={handleNewRegistration}
          >
            {t('registrationComplete.btnNewRegistration')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PhaseRegistrationComplete;
