import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import { Typography, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import { useNavigate, useParams, } from 'react-router-dom';
import { useNewsArticleDetailQuery, } from 'Apollo/graphql';
import { ROUTES, } from 'Utils/constants';
import NewsArticleDetail from './NewsArticleDetail';

const NewsArticlesDetailPage = () => {
  const { t, } = useTranslation('newsArticlesSeller');
  const { id, } = useParams();
  const navigate = useNavigate();

  const { loading, error, data, } = useNewsArticleDetailQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id || '',
    },
  });

  const navigateBack = () => navigate(`/${ROUTES.news}`);

  return (
    <AppContainer maxWidth="sm">
      <Typography
        variant="h1"
        marginBottom={3}
      >
        {t('newsArticlesTitle')}
      </Typography>
      
      <NewsArticleDetail
        data={data}
        error={error}
        loading={loading}
      />

      <Button
        size="large"
        variant="outlined"
        color="primary"
        onClick={navigateBack}
        disabled={loading}
      >
        {t('common:actions.back')}
      </Button>
    </AppContainer>
  );
};

export default NewsArticlesDetailPage;
