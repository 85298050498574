import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { numberOfTaskCompletionsQuery, } from './queries';

const NumberOfTaskCompletions = ({
  showAddButton,
  filtration,
  chartFilter,
  showSlug = true,
  showTotal,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');
  const query = useMemo(
    () =>
      numberOfTaskCompletionsQuery({
        filters: chartFilter,
      }),
    [chartFilter, ],
  );
  return (
    <Base headerText={t('dashboards.pointsAndTasks.numberOfTaskCompletions')}  slug={showSlug ? 'number-of-task-completions' : undefined}
    showAddButton={showAddButton}
    
    filtration={filtration}
    dashboardLayoutItemName={DashboardLayoutItemName.PointsAndTasksNumberOfTasksCompletions}
    >
      <ChartRenderer
        showTotal={showTotal}
        query={query}
        type={ChartType.pie}
      />
    </Base>
  );
};

export default NumberOfTaskCompletions;
