import { yupResolver, } from '@hookform/resolvers/yup';
import { AuthUserFragment, } from 'Apollo/graphql';
import { InformationVerificationFormValues, RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { useCallback, useState, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useAuthUser, } from 'Tools/auth';
import shallow from 'zustand/shallow';
import { useMakePurchase, } from './useMakePurchase';
import { useInformationVerificationForm, } from './utils';
import View from './View';

const selectStoreData = (s: RegistrationStore) => ({
  customer: s.customer,
  purchase: s.purchase,
  customerRegistration: s.customerRegistration,
  informationVerification: s.informationVerification,
  registerCustomer: s.registerCustomer,
  completeInformationVerificationPhase: s.completeInformationVerificationPhase,
  setInformationVerification: s.setInformationVerification,
  goBackToPhase: s.goBackToPhase,
  deviceCodeRequiredList: s.deviceCodeRequiredList,
  deviceCodeRequiredPromotionsList: s.deviceCodeRequiredPromotionsList,
  promotionPurchase: s.promotionCategoryPurchaseList,
  isShortRegistration: s.isShortRegistration,
  resetIndexes: s.resetIndexes,
  promotionCategoryPurchaseList: s.promotionCategoryPurchaseList,
});

const PhaseInformationVerification = (): JSX.Element => {
  const authUser = useAuthUser();
  const {
    customer,
    purchase,
    customerRegistration,
    informationVerification,
    registerCustomer,
    completeInformationVerificationPhase,
    setInformationVerification,
    goBackToPhase,
    deviceCodeRequiredList,
    deviceCodeRequiredPromotionsList,
    promotionPurchase,
    isShortRegistration,
    resetIndexes,
    promotionCategoryPurchaseList,
  } = useRegistrationStore(selectStoreData, shallow);
  const [canSubmit, disableSubmit,] = useState(true);
  const { initValues, showFields, formValidationSchema, } = useInformationVerificationForm(informationVerification, authUser as AuthUserFragment);
  const methods = useForm<InformationVerificationFormValues>({
    defaultValues: initValues,
    resolver: formValidationSchema ? yupResolver(formValidationSchema) : undefined,
    mode: 'onChange',
  });

  const { error, makePurchase, } = useMakePurchase({
    customer,
    purchase,
    customerRegistration,
    completeInformationVerificationPhase,
    registerCustomer,
    purchasedAssortmentsRaw: deviceCodeRequiredList,
    purchasedAssortmentsPromotionRaw: deviceCodeRequiredPromotionsList,
    promotionPurchase,
    isShortRegistration,
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    makePurchase(values);
    disableSubmit(() => false);
  });

  const handleGoBack = useCallback(
    () => {
      setInformationVerification(methods.getValues());
      if (deviceCodeRequiredList.length > 0) { 
        goBackToPhase('deviceCodes');}
      if (deviceCodeRequiredPromotionsList.length > 0) { 
          goBackToPhase('deviceCodesPromotion');}
      if (deviceCodeRequiredList.length < 1 && deviceCodeRequiredPromotionsList.length < 1) {
        goBackToPhase('purchase');
      }
      },
    [ setInformationVerification, goBackToPhase, methods,deviceCodeRequiredList.length, ],
  );
  
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <View
          error={error}
          authUser={authUser as AuthUserFragment}
          showFields={showFields}
          customer={customer}
          purchase={purchase}
          onGoBack={handleGoBack}
          goBackToPhase={goBackToPhase}
          resetIndexes={resetIndexes}
          promotionCategoryPurchaseList={promotionCategoryPurchaseList}
          canSubmit={canSubmit}
        />
      </form>
    </FormProvider>
  );
};

export default PhaseInformationVerification;
