import { ChartPivotRow, ResultSet, Series, } from '@cubejs-client/core';
import { PieChart, } from 'bizcharts';
import NoDataFound from 'Components/Dashboard/NoDataFound';
import { useTranslation, } from 'react-i18next';
import { useDeepCompareMemo, } from 'use-deep-compare';
import getTranslationText from './getTranslationText';
import { formatValue, } from './utils';
import { ChartType, } from './types';

const calculatePercentage = (data: ChartPivotRow[]) => {
  const total = data.reduce((acc, row) => {
    const keys = Object.keys(row);
    const lastKey = keys[keys.length - 1];
    return acc + row[lastKey];
  }, 0);
  return data.map((row) => {
    return {
      ...row,
      percent: (
        (row[Object.keys(row)[Object.keys(row).length - 1]] / total) *
        100
      ).toFixed(0),
    };
  });
};

const PieChartRenderer = ({
  resultSet,
  legendPosition,
  hideNullValues = true,
  hideUnknownValues = false,
}: {
  resultSet: ResultSet;
  legendPosition?: 'left' | 'right' | 'top' | 'bottom';
  hideNullValues?: boolean;
  hideUnknownValues?: boolean;
}) => {
  type DataType = {
    percent: string;
    x: string;
    xValues: string[];
    [key: string]: unknown;
  }[];
  const [data, angleField,]: [DataType, Series<never>[],] =
    useDeepCompareMemo(() => {
      return [calculatePercentage(resultSet.chartPivot()), resultSet.series(),];
    }, [resultSet,]);
  const { t, } = useTranslation('dashboards');

  const tooltipFormatter = (text: string) => {
    let formattedText;
    if (text === 'false') {
      formattedText = t('dashboards.inactive');
    } else if (text === 'true') {
      formattedText = t('dashboards.active');
    } else {
      formattedText = formatValue(getTranslationText(t, text), {
        undefinedValue: t('dashboards:dashboards.unknown'),
        pieType: ChartType.pie,
      });
    }
    return formattedText;
  };
  const filteredData = data.filter((d) => {
    if (hideUnknownValues && (d.x === 'null' || d.x === '∅')) {
      return false;
    }
    if (
      hideNullValues &&
      (d[angleField[0].key] === null || d[angleField[0].key] === 0)
    ) {
      return false;
    }
    return true;
  });
  if (filteredData.length > 0 && angleField.length > 0) {
    return (
      <PieChart
        data={filteredData}
        height={265}
        radius={0.9}
        angleField={angleField[0].key}
        colorField="x"
        color={['#96704F', '#FF7000', '#413530',]}
        tooltip={{
          formatter: (text) => {
            const name = tooltipFormatter(text.x);
            return {
              name,
              value: text[angleField[0].key],
            };
          },
        }}
        label={{
          visible: true,
          style: {
            fontSize: 14,
          },
          offset: 20,
          formatter: (text) => {
            const keys = Object.keys(text);
            return `${(text.percent * 100).toFixed(0)}% (${text[keys[2]]})`;
          },
        }}
        legend={{
          position: legendPosition || 'right',
          formatter(text) {
            return tooltipFormatter(text);
          },
        }}
      />
    );
  }
  return <NoDataFound />;
};

export default PieChartRenderer;
