import { styled, } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { UserPointsBalanceFragment, } from 'Apollo/graphql';
import { useTranslation, } from 'react-i18next';

type Props = {
  data: UserPointsBalanceFragment;
};

const PointsBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const View = ({ data, }: Props): JSX.Element => {
  const { t, } = useTranslation('myPoints');
  return (
    <Box
      pt={3}
      pb={3}
      pl={2}
      pr={2}
    >
      <PointsBox mb={2}>
        <Typography variant="body0">{t('balance.available')}</Typography>
        <Typography component="div" variant="h1">{`${data.available} ${t('generic.points')}`}</Typography>
      </PointsBox>
      <Divider />
      <PointsBox mb={2} mt={2}>
        <Typography variant="body0">{t('balance.earned')}</Typography>
        <Typography component="div" variant="h2" color="success.main">{`${data.total} ${t('generic.points')}`}</Typography>
      </PointsBox>
      <PointsBox>
        <Typography variant="body0">{t('balance.spent')}</Typography>
        <Typography component="div" variant="h2">{`${data.spent} ${t('generic.points')}`}</Typography>
      </PointsBox>
    </Box>
  );
};
export default View;
