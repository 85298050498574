import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const ReplacementsColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M4.83 17.188a1.381 1.381 0 0 1 .802-1.117 1.12 1.12 0 0 1 1.259.306c.756.829 1.63 1.493 2.692 1.873 2.625.937 4.957.407 7.016-1.417a6.771 6.771 0 0 0 1.818-2.728c.11-.29.03-.415-.296-.463-.467-.061-.94-.089-1.402-.177-.178-.035-.43-.163-.473-.303a.701.701 0 0 1 .163-.572c.976-1.055 1.974-2.093 2.97-3.131.352-.368.641-.332.902.117a230.611 230.611 0 0 1 2.186 3.798.638.638 0 0 1 0 .56c-.09.13-.34.189-.525.204-.23.004-.46-.021-.684-.075-.276-.05-.414.051-.497.326a9.46 9.46 0 0 1-3.325 4.76c-3.695 2.871-9.142 2.349-12.194-1.192-.185-.22-.277-.512-.413-.77Z"
      fill="#d47e5f"
    />
    <path
      d="M2.026 9.827c.212.016.424.043.634.082.334.077.474-.07.566-.375a9.006 9.006 0 0 1 2.376-3.869c3.763-3.65 9.544-3.536 13.023.252.414.447.494.925.243 1.381-.252.457-.71.72-1.21.586a1.796 1.796 0 0 1-.766-.478 6.722 6.722 0 0 0-2.633-1.719c-2.574-.891-4.846-.326-6.848 1.45a6.814 6.814 0 0 0-1.81 2.7c-.129.34-.077.424.29.471.514.065 1.03.11 1.54.205a.613.613 0 0 1 .401.298.528.528 0 0 1-.103.46 208.276 208.276 0 0 1-3.164 3.28c-.276.277-.579.232-.775-.11a380.691 380.691 0 0 1-2.248-3.96c-.203-.36.029-.672.484-.654Z"
      fill="#000"
    />
  </SvgIcon>
);

export default ReplacementsColoredIcon;
