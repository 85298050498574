import { ReactNode, } from 'react';
import Grid from '@mui/material/Grid';
import {
  AssessmentBlockTypeEnum,
  SellerTextAssessmentBlockFragment,
  SellerNumberAssessmentBlockFragment,
  SellerYesNoAssessmentBlockFragment,
  SellerCheckboxAssessmentBlockFragment,
  SellerRadioAssessmentBlockFragment,
  SellerScaleAssessmentBlockFragment,
  SellerPhotoAssessmentBlockFragment,
} from 'Apollo/graphql';
import { NodeKeys, } from '../types';
import { NodeProps, } from './types';
import NodeText from './nodes/NodeText';
import NodeNumber from './nodes/NodeNumber';
import NodeYesNo from './nodes/NodeYesNo';
import NodeCheckbox from './nodes/NodeCheckbox';
import NodeRadio from './nodes/NodeRadio';
import NodeScale from './nodes/NodeScale';
import NodePhoto from './nodes/NodePhoto';

type NodeTypes = {
  [key in NodeKeys]: (props: NodeProps) => ReactNode | null
};

const NODE_TYPES: NodeTypes = {
  [AssessmentBlockTypeEnum.Text]: (props) => <NodeText {...props as NodeProps<SellerTextAssessmentBlockFragment>} />,
  [AssessmentBlockTypeEnum.Number]: (props) =>  <NodeNumber {...props as NodeProps<SellerNumberAssessmentBlockFragment>} />,
  [AssessmentBlockTypeEnum.YesNo]: (props) => <NodeYesNo {...props as NodeProps<SellerYesNoAssessmentBlockFragment>} />,
  [AssessmentBlockTypeEnum.Checkbox]: (props) => <NodeCheckbox {...props as NodeProps<SellerCheckboxAssessmentBlockFragment>} />,
  [AssessmentBlockTypeEnum.Radio]: (props) => <NodeRadio {...props as NodeProps<SellerRadioAssessmentBlockFragment>} />,
  [AssessmentBlockTypeEnum.Scale]: (props) => <NodeScale {...props as NodeProps<SellerScaleAssessmentBlockFragment>} />,
  [AssessmentBlockTypeEnum.Photo]: (props) => <NodePhoto {...props as NodeProps<SellerPhotoAssessmentBlockFragment>} />,
};

const Node = (props: NodeProps): JSX.Element | null => {
  const { block: { type, }, } = props;

  if (type && Object.prototype.hasOwnProperty.call(NODE_TYPES, type)) return (
    <Grid item xs={12}>
      {NODE_TYPES[type as NodeKeys](props)}
    </Grid>
  );
  return null;
};

export default Node;
