import { zodResolver, } from '@hookform/resolvers/zod';
import { Grid, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import ChartDetailLayout from 'Components/Dashboard/ChartDetailLayout';
import { usersByRolesQuery, } from 'Components/Dashboard/Users/queries';
import UsersByRoles from 'Components/Dashboard/Users/UsersByRoles';
import { useMemo, } from 'react';
import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { OptionTypes, ChartType, } from 'Utils/Charts/types';
import useDashboardFilter from 'Utils/Charts/UseDashboardFilter';
import { SourceValidationSchema, } from 'Utils/Charts/ValidationSchemas';
import { z, } from 'zod';


type ValidationSchema = z.infer<typeof SourceValidationSchema>;

const UsersByRolesPage = () => {
  const { t, } = useTranslation('dashboards');
  const filtration = [OptionTypes.source,];
  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(SourceValidationSchema),
    defaultValues: {
      chainIds: [],
      posIds: [],
    },
  });
  const watch = methods.watch();

  const {filtersArray,} = useDashboardFilter({
    chainIds: {
operator: 'equals',
      values: watch.chainIds as { id: string; name: string; }[],
      filterMember: 'user.chainid',
    },
    posIds: {
operator: 'equals',
      values: watch.posIds as { id: string; name: string; }[],
      filterMember: 'user.posid',
    },
  });

  const query = useMemo(
    () =>
      usersByRolesQuery({
        filters: filtersArray,
      }),
    [filtersArray,],
  );
  return (
    <AppContainer>
      <ChartDetailLayout pageTitle={t('common:dashboardSidebar.users')}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <UsersByRoles
                showTotal
                showSlug={false}
                filtration={filtration}
                chartFilter={filtersArray}
              />
            </FormProvider>
          </Grid>
          <Grid item xs={12}>
            <ChartRenderer
              query={query}
              type={ChartType.table}
              pivotConfig={{
                x: ['user.role',],
                y: ['measures',],
                fillMissingDates: true,
                joinDateRange: false,
              }}
            />
          </Grid>
        </Grid>
      </ChartDetailLayout>
    </AppContainer>
  );
};

export default UsersByRolesPage;
