import create from 'zustand';

export type AuthStore = {
  accessToken: string | null,
  setAccessToken: (accessToken: string | null) => void,
};

export const useAuthStore = create<AuthStore>((set) => ({
  //  state
  accessToken: null,

  // methods
  setAccessToken: (accessToken) => set({
    accessToken,
  }),
}));

export const getAccessToken = (): string | null => useAuthStore.getState().accessToken;

export const setAccessToken = (token: string | null): void => useAuthStore.getState().setAccessToken(token);
