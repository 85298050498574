import { Typography } from '@mui/material';

const NoDataFound = () => {
  return (
    <Typography variant="h2" textAlign="center">
      No data found
    </Typography>
  );
};

export default NoDataFound;
