import { ChangeEventHandler, ReactNode, useMemo, } from 'react';
import { styled, } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Checkbox, } from 'Components/Checkbox';
import { AnyObject, } from 'Utils/types';
import InputLabelClassic from './components/InputLabelClassic';
import FormHelperTextClassic from './components/FormHelperTextClassic';

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '1rem',
  },
}));

type GetOptionLabel<T extends AnyObject,> = (o: T) => ReactNode;
type GetOptionKey<T extends AnyObject,> = (o: T) => string | number;
type OnChange<T extends AnyObject,> = (newValue: T[]) => void;
type ObjectValue = { [key: string]: boolean };

export interface InputGroupCheckboxClassicProps<T extends AnyObject,> {
  id: string,
  label: ReactNode,
  value: T[],
  options: T[],
  onChange: OnChange<T>,
  getOptionLabel: GetOptionLabel<T>,
  getOptionKey: GetOptionKey<T>,
  required?: boolean,
  helperText?: ReactNode,
  error?: boolean,
}

// TODO - loading, error
const InputGroupCheckboxClassic = <T extends AnyObject,>({
  id,
  label,
  value,
  options,
  onChange,
  getOptionLabel,
  getOptionKey,
  required,
  helperText,
  error,
}: InputGroupCheckboxClassicProps<T>): JSX.Element => {
  const objValue = useMemo<ObjectValue>(
    () => value.reduce<ObjectValue>(
      (prev, curr) => ({
        ...prev,
        [getOptionKey(curr)]: true,
      }),
      {},
    ),
    [ value, getOptionKey, ],
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const clickedId = e.target.value;
    const node = options.find((o) => getOptionKey(o) === clickedId);

    if (node){
      if (!objValue[getOptionKey(node)]) onChange([ ...value, node, ]);
      else onChange(value.filter((o) => getOptionKey(o) !== clickedId));
    }
  };

  return (
    <FormControl>
      <InputLabelClassic
        id={id}
        required={required}
      >
        {label}
      </InputLabelClassic>
      <FormGroup>
        {options.map((o) => (
          <StyledFormControlLabel
            key={getOptionKey(o)}
            value={getOptionKey(o)}
            label={getOptionLabel(o)}
            control={(
              <Checkbox
                checked={!!objValue[getOptionKey(o)]}
                onChange={handleChange}
              />
            )}
          />
        ))}        
      </FormGroup>
      {(helperText) && (
        <FormHelperTextClassic
          error={error}
        >
          {helperText}
        </FormHelperTextClassic>
      )}
    </FormControl>
  );
};

export default InputGroupCheckboxClassic;
