import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { PromotionsStore, usePromotionsStore, } from 'Modules/PromotionsAdmin/store/PromotionsStore';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import FetchData from './FetchData';

const selectModalData = (s: PromotionsStore) => s.modalEditPromotion;
const selectClose = (s: PromotionsStore) => s.closeModalEditPromotion;

const ModalEditPromotion = (): JSX.Element => {
  const { isOpen, promotion, } = usePromotionsStore(selectModalData);
  const onClose = usePromotionsStore(selectClose);
  const { t, } = useTranslation('promotionsAdmin');

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!promotion}
    >
      <ModalDrawerTitle
        onClose={onClose}
      >
        {t('editPromotionModal.title')}
      </ModalDrawerTitle>
      <FetchData
        promotion={promotion}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalEditPromotion;
