import { INPUT_FORM_ERRORS } from 'Components/Form';
import * as yup from 'yup';
// import { PurchasedProductsForGqlDbOptionFragment, } from 'Apollo/graphql';
import { MarketEnum } from 'Apollo/graphql';
import type { FormConfig } from './types';
import { parsePhoneNumber, } from 'libphonenumber-js/max';

export const formValidationSchema = (
  formConfig: FormConfig,
  phoneIsDuplicate: boolean | undefined,
) =>
  yup.object().shape({
    ...(formConfig.enableTitle && {
      title: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
    }),
    firstName: yup
      .string()
      .required(INPUT_FORM_ERRORS.REQUIRED)
      .max(125, INPUT_FORM_ERRORS.STRING_MAX),
    lastName: yup
      .string()
      .required(INPUT_FORM_ERRORS.REQUIRED)
      .max(125, INPUT_FORM_ERRORS.STRING_MAX),
    dateOfBirth: yup
      .date()
      .required(INPUT_FORM_ERRORS.REQUIRED)
      .typeError(INPUT_FORM_ERRORS.DATE_FORMAT)
      .test(
        'dateOfBirth',
        INPUT_FORM_ERRORS.DATE_MIN_18_YEARS,
        (dateOfBirth) => {
          const cutoff = new Date();
          cutoff.setFullYear(cutoff.getFullYear() - 18);
          return dateOfBirth ? dateOfBirth <= cutoff : false;
        },
      )
      .nullable(),
    phone: yup
      .string()
      .min(2)
      .required(INPUT_FORM_ERRORS.REQUIRED)
      .test('phone', (value, { createError, path }) => {
        if (!formConfig.enablePhoneDuplicityVerification) return true;
        if (phoneIsDuplicate)
          return createError({
            message: INPUT_FORM_ERRORS.ALREADY_EXIST,
          });
        return true;
      })
      .test('phoneWithPrefix', (value, { createError, parent, }) => {
        const MIN_NUMBER_LENGTH = 2;
        if (!value || value.length < MIN_NUMBER_LENGTH || !parent.phonePrefix) {
          return false;
        }

        const number = `${parent.phonePrefix?.name}${value}`;
        const parsedNumber = parsePhoneNumber(number);

        const isNumberValid = parsedNumber.isValid();

        if (!isNumberValid) {
          return createError({
            message: INPUT_FORM_ERRORS.PHONE,
          });
        }

        const numberType = parsedNumber.getType();
        const isValidType = numberType === 'MOBILE' || numberType === 'FIXED_LINE_OR_MOBILE';

        if (!isValidType) {
          return createError({
            message: INPUT_FORM_ERRORS.PHONE_NUMBER_NOT_VALID,
          });

        }

        return true;
      }),
    phonePrefix: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
    purchasedProducts: yup
      .object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    previouslyOwnedDevices: yup
      .object()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED),
    acceptedTermsAndConditions: yup
      .boolean()
      .oneOf([true], INPUT_FORM_ERRORS.REQUIRED),
    acceptedPulzeCare: yup
      .boolean()
      .test(
        'acceptedPulzeCare',
        INPUT_FORM_ERRORS.REQUIRED,
        (acceptedPulzeCare) => {
          if (
            formConfig.currentMarket !== MarketEnum.Cz &&
            formConfig.currentMarket !== MarketEnum.Hu
          ) {
            return acceptedPulzeCare || false;
          }
          return true;
        },
      ),
    ...(formConfig.enablePulzeOn && {
      acceptedPulzeOn: yup
        .boolean()
        .test('enablePulzeOn', INPUT_FORM_ERRORS.REQUIRED, (enablePulzeOn) => {
          if (formConfig.currentMarket !== MarketEnum.Cz) {
            return enablePulzeOn || false;
          }
          return true;
        }),
    }),
    ...((formConfig.verificationMethods || []).length > 1 && {
      verificationMethod: yup
        .object()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
  });
