import { styled, } from '@mui/material/styles';
import { TableCell, } from '@mui/material';

const TableCellBlock = styled(TableCell)(({ theme, }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  paddingTop: 0,
  paddingBottom: 0,
}));

export default TableCellBlock;
