import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const ThreeDotsIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M16 20c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2Zm0-2c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Zm0-6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ThreeDotsIcon;
