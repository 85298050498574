import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { AssortmentStore, useAssortmentStore, } from 'Modules/AssortmentAdmin/store/AssortmentStore';
import Logic from './Logic';

const selectModalData = (s: AssortmentStore) => s.modalCreateAssortment;
const selectClose = (s: AssortmentStore) => s.closeModalCreateAssortment;

const ModalCreateAssortment = (): JSX.Element => {
  const { isOpen, } = useAssortmentStore(selectModalData);
  const onClose = useAssortmentStore(selectClose);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen}
    >
      <Logic
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalCreateAssortment;
