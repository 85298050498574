import { ReactNode, } from 'react';
import Grid from '@mui/material/Grid';
import { AssessmentBlockTypeEnum, CheckboxAssessmentBlockFragment, PhotoAssessmentBlockFragment, NumberAssessmentBlockFragment, RadioAssessmentBlockFragment, ScaleAssessmentBlockFragment, TextAssessmentBlockFragment, YesNoAssessmentBlockFragment, } from 'Apollo/graphql';
import { BlockProps, } from './types';
import BlockTextNumberScale from './blocks/BlockTextNumberScale';
import BlockRadio from './blocks/BlockRadio';
import BlockCheckbox from './blocks/BlockCheckbox';
import BlockYeasNo from './blocks/BlockYeasNo';
import BlockPhoto from './blocks/BlockPhoto';

type BlockTypes = {
  [key in AssessmentBlockTypeEnum]: (props: BlockProps) => ReactNode | null
};

const BLOCK_TYPES: BlockTypes = {
  [AssessmentBlockTypeEnum.Text]: (props) => <BlockTextNumberScale {...props as BlockProps<TextAssessmentBlockFragment>} />,
  [AssessmentBlockTypeEnum.Number]: (props) => <BlockTextNumberScale {...props as BlockProps<NumberAssessmentBlockFragment>} />,
  [AssessmentBlockTypeEnum.YesNo]: (props) => <BlockYeasNo {...props as BlockProps<YesNoAssessmentBlockFragment>} />,
  [AssessmentBlockTypeEnum.Checkbox]: (props) => <BlockCheckbox {...props as BlockProps<CheckboxAssessmentBlockFragment>} />,
  [AssessmentBlockTypeEnum.Radio]: (props) => <BlockRadio {...props as BlockProps<RadioAssessmentBlockFragment>} />,
  [AssessmentBlockTypeEnum.Scale]: (props) => <BlockTextNumberScale {...props as BlockProps<ScaleAssessmentBlockFragment>} />,
  [AssessmentBlockTypeEnum.Photo]: (props) => <BlockPhoto {...props as BlockProps<PhotoAssessmentBlockFragment>} />,
};

const Block = (props: BlockProps): JSX.Element | null => {
  const { block: { type, }, } = props;

  if (type && Object.prototype.hasOwnProperty.call(BLOCK_TYPES, type)) return (
    <Grid item xs={12}>{BLOCK_TYPES[type](props)}</Grid>
  );
  return null;
};

export default Block;
