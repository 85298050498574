import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import AppContainer from 'Components/AppContainer';
import CreateFaqList from './CreateFaqList';
import CallBox from './CallBox';

const SupportSeller = (): JSX.Element => {
  const { t, } = useTranslation('supportSeller');
  return (
    <AppContainer maxWidth="sm">
      <Typography variant="h1" marginBottom={2}>
        {t('title')}
      </Typography>
      <Typography variant="h2" color="primary" marginBottom={2}>
        {t('contactTitle')}
      </Typography>
      <CallBox />
      <Typography variant="h2" color="primary" marginBottom={2}>
        {t('faqTitle')}
      </Typography>
      <CreateFaqList />
    </AppContainer>
  );
};

export default SupportSeller;
