import {
  AssortmentCategoriesForPurchaseInputGqlType,
  AssortmentCategoryOptionFragment, AuthUserFragment, ChainFragment, ClusterFragment, FileFragment,
  PromotionAssortmentCategoryFragment,
  PromotionFragment,
  PromotionInput,
} from 'Apollo/graphql';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { useMemo, } from 'react';
import * as yup from 'yup';

export type AssortmentCategory = {
  option: AssortmentCategoryOptionFragment | null;
  count: number | null;
};
export type PromotionFormValues = {
  name: string;
  preview: FileFragment | null;
  banner: FileFragment | null;
  description: string;
  offerLimit: number | null;
  validFrom: Date | null;
  validTo: Date | null;
  chains: ChainFragment[];
  assortmentCategory: AssortmentCategory[];
  clusters: ClusterFragment[];
  isForBrandAmbassadors: boolean;
  notificationsVisible: boolean;
};

export const initPromotionFormValues: PromotionFormValues = {
  name: '',
  preview: null,
  banner: null,
  description: '',
  offerLimit: null,
  validFrom: null,
  validTo: null,
  chains: [],
  clusters: [],
  assortmentCategory: [{ option: null, count: null, },],
  isForBrandAmbassadors: false,
  notificationsVisible: false,
};
const mapPromotionCategoryToForm = (categories: PromotionAssortmentCategoryFragment[]): AssortmentCategory[] =>
  categories.map((category) => ({ option: category.assortmentCategory, count: category.count, }));
export const useInitPromotionValues = (promotion: PromotionFragment, authUser: AuthUserFragment | null): PromotionFormValues =>
  useMemo<PromotionFormValues>(
    () => ({
      name: promotion.name,
      preview: promotion.image || null,
      banner: promotion.banner || null,
      description: promotion.description,
      offerLimit: promotion.offerLimitPerCustomer,
      validFrom: promotion.validFrom,
      validTo: promotion.validTo,
      chains: authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS ? [] : promotion.chains,
      clusters: authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS ? promotion.clusterIds.map((c) => ({ id: c, })) : [],
      assortmentCategory: mapPromotionCategoryToForm(promotion.promotionAssortmentCategoriesForPurchases),
      isForBrandAmbassadors: promotion.isForBrandAmbassadors,
      notificationsVisible: promotion.notificationsVisible,
    }),
    // on mount -> init form values
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /* promotion */
    ],
  );

export const promotionFormValidationSchema = yup.object().shape({
  name: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
  preview: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
  banner: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
  description: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(2047, INPUT_FORM_ERRORS.STRING_MAX),
  offerLimit: yup.number().nullable().required(INPUT_FORM_ERRORS.REQUIRED).min(0, INPUT_FORM_ERRORS.INT_MIN).max(100, INPUT_FORM_ERRORS.INT_MAX),
  validTo: yup
    .date()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .typeError(INPUT_FORM_ERRORS.DATE_FORMAT)
    .min(new Date(), INPUT_FORM_ERRORS.DATE_MIN_TODAY)
    .nullable()
    .when('validFrom', {
      is: (validFrom: Date | null) => !!validFrom,
      then: yup.date().min(yup.ref('validFrom'), INPUT_FORM_ERRORS.DATE_START_BEFORE),
    }),
  validFrom: yup.date().required(INPUT_FORM_ERRORS.REQUIRED).typeError(INPUT_FORM_ERRORS.DATE_FORMAT).nullable(),
  chains: yup.array(),
  assortmentCategory: yup.object().nullable(),
  clusters: yup.array(),
  isForBrandAmbassadors: yup.boolean().required(),
});

const mapCategoriesToQuery = (values: PromotionFormValues): AssortmentCategoriesForPurchaseInputGqlType[] => {
  const { assortmentCategory, } = values;
  const mappedCategories = assortmentCategory.map((category) => ({ assortmentCategoryId: category.option?.id, count: category.count, }));
  const filteredCategories = mappedCategories.filter((category) => category.assortmentCategoryId) as AssortmentCategoriesForPurchaseInputGqlType[];
  return filteredCategories;
};

export const mapFormToVariables = (values: PromotionFormValues, authUser: AuthUserFragment | null): PromotionInput => ({
  name: values.name,
  description: values.description,
  bannerFileId: values.banner?.id || null,
  imageFileId: values.preview?.id || null,
  offerLimitPerCustomer: values.offerLimit || 0,
  validFrom: values.validFrom || '',
  validTo: values.validTo || '',
  chainIds: authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS ? [] : values?.chains?.map((c) => c.id) || [],
  clusterIds: authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS ? values?.clusters?.map((c) => c.id) || [] : [],
  assortmentCategoriesForPurchase: mapCategoriesToQuery(values),
  isForBrandAmbassadors: values.isForBrandAmbassadors,
  notificationsVisible: values.notificationsVisible,
});
