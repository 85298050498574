import { OrderBy, TaskCompletionSortableFields, } from 'Apollo/graphql';
import { TaskCompletionStatusOption, } from 'Utils/options/useOptionsTaskCompletionStatus';
import { ChangeTableData, ChangeTableFilter, ChangeTableSort, } from 'Utils/types';
import create from 'zustand';

export type ObligatoryTableTasksFilter = {
  status:TaskCompletionStatusOption | null;
  author:string;
  id:string;
  taskId:string;
  name:string;
  description:string;
  pointsForCompletion:number | null;
  };


export type ObligatoryTaskStore = {

  tableTasks: {
    data: {
      sortField: TaskCompletionSortableFields | null;
      sortOrder: OrderBy | null;
      limit: number;
      offset: number;
    };
    filter: ObligatoryTableTasksFilter;
  };
  changeTableData: ChangeTableData;
  changeTableFilter: ChangeTableFilter;
  changeTableSort: ChangeTableSort;
};
export const useObligatoryTaskStore = create<ObligatoryTaskStore>((set) => ({
  tableTasks: {
    data: {
      sortField: null,
      sortOrder: null,
      limit: 10,
      offset: 0,
    },
    filter: {
      status: null,
      author:"",
      id:"",
      taskId:"",
      name:"",
      description:"",
      pointsForCompletion: null,
    },
  },

  /**
   * Task table
   */
  changeTableData: (name, value) =>
    set((state) => ({
      tableTasks: {
        ...state.tableTasks,
        data: {
          ...state.tableTasks.data,
          [name]: value,
        },
      },
    })),
  changeTableFilter: (name, value) =>
    set((state) => ({
      tableTasks: {
        ...state.tableTasks,
        data: {
          ...state.tableTasks.data,
          offset: 0,
        },
        filter: {
          ...state.tableTasks.filter,
          [name]: value,
        },
      },
    })),
  changeTableSort: (sortField, sortOrder) =>
    set((state) => ({
      tableTasks: {
        ...state.tableTasks,
        data: {
          ...state.tableTasks.data,
          sortField: sortField as TaskCompletionSortableFields,
          sortOrder,
        },
      },
    })),
}));
