import { AvailableVoucherType, ChainFragment, OrderBy, TableVoucherFragment, VoucherFragment, VoucherSortableFields, } from 'Apollo/graphql';
import { ChangeTableData, ChangeTableFilter, ChangeTableSort, } from 'Utils/types';
import create from 'zustand';

export type TableVouchersFilter = {
  author: string;
  chainIds: ChainFragment | null;
  description: string;
  id: string;
  isNowAvailable: boolean | null;
  name: string;
  number: number | null;
  priceFiat: number | null;
  pricePoints: number | null;
  type: AvailableVoucherType | null;
};

export type VoucherStore = {
  modalDetailVoucher: {
    isOpen: boolean;
    voucher: TableVoucherFragment | null;
  };
  modalRemoveVoucher: {
    isOpen: boolean;
    voucher: TableVoucherFragment | VoucherFragment | null;
  };
  modalCreateVoucher: {
    isOpen: boolean;
  };
  tableVouchers: {
    data: {
      sortField: VoucherSortableFields | null;
      sortOrder: OrderBy | null;
      limit: number;
      offset: number;
    };
    filter: TableVouchersFilter;
  };

  openModalDetailVoucher: (voucher: TableVoucherFragment) => void;
  closeModalDetailVoucher: () => void;
  openModalCreateVoucher: () => void;
  closeModalCreateVoucher: () => void;
  openModalRemoveVoucher: (voucher: TableVoucherFragment | VoucherFragment) => void;
  closeModalRemoveVoucher: () => void;
  changeTableData: ChangeTableData;
  changeTableFilter: ChangeTableFilter;
  changeTableSort: ChangeTableSort;
};

export const useVoucherStore = create<VoucherStore>((set) => ({
  modalRemoveVoucher: {
    isOpen: false,
    voucher: null,
  },
  modalDetailVoucher: {
    isOpen: false,
    voucher: null,
  },
  modalCreateVoucher: {
    isOpen: false,
  },
  tableVouchers: {
    data: {
      sortField: null,
      sortOrder: null,
      limit: 10,
      offset: 0,
    },
    filter: {
      author: '',
      chainIds: null,
      description: '',
      id: '',
      isNowAvailable: null,
      name: '',
      number: null,
      priceFiat: null,
      pricePoints: null,
      type: null,
    },
  },

  openModalDetailVoucher: (voucher) =>{
    return set({
      modalDetailVoucher: {
        isOpen: true,
        voucher,
      },
    });},
  closeModalDetailVoucher: () =>
    set((state) => ({
      modalDetailVoucher: {
        ...state.modalDetailVoucher,
        isOpen: false,
      },
    })),

  openModalCreateVoucher: () =>
    set({
      modalCreateVoucher: {
        isOpen: true,
      },
    }),
  closeModalCreateVoucher: () =>
    set((state) => ({
      modalCreateVoucher: {
        ...state.modalCreateVoucher,
        isOpen: false,
      },
    })),
  /*
   * Modal Remove
   */
  openModalRemoveVoucher: (voucher) =>
    set({
      modalRemoveVoucher: {
        isOpen: true,
        voucher,
      },
    }),
  closeModalRemoveVoucher: () =>
    set((state) => ({
      modalRemoveVoucher: {
        ...state.modalRemoveVoucher,
        isOpen: false,
        voucher: null,
      },
    })),

  /**
   * Voucher table
   */
  changeTableData: (name, value) =>
    set((state) => ({
      tableVouchers: {
        ...state.tableVouchers,
        data: {
          ...state.tableVouchers.data,
          [name]: value,
        },
      },
    })),
  changeTableFilter: (name, value) =>
    set((state) => ({
      tableVouchers: {
        ...state.tableVouchers,
        data: {
          ...state.tableVouchers.data,
          offset: 0,
        },
        filter: {
          ...state.tableVouchers.filter,
          [name]: value,
        },
      },
    })),
  changeTableSort: (sortField, sortOrder) =>
    set((state) => ({
      tableVouchers: {
        ...state.tableVouchers,
        data: {
          ...state.tableVouchers.data,
          sortField: sortField as VoucherSortableFields,
          sortOrder,
        },
      },
    })),
}));
