import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { AssessmentMethodEnum, SellerFillTaskFragment, SubmitTaskCompletionMutationResult, } from 'Apollo/graphql';
import { FormError, FormSubmit, } from 'Components/Form';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { AssessmentConfig, } from 'Modules/TasksSeller/store/fillTaskStore';
import FormInputs from './FormInputs';

interface Props {
  task: SellerFillTaskFragment,
  assessmentConfig: AssessmentConfig,
  submitTaskMutationResult: SubmitTaskCompletionMutationResult,
  onBack: () => void,
};

const getInfoKey = (task: SellerFillTaskFragment): string => {
  if (task.assessmentMethod === AssessmentMethodEnum.Survey) return 'fill.assessment.infoSurvey';
  if (task.assessmentMethod === AssessmentMethodEnum.Photo) return 'fill.assessment.infoPhoto';
  return 'fill.assessment.infoTest';
};

const Layout = ({
  task,
  assessmentConfig,
  submitTaskMutationResult,
  onBack,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksSeller');
  const info = t(getInfoKey(task));

  return (
    <>
      <Typography
        component="h1"
        variant="h1"
        mb={1.5}
      >
        {task.name}
      </Typography>
      <Typography
        variant="body1"
        mb={4}
      >
        {info}
      </Typography>

      <Paper
        sx={{
          pt: 4,
          pr: 2,
          pb: 4,
          pl: 2,
          mb: 4,
        }}
      >
        <FormInputs
          blocks={task.assessment?.blocks || []}
          assessmentConfig={assessmentConfig}
        />
      </Paper>

      <FormError mb={2} />

      <ErrorAlert
        mb={2}
        error={submitTaskMutationResult.error}
      />

      <Grid container spacing={1.5}>
        <Grid item xs={12} lg="auto">
          <FormSubmit
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
          >
            {t('fill.assessment.btnContinue')}
          </FormSubmit>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            variant="outlined"
            color="primary"
            onClick={onBack}
          >
            {t('common:actions.back')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Layout;
