import { useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import IconButtonSquare from 'Components/IconButtonSquare';
import KeyboardArrowUpIcon from 'Utils/svg/KeyboardArrowUpIcon';
import KeyboardArrowDownIcon from 'Utils/svg/KeyboardArrowDownIcon';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import ProductCard from 'Components/ProductCard';
import { PurchaseDetailFragment, } from 'Apollo/graphql';

export const RootContainer = styled(Box)(({ theme, }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const BoxCollapsed = styled(Box)(({ theme, }) => ({
  paddingTop: theme.spacing(2),
}));

interface Props {
  purchase: PurchaseDetailFragment;
}

const CollapsibleItem = ({ purchase, }: Props) => {
  const [isOpen, setIsOpen,] = useState(false);
  const { t, } = useTranslation('registrationsAdmin');
  const handleToggle = () => setIsOpen((s) => !s);
  const productsExist = purchase.purchasedAssortments.length || purchase.purchasedPromotions.length;

  return (
    <RootContainer key={purchase.id}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={1}>
          <Typography variant="h4">{purchase.id}</Typography>
          <Typography variant="body1">{t('common:date.fullDateTime', { date: new Date(purchase.createdAt), })}</Typography>
        </Stack>

        <IconButtonSquare variant={isOpen ? 'contained' : 'outlined'} onClick={handleToggle}>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButtonSquare>
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <BoxCollapsed>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NotEditableTextClassic label={t('detailRegistration.posId')}>{purchase.posId}</NotEditableTextClassic>
            </Grid>
            <Grid item xs={12}>
              <NotEditableTextClassic label={t('detailRegistration.chainId')}>{purchase.chainId}</NotEditableTextClassic>
            </Grid>
            <Grid item xs={12}>
              <NotEditableTextClassic label={t('detailRegistration.username')}>{purchase.author.username}</NotEditableTextClassic>
            </Grid>
            <Grid item xs={12}>
              <NotEditableTextClassic label={t('detailRegistration.dateOfPurchase')}>
                {t('common:date.fullDateTime', { date: new Date(purchase.createdAt), })}
              </NotEditableTextClassic>
            </Grid>
            <Grid item xs={12}>
              <NotEditableTextClassic label={t('detailRegistration.purchasedProducts')}>
                {productsExist ? (
                  <>
                  {purchase.purchasedPromotions.map((product) =>
                    <ProductCard key={product.promotion.name} title={product.promotion.name} imageSrc={product.promotion.image?.publicUrl} count={product.count} />
                  )}
                  {purchase.purchasedAssortments.map((product) =>
                    <ProductCard key={product.assortment.name} title={product.assortment.name} imageSrc={product.assortment.image?.publicUrl} count={product.count} deviceCode={product.deviceCode}/>
                  )}
                  </>
                ) : (
                  <Typography>
                    {t('detailRegistration.noProducts')}
                  </Typography>
                )}
              </NotEditableTextClassic>
            </Grid>
          </Grid>
        </BoxCollapsed>
      </Collapse>
    </RootContainer>
  );
};

export default CollapsibleItem;
