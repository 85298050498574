import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import StepConnector from 'Components/Stepper/StepConnector';
import StepIcon from 'Components/Stepper/StepIcon';
import { CzGiftCodePhase, } from 'Modules/GiftCodeSeller/store/CzGiftCodeStore';
import { ReactNode, } from 'react';

interface Props {
  children: ReactNode;
  phase: CzGiftCodePhase;
}

const STEP_NUMBERS: { [key in CzGiftCodePhase]: number } = {
  init: 0,
  detail: 1,
  registerCustomer: 2,
  customerVerification: 3,
  complete: 4,
};

const STEPS = [0, 1, 2, 3, 4,];

const LayoutStepper = ({ children, phase, }: Props): JSX.Element => {
  const activeStep = STEP_NUMBERS[phase] || 0;

  return (
    <>
      <Box width="100%" mb={3}>
        <Stepper activeStep={activeStep} connector={<StepConnector />}>
          {STEPS.map((stepKey) => (
            <Step key={stepKey}>
              <StepLabel StepIconComponent={StepIcon} />
            </Step>
          ))}
        </Stepper>
      </Box>
      {children}
    </>
  );
};

export default LayoutStepper;
