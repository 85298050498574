import InputTextClassic, { InputTextClassicProps, } from 'Components/Inputs/InputTextClassic';
import { useInputInt, } from './utils/useInputInt';

export interface InputIntClassicProps extends Omit<InputTextClassicProps, 'onChange' | 'value'> {
  value: number | null,
  onChange: (value: number | null) => void,
}

const InputIntClassic = ({
  value,
  onFocus,
  onBlur,
  onKeyDown,
  onChange,
  ...rest
}: InputIntClassicProps): JSX.Element => {
  const {
    valueInternal,
    onChangeInternal,
    onFocusInternal,
    onBlurInternal,
    onKeyDownInternal,
  } = useInputInt(
    value,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
  );

  return (
    <InputTextClassic
      {...rest}
      value={valueInternal}
      onChange={onChangeInternal}
      onFocus={onFocusInternal}
      onBlur={onBlurInternal}
      onKeyDown={onKeyDownInternal}
    />
  );
};

export default InputIntClassic;
