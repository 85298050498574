import { NodeProps, } from '../types';
import FormInputNodeType from '../FormInputNodeType';
import NodeLayout from '../NodeLayout';

const NodeEmpty = ({
  index,
  onRemove,
  onChangeType,
}: NodeProps) => {


  return (
    <NodeLayout
      index={index}
      onRemove={onRemove}
    >
      <FormInputNodeType
        index={index}
        onChangeType={onChangeType}
      />
    </NodeLayout>
  );
};

export default NodeEmpty;
