import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { AssortmentCategoryWithAssortmentOptionFragment, } from 'Apollo/graphql';
import { FormError, FormSubmit, } from 'Components/Form';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementSeller/store/ReplacementStore';
import ProductsForm from './ProductsForm';

const selectGoToPhase = (s: ReplacementStore) => s.goToPhase; 

interface Props {
  structure: AssortmentCategoryWithAssortmentOptionFragment | null,
};

const View = ({
  structure,
}: Props) => {
  const { t, } = useTranslation('replacementSeller');
  const goToPhase = useReplacementStore(selectGoToPhase);

  const handleBack = () => goToPhase('replacementDetail');

  return (
    <>
      <Typography
        variant="h1"
        sx={{ mb: 1.5, }}
      >
        {t('phaseProducts.title')}
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 5, }}
      >
        {t('phaseProducts.info')}
      </Typography>
      <ProductsForm
        structure={structure}
      />

      <FormError
        mb={2}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} lg="auto">
          <FormSubmit
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
          >
            {t('phaseProducts.submit')}
          </FormSubmit>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            onClick={handleBack}
          >
            {t('common:actions.back')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default View;
