import shallow from 'zustand/shallow';
import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { PointOfSellStore, usePointOfSellStore, } from 'Modules/PointOfSell/store/PointOfSellStore';
import FetchData from './FetchData';

const selectStoreData = (s: PointOfSellStore) =>({
  isOpen: s.modalDetailPointOfSell.isOpen,
  pointOfSell: s.modalDetailPointOfSell.pointOfSell,
  onClose: s.closeModalDetailPointOfSell,
});

const ModalDetailPointOfSell = (): JSX.Element => {
  const { t, } = useTranslation('pos');
  const {
    isOpen,
    pointOfSell,
    onClose,
  } = usePointOfSellStore(selectStoreData, shallow);


  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!pointOfSell}
    >
      <ModalDrawerTitle
        onClose={onClose}
      >
        {t('detailPosModal.title')}
      </ModalDrawerTitle>
      <FetchData
        pointOfSell={pointOfSell}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalDetailPointOfSell;
