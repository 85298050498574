import { useTranslation, } from "react-i18next";
import { Link as LinkRouter, } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AppContainer from "Components/AppContainer";
import { ROUTES, } from "Utils/constants";
import FetchData from "./FetchData";

const TransactionList = (): JSX.Element => {
  const { t, } = useTranslation();

  return (
  <AppContainer maxWidth="sm">
    <Typography variant="h1" mb={1.5}>
        {t('myPoints:transaction.title')}
      </Typography>
    <FetchData />
      <Box mt={2}>
        <Button
          component={LinkRouter}
          to={`/${ROUTES.myPoints}`}
          size="large"
          variant="outlined"
          color="primary"
        >
          {t('common:actions.back')}
        </Button>
      </Box>
  </AppContainer>
  );
};

export default TransactionList;
