import { OrderBy, PointsTransactionSortableFields, useUserPointsQuery, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import LoadingBox from 'Components/LoadingBox';
import View from './View';

interface Props {
    userId: string,
};

const PointsSegment = ({ userId, }: Props): JSX.Element => {
  const { error, loading, data, } = useUserPointsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id:userId,
      filter: {
        authorId: { equals: userId, },
      },
      sort: {
        field: PointsTransactionSortableFields.CreatedAt,
        order: OrderBy.Desc,
      },
    },
  });
  if (loading) return <LoadingBox />;
  if (error || !data?.pointsTransactions) return <ErrorAlert forceError error={error} />;
  return <View data={data} />;
};
export default PointsSegment;
