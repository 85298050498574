import { ReactNode, useEffect, } from 'react';
import shallow from 'zustand/shallow';
import AppContainer from 'Components/AppContainer';
import { RegistrationPhase, RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import LayoutStepper from './LayoutStepper';
import PhaseRegisterCustomer from './PhaseRegisterCustomer';
import PhasePurchase from './PhasePurchase';
import PhaseInformationVerification from './PhaseInformationVerification';
import PhaseCustomerVerification from './PhaseCustomerVerification';
import PhaseRegistrationComplete from './PhaseRegistrationComplete';
import PhaseInit from './PhaseInit';
import PhaseDeviceCodes from './PhaseDeviceCodes';
import PhasePromotionPurchase from './PhasePromotionPurchase';
import PhaseDeviceCodesPromotion from './PhaseDeviceCodesPromotion';
import PhaseChooseVerificationMethod from './PhaseChooseVerificationMethod';

const PHASE_COMPONENTS: { [key in Exclude<RegistrationPhase, 'init'>]: ReactNode } = {
  customerRegistration: <PhaseRegisterCustomer />,
  purchase: <PhasePurchase />,
  customerVerification: <PhaseCustomerVerification />,
  informationVerification: <PhaseInformationVerification />,
  registrationComplete: <PhaseRegistrationComplete />,
  deviceCodes: <PhaseDeviceCodes />,
  promotionPurchase: <PhasePromotionPurchase />,
  deviceCodesPromotion: <PhaseDeviceCodesPromotion />,
  chooseVerificationMethod: <PhaseChooseVerificationMethod />,
};

const selectStoreData = (s: RegistrationStore) => ({
  phase: s.phase,
  resetStore: s.resetStore,
  isShortRegistrationStore: s.isShortRegistration,
  goToPhase: s.goBackToPhase,
});
interface Props {
  isShortRegistration?:boolean
}

const RegistrationSellerPage = ({ isShortRegistration, } : Props) => {
  const { phase, resetStore,isShortRegistrationStore,goToPhase, } = useRegistrationStore(selectStoreData, shallow);

  useEffect(
    () => {
      return () => resetStore(isShortRegistration);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (phase ==='init' && isShortRegistration) {
    goToPhase('purchase');
  }
  if (isShortRegistration !== isShortRegistrationStore) {
    resetStore(isShortRegistration);
  }
  useEffect(
    () => window.scrollTo({ top: 0, }),
    [ phase, ],
  );

  return (
    <AppContainer maxWidth="sm">
      {phase === 'init' ? (
        <PhaseInit />
      ) : (
        <LayoutStepper
          phase={phase}
        >
          {PHASE_COMPONENTS[phase]}
        </LayoutStepper>
      )}
    </AppContainer>
  );
};

export default RegistrationSellerPage;
