import { Grid, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import ActiveInactiveChains from 'Components/Dashboard/Chains/ActiveInactiveChains';
import { activeInactiveChainsQuery, } from 'Components/Dashboard/Chains/queries';
import ChartDetailLayout from 'Components/Dashboard/ChartDetailLayout';
import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartType, } from 'Utils/Charts/types';

const ActiveInactiveChainsPage = () => {
  const { t, } = useTranslation('dashboards');

  const query = useMemo(() => activeInactiveChainsQuery(), []);
  return (
    <AppContainer>
      <ChartDetailLayout pageTitle={t('common:dashboardSidebar.chains')}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActiveInactiveChains showTotal showSlug={false} />
          </Grid>
          <Grid item xs={12}>
            <ChartRenderer
              query={query}
              type={ChartType.table}
              pivotConfig={{
                x: ['chain.count',],
                y: ['measures',],
                fillMissingDates: true,
                joinDateRange: false,
              }}
            />
          </Grid>
        </Grid>
      </ChartDetailLayout>
    </AppContainer>
  );
};

export default ActiveInactiveChainsPage;
