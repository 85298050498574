import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const RePhotoIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    style={{ width: "unset", }}
  >
    <path
      d="M19.2 5.778h-2.853L14.7 4H9.3L7.653 5.778H4.8c-.477 0-.935.187-1.273.52A1.767 1.767 0 0 0 3 7.556v10.666c0 .472.19.924.527 1.257.338.334.796.521 1.273.521h14.4c.477 0 .935-.187 1.273-.52.337-.334.527-.786.527-1.258V7.556c0-.472-.19-.924-.527-1.258a1.812 1.812 0 0 0-1.273-.52zm0 12.444H4.8V7.556h3.645l1.647-1.778h3.816L15.6 7.556h3.6zm-7.2-.889a4.525 4.525 0 0 1-2.7-.889l1.296-1.28a2.717 2.717 0 0 0 3.313-.39c.506-.5.791-1.178.791-1.885a2.65 2.65 0 0 0-.79-1.886 2.717 2.717 0 0 0-1.91-.78c-1.134 0-2.16.71-2.538 1.777H11.1l-2.7 2.667L5.7 12h1.89a4.412 4.412 0 0 1 .68-1.6c.33-.484.754-.899 1.247-1.22a4.53 4.53 0 0 1 3.383-.647 4.506 4.506 0 0 1 2.728 1.72 4.404 4.404 0 0 1-.607 5.934A4.532 4.532 0 0 1 12 17.333z"
      fill="#fff"
    />
  </SvgIcon>
);

export default RePhotoIcon;
