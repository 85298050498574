import { ReactNode, useState, } from 'react';
import { styled, } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import IconButtonSquare from 'Components/IconButtonSquare';
import MinusIcon from 'Utils/svg/MinusIcon';
import PlusIcon from 'Utils/svg/PlusIcon';

const CardContainer = styled(Card)(({ theme, }) => ({
  marginBottom: '1px',
  borderRadius: 0,
}));

interface Props {
  title?: ReactNode;
  children?: ReactNode;
  disabled?: boolean;
}

const CardItem = ({ title, children, disabled, }: Props): JSX.Element => {
  const [isOpen, setIsOpen,] = useState(false);
  const handleToggle = () => setIsOpen((s) => !s);

  return (
    <CardContainer>
      <Box p={2} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" color="primary" mr={2}>
          {title}
        </Typography>
        <IconButtonSquare variant={isOpen ? 'contained' : 'outlined'} onClick={handleToggle} disabled={disabled}>
          {isOpen ? <MinusIcon /> : <PlusIcon />}
        </IconButtonSquare>
      </Box>
      <Collapse in={isOpen}>
        <Typography variant="body1" p={2} pt={0} whiteSpace='pre-line'>
          {children}
        </Typography>
      </Collapse>
    </CardContainer>
  );
};

export default CardItem;
