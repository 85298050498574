import { ApolloError, } from '@apollo/client/errors';
import { PurchasedProductsForGqlDbOptionFragment, usePurchasedProductsForGlobalDbOptionsQuery, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

type GetOptionLabel = (o: PurchasedProductsForGqlDbOptionFragment) => string;
type GetOptionKey = (o: PurchasedProductsForGqlDbOptionFragment) => string;

type UseOptionsPurchasedProduct = () => {
  options: PurchasedProductsForGqlDbOptionFragment[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<PurchasedProductsForGqlDbOptionFragment>, 
  loading:boolean,
  error: ApolloError | undefined,
};

export const useOptionsPurchasedProduct: UseOptionsPurchasedProduct = () => {
  const getOptionLabel = (o: PurchasedProductsForGqlDbOptionFragment) => o.label;
  const { loading, error, data, } = usePurchasedProductsForGlobalDbOptionsQuery();

  return {
    options: data?.purchasedProductsForGlobalDBOptions.options || [],
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
    loading,
    error,
  };
};
