import create from 'zustand';
import { PurchaseDetailFragment, OrderBy, PurchaseSortableFields, ChainFragment, PointOfSellOptionFragment, } from 'Apollo/graphql';
import { ChangeTableFilter, ChangeTableSort, ChangeTableData, } from 'Utils/types';

export type TablePurchasesFilter = {
  chain: ChainFragment | null;
  pos: PointOfSellOptionFragment | null;
  author: string;
  email: string;
  deviceCode: string;
  promotionId: string;
  promotionName: string;
  assortmentId: string;
  assortmentName: string;
};

export type PurchasesStore = {
  modalExportPurchase: {
    isOpen: boolean;
  };
  modalDetailPurchase: {
    isOpen: boolean;
    purchase: PurchaseDetailFragment | null;
  };
  tablePurchases: {
    data: {
      sortField: PurchaseSortableFields | null;
      sortOrder: OrderBy | null;
      limit: number;
      offset: number;
    };
    filter: TablePurchasesFilter;
  };

  openModalDetailPurchase: (purchase: PurchaseDetailFragment) => void;
  closeModalDetailPurchase: () => void;
  changeTableData: ChangeTableData;
  changeTableFilter: ChangeTableFilter;
  changeTableSort: ChangeTableSort;
  openModalExportPurchase: () => void;
  closeModalExportPurchase: () => void;
};

export const usePurchasesStore = create<PurchasesStore>((set) => ({
  modalExportPurchase: {
    isOpen: false,
  },
  modalDetailPurchase: {
    isOpen: false,
    purchase: null,
  },
  tablePurchases: {
    data: {
      sortField: null,
      sortOrder: null,
      limit: 10,
      offset: 0,
    },
    filter: {
      chain: null,
      pos: null,
      author: '',
      email: '',
      deviceCode: '',
      promotionId: '',
      promotionName: '',
      assortmentId: '',
      assortmentName: '',
    },
  },
  /*
   * Modal Export
   */
  openModalExportPurchase: () =>
    set({
      modalExportPurchase: {
        isOpen: true,
      },
    }),
  closeModalExportPurchase: () =>
    set({
      modalExportPurchase: {
        isOpen: false,
      },
    }),
  /*
   * Modal Detail
   */
  openModalDetailPurchase: (purchase) =>
    set({
      modalDetailPurchase: {
        isOpen: true,
        purchase,
      },
    }),
  closeModalDetailPurchase: () =>
    set((state) => ({
      modalDetailPurchase: {
        ...state.modalDetailPurchase,
        isOpen: false,
      },
    })),

  /**
   * Registration table
   */
  changeTableData: (name, value) =>
    set((state) => ({
      tablePurchases: {
        ...state.tablePurchases,
        data: {
          ...state.tablePurchases.data,
          [name]: value,
        },
      },
    })),
  changeTableFilter: (name, value) => {
    return set((state) => ({
      tablePurchases: {
        ...state.tablePurchases,
        data: {
          ...state.tablePurchases.data,
          offset: 0,
        },
        filter: {
          ...state.tablePurchases.filter,
          [name]: value,
        },
      },
    }));
  },
  changeTableSort: (sortField, sortOrder) =>
    set((state) => ({
      tablePurchases: {
        ...state.tablePurchases,
        data: {
          ...state.tablePurchases.data,
          sortField: sortField as PurchaseSortableFields,
          sortOrder,
        },
      },
    })),
}));
