import { yupResolver, } from '@hookform/resolvers/yup';
import { RoleEnum, useCreateOrUpdateUserTaxInfoMutation, UserTaxInformationFragment, } from 'Apollo/graphql';
import { useSnackbar, } from 'notistack';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import {
  InvoiceTaxFormValues,
  PITTaxFormValues,
  taxInformationValidationSchemaInvoice,
  taxInformationValidationSchemaPIT,
  useInvoiceTaxFormInitValues,
  usePITTaxFormInitValues,
} from '../utils/editMyTaxInfoForm';
import View from './View';

type Props = {
  data: UserTaxInformationFragment | null | undefined;
};

const Logic = ({ data, }: Props): JSX.Element => {
  const { t, } = useTranslation('myProfile');
  const authUser = useAuthUser();
  const formIsInvoice = authUser?.roleCode !== RoleEnum.IndependentPosOwner;
  const initValuesPIT = usePITTaxFormInitValues(data);
  const initValuesInvoice = useInvoiceTaxFormInitValues(data);
  
  const { enqueueSnackbar, } = useSnackbar();
  const methods = useForm({
    defaultValues: formIsInvoice ? initValuesInvoice : initValuesPIT,
    resolver: yupResolver(formIsInvoice ? taxInformationValidationSchemaInvoice : taxInformationValidationSchemaPIT),
    mode: 'onTouched',
  });

  const [editMutation, editMutationResult,] = useCreateOrUpdateUserTaxInfoMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('editedMessageTitle') as string,
        message: t('editedMessage') as string,
        variant: 'common',
      });
    },
    onError: () => {
      // TODO: merge form errors
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    const mappedValues = {
      ...data,
      ...values as InvoiceTaxFormValues | PITTaxFormValues,
      accountNumber: values.accountNumber,
      phonePrefix: values.phonePrefix?.id || '',
      taxNumber: values.taxNumber,
      typeOfPayment: formIsInvoice ? "comboBox" : "switch", 
    };
    // eslint-disable-next-line no-underscore-dangle
    delete mappedValues.__typename;
    await editMutation({
      variables: {
        userTaxInformationInput: mappedValues,
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View editMutationResult={editMutationResult} formIsInvoice={formIsInvoice} />
      </form>
    </FormProvider>
  );
};

export default Logic;
