import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const ChristmasPrizeWheel = (props: SvgIconProps): JSX.Element => (
  <SvgIcon viewBox="0 0 24 24" {...props} style={{ width: 'unset', fill: 'none', }}>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12.0002" cy="12.0034" r="12" transform="rotate(33.8779 12.0002 12.0034)" fill="white"/>
<path d="M21.9629 18.6925C21.0845 20.0008 19.957 21.1233 18.6448 21.9959C17.3325 22.8685 15.8613 23.474 14.315 23.778L12.0002 12.0034L21.9629 18.6925Z" fill="#BB0B0B"/>
<path d="M18.6893 2.04066C21.3316 3.81471 23.1609 6.56575 23.7748 9.68858L12.0002 12.0034L18.6893 2.04066Z" fill="#BB0B0B"/>
<path d="M2.03747 5.31429C2.91589 4.00597 4.04341 2.88347 5.35564 2.0109C6.66788 1.13832 8.13913 0.532753 9.68539 0.228768L12.0002 12.0034L2.03747 5.31429Z" fill="#BB0B0B"/>
<path d="M5.3111 21.9661C2.66883 20.1921 0.839508 17.441 0.225582 14.3182L12.0002 12.0034L5.3111 21.9661Z" fill="#BB0B0B"/>
<circle cx="12" cy="12" r="11" stroke="url(#paint0_linear_8811_91038)" strokeWidth="2"/>
<circle cx="12" cy="12" r="2" fill="url(#paint1_linear_8811_91038)"/>
<defs>
<linearGradient id="paint0_linear_8811_91038" x1="5.71893" y1="1.79806" x2="18.2048" y2="22.0876" gradientUnits="userSpaceOnUse">
<stop stopColor="#AC583D"/>
<stop offset="1" stopColor="#D79574"/>
</linearGradient>
<linearGradient id="paint1_linear_8811_91038" x1="10.9532" y1="10.2997" x2="13.0341" y2="13.6813" gradientUnits="userSpaceOnUse">
<stop stopColor="#AC583D"/>
<stop offset="1" stopColor="#D79574"/>
</linearGradient>
</defs>
</svg>

  </SvgIcon>
);

export default ChristmasPrizeWheel;
