import { useFormContext, useWatch, } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import TrashIcon from 'Utils/svg/TrashIcon';
import { Checkbox, } from 'Components/Checkbox';
import { FormInputText, } from 'Components/Form';
import { NodeProps, } from '../../types';
import { buildNodeInputAnswerName, } from '../../utils';
import { ObjectValue, } from './types';

interface Props extends Pick<NodeProps, 'assessmentConfig'> {
  nodeIndex: number,
  rowAnswerIndex: number,
  objValue: ObjectValue,
  onRemove: (rowAnswerIndex: number, rowAnswerId: string) => void,
  onSelectCorrectAnswer: (rowId: string | null, isChecked: boolean) => void,
};

const AnswerRow = ({
  nodeIndex,
  rowAnswerIndex,
  assessmentConfig,
  objValue,
  onRemove,
  onSelectCorrectAnswer,
}: Props): JSX.Element => {
  const { enableCorrectAnswer, } = assessmentConfig;
  const { control, } = useFormContext();
  const answerRowId = useWatch({ control, name: buildNodeInputAnswerName(nodeIndex, rowAnswerIndex, 'id'), });
  
  const isChecked = !!objValue[answerRowId];

  const handleRemove = () => {
    onRemove(rowAnswerIndex, answerRowId);
  };
  const handleSelectCorrectAnswer = () => onSelectCorrectAnswer(answerRowId, !isChecked);

  return (
    <Grid container item spacing={2}>
      <Grid item xs={enableCorrectAnswer ? 9 : 12} display="flex" alignItems="center">
        <Typography
          component="div"
          mr={0.75}
        >
          {`${rowAnswerIndex + 1}.`}
        </Typography>
        <FormInputText
          name={buildNodeInputAnswerName(nodeIndex, rowAnswerIndex, 'answer')}
        />
        <IconButton
          sx={{ marginLeft: 0.25, }}
          color="inherit"
          onClick={handleRemove}
        >
          <TrashIcon sx={{ fontSize: '1rem', }} />
        </IconButton>
      </Grid>
      {enableCorrectAnswer && (
        <Grid item xs={3} display="flex" alignItems="center">
          <FormControlLabel
            value={answerRowId}
            label={`${rowAnswerIndex + 1}.`}
            control={(
              <Checkbox
                checked={isChecked}
                onChange={handleSelectCorrectAnswer}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AnswerRow;
