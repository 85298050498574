import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const UsersColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#users-colored)">
      <path
        d="M8.247 19.502H2.649c-.539 0-.67-.135-.646-.663a6.269 6.269 0 0 1 7.445-5.903c2.83.52 5.02 3.136 5.048 6.03 0 .375-.156.53-.564.53-1.896.006-3.79.008-5.685.006ZM8.26 12.408a3.956 3.956 0 1 1 3.934-3.965 3.935 3.935 0 0 1-3.935 3.965Z"
        fill="#000"
      />
      <path
        d="M15.71 12.407c-1 .006-1.858-.375-2.607-1.03-.25-.217-.286-.427-.149-.712a4.952 4.952 0 0 0 .404-3.134 6.45 6.45 0 0 0-.395-1.243c-.125-.313-.109-.53.155-.77a3.95 3.95 0 1 1 2.593 6.889ZM18.866 19.502h-2.529c-.44 0-.575-.131-.59-.576a7.409 7.409 0 0 0-2.001-4.92c-.425-.463-.333-.75.283-.913 2.94-.787 5.727.382 7.227 3.045.482.882.737 1.871.74 2.877.01.33-.17.49-.54.49-.862 0-1.726-.003-2.59-.003Z"
        fill="#D47E5F"
      />
    </g>
    <defs>
      <clipPath id="users-colored">
        <path
          fill="#fff"
          transform="translate(2 4.496)"
          d="M0 0h20v15.008H0z"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default UsersColoredIcon;
