import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const PointsTransactionColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M13.246 2h6.749C21.209 2 22 2.776 22 3.99v13.496c0 1.232-.779 2.012-2.014 2.013H6.52c-1.252 0-2.018-.783-2.018-2.038V4.025C4.503 2.772 5.276 2 6.532 2h6.714ZM5.75 10.75v6.654c0 .626.211.847.835.847h13.336c.608 0 .828-.218.828-.824V4.058c0-.593-.215-.808-.804-.81h-13.4c-.575 0-.795.217-.795.786v6.717Z"
      fill="#000"
    />
    <path
      d="M2 12.65V5.216c0-.56.465-.89.908-.627.155.093.245.31.331.488.025.1.029.206.01.308v14.498c0 .668.202.868.877.868h14.465c.114-.005.229-.003.343.007a.616.616 0 0 1 .564.604.642.642 0 0 1-.575.633 2.367 2.367 0 0 1-.282 0H4.115c-1.363 0-2.114-.752-2.115-2.1V12.65Z"
      fill="#000"
    />
    <path
      d="M15.733 13.25V8.266l-1.306.97c-.08.065-.163.124-.25.178a.626.626 0 0 1-.806-.182.59.59 0 0 1 .095-.817 20.18 20.18 0 0 1 1.678-1.247c.803-.508 1.844.125 1.854 1.108.014 1.467 0 2.936 0 4.404v.57c.211 0 .405-.006.598 0 .392.016.65.263.65.616a.626.626 0 0 1-.641.626c-.812.01-1.629.011-2.436 0-.41 0-.673-.276-.669-.634.004-.359.272-.6.688-.61.177-.002.35.002.545.002ZM10.75 12v1.734c0 .475-.251.77-.635.763-.383-.006-.614-.293-.615-.752v-1.747H7.675a.626.626 0 0 1-.627-.389c-.104-.234-.081-.477.105-.633.16-.127.353-.202.555-.217.58-.027 1.163-.01 1.787-.01 0-.626-.007-1.238 0-1.848.008-.518.521-.833.932-.547.17.142.278.344.301.564.043.502.014 1.003.014 1.498v.333h1.803c.382 0 .633.195.689.51a.637.637 0 0 1-.653.736c-.501.01-1.002 0-1.503 0l-.329.005Z"
      fill="#D47E5F"
    />
  </SvgIcon>
);

export default PointsTransactionColoredIcon;

