import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TrashIcon from 'Utils/svg/TrashIcon';
import { NodeProps, } from './types';

interface Props extends Pick<NodeProps, 'index' | 'onRemove'> {
  children: ReactNode,
}

const NodeLayout = ({
  index,
  onRemove,
  children,
}: Props) => {
  const { t, } = useTranslation('tasksAdmin');
  const handleRemove = () => onRemove(index);

  return (
    <Paper sx={{ padding: 2, }}>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography
          variant="h3"
          color="primary"
          mr={1}
        >
          {t('creation.contentPhase.blockTitle', { position: index + 1, })}
        </Typography>
        <IconButton
          color="inherit"
          onClick={handleRemove}
        >
          <TrashIcon sx={{ fontSize: '1rem', }} />
        </IconButton>
      </Box>

      {children}

    </Paper>
  );
};

export default NodeLayout;
