import { useRef, useEffect, RefObject, } from 'react';

const useOutsideClick = (callback: () => void, openerSelector: string = ''): RefObject<HTMLDivElement> => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const target = event.target as Node;
            const openerElement = openerSelector ? document.querySelector(openerSelector) : null;

            if (ref.current && !ref.current.contains(target) && (!openerElement || !openerElement.contains(target))) {
                callback();
            }
        };

        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [callback, openerSelector,]);

    return ref;
};

export default useOutsideClick;