import { PaginationInput, NewsArticleFilterInput, NewsArticleSortInput, } from 'Apollo/graphql';
import { NewsArticlesStore, } from 'Modules/NewsArticlesAdmin/store/NewsArticlesStore';

export const mapVariables = (storeData: NewsArticlesStore['tableNewsArticles']): {
  filter?: NewsArticleFilterInput,
  pagination?: PaginationInput,
  sort?: NewsArticleSortInput,
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    id: storeData.filter.newsArticleId 
      ? { contains: storeData.filter.newsArticleId, }
      : undefined,
    title: storeData.filter.title
      ? { contains: storeData.filter.title, }
      : undefined,
    description: storeData.filter.description
      ? { contains: storeData.filter.description, }
      : undefined,
    chainIds: storeData.filter.chainId?.id
      ? { has: storeData.filter.chainId?.id, }
      : undefined,
    authorId: storeData.filter.authorId
      ? { contains: storeData.filter.authorId, }
      : undefined,
  },
  sort: storeData.data.sortField && storeData.data.sortOrder
    ? {
      field: storeData.data.sortField,
      order: storeData.data.sortOrder,
    } : undefined,
});
