import { NotificationType, NotificationsForCurrentUserDocument, useResetNotificationCountForCategoryMutation, } from 'Apollo/graphql';
import { useCallback, useEffect, } from 'react';

export const useDelayedResetNotificationCategoryCount = (type: NotificationType) => {
  const [resetNotificationCountForCategoryMutation,] = useResetNotificationCountForCategoryMutation();
  const resetCategory = useCallback(() => {
    resetNotificationCountForCategoryMutation({
      variables: {
        category: type,
      },
      refetchQueries: [NotificationsForCurrentUserDocument,],
      // onCompleted: () => refetchNotificationsForCurrentUser(),
    });
  }, [resetNotificationCountForCategoryMutation, type,]);

  useEffect(() => {
    // the delay is there for the case when the user misclicks
    const timeout = setTimeout(resetCategory, 2000);

    return () => clearTimeout(timeout);
    // run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
