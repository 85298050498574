import { yupResolver, } from '@hookform/resolvers/yup';
import { PromotionAvailabilityFragment, PurchaseProductsAvailabilityFragment, } from 'Apollo/graphql';
import { PromotionCategoryPurchaseObject, RegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { useMemo, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useAuthUser, } from 'Tools/auth';
import { FormConfig, } from './types';
import { usePurchaseForm, } from './utils';
import View from './View';

interface Props {
  purchase: RegistrationStore['purchase'],
  purchaseProductsAvailability: PurchaseProductsAvailabilityFragment,
  goBack: () => void,
  completePurchasePhase: RegistrationStore['completePurchasePhase'],
  setActivePromotionPurchase: (index: number, id: string) => void,
  promotionCategoryPurchaseList: PromotionCategoryPurchaseObject;
  isShortRegistration?: boolean,
  handleChangeInPromotionPurchaseForm: (option: PromotionAvailabilityFragment,toggle: "add" | "remove" ) => void,
};

const Logic = ({
  purchase,
  purchaseProductsAvailability,
  goBack,
  completePurchasePhase,
  setActivePromotionPurchase,
  promotionCategoryPurchaseList,
  isShortRegistration,
  handleChangeInPromotionPurchaseForm,
}: Props): JSX.Element => {
  const authUser = useAuthUser();
  const formConfig = useMemo<FormConfig>(
    () => ({
      enablePromotions: !!authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PROMOTIONS,
    }),
    [ authUser?.featureFlagsObject, ],
  );
  const {
    initValues,
    validationSchema,
    structure,
  } = usePurchaseForm(purchaseProductsAvailability, purchase, formConfig, promotionCategoryPurchaseList);
  
  const methods = useForm({
    defaultValues: initValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const handleSubmit = methods.handleSubmit((values) => {
    completePurchasePhase({
      promotions: values.values.promotions,
      assortmentCategories: values.values.assortmentCategories,
    }, 'deviceCodes');
  });
  const formValues = methods.watch();
  const handleSubmitForPromotion = () => {
    completePurchasePhase(formValues.values, 'promotionPurchase');
  ;};

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <View
          goBack={goBack}
          structure={structure}
          formConfig={formConfig}
          handleSubmitForPromotion={handleSubmitForPromotion}
          setActivePromotionPurchase={setActivePromotionPurchase}
          promotionCategoryPurchaseList={promotionCategoryPurchaseList}
          isShortRegistration={isShortRegistration}
          handleChangeInPromotionPurchaseForm={handleChangeInPromotionPurchaseForm}
        />
      </form>
    </FormProvider>
  );
};

export default Logic;
