import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const H2Icon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    {...props}
    fill="none"
  >
    <path
      d="M9.547 16.25V4.586H7.975v4.94H2.199V4.587H.625V16.25h1.572v-5.34h5.776v5.34h1.575Zm3.778-8.416v-.06c0-1.111.788-2.085 2.145-2.085 1.196 0 2.094.76 2.094 1.965 0 1.069-.693 1.88-1.334 2.606l-4.391 4.999v.991h7.536v-1.367h-5.306v-.094l3.101-3.555c1.094-1.248 1.982-2.23 1.982-3.691 0-1.83-1.443-3.195-3.648-3.195-2.426 0-3.708 1.657-3.708 3.425v.06h1.529Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default H2Icon;
