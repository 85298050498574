/* eslint-disable @typescript-eslint/no-explicit-any */
import { TFunction, } from 'react-i18next';
import { PickersLocaleText, CalendarPickerView, } from '@mui/x-date-pickers';

export const getLocalText = (t: TFunction<"common", undefined>): PickersLocaleText<any> => ({
  // Calendar navigation
  previousMonth: t('inputDateTime.calendarNavigation.previousMonth'),
  nextMonth: t('inputDateTime.calendarNavigation.nextMonth'),

  // View navigation
  openPreviousView: t('inputDateTime.viewNavigation.openPreviousView'),
  openNextView: t('inputDateTime.viewNavigation.openNextView'),
  calendarViewSwitchingButtonAriaLabel: (view: CalendarPickerView) =>
    view === 'year'
      ? 'year view is open, switch to calendar view'
      : 'calendar view is open, switch to year view',

  // DateRange placeholders
  start: t('inputDateTime.dateRangePlaceholders.start'),
  end: t('inputDateTime.dateRangePlaceholders.end'),

  // Action bar
  cancelButtonLabel: t('inputDateTime.actionBar.cancelButtonLabel'),
  clearButtonLabel: t('inputDateTime.actionBar.clearButtonLabel'),
  okButtonLabel: t('inputDateTime.actionBar.okButtonLabel'),
  todayButtonLabel: t('inputDateTime.actionBar.todayButtonLabel'),

  // Clock labels
  clockLabelText: (view, time, adapter) => time === null
    ? t('inputDateTime.clockLabels.ClockLabelTextNotSelectedTime', { view,})
    : t('inputDateTime.clockLabels.ClockLabelTextSelectedTime', { view, time: adapter.format(time, 'fullTime'), }),
  hoursClockNumberText: (hours) => t('inputDateTime.clockLabels.hoursClockNumberText', { hours, }),
  minutesClockNumberText: (minutes) => t('inputDateTime.clockLabels.minutesClockNumberText', { minutes, }),
  secondsClockNumberText: (seconds) => t('inputDateTime.clockLabels.secondsClockNumberText', { seconds, }),

  // Open picker labels
  openDatePickerDialogue: (rawValue, utils) =>
    rawValue && utils.isValid(utils.date(rawValue))
      ? t('inputDateTime.openPickerLabels.openDatePickerDialogue', { date: utils.format(utils.date(rawValue)!, 'fullDate'), })
      : t('inputDateTime.openPickerLabels.openDatePickerDialogueNotSelected'),
  openTimePickerDialogue: (rawValue, utils) =>
    rawValue && utils.isValid(utils.date(rawValue))
      ? t('inputDateTime.openPickerLabels', { time: utils.format(utils.date(rawValue)!, 'fullTime'), })
      : t('inputDateTime.openPickerLabels.openTimePickerDialogueNotSelected'),

  // Table labels
  timeTableLabel: t('inputDateTime.tableLabels.timeTableLabel'),
  dateTableLabel: t('inputDateTime.tableLabels.dateTableLabel'),
});
