import { ErrorCode, FileRejection, } from 'react-dropzone';
import type { InputFormErrorMessage, } from 'Components/Form';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { ApolloError, } from '@apollo/client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ErrorMapping = { [key in ErrorCode]: (params?: any) => InputFormErrorMessage };

const ERROR_MAPPING: ErrorMapping = {
  [ErrorCode.FileInvalidType]: INPUT_FORM_ERRORS.FILE_TYPE,
  [ErrorCode.FileTooLarge]: INPUT_FORM_ERRORS.FILE_SIZE_MAX,
  [ErrorCode.FileTooSmall]: INPUT_FORM_ERRORS.FILE_SIZE_MIN,
  [ErrorCode.TooManyFiles]: INPUT_FORM_ERRORS.FILE_COUNT_MAX,
};

type ValidationParams = {
  fileTypes?: string,
  maxSize: number,
  // minSize: number,
  // countMax: number,
};

export const parseFileApolloError = (error: ApolloError): InputFormErrorMessage => {
  // TODO some logic - error parser from errors
  return INPUT_FORM_ERRORS.FILE_UPLOAD_ERROR();
};

const formatSize = (size: number): string => {
  if (size < 1_000) return `${size} B`;
  if (size < 1_000_000) return `${Math.floor(size / 1_000)} KB`;
  return `${Math.floor(size) / 1_000_000} MB`;
};

export const mapFileError = (fileRejection: FileRejection, validationParams: ValidationParams): InputFormErrorMessage => {
  const { errors, } = fileRejection;

  if (errors.length > 0 && Object.prototype.hasOwnProperty.call(ERROR_MAPPING, errors[0].code)) {
    return ERROR_MAPPING[errors[0].code as ErrorCode]({
      fileTypes: validationParams.fileTypes,
      maxSize: formatSize(validationParams.maxSize),
      minSize: formatSize(0),
      countMax: 1,
    });
  }
  return INPUT_FORM_ERRORS.UNKNOWN();
};
