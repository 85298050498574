import { Query, } from '@cubejs-client/core';
import { QueryParams, } from '../types';

export const reDoiQuery = ({
  timeDimensions,
  filters = [],
}: QueryParams): Query => ({
  measures: ['customer.total_registrations',],
  dimensions: ['customer.verificationMethod',],
  timeDimensions: [
    {
      dateRange: timeDimensions?.[0]?.dateRange,
      dimension: timeDimensions?.[0]?.dimension ?? 'customer.createdat',
      granularity: timeDimensions?.[0]?.granularity ?? 'year',
    },
  ],
  order: {
    'customer_verification.count': 'desc',
  },
  filters: [
    ...filters,
    {
      member: 'customer.posId',
      operator: 'equals',
      values: ['GLOBAL-API',],
    },
    {
      member: 'customer.verificationMethod',
      operator: 'set',
    },
  ],
});

export const genderTitleQuery = (): Query => ({
  measures: ['customer.total_registrations',],
  timeDimensions: [
    {
      dimension: 'customer.dob',
    },
  ],
  order: {
    'customer.total_registrations': 'desc',
  },
  dimensions: ['customer.genderTitle',],
  filters: [
    {
      member: 'customer.posId',
      operator: 'notEquals',
      values: ['GLOBAL-API',],
    },
  ],
});

export const methodOfVerificationQuery = ({
  timeDimensions,
  filters = [],
}: QueryParams): Query => ({
  measures: ['customer.total_registrations',],
  timeDimensions: [
    {
      dateRange: timeDimensions?.[0]?.dateRange,
      dimension: timeDimensions?.[0]?.dimension || 'customer.createdat',
      granularity: timeDimensions?.[0]?.granularity || 'year',
    },
  ],
  order: {
    'customer.total_registrations': 'desc',
  },
  dimensions: ['customer.verificationMethod',],
  filters: [
    ...filters,
    {
      member: 'customer.posId',
      operator: 'notEquals',
      values: ['GLOBAL-API',],
    },
    {
      member: 'customer.verificationMethod',
      operator: 'set',
    },
  ],
});


export const previouslyOwnedDeviceQuery = (): Query => ({
  measures: ['customer.total_registrations',],
  timeDimensions: [
    {
      dimension: 'customer.dob',
    },
  ],
  order: {
    'customer.total_registrations': 'desc',
  },
  dimensions: ['customer.previouslyOwnedDevices',],
  filters: [
    {
      member: 'customer.posId',
      operator: 'notEquals',
      values: ['GLOBAL-API',],
    },
  ],
});

export const purchasedProductQuery = ({
  timeDimensions,
  filters = [],
}: QueryParams): Query => ({
  measures: ['customer.total_registrations',],
  timeDimensions: [
    {
      dateRange: timeDimensions?.[0]?.dateRange,
      dimension: timeDimensions?.[0]?.dimension || 'customer.createdat',
    },
  ],
  order: {
    'customer.total_registrations': 'desc',
  },
  dimensions: ['customer.purchasedProductsForGlobalDB',],
  filters: [
    ...filters,
    {
      member: 'customer.posId',
      operator: 'notEquals',
      values: ['GLOBAL-API',],
    },
  ],
});

export const topRegistrationsByCitiesQuery = (): Query => ({
  measures: ['customer.total_registrations',],
  timeDimensions: [
    {
      dimension: 'customer.dob',
    },
  ],
  order: {
    'customer.total_registrations': 'desc',
  },
  dimensions: ['point_of_sell.city',],
  filters: [
    {
      member: 'customer.posId',
      operator: 'notEquals',
      values: ['GLOBAL-API',],
    },
  ],
});

export const splitOfTotalRegistrationsQuery = (): Query => ({
  measures: ['customer.total_registrations',],
  order: [['customer.age', 'asc',],],
  dimensions: ['customer.age',],
  filters: [
    {
      member: 'customer.posId',
      operator: 'notEquals',
      values: ['GLOBAL-API',],
    },
  ],
});

export const splitOfTotalRegistrationsByRegistrationAgeQuery = (): Query => ({
  measures: ['customer.total_registrations',],
  order: [['customer.registrationAge', 'asc',],],
  dimensions: ['customer.registrationAge',],
  filters: [
    {
      member: 'customer.posId',
      operator: 'notEquals',
      values: ['GLOBAL-API',],
    },
  ],
});

export const totalNumberOfRegistrationsQuery = ({
  timeDimensions,
  filters = [],
}: QueryParams): Query => ({
  measures: ['customer.total_registrations',],
  dimensions: ['customer.registration_type'],
  timeDimensions: [
    {
      dateRange: timeDimensions?.[0]?.dateRange,
      dimension: timeDimensions?.[0]?.dimension || 'customer.createdat',
      granularity: timeDimensions?.[0]?.granularity || 'year',
    },
  ],
  order: {
    'customer.createdat': 'asc',
  },
  filters: [
    ...filters,
    {
      member: 'customer.posId',
      operator: 'notEquals',
      values: ['GLOBAL-API',],
    },
  ],
});

export const totalNumberOfRepurchasesQuery = ({
  timeDimensions,
  filters = [],
}: QueryParams): Query => ({
  measures: ['purchase.count',],
  dimensions: ['purchase.is_customers_first_purchase',],
  timeDimensions: [
    {
      dateRange: timeDimensions?.[0]?.dateRange,
      dimension: timeDimensions?.[0]?.dimension || 'purchase.createdat',
      granularity: timeDimensions?.[0]?.granularity || 'year',
    },
  ],
  order: {
    'purchase.createdat': 'desc',
  },
  filters: [
    ...filters,
  ],
});