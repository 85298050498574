import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSnackbar, } from 'notistack';
import { NewsArticleFragment, NewsArticleTableFragment, useDeleteNewsArticleMutation, } from 'Apollo/graphql';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import { updateNewsArticleRemove, } from 'Modules/NewsArticlesAdmin/utils/newsArticlesCache';
import View from './View';

interface Props {
  newsArticle?: NewsArticleTableFragment | NewsArticleFragment | null,
  onCloseRemove: () => void,
  onCloseEdit: () => void,
};

const Logic = ({
  newsArticle,
  onCloseRemove,
  onCloseEdit,
}: Props): JSX.Element => {
  const preservedNewsArticle = usePreserveValue(newsArticle);
  const { t, } = useTranslation('newsArticlesAdmin');
  const { enqueueSnackbar, } = useSnackbar();

  const [ removeMutation, removeMutationResult, ] = useDeleteNewsArticleMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('removeNewsArticleModal.notifRemovedTitle') as string,
        message: t('removeNewsArticleModal.notifRemovedMessage', { id: preservedNewsArticle.id, }),
        variant: 'common',
      });
      onCloseRemove();
      onCloseEdit();
    },
    update: updateNewsArticleRemove,
  });

  const handleSubmit = useCallback(
    () => {
      removeMutation({
        variables: { id: preservedNewsArticle.id, },
      });
    },
    [ preservedNewsArticle.id, removeMutation, ],
  );

  return (
    <View
      newsArticle={preservedNewsArticle}
      removeMutationResult={removeMutationResult}
      onClose={onCloseRemove}
      onSubmit={handleSubmit}
    />
  );
};

export default Logic;
