import { MouseEventHandler, } from 'react';
import { useTranslation, } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import { AuthUserFragment, Claim, PromotionFragment, } from 'Apollo/graphql';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellImage from 'Components/Table/TableCellImage';
import TableCellText from 'Components/Table/TableCellText';

interface Props {
  row: PromotionFragment
  authUser: AuthUserFragment | null,
  onEdit: (promotion: PromotionFragment) => void,
  onRemove: (promotion: PromotionFragment) => void,
};

const PromotionsTableRow = ({
  row,
  authUser,
  onEdit,
  onRemove,
}: Props): JSX.Element => {
  const { t, } = useTranslation('assortmentAdmin');

  const handleRemove: MouseEventHandler<HTMLLIElement> = () => {
    onRemove(row);
  };

  const handleEdit: MouseEventHandler<HTMLLIElement> = () => {
    onEdit(row);
  };

  return (
    <TableRow key={row.id}>
      <TableCellText>
        {row.id}
      </TableCellText>
      <TableCellText>
        {row.name || '-'}
      </TableCellText>
      <TableCellText>
        {row.description || '-'}
      </TableCellText>
      <TableCellImage
        src={row.image?.publicUrl}
      />
      <TableCellText>
        {row.offerLimitPerCustomer}
      </TableCellText>
      <TableCellText>
        {row.validFrom ? t('common:date.fullDate', { date: new Date(row.validFrom), }) : '-'}
      </TableCellText>
      <TableCellText>
        {row.validTo ? t('common:date.fullDate', { date: new Date(row.validTo), }) : '-'}
      </TableCellText>
      <TableCellText>
        {authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS
          ? row.clusterIds.join(', ') || '-'
          : row.chains.map(c => c.id).join(', ') || '-'
        }
      </TableCellText>
      {authUser?.claims.includes(Claim.ManagePromotion) ? (
        <TableCellActions>
          <MenuItem
            onClick={handleEdit}
          >
            {t('assortments.table.btnDetail')}
          </MenuItem>
          <MenuItem
            onClick={handleRemove}
          >
            {t('assortments.table.btnRemove')}
          </MenuItem>
        </TableCellActions>
      ) : (
        <TableCell padding="none" />
      )}
    </TableRow>
  );
};

export default PromotionsTableRow;
