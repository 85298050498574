import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, ReactNode, } from 'react';
import { styled, } from '@mui/material/styles';
import { PhonePrefixOption, } from 'Utils/options/useOptionsPhonePrefix';
import FormHelperTextClassic from '../components/FormHelperTextClassic';
import InputLabelClassic from '../components/InputLabelClassic';
import InputBaseClassic from '../components/InputBaseClassic';
import Prefix from './Prefix';

const InputContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'start',
}));

const InputItemPrefix = styled('div')(({ theme, }) => ({
  minWidth: 110,
  marginRight: theme.spacing(1),
}));

const InputItemPhone = styled('div')(() => ({
  flexGrow: 1,
  width: '100%',
  position: 'relative',
}));

const PhoneInputWrapper = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
}));
export interface InputPhoneNumberProps {
  id?: string,
  name?: string,
  valuePrefix: PhonePrefixOption | null,
  onChangePrefix: (newValue: PhonePrefixOption | null) => void,
  valuePhone: string,
  onChangePhone: (newValue: string) => void,
  errorPrefix?: boolean,
  errorPhone?: boolean,
  required?: boolean,
  disabled?: boolean,
  label?: ReactNode,
  helperText?: ReactNode,
  placeholder?: string,
  onFocusPhone?: FocusEventHandler<HTMLInputElement>,
  onBlurPhone?: FocusEventHandler<HTMLInputElement>,
  onKeyDownPhone?: KeyboardEventHandler<HTMLInputElement>,
}

const InputPhoneNumber = ({
  id,
  name,
  valuePrefix,
  onChangePrefix,
  valuePhone,
  onChangePhone,
  errorPrefix,
  errorPhone,
  required,
  disabled,
  label,
  helperText,
  placeholder,
  onFocusPhone,
  onBlurPhone,
  onKeyDownPhone,
}: InputPhoneNumberProps): JSX.Element => {
  const isError = errorPrefix || errorPhone;
  const handleChangePhone: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChangePhone(e.target.value);
  };

  return (
    <div>
      <InputLabelClassic
        id={id}
        required={required}
        error={isError}
      >
        {label}
      </InputLabelClassic>
      <InputContainer>
        <InputItemPrefix>
          <Prefix
            id={id ? `prefix-${id}` : undefined}
            value={valuePrefix}
            onChange={onChangePrefix}
            disabled={disabled}
            error={errorPrefix}
          />
        </InputItemPrefix>
        <PhoneInputWrapper>
          <InputItemPhone>
            <InputBaseClassic
              id={id}
              name={name}
              autoComplete={id}
              value={valuePhone}
              onChange={handleChangePhone}
              type="text"
              disabled={disabled}
              error={errorPhone}
              onFocus={onFocusPhone}
              onBlur={onBlurPhone}
              onKeyDown={onKeyDownPhone}
              placeholder={placeholder}
            />
          </InputItemPhone>
        </PhoneInputWrapper>
      </InputContainer>
      {(helperText) && (
        <FormHelperTextClassic
          error={isError}
        >
          {helperText}
        </FormHelperTextClassic>
      )}
    </div>
  );
};

export default InputPhoneNumber;
