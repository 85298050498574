import Box, { BoxProps, } from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingBox = ({
  ...rest
}: BoxProps): JSX.Element => {
  return (
    <Box
      pt={3}
      pb={3}
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <CircularProgress size={30} />
    </Box>
  );
};

export default LoadingBox;
