import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const POSColoredIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#pos-colored)">
      <path
        d="M3.676 9.652c.084-2.99 1.502-5.614 4.576-7.211 2.08-1.085 4.275-1.218 6.478-.44 2.428.86 4.172 2.503 5.034 4.947 1.155 3.268.478 6.189-1.87 8.723a7.495 7.495 0 0 1-2.305 1.647 2.49 2.49 0 0 0-1.245 1.287c-.47 1.05-.953 2.084-1.431 3.125-.023.05-.042.1-.066.15-.17.357-.41.622-.84.626-.43.004-.697-.262-.867-.636-.291-.635-.578-1.272-.87-1.906-.217-.478-.44-.95-.655-1.428a2.336 2.336 0 0 0-1.162-1.193 8.162 8.162 0 0 1-2.87-2.237c-1.182-1.47-1.814-3.145-1.907-5.454Zm2.868.16a5.452 5.452 0 1 0 1.609-3.855A5.448 5.448 0 0 0 6.55 9.812h-.006Z"
        fill="#000"
      />
      <path
        d="M11.989 6.113a3.71 3.71 0 1 1-3.71 3.717 3.74 3.74 0 0 1 3.71-3.717Z"
        fill="#D47E5F"
      />
    </g>
    <defs>
      <clipPath id="pos-colored">
        <path
          fill="#fff"
          transform="translate(3.68 1.5)"
          d="M0 0h16.639v21H0z"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default POSColoredIcon;
