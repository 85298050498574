import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const ItalicIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    {...props}
    fill="none"
  >
    <path
      d="M15.586 3.125H7.148a.157.157 0 0 0-.156.156v1.25c0 .086.07.157.156.157h3.54L7.64 15.313H4.473a.157.157 0 0 0-.157.156v1.25c0 .086.07.156.157.156h8.437c.086 0 .156-.07.156-.156v-1.25a.157.157 0 0 0-.156-.156H9.266l3.046-10.626h3.274c.086 0 .156-.07.156-.156v-1.25a.157.157 0 0 0-.156-.156Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ItalicIcon;
