import { CheckDeviceCodeMutation, CheckDeviceCodeResultType, } from 'Apollo/graphql';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { codeRegex, } from 'Utils/deviceCodeRegex';
import * as yup from 'yup';

export const formValidationSchema = (checkDeviceCodeMutationResult: CheckDeviceCodeMutation | null | undefined, onlyDeviceCodes: string[]) =>
  yup.object().shape({
    deviceCode: yup
      .string()
      .required(INPUT_FORM_ERRORS.REQUIRED)
      .matches(codeRegex, INPUT_FORM_ERRORS.INVALID_DEVICE_CODE)
      .test('deviceCode', (value, { createError, path, }) => {
        if (checkDeviceCodeMutationResult?.checkDeviceCode.type === CheckDeviceCodeResultType.Ok) return true;
        if (checkDeviceCodeMutationResult?.checkDeviceCode.type === CheckDeviceCodeResultType.AlreadyRegistered)
          return createError({
            message: INPUT_FORM_ERRORS.ALREADY_EXIST,
          });
        if (checkDeviceCodeMutationResult?.checkDeviceCode.type === CheckDeviceCodeResultType.Invalid) return createError({
          message: INPUT_FORM_ERRORS.DEVICE_CODE_INVALID_IN_MARKET,
        });
        return createError({
          message: INPUT_FORM_ERRORS.INVALID_DEVICE_CODE,
        });
      })
      .test('deviceCode', INPUT_FORM_ERRORS.ALREADY_EXIST, (value) => {
        return !onlyDeviceCodes.find((item) => item === value)?.length;
      }),
  });
