import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const CheckOutlinedIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...props}
    fill="none"
  >
    <path
      d="m10.5 14.774-2.625-2.821-.875.94 3.5 3.761 7.5-8.06-.875-.94Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <rect
      x={1}
      y={1}
      width={22}
      height={22}
      rx={11}
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
    />
  </SvgIcon>
);

export default CheckOutlinedIcon;
