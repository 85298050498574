import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import { CsvExportFragment, } from 'Apollo/graphql';
import Grid from '@mui/material/Grid';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementAdmin/store/ReplacementStore';

interface Props {
  exportReplacements: CsvExportFragment;
}

const selectClose = (s: ReplacementStore) => s.closeModalExportReplacement;

const DownloadButton = ({ exportReplacements, }: Props): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  const onClose = useReplacementStore(selectClose);

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button component="a" href={exportReplacements.file.publicUrl} download size="large" variant="contained" color="primary">
          {t('common:actions.download')}
        </Button>
      </Grid>
      <Grid item>
        <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
          {t('common:actions.cancel')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DownloadButton;
