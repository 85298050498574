import shallow from 'zustand/shallow';
import { useForm, FormProvider, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useNavigate, } from 'react-router-dom';
import { SellerSubmitTaskFragment, SellerSubmitTaskFragmentDoc, SellerTaskFragment, SellerTaskFragmentDoc, useSubmitTaskCompletionMutation, } from 'Apollo/graphql';
import { FillTaskStore, useFillTaskStore, } from 'Modules/TasksSeller/store/fillTaskStore';
import AppContainer from 'Components/AppContainer';
import { ROUTES, } from 'Utils/constants';
import Layout from './Layout';
import { assessmentValidationSchema, mapVariables, useInitForm, } from './utils';
import { AssessmentValues, } from './types';

const selectStoreData = (s: FillTaskStore) => ({
  task: s.taskResult.data!,
  skipPhaseContent: s.skipPhaseContent,
  assessmentConfig: s.assessmentConfig,
  goToPhase: s.goToPhase,
  finishAssessment: s.finishAssessment,
});

const PhaseAssessment = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    task,
    skipPhaseContent,
    assessmentConfig,
    goToPhase,
    finishAssessment,
  } = useFillTaskStore(selectStoreData, shallow);

  const { initValues, } = useInitForm(task.assessment!.blocks);

  const methods = useForm<AssessmentValues>({
    defaultValues: initValues,
    resolver: yupResolver(assessmentValidationSchema),
    mode: 'onSubmit',
  });

  const [ submitTaskMutation, submitTaskMutationResult, ] = useSubmitTaskCompletionMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      finishAssessment(result.submitTaskCompletion);
    },
    update: (cache, { data, }) => {
      try {
        if (data?.submitTaskCompletion.task) {
          const resultTask = data.submitTaskCompletion.task;
          cache.writeFragment<SellerSubmitTaskFragment>({
            id: `Task:${resultTask.id}`,
            fragment: SellerSubmitTaskFragmentDoc,
            fragmentName: 'SellerSubmitTask',
            data: resultTask,
          });
        }
      } catch {
        // ignore error
      }
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => { 
    await submitTaskMutation({
      variables: mapVariables(task.id, values),
    });
  });

  const handleBack = () => {
    if (skipPhaseContent) navigate(`/${ROUTES.tasks}`);
    else goToPhase('content');
  };

  return (
    <AppContainer maxWidth="sm">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Layout
            task={task}
            assessmentConfig={assessmentConfig}
            submitTaskMutationResult={submitTaskMutationResult}
            onBack={handleBack}
          />
        </form>
      </FormProvider>
    </AppContainer>
  );
};

export default PhaseAssessment;
