import create from 'zustand';
import { UserFragment, UserSortableFields, OrderBy, ChainFragment, PointOfSellOptionFragment, } from 'Apollo/graphql';
import { ChangeTableFilter, ChangeTableSort, ChangeTableData, } from 'Utils/types';
import { UserRolesOption, } from 'Utils/options/useOptionsUserRoles';

export type TableUserFilter = {
  username: string
  pos: PointOfSellOptionFragment | null,
  chain: ChainFragment | null,
  role: UserRolesOption | null,
  firstName: string,
  lastName: string,
  phone: string,
};

export type UserStore = {
  modalExportUsers: {
    isOpen: boolean,
  },
  modalChangePassword: {
    isOpen: boolean,
    user: UserFragment | null,
  },
  modalEraseAllUserData: {
    isOpen: boolean,
    user: UserFragment | null,
  },
  modalDetailUser: {
    isOpen: boolean,
    user: UserFragment | null,
  },
  modalCreateUser: {
    isOpen: boolean,
  },
  modalEditUser: {
    isOpen: boolean,
    user: UserFragment | null,
  },
  modalRemoveUser: {
    isOpen: boolean,
    user: UserFragment | null,
  },
  modalReactivateUser: {
    isOpen: boolean,
  },
  modalImportUsers: {
    isOpen: boolean,
  },
  tableUsers: {
    data: {
      sortField: UserSortableFields | null,
      sortOrder: OrderBy | null,
      limit: number,
      offset: number,
    },
    filter: TableUserFilter,
  },
  openModalExportUsers: () => void,
  closeModalExportUsers: () => void,
  openModalChangePassword: (user: UserFragment) => void,
  closeModalChangePassword: () => void,
  openModalEraseAllUserData: (user: UserFragment) => void,
  closeModalEraseAllUserData: () => void,
  openModalDetailUser: (user: UserFragment) => void,
  closeModalDetailUser: () => void,
  openModalCreateUser: () => void,
  closeModalCreateUser: () => void,
  openModalEditUser: (user: UserFragment) => void,
  closeModalEditUser: () => void,
  openModalRemoveUser: (user: UserFragment) => void,
  closeModalRemoveUser: () => void,
  openModalReactivateUser: () => void,
  closeModalReactivateUser: () => void,
  openModalImportUsers: () => void,
  closeModalImportUsers: () => void,
  changeTableData: ChangeTableData,
  changeTableFilter: ChangeTableFilter,
  changeTableSort: ChangeTableSort,
};

export const useUserStore = create<UserStore>((set) => ({
  modalExportUsers: {
    isOpen: false,
    filter: null,
    sort:null,
  },
  modalChangePassword: {
    isOpen: false,
    user: null,
  },
  modalEraseAllUserData: {
    isOpen: false,
    user: null,
  },
  modalDetailUser: {
    isOpen: false,
    user: null,
  },
  modalCreateUser: {
    isOpen: false,
  },
  modalEditUser: {
    isOpen: false,
    user: null,
  },
  modalRemoveUser: {
    isOpen: false,
    user: null,
  },
  modalReactivateUser: {
    isOpen: false,
  },
  modalImportUsers: {
    isOpen: false,
  },
  tableUsers: {
    data: {
      sortField: null,
      sortOrder:  null,
      limit: 10,
      offset: 0,
    },
    filter: {  
      username: '',
      pos: null,
      chain: null,
      role: null,
      firstName: '',
      lastName: '',
      phone: '',
    },
  },
    /*
  * Modal Change password
  */
    openModalExportUsers: () => set({
      modalExportUsers: {
        isOpen: true,
      },
    }),
    closeModalExportUsers: () => set(() => ({
      modalExportUsers: {
        isOpen: false,
      },
    })),
  /*
  * Modal Change password
  */
  openModalChangePassword: (user) => set({
    modalChangePassword: {
      isOpen: true,
      user,
    },
  }),
  closeModalChangePassword: () => set((state) => ({
    modalChangePassword: {
      ...state.modalDetailUser,
      isOpen: false,
    },
  })),
  /*
  * Modal Erase all user data
  */
  openModalEraseAllUserData: (user) => set({
    modalEraseAllUserData: {
      isOpen: true,
      user,
    },
  }),
  closeModalEraseAllUserData: () => set((state) => ({
    modalEraseAllUserData: {
      ...state.modalDetailUser,
      isOpen: false,
    },
  })),
  /*
  * Modal Detail
  */
  openModalDetailUser: (user) => set({
    modalDetailUser: {
      isOpen: true,
      user,
    },
  }),
  closeModalDetailUser: () => set((state) => ({
    modalDetailUser: {
      ...state.modalDetailUser,
      isOpen: false,
    },
  })),
  /*
   * Modal Create
   */
  openModalCreateUser: () => set({
    modalCreateUser: {
      isOpen: true,
    },
  }),
  closeModalCreateUser: () => set({
    modalCreateUser: {
      isOpen: false,
    },
  }),
  /*
   * Modal Edit
   */
  openModalEditUser: (user) => set({
    modalEditUser: {
      isOpen: true,
      user,
    },
  }),
  closeModalEditUser: () => set((state) => ({
    modalEditUser: {
      ...state.modalEditUser,
      isOpen: false,
    },
  })),

  /*
   * Modal Remove
   */
  openModalRemoveUser: (user) => set({
    modalRemoveUser: {
      isOpen: true,
      user,
    },
  }),
  closeModalRemoveUser: () => set((state) => ({
    modalRemoveUser: {
      ...state.modalRemoveUser,
      isOpen: false,
      User: null,
    },
  })),
  /*
   * Modal Remove
  */
  openModalReactivateUser: () => set({
    modalReactivateUser: {
      isOpen: true,
    },
  }),
  closeModalReactivateUser: () => set((state) => ({
    modalReactivateUser: {
      ...state.modalReactivateUser,
      isOpen: false,
    },
  })),
  /*
   * Modal Import
   */
  openModalImportUsers: () => set({
    modalImportUsers: {
      isOpen: true,
    },
  }),
  closeModalImportUsers: () => set({
    modalImportUsers: {
      isOpen: false,
    },
  }),
  /**
   * User table
   */
   changeTableData: (name, value) => set((state) => ({
    tableUsers: {
      ...state.tableUsers,
      data: {
        ...state.tableUsers.data,
        [name]: value,
      },
    },
   })),
   changeTableFilter: (name, value) => set((state) => ({
    tableUsers: {
      ...state.tableUsers,
      data: {
        ...state.tableUsers.data,
        offset: 0,
      },
      filter: {
        ...state.tableUsers.filter,
        [name]: value,
      },
    },
   })), 
   changeTableSort: (sortField, sortOrder) => set((state) => ({
    tableUsers: {
      ...state.tableUsers,
      data: {
        ...state.tableUsers.data,
        sortField: sortField as UserSortableFields,
        sortOrder,
      },
    },
   })),
}));
