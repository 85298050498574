import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import ButtonBase, { ButtonBaseProps, } from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FileFragment, } from 'Apollo/graphql';
import { DropzoneInputProps, DropzoneRootProps, } from 'react-dropzone';
import FileHelpIcon from 'Utils/svg/FileHelpIcon';
import DropAreaValue from './DropAreaValue';

interface EnhancedButtonBaseProps extends ButtonBaseProps {
  isDragActive: boolean,
  disabled: boolean,
  error: boolean,
}

const StyledDropArea = styled(
  ButtonBase,
  { shouldForwardProp: (prop) => (prop !== 'isDragActive' && prop !== 'disabled' && prop !== 'error'), }
)<EnhancedButtonBaseProps>(({ theme, error, isDragActive, disabled, }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',
  padding: theme.spacing(2),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#D9D9D9',
  borderRadius: theme.shape.borderRadius,

  transition: theme.transitions.create([
    'border-color',
    'background-color',
  ]),

  ...(disabled && ({
    color: theme.palette.action.disabled,
  })),

  ...(!disabled && ({
    '&:hover': {
      borderColor: '#333333',
      backgroundColor: theme.palette.action.hover,
    },  
  })),  

  ...((isDragActive) && ({
    borderColor: '#333333',
    backgroundColor: theme.palette.action.hover,
  })),

  ...(error && ({
    borderColor: theme.palette.error.main,
  })),
}));

const StyledTitle = styled(Typography)(({ theme, }) => ({
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightBold,
}));

interface Props {
  value: FileFragment,
  loading?: boolean,
  error?: boolean,
  isDragActive?: boolean,
  disabled?: boolean,
  onInputClick: () => void,
  getRootProps: <T extends DropzoneRootProps,>(props?: T | undefined) => T,
  getInputProps: <T extends DropzoneInputProps,>(props?: T | undefined) => T,
}

const DropArea = ({
  value,
  loading,
  disabled,
  error,
  isDragActive,
  onInputClick,
  getRootProps,
  getInputProps,
}: Props): JSX.Element => {  
  const { t, } = useTranslation('common');

  return (
    <StyledDropArea
      {...getRootProps()}
      isDragActive={!!isDragActive}
      error={!!error}
      disabled={!!disabled}
    >
      <input {...getInputProps()} />
      {value ? (
        <DropAreaValue
          value={value}
        />
      ) : (
        <>
          <Box
            mb={1}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 40,
              },
            }}
          >
            {loading ? (
              <CircularProgress size={37} />
            ) : (
              <FileHelpIcon />
            )}
          </Box>
          <StyledTitle>
            {t('inputFile.title')}
          </StyledTitle>
          <Typography
            variant="body1"
          >
            {t('inputFile.description')}
          </Typography>
        </>
      )}
    </StyledDropArea>
  );
};

export default DropArea;
