import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { VoucherFragment, UpdateVoucherMutationResult, TableVoucherFragment, } from 'Apollo/graphql';
import { FormError, FormSubmit, } from 'Components/Form';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import VoucherForm from 'Modules/Vouchers/components/VoucherForm';
import LinkButton from 'Components/LinkButton';

interface Props {
  voucher: VoucherFragment;
  editMutationResult: UpdateVoucherMutationResult;
  onClose: () => void;
  onDelete: (voucher: TableVoucherFragment | VoucherFragment) => void;
}

const View = ({ voucher, editMutationResult, onClose, onDelete, }: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');

  return (
    <ModalDrawerBody>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 5,
        }}
      >
        {t('detailVoucherModal.info')}
      </Typography>
      <VoucherForm voucher={voucher} />
      <Box mt={4}>
        <LinkButton type="button" variant="body1" onClick={() => onDelete(voucher)} color="secondary" disabled={false}>
          {t('detailVoucherModal.btnDelete')}
        </LinkButton>
      </Box>
      <Box mt={4}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <FormSubmit size="large">{t('detailVoucherModal.btnSubmit')}</FormSubmit>
          </Grid>
          <Grid item>
            <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <FormError mt={2} />
      <ErrorAlert mt={2} error={editMutationResult.error} />
    </ModalDrawerBody>
  );
};

export default View;
