import Typography from '@mui/material/Typography';
import LoadingBox from 'Components/LoadingBox';
import { PromotionCategoryPurchaseObject, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import type { AnyObject, } from 'Utils/types';
import { PromotionAvailabilityFragment, } from 'Apollo/graphql';
import ItemInput from './ItemInput';
import { ObjValue, OnChange, } from './types';

export interface Props<T extends AnyObject,> {
  objValue: ObjValue<T>,
  options: T[],
  loading?: boolean,
  getOptionKey: (option: T) => string,
  getOptionLabel: (option: T) => ReactNode,
  getOptionMax?: (option: T) => number | null | undefined,
  onChange: OnChange<T>,
  noOptionsText?: string,
  completePurchasePhase: () => void,
  setActivePromotionPurchase: (index: number, id: string) => void;
  promotionCategoryPurchaseList: PromotionCategoryPurchaseObject;
  handleChangeInPromotionPurchaseForm: (option: PromotionAvailabilityFragment, toggle: "add" | "remove") => void;
};

const OptionList = <T extends AnyObject,>({
  objValue,
  onChange,
  getOptionKey,
  getOptionLabel,
  getOptionMax,
  options,
  loading,
  noOptionsText,
  completePurchasePhase,
  setActivePromotionPurchase,
  promotionCategoryPurchaseList,
  handleChangeInPromotionPurchaseForm,
}: Props<T>): JSX.Element => {
  const { t, } = useTranslation();
  if (loading) return (
    <LoadingBox />
  );

  if (options.length < 1) return (
    <Typography
      variant="body1"
    >
      {noOptionsText || t('common:select.noOptions')}
    </Typography>
  );


  return (
    <>
      {options.map((o) => (
        <ItemInput<T>
          key={getOptionKey(o)}
          option={o}
          onChange={onChange}
          objValue={objValue}
          getOptionLabel={getOptionLabel}
          getOptionMax={getOptionMax}
          getOptionKey={getOptionKey}
          completePurchasePhase={completePurchasePhase}
          setActivePromotionPurchase={setActivePromotionPurchase}
          promotionCategoryPurchaseList={promotionCategoryPurchaseList}
          handleChangeInPromotionPurchaseForm={handleChangeInPromotionPurchaseForm}
        />
      ))}
    </>
  );
};

export default OptionList;
