import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import { NewsArticleFragment, } from 'Apollo/graphql';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import ImageAsync from 'Components/ImageAsync';
import VideoPlayer from 'Components/VideoPlayer';
import InputLabelClassic from 'Components/Inputs/components/InputLabelClassic';
import RichText from 'Components/RichText';
import { stringToRichText, } from 'Components/RichText/tools';

const VideoContainer = styled('div')(({ theme, }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
  marginBottom: theme.spacing(2),

  '& > video': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
}));

const ImageContainer = styled('div')(({ theme, }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '75%',
  marginBottom: theme.spacing(2),

  '& > img': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

interface Props {
  newsArticle: NewsArticleFragment,
  onClose: () => void,
}

const View = ({
  newsArticle,
  onClose,
}: Props) => {
  const { t, } = useTranslation('newsArticlesAdmin');

  const textDescendant = useMemo(
    () => stringToRichText(newsArticle.text),
    [ newsArticle.text, ],
  );

  return (
    <ModalDrawerBody>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('newsArticle.labelNewsArticleId')}
          >
            {newsArticle.id || '-'}
          </NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('newsArticle.labelTitle')}
          >
            {newsArticle.title || '-'}
          </NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('newsArticle.labelDescription')}
          >
            {newsArticle.description || '-'}
          </NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <InputLabelClassic shrink color="primary" >
            {t('newsArticle.labelPicture')}
          </InputLabelClassic>
          <ImageContainer>
            <ImageAsync src={newsArticle.image?.publicUrl} />
          </ImageContainer>
        </Grid>
        <Grid item xs={12}>
          <InputLabelClassic
            shrink
            color="primary"
          >
          {t('newsArticle.labelVideo')}
          </InputLabelClassic>
          {newsArticle.video ? (
            <VideoContainer>
              <VideoPlayer videoSrc={newsArticle.video.publicUrl} />
            </VideoContainer>

          ): '-'}
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('newsArticle.labelText')}
          >
            <RichText
              initValue={textDescendant}
              readOnly
            />
          </NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('newsArticle.labelChainId')}
          >
            {newsArticle.chains.map(c => c.name).join(', ') || '-'}
          </NotEditableTextClassic>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Grid container spacing={3}>
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              color="inherit"
              onClick={onClose}
            >
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalDrawerBody>
  );
};

export default View;
