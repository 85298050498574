import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const CloseIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M11.287 8.714a3.64 3.64 0 0 0-5.147 0l-2.574 2.573a3.64 3.64 0 1 0 5.148 5.148L10 15.148"
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.714 11.287a3.64 3.64 0 0 0 5.146 0l2.575-2.573a3.64 3.64 0 1 0-5.148-5.148L10 4.853"
      fill="none"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default CloseIcon;
