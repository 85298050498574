/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloCache, DefaultContext, MutationUpdaterFunction, Reference, } from '@apollo/client';
import { UserFragmentDoc, UserCreateInput, CreateUserMutation, Exact, DeleteUserMutation, EraseAllUserDataPermanentlyMutation, } from 'Apollo/graphql';

type UpdateUserCreate = MutationUpdaterFunction<CreateUserMutation, Exact<{
  userInput: UserCreateInput;
}>, DefaultContext, ApolloCache<any>> | undefined;

export const updateUserCreate: UpdateUserCreate = (cache, { data, }) => {
  try {
    if (data?.createUser) {
      const newRef = cache.writeFragment({
        id: cache.identify(data.createUser),
        data: data.createUser,
        fragment: UserFragmentDoc,
        fragmentName: data.createUser.__typename,
      });
  
      cache.modify({
        fields: {
          users: (existing) => ({
            ...existing,
            data: [ ...existing.data, newRef, ],
          }),
        },
      });
    }
  } catch {
    // ignore error
  }
};

type UpdateUserRemove = MutationUpdaterFunction<DeleteUserMutation, Exact<{
  id: string;
}>, DefaultContext, ApolloCache<any>> | undefined;

export const updateUserRemove: UpdateUserRemove = (cache, { data, }) => {
  try {
    if (data?.deleteUser) {
      cache.modify({
        fields: {
          users: (existing, { readField, }) => ({
            ...existing,
            data: existing.data.filter((itemRef: Reference) => readField('id', itemRef) !== data.deleteUser.id),
          }),
        },
      });
    }
  } catch {
    // ignore error
  }
};

type UpdateAllUserDataRemove = MutationUpdaterFunction<EraseAllUserDataPermanentlyMutation, Exact<{
  id: string;
}>, DefaultContext, ApolloCache<any>> | undefined;

export const updateAllUserDataRemove: UpdateAllUserDataRemove = (cache, { data, }) => {
  try {
    if (data?.eraseAllUserDataPermanently) {
      cache.modify({
        fields: {
          users: (existing, { readField, }) => ({
            ...existing,
            data: existing.data.filter((itemRef: Reference) => readField('id', itemRef) !== data.eraseAllUserDataPermanently.id),
          }),
        },
      });
    }
  } catch {
    // ignore error
  }
};
