import { MouseEventHandler, } from 'react';
import { useTranslation, } from 'react-i18next';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import { AuthUserFragment, PointOfSellFragment, } from 'Apollo/graphql';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellText from 'Components/Table/TableCellText';

interface Props {
  row: PointOfSellFragment,
  authUser: AuthUserFragment | null,
  onDetail: (pointOfSell: PointOfSellFragment) => void
};

const PointOfSellTableRow = ({
  row,
  authUser,
  onDetail,
}: Props): JSX.Element => {
  const { t, } = useTranslation('pos');

  const handleDetail: MouseEventHandler<HTMLLIElement> = () => {
    onDetail(row);
  };
  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;
  const showClusterIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS;

  return (
    <TableRow key={row.id}>
      <TableCellText>
        {row.id}
      </TableCellText>
      <TableCellText>
        {row.name || '-'}
      </TableCellText>
      {showChainIDs &&
        <TableCellText>
          {row.chain.id || '-'}
        </TableCellText>
      }
      <TableCellText>
        {row.chain.name || '-'}
      </TableCellText>
      {showClusterIDs &&
        <TableCellText>
          {row.clusterId || '-'}
        </TableCellText>
      }
      <TableCellText>
        {row.street || '-'}
      </TableCellText>
      <TableCellText>
        {row.houseNumber || '-'}
      </TableCellText>
      <TableCellText>
        {row.city || '-'}
      </TableCellText>
      <TableCellText>
        {row.postalCode || '-'}
      </TableCellText>
      <TableCellActions>
        <MenuItem
          onClick={handleDetail}
        >
          {t('pointsOfSell.table.btnDetail')}
        </MenuItem>
      </TableCellActions>
    </TableRow>
  );
};

export default PointOfSellTableRow;
