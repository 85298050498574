import LoadingBox from 'Components/LoadingBox';
import { RegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { useEffect, } from 'react';

interface Props {
  completeCustomerVerificationPhase: (verification: RegistrationStore['verification']) => void;
}

const SkipVerification = ({ completeCustomerVerificationPhase, }: Props): JSX.Element => {
  useEffect(() => {
    completeCustomerVerificationPhase({ shouldVerify: false, isVerified: true, method: null, value: null,});
  }, [completeCustomerVerificationPhase,]);
  return <LoadingBox />;
};

export default SkipVerification;
