import AppContainer from 'Components/AppContainer';
import { Grid, Typography, } from '@mui/material';
import TotalNumberOfRepurchases from 'Components/Dashboard/Registrations/TotalNumberOfRepurchases';
import TotalNumberOfRegistrations from 'Components/Dashboard/Registrations/TotalNumberOfRegistrations';
import MethodOfVerification from 'Components/Dashboard/Registrations/MethodOfVerification';
import ReDoi from 'Components/Dashboard/Registrations/ReDoi';
import GenderTitle from 'Components/Dashboard/Registrations/GenderTitle';
import PreviouslyOwnedDevice from 'Components/Dashboard/Registrations/PreviouslyOwnedDevice';
import RegistrationsByCities from 'Components/Dashboard/Registrations/RegistrationsByCities';
import SplitOfTotalRegistrations from 'Components/Dashboard/Registrations/SplitOfTotalRegistrations';
import PurchasedProduct from 'Components/Dashboard/Registrations/PurchasedProduct';
import { Claim, DashboardLayoutItemName, useUserDashboardLayoutQuery, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import { useTranslation, } from 'react-i18next';
import SplitOfTotalRegistrationsByRegistrationAge from 'Components/Dashboard/Registrations/SplitOfTotalRegistrationsByRegistrationAge';

const Registrations = () => {
  const { data, } = useUserDashboardLayoutQuery();
  const authUser = useAuthUser();
  const { t, } = useTranslation('common');
  return (
    <AppContainer>
      <Typography variant="h1" marginBottom={2}>{t('dashboardSidebar.registrations')}</Typography>
      <Grid container spacing={2}>
        <Grid xs={12} md={6} container item spacing={2}>
          {authUser?.claimsSet.has(Claim.DashboardRegistrationsTotalNumberOfRegistrations) && (
            <Grid item xs={12}>
              <TotalNumberOfRegistrations
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsTotalNumberOfRegistrations,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.claimsSet.has(Claim.DashboardRegistrationsTotalNumberOfRePurchases) && (
            <Grid item xs={12}>
              <TotalNumberOfRepurchases
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsTotalNumberOfRePurchases,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.claimsSet.has(Claim.DashboardRegistrationsConsumerTitleGender) && (
            <Grid item xs={12}>
              <GenderTitle
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsConsumerTitleGender,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.claimsSet.has(Claim.DashboardRegistrationsPreviouslyOwnedDevice) && (
            <Grid item xs={12}>
              <PreviouslyOwnedDevice
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsPreviouslyOwnedDevice,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.claimsSet.has(Claim.DashboardRegistrationsTop_10Cities) && (
            <Grid item xs={12}>
              <RegistrationsByCities
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsTop_10Cities,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid xs={12} md={6} container item spacing={2} sx={{ height: '100%', }}>
          {authUser?.claimsSet.has(Claim.DashboardRegistrationsMethodOfVerification) && (
            <Grid item xs={12}>
              <MethodOfVerification
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsMethodOfVerification,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.claimsSet.has(Claim.DashboardRegistrationsReDoi) && (
            <Grid item xs={12}>
              <ReDoi
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsReDoi,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.claimsSet.has(Claim.DashboardRegistrationsConsumerAgeGroups) && (
            <Grid item xs={12}>
              <SplitOfTotalRegistrations
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsConsumerAgeGroups,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.claimsSet.has(Claim.DashboardRegistrationsConsumerAgeGroups) && (
            <Grid item xs={12}>
              <SplitOfTotalRegistrationsByRegistrationAge
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsConsumerAgeGroups,
                  )
                }
              />
            </Grid>
          )}
          {authUser?.claimsSet.has(Claim.DashboardRegistrationsPurchasedProduct) && (
            <Grid item xs={12}>
              <PurchasedProduct
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.RegistrationsPurchasedProduct,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AppContainer>
  );
};

export default Registrations;
