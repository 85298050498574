import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, useState, useCallback, } from 'react';

export const parseValue = (value?: number | null): string => {
  if (value === null || value === undefined || Number.isNaN(value)) return '';
  return value.toString();
};

export const parseInternalValue = (internalValue: string): number | null => {
  const parsed = parseInt(internalValue, 10);
  if (parsed === null || parsed === undefined || Number.isNaN(parsed)) return null;
  return parsed;
};

export const useInputInt = (
  value: number | null,
  onChange: (value: number | null) => void,
  onFocus?: FocusEventHandler<HTMLInputElement>,
  onBlur?: FocusEventHandler<HTMLInputElement>,
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>,
) => {
  const [ internalValue, changeInternalValue, ] = useState<string>(parseValue(value));
  const [ isFocused, changeFocus, ] = useState<boolean>(false);

  const handleFocusInternal: FocusEventHandler<HTMLInputElement> = (e) => {
    changeInternalValue(parseValue(value));
    changeFocus(true);
    onFocus?.(e);
  };

  const handleChange = useCallback(
    (newValue: string) => onChange(parseInternalValue(newValue)),
    [ onChange, ],
  );

  const handleKeyDownInternal = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.key === 'Enter') {
        changeFocus(false);
      }
      onKeyDown?.(e);
    },
    [ onKeyDown, ],
  );

  const handleBlurInternal = useCallback<FocusEventHandler<HTMLInputElement>>(
    (e) => {
      changeFocus(false);
      onBlur?.(e);
    },
    [ onBlur, ],
  );

  const handleChangInternalValue = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const newValue = e.target.value;
      changeInternalValue(newValue);
      handleChange(newValue);
    },
    [ changeInternalValue, handleChange, ],
  );

  return ({
    valueInternal: isFocused ? internalValue : parseValue(value),
    onChangeInternal: handleChangInternalValue,
    onKeyDownInternal: handleKeyDownInternal,
    onBlurInternal: handleBlurInternal,
    onFocusInternal: handleFocusInternal,
  });
};
