import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { SearchReplacementByIdQueryResult, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { FormInputText, } from 'Components/Form';
import { CustomErrorMessage, } from 'Components/Errors/types';

interface Props {
  replacementResult: SearchReplacementByIdQueryResult,
  customError: CustomErrorMessage | null,
}

const View = ({
  replacementResult,
  customError,
}: Props): JSX.Element => {
  const { t, } = useTranslation('replacementSeller');

  return (
    <>
      <Typography
        variant="h1"
        sx={{ mb: 1.5, }}
      >
        {t('phaseSearch.title')}
      </Typography>

      <Box
        mt={4}
        mb={4}
      >
        <FormInputText
          autoFocus
          name="replacementId"
          label={t('phaseSearch.labelId')}
          disabled={replacementResult.loading}
          required
        />
      </Box>
      <Button 
        type="submit"
        size="large"
        color="primary"
        sx={{ width: { xs: '100%', lg: 'unset', }, }}
      >
        {t('phaseSearch.btnSearch')}
      </Button>
      <ErrorAlert
        sx={{ mt: 2, }}
        error={replacementResult.error}
        customError={customError}
      />
    </>
  );
};

export default View;
