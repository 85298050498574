import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AppContainer from 'Components/AppContainer';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { ROUTES, } from 'Utils/constants';

const Success = (): JSX.Element => {
  const { t, } = useTranslation('myProfile');
  const navigate = useNavigate();
  const backToProfile = () => navigate(`/${ROUTES.myProfile}`);
  return (
    <AppContainer maxWidth="sm">
      <Typography
        variant="h1"
        sx={{
          marginBottom: 1.5,
        }}
      >
        {t('deleteMyProfile.titleSuccess')}
      </Typography>
      <Typography
        variant="inherit"
        sx={{
          marginBottom: 5,
        }}
      >
        {t('deleteMyProfile.descriptionSuccess')}
      </Typography>
      <Button variant="contained" color="primary" onClick={backToProfile}>
        {t('common:actions.done')}
      </Button>
    </AppContainer>
  );
};

export default Success;
