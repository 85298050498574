import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { PurchaseDetailFragment, } from 'Apollo/graphql';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellText from 'Components/Table/TableCellText';
import { MouseEventHandler, useEffect, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { valueOrEmpty, } from 'Utils/parsers';
import KeyboardArrowDownIcon from 'Utils/svg/KeyboardArrowDownIcon';
import KeyboardArrowUpIcon from 'Utils/svg/KeyboardArrowUpIcon';
import { countPurchasedProducts, } from '../utils';
import { COL_WIDTHS, } from './PurchasesTableHeader';

interface Props {
  row: PurchaseDetailFragment;
  onDetail: (purchase: PurchaseDetailFragment) => void;
  colSpan: number;
  showSensitiveData: boolean | undefined;
  showChainIDs: boolean | undefined;
  filterIsAssortmentOrPromotion: boolean;
}
const PurchasesTableRow = ({ row, onDetail, colSpan, showChainIDs, showSensitiveData, filterIsAssortmentOrPromotion,}: Props): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const [open, setOpen,] = useState(false);
  const handleDetail: MouseEventHandler<HTMLLIElement> = () => {
    onDetail(row);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(()=> {if (filterIsAssortmentOrPromotion && !open) setOpen(true);},[filterIsAssortmentOrPromotion,]);

  return (
    <>
      <TableRow key={row.id}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {showSensitiveData && <TableCellText>{valueOrEmpty(row?.customer?.email)}</TableCellText>}
        <TableCellText>{t('common:date.fullDate', { date: new Date(row.createdAt), })}</TableCellText>
        <TableCellText>---</TableCellText>
        <TableCellText>---</TableCellText>
        <TableCellText>---</TableCellText>
        <TableCellText>---</TableCellText>
        <TableCellText>---</TableCellText>
        <TableCellText>
          {valueOrEmpty(countPurchasedProducts(row.purchasedAssortments))}
        </TableCellText>
        <TableCellText>{valueOrEmpty(row.posId)}</TableCellText>
        {showChainIDs && <TableCellText hidden={!showChainIDs}>{valueOrEmpty(row.chainId)}</TableCellText>}
        <TableCellText>{valueOrEmpty(row.author.username)}</TableCellText>
        <TableCellText>---</TableCellText>
        <TableCellActions>
          <MenuItem onClick={handleDetail}>{t('purchases.table.btnDetail')}</MenuItem>
        </TableCellActions>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: 0, }} colSpan={colSpan}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0, }}>
              <Table
                sx={{
                  tableLayout: 'fixed',
                  maxWidth: '100%',
                }}
              >
                <TableBody>
                  {row.purchasedAssortments.map((assortment,index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={`${assortment.assortment.id}${index}`}>
                      <TableCell style={COL_WIDTHS[0]} />
                      {showSensitiveData && <TableCellText style={COL_WIDTHS[1]}>---</TableCellText>}
                      <TableCell style={COL_WIDTHS[2]}>---</TableCell>
                      <TableCellText style={COL_WIDTHS[2]}>{assortment.promotion ? t('purchases.table.type.promotion') : t('purchases.table.type.assortment')}</TableCellText>
                      <TableCellText style={COL_WIDTHS[2]}>{valueOrEmpty(assortment.promotion?.id)}</TableCellText>
                      <TableCellText style={COL_WIDTHS[2]}>{valueOrEmpty(assortment.promotion?.name)}</TableCellText>
                      <TableCellText style={COL_WIDTHS[2]}>{valueOrEmpty(assortment.assortment.id)}</TableCellText>
                      <TableCellText style={COL_WIDTHS[2]}>{valueOrEmpty(assortment.assortment.name)}</TableCellText>
                      <TableCellText style={COL_WIDTHS[3]}>{valueOrEmpty(assortment.count)}</TableCellText>
                      <TableCellText style={COL_WIDTHS[4]}>---</TableCellText>
                      {showChainIDs && <TableCellText style={COL_WIDTHS[5]}>---</TableCellText>}
                      <TableCellText style={COL_WIDTHS[6]}>---</TableCellText>
                      <TableCellText style={COL_WIDTHS[7]}>{valueOrEmpty(assortment.deviceCode)}</TableCellText>
                      <TableCell style={COL_WIDTHS[8]} />
                    </TableRow>
                  ))}  
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PurchasesTableRow;
