import { MouseEventHandler, ReactNode, } from 'react';
import { styled, } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import IconButtonSquare from 'Components/IconButtonSquare';
import PlusIcon from 'Utils/svg/PlusIcon';
import MinusIcon from 'Utils/svg/MinusIcon';
import type { AnyObject, } from 'Utils/types';
import { ObjValue, OnChange, } from './types';

const StyleCard = styled(Card)(({ theme, }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  marginBottom: 1,
  borderRadius: 0,

  '& .MuiTypography-root': {
    fontSize: 16,
    lineHeight: '22px',
  },
}));

const StyledTypography = styled(Typography)(({ theme, }) => ({
  marginRight: theme.spacing(1),
  userSelect: 'none',  
}));

const StyleValueContainer = styled('div')(({ theme, }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: 130,
}));

const StyledValue = styled('span')(({ theme, }) => ({
  marginRight: theme.spacing(0.25),
  marginLeft: theme.spacing(0.25),
  fontSize: 18,
  lineHeight: '22px',
  letterSpacing: '0.25px',
  userSelect: 'none',  
  minWidth: 42,
  textAlign: 'center', 
}));

const StyledMax = styled('span')(() => ({
  fontSize: 12,
  userSelect: 'none',
  color: '#808080',
}));

interface Props<T extends AnyObject,> {
  option: T,
  onChange: OnChange<T>,
  objValue: ObjValue<T>,
  getOptionLabel: (o: T) => ReactNode,
  getOptionMax?: (o: T) => number | null | undefined,
  totalDisabledPlus?: boolean,
  getOptionKey: (o: T) => string, 
};

const ItemInput = <T extends AnyObject,>({
  option,
  objValue,
  onChange,
  getOptionLabel,
  getOptionMax,
  getOptionKey,
  totalDisabledPlus,
}: Props<T>): JSX.Element => {
  const max = getOptionMax?.(option);
  const label = getOptionLabel(option);
  const count: number = objValue[getOptionKey(option)]?.count || 0;
  const isMaxActive = max !== undefined && max !== null;
  const disabledPlus: boolean = (isMaxActive && (count || 0) >= max) || (totalDisabledPlus || false);
  const disabledMinus: boolean = !count;

  const handlePlus: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (!disabledPlus) onChange({
      option,
      count: count + 1,
    });
  };
  const handleMinus: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (!disabledMinus) onChange({
      option,
      count: count - 1,
    });
  };

  return (
    <StyleCard>
      <StyledTypography
        variant="subtitle1"
        color="primary"
      >
        {label}
      </StyledTypography>
      <StyleValueContainer>
        <IconButtonSquare
          color="primary"
          onClick={handleMinus}
          disabled={disabledMinus}
        >
          <MinusIcon />
        </IconButtonSquare>
          <StyledValue>
            {count}
            {isMaxActive && (
              <StyledMax>
                {`/${max < 0 ? 0 : max}`}
              </StyledMax>
            )}
          </StyledValue>
        <IconButtonSquare
          color="secondary"
          onClick={handlePlus}
          disabled={disabledPlus}                      
        >
          <PlusIcon />
        </IconButtonSquare>
      </StyleValueContainer>
    </StyleCard>
  );
};

export default ItemInput;
