import ModalChangePassword from 'Modules/Users/modals/ModalChangePassword';
import ModalCreateUser from 'Modules/Users/modals/ModalCreateUser';
import ModalDetailUser from 'Modules/Users/modals/ModalDetailUser';
import ModalEditUser from 'Modules/Users/modals/ModalEditUser';
import ModalEraseAllUserData from 'Modules/Users/modals/ModalEraseAllUserData';
import ModalExportUsers from 'Modules/Users/modals/ModalExportUsers';
import ModalImportUsers from 'Modules/Users/modals/ModalImportUsers';
import ModalReactivateUser from 'Modules/Users/modals/ModalReactivateUser';
import ModalRemoveUser from 'Modules/Users/modals/ModalRemoveUser';
import UsersTable from './UsersTable';


const UsersPage = () => {
  return (
    <>
      <UsersTable />
      <ModalExportUsers />
      <ModalCreateUser />
      <ModalEditUser />
      <ModalRemoveUser />
      <ModalReactivateUser />
      <ModalDetailUser />
      <ModalEraseAllUserData />
      <ModalChangePassword />
      <ModalImportUsers />
    </>

  );
};

export default UsersPage;
