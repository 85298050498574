import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DeleteVoucherMutationResult, TableVoucherFragment, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { useTranslation, } from 'react-i18next';

interface Props {
  voucher: TableVoucherFragment,
  removeMutationResult: DeleteVoucherMutationResult,
  onSubmit: () => void,
  onClose: () => void,
};

const View = ({
  voucher,
  removeMutationResult,
  onSubmit,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>
        {t('removeVoucherModal.title')}
      </ModalDrawerTitle>
      <ModalDrawerBody>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 5,
          }}
        >
          {t('removeVoucherModal.info', { id: voucher.id, })}
        </Typography>
        <Box mt={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Button
                color="error"
                variant="contained"
                size="large"
                disabled={removeMutationResult.loading}
                onClick={onSubmit}
              >
                {t('removeVoucherModal.btnSubmit')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                color="inherit"
                onClick={onClose}
              >
                {t('common:actions.cancel')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <ErrorAlert
          mt={2}
          error={removeMutationResult.error}
        />
      </ModalDrawerBody>
    </>
  );
};

export default View;
