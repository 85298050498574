import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { AssortmentStore, useAssortmentStore, } from 'Modules/AssortmentAdmin/store/AssortmentStore';
import AppContainer from 'Components/AppContainer';
import PlusIcon from 'Utils/svg/PlusIcon';
import { useAuthUser, } from 'Tools/auth';
import { Claim, } from 'Apollo/graphql';
import Assortments from './Assortments';

const selectOpenCreateModal = (s: AssortmentStore) => s.openModalCreateAssortment;

const AssortmentsTable = (): JSX.Element => {  
  const { t, } = useTranslation('assortmentAdmin');
  const openCreateModal = useAssortmentStore(selectOpenCreateModal);
  const authUser = useAuthUser();

  return (
    <AppContainer>

      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography
            variant="h1"
          >
            {t('assortments.title')}
          </Typography>
        </Grid>
        <Grid item>
          {authUser?.claimsSet.has(Claim.ManageAssortment) && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={openCreateModal}
            >
              {t('assortments.bntAdd')}
            </Button>
          )}
        </Grid>
      </Grid>

      <Assortments />

    </AppContainer>
  );
};

export default AssortmentsTable;
