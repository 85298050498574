import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import AppContainer from 'Components/AppContainer';
import FetchData from './FetchData';

const TaskDetailPage = (): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');

  return (
    <AppContainer maxWidth="md" >
      
      <Typography variant="h1" mb={1.5}>
        {t('detail.title')}
      </Typography>
      
      <FetchData />

    </AppContainer>
  );
};

export default TaskDetailPage;
