import { Claim, FeatureFlagObjectFragment, } from 'Apollo/graphql';
import { ReactNode, ReactElement, } from 'react';
import { Navigate, Outlet, } from 'react-router-dom';
import { useAuthUser, } from 'Tools/auth';
import { useAuthRedirectRoute, } from 'Tools/auth/hooks/useAuthRedirectRoute';
import { ClaimCheckStrategy, isPermitted, PosFlagOption } from 'Tools/auth/utils/isPermitted';

interface Props {
  redirectToPath?: string,
  children?: ReactNode,
  featureFlagRequired?: keyof FeatureFlagObjectFragment,
  claimsRequired?: Claim[],
  claimCheckStrategy?: ClaimCheckStrategy,
  posFlagRequired?: PosFlagOption,
};

const ProtectedRoute = ({
  redirectToPath,
  featureFlagRequired,
  claimsRequired = [],
  claimCheckStrategy = 'all',
  posFlagRequired,
  children,
}: Props): JSX.Element => {
  const authUser = useAuthUser();
  const notAllowedRedirect = useAuthRedirectRoute(redirectToPath);
  
  if (!isPermitted(authUser, claimsRequired, featureFlagRequired, posFlagRequired, claimCheckStrategy)) {
    return (
      <Navigate
        to={notAllowedRedirect}
        replace
      />
    );
  };

  return children ? children as ReactElement : <Outlet />;
};

export default ProtectedRoute;
