import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AppContainer from 'Components/AppContainer';
import PlusIcon from 'Utils/svg/PlusIcon';
import { NewsArticlesStore, useNewsArticlesStore, } from 'Modules/NewsArticlesAdmin/store/NewsArticlesStore';
import { useAuthUser, } from 'Tools/auth/hooks/useAuthUser';
import { Claim, } from 'Apollo/graphql';
import NewsArticles from './NewsArticles';

const selectOpenCreateModal = (s: NewsArticlesStore) => s.openModalCreateNewsArticle;

const NewsArticlesTable = (): JSX.Element => {  
  const { t, } = useTranslation('newsArticlesAdmin');
  const openCreateModal = useNewsArticlesStore(selectOpenCreateModal);
  const authUser = useAuthUser();

  return (
    <AppContainer>

      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <Typography
            variant="h1"
          >
            {t('newsArticles.title')}
          </Typography>
        </Grid>
        {authUser?.claimsSet.has(Claim.ManageNews) && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<PlusIcon />}
              onClick={openCreateModal}
            >
              {t('newsArticles.bntAdd')}
            </Button>
          </Grid>
        )}
      </Grid>

      <NewsArticles />

    </AppContainer>
  );
};

export default NewsArticlesTable;
