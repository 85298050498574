import {  useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CheckVerificationCodeMutationResult, CustomerVerificationMethod, StartCustomerVerificationMutationResult, } from 'Apollo/graphql';
import { FormInputText, FormSubmit, } from 'Components/Form';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { CustomErrorMessage, } from 'Components/Errors/types';
import LinkButton from 'Components/LinkButton';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import { CreateReplacementStore, } from '../../../createReplacementStore';

interface Props {
  errorInvalidVerificationCode: CustomErrorMessage | null,
  verification: CreateReplacementStore['customerVerification'],
  customer: CreateReplacementStore['customer'],
  checkVerificationCodeResult: CheckVerificationCodeMutationResult,
  sendVerificationCodeResult: StartCustomerVerificationMutationResult,
  onResend: () => void,
  goBackToPhase: CreateReplacementStore['goBackToPhase'],
  onClose: () => void,
};

const View = ({
  errorInvalidVerificationCode,
  verification,
  customer,
  checkVerificationCodeResult,
  sendVerificationCodeResult,
  onResend,
  goBackToPhase,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('registrationSeller');
  const {
    verifyContact,
    wrongContact,
    onClickWrongContact,
  } = useMemo(
    () => verification.method === CustomerVerificationMethod.Sms ? {
      verifyContact: `${customer?.phonePrefix || ''}${customer?.phoneNumber || ''}`,
      wrongContact: 'phaseCustomerVerification.infoWrongPhone',
      onClickWrongContact: () => goBackToPhase('customerRegistration'),
    } : {
      verifyContact: customer?.email || '',
      wrongContact: 'phaseCustomerVerification.infoWrongEmail',
      onClickWrongContact: () => goBackToPhase('init'),
    },
    [ verification, customer, goBackToPhase, ],
  );
  const handleBack = () => goBackToPhase('customerRegistration');
  
  return (
    <>
      <ModalDrawerTitle onClose={onClose}>
        {t('replacementAdmin:createReplacementModal.phaseCustomerVerification.title')}
      </ModalDrawerTitle>
      
      <ModalDrawerBody>

      <Typography
        variant="body1"
        sx={{ mb: 5, }}
      >
        {t('phaseCustomerVerification.info', { contact: verifyContact, })}
        
        <Link
          component="button"
          variant="body1"
          onClick={onClickWrongContact}
          color="secondary"
          sx={{ verticalAlign: 'unset', }}
        >
          {t(wrongContact)}
        </Link>
      </Typography>
      <Box mb={1.5}>
        <FormInputText
          name="verificationCode"
        />
      </Box>
      <LinkButton
        type="button"
        variant="body1"
        onClick={onResend}
        sx={{ mb: 4, }}
        color="secondary"
        disabled={sendVerificationCodeResult.loading}
      >
        {t('phaseCustomerVerification.btnResend')}
      </LinkButton>

      <ErrorAlert
        sx={{ mb: 2, }}
        error={checkVerificationCodeResult.error}
        customError={errorInvalidVerificationCode}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} lg="auto">
          <FormSubmit
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
          >
            {t('phaseCustomerVerification.btnSubmit')}
          </FormSubmit>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            onClick={handleBack}
          >
            {t('common:actions.back')}
          </Button>
        </Grid>
      </Grid>
      </ ModalDrawerBody>
    </>
  );
};

export default View;
