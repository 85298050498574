import { Typography, } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { CreateSodexoVoucherMutationResult, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { FormInputCheckbox, FormInputFileUpload, FormInputText, FormSubmit, } from 'Components/Form';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import { IMAGE_MAX_SIZE, } from 'Utils/constants';
import { useTranslation, } from 'react-i18next';

interface Props {
  onClose: () => void;
  createMutationResult: CreateSodexoVoucherMutationResult;
}

const FormView = ({ onClose, createMutationResult, }: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');

  return (
    <ModalDrawerBody>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormInputText name="name" label={t('vouchers.form.name')} required />
        </Grid>
        <Grid item xs={12}>
          <FormInputFileUpload name="image" label={t('vouchers.form.preview')} required fileType="image" maxSize={IMAGE_MAX_SIZE} />
        </Grid>
        <Grid item xs={12}>
          <FormInputText name="description" label={t('vouchers.form.description')} required multiline minRows={4} maxRows={6} />
        </Grid>
        <Grid item xs={12}>
          <FormInputCheckbox name="notificationsVisible" label={<Typography>{t('common:notification.createPopUpNotification')}</Typography>} />
        </Grid>
        <Grid item>
          <FormSubmit size="large">{t('createVoucherModal.btnSubmit')}</FormSubmit>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ErrorAlert mt={2} error={createMutationResult.error} />
    </ModalDrawerBody>
  );
};

export default FormView;
