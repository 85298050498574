import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSnackbar, } from 'notistack';
import { PointsTransactionFragment, useDeletePointsTransactionMutation, } from 'Apollo/graphql';
import { updatePointsTransactionRemove, } from 'Modules/PointsTransactionAdmin/utils/pointsTransactionCache';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import View from './View';

interface Props {
  pointsTransaction?: PointsTransactionFragment | null,
  onClose: () => void,
  onCloseDetail: () => void,
};

const Logic = ({
  pointsTransaction,
  onClose,
  onCloseDetail,
}: Props): JSX.Element => {
  const preservedPointsTransaction = usePreserveValue(pointsTransaction);
  const { t, } = useTranslation('pointsTransactionAdmin');
  const { enqueueSnackbar, } = useSnackbar();

  const [ removeMutation, removeMutationResult, ] = useDeletePointsTransactionMutation({
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('removePointsTransactionModal.notifRemovedTitle') as string,
        message: t('removePointsTransactionModal.notifRemovedMessage', { id: response.deletePointsTransaction.id, }),
        variant: 'common',
      });
      onCloseDetail();
      onClose();
    },
    update: updatePointsTransactionRemove,
  });

  const handleSubmit = useCallback(
    () => {
      removeMutation({
        variables: { id: preservedPointsTransaction.id, },
      });
    },
    [ preservedPointsTransaction.id, removeMutation, ],
  );

  return (
    <View
      pointsTransaction={preservedPointsTransaction}
      removeMutationResult={removeMutationResult}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default Logic;
