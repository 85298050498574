import { useTranslation, } from 'react-i18next';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationsAdmin/store/RegistrationStore';
import FetchData from './FetchData';

const selectModalData = (s: RegistrationStore) => s.modalDetailRegistration;
const selectClose = (s: RegistrationStore) => s.closeModalDetailRegistration;

const ModalDetailRegistration = (): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const { isOpen, registration, } = useRegistrationStore(selectModalData);
  const onClose = useRegistrationStore(selectClose);

  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!registration}
    >
      <ModalDrawerTitle
        onClose={onClose}
      >
        {t('detailRegistration.title')}
      </ModalDrawerTitle>
      <FetchData
        registration={registration}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalDetailRegistration;
