import { useForm, FormProvider, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useImportUsersMutation, UsersDocument, } from 'Apollo/graphql';
import { mapBadFormDataError, } from 'Components/Form';
import { useUserStore, } from 'Modules/Users/store/UserStore';
import { mapVariables, } from 'Modules/Users/utils/mapVariables';
import { defaultImportUsersFormValues, importUsersValidationSchema, ImportUsersFormValues, importBadFormDataFieldMapper, } from './utils';
import FormView from './FormView';
import { ImportState, } from './types';

interface Props {
  setImportState: (state: ImportState) => void,
  onClose: () => void,
};

const FormLogic = ({
  setImportState,
  onClose,
}: Props): JSX.Element => {
  const methods = useForm({
    defaultValues: defaultImportUsersFormValues,
    resolver: yupResolver(importUsersValidationSchema),
    mode: 'onTouched',
  });

  const [ importMutation, importMutationResult, ] = useImportUsersMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [{
      query: UsersDocument,
      variables: mapVariables(useUserStore.getState().tableUsers),
    },],
    onCompleted: (response) => {
      if (response.importUsers.success) {
        setImportState({
          isImported: true,
          result: response.importUsers,
        });
      }
    },
    onError: (error) => {
      mapBadFormDataError({
        apolloError: error,
        fieldMapper: importBadFormDataFieldMapper,
        methods,
      });
    },
  });

  const handleSubmit = methods.handleSubmit(async (values: ImportUsersFormValues) => {
    await importMutation({
      variables: {
        fileId: values.file?.id || '',
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <FormView
          importMutationResult={importMutationResult}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default FormLogic;
