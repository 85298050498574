import shallow from 'zustand/shallow';
import { Claim, useCustomersQuery, } from 'Apollo/graphql';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationsAdmin/store/RegistrationStore';
import { useAuthUser, } from 'Tools/auth';
import RegistrationsTableRow from './RegistrationsTableRow';
import RegistrationsTableHeader from './RegistrationsTableHeader';
import { mapVariablesRegistration, } from '../utils';

const selectStoreData = (s: RegistrationStore) => ({
  tableRegistrations: s.tableRegistrations,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,  
  onDetail: s.openModalDetailRegistration,
  onDelete: s.openModalRemoveRegistration,
  onRemove: s.openModalEraseAllCustomerData,
});

const Registrations = (): JSX.Element => {
  const {
    onDetail,
    onRemove,
    onDelete,
    tableRegistrations,
    changeFilter,
    changeSort,
    changeData,
  } = useRegistrationStore(selectStoreData, shallow);

  const { loading, error, data, } = useCustomersQuery({
    variables: mapVariablesRegistration(tableRegistrations),
    fetchPolicy: 'cache-and-network',
  });
  const authUser = useAuthUser();
  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS  ? 0 : 1;
  const numberOfSensitiveDataColumns = 4;
  const showSensitiveData = authUser?.claimsSet.has(Claim.ReadSensitiveData) ? 0 : 1 * numberOfSensitiveDataColumns;
  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={12-showChainIDs-showSensitiveData}
      rows={data?.customers.data.length || 0}
      loading={loading}
      error={error}
      limit={tableRegistrations.data.limit}
      sortField={tableRegistrations.data.sortField}
      sortOrder={tableRegistrations.data.sortOrder}
      offset={tableRegistrations.data.offset}
      count={data?.customers.pagination.count}
      filter={tableRegistrations.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <RegistrationsTableHeader />
      <TableBody>
        {(data?.customers.data || []).map((row) => (
          <RegistrationsTableRow
            key={row.id}
            row={row}
            onDetail={onDetail}
            onRemove={onRemove}
            onDelete={onDelete}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default Registrations;
