import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormSubmit, } from 'Components/Form';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { useTranslation, } from 'react-i18next';
import ReplacementRegistrationForm from './ReplacementRegistrationForm';

interface Props {
  onClose: () => void;
}

const View = ({  onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  return (
    <>
      <ModalDrawerTitle onClose={onClose}>{t('createReplacementModal.phaseReplacementRegistration.title')}</ModalDrawerTitle>
      <ModalDrawerBody>
        <Typography variant="body1" sx={{ mb: 3, }}>
          {t('createReplacementModal.phaseReplacementRegistration.info')}
        </Typography>
        <Box mb={4}>
          <ReplacementRegistrationForm />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg="auto">
            <FormSubmit color="primary" variant="contained" size="large" sx={{ width: { xs: '100%', lg: 'unset', }, }}>
              {t('common:actions.confirm')}
              </FormSubmit>
          </Grid>
        </Grid>
      </ModalDrawerBody>
    </>
  );
};

export default View;
