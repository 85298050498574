import { ChainFragment, FileFragment, VoucherFragment, } from 'Apollo/graphql';
import { INPUT_FORM_ERRORS, } from 'Components/Form/constants';
import { useMemo, } from 'react';
import * as yup from 'yup';

export type VoucherFormValues = {
  name: string;
  image: FileFragment | null;
  description: string;
  priceFiat: number | null;
  number: number | null;
  validFrom: Date | null;
  validTo: Date | null;
  pricePoints: number | null;
  chainIds: ChainFragment[];
  notificationsVisible: boolean;
};
export const initVoucherFormValues: VoucherFormValues = {
  name: '',
  image: null,
  description: '',
  priceFiat: 0,
  number: 0,
  validFrom: null,
  validTo: null,
  pricePoints: 0,
  chainIds: [],
  notificationsVisible: false,
};

export const useInitVoucherValues = (voucher: VoucherFragment): VoucherFormValues =>
  useMemo<VoucherFormValues>(
    () => ({
      name: voucher.name,
      image: voucher.image || null,
      description: voucher.description,
      priceFiat: voucher.priceFiat ?? null,
      number: voucher.number ?? null,
      validFrom: voucher.validFrom,
      validTo: voucher.validTo,
      pricePoints: voucher.pricePoints ?? null,
      chainIds: voucher.chainIds,
      type: voucher.type,
      notificationsVisible: voucher.notificationsVisible,
    }),
    [voucher,],
  );

export const voucherFormValidationSchema = yup.object().shape({
  name: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
  image: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
  description: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(2000, INPUT_FORM_ERRORS.STRING_MAX),
  priceFiat: yup.number().required(INPUT_FORM_ERRORS.REQUIRED).min(0, INPUT_FORM_ERRORS.INT_MIN).nullable(),
  number: yup.number().required(INPUT_FORM_ERRORS.REQUIRED).min(0, INPUT_FORM_ERRORS.INT_MIN).nullable(),
  validTo: yup
    .date()
    .required(INPUT_FORM_ERRORS.REQUIRED)
    .typeError(INPUT_FORM_ERRORS.DATE_FORMAT)
    .min(new Date(), INPUT_FORM_ERRORS.DATE_MIN_TODAY)
    .nullable(),
  validFrom: yup.date().required(INPUT_FORM_ERRORS.REQUIRED).typeError(INPUT_FORM_ERRORS.DATE_FORMAT).nullable(),
  pricePoints: yup.number().required(INPUT_FORM_ERRORS.REQUIRED).min(0, INPUT_FORM_ERRORS.INT_MIN).nullable(),
  chains: yup.array(),
  notificationsVisible: yup.boolean(),
});

export const mapFormToVariables = (values: VoucherFormValues) => ({
  name: values.name,
  imageFileId: values.image?.id,
  description: values.description,
  priceFiat: values.priceFiat || 0,
  number: values.number || 0,
  validFrom: values.validFrom,
  validTo: values.validTo,
  pricePoints: values.pricePoints || 0,
  chainIds: values.chainIds.map((chain) => chain.id),
  notificationsVisible: values.notificationsVisible,
});
