import { useFormContext, useController, } from 'react-hook-form';
import InputProductCountMultiple, { InputProductCountMultipleProps, } from 'Components/Inputs/InputProductCountMultiple';
import { InputProductCountMultipleValueOption ,} from 'Components/Inputs/InputProductCountMultiple/types';
import { AnyObject, } from 'Utils/types';
import { useFormInputStatus, } from '../utils/useFormInputStatus';
import { InputFormErrorMessage, } from '../types';

interface Props<T extends AnyObject,> extends Omit<InputProductCountMultipleProps<T>, 'value' | 'onChange' | 'error' > {
  name: string,
  triggerFields?: string | string[],
};

const FormInputProductCountMultiple = <T extends AnyObject,>({
  name,
  helperText,
  triggerFields,
  ...rest
}: Props<T>): JSX.Element => {
  const { control, trigger, } = useFormContext();
  const {
    field: { value, onBlur: onBlurField, onChange, },
    fieldState: { error, },
  } = useController({
    name,
    control,
  });

  const handleChange = (newValue: InputProductCountMultipleValueOption<T>[]) => {
    onChange(newValue);
    onBlurField();
    if (triggerFields) trigger(triggerFields);
  };

  const inputStatus = useFormInputStatus(
    error?.message as InputFormErrorMessage | undefined,
    helperText,
  );

  return (
    <InputProductCountMultiple<T>
      {...rest}
      value={value || []}
      onChange={handleChange}
      error={inputStatus.isError}
      helperText={inputStatus.text}
    />
  );
};

export default FormInputProductCountMultiple;
