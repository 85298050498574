import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { styled, } from "@mui/material/styles";
import Typography from '@mui/material/Typography';
import ImageAsync from 'Components/ImageAsync';
import { ReactNode, } from 'react';

const CardContainer = styled(Card)(({ theme, }) => ({
  marginBottom: 4,
  borderRadius: 0,
}));

const ImageContainer = styled('div')(() => ({
  height: 45,
  minHeight: 45,
  width: 70,
  minWidth: 70,
  marginRight: 4,
}));

interface Props {
  title?: ReactNode,
  imageSrc?: string | null,
}

const CardItem = ({
  title,
  imageSrc,
}: Props): JSX.Element => {


  return (
    <CardContainer>
      <Box
        p={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap={1} sx={{ mr: 1, }}>
          <ImageContainer>
            <ImageAsync src={imageSrc} />
          </ImageContainer>
          <Typography variant="h3" color="primary">{title}</Typography>
        </Box>
      </Box>
    </CardContainer>
  );
}; 

export default CardItem;
