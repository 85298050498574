import { useTranslation, } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AuthUserFragment, ChainFragment, ClusterFragment, PromotionSortableFields, useChainsQuery, useClustersQuery, } from 'Apollo/graphql';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import { areOptionsEqualById, getOptionFieldId, renderOptionById, } from 'Utils/helpers';
import TableCellSortable from 'Components/Table/TableCellSortable';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import TableFilterInt from 'Components/Table/filters/TableFilterInt';

const COL_WIDTHS = [
  { width: '150px', },
  { width: '200px', },
  { width: '170px', },
  { width: '150px', },
  { width: '180px', },
  { width: '210px', },
  { width: '150px', },
  { width: '150px', },
  { width: '150px', },
  { width: '150px', },
  { width: '45px', },
];

interface Props {
  authUser: AuthUserFragment | null,
}

const AssortmentsTableHeader = ({
  authUser,
}: Props): JSX.Element => {
  const { t, } = useTranslation('promotionsAdmin');

  const chainsResult = useChainsQuery({ fetchPolicy: 'cache-and-network', });
  const clustersResult = useClustersQuery({ fetchPolicy: 'cache-and-network', });

  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={COL_WIDTHS[0]}
        >
          {t('promotions.table.labelPromotionId')}
        </TableCell>
        <TableCellSortable
          style={COL_WIDTHS[1]}
          name={PromotionSortableFields.Name}
        >
          {t('promotions.table.labelName')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[2]}
          name={PromotionSortableFields.Description}
        >
          {t('promotions.table.labelDescription')}
        </TableCellSortable>
        <TableCell
          style={COL_WIDTHS[3]}
        >
          {t('promotions.table.labelPreview')}
        </TableCell>
        <TableCellSortable
          style={COL_WIDTHS[6]}
          name={PromotionSortableFields.OfferLimitPerCustomer}
        >
          {t('promotions.table.labelOfferLimit')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[7]}
          name={PromotionSortableFields.ValidFrom}
        >
          {t('promotions.table.labelValidFrom')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[8]}
          name={PromotionSortableFields.ValidTo}
        >
          {t('promotions.table.labelValidTo')}
        </TableCellSortable>
        <TableCell
          style={COL_WIDTHS[9]}
        >
          {authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS
            ? t('promotions.table.labelClusterIds')
            : t('promotions.table.labelChainIds')
          }
        </TableCell>
        <TableCell
          style={COL_WIDTHS[10]}
        />
      </TableRow>
      <TableRow>
        <TableCell>
          <TableFilterText
            name="id"
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="name"
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="description"
          />
        </TableCell>
        <TableCell />
        <TableCell>
          <TableFilterInt
            name="offerLimitPerCustomer"
          />
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell>
          {authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS ? (
            <TableFilterAutocomplete<ClusterFragment>
              name="clusterId"
              getOptionLabel={getOptionFieldId}
              isOptionEqualToValue={areOptionsEqualById}
              renderOption={renderOptionById(getOptionFieldId)}
              options={clustersResult.data?.clusters || []}
              loading={clustersResult.loading}
            />
          ) : (
            <TableFilterAutocomplete<ChainFragment>
              name="chainId"
              getOptionLabel={getOptionFieldId}
              isOptionEqualToValue={areOptionsEqualById}
              renderOption={renderOptionById(getOptionFieldId)}
              options={chainsResult.data?.chains || []}
              loading={chainsResult.loading}
            />
          )}
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default AssortmentsTableHeader;
