import shallow from 'zustand/shallow';
import { Link as RouterLink, } from 'react-router-dom';
import { useTranslation, Trans, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ROUTES, } from 'Utils/constants';
import { FillTaskStore, useFillTaskStore, } from 'Modules/TasksSeller/store/fillTaskStore';
import { AssessmentMethodEnum, } from 'Apollo/graphql';
import AppContainer from 'Components/AppContainer';
import CheckOutlinedIcon from 'Utils/svg/CheckOutlinedIcon';
import CrossOutlinedIcon from 'Utils/svg/CrossOutlinedIcon';
import ErrorFilledIcon from 'Utils/svg/ErrorFilledIcon';
import { isTaskPointsEnabledMarket, } from 'Utils/market';

const PointsBox = styled(
  'div',
  { shouldForwardProp: (prop) => (prop !== 'isCompleted'), }
)<{ isCompleted: boolean, }>(({ theme, isCompleted, }) => ({
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: isCompleted ? theme.palette.success.main : theme.palette.error.main,
  borderRadius: 10,
  padding: theme.spacing(2),
}));

const selectStoreData = (s: FillTaskStore) => ({
  task: s.taskResult.data!,
  assessmentResult: s.assessmentResult!,
  resetStore: s.resetStore,
  fetchTask: s.fetchTask,
});

const PhaseResult = (): JSX.Element => {
  const { t, } = useTranslation('tasksSeller');
  const {
    task,
    assessmentResult,
    resetStore,
    fetchTask,
  } = useFillTaskStore(selectStoreData, shallow);

  if (task.assessmentMethod === AssessmentMethodEnum.Photo) return (
    <AppContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h1"
        mb={1.5}
      >
        {t('fill.result.titlePhoto')}
      </Typography>
      <Typography
        variant="body1"
        mb={4}
      >
        {t('fill.result.infoPhoto')}
      </Typography>
      <Button
        component={RouterLink}
        to={`/${ROUTES.tasks}`}
        size="large"
        variant="contained"
        color="primary"
        sx={{ width: { xs: '100%', lg: 'unset', }, }}
      >
        {t('fill.result.btnDone')}
      </Button>
    </AppContainer>
  );

  const isCompleted = assessmentResult.pointsEarned >= assessmentResult.pointsLimit;
  const isTest = task.assessmentMethod === AssessmentMethodEnum.Test;
  const infoKey = isTest
    ? isCompleted
      ? 'tasksSeller:fill.result.infoSuccessTest'
      : 'tasksSeller:fill.result.infoErrorTest'
    : 'tasksSeller:fill.result.infoSurvey';
  const yourPoints = assessmentResult.pointsEarned;
  const canTryAgain = assessmentResult.canComplete;

  const handleTryAgain = () => {
    resetStore();
    fetchTask(task.id);
  };

  return (
    <AppContainer maxWidth="sm">

      <Typography
        component="h1"
        variant="h1"
        mb={1.5}
      >
        <span>
          {t(isCompleted ? 'fill.result.titleCompleted' : 'fill.result.titleFailed')}
        </span>
        {isCompleted ? (
          <CheckOutlinedIcon
            sx={{ ml: 0.5, fontSize: '1em', color: 'success.main', verticalAlign: 'middle', }}
          />
        ) : (
          <CrossOutlinedIcon
            sx={{ ml: 0.5, fontSize: '1em', color: 'error.main', verticalAlign: 'middle', }}
          />
        )}
      </Typography>
      <Typography
        variant="body1"
        mb={4}
      >
        <Trans
          i18nKey={infoKey}
          components={{
            strong: <strong />,
          }}
          values={{
            minimumPoints: task.pointsLimit,
          }}
        />        
      </Typography>

      {isTaskPointsEnabledMarket && (
        <PointsBox isCompleted={isCompleted}>
          <Typography variant="body0" component="div">
            {t(
              isTest
                ? 'fill.result.boxTestResult'
                : 'fill.result.boxSurveyResult',
            )}
          </Typography>
          <Typography variant="body0" component="div" fontWeight="bold">
            {t(
              isTest
                ? 'fill.result.boxTestPoints'
                : 'fill.result.boxSurveyPoints',
              {
                points: yourPoints,
                maxPoints: task.pointsForCompletion,
              },
            )}
          </Typography>
        </PointsBox>
      )}

      {canTryAgain && (
        <Typography
          mt={1}
        >
          <ErrorFilledIcon sx={{ mr: 0.5, fontSize: '1em', color: isCompleted ? 'inherit' : 'error.main' , verticalAlign: 'middle', }} />
          <span>
            {t('fill.result.infoImproveScore')}
          </span>
        </Typography>
      )}

      <Box mt={4}>
        <Grid container spacing={2} alignItems="center">
          {canTryAgain && (
            <Grid item xs={12} lg="auto">
              <Button
                size="large"
                sx={{ width: { xs: '100%', lg: 'unset', }, }}
                onClick={handleTryAgain}
              >
                {t('fill.result.btnTryAgain')}
              </Button>
            </Grid>
          )}
          <Grid item xs={12} lg="auto">
            <Button
              component={RouterLink}
              to={`/${ROUTES.tasks}`}
              size="large"
              variant={isTest ? 'outlined' : 'contained'}
              color="primary"
              sx={{ width: { xs: '100%', lg: 'unset', }, }}
            >
              {t(isTest ? 'fill.result.btnGoToTasks' : 'fill.result.btnDone')}
            </Button>
          </Grid>
        </Grid>
      </Box>

    </AppContainer>
  );
};

export default PhaseResult;
