import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const PhoneIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 36 36"
    fill="none"
    {...props}
  >
        <path
      d="M4.406 22.39c1.81 4.045 4.682 6.91 8.653 8.697-.033-.765-.116-1.488-.088-2.207a5.765 5.765 0 0 1 1.789-3.94 50.48 50.48 0 0 1 1.81-1.75 4.506 4.506 0 0 1 6.158.127 386.312 386.312 0 0 1 5.198 5.169c1.802 1.825 1.226 4.57-1.186 5.44-7.793 2.805-14.815 1.487-20.83-4.241C2.486 26.425.676 22.29.258 17.567A19.22 19.22 0 0 1 1.584 8.73c.889-2.26 3.58-2.888 5.322-1.203a308.706 308.706 0 0 1 5.166 5.124c1.852 1.88 1.926 4.495.138 6.436a18.667 18.667 0 0 1-2.767 2.542c-1.327.954-2.883 1.158-4.496.82-.154-.023-.311-.032-.541-.06Z"
      fill="#000"
    />
    <path
      d="M17.262.346c7.661.14 14.541 5.206 16.925 12.31a18.84 18.84 0 0 1 .998 5.843 1.428 1.428 0 0 1-1.022 1.455 1.347 1.347 0 0 1-1.554-.488 1.996 1.996 0 0 1-.327-1.01 15.195 15.195 0 0 0-1.162-5.638c-2.217-5.094-6.044-8.231-11.518-9.295a21.08 21.08 0 0 0-2.439-.26c-1.066-.083-1.692-.624-1.67-1.482.024-.899.703-1.45 1.751-1.435h.018Z"
      fill="#fff"
    />
    <path
      d="M29.345 18.024c.063.757-.026 1.433-.788 1.831-.98.512-2.067-.146-2.117-1.313a9.711 9.711 0 0 0-.99-4.107c-1.73-3.35-4.489-5.12-8.245-5.323-.69-.038-1.265-.22-1.572-.876a1.416 1.416 0 0 1 1.313-2.033 11.717 11.717 0 0 1 5.243 1.151c3.763 1.819 6.037 4.827 6.935 8.884.129.582.142 1.19.22 1.786Z"
      fill="#fff"
    />
    <path
      d="M17.227 12.383c3.163.07 5.91 2.895 5.91 6.072 0 .716-.438 1.193-1.12 1.215-.56.017-1.02-.482-1.065-1.155-.15-2.235-1.687-3.815-3.835-3.94-.79-.047-1.294-.49-1.28-1.124.013-.634.582-1.085 1.39-1.068Z"
      fill="#fff"
    />
  </SvgIcon>
);

export default PhoneIcon;
