import { PromotionFragment, usePromotionQuery, } from 'Apollo/graphql';
import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import Logic from './Logic';

interface Props {
  promotion?: PromotionFragment | null,
  onClose: () => void,
};

const FetchData = ({
  promotion,
  onClose,
}: Props): JSX.Element => {
  const preservedPromotion = usePreserveValue(promotion);

  const { loading, error, data, } = usePromotionQuery({
    variables: { id: preservedPromotion.id, },
    fetchPolicy: 'network-only',
  });

  if (loading) return (
    <ModalDrawerBodyLoading
      onClose={onClose}
    />
  );
  if (error || !data?.promotion) return (
    <ModalDrawerBodyError
      onClose={onClose}
      error={error}
    />
  );

  return (
    <Logic
      promotion={data.promotion}
      onClose={onClose}
    />
  );
};

export default FetchData;
