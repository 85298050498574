import shallow from 'zustand/shallow';
import { FormProvider, useForm, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import AppContainer from 'Components/AppContainer';
import { CreateTaskStore, MethodValues, useCreateTaskStore, } from 'Modules/TasksAdmin/store/createTaskStore';
import { methodValidationSchema, } from './validationSchema';
import View from './View';

const selectStoreData = (s: CreateTaskStore) => ({
  completeMethodPhase: s.completeMethodPhase,
  initValues: s.methodValues,
  goBackToPhase: s.goBackToPhase,
});

const PhaseMethod = (): JSX.Element => {
  const { completeMethodPhase, initValues, goBackToPhase, } = useCreateTaskStore(selectStoreData, shallow);

  const methods = useForm<MethodValues>({
    defaultValues: initValues,
    resolver: yupResolver(methodValidationSchema),
    mode: 'onSubmit',
  });

  const handleSubmit = methods.handleSubmit((values) => {
    completeMethodPhase(values);
  });

  const handleBack = () => {
    goBackToPhase('type');
  };

  return (
    <AppContainer maxWidth="sm">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <View onBack={handleBack} />
        </form>
      </FormProvider>
    </AppContainer>
  );
};

export default PhaseMethod;
