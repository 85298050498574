import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CreateReplacementMutationResult, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { FormError, FormSubmit, } from 'Components/Form';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { useTranslation, } from 'react-i18next';
import { CreateReplacementPhase, CreateReplacementStore, ReplacementRegistration, } from '../createReplacementStore';
import CreateTicketForm from './CreateTicketForm';

interface Props {
  customer: CreateReplacementStore['customer'],
  onClose: () => void,
  createReplacementMutation: CreateReplacementMutationResult,
  replacementRegistration: ReplacementRegistration | null,
  goBackToPhase: (phase:CreateReplacementPhase)=>void,
}

const View = ({
  onClose,
  customer,
  createReplacementMutation,
  replacementRegistration,
  goBackToPhase,
}: Props): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>
        {t('createReplacementModal.phaseCreateTicket.title')}
      </ModalDrawerTitle>

      <ModalDrawerBody>
        <Typography
          variant="body1"
          mb={3}
        >
          {t('createReplacementModal.phaseCreateTicket.info')}
        </Typography>
        
        <CreateTicketForm
          customer={customer}
        />

        <Box mt={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <FormSubmit
                size="large"
              >
                {t('createReplacementModal.phaseCreateTicket.btnSubmit')}
              </FormSubmit>
            </Grid>
            <Grid item>
              {replacementRegistration ? <Button
                size="large"
                variant="outlined"
                color="inherit"
                onClick={()=>goBackToPhase('replacementRegistration')}
              >
                {t('common:actions.back')}
              </Button> : <Button
                size="large"
                variant="outlined"
                color="inherit"
                onClick={onClose}
              >
                {t('common:actions.cancel')}
              </Button>}
            </Grid>
          </Grid>
        </Box>
        <FormError mt={2} />
        <ErrorAlert error={createReplacementMutation.error} mt={2} />
      </ModalDrawerBody>
    </>
  );
};

export default View;
