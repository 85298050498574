import { Descendant, } from 'slate';

export const emptyValueRichText: Descendant[] = [
  {
    type: 'paragraph',
    children: [ { text: '', }, ],
  },
];

// Before you ask read this!!!
// there will be no database migrations for news (but why? i am not allowed to ask)
// old news version has data as text string
// new news version has data as descendant[] string
// problem is that, there is no way how to know if string is text string or descendant[] string :D, enjoy life
// solution: when JSON.parse throws error, it is "probably" text string :D
export const stringToRichText = (data?: string | null): Descendant[] => {
  if (!data) return emptyValueRichText;

  try {
    return JSON.parse(data);
  } catch {
    // hopefully this is text string
    return [
      {
        type: 'paragraph',
        children: [ { text: data, }, ],
      },
    ];
  }
};

// OLD version (correct one)
// export const stringToRichText = (value: string | null): Descendant[] => {
//   try {
//     if (value) return JSON.parse(value);
//     return emptyValueRichText;
//   } catch {
//     // ignore error
//   }

//   return emptyValueRichText;
// };

export const richTextToString = (value: Descendant[] | null): string => value ? JSON.stringify(value) : '';
