import Divider from '@mui/material/Divider';
import { AssortmentCategoryOptionFragment, } from 'Apollo/graphql';
import {
  FormInputAutocomplete, FormInputInt,
} from 'Components/Form';
import { useEffect, } from 'react';
import { useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { getOptionFieldName, renderOptionById, } from 'Utils/helpers';

interface Props {
    assortmentCategoryValues: AssortmentCategoryOptionFragment[],
    index: number,
};
const areOptionsEqualById = (option:AssortmentCategoryOptionFragment, value:AssortmentCategoryOptionFragment) => true;

const AssortmentCategoryChunk = ({
  assortmentCategoryValues,
  index,
}: Props): JSX.Element => {
  const { t, } = useTranslation('promotionsAdmin');
  const name = `assortmentCategory.${index}`;
  const { register, } = useForm();
  useEffect(() => {
    register(`${name}.option`);
    register(`${name}.count`);
  }, [name,register,]);

  return (
    <>
        <Divider sx={{mb:'1rem',}} />
        <FormInputAutocomplete<AssortmentCategoryOptionFragment, true>
          name={`${name}.option`}
          getOptionLabel={getOptionFieldName}
          isOptionEqualToValue={areOptionsEqualById}
          renderOption={renderOptionById(getOptionFieldName)}
          options={assortmentCategoryValues || []}
          label={t('promotion.labelCategory')}
          placeholder={t('common:select.placeholder')}
        />
        <FormInputInt
          sx={{mt:'1rem',mb:'2rem',}}
          name={`${name}.count`}
          label={t('promotion.labelQuantity')}
          required
        />
    </>
  );
};

export default AssortmentCategoryChunk;
