import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  PointOfSellOptionFragment,
  useGetQrCodeForPointOfSellQuery,
  usePointsOfSellListQuery,
} from 'Apollo/graphql';
import InputAutocompleteClassic from 'Components/Inputs/InputAutocompleteClassic';
import { useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import {
  areOptionsEqualById,
  getOptionFieldName,
  renderOptionByIdName,
} from 'Utils/helpers';
import { CircularProgress, } from '@mui/material';
import RenderQr from '../RenderQr';

const View = (): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const user = useAuthUser();

  const [posSelection, setPosSelection,] =
    useState<PointOfSellOptionFragment | null>(null);

  const { loading, error, data, } = usePointsOfSellListQuery({
    fetchPolicy: 'cache-and-network',
    variables: { chainId: user?.chainId, },
  });
  const { loading: qrCodeLoading, data: qrCodeData, } =
    useGetQrCodeForPointOfSellQuery({
      fetchPolicy: 'no-cache',
      skip: !posSelection?.id,
      // WHY: non-null assertion used here becase if doesn't exist it doesn't run
      variables: { posId: posSelection?.id!, },
    });

  const handleChange = (
    e: unknown,
    newValue: PointOfSellOptionFragment | null,
  ) => {
    setPosSelection(newValue);
  };

  return (
    <Grid container spacing={3} sx={{ pt: '24px', }}>
      <Grid item xs={12}>
        <InputAutocompleteClassic<
          PointOfSellOptionFragment,
          false,
          false,
          false
        >
          value={posSelection}
          onChange={handleChange}
          label={t('registrationSeller:phaseInformationVerification.labelPos')}
          error={!!error}
          loading={loading}
          getOptionLabel={getOptionFieldName}
          renderOption={renderOptionByIdName}
          isOptionEqualToValue={areOptionsEqualById}
          options={data?.pointsOfSellList.data || []}
          placeholder={t('common:select.placeholder')}
          required
        />
      </Grid>
      {qrCodeLoading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {qrCodeData?.getQrCodeForPointOfSell.url && (
        <Grid item xs={12}>
          <RenderQr value={qrCodeData?.getQrCodeForPointOfSell.url} />
        </Grid>
      )}
    </Grid>
  );
};

export default View;
