import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormSubmit, FormError, FormInputCheckbox, } from 'Components/Form';
import { MethodValues, ParametersFormConfig, TypeValues, } from 'Modules/TasksAdmin/store/createTaskStore';
import FormParameters from './FormParameters';

type Props = {
  parametersFormConfig: ParametersFormConfig,
  typeValues: TypeValues,
  methodValues: MethodValues,
  onBack: () => void,
};

const View = ({
  parametersFormConfig,
  typeValues,
  methodValues,
  onBack,
}: Props): JSX.Element => {
  const { t ,} = useTranslation('tasksAdmin');
  const type = typeValues.type ? t(typeValues.type.labelKey, typeValues.type.id) : '';
  const method = methodValues.method ? t(methodValues.method.labelKey, methodValues.method.id) : '';

  return (
    <>
      <Typography variant="h1" mb={1.5}>
        {t('creation.parameterPhase.title', { method, type, })}
      </Typography>
      <Typography mb={5}>
        {t('creation.parameterPhase.description', { method, type, })}
      </Typography>
      
      <Box mb={4}>
        <FormParameters
          parametersFormConfig={parametersFormConfig}
        />
      </Box>

      <Grid item xs={12}>
        <FormInputCheckbox name="notificationsVisible" label={<Typography>{t('common:notification.createPopUpNotification')}</Typography>} />
      </Grid>

      <Grid container spacing={2}>
        <Grid item>
          <FormSubmit size="large" variant="contained" color="primary">
            {t('common:actions.confirm')}
          </FormSubmit>
        </Grid>
        <Grid item>
          <Button size="large" variant="outlined" color="inherit" onClick={onBack}>
            {t('common:actions.cancel')}
          </Button>
        </Grid>
      </Grid>

      <FormError mt={2} />
    </>
  );
};

export default View;
