import { SvgIcon, SvgIconProps, } from '@mui/material';

const DashboardPersonalDashboardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6.66668C6 6.29868 6.29867 6.00002 6.66667 6.00002H10C10.368 6.00002 10.6667 6.29868 10.6667 6.66668V11.3333C10.6667 11.7013 10.368 12 10 12H6.66667C6.29867 12 6 11.7013 6 11.3333V6.66668Z"
        stroke="#D47E5F"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15.3333C6 14.9653 6.29867 14.6667 6.66667 14.6667H10C10.368 14.6667 10.6667 14.9653 10.6667 15.3333V17.3333C10.6667 17.7013 10.368 18 10 18H6.66667C6.29867 18 6 17.7013 6 17.3333V15.3333Z"
        stroke="#D47E5F"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 12.6667C13.3333 12.2987 13.632 12 14 12H17.3333C17.7013 12 18 12.2987 18 12.6667V17.3333C18 17.7013 17.7013 18 17.3333 18H14C13.632 18 13.3333 17.7013 13.3333 17.3333V12.6667Z"
        stroke="#D47E5F"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 6.66668C13.3333 6.29868 13.632 6.00002 14 6.00002H17.3333C17.7013 6.00002 18 6.29868 18 6.66668V8.66668C18 9.03468 17.7013 9.33335 17.3333 9.33335H14C13.632 9.33335 13.3333 9.03468 13.3333 8.66668V6.66668Z"
        stroke="#D47E5F"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 3.33333C2 2.59733 2.59733 2 3.33333 2H20.6667C21.4027 2 22 2.59733 22 3.33333V20.6667C22 21.4036 21.4027 22 20.6667 22H3.33333C2.59733 22 2 21.4036 2 20.6667V3.33333Z"
        stroke="black"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default DashboardPersonalDashboardIcon;
