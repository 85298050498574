import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CreateAlzaVouchersMutationResult, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { FormInputFileUpload, FormInputText, FormSubmit, } from 'Components/Form';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import { CSV_MAX_SIZE, IMAGE_MAX_SIZE, } from 'Utils/constants';
import { useTranslation, } from 'react-i18next';

interface Props {
  onClose: () => void;
  importMutationResult: CreateAlzaVouchersMutationResult;
}

const FormView = ({ onClose, importMutationResult, }: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');

  return (
    <ModalDrawerBody>
      {!importMutationResult.data && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormInputText name="name" label={t('vouchers.form.name')} required />
          </Grid>
          <Grid item xs={12}>
            <FormInputFileUpload name="image" label={t('vouchers.form.preview')} required fileType="image" maxSize={IMAGE_MAX_SIZE} />
          </Grid>
          <Grid item xs={12}>
            <FormInputText name="description" label={t('vouchers.form.description')} required multiline minRows={4} maxRows={6} />
          </Grid>
          <Grid item xs={12}>
            <FormInputFileUpload label={t('vouchers.form.file')} name="file" fileType="csv" maxSize={CSV_MAX_SIZE} />
          </Grid>
          <Grid item>
            <FormSubmit size="large">{t('createVoucherModal.btnSubmit')}</FormSubmit>
          </Grid>
        </Grid>
      )}

      {importMutationResult.data && (
        <Box mt={2}>
          <NotEditableTextClassic label={t('createVoucherModal.importForm.changes')}>
            <Typography variant="h4">{t('createVoucherModal.importForm.numberOfErrors')}</Typography>
            <Typography marginBottom={2}>{importMutationResult.data.importAlzaVouchers.numberOfErrors}</Typography>
            <Button color="neutral" component="a" download href={importMutationResult.data.importAlzaVouchers.reportFile.publicUrl}>
              {t('createVoucherModal.importForm.btnDownload')}
            </Button>
          </NotEditableTextClassic>
        </Box>
      )}

      <Box mt={4}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Button size="large" variant="outlined" color="inherit" onClick={onClose}>
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ErrorAlert mt={2} error={importMutationResult.error} />
    </ModalDrawerBody>
  );
};

export default FormView;
