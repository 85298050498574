import Grid from '@mui/material/Grid';
import { styled, } from '@mui/material/styles';
import { MarketEnum, PreviouslyOwnedDevicesOptionFragment, PurchasedProductsForGqlDbOptionFragment, useCurrentMarketQuery, } from 'Apollo/graphql';
import { FormInputAutocomplete, FormInputCheckbox, FormInputDate, FormInputGroupRadio, FormInputPhoneNumber, FormInputText, } from 'Components/Form';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import PulzeOn from 'Modules/RegistrationSeller/pages/RegistrationSellerPage/PhaseRegisterCustomer/PulzeOn';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import { renderOptionById, } from 'Utils/helpers';
import { GenderTitleOption, useOptionsGenderTitle, } from 'Utils/options/useOptionsGenderTitle';
import { useOptionsPreviouslyOwnedDevice, } from 'Utils/options/useOptionsPreviouslyOwnedDevice';
import { useOptionsPurchasedProduct, } from 'Utils/options/useOptionsPurchasedProduct';
import { useOptionsUserVerification, UserVerificationOption, } from 'Utils/options/useOptionsUserVerification';
import ImageAsync from 'Components/ImageAsync';
import { positionUrl, } from 'Utils/deviceCodeImages';
import { Typography, } from '@mui/material';
import TermsAndConditions from './TermsAndConditions';
import { FormConfig, } from './types';

const ImageWrapper = styled('div')(() => ({
  position: 'relative',
  minHeight: 200,
  width: '100%',
  '& > img': {
    position: 'relative',
  },
}));

interface Props {
  formConfig: FormConfig;
  registrationEmail: string;
}

const CustomerRegistrationForm = ({ formConfig, registrationEmail, }: Props): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  const authUser = useAuthUser();
  const genderTitleOptions = useOptionsGenderTitle();
  const purchasedProductOptions = useOptionsPurchasedProduct();
  const previouslyOwnedDeviceOptions = useOptionsPreviouslyOwnedDevice();
  const userVerificationOptions = useOptionsUserVerification();
  const showPulzeOn = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PULZE_ON;
  const currentMarket = useCurrentMarketQuery();
  const deviceCodeImageUrl = positionUrl(currentMarket.data?.currentMarket);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('createReplacementModal.phaseCustomerRegistration.form.labelEmail')}>
          {registrationEmail}
        </NotEditableTextClassic>
      </Grid>
      {formConfig.enableTitle && (
        <Grid item xs={12}>
          <FormInputAutocomplete<GenderTitleOption>
            name="title"
            getOptionLabel={genderTitleOptions.getOptionLabel}
            isOptionEqualToValue={genderTitleOptions.isOptionEqualToValue}
            options={genderTitleOptions.options}
            label={t('createReplacementModal.phaseCustomerRegistration.form.labelTitle')}
            required
            renderOption={renderOptionById(genderTitleOptions.getOptionLabel)}
            placeholder={t('createReplacementModal.phaseCustomerRegistration.form.placeholderTitle')}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormInputText
          name="firstName"
          required
          label={t('createReplacementModal.phaseCustomerRegistration.form.labelFirstName')}
          id="new-first-name"
          autoComplete="new-first-name"
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="lastName"
          required
          label={t('createReplacementModal.phaseCustomerRegistration.form.labelLastName')}
          id="new-last-name"
          autoComplete="new-last-name"
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputDate name="dateOfBirth" required label={t('createReplacementModal.phaseCustomerRegistration.form.labelDateOfBirth')} />
      </Grid>
      <Grid item xs={12}>
        <FormInputPhoneNumber
          namePhone="phone"
          required
          namePrefix="phonePrefix"
          label={t('createReplacementModal.phaseCustomerRegistration.form.labelPhone')}
          id="nope"
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputGroupRadio<PurchasedProductsForGqlDbOptionFragment>
          name="purchasedProducts"
          label={t('createReplacementModal.phaseCustomerRegistration.form.labelPurchasedProducts')}
          options={purchasedProductOptions.options}
          getOptionKey={purchasedProductOptions.getOptionKey}
          getOptionLabel={purchasedProductOptions.getOptionLabel}
          loading={purchasedProductOptions.loading}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputGroupRadio<PreviouslyOwnedDevicesOptionFragment>
          name="previouslyOwnedDevices"
          label={t('createReplacementModal.phaseCustomerRegistration.form.labelPreviouslyOwnedDevices')}
          options={previouslyOwnedDeviceOptions.options}
          getOptionKey={previouslyOwnedDeviceOptions.getOptionKey}
          getOptionLabel={previouslyOwnedDeviceOptions.getOptionLabel}
          loading={previouslyOwnedDeviceOptions.loading}
          required
        />
      </Grid>
      {formConfig.currentMarket === MarketEnum.Hu && (
        <>
          <Grid item xs={12}>
            <FormInputText
              name="deviceCode"
              label={t('createReplacementModal.phaseCustomerRegistration.form.labelDeviceCode')}
              id="device-code"
              autoComplete="device-code"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mt: 2, mb: 3, }}>
              {t('createReplacementModal.phaseCustomerRegistration.form.deviceCodeInfo')}
            </Typography>
            <ImageWrapper>
              <ImageAsync src={deviceCodeImageUrl} />
            </ImageWrapper>
          </Grid>
        </>
      )}
      {formConfig.currentMarket === MarketEnum.Cz && (
        <>
          <Grid item xs={12}>
            <FormInputCheckbox name="acceptedTermsAndConditions" required label={<TermsAndConditions />} />
          </Grid>
          {showPulzeOn && (
            <Grid item xs={12}>
              <FormInputCheckbox name="acceptedPulzeOn" label={<PulzeOn />} />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormInputCheckbox name="acceptedPulzeCare" label={t('registrationSeller:phaseRegisterCustomer.form.labelAcceptedPulzeCare')} />
          </Grid>
        </>
      )}
      {formConfig.currentMarket !== MarketEnum.Cz && (
        <>
          {showPulzeOn && (
            <Grid item xs={12}>
              <FormInputCheckbox name="acceptedPulzeOn" required label={<PulzeOn />} />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormInputCheckbox name="acceptedTermsAndConditions" required label={<TermsAndConditions />} />
          </Grid>
          <Grid item xs={12}>
            <FormInputCheckbox name="acceptedPulzeCare" required label={t('registrationSeller:phaseRegisterCustomer.form.labelAcceptedPulzeCare')} />
          </Grid>
        </>
      )}
      {formConfig.verificationMethods && formConfig.verificationMethods.length > 1 && (
        <Grid item xs={12}>
          <FormInputGroupRadio<UserVerificationOption>
            name="verificationMethod"
            label={t('registrationSeller:phaseRegisterCustomer.form.labelVerificationMethod')}
            options={
              formConfig.verificationMethods.map((option) =>
                userVerificationOptions.options.find((userVerificationOption) => userVerificationOption.id === option),
              ) as UserVerificationOption[]
            }
            getOptionKey={userVerificationOptions.getOptionKey}
            getOptionLabel={userVerificationOptions.getOptionLabel}
            required
          />
        </Grid>
      )}{' '}
    </Grid>
  );
};

export default CustomerRegistrationForm;
