import { useParams, } from 'react-router-dom';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { useTaskCompletionQuery, } from 'Apollo/graphql';
import LoadingSkeleton from './LoadingSkeleton';
import Logic from './Logic';

const FetchData = (): JSX.Element => {
  const { taskId, } = useParams();

  const { loading, error, data, } = useTaskCompletionQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: taskId as string,
    },
  });

  if (loading && !data?.taskCompletion || !data?.taskCompletionFiles) return <LoadingSkeleton />;
  if (error || !data?.taskCompletion) return (
    <ErrorAlert
      forceError
      error={error}
    />
  );
  return (
    <Logic
      taskCompletion={data.taskCompletion}
      taskCompletionFiles={data.taskCompletionFiles}
    />
  );
};

export default FetchData;
