import cubejs, { Query, ResultSet, } from '@cubejs-client/core';
import CircularProgress from '@mui/material/CircularProgress';
import BigNumbers from 'Components/Dashboard/BigNumbers';
import { QueryRenderer, } from '@cubejs-client/react';
import Stack from '@mui/material/Stack';
import { styled, } from '@mui/material/styles';
import { useTranslation, } from 'react-i18next';
import PieChartRenderer from './PieChartRenderer';
import BarChartRenderer from './BarChartRenderer';
import { LegendPosition, ChartType, PivotConfig, } from './types';
import TableChartRenderer from './TableChartRenderer';
import { getAccessToken, } from '../../Tools/auth';

interface ChartRendererProps {
  query: Query;
  type: ChartType;
  legendPosition?: LegendPosition;
  pivotConfig?: PivotConfig;
  numberOneLabel?: string;
  numberTwoLabel?: string;
  showTotal?: boolean;
  hideNullValues?: boolean;
  hideUnknownValues?: boolean;
  orderByDate?: boolean | undefined
  hideLegend?: boolean | undefined;
  noDataMessage?: string;
  errorMessage?: string;
}

type RenderChartProps = Omit<ChartRendererProps, 'query'> & {
  resultSet: ResultSet | null;
  error: unknown;
  type: ChartType;
};

const StyledHeader = styled('h3')(({ theme, }) => ({
  margin: 0,
  fontSize: 16,
}));

const StyledMessage = styled('h4')(()=>({
  textAlign: 'center',
  color: '#5b5b5b',
}));


const Total = ({ resultSet, }: { resultSet: ResultSet }) => {
  const { t, } = useTranslation('dashboards');
  return (
    <span>
      {t('dashboards.total')}:{' '}
      {resultSet.seriesNames().map((s: { key: string }) =>
        resultSet
          .rawData()
          .reduce((acc: number, row: { [key: string]: string }) => {
          const key = s.key.split(',').length > 1 ? s.key.split(',')[1] : s.key;
            return acc + Number(row[key]);
          }, 0),
      )[0]}
    </span>
  );
};

const RenderChart = ({
  resultSet,
  error,
  type,
  legendPosition,
  pivotConfig,
  numberOneLabel,
  numberTwoLabel,
  showTotal,
  hideNullValues,
  hideUnknownValues,
  orderByDate,
  hideLegend,
  noDataMessage,
  errorMessage,
}: RenderChartProps) => {
  if (resultSet?.rawData().length === 0) {
    return <StyledMessage>{noDataMessage}</StyledMessage>;
  }

  if (error) {
    <StyledMessage>{errorMessage}</StyledMessage>;
  }

  if (!resultSet) {
    return <CircularProgress sx={{ margin: '0 auto', display: 'block', }} />;
  }

  if (type === 'pie') {
    return (
      <>
        {showTotal && <Total resultSet={resultSet} />}
        <PieChartRenderer
          resultSet={resultSet}
          legendPosition={legendPosition}
          hideNullValues={hideNullValues}
          hideUnknownValues={hideUnknownValues}
        />
      </>
    );
  }
  if (type === 'number') {
    return (
      <>
        {resultSet.seriesNames().map((s: { key: string }) => (
          <BigNumbers key={s.key} number={resultSet.totalRow()[s.key]} />
        ))}
      </>
    );
  }
  if (type === 'bar') {
    return (
      <>
        {showTotal && <Total resultSet={resultSet} />}
        <BarChartRenderer
          resultSet={resultSet}
          pivotConfig={pivotConfig ?? null}
          hideNullValues={hideNullValues}
          hideUnknownValues={hideUnknownValues}
          orderByDate={orderByDate}
          hideLegend={hideLegend}
        />
      </>
    );
  }
  if (type === 'doubleNumber') {
    return (
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <StyledHeader>{numberOneLabel}</StyledHeader>
          {resultSet.seriesNames().map((s: { key: string }) => (
            <BigNumbers key={s.key} number={resultSet.totalRow()[s.key]} />
          ))}
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <StyledHeader>{numberTwoLabel}</StyledHeader>
          {resultSet.seriesNames().map((s: { key: string }) => (
            <BigNumbers key={s.key} number={resultSet.totalRow()[s.key]} />
          ))}
        </Stack>
      </Stack>
    );
  }

  if (type === 'table' && pivotConfig) {
    return (
      <TableChartRenderer
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        hideNullValues={hideNullValues ?? false}
      />
    );
  }
  return <div />;
};

const cubejsApi = cubejs(() => Promise.resolve(getAccessToken() as string), {
  apiUrl: import.meta.env.VITE_APP_CUBEJS_API_URL ?? '',
});

const ChartRenderer = ({
  query,
  type,
  legendPosition,
  pivotConfig,
  numberOneLabel,
  numberTwoLabel,
  showTotal = false,
  hideNullValues,
  hideUnknownValues,
  orderByDate,
  hideLegend,
  noDataMessage,
  errorMessage,
}: ChartRendererProps) => {
  const { t, } = useTranslation('dashboards');
  return (
    <QueryRenderer
      query={query}
      cubejsApi={cubejsApi}
      resetResultSetOnChange={false}
      render={(props) =>
        RenderChart({
          ...props,
          resultSet: props.resultSet,
          type,
          legendPosition,
          pivotConfig,
          numberOneLabel,
          numberTwoLabel,
          showTotal,
          hideNullValues,
          hideUnknownValues,
          orderByDate,
          hideLegend,
          errorMessage: errorMessage ?? t('dashboards.error'),
          noDataMessage: noDataMessage ?? t('dashboards.noData'),
        })
      }
    />
  );
};

export default ChartRenderer;
