import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { TaskStore, useTaskStore, } from 'Modules/TasksAdmin/store/GeneralTasksStore';
import FetchData from './FetchData';

const selectModalData = (s: TaskStore) => s.modalExportTask;
const selectClose = (s: TaskStore) => s.closeModalExportTask;

const ModalExportTask = (): JSX.Element => {
  const { isOpen, task, } = useTaskStore(selectModalData);
  const onClose = useTaskStore(selectClose);

  return (
    <ModalDrawer onClose={onClose} open={isOpen}>
      <FetchData
        task={task}
        onClose={onClose}
      />
    </ModalDrawer>
  );
};

export default ModalExportTask;
