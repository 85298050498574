import moment from 'moment';
import { TFunction } from 'react-i18next';

const getTranslationText = (
  t: TFunction<'translation', undefined>,
  text: string,
) => {
  if (moment(text).isValid()) {
    return text;
  }
  if (text.toLocaleLowerCase().includes('expiration date')) {
    return t('dashboards:dashboards.cube.Expiration Date');
  }
  if (text.toLocaleLowerCase().includes('createdat')) {
    return t('dashboards:dashboards.cube.Createdat');
  }
  // pl gift codes always have chain name at the start
  if (text.toLocaleLowerCase().includes(', imported_pl_gift_code')) {
    return text.split(', ')[0];
  }
  if (text.toLocaleLowerCase().includes('is active')) {
    return t('dashboards:dashboards.cube.Is Active');
  }
  const commonTranslation = t(`common:options.userRoles.${text}`);
  const dashboardsTranslation = t(`dashboards:dashboards.cube.${text}`);
  if (commonTranslation !== `options.userRoles.${text}`) {
    return commonTranslation;
  }
  if (dashboardsTranslation !== `dashboards.cube.${text}`) {
    return dashboardsTranslation;
  }
  return text;
};

export default getTranslationText;
