import { ReactNode, } from 'react';
import RichText, { RichTextProps, } from 'Components/RichText';
import InputLabelClassic from './components/InputLabelClassic';
import FormHelperTextClassic from './components/FormHelperTextClassic';

export interface InputRichTextClassicProps extends Omit<RichTextProps, 'readOnly'> {
  label?: ReactNode,
  helperText?: ReactNode,
  required?: boolean,
};

const InputRichTextClassic = ({
  initValue,
  onChange,
  placeholder,
  error,
  label,
  helperText,
  required,
}: InputRichTextClassicProps): JSX.Element => {
  return (
    <div>
      {label && (
        <InputLabelClassic
          shrink
          color="primary"
          required={required}
        >
          {label}
        </InputLabelClassic>
      )}
      <RichText
        initValue={initValue}
        onChange={onChange}
        placeholder={placeholder}
        error={error}
      />
      {(helperText) && (
        <FormHelperTextClassic
          error={error}
        >
          {helperText}
        </FormHelperTextClassic>
      )}
    </div>
  );
};

export default InputRichTextClassic;
