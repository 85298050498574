import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';

export type CodeValidity = {
    isValid: boolean,
};
export type PhaseInitFormValues = {
  giftCode: string;
};

export const phaseInitFormValidationSchema = yup.object().shape({
  giftCode: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(255, INPUT_FORM_ERRORS.STRING_MAX),
});

type InitPhaseBadFormDataFieldMapper = {
  giftCode: undefined | keyof PhaseInitFormValues;
};

export const initPhaseBadFormDataFieldMapper: InitPhaseBadFormDataFieldMapper = {
  giftCode: 'giftCode',
};
