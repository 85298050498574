import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useTranslation, } from 'react-i18next';
import { useMemo, } from 'react';
import { reDoiQuery, } from './queries';

const ReDoi = ({ showAddButton,  filtration, showSlug = true, chartFilter, timeDimensions, }: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');

  const query = useMemo(
    () =>
      reDoiQuery({
        timeDimensions,
        filters: chartFilter,
      }),
    [chartFilter, timeDimensions,],
  );

  return (
    <Base
      headerText={t('dashboards.registrations.redoi')}
      slug={showSlug ? 're-doi' : undefined}
      showAddButton={showAddButton}
      
      filtration={filtration}
      dashboardLayoutItemName={DashboardLayoutItemName.RegistrationsReDoi}
    >
      <ChartRenderer
        query={query}
        pivotConfig={{
          x: ['customer.dob.month',],
          y: ['measures',],
          fillMissingDates: true,
          joinDateRange: false,
        }}
        type={ChartType.bar}
      />
    </Base>
  );
};

export default ReDoi;
