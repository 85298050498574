import { useFormContext, useController, } from 'react-hook-form';
import InputDateClassic, {
  InputDateClassicProps,
} from 'Components/Inputs/InputDateClassic';
import { useFormInputStatus, } from '../utils/useFormInputStatus';
import { InputFormErrorMessage, } from '../types';

interface Props
  extends Omit<
    InputDateClassicProps,
    'value' | 'name' | 'onChange' | 'error' | 'id'
  > {
  name: string;
}

const FormInputDate = ({ name, helperText, ...rest }: Props): JSX.Element => {
  const { control, } = useFormContext();
  const {
    field: { value, onBlur: onBlurField, onChange: onChangeField, },
    fieldState: { error, },
  } = useController({
    name,
    control,
  });

  const inputStatus = useFormInputStatus(
    error?.message as InputFormErrorMessage | undefined,
    helperText,
  );

  const handleChangeValue = (newValue: Date | null) => {
    onChangeField(newValue);
    onBlurField();
  };

  return (
    <InputDateClassic
      value={value as Date | null}
      onChange={handleChangeValue as (value: unknown) => void}
      helperText={inputStatus.text}
      error={inputStatus.isError}
      {...rest}
    />
  );
};

export default FormInputDate;
