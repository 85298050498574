import { useEffect, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import shallow from 'zustand/shallow';
import { FormProvider, useForm, useWatch, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementSeller/store/ReplacementStore';
import { Claim, RoleEnum, useSearchReplacementByIdLazyQuery, } from 'Apollo/graphql';
import { CustomErrorMessage, } from 'Components/Errors/types';
import { useAuthUser, } from 'Tools/auth';
import { useSearchPhaseInitFormValues, searchPhaseFormValidationSchema, } from './utils';
import View from './View';
import { mapReplacementToReplacementState, } from '../utils';

const selectStoreData = (s: ReplacementStore) => ({
  replacementDetail: s.replacement,
  updateReplacement: s.updateReplacement,
  goToPhase: s.goToPhase,
});

const SearchReplacement = (): JSX.Element => {
  const { t, } = useTranslation();
  const {
    replacementDetail,
    updateReplacement,
    goToPhase,
  } = useReplacementStore(selectStoreData, shallow);
  const authUser = useAuthUser();
  const [ customError, setCustomError, ] = useState<CustomErrorMessage | null>(null);

  const initFormValues = useSearchPhaseInitFormValues(replacementDetail);
  const methods = useForm({
    defaultValues: initFormValues,
    resolver: yupResolver(searchPhaseFormValidationSchema),
    mode: 'onSubmit',
  });

  const emailValue = useWatch({
    control: methods.control,
    name: 'replacementId',
  });
  useEffect(
    () => {
      setCustomError(null);
    },
    [ emailValue, ],
  );

  const [ replacementQuery, replacementResult, ] = useSearchReplacementByIdLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ searchReplacementById, }) => {
      if (searchReplacementById) {
        updateReplacement(mapReplacementToReplacementState(searchReplacementById));
        if (authUser?.claimsSet.has(Claim.DispatchReplacement) && authUser?.roleCode !== RoleEnum.SuperAdmin) {
          goToPhase('replacementLogistic');
        }
        else {
        goToPhase('replacementDetail');
      }
      } else {
        setCustomError({
          title: t('replacementSeller:phaseSearch.error.title'),
          text: t('replacementSeller:phaseSearch.error.text'),
        });
      }
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    setCustomError(null);
    await replacementQuery({
      variables: {
        id: values.replacementId,
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <View
          replacementResult={replacementResult}
          customError={customError}
        />
      </form>
    </FormProvider>
  );
};

export default SearchReplacement;
