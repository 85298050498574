import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { ReplacementStatus, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type ReplacementStatusOption = {
  id: ReplacementStatus,
  labelKey: string,
};

export const REPLACEMENT_STATUS_OPTIONS: ReplacementStatusOption[] = [
  {
    id: ReplacementStatus.Completed,
    labelKey: 'common:status.completed',
  },
  {
    id: ReplacementStatus.Expired,
    labelKey: 'common:status.expired',
  },
  {
    id: ReplacementStatus.InProgress,
    labelKey: 'common:status.inProgress',
  },
];

type GetOptionLabel = (o: ReplacementStatusOption) => string;
type GetOptionKey = (o: ReplacementStatusOption) => string;

type UseOptionsReplacementStatus = () => {
  options: ReplacementStatusOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<ReplacementStatusOption>, 
};

export const useOptionsReplacementStatus: UseOptionsReplacementStatus = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: REPLACEMENT_STATUS_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
