import { Resource, } from 'i18next';
import { DEFAULT_LANG, } from 'Utils/constants';
import { z, } from 'zod';
import { resources, } from './resources';

type GetResources = () => { resources: Resource, lang: string, };

// TODO: move away
const lang = z
  .enum(['en', 'pl', 'hu', 'cz',])
  .parse(import.meta.env.VITE_APP_LANG);

export const getResources: GetResources = () => (Object.prototype.hasOwnProperty.call(resources, lang)
  ? {
    resources: { [lang]: (resources as Resource)[lang], },
    lang,
  } : {
    resources: { [DEFAULT_LANG]: resources[DEFAULT_LANG], },
    lang: DEFAULT_LANG,
  }
);
