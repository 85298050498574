import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useFieldArray, useFormContext, useController, } from 'react-hook-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PlusIcon from 'Utils/svg/PlusIcon';
import FormHelperTextClassic from 'Components/Inputs/components/FormHelperTextClassic';
import InputLabelClassic from 'Components/Inputs/components/InputLabelClassic';
import { InputFormErrorMessage, useFormInputStatus, } from 'Components/Form';
import { NodeProps, } from '../../types';
import { buildNodeInputName, } from '../../utils';
import { createNodeAnswerRow, } from '../../../utils';
import AnswerRow from './AnswerRow';

interface Props extends Pick<NodeProps, 'assessmentConfig'> {
  nodeIndex: number,
};

const AnswerList = ({
  nodeIndex,
  assessmentConfig,
}: Props): JSX.Element => {
  const { enableCorrectAnswer, } = assessmentConfig;
  const { t, } = useTranslation('tasksAdmin');
  const { control, } = useFormContext();
  
  const {
    field: { value: correctAnswerRowId, onBlur: onBlurCorrectAnswerRowId, onChange: onChangeCorrectAnswerRowId, },
    fieldState: { error: errorCorrectAnswerRowId, },
  } = useController({
    name: buildNodeInputName(nodeIndex, 'correctAnswer'),
    control,
  });
  const handleSelectCorrectAnswerId = (rowId: string | null) => {
    onChangeCorrectAnswerRowId(rowId);
    onBlurCorrectAnswerRowId();
  };
  const inputCorrectAnswerStatus = useFormInputStatus(errorCorrectAnswerRowId?.message as InputFormErrorMessage | undefined);

  const { fields, append, remove, update, } = useFieldArray({
    control,
    name: buildNodeInputName(nodeIndex, 'answers'),
  });

  const handleAdd = () => {
    append(createNodeAnswerRow());
  };

  const handleRemove = useCallback(
    (index: number, rowAnswerId: string) => {
      if (rowAnswerId === correctAnswerRowId) {
        onChangeCorrectAnswerRowId(null);
      }
      if (fields.length === 1) {
        update(0, createNodeAnswerRow());
      } 
      else if (fields.length > 1) {
        remove(index);
      }
    },
    [ remove, update, fields.length, correctAnswerRowId, onChangeCorrectAnswerRowId, ],
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid container item spacing={2}>
          <Grid item xs={enableCorrectAnswer ? 9 : 12}>
            <Typography
              color="primary"
              variant="h4"
            >
              {t('assessment.labelAnswer')}
            </Typography>
          </Grid>
          {enableCorrectAnswer && (
            <Grid item xs={3}>
              <InputLabelClassic
                required
                error={!!errorCorrectAnswerRowId}
              >
                {t('assessment.labelCorrectAnswer')}
              </InputLabelClassic>
            </Grid>
          )}
        </Grid>
        {fields.map((field, rowAnswerIndex) => (
          <AnswerRow
            key={field.id}
            rowAnswerIndex={rowAnswerIndex}
            nodeIndex={nodeIndex}
            correctAnswerRowId={correctAnswerRowId}
            assessmentConfig={assessmentConfig}
            onRemove={handleRemove}
            onSelectCorrectAnswerId={handleSelectCorrectAnswerId}
          />
        ))}
        {(!!inputCorrectAnswerStatus.text) && (
          <Grid container item spacing={2}>
            <Grid item xs={enableCorrectAnswer ? 9 : 12} />
            <Grid item xs={3}>
              <FormHelperTextClassic error={inputCorrectAnswerStatus.isError}>
                {inputCorrectAnswerStatus.text}
              </FormHelperTextClassic>
            </Grid>
        </Grid>
        )}
      </Grid>

      <Box mt={2}>
        <Button
          color="primary"
          startIcon={<PlusIcon />}
          onClick={handleAdd}
        >
          {t('creation.assessmentPhase.btnAddAnswer')}
        </Button>
      </Box>
    </>
  );
};

export default AnswerList;
