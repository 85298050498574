import Chip from '@mui/material/Chip';
import { useTranslation, } from 'react-i18next';

interface Props {
  isAvailable: boolean | null | undefined
}
const IsNowAvailableChip = ({ isAvailable, }: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  if (typeof isAvailable !== 'boolean') return (<>-</>);

  if (isAvailable) {
    return <Chip label={t('tasks.available')} color='success'/>;
  }
  return <Chip label={t('tasks.unavailable')} color='error'/>;
};

export default IsNowAvailableChip;
