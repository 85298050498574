import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GlobalApiVerification, GlobalApiVerificationStatus, useCheckGlobalApiVerificationStatusMutation, } from 'Apollo/graphql';
import LoadingBox from 'Components/LoadingBox';
import { useEffect, useState, } from 'react';
import { useTranslation, } from 'react-i18next';

enum VerificationState {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  COMPLETED = 'COMPLETED',
}

type Verification = {
  verificationState: VerificationState | null;
  verification: GlobalApiVerification | null;
};

const DEFAULT_VERIFICATION: Verification = {
  verificationState: VerificationState.LOADING,
  verification: null,
};

interface Props {
  onComplete: () => void;
  onGoBack: () => void;
  startedVerification: GlobalApiVerification;
}

export const CheckGrpcVerification = ({ onComplete, onGoBack, startedVerification, }: Props): JSX.Element => {
  const { t, } = useTranslation();
  const [verification, setVerification,] = useState<Verification>(DEFAULT_VERIFICATION);
  useEffect(() => {  setVerification((state) => ({ ...state, verification: startedVerification, }));
},[]);

  const [checkVerification, checkVerificationResult,] = useCheckGlobalApiVerificationStatusMutation({
    onCompleted: (response) => {
      const responseStatus = response.checkGlobalApiVerificationStatus.status;
      if (responseStatus === GlobalApiVerificationStatus.Completed) {
        return setVerification((state) => ({
          ...state,
          verification: response.checkGlobalApiVerificationStatus,
          verificationState: VerificationState.COMPLETED,
        }));
      }
      if (responseStatus === GlobalApiVerificationStatus.InProgress) {
        return setVerification((state) => ({
          ...state,
          verificationState: VerificationState.LOADING,
        }));
      }
      return setVerification((state) => ({ ...DEFAULT_VERIFICATION, verificationState: VerificationState.ERROR, }));
    },
    onError: () => {
      return setVerification((state) => ({ ...DEFAULT_VERIFICATION, verificationState: VerificationState.ERROR, }));
    },
  });

  const resetVerification = () => {
    if (verification.verification?.id) {
      const { id, } = verification.verification;

      setVerification(() => DEFAULT_VERIFICATION);
      checkVerification({ variables: { id, }, });
    }
  };

  useEffect(() => {
    if (verification.verification?.id) {
      const { id, } = verification.verification;
      const interval = setInterval(() => {
        checkVerification({ variables: { id, }, });
      }, 2000);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [checkVerificationResult, checkVerification, verification.verification,]);

  if (verification.verification?.status === GlobalApiVerificationStatus.Completed) {
    onComplete();
    // alert('will complete');
  }

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <Typography variant="h1" sx={{mb: 4,}}>
          {t('registrationSeller:phaseCustomerVerification.title')}
        </Typography>
      </Grid> */}
      {verification.verificationState === VerificationState.ERROR && (
        <>
          <Grid item xs={12} alignContent="center" alignSelf="center">
            <Typography>{t('registrationSeller:grpcApiVerification.error')}</Typography>
          </Grid>

          <Grid item xs={12} alignContent="center">
            <Button variant="contained" color="warning" onClick={() => resetVerification()}>
              {t('common:actions.tryAgain')}
            </Button>
          </Grid>
        </>
      )}
      {verification.verificationState === VerificationState.LOADING && (
        <>
          <Grid item xs={12} alignSelf="center">
            <LoadingBox />
          </Grid>
          <Grid item xs={12} alignSelf="center">
            <Typography align="center">{t('registrationSeller:grpcApiVerification.waitingForConsumerToVerifyHisEmail')}</Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12} marginTop={4}>
        <Button size="large" variant="outlined" color="primary" sx={{ width: { xs: '100%', lg: 'unset', mt: '2rem', }, }} onClick={onGoBack}>
          {t('common:actions.back')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CheckGrpcVerification;
