import { styled, } from '@mui/material/styles';

type Props = {
  number: number;
};

const NotificationDot = styled('div')(({ theme, }) => ({
  background: theme.palette.secondary.main,
  color: 'white',
  minWidth: 20,
  height: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 100,
  fontSize: '0.875em',
  padding: 4,
}));

const SidebarMenuNotificationDot = ({ number, }: Props) => {
  const sanitizedNumber = number > 99 ? '99+' : number;
  
  return <NotificationDot>{sanitizedNumber}</NotificationDot>;
};

export default SidebarMenuNotificationDot;
