import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { FileFragment, ImportPointsOfSellMutationVariables, } from 'Apollo/graphql';

export type ImportPosFormValues = {
  file: FileFragment | null,
};
type ImportBadFormDataFieldMapper = {
  [key in keyof ImportPointsOfSellMutationVariables]: undefined | keyof ImportPosFormValues
};

export const defaultImportPosFormValues: ImportPosFormValues = {
  file: null,
};

export const importPosValidationSchema = yup.object().shape({
  file: yup.object()
    .nullable()
    .required(INPUT_FORM_ERRORS.REQUIRED),
});

export const importBadFormDataFieldMapper: ImportBadFormDataFieldMapper = {
  fileId: 'file',
};
