import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import { useWatch, } from 'react-hook-form';
import { SellerPhotoAssessmentBlockFragment, } from 'Apollo/graphql';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { FormInputFileUpload, } from 'Components/Form';
import {  IMAGE_PHOTO_TASK_MAX_SIZE, } from 'Utils/constants';
import RePhotoIcon from 'Utils/svg/RePhotoIcon';
import { NodeProps, } from '../types';
import { buildNodeInputName, } from '../utils';

const NodePhoto = ({
  index,
  block,
  assessmentConfig,
}: NodeProps<SellerPhotoAssessmentBlockFragment>): JSX.Element => {
  const { t, } = useTranslation('tasksSeller');

  const value = useWatch({ name: buildNodeInputName(index, 'value'), });

  return (
    <>
      <NotEditableTextClassic
        label={t('fill.assessment.labelDescription')}

      >
        <div style={{ whiteSpace: 'pre-wrap', }}>
          {block.description}
        </div>
      </NotEditableTextClassic>
      <Box mt={3}>
        <FormInputFileUpload
          label={t('fill.assessment.labelPhoto')}
          name={buildNodeInputName(index, 'value')}
          required
          maxSize={IMAGE_PHOTO_TASK_MAX_SIZE}
          fileType="image"
          type="button"
          buttonProps={{
            startIcon: value ? <RePhotoIcon /> : undefined,
            children: value ? t('fill.assessment.bntRetakePhoto') : t('fill.assessment.bntTakePhoto'),
          }}
        />
      </Box>
    </>
  );
};

export default NodePhoto;
