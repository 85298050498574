import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useAuthUser, } from 'Tools/auth';
import { useTranslation, } from 'react-i18next';
import LogoIcon from 'Utils/svg/LogoIcon';
import { FormInputCheckbox, INPUT_FORM_ERRORS, } from 'Components/Form';
import { FormProvider, useForm, } from 'react-hook-form';
import { Claim, useUserAcceptTermsAndConditionsMutation, } from 'Apollo/graphql';
import TermsAndConditions from './TermsAndConditions';

const AcceptTermsAndConditionsModal: React.FC = () => {
  const { t, } = useTranslation('common');
  const authUser = useAuthUser();

  const [editMutation,] = useUserAcceptTermsAndConditionsMutation({
    onCompleted: () => {},
    onError: () => {},
  });

  const form = useForm<{ acceptedTermsAndConditions: boolean }>({
    defaultValues: {
      acceptedTermsAndConditions: false,
    },
    resolver: (values) => {
      if (!values.acceptedTermsAndConditions) {
        return {
          values,
          errors: {
            acceptedTermsAndConditions: {
              message: INPUT_FORM_ERRORS.CHECKBOX_IS_MANDATORY(),
            },
          },
        };
      }
      return {
        values,
        errors: {},
      };
    },
    mode: 'all',
  });

  // @TODO disable until translation & pdf update
  const allowedEnv = ['development',];
  if (!allowedEnv.includes(import.meta.env.VITE_APP_ENV ?? '')) {
    return null;
  }

  if (authUser?.claimsSet.has(Claim.AcceptTermsAndConditions)) {
    return null;
  }

  // FIXME:
  // const { acceptedTermsAndConditions } = authUser;
  // if (acceptedTermsAndConditions) {
  //   return null;
  // }

  const onSubmit = async () => {
    await editMutation({
      variables: { input: { acceptedTermsAndConditions: true, }, },
    });
  };

  const disableSubmitButton = Object.keys(form.formState.errors).length !== 0;

  return (
    <Dialog open onClose={() => {}} sx={{ backdropFilter: 'blur(8px)', }}>
      <DialogContent sx={{ padding: '50px', }}>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            noValidate
            autoComplete="off"
          >
            <Stack spacing="10px">
              <LogoIcon sx={{ fontSize: '60px', }} />
              <Box>
                <Typography variant="h1" color="secondary">
                  {t('acceptTermsAndConditions.title')}
                </Typography>
              </Box>
              <Box>
                <TermsAndConditions />
              </Box>
              <FormInputCheckbox
                name="acceptedTermsAndConditions"
                label={
                  <Typography>
                    {t(
                      'acceptTermsAndConditions.form.labelAcceptedTermsAndConditions',
                    )}
                  </Typography>
                }
              />
              <Button
                disabled={disableSubmitButton}
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => {}}
                sx={{
                  paddingX: '60px',
                  borderRadius: '25px',
                  alignSelf: 'center',
                }}
              >
                {t('acceptTermsAndConditions.btnSubmit')}
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default AcceptTermsAndConditionsModal;
