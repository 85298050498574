import { createTheme, darken, } from '@mui/material/styles';


// Update the Element's color prop options
declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
  interface ButtonPropsVariantOverrides {
    xmasWheel: true;
  }
}


// Typography
declare module '@mui/material/styles' {
  interface TypographyVariants {
    body0: React.CSSProperties;
    footer: React.CSSProperties;
    label: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body0?: React.CSSProperties;
    footer: React.CSSProperties;
    label?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true,
    h2: true,
    h3: true,
    h4: true,
    h5: false,
    h6: false,
    body0: true,
    body1: true,
    body2: false,
    footer: true,
    button: true,
    caption: false,
    subtitle1: true,
    subtitle2: false,
    overline: false,
    label: true,
  }
}

export const appTheme = createTheme({
  palette: {
    primary: {
      main: '#008893',
    },
    secondary: {
      main: '#D47E5F',
    },
    neutral: {
      main: '#333333',
      contrastText: 'white',

    },
    error: {
      main: '#E56E6E',
    },
    success: {
      main: "#4CB476",
    },
    warning: {
      main: "#F6DF8E",
    },
    background: {
      default: '#F2F2F2',
    },
  },
  typography: {
    fontFamily: [
      '"Georama"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontWeightLight: 300, // not imported
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {},
    h2: {},
    h3: {},
    h4: {},
    // h5: undefined,
    // h6: undefined,
    body0: {},
    body1: {},
    footer: {},
    // body2: undefined,
    button: {},
    // caption: undefined,
    subtitle1: {},
    // subtitle2: undefined,
    // overline: undefined,
    label: {},
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#F2F2F2',
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        standardError: ({ theme, }) => ({
          '& .MuiAlert-icon': {
            color: theme.palette.error.main,
          },
        }),
      },
    },

    MuiChip: {
      styleOverrides: {
        root: ({ theme, }) => ({
          borderRadius: theme.spacing(0.5),
          fontSize: '0.875rem',
          lineHeight: '1.5em',
          letterSpacing: '0.25px',
        }),
      },
    },

    MuiAppBar: {
      styleOverrides: {
        colorDefault: ({ theme, }) => ({
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.05)',
        }),
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: ({ theme, }) => ({
          [theme.breakpoints.up('sm')]: {
            minHeight: 60,
          },
        }),
      },
    },

    MuiButton: {
      variants: [{ props: { variant: 'xmasWheel', }, style: {
        padding: '1rem 3rem',
        position: 'absolute',
        zIndex: 10,
        height: 'fit-content',
        top: '66%',
        color: 'white',
        background: 'linear-gradient(180deg, #D79574 0%, #AC583D 100%)',
        paddingX: '60px',
        borderRadius: '100px',
        alignSelf: 'center',
        fontWeight: '700',
        '&:hover': {
          backgroundColor: darken('#C37D5A', 0.1),
        },
      }, },],
      defaultProps: {
        color: 'primary',
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ theme, }) => ({
          textTransform: 'none',
          fontWeight: theme.typography.fontWeightMedium,
        }),
        sizeLarge: ({ theme, }) => ({
          borderRadius: 100,
          padding: '16px 24px',
          fontSize: '1rem',
          lineHeight: '1.2em',
          '& .MuiButton-iconSizeLarge svg': {
            fontSize: '	1.25rem',
          },
          [theme.breakpoints.up('lg')]: {
            borderRadius: theme.shape.borderRadius,
            padding: '10px 16px',
            lineHeight: '1.5em',
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1rem',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '2px solid #e8e8e8',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },

    MuiTypography: {
      styleOverrides: {
        button: ({ theme, }) => ({
          textTransform: 'none',
        }),
      },
    },

    MuiBadge: {
      styleOverrides: {
        dot: ({ theme, }) => ({
          height: 10,
          width: 10,
          borderRadius: '50%',
        }),
      },
    },

    MuiInputBase: {
      defaultProps: {
        fullWidth: true,
        color: 'primary',
      },
      styleOverrides: {
        root: ({ theme, }) => ({
          backgroundColor: theme.palette.common.white,
          '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: '#999999',
          },
        }),
        sizeSmall: {
          '& .MuiOutlinedInput-input': {
            fontSize: '0.75rem',
            padding: 8,
          },
          // '&.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
          //   padding: 2,
          // },
          // '& .MuiAutocomplete-endAdornment': {
          //   right: 0,
          //   paddingRight: '4px',
          //   top: 'calc(50% - 12px)',
          // },
          // '& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
          //   fontSize: 18,
          // },
          // '&.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-clearIndicator, &.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-popupIndicator': {
          //   height: 20,
          //   width: 20,
          // },
          // '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot': {
          //   paddingRight: 28,
          // },
          // '&.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
          //   paddingRight: 48,
          // },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: ({ theme, }) => ({
          backgroundColor: '#F7F7F8',
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,

          '& > tr:not(:last-child) > th': {
            paddingBottom: theme.spacing(0.5),
          },
          '& > tr:not(:first-of-type) > th': {
            paddingTop: theme.spacing(0.5),
          },
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme, }) => ({
          '& > td:first-of-type:not(.MuiTableCell-paddingNone)': {
            paddingLeft: '24px',
          },
          '& > td:last-child:not(.MuiTableCell-paddingNone)': {
            paddingRight: '24px',
          },
          '& > th:first-of-type': {
            paddingLeft: '24px',
          },
          '& > th:last-child': {
            paddingRight: '24px',
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme, }) => ({
          border: 'none',
          borderBottom: 'none',
          fontSize: '0.875rem',
          lineHeight: '1.5em',
          letterSpacing: '0.25px',
          fontWeight: appTheme.typography.fontWeightRegular,
        }),
        head: ({ theme, }) => ({
          padding: '18px 12px',
        }),
        body: ({ theme, }) => ({
          padding: '21px 12px',
        }),
        paddingNone: ({ theme, }) => ({
          padding: 0,
        }),
      },
    },

    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
    },

    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          padding: 'unset',
        },
      },
    },
  },
});


appTheme.typography.h1 = {
  ...appTheme.typography.h1,
  fontSize: '1.5rem',
  lineHeight: '1.35em',
  letterSpacing: '0px',
  fontWeight: appTheme.typography.fontWeightBold,
  [appTheme.breakpoints.up('lg')]: {
    fontSize: '2rem',
  },
};
appTheme.typography.h2 = {
  ...appTheme.typography.h2,
  fontSize: '1.125rem',
  lineHeight: '1.35em',
  letterSpacing: '0.25px',
  fontWeight: appTheme.typography.fontWeightBold,
  [appTheme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
  },
};
appTheme.typography.h3 = {
  ...appTheme.typography.h3,
  fontSize: '1rem',
  lineHeight: '1.35em',
  letterSpacing: '0.25px',
  fontWeight: appTheme.typography.fontWeightBold,
  [appTheme.breakpoints.up('lg')]: {
    fontSize: '1.125rem',
  },
};
appTheme.typography.h4 = {
  ...appTheme.typography.h4,
  fontSize: '0.875',
  lineHeight: '1.35em',
  letterSpacing: '0.25px',
  fontWeight: appTheme.typography.fontWeightBold,
  [appTheme.breakpoints.up('lg')]: {
    fontSize: '1rem',
  },
};
appTheme.typography.body0 = {
  ...appTheme.typography.body0,
  fontSize: '1rem',
  lineHeight: '1.5em',
  letterSpacing: '0.25px',
  fontWeight: appTheme.typography.fontWeightRegular,
  [appTheme.breakpoints.up('lg')]: {
    fontSize: '1.125rem',
  },
};
appTheme.typography.footer = {
  ...appTheme.typography.footer,
  fontSize: '14px',
  lineHeight: '1.5em',
  letterSpacing: '0.25px',
  fontWeight: appTheme.typography.fontWeightRegular,
  [appTheme.breakpoints.up('lg')]: {
    fontSize: '14px',
  },
};
appTheme.typography.h4 = {
  ...appTheme.typography.h4,
  fontSize: '0.875rem',
  lineHeight: '1.35em',
  letterSpacing: '0.25px',
  fontWeight: appTheme.typography.fontWeightBold,
  [appTheme.breakpoints.up('lg')]: {
    fontSize: '1rem',
  },
};
appTheme.typography.body1 = {
  ...appTheme.typography.body1,
  fontSize: '0.875rem',
  lineHeight: '1.5em',
  letterSpacing: '0.25px',
  fontWeight: appTheme.typography.fontWeightRegular,
  [appTheme.breakpoints.up('lg')]: {
    fontSize: '1rem',
  },
};
appTheme.typography.subtitle1 = {
  ...appTheme.typography.subtitle1,
  fontSize: '1rem',
  lineHeight: '1.35em',
  letterSpacing: '0.25px',
  fontWeight: appTheme.typography.fontWeightBold,
};
appTheme.typography.label = {
  ...appTheme.typography.label,
  fontSize: '1.125rem',
  lineHeight: '1.35em',
  letterSpacing: '0.25px',
  fontWeight: appTheme.typography.fontWeightBold,
  [appTheme.breakpoints.up('lg')]: {
    fontSize: '1rem',
  },
};
