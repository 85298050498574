import { styled, } from '@mui/material/styles';
import ToggleButtonMui from '@mui/material/ToggleButton';

const ToggleButton = styled(ToggleButtonMui)(({ theme, }) => ({
  width: 28,
  height: 28,
  border: 'none',

  '& > .MuiSvgIcon-root': {
    fontSize: 18,
  },
}));

ToggleButton.defaultProps = {
  color: 'primary',
};

export default ToggleButton;
