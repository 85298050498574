import { PromotionSortInput, PaginationInput, PromotionFilterInput, AuthUserFragment, } from 'Apollo/graphql';
import { PromotionsStore, } from 'Modules/PromotionsAdmin/store/PromotionsStore';

export const mapVariables = (storeData: PromotionsStore['tablePromotions'], authUser: AuthUserFragment | null): {
  filter?: PromotionFilterInput,
  pagination?: PaginationInput,
  sort?: PromotionSortInput,
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    id: storeData.filter.id 
      ? { contains: storeData.filter.id, }
      : undefined,
    name: storeData.filter.name
      ? { contains: storeData.filter.name, }
      : undefined,
    description: storeData.filter.description
      ? { contains: storeData.filter.description, }
      : undefined,
    offerLimitPerCustomer: storeData.filter.offerLimitPerCustomer
      ? { equals: storeData.filter.offerLimitPerCustomer, }
      : undefined,
    chainIds: (!authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS && storeData.filter.chainId?.id)
      ? { has: storeData.filter.chainId?.id, }
      : undefined,
    clusterIds: (authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS && storeData.filter.clusterId?.id)
      ? { has: storeData.filter.clusterId?.id, }
      : undefined,
  },
  sort: storeData.data.sortField && storeData.data.sortOrder
    ? {
      field: storeData.data.sortField,
      order: storeData.data.sortOrder,
    } : undefined,
});
