import { Box, Dialog, DialogContent, IconButton, Stack, Typography, useTheme, } from '@mui/material';
import CloseIcon from 'Utils/svg/CloseIcon';
import { useNavigate, } from 'react-router-dom';
import { GeneratePrizeWheelMutationHookResult, } from 'Apollo/graphql';
import PrizeWheel from './PrizeWheel';

type Props = {
  prizeWheelModalVisible: boolean;
  setConfettiVisible: (visible: boolean) => void;
  heading: string;
  prizeWheelMutation: GeneratePrizeWheelMutationHookResult[0];
  prizeNumber: number;
};

const PrizeWheelModal = ({ prizeWheelModalVisible, setConfettiVisible, heading, prizeWheelMutation, prizeNumber, }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  if (!prizeWheelModalVisible) {
    return null;
  }

  const handleClose = () => {
    navigate('/');
  };

  return (
    <Dialog
      open
      onClose={() => {}}
      sx={{
        backdropFilter: 'blur(8px)',
        '& .MuiPaper-root': {
          bgcolor: 'transparent',
          boxShadow: 'revert',
          alignItems: 'center',
          overFlow: 'hidden',
          width: 'unset',
          [theme.breakpoints.down('md')]: {
            margin: '1rem',
          },
        },
      }}
      maxWidth="sm"
      fullWidth
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white',
          [theme.breakpoints.down('md')]: {
            transform: 'scale(0.75)',
            right: 0,
            top: 0,
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          paddingTop: '4.19rem',
          height: '100vh',
          backgroundImage: 'url(images/xmas-wheel/prize_wheel_bg.svg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center bottom',
          maxWidth: '422px',
          maxHeight: '814px',
          [theme.breakpoints.down('md')]: {
            paddingTop: '1rem',
          },
        }}
      >
        <Stack sx={{ alignItems: 'center', fontSize: '2rem', lineHeight: '2.25rem', }}>
          <Box>
            <Typography
              variant="h1"
              color="white"
              sx={{
                textAlign: 'center',
                [theme.breakpoints.down('md')]: {
                  fontSize: '1rem',
                },
              }}
            >
              {heading}
            </Typography>
          </Box>
          <Stack sx={{ transform: 'scale(0.77) translateY(-28px)', }}>
            <PrizeWheel setConfettiVisible={setConfettiVisible} prizeWheelMutation={prizeWheelMutation} prizeNumber={prizeNumber} />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PrizeWheelModal;
