import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  AssortmentCategoryOptionFragment,
  ChainFragment, ClusterFragment, PromotionFragment,
  useAssortmentCategoryOptionsQuery,
  useChainsQuery, useClustersQuery,
} from 'Apollo/graphql';
import { FormInputAutocomplete, FormInputCheckbox, FormInputFileUpload, FormInputText, } from 'Components/Form';
import FormInputDate from 'Components/Form/inputs/FormInputDate';
import FormInputInt from 'Components/Form/inputs/FormInputInt';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { useMemo, } from 'react';
import { useFieldArray, useFormContext, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import { IMAGE_MAX_SIZE, } from 'Utils/constants';
import { areOptionsEqualById, getOptionFieldId, renderOptionById, } from 'Utils/helpers';
import PlusIcon from 'Utils/svg/PlusIcon';
import TrashIcon from 'Utils/svg/TrashIcon';
import Typography from '@mui/material/Typography';
import { PromotionFormValues, } from '../utils/promotionForm';
import AssortmentCategoryChunk from './AssortmentCategoryChunk';

interface Props {
  promotion?: PromotionFragment;
}
const formCategoriesToOptionsArray = (formValues: PromotionFormValues['assortmentCategory']): AssortmentCategoryOptionFragment[] => {
  const keys = Object.keys(formValues);
  const categoryArray = [];
  for (let i = 0; i < keys.length; i++) {
    const { option, } = formValues[i];
    if (option) categoryArray.push(option);
  }
  return categoryArray;
};

const PromotionForm = ({ promotion, }: Props): JSX.Element => {
  const { t, } = useTranslation('promotionsAdmin');
  const authUser = useAuthUser();
  const chainsResult = useChainsQuery({ fetchPolicy: 'cache-and-network', });
  const clustersResult = useClustersQuery({ fetchPolicy: 'cache-and-network', });
  const categoriesOptionsResult = useAssortmentCategoryOptionsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const categoriesOptionsList = useMemo(() => categoriesOptionsResult.data?.assortmentCategories || [], [categoriesOptionsResult,]);
  const { watch, control, } = useFormContext();
  const { fields, append, remove, } = useFieldArray({
    name: 'assortmentCategory',
    control,
  });

  const listLength = fields.length;

  const assortmentCategories = watch('assortmentCategory');
  const assortmentCategoriesArray = formCategoriesToOptionsArray(assortmentCategories);
  const arrayDiff = categoriesOptionsList.filter((x) => !assortmentCategoriesArray.find((y) => y.id === x.id));

  const handleAdd = () => {
    append({ option: null, count: null, });
  };

  const handleDelete = () => {
    if (listLength > 1) {
      remove(listLength - 1);
    }
  };
  
  return (
    <Grid container spacing={3}>
      {promotion && (
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('promotion.labelPromotionId')}>{promotion.id}</NotEditableTextClassic>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormInputText name="name" label={t('promotion.labelName')} required />
      </Grid>
      <Grid item xs={12}>
        <FormInputFileUpload name="preview" label={t('promotion.labelPreview')} required fileType="image" maxSize={IMAGE_MAX_SIZE} />
      </Grid>
      <Grid item xs={12}>
        <FormInputFileUpload name="banner" label={t('promotion.labelBanner')} required fileType="image" maxSize={IMAGE_MAX_SIZE} />
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="description" label={t('promotion.labelDescription')} required multiline minRows={4} maxRows={6} />
      </Grid>
      <Grid item xs={12}>
        <FormInputInt name="offerLimit" label={t('promotion.labelOfferLimit')} required />
      </Grid>
      <Grid item xs={12}>
        <FormInputDate name="validFrom" label={t('promotion.labelValidFrom')} required />
      </Grid>
      <Grid item xs={12}>
        <FormInputDate name="validTo" label={t('promotion.labelValidTo')} required />
      </Grid>
      <Grid item xs={12}>
        <FormInputCheckbox name="isForBrandAmbassadors" label={t('promotion.labelIsForBrandAmbassadors')} required />
      </Grid>
      <Grid item xs={12}>
        {authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_CLUSTER_IDS ? (
          <FormInputAutocomplete<ClusterFragment, true>
            multiple
            name="clusters"
            getOptionLabel={getOptionFieldId}
            isOptionEqualToValue={areOptionsEqualById}
            renderOption={renderOptionById(getOptionFieldId)}
            options={clustersResult.data?.clusters || []}
            loading={clustersResult.loading}
            label={t('promotion.labelClusterIds')}
            placeholder={t('common:select.placeholder')}
          />
        ) : (
          <FormInputAutocomplete<ChainFragment, true>
            multiple
            name="chains"
            getOptionLabel={getOptionFieldId}
            isOptionEqualToValue={areOptionsEqualById}
            renderOption={renderOptionById(getOptionFieldId)}
            options={chainsResult.data?.chains || []}
            loading={chainsResult.loading}
            label={t('promotion.labelChainIds')}
            placeholder={t('common:select.placeholder')}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {fields.map((x, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <AssortmentCategoryChunk key={x.id} assortmentCategoryValues={arrayDiff} index={index} />
        ))}
      </Grid>
      <Box mt={2} display="flex" flexDirection="row">
        {listLength < categoriesOptionsList.length && (
          <Button sx={{ ml:'1.5rem', }} color="inherit" startIcon={<PlusIcon />} onClick={handleAdd}>
            {t('promotion.btnAddCategory')}
          </Button>
        )}
        {listLength > 1 && (
          <Button sx={{ ml:'1.5rem',}} color="inherit" startIcon={<TrashIcon />} onClick={handleDelete}>
            {t('promotion.btnRemoveCategory')}
          </Button>
        )}
      </Box>

      <Grid item xs={12}>
        <FormInputCheckbox name="notificationsVisible" label={<Typography>{t('common:notification.createPopUpNotification')}</Typography>} />
      </Grid>
    </Grid>
  );
};

export default PromotionForm;
