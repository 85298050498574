import { useMemo, } from 'react';
import { styled, } from '@mui/material/styles';
import { useTranslation, } from 'react-i18next';
import { Link as RouterLink, } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ChipTaskCompletionStatus from 'Components/ChipTaskCompletionStatus';
import Typography from '@mui/material/Typography';
import { ROUTES, } from 'Utils/constants';
import { AssessmentMethodEnum, SellerTaskFragment, } from 'Apollo/graphql';
import { isTaskPointsEnabledMarket, } from 'Utils/market';

const CardContainer = styled(Card)(({ theme, }) => ({
  marginBottom: theme.typography.pxToRem(1),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
}));

const PointsText = styled(Typography)(({ theme, }) => ({
  color: theme.palette.grey[500],
  fontWeight: theme.typography.fontWeightBold,
}));

interface Props {
  data: SellerTaskFragment;
};

const CardItem = ({ data, }: Props): JSX.Element => {
  const { t, } = useTranslation('tasksSeller');
  const memoData = useMemo(
    () => {
      let btnTextKey = 'taskList.btnTakeTest';
      if (data.assessmentMethod === AssessmentMethodEnum.Photo) {
        btnTextKey = (data.taskCompletionInfo?.attemptsTaken || 0) > 0
        ? 'taskList.btnTakePhotoAgain'
        : 'taskList.btnTakePhoto';
      }
      else if (data.assessmentMethod === AssessmentMethodEnum.Survey) {
        btnTextKey = (data.taskCompletionInfo?.attemptsTaken || 0) > 0
        ? 'taskList.btnTakeSurveyAgain'
        : 'taskList.btnTakeSurvey';
      }
      else if (data.assessmentMethod === AssessmentMethodEnum.Test) {
        btnTextKey = (data.taskCompletionInfo?.attemptsTaken || 0) > 0
        ? 'taskList.btnTakeTestAgain'
        : 'taskList.btnTakeTest';
      }

      return {
        btnTextKey,
      };
    },
    [ data, ],
  );

  return (
    <CardContainer>
      <Box p={2}>

        <ChipTaskCompletionStatus
          taskCompletionStatus={data.taskCompletionInfo?.status}
        />

        <Box mt={2.5} mb={2} display="flex" justifyContent="space-between">
          <Typography variant="h2" color="primary">
            {data.name}
          </Typography>
          {isTaskPointsEnabledMarket && data.pointsForCompletion && (
            <PointsText>
              {`${data.taskCompletionInfo?.pointsEarned || 0}/${data.pointsForCompletion} ${t("tasksSeller:taskList.generic.points")}`}
            </PointsText>
          )}
        </Box>

        <Typography
          style={{ whiteSpace: 'pre-wrap', }}
        >
          {data.description}
        </Typography>

        {data.taskCompletionInfo?.canComplete && (
          <Button
            component={RouterLink}
            to={`/${ROUTES.tasks}/${data.id}`}
            size="large"
            variant="contained"
            color="primary"
            sx={{ width: { xs: '100%', }, marginTop: 2, }}
          >
            {t(memoData.btnTextKey)}
          </Button>
        )}

      </Box>
    </CardContainer>
  );
};

export default CardItem;
