import { useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FormInputGroupRadio, FormSubmit, } from 'Components/Form';
import { TaskTypeOption, useOptionsTaskType, } from 'Utils/options/useOptionTaskType';
import { useAuthUser, } from 'Tools/auth';

type Props = {
  onBack: () => void;
};

const View = ({
  onBack,
}: Props): JSX.Element => {
  const authUser = useAuthUser();
  const { t, } = useTranslation('tasksAdmin');
  const filterTypes = useMemo(
    () => ({
      ADDITIONAL: !!authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_ADDITIONAL_TASKS,
      ELEARNING: !!authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_ELEARNING_TASKS,
      OBLIGATORY: !!authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_OBLIGATORY_TASKS,
    }),
    [ authUser, ],
  );
  const taskTypeOptions = useOptionsTaskType(filterTypes);

  return (
    <>
      <Typography variant="h1" mb={1.5}>
        {t('creation.typePhase.title')}
      </Typography>
      <Typography mb={5}>
        {t('creation.typePhase.taskTypeDescription')}
      </Typography>

      <Box mb={4}>
        <FormInputGroupRadio<TaskTypeOption>
          name="type"
          label={t(`creation.typePhase.chooseType`)}
          options={taskTypeOptions.options}
          getOptionKey={taskTypeOptions.getOptionKey}
          getOptionLabel={taskTypeOptions.getOptionLabel}
          required
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} lg="auto">
          <FormSubmit
            size="large"
            variant="contained"
            color="primary"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
          >
            {t('common:actions.confirm')}
          </FormSubmit>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button
            size="large"
            variant="outlined"
            color="inherit"
            onClick={onBack}
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
          >
            {t('common:actions.cancel')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default View;
