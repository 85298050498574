import { AssortmentCategoryWithAssortmentOptionFragment, AssortmentOptionFragment, CustomerFragment, ReplacementRegistrationFragment, ReplacementStatus, } from 'Apollo/graphql';
import { InputProductCountMultipleValueOption, } from 'Components/Inputs/InputProductCountMultiple/types';
import create from 'zustand';

export type ReplacementPhase = 'replacementSearch' | 'replacementDetail' | 'replacementForm' | 'replacementClosed' | 'replacementLogistic';

export type Customer = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  dateOfBirth: string,
};
export type ProductOption = InputProductCountMultipleValueOption<AssortmentOptionFragment>;

export type Replacement = {
  id: string | null,
  username: string | null,
  status: ReplacementStatus | null,
  createdAt: Date | null,
  updatedAt: Date | null,
  category: string | null,
  categoryItems: AssortmentCategoryWithAssortmentOptionFragment | null,
  customer: CustomerFragment | null,
  replacementRegistration: ReplacementRegistrationFragment | null,
  reason: string | null,
  exchangedProducts: ProductOption[],
  exchangeProductsWhenComplete: ProductOption[],
  address: string | null,
  batchCode: string,
};

type ReplacementStoreState = {
  phase: ReplacementPhase,
  replacement: Replacement,
  replacementForm: {
    assortmentCategories: {
      [key: string]: ProductOption[],
    },
  },
};

export const INIT_STATE: ReplacementStoreState = {
  phase: 'replacementSearch',
  replacement: {
    id: null,
    username: null,
    status: null,
    createdAt: null,
    updatedAt: null,
    category: null,
    categoryItems: null,
    customer: null,
    replacementRegistration: null,
    reason: null,
    exchangedProducts: [],
    exchangeProductsWhenComplete: [],
    address: null,
    batchCode: '',
  },
  replacementForm: {
    assortmentCategories: {},
  },
};

export type ReplacementStore = ReplacementStoreState & {
  updateReplacement: (args: ReplacementStore['replacement']) => void,
  completeReplacementFormPhase: (args: ReplacementStore['replacementForm'],batchCode: string | null) => void,
  goToPhase: (phase: ReplacementPhase) => void,
  finishReplacement: () => void,
  leavePage: () => void,
};

export const useReplacementStore = create<ReplacementStore>((set) => ({
  ...INIT_STATE,
  updateReplacement: (data) => set((state) => ({
    replacement: {
      ...state.replacement,
      id: data.id,
      username: data.username,
      status: data.status,
      createdAt: data.createdAt,
      category: data.category,
      categoryItems: data.categoryItems,
      customer: data.customer,
      replacementRegistration:data.replacementRegistration,
      reason: data.reason,
      exchangedProducts: data.exchangedProducts,
      exchangeProductsWhenComplete: data.exchangeProductsWhenComplete,
      batchCode: data.batchCode || '',
    },
  })),
  completeReplacementFormPhase: ({ assortmentCategories, },batchCode) => set((state) => ({
    phase: 'replacementDetail',
    replacement: {
      ...state.replacement,
      exchangedProducts: [],
      batchCode: batchCode || '',
    },
    replacementForm: {
      assortmentCategories,
    },
  })),
  goToPhase: (phase) => set({
    phase,
  }), 
  finishReplacement: () => set({
    ...INIT_STATE,
    phase: 'replacementSearch',
  }),
  leavePage: () => set({
    ...INIT_STATE,
  }),
}));
