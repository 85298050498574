import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { TaskCompletionStatusOption, } from 'Utils/options/useOptionsTaskCompletionStatus';

export type ReviewTaskValues = {
  status: TaskCompletionStatusOption | null,
};

export const reviewTaskValidationSchema = yup.object().shape({
  status: yup.object()
    .nullable()
    .required(INPUT_FORM_ERRORS.REQUIRED),
});
