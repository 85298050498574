import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useTranslation, } from 'react-i18next';
import { useMemo, } from 'react';
import { activeInactivePosQuery, } from './queries';

const ActiveInactivePos = ({
  showAddButton,
  filtration,
  showSlug = true,
  showTotal,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');
  const query = useMemo(() => activeInactivePosQuery(), []);
  return (
    <Base
      headerText={t('dashboards.pos.activeInactivePos')}
      slug={showSlug ? 'total-pos' : undefined}
      showAddButton={showAddButton}
      filtration={filtration}
      dashboardLayoutItemName={DashboardLayoutItemName.PointsOfSaleActivePos}
    >
      <ChartRenderer showTotal={showTotal} query={query} type={ChartType.pie} />
    </Base>
  );
};

export default ActiveInactivePos;
