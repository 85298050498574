import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { UserStore, useUserStore, } from 'Modules/Users/store/UserStore';
import Logic from './Logic';

const selectModalData = (s: UserStore) => s.modalReactivateUser;
const selectCloseReactivate = (s: UserStore) => s.closeModalReactivateUser;
const selectCloseDetail = (s: UserStore) => s.closeModalDetailUser;

const ModalReactivateUser = (): JSX.Element => {
  const { isOpen, } = useUserStore(selectModalData);
  const onCloseReactivate = useUserStore(selectCloseReactivate);
  const onCloseDetail = useUserStore(selectCloseDetail);


  return (
    <ModalDrawer
      onClose={onCloseReactivate}
      open={isOpen}
    >
      <Logic
        onCloseReactivate={onCloseReactivate}
        onCloseDetail={onCloseDetail}
      />
    </ModalDrawer>
  );
};

export default ModalReactivateUser;
