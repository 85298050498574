import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useFieldArray, useFormContext, } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PlusIcon from 'Utils/svg/PlusIcon';
import { NodeProps, } from './Node/types';
import { getNodeInitValue, NODES, } from './utils';
import Node from './Node';

const FormInputs = (): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const { control, clearErrors, } = useFormContext();
  const { fields, append, remove, update, } = useFieldArray({
    control,
    name: 'nodes',
  });

  const handleAdd = () => {
    append(NODES.default());
  };

  const handleRemove = useCallback<NodeProps['onRemove']>(
    (index) => {
      if (fields.length === 1) {
        update(0, NODES.default());
      }
      else if (fields.length > 1) {
        remove(index);
      }
    },
    [ remove, update, fields.length, ],
  );

  const handleChangeType = useCallback<NodeProps['onChangeType']>(
    (index, type) => {
      update(index, getNodeInitValue(type));
      clearErrors(`nodes.${index}`);
    },
    [ update, clearErrors, ],
  );

  return (
    <Grid container spacing={4}>
      {fields.map((field, index) => (
        <Grid item xs={12} key={field.id}>
          <Node
            index={index}
            onRemove={handleRemove}
            onChangeType={handleChangeType}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          startIcon={<PlusIcon />}
          color="neutral"
          onClick={handleAdd}
        >
          {t('creation.contentPhase.btnAddBlock')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormInputs;
