import Stack from '@mui/material/Stack';
import { styled, } from '@mui/material/styles';
import { UserTaxInformationFragment, } from 'Apollo/graphql';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import CollapsibleItem from 'Modules/Users/components/CollapsibleItem';
import { useTranslation, } from 'react-i18next';
import { valueOrEmpty, } from 'Utils/parsers';

export const ColleagueWrapper = styled(Stack)(({ theme, }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

interface Props {
  userTax: UserTaxInformationFragment,
};

const View = ({userTax,}: Props): JSX.Element => {
  const { t, } = useTranslation('users');
  return (
            <CollapsibleItem
              label={t('user.labelTaxData')}
            >
              <Stack spacing={2}>
                <NotEditableTextClassic
                  label={t('detailUserTax.firstName')}
                >
                  {valueOrEmpty(userTax.firstName)}
                </NotEditableTextClassic>
                <NotEditableTextClassic
                  label={t('detailUserTax.lastName')}
                >
                  {valueOrEmpty(userTax.lastName)}
                </NotEditableTextClassic>
                <NotEditableTextClassic
                  label={t('detailUserTax.email')}
                >
                  {valueOrEmpty(userTax.email)}
                </NotEditableTextClassic>
                <NotEditableTextClassic
                  label={t('detailUserTax.phone')}
                >
                  {valueOrEmpty(`${userTax.phonePrefix}${userTax.phoneNumber}`)}
                </NotEditableTextClassic>
                <NotEditableTextClassic
                  label={t('detailUserTax.city')}
                >
                  {valueOrEmpty(userTax.city)}
                </NotEditableTextClassic>
                <NotEditableTextClassic
                  label={t('detailUserTax.postCode')}
                >
                  {valueOrEmpty(userTax.postCode)}
                </NotEditableTextClassic>
                <NotEditableTextClassic
                  label={t('detailUserTax.street')}
                >
                  {valueOrEmpty(userTax.street)}
                </NotEditableTextClassic>
                <NotEditableTextClassic
                  label={t('detailUserTax.taxNumber')}
                >
                  {valueOrEmpty(userTax.taxNumber)}
                </NotEditableTextClassic>
                <NotEditableTextClassic
                  label={t('detailUserTax.accountNumber')}
                >
                  {valueOrEmpty(userTax.accountNumber)}
                </NotEditableTextClassic>
                <NotEditableTextClassic
                  label={t('detailUserTax.taxOffice')}
                >
                  {valueOrEmpty(userTax.taxOffice)}
                </NotEditableTextClassic>
                <NotEditableTextClassic
                  label={t('detailUserTax.typeOfPayment')}
                >
                  {valueOrEmpty(userTax.typeOfPayment)}
                </NotEditableTextClassic>
              </Stack>
            </CollapsibleItem>

  );
};

export default View;

