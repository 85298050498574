import { GeneralTaskFragment, useDeleteTaskMutation, } from 'Apollo/graphql';
import { updateTaskRemove, } from 'Modules/TasksAdmin/utils/taskCache';
import { useSnackbar, } from 'notistack';
import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import View from './View';
// import { updateRegistrationRemove, } from '../../utils/registrationCache';
// import View from './View';

interface Props {
  task?: GeneralTaskFragment | null,
  onClose: () => void,
};

const Logic = ({
  task,
  onClose,
}: Props): JSX.Element => {
  const preservedTask = usePreserveValue(task);
  const { t, } = useTranslation('tasksAdmin');
  const { enqueueSnackbar, } = useSnackbar();

  const [ removeMutation, removeMutationResult, ] = useDeleteTaskMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('deleteTask.notifRemovedTitle') as string,
        message: t('deleteTask.notifRemovedMessage', { id: preservedTask.id, }),
        variant: 'common',
      });
      onClose();
    },
    update: updateTaskRemove,
  });

  const handleSubmit = useCallback(
    () => {
      removeMutation({
        variables: { id: preservedTask.id, },
      });
    },
    [ preservedTask, removeMutation, ],
  );

  return (
    <View
      task={preservedTask}
      removeMutationResult={removeMutationResult}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default Logic;
