import { Stack, styled, Typography, } from '@mui/material';
import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import ArrowLeftIcon from 'Utils/svg/ArrowLeftIcon';

const StyledLink = styled('a')(({ theme, }) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  textDecoration: 'none',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
  transition: 'color 0.2s',
  '&:hover': {
    color: theme.palette.text.primary,
  },
  cursor: 'pointer',
  width: 'fit-content',
}));

const ChartDetailLayout = ({
  pageTitle,
  children,
}: {
  pageTitle: string;
  children: ReactNode;
}) => {
  const { t, } = useTranslation('dashboards');
  return (
    <Stack>
      <Stack marginBottom={2}>
        <StyledLink
          onClick={() => {
            // 2 because one is empty page and one is current page
            if (window.history.length > 2){
              window.history.back();
            }else{
              window.location.href = '/dashboard';
            }
          }}
        >
          <ArrowLeftIcon width={10} />
          {t('dashboards.backToDashboard')}
        </StyledLink>
        <Typography variant="h1">{pageTitle}</Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default ChartDetailLayout;
