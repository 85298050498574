import { useTranslation, } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { ImportState, } from './types';

interface Props {
  importState: ImportState,
  onClose: () => void,
};

const SuccessImport = ({
  importState: { result, },
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('pos');

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>
        {t('importPosModal.successImport.title')}
      </ModalDrawerTitle>
      <ModalDrawerBody>
        <Typography
          variant="body1"
        >
          {t('importPosModal.successImport.info')}
        </Typography>
        
        <Box mt={4}>
          <Stack spacing={2}>
            <NotEditableTextClassic
              label={t('importPosModal.successImport.fileName')}
            >
              {result.importFile.filename}
            </NotEditableTextClassic>
            <NotEditableTextClassic
              label={t('importPosModal.successImport.changes')}
            >
              <Typography variant="h4">
                {t('importPosModal.successImport.addedUSers')}
              </Typography>
              <Typography marginBottom={1}>
                {result.numberOfAddedDocuments}
              </Typography>
              <Typography variant="h4">
                {t('importPosModal.successImport.updatedUsers')}
              </Typography>
              <Typography marginBottom={1}>
                {result.numberOfUpdatedDocuments}
              </Typography>
              <Typography variant="h4">
                {t('importPosModal.successImport.deletedUsers')}
              </Typography>
              <Typography marginBottom={2}>
                {result.numberOfDeletedDocuments}
              </Typography>
              <Button
                color="neutral"
                component="a"
                download
                href={result.reportFile.publicUrl}
              >
                {t('importPosModal.successImport.btnDownload')}
              </Button>
            </NotEditableTextClassic>
          </Stack>
        </Box>

        <Box mt={4}>
        <Button
          size="large"
          variant="outlined"
          color="inherit"
          onClick={onClose}
        >
          {t('common:actions.cancel')}
        </Button>
        </Box>
      </ModalDrawerBody>
    </>
  );
};

export default SuccessImport;
