import { yupResolver, } from '@hookform/resolvers/yup';
import { DetailVoucherPurchaseFragment, useUpdateVoucherPurchaseMutation, } from 'Apollo/graphql';
import { mapFormToVariables, useInitVoucherPurchaseValues, voucherPurchaseFormValidationSchema, } from 'Modules/Vouchers/utils/voucherPurchaseForm';
import { useSnackbar, } from 'notistack';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import View from './View';

interface Props {
  voucherPurchase: DetailVoucherPurchaseFragment;
  onClose: () => void;
}

const Logic = ({ voucherPurchase, onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const initValues = useInitVoucherPurchaseValues(voucherPurchase);
  const methods = useForm({
    defaultValues: initValues,
    resolver: yupResolver(voucherPurchaseFormValidationSchema),
    mode: 'onTouched',
  });

  const [editMutation, editMutationResult,] = useUpdateVoucherPurchaseMutation({
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('detailVoucherModal.notifEditedTitle') as string,
        message: t('detailVoucherModal.notifEditedMessage', { id: response.updateVoucherPurchase.id, }),
        variant: 'common',
      });
      onClose();
    },
    onError: () => {
      // TODO: merge form errors
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await editMutation({
      variables: {
        id: voucherPurchase.id,
        voucherPurchaseUpdateInput: mapFormToVariables(values),
      },
    });
  });
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off" >
        <View voucherPurchase={voucherPurchase} editMutationResult={editMutationResult} onClose={onClose} />
      </form>
    </FormProvider>
  );
};

export default Logic;
