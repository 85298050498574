import { TFunction, } from 'react-i18next';
import { ApolloError, } from '@apollo/client';
import { ErrorCodeEnum, } from 'Apollo/graphql';
import { ApolloCustomGqlErrors, CustomErrorMessage, } from './types';
import { translateError, } from './utils';

const APOLLO_CUSTOM_GQL_ERRORS: ApolloCustomGqlErrors = {
  [ErrorCodeEnum.BadFormData]: (gqlError, t) => translateError(
    { text: { key: 'common:errors.invalidForm', }, title: null, },
    t,
  ),
  [ErrorCodeEnum.Forbidden]: (gqlError, t) => translateError(
    { text: { key: 'common:errors.unauthorized', }, title: null, },
    t,
  ),
  'UNAUTHENTICATED': (gqlError, t) => translateError(
    { text: { key: 'common:errors.unauthorized', }, title: null, },
    t,
  ),
  [ErrorCodeEnum.DeviceAlreadyRegistered]: (gqlError, t) => translateError(
    { text: { key: 'common:errors.deviceAlreadyRegistered', }, title: null, },
    t,
  ),
  [ErrorCodeEnum.DeviceCodeDoesNotExist]: (gqlError, t) => translateError(
    { text: { key: 'common:errors.deviceCodeDoesNotExist', }, title: null, },
    t,
  ),
  [ErrorCodeEnum.InvalidDeviceCode]: (gqlError, t) => translateError(
    { text: { key: 'common:errors.invalidDeviceCode', }, title: null, },
    t,
  ),
  [ErrorCodeEnum.PromotionLimitExceeded]: (gqlError, t) => translateError(
    { text: { key: "common:errors.promotionLimit", }, title: null, },
    t,
  ),
};

type Args = {
  apolloError: ApolloError,
  apolloCustomGqlErrors?: ApolloCustomGqlErrors,
  t: TFunction,
};

export const parseApolloError = ({
  apolloError,
  apolloCustomGqlErrors,
  t,
}: Args): CustomErrorMessage | null => {
  // if (apolloError.networkError) {
  //   TODO - connection problem
  // }
  if (apolloError.graphQLErrors?.[0]) {
    const gqlError = apolloError.graphQLErrors?.[0];

    if (gqlError.message === 'Invalid phone number') {
      return translateError({ text: { key: "common:inputErrors.phone" }, title: null }, t)
    }

    const code = gqlError.extensions?.code as string || null;

    const groupedGqlErrors = {
      ...APOLLO_CUSTOM_GQL_ERRORS,
      ...(apolloCustomGqlErrors || {}),
    };

    if (code && Object.prototype.hasOwnProperty.call(groupedGqlErrors, code)) {
      return groupedGqlErrors[code as ErrorCodeEnum]?.(gqlError, t) || null;
    }
  }

  return null;
};
