import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled, } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useCurrentMarketQuery, } from 'Apollo/graphql';
import FormSubmit from 'Components/Form/components/FormSubmit';
import FormInputText from 'Components/Form/inputs/FormInputText';
import ImageAsync from 'Components/ImageAsync';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import { positionUrl, } from 'Utils/deviceCodeImages';
import CardItem from './CardItem';

const ImageWrapper = styled('div')(() => ({
  position: 'relative',
  minHeight: 200,
  width: '100%',
  '& > img': {
    position: 'relative',
  },
}));

interface Props {
  totalDeviceCodeCount: number;
  currentDeviceCodeIndex: number;
  assortmentName: string;
  assortmentImageUrl: string;
  handleGoBack: () => void;
}

const DeviceCodeForm = ({ totalDeviceCodeCount, currentDeviceCodeIndex, assortmentName, assortmentImageUrl, handleGoBack, }: Props): JSX.Element => {
  const { t, } = useTranslation('registrationSeller');
  const currentMarket = useCurrentMarketQuery();
  const deviceCodeImageUrl = positionUrl(currentMarket.data?.currentMarket);
  return (
    <>
      <Typography variant="h1" sx={{ mb: 1.5, }}>
        {t('phaseDeviceCodeInput.title', { in: currentDeviceCodeIndex, many: totalDeviceCodeCount, })}
      </Typography>
      <Typography variant="body1" sx={{ mb: 5, }}>
        {t('phaseDeviceCodeInput.info')}
      </Typography>
      <CardItem title={assortmentName} imageSrc={assortmentImageUrl} />
      <FormInputText sx={{ marginTop: '2.5rem ', }} name="deviceCode" label={t('phaseRegisterCustomer.form.labelDeviceCode')} required />
      <Typography variant="body1" sx={{ mt: 3, mb: 3, }}>
        {t('phaseDeviceCodeInput.deviceCodeInfo')}
      </Typography>
      {!currentMarket.loading && <ImageWrapper>
        <ImageAsync src={deviceCodeImageUrl} />
      </ImageWrapper>}
      <Grid container spacing={2} sx={{ marginTop: 2, }}>
        <Grid item xs={12} lg="auto">
          <FormSubmit color="primary" variant="contained" size="large" sx={{ width: { xs: '100%', lg: 'unset', }, }}>
            {t('phasePurchase.submit')}
          </FormSubmit>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button size="large" variant="outlined" color="primary" sx={{ width: { xs: '100%', lg: 'unset', }, }} onClick={handleGoBack}>
            {t('common:actions.back')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DeviceCodeForm;
