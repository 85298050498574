import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useSnackbar, } from 'notistack';
import { AssortmentFragment, useUpdateAssortmentMutation, } from 'Apollo/graphql';
import {
  assortmentFormValidationSchema,
  mapFormToVariables,
  useInitAssortmentValues,
} from '../../utils/assortmentForm';
import View from './View';

interface Props {
  assortment: AssortmentFragment,
  onClose: () => void,
};

const Logic = ({
  assortment,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('assortmentAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const initValues = useInitAssortmentValues(assortment);
  const methods = useForm({
    defaultValues: initValues,
    resolver: yupResolver(assortmentFormValidationSchema),
    mode: 'onTouched',
  });

  const [ editMutation, editMutationResult, ] = useUpdateAssortmentMutation({
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('editAssortmentModal.notifEditedTitle') as string,
        message: t('editAssortmentModal.notifEditedMessage', { productId: response.updateAssortment.id, }),
        variant: 'common',
      });
      onClose();
    },
    onError: () => {
      // TODO: merge form errors
    },
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await editMutation({
      variables: {
        id: assortment.id,
        assortmentInput: mapFormToVariables(values),
      },
    });    
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View
          assortment={assortment}
          editMutationResult={editMutationResult}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default Logic;
