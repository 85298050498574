import { useTranslation, } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Transactions from 'Modules/MyPointsSeller/pages/MyPoints/Transactions';
import Balance from './Balance';
import Vouchers from './Vouchers';


const View = (): JSX.Element => {
  const { t, } = useTranslation("myPoints");
  return (
    <>
      <Typography variant="h1" mb={1.5}>
        {t('generic.title')}
      </Typography>
      <Typography variant="h2" mb={1.5} color="primary">
        {t('balance.title')}
      </Typography>
      <Paper
        sx={{ marginBottom: 4, }}
        elevation={1}
      >
        <Balance />
      </Paper>
      <Typography variant="h2" mb={1.5} color="primary">
        {t('transaction.title')}
      </Typography>
      <Paper
        elevation={1}
      >
        <Transactions />
      </Paper>
      <Typography variant="h2" mb={1.5} mt={3.5} color="primary">
        {t('voucher.title')}
      </Typography>
      <Vouchers />
    </>
  );
};

export default View;
