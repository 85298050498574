import { MouseEventHandler, } from 'react';
import { useTranslation, } from 'react-i18next';
import Chip from '@mui/material/Chip';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import { Claim, ReplacementTableFragment, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellBlock from 'Components/Table/TableCellBlock';
import TableCellText from 'Components/Table/TableCellText';
import { useReplacementStatus, } from 'Tools/useReplacementStatus';

interface Props {
  row: ReplacementTableFragment,
  onRemove: (replacement: ReplacementTableFragment) => void,
  onDetail: (replacement: ReplacementTableFragment) => void,
  onEdit: (replacement: ReplacementTableFragment) => void,
};

const ReplacementTableRow = ({
  row,
  onDetail,
  onRemove,
  onEdit,
}: Props): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  const chip = useReplacementStatus(row.status);
  const user = useAuthUser();

  const handleRemove: MouseEventHandler<HTMLLIElement> = () => {
    onRemove(row);
  };

  
  const handleDetail: MouseEventHandler<HTMLLIElement> = () => user?.claimsSet.has(Claim.UpdateReplacement) ? onEdit(row) : onDetail(row);
  const showChainIDs = user?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;

  return (
    <TableRow key={row.id}>
      <TableCellText>
        {row.id}
      </TableCellText>
      <TableCellText>
        {row.category.name || '-'}
      </TableCellText>
      <TableCellText>
        {row.batchCode || '-'}
      </TableCellText>
      <TableCellText>
        {row.reason || '-'}
      </TableCellText>
      <TableCellBlock>
        <Chip
          color={chip.color}
          label={chip.label}
        />
      </TableCellBlock>
      <TableCellText>
        {row.author.username || '-'}
      </TableCellText>
      <TableCellText>
        {row.completor?.username || '-'}
      </TableCellText>
      <TableCellText>
        {row.posId || '-'}
      </TableCellText>
      {showChainIDs &&
      <TableCellText>
        {row.chainId || '-'}
      </TableCellText>}
      <TableCellActions>
        <MenuItem
          onClick={handleDetail}
        >
          {t('replacements.table.btnDetail')}
        </MenuItem>
        {user?.claimsSet.has(Claim.DeleteReplacement) && (
          <MenuItem
            onClick={handleRemove}
          >
            {t('replacements.table.btnRemove')}
          </MenuItem>
        )}
      </TableCellActions>
    </TableRow>
  );
};

export default ReplacementTableRow;
