import { Query, } from '@cubejs-client/core';
import { QueryParams, } from '../types';

export const numberOfActiveTasksQuery = ({
  filters = [],
}: QueryParams): Query => ({
  measures: ['task.count',],
  timeDimensions: [
    {
      dimension: 'task.createdat',
    },
  ],
  dimensions: ['task.type',],
  order: {
    'task.count': 'desc',
  },
  filters: [
    {
      member: 'task_completion.status',
      operator: 'notEquals',
      values: ['COMPLETED',],
    },
    ...filters,
  ],
});

export const numberOfTaskCompletionsQuery = ({
  filters = [],
}: QueryParams): Query => ({
  measures: ['task.count',],
  timeDimensions: [
    {
      dimension: 'task.createdat',
    },
  ],
  dimensions: ['task.type',],
  order: {
    'task.count': 'desc',
  },
  filters: [
    {
      member: 'task_completion.status',
      operator: 'equals',
      values: ['COMPLETED',],
    },
    ...filters,
  ],
});

export const pointsCategoryQuery = ({ filters = [], }: QueryParams): Query => ({
  measures: ['points_transaction.amount',],
  timeDimensions: [
    {
      dimension: 'points_transaction.createdat',
    },
  ],
  order: {
    'points_transaction.amount': 'desc',
  },
  dimensions: ['points_transaction.reason',],
  filters,
});

export const totalNumberOfPointsPlusQuery = ({
  timeDimensions,
  filters = [],
}: QueryParams): Query => ({
  measures: ['points_transaction.amount',],
  timeDimensions: [
    {
      dimension: 'points_transaction.createdat',
      dateRange: timeDimensions?.[0]?.dateRange,
    },
  ],
  filters: [
    {
      member: 'points_transaction.operation',
      operator: 'equals',
      values: ['Plus',],
    },
    ...filters,
  ],
});

export const totalNumberOfPointsMinusQuery = ({
  timeDimensions,
  filters = [],
}: QueryParams): Query => ({
  measures: ['points_transaction.amount',],
  timeDimensions: [
    {
      dimension: 'points_transaction.createdat',
      dateRange: timeDimensions?.[0]?.dateRange,
    },
  ],
  filters: [
    {
      member: 'points_transaction.operation',
      operator: 'equals',
      values: ['Minus',],
    },
    ...filters,
  ],
});

export const totalNumberOfPointsQuery = ({
  filters = [],
}: QueryParams): Query => ({
  timeDimensions: [
    {
      dimension: 'points_transaction.createdat',
    },
  ],
  order: {
    'points_transaction.amount': 'desc',
  },
  dimensions: ['points_transaction.operation',],
  measures: ['points_transaction.amount',],
  filters,
});
