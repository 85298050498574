import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AvailableVoucherType, VoucherSortableFields, VoucherType, } from 'Apollo/graphql';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import TableFilterInt from 'Components/Table/filters/TableFilterInt';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import TableCellSortable from 'Components/Table/TableCellSortable';
import { useTranslation, } from 'react-i18next';
import { renderOptionById, } from 'Utils/helpers';
import { useAvailableVoucherTypes, } from 'Utils/options/useOptionsAvailableVoucherTypes';

const COL_WIDTHS = [
  {width: '150px',},
  {width: '140px',},
  {width: '200px',},
  {width: '150px',}, 
  {width: '120px',},
  {width: '120px',},
  {width: '150px',},
  {width: '150px',},
  {width: '150px',},
  {width: '150px',},
  {width: '45px',},
];
interface Props {
  availableTypes: {
    __typename?: "AvailableVoucherType" | undefined;
    id: VoucherType;
    labelKey: string;
}[] | undefined

}
const VouchersTableHeader = ({availableTypes,}:Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const availableVoucherTypes = useAvailableVoucherTypes(availableTypes || []);
  return (
    <TableHead>
      <TableRow>
        <TableCell
        style={COL_WIDTHS[0]}
        >
          {t('vouchers.table.id')}
        </TableCell>
        <TableCell
        style={COL_WIDTHS[0]}
        >
          {t('vouchers.table.type')}
        </TableCell>
        <TableCellSortable
          style={COL_WIDTHS[1]}
          name={VoucherSortableFields.Name}
        >
          {t('vouchers.table.name')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[2]}
          name={VoucherSortableFields.Description}
        >
          {t('vouchers.table.description')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[3]}
          name={VoucherSortableFields.PriceFiat}
        >
          {t('vouchers.table.priceFiat')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[4]}
          name={VoucherSortableFields.PricePoints}
        >
          {t('vouchers.table.pricePoints')}
        </TableCellSortable>
        <TableCell
        style={COL_WIDTHS[5]}
        >
          {t('vouchers.table.number')}
        </TableCell>
        <TableCellSortable
          style={COL_WIDTHS[6]}
          name={VoucherSortableFields.ValidFrom}
        >
          {t('vouchers.table.validFrom')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[7]}
          name={VoucherSortableFields.ValidTo}
        >
          {t('vouchers.table.validTo')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[8]}
          name={VoucherSortableFields.CreatedAt}
        >
          {t('vouchers.table.createdAt')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[9]}
          name={VoucherSortableFields.AuthorId}
        >
          {t('vouchers.table.authorId')}
        </TableCellSortable>
        <TableCell
          style={COL_WIDTHS[10]}
        />
      </TableRow>
      <TableRow>
        <TableCell>
          <TableFilterText
            name="id"
          />
        </TableCell>
        <TableCell>
        <TableFilterAutocomplete<AvailableVoucherType>
            name="type"
            options={availableVoucherTypes.options}
            getOptionLabel={availableVoucherTypes.getOptionLabel}
            isOptionEqualToValue={availableVoucherTypes.isOptionEqualToValue}
            renderOption={renderOptionById(availableVoucherTypes.getOptionLabel)}
            // filterOptions={availableVoucherTypes.filterOptions}
            // onInputChange={availableVoucherTypes.onInputChange}
            // noOptionsText={availableVoucherTypes.noOptionsText}
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="name"
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="description"
          />
        </TableCell>
        <TableCell>
          <TableFilterInt
            name="priceFiat"
          />
        </TableCell>
        <TableCell>
          <TableFilterInt
            name="pricePoints"
          />
        </TableCell>
        <TableCell>
          <TableFilterInt
            name="number"
          />
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell>
          <TableFilterText
            name="author"
          />
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default VouchersTableHeader;
