import { useTranslation, } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AssortmentCategoryOptionFragment, ChainFragment, PointOfSellOptionFragment, ReplacementSortableFields, useAssortmentCategoryOptionsReplacementsQuery, useChainsQuery, } from 'Apollo/graphql';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import { areOptionsEqualById, getOptionFieldName, renderOptionByIdName, renderOptionById, getOptionFieldId, } from 'Utils/helpers';
import TableCellSortable from 'Components/Table/TableCellSortable';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import { useOptionsReplacementStatus, ReplacementStatusOption, } from 'Utils/options/useOptionsReplacementStatus';
import { useSearchOptionsPos, } from 'Utils/options/useSearchOptionsPos';
import { useAuthUser, } from 'Tools/auth';

const COL_WIDTHS = [
  { width: '140px', },
  { width: '170px', },
  { width: '250px', },
  { width: '170px', },
  { width: '150px', },
  { width: '150px', },
  { width: '150px', },
  { width: '110px', },
  { width: '45px', },
];

const ReplacementTableHeader = (): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  const replacementStatusOptions = useOptionsReplacementStatus();
  const categoriesOptionsResult = useAssortmentCategoryOptionsReplacementsQuery({ fetchPolicy: 'cache-and-network', });
  const chainOptions = useChainsQuery();
  const posOptions = useSearchOptionsPos();

  const authUser = useAuthUser();
  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;

  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={COL_WIDTHS[0]}
        >
          {t('replacements.table.labelReplacementId')}
        </TableCell>
        <TableCell
          style={COL_WIDTHS[1]}
        >
          {t('replacements.table.labelCategory')}
        </TableCell>
        <TableCell
          style={COL_WIDTHS[1]}
        >
          {t('replacements.table.labelBatchCode')}
        </TableCell>
        <TableCell
          style={COL_WIDTHS[2]}
        >
          {t('replacements.table.labelReason')}
        </TableCell>
        <TableCellSortable
          style={COL_WIDTHS[3]}
          name={ReplacementSortableFields.Status}
        >
          {t('replacements.table.labelStatus')}
        </TableCellSortable>
        <TableCell
          style={COL_WIDTHS[4]}
        >
          {t('replacements.table.labelUsername')}
        </TableCell>
        <TableCell
          style={COL_WIDTHS[5]}
        >
          {t('replacements.table.labelCompletor')}
        </TableCell>
        <TableCellSortable
          style={COL_WIDTHS[6]}
          name={ReplacementSortableFields.PosId}
        >
          {t('replacements.table.labelPosId')}
        </TableCellSortable>
        {showChainIDs && <TableCellSortable
          style={COL_WIDTHS[7]}
          name={ReplacementSortableFields.ChainId}
        >
          {t('replacements.table.labelChainId')}
        </TableCellSortable>}
        <TableCell
          style={COL_WIDTHS[8]}
        />
      </TableRow>
      <TableRow>
        <TableCell>
          <TableFilterText
            name="id"
          />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<AssortmentCategoryOptionFragment>
            name="category"
            getOptionLabel={getOptionFieldName}
            isOptionEqualToValue={areOptionsEqualById}
            renderOption={renderOptionByIdName}
            options={categoriesOptionsResult.data?.assortmentCategoriesReplacement || []}
            loading={categoriesOptionsResult.loading}
          />
        </TableCell>
        <TableCell />
        <TableCell>
          <TableFilterText
            name="reason"
          />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<ReplacementStatusOption>
            name="status"
            getOptionLabel={replacementStatusOptions.getOptionLabel}
            isOptionEqualToValue={replacementStatusOptions.isOptionEqualToValue}
            renderOption={renderOptionById(replacementStatusOptions.getOptionLabel)}
            options={replacementStatusOptions.options}
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="username"
          />
        </TableCell>
        <TableCell>
          <TableFilterText
            name="completor"
          />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<PointOfSellOptionFragment>
            name="pos"
            options={posOptions.options}
            getOptionLabel={posOptions.getOptionLabel}
            isOptionEqualToValue={posOptions.isOptionEqualToValue}
            renderOption={renderOptionById(posOptions.getOptionLabel)}
            filterOptions={posOptions.filterOptions}
            loading={posOptions.loading}
            onInputChange={posOptions.onInputChange}
            noOptionsText={posOptions.noOptionsText}
          />
        </TableCell>
        {showChainIDs &&
        <TableCell>
          <TableFilterAutocomplete<ChainFragment>
            name="chain"
            options={chainOptions.data?.chains || []}
            getOptionLabel={getOptionFieldId}
            isOptionEqualToValue={areOptionsEqualById}
            renderOption={renderOptionById(getOptionFieldId)}
          />
        </TableCell>}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default ReplacementTableHeader;
