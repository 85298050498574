import { WheelData, } from "react-custom-roulette/dist/components/Wheel/types";

const imageProperties = {
    sizeMultiplier: 1.2,
    offsetY: -70,
};

export const data: WheelData[] = [
    {
        image: {
            ...imageProperties,
            uri: '/images/xmas-wheel/hu/wish_1.svg',
        },
    },
    {
        image: {
            ...imageProperties,
            uri: '/images/xmas-wheel/hu/wish_2.svg',
          },
    },
    {
        image: {
            ...imageProperties,
            uri: '/images/xmas-wheel/hu/wish_3.svg',
          },
    },
    {
        image: {
            ...imageProperties,
            uri: '/images/xmas-wheel/hu/wish_4.svg',
          },
    },
    {
        image: {
            ...imageProperties,
            uri: '/images/xmas-wheel/hu/wish_5.svg',
          },
    },
    {
        image: {
            ...imageProperties,
            uri: '/images/xmas-wheel/hu/wish_6.svg',
          },
    },
    {
        image: {
            ...imageProperties,
            uri: '/images/xmas-wheel/hu/wish_7.svg',
          },
    },
    {
        image: {
            ...imageProperties,
            uri: '/images/xmas-wheel/hu/wish_8.svg',
          },
    },
];