import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const CloseIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 18 19"
    fill="none"
    {...props}
    style={{ width: "unset", }}
  >
    <path
      d="m13.974 12.704 2.871 2.872a1.405 1.405 0 0 1 .284 1.685c-.279.549-.858.9-1.448.762a2.124 2.124 0 0 1-.952-.518c-1.88-1.846-3.736-3.718-5.602-5.583-.384-.385-.396-.385-.77-.01l-5.62 5.62c-.43.428-.927.643-1.53.455a1.428 1.428 0 0 1-.663-2.323c.079-.09.17-.168.255-.254l5.515-5.515c.38-.38.38-.403.01-.773L.705 3.503C.36 3.16.139 2.764.2 2.256a1.37 1.37 0 0 1 .93-1.211 1.398 1.398 0 0 1 1.472.293c.1.086.186.186.28.28l5.49 5.49c.344.344.382.344.72.007l5.646-5.646c.43-.43.934-.64 1.532-.452a1.425 1.425 0 0 1 .655 2.324c-.076.09-.168.168-.253.253L11.105 9.16c-.31.31-.31.363-.006.669.959.96 1.917 1.918 2.875 2.874Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CloseIcon;
