import { styled, } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { FileFragment, } from 'Apollo/graphql';
import ImageAsync from 'Components/ImageAsync';

const StyledDropAreaValue = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const StyledImageContainer = styled('div')(() => ({
  position: 'relative',
  width: 80,
  height: 55,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

interface Props {
  value: FileFragment
};

const DropAreaValue = ({
  value,
}: Props): JSX.Element => {
  return (
    <StyledDropAreaValue>
      <StyledImageContainer>
        <ImageAsync
          src={value.publicUrl}
        />
      </StyledImageContainer>
      <Typography
        variant="body1"
        sx={{
          ml: 1,
          wordBreak: 'break-all',
        }}
      >
        {value.filename}
      </Typography>
    </StyledDropAreaValue>
  );
};

export default DropAreaValue;
