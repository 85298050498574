import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled, } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useCreateVoucherPurchaseMutation, VoucherQuery, } from 'Apollo/graphql';
import ImageAsync from 'Components/ImageAsync';
import { useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { useAuthUser, } from 'Tools/auth/hooks/useAuthUser';
import { ROUTES, } from 'Utils/constants';
import { valueOrEmpty, } from 'Utils/parsers';

type Props = {
  data: VoucherQuery;
};

const Item = styled(Paper)(({ theme, }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.5, 2),
  marginBottom: '1rem',
}));

const StyledChip = styled(Chip)(({ theme, }) => ({
  color: 'white',
  background: theme.palette.primary.main,
  fontVariant: 'h1',
  alignSelf: 'flex-end',
}));

const StandartView = ({ data, }: Props): JSX.Element => {
  const { t, } = useTranslation('myPoints');
  const authUser = useAuthUser();
  const { voucher, } = data;
  const dateFormatted = valueOrEmpty(`${t('common:date.fullDate', { date: new Date(voucher.validTo), })}`);
  const navigate = useNavigate();
  const backToPoints = () => navigate(`/${ROUTES.myPoints}`);
  const [state, setState,] = useState(1);
  const [createMutation,] = useCreateVoucherPurchaseMutation({
    onCompleted: (response) => {
      setState((s) => 0);
    },
    onError: () => {
      // TODO: merge form errors
    },
  });
  const createPurchase = () =>
    createMutation({
      variables: {
        voucherPurchaseInput: {
          userId: authUser?.id || '',
          voucherId: data.voucher.id,
        },
      },
    });
  if (state === 0) {
    return (
      <>
        <Typography variant="h1" mb={1.5}>
          {t('voucher.detailTitlePurchased')}
        </Typography>
        <Typography variant="inherit" mb={4}>
          {t('voucher.detailDescriptionPurchased')}
        </Typography>
        <Button size="large" fullWidth variant="contained" color="primary" onClick={backToPoints}>
          {t('common:actions.done')}
        </Button>
      </>
    );
  }
  return (
    <>
      <Typography variant="h1" mb={1.5}>
        {t('voucher.detailTitle')}
      </Typography>
      <Item>
        <StyledChip label={`${voucher.pricePoints} ${t('voucher.points')}`} />
        <ImageAsync src={voucher.image?.publicUrl} />
        <Box display="flex" justifyContent="space-between" flexDirection="column">
          <Typography variant="h2" color="primary">
            {voucher.name}
          </Typography>
          <Typography variant="inherit" color="#4C4C4C">{`${t('voucher.expires')}: ${dateFormatted}`}</Typography>
          <Typography mt={4} mb={2} variant="inherit">
            {voucher.description}
          </Typography>
            <Button size="large" variant="contained" color="primary" onClick={createPurchase} disabled={!voucher.canPurchase}>
              {t('voucher.purchase')}
            </Button>
            {!voucher.canPurchase && (
              <Typography mt={1} color="#E56E6E">
                {t('voucher.cantPurchase')}
              </Typography>
             )}
        </Box>
      </Item>
      <Button size="large" fullWidth variant="outlined" color="inherit" onClick={backToPoints}>
        {t('common:actions.back')}
      </Button>
    </>
  );
};

export default StandartView;
