import { zodResolver, } from '@hookform/resolvers/zod';
import { Grid, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import ChartDetailLayout from 'Components/Dashboard/ChartDetailLayout';
import ActiveInactiveUsers from 'Components/Dashboard/Users/ActiveInactiveUsers';
import { activeInactiveUsersQuery, } from 'Components/Dashboard/Users/queries';
import { useMemo, } from 'react';
import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { OptionTypes, ChartType, } from 'Utils/Charts/types';
import useDashboardFilter from 'Utils/Charts/UseDashboardFilter';
import { SourceValidationSchema, } from 'Utils/Charts/ValidationSchemas';
import { z, } from 'zod';

type ValidationSchema = z.infer<typeof SourceValidationSchema>;

const ActiveInactiveUsersPage = () => {
  const { t, } = useTranslation('dashboards');

  const filtration = [OptionTypes.source,];
  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(SourceValidationSchema),
    defaultValues: {
      chainIds: [],
      posIds: [],
    },
  });
  const watch = methods.watch();

  const { filtersArray, } = useDashboardFilter({
    chainIds: {
      operator: 'equals',
      values: watch.chainIds,
      filterMember: 'user.chainid',
    },
    posIds: {
      operator: 'equals',
      values: watch.posIds,
      filterMember: 'user.posid',
    },
  });

  const query = useMemo(
    () => activeInactiveUsersQuery({ filters: filtersArray, }),
    [filtersArray,],
  );
  return (
    <AppContainer>
      <ChartDetailLayout pageTitle={t('common:dashboardSidebar.users')}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <ActiveInactiveUsers
                showTotal
                showSlug={false}
                filtration={filtration}
                chartFilter={filtersArray}
              />
            </FormProvider>
          </Grid>
          <Grid item xs={12}>
            <ChartRenderer
              query={query}
              type={ChartType.table}
              pivotConfig={{
                x: ['user.hascreatedpurchase',],
                y: ['measures',],
                fillMissingDates: true,
                joinDateRange: false,
              }}
            />
          </Grid>
        </Grid>
      </ChartDetailLayout>
    </AppContainer>
  );
};

export default ActiveInactiveUsersPage;
