import { PromotionFragment, } from 'Apollo/graphql';
import Typography from '@mui/material/Typography';
import CardLayout from './CardLayout';

interface Props {
  promotion: PromotionFragment
}

const CardItem = ({ promotion, }: Props): JSX.Element => {
  return (
    <CardLayout
      title={promotion.name}
      imageSrc={promotion.image?.publicUrl}
      bannerSrc={promotion.banner?.publicUrl}
    >
      <Typography variant="h2" component="p" marginBottom={1}>
        {promotion.name}
      </Typography>
      <Typography>
        {promotion.description}
      </Typography>
    </CardLayout>
  );
};

export default CardItem;

