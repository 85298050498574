import { useTranslation, } from 'react-i18next';
import Grid from '@mui/material/Grid';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { NumberAssessmentBlockFragment, ScaleAssessmentBlockFragment, TextAssessmentBlockFragment, } from 'Apollo/graphql';
import { BlockProps, } from '../types';
import BlockLayout from '../BlockLayout';

const BlockTextNumberScale = ({
  index,
  block,
  showAssessmentCorrectAnswers,
}: BlockProps<TextAssessmentBlockFragment | NumberAssessmentBlockFragment | ScaleAssessmentBlockFragment>): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');

  return (
    <BlockLayout
      index={index}
      type={block.type}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={showAssessmentCorrectAnswers ? 9 : 12}>
          <NotEditableTextClassic
            label={t('assessment.labelQuestion')}
          >
            {block.question}
          </NotEditableTextClassic>
        </Grid>
        {showAssessmentCorrectAnswers && (
          <Grid item xs={12} sm={showAssessmentCorrectAnswers ? 3 : 12}>
            <NotEditableTextClassic
              label={t('assessment.labelPoints')}
            >
              {block.numberOfPoints}
            </NotEditableTextClassic>
          </Grid>
        )}
      </Grid>
    </BlockLayout>
  );
};

export default BlockTextNumberScale;
