import { ReactNode, } from 'react';
import { styled, } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ImageAsync from 'Components/ImageAsync';

const CardContainer = styled(Card)(({ theme, }) => ({
  borderRadius: 0,
  marginBottom: theme.spacing(4),
  boxShadow: 'none',
}));

const BannerContainer = styled('div')(() => ({
  height: 45,
  minHeight: 45,
  width: 70,
  minWidth: 70,
  marginRight: 4,
}));

const ImageContainer = styled('div')(({ theme, }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '75%',
  marginBottom: theme.spacing(2),

  '& > img': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const DetailContainer = styled(Box)(({ theme, }) => ({
  padding: theme.spacing(2, 1.5),
  margin: theme.spacing(2, 1.5),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.grey[100],
}));

interface Props {
  title: ReactNode,
  bannerSrc?: string,
  imageSrc?: string,
  children: ReactNode,
}

const CardLayout = ({
  title,
  children,
  bannerSrc,
  imageSrc,
}: Props): JSX.Element => {
  return (
    <CardContainer>

      <Box
        p={1.5}
        display="flex"
        alignItems="center"
      >
        <BannerContainer>
          <ImageAsync src={bannerSrc} />
        </BannerContainer>
        <Typography variant="h3" component="h2" color="primary">{title}</Typography>
      </Box>

      <Divider />

      <DetailContainer display="flex" flexDirection="column">
        <ImageContainer>
          <ImageAsync src={imageSrc} />
        </ImageContainer>
        {children}
      </DetailContainer>

    </CardContainer>
  );
};

export default CardLayout;

