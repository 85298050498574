import { useTranslation, Trans, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import LogoIcon from 'Utils/svg/LogoIcon';
import { PRIVACY_POLICY_LINK, } from 'Utils/constants';

const FooterPaper = styled(Paper)(({ theme, }) => ({
  position: 'relative',
  borderRadius: 0,
}));

const WrapperTermsAndConditions = styled('div')(({ theme, }) => ({
  backgroundColor: theme.palette.background.default,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const Footer = (): JSX.Element => {
  const {
    t,
    i18n: { language, },
  } = useTranslation('footer');

  return (
    <div>
      <FooterPaper elevation={0}>
        <Container>
          <Box py={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box fontSize={45} mb={1.5}>
                  <LogoIcon fontSize="inherit" />
                </Box>
                <Trans
                  i18nKey="footer:publisher"
                  transWrapTextNodes="span"
                  components={{
                    text: <Typography component="span" variant="footer" />,
                    publisher: <Link color="secondary" href={t('urlToPublisher')} rel="noopener noreferrer" target="_blank" variant="footer" />,
                  }}
                />
                <Typography mt={1.5} variant="footer" component="div">
                  {t('registration')}
                </Typography>
                <Typography variant="footer" component="div">
                  {t('taxNumber')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight="bold" variant="footer" component="div">
                  {t('headquarterTitle')}
                </Typography>
                <Typography variant="footer" component="div">
                  {t('headquarterName')}
                </Typography>
                <Typography variant="footer" component="div">
                  {t('headquarterAddress')}
                </Typography>
                <Typography variant="footer" component="div">
                  {t('headquarterPhone')}
                </Typography>
                {language === 'cz' && 
                  <Typography variant="footer" component="div">
                    {t('businessPartnerPhone')}
                  </Typography>
                }
                <Typography variant="footer" component="div">
                  {t('headquarterEmail')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </FooterPaper>
      <Container>
        <WrapperTermsAndConditions>
          <Grid
            container
            spacing={2}
            justifyContent={{ xs: 'flex-start', md: 'center', }}
          >
            <Grid item md="auto" xs={12} order={{ xs: 2, md: 0, }}>
              <Typography
                color="#808080"
                variant="footer"
              >
                {t('copyright')}
              </Typography>
            </Grid>
            <Grid item order={{ xs: 0, md: 1, }}>
              <Link
                color="secondary"
                href={`/tos/${language}/termsOfUse.pdf`}
                rel="noopener noreferrer"
                target="_blank"
                variant="footer"
              >
                {t('linkTermsAndConditions')}
              </Link>
            </Grid>
            <Grid item order={{ xs: 1, md: 2, }}>
              <Link
                color="secondary"
                href={PRIVACY_POLICY_LINK}
                rel="noopener noreferrer"
                target="_blank"
                variant="footer"
              >
                {t('linkPrivacyPolicy')}
              </Link>
            </Grid>
          </Grid>
        </WrapperTermsAndConditions>
      </Container>
    </div>
  );
};

export default Footer;
