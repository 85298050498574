import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useTranslation, } from 'react-i18next';
import { useMemo, } from 'react';
import { topRegistrationsByCitiesQuery, } from './queries';

const RegistrationsByCities = ({
  showAddButton,
  showSlug = true,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');
  const query = useMemo(() => topRegistrationsByCitiesQuery(), []);
  return (
    <Base
      headerText={t('dashboards.registrations.registrationsByCities')}
      slug={showSlug ? 'registrations-by-cities' : undefined}
      showAddButton={showAddButton}
      dashboardLayoutItemName={
        DashboardLayoutItemName.RegistrationsTop_10Cities
      }
    >
      <ChartRenderer query={query} type={ChartType.bar} hideNullValues />
    </Base>
  );
};

export default RegistrationsByCities;
