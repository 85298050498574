import { ReactNode, } from 'react';
import { useFormContext, useWatch, } from 'react-hook-form';
import { AssessmentBlockTypeEnum, } from 'Apollo/graphql';
import { AssessmentBlockOption, } from 'Utils/options/useOptionsAssessmentBlock';
import { NodeKeys, } from '../types';
import { NodeProps, } from './types';
import { buildNodeInputName, } from './utils';
import NodeEmpty from './types/NodeEmpty';
import NodeText from './types/NodeText';
import NodeYesNo from './types/NodeYesNo';
import NodeNumber from './types/NodeNumber';
import NodeScale from './types/NodeScale';
import NodeRadio from './types/NodeRadio';
import NodeCheckbox from './types/NodeCheckbox';
import NodePhotoTask from './types/NodePhotoTask';

type NodeTypes = {
  [key in (NodeKeys)]: (props: NodeProps) => ReactNode
};

const NODE_TYPES: NodeTypes = {
  default: (props) => <NodeEmpty {...props} />,
  [AssessmentBlockTypeEnum.Text]: (props) => <NodeText {...props} />,
  [AssessmentBlockTypeEnum.Number]: (props) => <NodeNumber {...props} />,
  [AssessmentBlockTypeEnum.YesNo]: (props) => <NodeYesNo {...props} />,
  [AssessmentBlockTypeEnum.Checkbox]: (props) => <NodeCheckbox {...props} />,
  [AssessmentBlockTypeEnum.Radio]: (props) => <NodeRadio {...props} />,
  [AssessmentBlockTypeEnum.Scale]: (props) => <NodeScale {...props} />,
  [AssessmentBlockTypeEnum.Photo]: (props) => <NodePhotoTask {...props} />,
};

const Node = (props: NodeProps): JSX.Element => {
  const { index, } = props;
  const { control, } = useFormContext();
  const type = useWatch({ control, name: buildNodeInputName(index, 'type'), }) as AssessmentBlockOption;

  if (type && Object.prototype.hasOwnProperty.call(NODE_TYPES, type.id)) return (
    <>{NODE_TYPES[type.id as NodeKeys](props)}</>
  );
  return <>{NODE_TYPES.default(props)}</>;
};

export default Node;
