import {
  PaginationInput,
  StringFilterMode,
  VoucherFilterInput,
  VoucherPurchaseFilterInput,
  VoucherPurchaseSortInput,
  VoucherSortInput,
} from 'Apollo/graphql';
import { VoucherPurchaseStore, } from 'Modules/Vouchers/store/VoucherPurchaseStore';
import { VoucherStore, } from 'Modules/Vouchers/store/VoucherStore';

export const mapVariablesVoucher = (
  storeData: VoucherStore['tableVouchers'],
): {
  filter?: VoucherFilterInput;
  pagination?: PaginationInput;
  sort?: VoucherSortInput;
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    author: storeData.filter.author ? { username: { contains: storeData.filter.author, }, } : undefined,
    description: storeData.filter.description ? { contains: storeData.filter.description, mode: StringFilterMode.Insensitive, } : undefined,
    id: storeData.filter.id ? { contains: storeData.filter.id, } : undefined,
    name: storeData.filter.name ? { contains: storeData.filter.name, } : undefined,
    number: storeData.filter.number ? { equals: storeData.filter.number, } : undefined,
    priceFiat: storeData.filter.priceFiat ? { equals: storeData.filter.priceFiat, } : undefined,
    pricePoints: storeData.filter.pricePoints ? { equals: storeData.filter.pricePoints, } : undefined,
    type: storeData.filter.type ? { equals: storeData.filter.type.id, } : undefined,
  },
  sort:
    storeData.data.sortField && storeData.data.sortOrder
      ? {
          field: storeData.data.sortField,
          order: storeData.data.sortOrder,
        }
      : undefined,
});
export const mapVariablesVoucherPurchase = (
  storeData: VoucherPurchaseStore['tableVoucherPurchases'],
): {
  filter?: VoucherPurchaseFilterInput;
  pagination?: PaginationInput;
  sort?: VoucherPurchaseSortInput;
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    voucherId: storeData.filter.voucherId ? { contains: storeData.filter.voucherId, } : undefined,
    status: storeData.filter.status ? { equals: storeData.filter.status.id, } : undefined,
    user: storeData.filter.user ? { username: { contains: storeData.filter.user, }, } : undefined,
    voucher: storeData.filter.voucherName ? { name: { contains: storeData.filter.voucherName, }, } : undefined,
  },
  sort:
    storeData.data.sortField && storeData.data.sortOrder
      ? {
          field: storeData.data.sortField,
          order: storeData.data.sortOrder,
        }
      : undefined,
});
