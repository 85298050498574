import create from 'zustand';
import { PointOfSellSortableFields, OrderBy, PointOfSellFragment, PointOfSellOptionFragment, ChainFragment, ClusterFragment, } from 'Apollo/graphql';
import { ChangeTableFilter, ChangeTableSort, ChangeTableData, } from 'Utils/types';

export type TablePointOfSellFilter = {
  pos: PointOfSellOptionFragment | null,
  posName: string,
  chain: ChainFragment | null,
  cluster: ClusterFragment | null,
  street: string,
  number: string,
  city: string,
  postalCode: string,
};

export type PointOfSellStore = {
  modalDetailPointOfSell: {
    isOpen: boolean,
    pointOfSell: PointOfSellFragment | null,
  },
  modalExportPointOfSell: {
    isOpen: boolean,
  },
  modalImportPos: {
    isOpen: boolean,
  },
  tablePointOfSell: {
    data: {
      sortField: PointOfSellSortableFields | null,
      sortOrder: OrderBy | null,
      limit: number,
      offset: number,
    },
    filter: TablePointOfSellFilter,
  },
  openModalDetailPointOfSell: (pointOfSell: PointOfSellFragment) => void,
  closeModalDetailPointOfSell: () => void,
  openModalExportPointOfSell: () => void,
  closeModalExportPointOfSell: () => void,
  openModalImportPos: () => void,
  closeModalImportPos: () => void,
  changeTableData: ChangeTableData,
  changeTableFilter: ChangeTableFilter,
  changeTableSort: ChangeTableSort,
};

export const usePointOfSellStore = create<PointOfSellStore>((set) => ({
  modalDetailPointOfSell: {
    isOpen: false,
    pointOfSell: null,
  },
  modalImportPos: {
    isOpen: false,
  },
  tablePointOfSell: {
    data: {
      sortField: null,
      sortOrder:  null,
      limit: 10,
      offset: 0,
    },
    filter: {  
      pos: null,
      posName: '',
      chain: null,
      cluster: null,
      street: '',
      number: '',
      city: '',
      postalCode: '',
    },
  },
  modalExportPointOfSell: {
    isOpen: false,
    filter: null,
    sort:null,
  },
  /*
  * Modal Detail
  */
  openModalDetailPointOfSell: (pointOfSell) => set({
    modalDetailPointOfSell: {
      isOpen: true,
      pointOfSell,
    },
  }),
  closeModalDetailPointOfSell: () => set(() => ({
    modalDetailPointOfSell: {
      isOpen: false,
      pointOfSell: null,
    },
  })),
  /*
  * Modal Export
  */
  openModalExportPointOfSell: () => set({
    modalExportPointOfSell: {
      isOpen: true,
    },
  }),
  closeModalExportPointOfSell: () => set(() => ({
    modalExportPointOfSell: {
      isOpen: false,
    },
  })),
  /*
   * Modal Import
   */
  openModalImportPos: () => set({
    modalImportPos: {
      isOpen: true,
    },
  }),
  closeModalImportPos: () => set({
    modalImportPos: {
      isOpen: false,
    },
  }),
  /**
   * Table
   */
   changeTableData: (name, value) => set((state) => ({
    tablePointOfSell: {
      ...state.tablePointOfSell,
      data: {
        ...state.tablePointOfSell.data,
        [name]: value,
      },
    },
   })),
   changeTableFilter: (name, value) => set((state) => ({
    tablePointOfSell: {
      ...state.tablePointOfSell,
      data: {
        ...state.tablePointOfSell.data,
        offset: 0,
      },
      filter: {
        ...state.tablePointOfSell.filter,
        [name]: value,
      },
    },
   })), 
   changeTableSort: (sortField, sortOrder) => set((state) => ({
    tablePointOfSell: {
      ...state.tablePointOfSell,
      data: {
        ...state.tablePointOfSell.data,
        sortField: sortField as PointOfSellSortableFields,
        sortOrder,
      },
    },
   })),
}));
