import { useTranslation, } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FullTaskFragment, } from 'Apollo/graphql';
import { useMemoArrayId, } from 'Tools/useMemoArrayId';
import Block from './Block';

interface Props {
  task: FullTaskFragment,
  showAssessmentCorrectAnswers: boolean,
};

const TaskAssessment = ({
  task,
  showAssessmentCorrectAnswers,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const blocks = useMemoArrayId(task.assessment?.blocks || []);

  if (blocks.length < 1) return (
    <Typography
      color="textSecondary"
    >
      {t('detail.noAssessmentBlocks')}
    </Typography>
  );
  return (
    <Grid container spacing={2}>
      {blocks.map((block, index) => (
        <Block
          key={block.id}
          index={index}
          block={block.payload}
          showAssessmentCorrectAnswers={showAssessmentCorrectAnswers}
        />
      ))}
    </Grid>
  );
};

export default TaskAssessment;
