import Grid from '@mui/material/Grid';
import { MarketEnum, PreviouslyOwnedDevicesOptionFragment, PurchasedProductsForGqlDbOptionFragment, useGetVerificationMethodsQuery, } from 'Apollo/graphql';
import {
  FormInputAutocomplete,
  FormInputCheckbox,
  FormInputDate,
  FormInputGroupRadio,
  FormInputPhoneNumber,
  FormInputText,
} from 'Components/Form';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { useTranslation, } from 'react-i18next';
import { GenderTitleOption, useOptionsGenderTitle, } from 'Utils/options/useOptionsGenderTitle';
import { useOptionsPreviouslyOwnedDevice, } from 'Utils/options/useOptionsPreviouslyOwnedDevice';
import { useOptionsPurchasedProduct, } from 'Utils/options/useOptionsPurchasedProduct';
import { useOptionsUserVerification, UserVerificationOption, } from 'Utils/options/useOptionsUserVerification';
import { useAuthUser, } from 'Tools/auth/hooks/useAuthUser';
import { renderOptionById, } from 'Utils/helpers';
import { isHungarianMarket, } from 'Utils/market';
import PulzeOn from './PulzeOn';
import TermsAndConditions from './TermsAndConditions';
import { FormConfig, } from './types';

interface Props {
  formConfig: FormConfig,
  registrationEmail: string,
}
  
const CustomerRegistrationForm = ({
  formConfig,
  registrationEmail,
}: Props): JSX.Element => {
  const { t, } = useTranslation('registrationSeller');
  const authUser = useAuthUser();
  const genderTitleOptions = useOptionsGenderTitle();
  const purchasedProductOptions = useOptionsPurchasedProduct();
  const previouslyOwnedDeviceOptions = useOptionsPreviouslyOwnedDevice();
  const showPulzeOn = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PULZE_ON;
  const userVerificationOptions = useOptionsUserVerification();
  
  return (<>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('phaseRegisterCustomer.form.labelEmail')}>{registrationEmail}</NotEditableTextClassic>
      </Grid>
      {formConfig.enableTitle && (
        <Grid item xs={12}>
          <FormInputAutocomplete<GenderTitleOption>
            name="title"
            getOptionLabel={genderTitleOptions.getOptionLabel}
            isOptionEqualToValue={genderTitleOptions.isOptionEqualToValue}
            options={genderTitleOptions.options}
            label={t('phaseRegisterCustomer.form.labelTitle')}
            required
            renderOption={renderOptionById(genderTitleOptions.getOptionLabel)}
            placeholder={t('phaseRegisterCustomer.form.placeholderTitle')}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormInputText
          name="firstName"
          required
          label={t('phaseRegisterCustomer.form.labelFirstName')}
          id="new-first-name"
          autoComplete="new-first-name"
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="lastName"
          required
          label={t('phaseRegisterCustomer.form.labelLastName')}
          id="new-last-name"
          autoComplete="new-last-name"
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputDate name="dateOfBirth" required label={t('phaseRegisterCustomer.form.labelDateOfBirth')} />
      </Grid>
      <Grid item xs={12}>
        <FormInputPhoneNumber namePhone="phone" required namePrefix="phonePrefix" label={t('phaseRegisterCustomer.form.labelPhone')} id="nope" />
      </Grid>
      <Grid item xs={12}>
        <FormInputGroupRadio<PurchasedProductsForGqlDbOptionFragment>
          name="purchasedProducts"
          label={t('phaseRegisterCustomer.form.labelPurchasedProducts')}
          options={purchasedProductOptions.options}
          getOptionKey={purchasedProductOptions.getOptionKey}
          getOptionLabel={purchasedProductOptions.getOptionLabel}
          loading={purchasedProductOptions.loading}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputGroupRadio<PreviouslyOwnedDevicesOptionFragment>
          name="previouslyOwnedDevices"
          label={t('phaseRegisterCustomer.form.labelPreviouslyOwnedDevices')}
          options={previouslyOwnedDeviceOptions.options}
          getOptionKey={previouslyOwnedDeviceOptions.getOptionKey}
          getOptionLabel={previouslyOwnedDeviceOptions.getOptionLabel}
          loading={previouslyOwnedDeviceOptions.loading}
          required
        />
      </Grid>
      </Grid>
      <Grid container spacing={1.5} mt={1.5}>
      {formConfig.currentMarket === MarketEnum.Cz && (
        <>
          <Grid item xs={12}>
            <FormInputCheckbox name="acceptedTermsAndConditions" required label={<TermsAndConditions />} />
          </Grid>
          {showPulzeOn && (
            <Grid item xs={12}>
              <FormInputCheckbox name="acceptedPulzeOn" label={<PulzeOn />} />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormInputCheckbox name="acceptedPulzeCare" label={t('phaseRegisterCustomer.form.labelAcceptedPulzeCare')} />
          </Grid>
        </>
      )}
      {formConfig.currentMarket !== MarketEnum.Cz && (
        <>
          {showPulzeOn && (
            <Grid item xs={12}>
              <FormInputCheckbox name="acceptedPulzeOn" required label={<PulzeOn />} />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormInputCheckbox name="acceptedTermsAndConditions" required label={<TermsAndConditions />} />
          </Grid>
          <Grid item xs={12}>
            <FormInputCheckbox name="acceptedPulzeCare" required={!isHungarianMarket} label={t('phaseRegisterCustomer.form.labelAcceptedPulzeCare')} />
          </Grid>
        </>
      )}
      {formConfig.verificationMethods && formConfig.verificationMethods.length > 1 && (
        <Grid item xs={12}>
          <FormInputGroupRadio<UserVerificationOption>
            name="verificationMethod"
            label={t('registrationSeller:phaseRegisterCustomer.form.labelVerificationMethod')}
            options={formConfig.verificationMethods.map((option)=> userVerificationOptions.options.find((userVerificationOption)=> userVerificationOption.id === option)) as UserVerificationOption[]}
            getOptionKey={userVerificationOptions.getOptionKey}
            getOptionLabel={userVerificationOptions.getOptionLabel}
            required
          />
        </Grid>
      )}
    </Grid></>
  );
};

export default CustomerRegistrationForm;
