import { ReactNode, } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { RegistrationPhase, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import StepIcon from 'Components/Stepper/StepIcon';
import StepConnector from 'Components/Stepper/StepConnector';

interface Props {
  children: ReactNode,
  phase: RegistrationPhase,
};

const STEP_NUMBERS: { [key in RegistrationPhase]: number } = {
  'init': 0,
  'purchase': 0,
  'promotionPurchase': 0,
  'deviceCodes': 0,
  'deviceCodesPromotion': 0,
  'chooseVerificationMethod': 1,
  'customerRegistration': 1,
  'customerVerification': 2,
  'informationVerification': 3,
  'registrationComplete': 4,
};

const STEPS = [ 0, 1, 2, 3, ];

const LayoutStepper = ({
  children,
  phase,
}: Props): JSX.Element => {
  const activeStep = STEP_NUMBERS[phase] || 0;

  return (
    <>
      <Box width="100%" mb={3}>
        <Stepper
          activeStep={activeStep}
          connector={<StepConnector />}
        >
          {STEPS.map((stepKey) => (
            <Step key={stepKey}>
              <StepLabel StepIconComponent={StepIcon} />
            </Step>
          ))}
        </Stepper>
      </Box>
      {children}
    </>
  );
};

export default LayoutStepper;
