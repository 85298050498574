import { yupResolver, } from '@hookform/resolvers/yup';
import { FormProvider, useForm, } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { CreateReplacementStore, useCreateReplacementStore, } from '../createReplacementStore';
import { formValidationSchema, } from './utils';
import View from './View';

const selectStoreData = (s: CreateReplacementStore) => ({
  completeReplacementRegistrationPhase: s.completeReplacementRegistrationPhase,
  replacementRegistration: s.replacementRegistration,
});

interface Props {
  onClose: () => void,
}

const PhaseReplacementRegistration = ({ onClose, }: Props): JSX.Element => {
  const {
    completeReplacementRegistrationPhase,
    replacementRegistration,
  } = useCreateReplacementStore(selectStoreData, shallow);

  const methods = useForm({
    defaultValues: replacementRegistration,
    resolver: yupResolver(formValidationSchema()),
    mode: 'onSubmit',
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    completeReplacementRegistrationPhase(values);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default PhaseReplacementRegistration;
