import { useTranslation, } from 'react-i18next';
import { styled, } from '@mui/material/styles';
import { useNavigate, } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { NewsArticleFragment, } from 'Apollo/graphql';
import CardLayout from 'Modules/NewsArticlesSeller/components/CardLayout';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ROUTES, } from 'Utils/constants';

const StyledButton = styled(Button)(({ theme, }) => ({
  paddingLeft: 0,
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: theme.typography.fontWeightRegular,
  letterSpacing: '0.25px',
  '& .MuiButton-endIcon .MuiSvgIcon-root': {
    fontSize: 20,
  },
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
}));

interface Props {
  newsArticle: NewsArticleFragment
}

const CardItem = ({
  newsArticle,
}: Props): JSX.Element => {
  const { t, } = useTranslation('newsArticlesSeller');
  const navigate = useNavigate();

  const navigateToDetail = () => navigate(`/${ROUTES.news}/${newsArticle.id}`);

  return (
    <CardLayout
      title={newsArticle.title}
      date={newsArticle.createdAt}
      imageSrc={newsArticle.image?.publicUrl}
    >
      <Typography variant="body1" marginBottom={1}>
        {newsArticle.description}
      </Typography>

      <StyledButton
        onClick={navigateToDetail}
        variant="text"
        color="inherit"
        disabled={false}
        endIcon={<KeyboardArrowRightIcon />}
      >
        {t('card.btnMore')}
      </StyledButton>

    </CardLayout>
  );
};

export default CardItem;

