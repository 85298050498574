import { useMemo, } from 'react';
import { v4, } from 'uuid';

type ItemWithPayload<T,> = {
  id: string,
  payload: T,
};

export const useMemoArrayId = <T,>(array: T[]): ItemWithPayload<T>[] => {
  return useMemo<ItemWithPayload<T>[]>(
    () => array.map((item) => ({
      id: v4(),
      payload: item,
    })),
    [ array, ],
  );
};
