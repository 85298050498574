import { useTranslation, } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { ChainFragment, NewsArticleFragment, useChainsQuery, } from 'Apollo/graphql';
import { FormInputText, FormInputAutocomplete, FormInputFileUpload, FormInputRichText, FormInputCheckbox, } from 'Components/Form';
import { areOptionsEqualById, getOptionFieldId, renderOptionById, } from 'Utils/helpers';
import { IMAGE_MAX_SIZE, VIDEO_MAX_SIZE, } from 'Utils/constants';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import Typography from '@mui/material/Typography';

interface Props {
  newsArticle?: NewsArticleFragment,
};

const NewsArticleForm = ({
  newsArticle,
}: Props): JSX.Element => {
  const { t, } = useTranslation('newsArticlesAdmin');
  const chainsResult = useChainsQuery({ fetchPolicy: 'cache-and-network', });

  return (
    <Grid container spacing={3}>
      {newsArticle && (
        <Grid item xs={12}>
          <NotEditableTextClassic
            label={t('newsArticle.labelNewsArticleId')}
          >
            {newsArticle.id}
          </NotEditableTextClassic>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormInputText
          name="title"
          label={t('newsArticle.labelTitle')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="description"
          label={t('newsArticle.labelDescription')}
          multiline
          required
          minRows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputFileUpload
          name="image"
          label={t('newsArticle.labelPicture')}
          required
          fileType="image"
          maxSize={IMAGE_MAX_SIZE}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputFileUpload
          name="video"
          label={t('newsArticle.labelVideo')}
          fileType="video"
          maxSize={VIDEO_MAX_SIZE}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputRichText
          name="text"
          label={t('newsArticle.labelText')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputAutocomplete<ChainFragment, true>
          multiple
          name="chains"
          getOptionLabel={getOptionFieldId}
          isOptionEqualToValue={areOptionsEqualById}
          renderOption={renderOptionById(getOptionFieldId)}
          options={chainsResult.data?.chains || []}
          loading={chainsResult.loading}
          label={t('newsArticle.labelChainId')}
          placeholder={t('common:select.placeholder')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputCheckbox name="notificationsVisible" label={<Typography>{t('common:notification.createPopUpNotification')}</Typography>} />
      </Grid>
    </Grid>
  );
};

export default NewsArticleForm;
