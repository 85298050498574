import { useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { OrderBy, PointOfSellSortableFields, usePointsOfSellListQuery, } from 'Apollo/graphql';
import { areOptionsEqualById, filterAll, getOptionFieldId, } from 'Utils/helpers';
import { useThrottleValue,} from 'Tools/useThrottleValue';

export const useSearchOptionsPos = (chainId?: string) => {
  const [ searchState, setSearchState, ] = useState<string>('');
  const throttleValue = useThrottleValue(searchState);
  const { t, } = useTranslation();

  const handleSearchChange = (e: unknown, newSearchValue: string) => setSearchState(newSearchValue);

  const { loading, error, data, previousData, } = usePointsOfSellListQuery({
    fetchPolicy: 'no-cache',
    // skip: !throttleValue,
    variables: {
      filter: {
        id: {
          contains: throttleValue,
        },
        chain: chainId ? {
          id: { equals: chainId, },
        } : undefined,
      },
      sort: {
        field: PointOfSellSortableFields.Id,
        order: OrderBy.Asc,
      },
      pagination: {
        offset: 0,
        limit: 20,
      },
    },
  });
  
  const options = data?.pointsOfSellList.data || [];
  const previousOptions = previousData?.pointsOfSellList.data || [];

  return {
    loading,
    error,
    options: (loading && options.length < 1) ? previousOptions : options,
    searchState,
    getOptionLabel: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
    onInputChange: handleSearchChange,
    filterOptions: filterAll,
    noOptionsText: (!loading && throttleValue === '') ? t('common:select.typeToSearch') : undefined,
  };
};
