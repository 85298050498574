import { useFormContext, useController, } from 'react-hook-form';
import InputFileUploadClassic, { InputFileUploadClassicProps, } from 'Components/Inputs/InputFileUploadClassic';
import { FileFragment, } from 'Apollo/graphql';
import { useFormInputStatus, } from '../utils/useFormInputStatus';
import { InputFormErrorMessage, } from '../types';
import { useFormBlockerContext, } from '../FormBlocker/useFormBlockerContext';

interface Props extends Omit<InputFileUploadClassicProps, 'value' | 'onChange' | 'error' > {
  name: string,
}

const FormInputFileUpload = ({
  name,
  helperText,
  ...rest
}: Props): JSX.Element => {
  const { control, } = useFormContext();
  const {
    field: { value, onBlur: onBlurField, onChange, },
    fieldState: { error, },
  } = useController({
    name,
    control,
  });

  const handleChange = (file: FileFragment | null) => {
    onChange(file);
    onBlurField();
  };

  const inputStatus = useFormInputStatus(
    error?.message as InputFormErrorMessage | undefined,
    helperText,
  );
  
  const blocker = useFormBlockerContext(); 

  return (
    <InputFileUploadClassic
      {...rest}
      value={value}
      onChange={handleChange}
      error={inputStatus.isError}
      helperText={inputStatus.text}
      onStartUpload={blocker?.addBlocker}
      onEndUpload={blocker?.removeBlocker}
    />
  );
};

export default FormInputFileUpload;
