import { FileFragment, } from "Apollo/graphql";
import { INPUT_FORM_ERRORS, } from "Components/Form";
import * as yup from "yup";

export type AlzaVoucherFormValues = {
    file: FileFragment | null,
    image: FileFragment | null,
    name: string,
    description: string,
  };

export const initAlzaVoucherFormValues:AlzaVoucherFormValues = {
    name: '',
    description: '',
    file: null,
    image: null,
};
export const alzaVoucherFormValidationSchema = yup.object().shape({
    name: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(125, INPUT_FORM_ERRORS.STRING_MAX),
    description: yup.string().required(INPUT_FORM_ERRORS.REQUIRED).max(2000, INPUT_FORM_ERRORS.STRING_MAX),
    file: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
    image: yup.object().nullable().required(INPUT_FORM_ERRORS.REQUIRED),
});
