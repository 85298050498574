import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AuthUserFragment, Claim, } from 'Apollo/graphql';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import { CustomerRegistrationValues, } from '../createReplacementStore';

interface Props {
  registerCustomerResult: CustomerRegistrationValues,
  onClose: () => void,
  onVerify: () => void,
}

const View = ({
  registerCustomerResult,
  onClose,
  onVerify,
}: Props): JSX.Element => {
  const { t, } = useTranslation('replacementAdmin');
  const authUser = useAuthUser() as AuthUserFragment;
  const showSensitiveData = authUser?.claims.includes(Claim.ReadSensitiveData);

  const { createdAt, firstName, lastName, dateOfBirth, email, phone, } = registerCustomerResult;

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>
        {t('createReplacementModal.phaseCustomerRegistrationComplete.success.title')}
      </ModalDrawerTitle>
      <ModalDrawerBody>
        <Typography
          variant="body1"
          mb={3}
        >
          {t('createReplacementModal.phaseCustomerRegistrationComplete.success.info')}
        </Typography>
        <Typography
          variant="body1"
          mb={3}
        >
          {t('common:date.fullDateTime', { date: createdAt, })}
        </Typography>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('createReplacementModal.phaseCustomerRegistrationComplete.success.posId')}
            >
              {authUser.pointOfSell?.id || '-'}
            </NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('createReplacementModal.phaseCustomerRegistrationComplete.success.labelAuthor')}
            >
              {`${authUser.firstName} ${authUser.lastName}`}
            </NotEditableTextClassic>
          </Grid>
          {showSensitiveData &&
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t('createReplacementModal.phaseCustomerRegistrationComplete.success.labelCustomerInformation')}
            >
              <div>
                {`${firstName} ${lastName}`}
              </div>
              <div>
                {dateOfBirth ? t('common:date.fullDate', { date: new Date(dateOfBirth), }) : '-'}
              </div>
              <div>
                {email}
              </div>
              <div>
                {phone}
              </div>
            </NotEditableTextClassic>
          </Grid>}
        </Grid>
        <Button
          color="primary"
          variant="contained"
          onClick={onVerify}
        >
          {t('createReplacementModal.phaseCustomerRegistrationComplete.success.btnCreateTicker')}
        </Button>
      </ModalDrawerBody>
    </>
  );
};

export default View;
