import { useTranslation, } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { RadioAssessmentBlockFragment, } from 'Apollo/graphql';
import { useMemoArrayId, } from 'Tools/useMemoArrayId';
import { BlockProps, } from '../../types';
import BlockLayout from '../../BlockLayout';
import AnswerRow from './AnswerRow';

const BlockRadio = ({
  index,
  block,
  showAssessmentCorrectAnswers,
}: BlockProps<RadioAssessmentBlockFragment>): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const answersWithId = useMemoArrayId(block.answers);

  return (
    <BlockLayout
      index={index}
      type={block.type}
    >

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={showAssessmentCorrectAnswers ? 9 : 12}>
          <NotEditableTextClassic
            label={t('assessment.labelQuestion')}
          >
            {block.question}
          </NotEditableTextClassic>
        </Grid>
        {showAssessmentCorrectAnswers && (
          <Grid item xs={12} sm={showAssessmentCorrectAnswers ? 3 : 12}>
            <NotEditableTextClassic
              label={t('assessment.labelPoints')}
            >
              {block.numberOfPoints}
            </NotEditableTextClassic>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={1}>
        <Grid container item spacing={2}>
          <Grid item xs={showAssessmentCorrectAnswers ? 9 : 12}>
            <Typography
              color="primary"
              variant="label"
            >
              {t('assessment.labelAnswer')}
            </Typography>
          </Grid>
          {showAssessmentCorrectAnswers && (
            <Grid item xs={3}>
              <Typography
                color="primary"
                variant="label"
              >
                {t('assessment.labelCorrectAnswer')}
              </Typography>
            </Grid>
          )}
        </Grid>

        {answersWithId.map((item, answerIndex) => (
          <AnswerRow
            key={item.id}
            index={answerIndex}
            answer={item.payload}
            correctAnswer={block.correctAnswerIndex}
            showAssessmentCorrectAnswers={showAssessmentCorrectAnswers}
          />
        ))}
      </Grid>

    </BlockLayout>
  );
};

export default BlockRadio;
