import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { PromotionAvailabilityFragment, PurchaseProductsAvailabilityFragment, } from 'Apollo/graphql';
import { FormError, FormSubmit, } from 'Components/Form';
import { PromotionCategoryPurchaseObject, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import PurchaseForm from './PurchaseForm';
import ProductError from './ProductError';
import { FormConfig, } from './types';

interface Props {
  goBack: () => void;
  structure: PurchaseProductsAvailabilityFragment;
  formConfig: FormConfig;
  handleSubmitForPromotion: () => void;
  setActivePromotionPurchase: (index: number, id: string) => void;
  promotionCategoryPurchaseList: PromotionCategoryPurchaseObject;
  isShortRegistration?: boolean;
  handleChangeInPromotionPurchaseForm: (option: PromotionAvailabilityFragment,toggle: "add" | "remove" ) => void,
}

const View = ({ handleChangeInPromotionPurchaseForm, goBack, structure, formConfig, handleSubmitForPromotion, setActivePromotionPurchase, promotionCategoryPurchaseList,isShortRegistration, }: Props) => {
  const { t, } = useTranslation('registrationSeller');

  return (
    <>
      <PurchaseForm
        structure={structure}
        formConfig={formConfig}
        handleSubmitForPromotion={handleSubmitForPromotion}
        setActivePromotionPurchase={setActivePromotionPurchase}
        promotionCategoryPurchaseList={promotionCategoryPurchaseList}
        handleChangeInPromotionPurchaseForm={handleChangeInPromotionPurchaseForm}
      />

      <ProductError />

      <FormError mb={2} />

      <Grid container spacing={2}>
        <Grid item xs={12} lg="auto">
          <FormSubmit color="primary" variant="contained" size="large" sx={{ width: { xs: '100%', lg: 'unset', }, }}>
            {t('phasePurchase.submit')}
          </FormSubmit>
        </Grid>
        {!isShortRegistration && <Grid item xs={12} lg="auto">
          <Button size="large" variant="outlined" color="primary" sx={{ width: { xs: '100%', lg: 'unset', }, }} onClick={goBack}>
            {t('common:actions.back')}
          </Button>
        </Grid>}
      </Grid>
    </>
  );
};

export default View;
