/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, } from 'react';
import { ApolloError, } from '@apollo/client';
import { ChangeTableFilter, ChangeTableSort, ChangeTableData, } from 'Utils/types';
import { OrderBy, } from 'Apollo/graphql';

export type TableContextValue = {
  loading: boolean,
  colSpan: number,
  rows: number,
  limit: number,
  offset: number,
  sortField: string | null,
  sortOrder: OrderBy | null,
  filter?: { [key: string]: any},
  error?: ApolloError | Error | null,
  count?: number,
  onChangeFilter: ChangeTableFilter,
  onChangeSort: ChangeTableSort,
  onChangeData: ChangeTableData,
};

export const TableContext = createContext<TableContextValue | null>(null);
