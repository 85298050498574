import { useCallback, } from 'react';
import shallow from 'zustand/shallow';
import { useForm, FormProvider, } from 'react-hook-form';
import { yupResolver, } from '@hookform/resolvers/yup';
import { CreateTaskStore, useCreateTaskStore, } from 'Modules/TasksAdmin/store/createTaskStore';
import { contentValidationSchema, useInitValues, } from './utils';
import { ContentValues, } from './types';
import Layout from './Layout';

const selectStoreData = (s: CreateTaskStore) => ({
  typeValues: s.typeValues,
  methodValues: s.methodValues,
  contentValues: s.contentValues,
  goBackToPhase: s.goBackToPhase,
  completeContentPhase: s.completeContentPhase,
  saveContentValues: s.saveContentValues,
});

const PhaseContent = (): JSX.Element => {
  const {
    typeValues,
    methodValues,
    contentValues,
    goBackToPhase,
    completeContentPhase,
    saveContentValues,
  } = useCreateTaskStore(selectStoreData, shallow);

  const initValues = useInitValues(contentValues);
  const methods = useForm<ContentValues>({
    defaultValues: initValues,
    resolver: yupResolver(contentValidationSchema),
  });

  const handleBack = useCallback(
    () => {
      saveContentValues(methods.getValues());
      goBackToPhase('parameters');
    },
    [ goBackToPhase, saveContentValues, ],
  );

  const handleSubmit = methods.handleSubmit(async (values) => {
    completeContentPhase(values);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Layout
          typeValues={typeValues}
          methodValues={methodValues}
          onBack={handleBack}
        />
      </form>
    </FormProvider>
  );
};

export default PhaseContent;
