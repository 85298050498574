import ModalRemoveReplacement from 'Modules/ReplacementAdmin/modals/ModalRemoveReplacement';
import ModalDetailReplacement from 'Modules/ReplacementAdmin/modals/ModalDetailReplacement';
import ModalExportReplacements from 'Modules/ReplacementAdmin/modals/ModalExportReplacement';
import ModalCreateReplacement from 'Modules/ReplacementAdmin/modals/ModalCreateReplacement';
import ModalEditReplacement from 'Modules/ReplacementAdmin/modals/ModalEditReplacement';
import ReplacementTable from './ReplacementTable';

const ReplacementAdminPage = () => {
  return (
    <>
      <ReplacementTable />

      <ModalCreateReplacement />
      <ModalRemoveReplacement />
      <ModalDetailReplacement />
      <ModalExportReplacements />
      <ModalEditReplacement />
    </>
  );
};

export default ReplacementAdminPage;
