import { ReactNode, } from 'react';
import { styled, } from '@mui/material/styles';
import ButtonBase, { ButtonBaseProps, } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const StyledButton = styled(ButtonBase)(({ theme, }) => ({
  width: '100%',
}));

const StyledCard = styled(Card)(({ theme, }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
  padding: theme.spacing(1, 2),
  marginBottom: 1,
  minHeight: `calc(${theme.spacing(2)} + 45px)`,
  transition: theme.transitions.create([
    'background-color',
  ]),

  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SquareItem = styled(Typography)(({ theme, }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto',
  width: 42,
  height: 42,
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.primary.main,
  borderWidth: 1,
  borderStyle: 'solid',
  color: theme.palette.primary.main,

  '& .MuiSvgIcon-root': {
    fontSize: 22,
  },
}));

interface Props extends ButtonBaseProps {
  icon?: ReactNode,
};

const ButtonWithSquareIcon = ({
  children,
  icon,
  ...rest
}: Props): JSX.Element => {
  return (
    <StyledButton {...rest}>
      <StyledCard>
        <Typography
          variant="h3"
          component="div"
          color="primary"
        >
          {children}
        </Typography>
        {icon && (
          <SquareItem>
            {icon}
          </SquareItem>
        )}
      </StyledCard>
    </StyledButton>
  );
};

export default ButtonWithSquareIcon;
