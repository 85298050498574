import { useEffect, } from 'react';
import { useParams, Navigate, Link as RouterLink, } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AppContainer from 'Components/AppContainer';
import { useAuthUser, getAuthRedirectRoute, useAuthLoginQrCode, } from 'Tools/auth';
import { ROUTES, } from 'Utils/constants';
import StateView from './StateView';

type RouteParams = {
  code: string,
};

const LoginQrCodePage = (): JSX.Element => {
  const { code, } = useParams<RouteParams>();
  const { t, } = useTranslation('auth');
  const authUser = useAuthUser();
  const [ loginByQrCode, { loading, error, }, ] = useAuthLoginQrCode();

  useEffect(
    () => {
      loginByQrCode({
        variables: { qrCode: code || '', },
      });
    },
    [ loginByQrCode, code, ],
  );

  if (authUser) {
    return (
      <Navigate
        to={getAuthRedirectRoute(authUser)}
      />
    );
  }

  return (
    <AppContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h1"
        marginBottom={3}
      >
        {t('loginQrCode.title')}
      </Typography>
      <StateView
        loading={loading}
        error={error}
      />
      <Box mt={3}>
        <Button
          component={RouterLink}
          to={`/${ROUTES.login}`}
          color="inherit"
          variant="text"
          size="large"
        >
          {t('loginQrCode.btnGotToLogin')}
        </Button>
      </Box>
    </AppContainer>
  );
};

export default LoginQrCodePage;
