import { useForm, FormProvider, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { yupResolver, } from '@hookform/resolvers/yup';
import { useSnackbar, } from 'notistack';
import { PromotionsListDocument, useCreatePromotionMutation, } from 'Apollo/graphql';
import { updatePromotionCreate, } from 'Modules/PromotionsAdmin/utils/promotionsCache';
import { useAuthUser, } from 'Tools/auth';
import moment from 'moment';
import { initPromotionFormValues, promotionFormValidationSchema, mapFormToVariables, } from '../../utils/promotionForm';
import View from './View';

interface Props {
  onClose: () => void;
}

const Logic = ({ onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation('promotionsAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const authUser = useAuthUser();
  const methods = useForm({
    defaultValues: initPromotionFormValues,
    resolver: yupResolver(promotionFormValidationSchema),
    mode: 'onTouched',
  });

  const [createMutation, createMutationResult,] = useCreatePromotionMutation({
    refetchQueries: [
      {
        query: PromotionsListDocument,
      },
    ],
    onCompleted: (response) => {
      enqueueSnackbar({
        title: t('createPromotionModal.notifCreatedTitle') as string,
        message: t('createPromotionModal.notifCreatedMessage', { promotionId: response.createPromotion.id, }),
        variant: 'common',
      });
      onClose();
    },
    onError: () => {
      // TODO: merge form errors
    },
    update: updatePromotionCreate,
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    const assortmentCategory = methods.watch('assortmentCategory');
    const validFrom = new Date(moment(values.validFrom).utc(true).set({ hour: 0, minute: 0, second: 0, millisecond: 0, }).format());
    const validTo = new Date(moment(values.validTo).utc(true).set({ hour: 0, minute: 0, second: 0, millisecond: 0, }).format());
    await createMutation({
      variables: {
        promotionInput: mapFormToVariables({ ...values, validFrom, validTo, assortmentCategory, }, authUser),
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View createMutationResult={createMutationResult} onClose={onClose} />
      </form>
    </FormProvider>
  );
};

export default Logic;
