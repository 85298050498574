import shallow from 'zustand/shallow';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { ReplacementStore, useReplacementStore, } from 'Modules/ReplacementAdmin/store/ReplacementStore';
import Logic from './Logic';

const selectStoreData = (s: ReplacementStore) => ({
  onCloseRemove: s.closeModalRemoveReplacement,
  onCloseDetail: s.closeModalDetailReplacement,
  onCloseEdit: s.closeModalEditReplacement,
  isOpen: s.modalRemoveReplacement.isOpen,
  replacement: s.modalRemoveReplacement.replacement,
});

const ModalRemoveReplacement = (): JSX.Element => {

  const {
    onCloseRemove,
    onCloseDetail,
    onCloseEdit,
    isOpen,
    replacement,
  } = useReplacementStore(selectStoreData, shallow);

  return (
    <ModalDrawer
      onClose={onCloseRemove}
      open={isOpen && !!replacement}
    >
      <Logic
        replacement={replacement}
        onCloseRemove={onCloseRemove}
        onCloseDetail={onCloseDetail}
        onCloseEdit={onCloseEdit}
      />
    </ModalDrawer>
  );
};

export default ModalRemoveReplacement;
