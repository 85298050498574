import { useFormContext, } from 'react-hook-form';
import { InputFormErrorMessage, useFormInputStatus, } from 'Components/Form';
import ErrorAlert from 'Components/Errors/ErrorAlert';

const ProductError = (): JSX.Element | null => {
  const {
    formState: { errors, },
  } = useFormContext();
  const inputStatus = useFormInputStatus(errors?.values?.message as InputFormErrorMessage | undefined);

  if (!inputStatus.isError) return null;
  return (
    <ErrorAlert
      mb={2}
      customError={{
        text: inputStatus.text,
        title: null,
      }}
    />
  );
};

export default ProductError;
