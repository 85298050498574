import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { FullTaskFragment, } from 'Apollo/graphql';
import TaskContentBlocks from 'Components/TaskContent';

interface Props {
  task: FullTaskFragment,
};

const TaskContent = ({
  task,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const blocks = task.content?.blocks || [];

  if (blocks.length < 1) return (
    <Typography
      color="textSecondary"
    >
      {t('detail.noContentBlocks')}
    </Typography>
  );
  return (
    <TaskContentBlocks
      contentBlocks={blocks}
    />
  );
};

export default TaskContent;
