import { yupResolver, } from '@hookform/resolvers/yup';
import { VoucherTableDocument, useCreateAlzaVouchersMutation, } from 'Apollo/graphql';
import { useSnackbar, } from 'notistack';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import AlzaVoucherForm from './AlzaVoucherForm';
import { alzaVoucherFormValidationSchema, initAlzaVoucherFormValues, } from './helpers';

interface Props {
  onClose: () => void,
};

const CreateAlzaVoucher = ({
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const methods = useForm({
    defaultValues: initAlzaVoucherFormValues,
    resolver: yupResolver(alzaVoucherFormValidationSchema),
    mode: 'onTouched',
  });
  const [ createMutation, createMutationResult, ] = useCreateAlzaVouchersMutation({
    onCompleted: (response) => {
      if (response.importAlzaVouchers.success) {
      enqueueSnackbar({
        title: t('createVoucherModal.notifCreatedTitle') as string,
        message: t('createVoucherModal.notifCreatedMessageAlza'),
        variant: 'common',
      });
      onClose();
    }
    },
    onError: () => {
      // TODO: merge form errors
    },
    refetchQueries: [{ query: VoucherTableDocument, },],
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await createMutation({
      variables: {
        alzaVoucherImportInput: {
          fileId: values.file?.id || '',
          imageFileId: values?.image?.id || '',
          description: values.description,
          name: values.name,
        },
      },
    });    
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <AlzaVoucherForm
          importMutationResult={createMutationResult}
          onClose={onClose}
        />
      </form>
    </FormProvider>
  );
};

export default CreateAlzaVoucher;
