import { ReactNode, HTMLAttributes, } from 'react';
import Typography from '@mui/material/Typography';
import InputLabelClassic from './components/InputLabelClassic';

export interface NotEditableTextClassicProps {
  children: ReactNode;
  label: ReactNode;
  InputLabelProps?: Omit<HTMLAttributes<HTMLLabelElement>, 'color'>;
  required?: boolean;
}

const NotEditableTextClassic = ({ children, label, InputLabelProps, required, }: NotEditableTextClassicProps): JSX.Element => {
  return (
    <div>
      <InputLabelClassic shrink color="primary" required={required} {...InputLabelProps}>
        {label}
      </InputLabelClassic>
      <Typography variant="body1" component="div">
        {children}
      </Typography>
    </div>
  );
};

export default NotEditableTextClassic;
