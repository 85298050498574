import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormError, FormSubmit, } from 'Components/Form';
import AppContainer from 'Components/AppContainer';
import { MethodValues, TypeValues, } from 'Modules/TasksAdmin/store/createTaskStore';
import FormInputs from './FormInputs';

interface Props {
  typeValues: TypeValues,
  methodValues: MethodValues,
  onBack: () => void,
};

const Layout = ({
  typeValues,
  methodValues,
  onBack,
}: Props): JSX.Element => {
  const { t, } = useTranslation('tasksAdmin');
  const type = typeValues.type ? t(typeValues.type.labelKey, typeValues.type.id) : '';
  const method = methodValues.method ? t(methodValues.method.labelKey, methodValues.method.id) : '';

  return (
    <AppContainer maxWidth="md">
      <Typography
        variant="h1"
        mb={1.5}
      >
        {t('creation.contentPhase.title', { type, method, })}
      </Typography>
      <Typography
        variant="body1"
        mb={4}
      >
        {t('creation.contentPhase.info', { type, method, })}
      </Typography>
      
      <Box>
        <FormInputs />
      </Box>

      <Grid container spacing={2} sx={{ marginTop: 3,}}>
        <Grid item xs={12} lg="auto">
          <FormSubmit
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
          >
            {t('creation.contentPhase.btnSubmit')}
          </FormSubmit>
        </Grid>
        <Grid item xs={12} lg="auto">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            sx={{ width: { xs: '100%', lg: 'unset', }, }}
            onClick={onBack}
          >
            {t('common:actions.back')}
          </Button>
        </Grid>
      </Grid>

      <FormError mt={2} />
    
    </AppContainer>
  );
};

export default Layout;
