import { SellerScaleAssessmentBlockFragment, } from 'Apollo/graphql';
import { FormInputScale, } from 'Components/Form';
import NodeLabel from '../NodeLabel';
import { NodeProps, } from '../types';
import { buildNodeInputName, } from '../utils';

const NodeScale = ({
  index,
  block,
  assessmentConfig,
}: NodeProps<SellerScaleAssessmentBlockFragment>): JSX.Element => {
  return (
    <FormInputScale
      name={buildNodeInputName(index, 'value')}
      label={(
        <NodeLabel
          index={index}
          question={block.question}
          numberOfPoints={block.numberOfPoints}
          assessmentConfig={assessmentConfig}
        />
      )}
    />
  );
};

export default NodeScale;
