const STORAGE_KEY = 'OFFLINE_REFRESH_TOKEN';

export const setOfflineRefreshToken = (value: string | null) => {
  try {
    if (value) localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
    else localStorage.removeItem(STORAGE_KEY);
  } catch (error) {
    // TODO - if development
    console.error('cannot set refresh offline token');
  }
};

export const getOfflineRefreshToken = (): string | null => {
  try {
    const value = localStorage.getItem(STORAGE_KEY);
    return value;
  } catch (error) {
    // TODO - if development
    console.error('cannot remove refresh offline token');
    return null;
  }
};
