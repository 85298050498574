import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const H1Icon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    {...props}
    fill="none"
  >
    <path
      d="M10.796 16.25V4.586H9.224v4.939H3.447V4.587H1.875V16.25h1.572v-5.34h5.776v5.34h1.573Zm6.662 0V4.586h-1.555l-2.778 2.059v1.58l2.7-1.955h.078v9.98h1.554Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default H1Icon;
