import { CustomerRegistrationFragment, } from 'Apollo/graphql';
import ModalDrawer from 'Components/ModalDrawer/ModalDrawer';
import { RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationsAdmin/store/RegistrationStore';
import Logic from './Logic';

const selectModalData = (s: RegistrationStore) => s.modalRemoveRegistration;
const selectClose = (s: RegistrationStore) => s.closeModalRemoveRegistration;
const selectCloseDetail = (s: RegistrationStore) => s.closeModalDetailRegistration;

const ModalRemoveRegistration = (): JSX.Element => {
  const { isOpen, registration, } = useRegistrationStore(selectModalData);
  const onClose = useRegistrationStore(selectClose);
  const onCloseLogic = useRegistrationStore(selectCloseDetail);
  return (
    <ModalDrawer
      onClose={onClose}
      open={isOpen && !!registration}
    >
      <Logic
        registration={registration as CustomerRegistrationFragment}
        onClose={onClose}
        onCloseLogic={onCloseLogic}
      />
    </ModalDrawer>
  );
};

export default ModalRemoveRegistration;
