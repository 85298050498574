import { useMemo, } from 'react';

import ChristmasPrizeIcon from 'Utils/svg/ChristmasPrizeIcon';
import { Prize, PrizeType, } from 'Modules/PrizeWheel/usePrize';
import PointsContent from './PointsContent';
import WishContent from './WishContent';

type PrizeModalProps = {
  visible: boolean;
  prize: Prize;
};

const PrizeModal = ({ visible, prize, }: PrizeModalProps) => {
  const { prizePoints, prizeType, wishBody, wishHeading, } = prize;
  const Content = useMemo(() => {
    if (prizeType === PrizeType.Points) {
      return <PointsContent points={prizePoints} headingIcon={<ChristmasPrizeIcon sx={{ fontSize: '4rem', }} />} />;
    }
    if (prizeType === PrizeType.Wish) {
      return <WishContent body={wishBody} heading={wishHeading} />;
    }
    return null;
  }, [prizePoints, prizeType, wishBody, wishHeading,]);

  if (!visible) {
    return null;
  }

  return Content;
};

export default PrizeModal;
