import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CreatePromotionMutationResult, } from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import { FormError, FormSubmit, } from 'Components/Form';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import ModalDrawerTitle from 'Components/ModalDrawer/ModalDrawerTitle';
import PromotionForm from 'Modules/PromotionsAdmin/components/PromotionForm';

interface Props {
  createMutationResult: CreatePromotionMutationResult,
  onClose: () => void,
};

const View = ({
  createMutationResult,
  onClose,
}: Props): JSX.Element => {
  const { t, } = useTranslation('promotionsAdmin');

  return (
    <>
      <ModalDrawerTitle onClose={onClose}>
        {t('createPromotionModal.title')}
      </ModalDrawerTitle>

      <ModalDrawerBody>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 5,
          }}
        >
          {t('createPromotionModal.info')}
        </Typography>

        <PromotionForm />

        <Grid container spacing={2} mt={4} alignItems="center">
          <Grid item>
            <FormSubmit
              size="large"
            >
              {t('createPromotionModal.btnSubmit')}
            </FormSubmit>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              color="inherit"
              onClick={onClose}
            >
              {t('common:actions.cancel')}
            </Button>
          </Grid>
        </Grid>

        <FormError mt={2} />
        <ErrorAlert
          mt={2}
          error={createMutationResult.error}
        />
      </ModalDrawerBody>
    </>
  );
};

export default View;
