import { useTranslation, } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import { Claim, CustomerRegistrationFragment, } from 'Apollo/graphql';
import TableCellActions from 'Components/Table/TableCellActions';
import TableCellText from 'Components/Table/TableCellText';
import { useAuthUser, } from 'Tools/auth';
import { MouseEventHandler, } from 'react';
import { valueOrEmpty, } from 'Utils/parsers';
import Chip from '@mui/material/Chip';
import TableCellBlock from 'Components/Table/TableCellBlock';

interface Props {
  row: CustomerRegistrationFragment;
  onDetail: (customer: CustomerRegistrationFragment) => void;
  onDelete: (customer: CustomerRegistrationFragment) => void;
  onRemove: (customer: CustomerRegistrationFragment) => void;
}
const RegistrationsTableRow = ({ row, onDetail, onDelete, onRemove, }: Props): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const authUser = useAuthUser();

  const handleDelete: MouseEventHandler<HTMLLIElement> = () => {
    onDelete(row);
  };

  const handleRemove: MouseEventHandler<HTMLLIElement> = () => {
    onRemove(row);
  };

  const handleDetail: MouseEventHandler<HTMLLIElement> = () => {
    onDetail(row);
  };
  const showChainIDs = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;
  const showSensitiveData = authUser?.claimsSet.has(Claim.ReadSensitiveData);
  const showPulzeOn = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PULZE_ON;

  return (
    <TableRow key={row.id}>
      {showChainIDs && <TableCellText>{valueOrEmpty(row.chainId)}</TableCellText>}
      <TableCellText>{valueOrEmpty(row.posId)}</TableCellText>
      <TableCellText>{valueOrEmpty(row?.author?.username)}</TableCellText>
      <TableCellText>{t(`common:options.userRoles.${row?.author?.roleCode}`, row?.author?.roleCode)}</TableCellText>
      <TableCellText>{t('common:date.fullDate', { date: new Date(row.createdAt), })}</TableCellText>
      {showSensitiveData && <TableCellText>{valueOrEmpty(row.firstName)}</TableCellText>}
      {showSensitiveData && <TableCellText>{valueOrEmpty(row.lastName)}</TableCellText>}
      {showSensitiveData && <TableCellText>{valueOrEmpty(row.email)}</TableCellText>}
      {showSensitiveData && <TableCellText>{valueOrEmpty(row.phoneNumberWithPrefix)}</TableCellText>}
      <TableCellBlock>
        {row.acceptedTermsAndConditions ? (
          <Chip color="success" label={t('common:conditions.accepted')} />
        ) : (
          <Chip color="error" label={t('common:conditions.rejected')} />
        )}
      </TableCellBlock>
      {showPulzeOn && (
        <TableCellBlock>
          {row.acceptedPulzeOn ? (
            <Chip color="success" label={t('common:conditions.accepted')} />
          ) : (
            <Chip color="error" label={t('common:conditions.rejected')} />
          )}
        </TableCellBlock>
      )}
      <TableCellBlock>
        {row.acceptedPulzeCare ? (
          <Chip color="success" label={t('common:conditions.accepted')} />
        ) : (
          <Chip color="error" label={t('common:conditions.rejected')} />
        )}
      </TableCellBlock>
      <TableCellActions>
        <MenuItem onClick={handleDetail}>{t('registrations.table.btnDetail')}</MenuItem>
        {authUser?.claimsSet.has(Claim.DeletePurchase) && (
          <MenuItem onClick={handleDelete}>{t('registrations.table.btnDelete')}</MenuItem>
        )}
        {authUser?.claimsSet.has(Claim.DeleteCustomer) && (
          <MenuItem onClick={handleRemove}>{t('registrations.table.btnRemove')}</MenuItem>
        )}
      </TableCellActions>
    </TableRow>
  );
};

export default RegistrationsTableRow;
