import { TypeOfCompletionEnum, } from 'Apollo/graphql';
import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type TaskTypeCompletionOption = {
  id: TypeOfCompletionEnum,
  labelKey: string,
};

export const TASK_COMPLETION_TYPE_OPTIONS: TaskTypeCompletionOption[] = [
  {
    id: TypeOfCompletionEnum.Elearning,
    labelKey: 'tasksAdmin:options.taskTypeOfCompletion.ELEARNING',
  },
  {
    id: TypeOfCompletionEnum.Pos,
    labelKey: 'tasksAdmin:options.taskTypeOfCompletion.POS',
  },
  {
    id: TypeOfCompletionEnum.User,
    labelKey: 'tasksAdmin:options.taskTypeOfCompletion.USER',
  },
];

type GetOptionLabel = (o: TaskTypeCompletionOption) => string;
type GetOptionKey = (o: TaskTypeCompletionOption) => string;

type UseOptionsTaskTypeCompletion = () => {
  options: TaskTypeCompletionOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<TaskTypeCompletionOption>, 
};

export const getOptionTaskCompletionTypeById = (id: TypeOfCompletionEnum): TaskTypeCompletionOption | null => (
  TASK_COMPLETION_TYPE_OPTIONS.find((value)=> value.id === id) || null
);

export const useOptionsTaskTypeCompletion: UseOptionsTaskTypeCompletion = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: TASK_COMPLETION_TYPE_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
