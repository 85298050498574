import { styled, } from '@mui/material/styles';
import Container from '@mui/material/Container';

const AppContainer = styled(Container)(({ theme, }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(7.5),
  minHeight: 580,
}));

export default AppContainer;
