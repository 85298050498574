import Grid from '@mui/material/Grid';
import { FormInputCheckbox, FormInputPhoneNumber, FormInputText, } from 'Components/Form';
import { useTranslation, } from 'react-i18next';


const EditMyTaxInformationInvoice = (): JSX.Element => {
  const { t, } = useTranslation('myProfile');
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormInputText
          name="firstName"
          label={t('formInvoice.firstName')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="lastName"
          label={t('formInvoice.lastName')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="taxNumber"
          label={t('formInvoice.pesel')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="city"
          label={t('formInvoice.city')}
          required
        />
      </Grid><Grid item xs={12}>
        <FormInputText
          name="postCode"
          label={t('formInvoice.postCode')}
          required
        />
      </Grid><Grid item xs={12}>
        <FormInputText
          name="street"
          label={t('formInvoice.street')}
          required
        />
      </Grid><Grid item xs={12}>
        <FormInputText
          name="email"
          label={t('formInvoice.email')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputPhoneNumber
          namePrefix="phonePrefix"
          namePhone="phoneNumber"
          label={t('formInvoice.phone')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="accountNumber"
          label={t('formInvoice.accountNumber')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="taxOffice"
          label={t('formInvoice.taxOffice')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputCheckbox name="RODOConsent" required label={t('formInvoice.RODOConsent')} />
      </Grid>
    </Grid>
  );
};

export default EditMyTaxInformationInvoice;
