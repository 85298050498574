import shallow from 'zustand/shallow';
import { useAssortmentsQuery, } from 'Apollo/graphql';
import { AssortmentStore, useAssortmentStore, } from 'Modules/AssortmentAdmin/store/AssortmentStore';
import Table from 'Components/Table/Table';
import TableBody from 'Components/Table/TableBody';
import AssortmentsTableRow from './AssortmentsTableRow';
import AssortmentsTableHeader from './AssortmentsTableHeader';
import { mapVariables, } from './utils';

const selectStoreData = (s: AssortmentStore) => ({
  onEdit: s.openModalEditAssortment,
  onRemove: s.openModalRemoveAssortment,
  tableAssortments: s.tableAssortments,
  changeFilter: s.changeTableFilter,
  changeSort: s.changeTableSort,
  changeData: s.changeTableData,
});

const Assortments = (): JSX.Element => {
  const {
    onEdit,
    onRemove,
    tableAssortments,
    changeFilter,
    changeSort,
    changeData,
  } = useAssortmentStore(selectStoreData, shallow);

  const { loading, error, data, } = useAssortmentsQuery({
    variables: mapVariables(tableAssortments),
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Table
      sx={{
        mt: 4,
      }}
      sxTable={{
        minWidth: '1000px',
        maxWidth: '100%',
        tableLayout: 'fixed',
      }}
      colSpan={6}
      rows={data?.assortments.data.length || 0}
      loading={loading}
      error={error}
      limit={tableAssortments.data.limit}
      sortField={tableAssortments.data.sortField}
      sortOrder={tableAssortments.data.sortOrder}
      offset={tableAssortments.data.offset}
      count={data?.assortments.pagination.count}
      filter={tableAssortments.filter}
      onChangeFilter={changeFilter}
      onChangeSort={changeSort}
      onChangeData={changeData}
    >
      <AssortmentsTableHeader />
      <TableBody>
        {(data?.assortments.data || []).map((row) => (
          <AssortmentsTableRow
            key={row.id}
            row={row}
            onEdit={onEdit}
            onRemove={onRemove}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default Assortments;
