import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { AssessmentBlockTypeEnum, } from 'Apollo/graphql';

interface Props {
  index: number,
  type: AssessmentBlockTypeEnum,
  children: ReactNode,
}

const BlockLayout = ({
  index,
  type,
  children,
}: Props) => {
  const { t, } = useTranslation('tasksAdmin');

  return (
    <Box pt={2} pb={2}>
      <Typography
        mb={2}
        color="primary"
        variant="h3"
      >
        {t('assessment.blockTitle', { position: index + 1, })}
      </Typography>
      <Box mb={2}>
        <NotEditableTextClassic
          label={t('assessment.labelType')}
        >
          {t(`options.assessmentBlock.${type}`, type)}
        </NotEditableTextClassic>
      </Box>
      {children}
    </Box>
  );
};

export default BlockLayout;
