import { useMemo, } from 'react';
import * as yup from 'yup';
import { INPUT_FORM_ERRORS, } from 'Components/Form';
import { ParametersFormConfig, ParametersValues, } from 'Modules/TasksAdmin/store/createTaskStore';
import { getOptionTypeOfCompletionById, } from 'Utils/options/useOptionTaskTypeOfCompletion';
import { RenewalEnum, TypeOfCompletionEnum, } from 'Apollo/graphql';
import { getOptionTaskRenewalById, } from 'Utils/options/useOptionTaskRenewal';

export const useInitValues = (initValues: ParametersValues, parametersFormConfig: ParametersFormConfig): ParametersValues => useMemo(
  () => ({
    ...initValues,
    ...(!parametersFormConfig.inputTypeOfCompletion && {
      typeOfCompletion: getOptionTypeOfCompletionById(TypeOfCompletionEnum.Elearning),
    }),
    ...(!parametersFormConfig.inputAttemptLimit && {
      attemptLimit: null,
    }),
    ...(!parametersFormConfig.inputPointsLimit && {
      pointsLimit: null,
    }),
    ...(!parametersFormConfig.inputPointsForCompletion && {
      pointsForCompletion: null,
    }),
    ...(!parametersFormConfig.inputRenewal && {
      renewal: getOptionTaskRenewalById(RenewalEnum.None),
    }),
  }),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [],
);

export const parameterValidationSchema = (parametersFormConfig: ParametersFormConfig) => {
  return yup.object().shape({
    name: yup.string()
      .required(INPUT_FORM_ERRORS.REQUIRED)
      .max(125, INPUT_FORM_ERRORS.STRING_MAX),
    description: yup.string()
      .required(INPUT_FORM_ERRORS.REQUIRED)
      .max(2047, INPUT_FORM_ERRORS.STRING_MAX),
    ...(parametersFormConfig.inputTypeOfCompletion && {
      typeOfCompletion: yup.object()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
    ...(parametersFormConfig.inputRenewal && {
      renewal: yup.object()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED),
    }),
    ...(parametersFormConfig.inputAttemptLimit && {
      attemptLimit: yup.number()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED)
        .min(0, INPUT_FORM_ERRORS.INT_MIN),
    }),
    ...(parametersFormConfig.inputPointsLimit && {
      pointsLimit: yup.number()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED)
        .min(0, INPUT_FORM_ERRORS.INT_MIN),
    }),
    ...(parametersFormConfig.inputPointsForCompletion && {
      pointsForCompletion: yup.number()
        .nullable()
        .required(INPUT_FORM_ERRORS.REQUIRED)
        .min(0, INPUT_FORM_ERRORS.INT_MIN),
    }),
    validFrom: yup.date()
      .nullable()
      .required(INPUT_FORM_ERRORS.REQUIRED)
      .typeError(INPUT_FORM_ERRORS.DATE_FORMAT),
    validTo: yup.date()
      .required(INPUT_FORM_ERRORS.REQUIRED)
      .typeError(INPUT_FORM_ERRORS.DATE_FORMAT)
      .min(new Date(), INPUT_FORM_ERRORS.DATE_MIN_TODAY)
      .when("validFrom",
        (validFrom, schema) => validFrom ? schema.min(validFrom, INPUT_FORM_ERRORS.DATE_START_BEFORE) : schema 
      )
      .nullable(),
    chains: yup.array(),
  });
};
