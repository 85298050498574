import { Query, } from '@cubejs-client/core';
import { QueryParams, } from '../types';

export const totalNumberOfChainsQuery = ({
  filters = [],
}: QueryParams): Query => ({
  measures: ['chain.count',],
  timeDimensions: [
    {
      dimension: 'chain.created_at',
    },
  ],
  filters,
});

export const topChainsInReplacementsQuery = ({
  timeDimensions,
  filters = [],
  showNullValues = false,
}: QueryParams): Query => {
  const query = {
    timeDimensions: [
      {
        dateRange: timeDimensions?.[0]?.dateRange,
        dimension: timeDimensions?.[0]?.dimension || 'replacement.createdat',
      },
    ],
    order: {
      'replacement.count': 'desc' as const,
    },
    measures: ['replacement.count',],
    dimensions: ['replacement.chain__name',],
    filters: [...filters,],
  };
  if (!showNullValues) {
    query.filters.push({
      member: 'replacement.chain__name',
      operator: 'set',
    });
  }
  return query;
};

export const topChainsInRegistrationsQuery = ({
  timeDimensions,
  filters = [],
  showNullValues = false,
}: QueryParams): Query => {
  const query = {
    measures: ['customer.total_registrations',],
    timeDimensions: [
      {
        dimension: 'customer.createdat',
        dateRange: timeDimensions?.[0].dateRange,
      },
    ],
    order: {
      'customer.total_registrations': 'desc'as const,
    },
    filters: [
      ...filters,
      {
        member: 'customer.posId',
        operator: 'notEquals' as const, 
        values: ['GLOBAL-API',],
      },
    ],
    dimensions: ['chain.name',],
  };
  if (!showNullValues) {
    query.filters?.push({
      member: 'chain.name',
      operator: 'set',
    });
  }
  return query;
};


export const activeInactiveChainsQuery = (): Query => ({
  measures: ['chain.count',],
  timeDimensions: [
    {
      dimension: 'chain.created_at',
    },
  ],
  order: {
    'chain.count': 'desc',
  },
  dimensions: ['chain.is_active',],
});