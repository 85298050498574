import { styled, } from '@mui/material/styles';
import MuiStepConnector, { stepConnectorClasses, } from '@mui/material/StepConnector';

const StepConnector = styled(MuiStepConnector)(({ theme, }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#E0E0E7',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

export default StepConnector;
