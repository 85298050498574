import { z, } from 'zod';

const basePosIdsSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
  }),
);

const baseChainIdsSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
  }),
);

const baseUserRoleIdSchema = z
  .object({
    id: z.string(),
    name: z.string(),
  })
  .nullable();

export const SourceAndUserRoleValidationSchema = z.object({
  posIds: basePosIdsSchema,
  chainIds: baseChainIdsSchema,
  userRoleId: baseUserRoleIdSchema,
});

export const SourceValidationSchema = z.object({
  posIds: basePosIdsSchema,
  chainIds: baseChainIdsSchema,
});

export const SourceAndTimeAndUserRoleValidationSchema = z.object({
  posIds: basePosIdsSchema,
  chainIds: baseChainIdsSchema,
  date: z.union([z.string(), z.array(z.string()),]),
  userRoleId: baseUserRoleIdSchema,
});

export const TimeAndProductCategoryAndProductValidationSchema = z.object({
  date: z.union([z.string(), z.array(z.string()),]),
  categoryId: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
  productId: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
});

export const ChainAndTimeValidationSchema = z.object({
  chainIds: baseChainIdsSchema,
  date: z.union([z.string(), z.array(z.string()),]),
});


export const TimeValidationSchema = z.object({
  date: z.union([z.string(), z.array(z.string()),]),
});