import ModalDrawerBodyLoading from 'Components/ModalDrawer/ModalDrawerBodyLoading';
import ModalDrawerBodyError from 'Components/ModalDrawer/ModalDrawerBodyError';
import { usePreserveValue, } from 'Tools/usePreserveValue';
import { TableVoucherFragment, useVoucherQuery, VoucherFragment, } from 'Apollo/graphql';
import Logic from './Logic';

interface Props {
  voucher?: TableVoucherFragment | null;
  onClose: () => void;
  onDelete: (voucher: TableVoucherFragment | VoucherFragment) => void;
}

const FetchData = ({ voucher, onClose, onDelete, }: Props): JSX.Element => {
  const preservedVoucher = usePreserveValue(voucher);

  const { loading, error, data, } = useVoucherQuery({
    variables: { id: preservedVoucher?.id, },
    fetchPolicy: 'network-only',
  });

  if (loading) return <ModalDrawerBodyLoading onClose={onClose} />;
  if (error || !data?.voucher) return <ModalDrawerBodyError onClose={onClose} error={error} />;

  return <Logic voucher={data.voucher} onClose={onClose} onDelete={onDelete} />;
};

export default FetchData;
