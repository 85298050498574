import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const EyeIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...props}
    fill="none"
  >
    <path
      d="M11.943 18.171c-2.122-.01-4.024-.751-5.799-1.864a17.473 17.473 0 0 1-3.57-2.962c-.72-.778-.786-1.798-.068-2.572 2.306-2.491 5.019-4.31 8.436-4.855 1.774-.283 3.475.069 5.106.797 2.104.938 3.88 2.33 5.44 4.008a1.815 1.815 0 0 1 .023 2.505c-1.925 2.079-4.145 3.728-6.89 4.564a8.35 8.35 0 0 1-2.678.38Zm3.896-6.138a3.85 3.85 0 0 0-.63-2.14 3.816 3.816 0 0 0-5.89-.617 3.844 3.844 0 0 0-.848 4.186 3.838 3.838 0 0 0 1.406 1.73c.629.424 1.37.652 2.127.653a3.844 3.844 0 0 0 2.695-1.118 3.87 3.87 0 0 0 1.14-2.692v-.002Z"
      fill="currentColor"
    />
    <path
      d="M12.011 13.662a1.647 1.647 0 0 1-1.521-1.021 1.663 1.663 0 0 1 .352-1.803 1.65 1.65 0 0 1 2.824 1.148 1.658 1.658 0 0 1-1.017 1.55c-.202.085-.419.127-.638.126Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default EyeIcon;
