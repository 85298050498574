import { yupResolver, } from '@hookform/resolvers/yup';
import { useCreateSodexoVoucherMutation, VoucherTableDocument, } from 'Apollo/graphql';
import { useSnackbar, } from 'notistack';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import SodexoVoucherForm from './SodexoVoucherForm';
import { sodexoVoucherFormValidationSchema, initSodexoVoucherFormValues, } from './helpers';

interface Props {
  onClose: () => void;
}

const CreateSodexoVoucher = ({ onClose, }: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const { enqueueSnackbar, } = useSnackbar();
  const methods = useForm({
    defaultValues: initSodexoVoucherFormValues,
    resolver: yupResolver(sodexoVoucherFormValidationSchema),
    mode: 'onTouched',
  });
  const [createMutation, createMutationResult,] = useCreateSodexoVoucherMutation({
    onCompleted: (response) => {
      if (response) {
        enqueueSnackbar({
          title: t('createVoucherModal.notifCreatedTitle') as string,
          message: t('createVoucherModal.notifCreatedMessage',  { id: response.createSodexoVoucher.id, }),
          variant: 'common',
        });
        onClose();
      }
    },
    onError: () => {
      // TODO: merge form errors
    },
    refetchQueries: [{ query: VoucherTableDocument, },],
  });

  const handleSubmit = methods.handleSubmit(async (values) => {
    await createMutation({
      variables: {
        input: {
          imageFileId: values?.image?.id || '',
          description: values.description,
          name: values.name,
          notificationsVisible: values.notificationsVisible,
        },
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <SodexoVoucherForm createMutationResult={createMutationResult} onClose={onClose} />
      </form>
    </FormProvider>
  );
};

export default CreateSodexoVoucher;
