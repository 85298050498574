import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export enum Availability {
  isAvailable='isAvailable',
  notAvailable='notAvailable',
}

export type IsNowAvailableOption = {
  id: string,
  labelKey: string,
  value: boolean,
};

export const IS_NOW_AVAILABLE_OPTIONS: IsNowAvailableOption[] = [
  {
    id: Availability.isAvailable,
    labelKey: 'tasksAdmin:tasks.available',
    value:true,
  },
];

type GetOptionLabel = (o: IsNowAvailableOption) => string;
type GetOptionKey = (o: IsNowAvailableOption) => string;

type UseOptionsIsNowAvailable = () => {
  options: IsNowAvailableOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<IsNowAvailableOption>, 
};

export const useOptionsIsNowAvailable: UseOptionsIsNowAvailable = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey),
    [ t, ],
  );

  return {
    options: IS_NOW_AVAILABLE_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
