import { GetAvailableVoucherPurchaseOptionsQuery, } from 'Apollo/graphql';
import AlzaVoucherBox from 'Modules/MyPointsSeller/components/AlzaVoucherBox';
import SodexoVoucherBox from 'Modules/MyPointsSeller/components/SodexoVoucherBox';
import VoucherBox from 'Modules/MyPointsSeller/components/VoucherBox';
// import { useTranslation, } from 'react-i18next';

type Props = {
  data: GetAvailableVoucherPurchaseOptionsQuery;
};

const View = ({ data, }: Props): JSX.Element => {
  // const { t, } = useTranslation('myPoints');
  // const navigate = useNavigate();
  // const handleToTransactionList = () => navigate(`/${ROUTES.myPoints}/${ROUTES.transactions}`);

  return (
    <>
      {data.getAvailableVoucherPurchaseOptions.alzaOptions.exampleVoucher ? <AlzaVoucherBox data={data} /> : <div />}
      {data.getAvailableVoucherPurchaseOptions.standartOptions.map((voucher) => (
        <VoucherBox key={voucher.id} data={voucher} />
      ))}
      {data.getAvailableVoucherPurchaseOptions.sodexoOptions.map((voucher) => (
        <SodexoVoucherBox key={voucher.id} data={voucher} />
      ))}
    </>
  );
};
export default View;
