import { useTranslation, } from 'react-i18next';
import { useFormState, } from 'react-hook-form';
import Box, { BoxProps, } from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import ErrorFilledIcon from 'Utils/svg/ErrorFilledIcon';
import { useFormBlockerContext, } from '../FormBlocker/useFormBlockerContext';

export const FormError = ({
  ...boxProps
}: Omit<BoxProps, 'children'>): JSX.Element | null => {
  const { t, } = useTranslation('common');
  const { isValid, isSubmitted, } = useFormState();
  const blocker = useFormBlockerContext();

  const isValidForm = !isSubmitted || isValid;
  const isBlockedForm = blocker !== null && blocker.isBlocked;
  
  if (isValidForm && !isBlockedForm) return null;

  return (
    <Box {...boxProps}>
      {isBlockedForm && (
        <Alert
          severity='info'
          icon={<ErrorFilledIcon />}
          sx={{mb:isValidForm ? undefined : 2,}}
        >
          {t('errors.waitingOnProcessInfo')}
        </Alert>
      )}
      {!isValidForm && (
        <Alert
          severity='error'
          icon={<ErrorFilledIcon />}
        >
          {t('errors.invalidForm')}
        </Alert>
      )}
    </Box>
  );
};


export default FormError;
