import { useMemo, useRef, } from 'react';

export const usePreserveValue = <T,>(value?: T | null): T => {
  const ref = useRef<T>();
  const ret = useMemo(
    () => {
      if (value) {
        ref.current = value;
        return value;
      };
      return ref.current as T;
    },
    [ value, ]
  );

  return ret;
};
