import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { ChainFragment, useChainsQuery, VoucherFragment, VoucherType, } from 'Apollo/graphql';
import { FormInputAutocomplete, FormInputCheckbox, FormInputFileUpload, FormInputText, } from 'Components/Form';
import FormInputDate from 'Components/Form/inputs/FormInputDate';
import FormInputInt from 'Components/Form/inputs/FormInputInt';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { useTranslation, } from 'react-i18next';
import { useAuthUser, } from 'Tools/auth';
import { IMAGE_MAX_SIZE, } from 'Utils/constants';
import { areOptionsEqualById, getOptionFieldId, renderOptionById, } from 'Utils/helpers';

interface Props {
  voucher?: VoucherFragment;
}

const VoucherForm = ({ voucher, }: Props): JSX.Element => {
  const { t, } = useTranslation('vouchersAdmin');
  const chainsResult = useChainsQuery({ fetchPolicy: 'cache-and-network', });
  const authUser = useAuthUser();
  return (
    <Grid container spacing={3}>
      {voucher && (
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('vouchers.form.id')}>{voucher.id}</NotEditableTextClassic>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormInputText name="name" label={t('vouchers.form.name')} required disabled={voucher?.type === VoucherType.Alza} />
      </Grid>
      <Grid item xs={12}>
        <FormInputFileUpload
          name="image"
          label={t('vouchers.form.preview')}
          required
          fileType="image"
          maxSize={IMAGE_MAX_SIZE}
          disabled={voucher?.type === VoucherType.Alza}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="description"
          label={t('vouchers.form.description')}
          required
          multiline
          minRows={4}
          maxRows={6}
          disabled={voucher?.type === VoucherType.Alza}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputInt
          name="priceFiat"
          label={t('vouchers.form.priceFiat')}
          required
          disabled={voucher?.type === VoucherType.Alza || voucher?.type === VoucherType.Sodexo}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputInt
          name="number"
          label={t('vouchers.form.number')}
          required
          disabled={voucher?.type === VoucherType.Alza || voucher?.type === VoucherType.Sodexo}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputDate
          name="validFrom"
          label={t('vouchers.form.validFrom')}
          required
          disabled={voucher?.type === VoucherType.Alza || voucher?.type === VoucherType.Sodexo}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputDate
          name="validTo"
          label={t('vouchers.form.validTo')}
          required
          disabled={voucher?.type === VoucherType.Alza || voucher?.type === VoucherType.Sodexo}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputInt
          name="pricePoints"
          label={t('vouchers.form.pricePoints')}
          required
          disabled={voucher?.type === VoucherType.Alza || voucher?.type === VoucherType.Sodexo}
        />
      </Grid>
      {authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS && (
        <Grid item xs={12}>
          <FormInputAutocomplete<ChainFragment, true>
            multiple
            name="chains"
            getOptionLabel={getOptionFieldId}
            isOptionEqualToValue={areOptionsEqualById}
            renderOption={renderOptionById(getOptionFieldId)}
            options={chainsResult.data?.chains || []}
            loading={chainsResult.loading}
            label={t('vouchers.form.chains')}
            placeholder={t('common:select.placeholder')}
            disabled={voucher?.type === VoucherType.Alza || voucher?.type === VoucherType.Sodexo}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormInputCheckbox name="notificationsVisible" label={<Typography>{t('common:notification.createPopUpNotification')}</Typography>} />
      </Grid>
    </Grid>
  );
};

export default VoucherForm;
