import { styled } from '@mui/material/styles';

const StyledText = styled('span')(({ theme }) => ({
  color: '#C37D5A',
  fontSize: 40,
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    fontSize: 64,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 96,
  },
  lineHeight: 1,
  fontWeight: 700,
}));

const BigNumbers = ({ number }: { number: Number }) => {
  return <StyledText>{number.toLocaleString()}</StyledText>;
};

export default BigNumbers;
