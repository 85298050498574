import { DashboardLayoutItemName, } from 'Apollo/graphql';
import Base from 'Components/Dashboard/Base';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { ChartBaseProps, ChartType, } from 'Utils/Charts/types';
import { useTranslation, } from 'react-i18next';
import { useMemo, } from 'react';
import { pointsCategoryQuery, } from './queries';

const PointsCategories = ({
  showAddButton,
  filtration,
  chartFilter,
  showSlug = true,
  showTotal,
}: ChartBaseProps) => {
  const { t, } = useTranslation('dashboards');

  const query = useMemo(
    () =>
      pointsCategoryQuery({
        filters: chartFilter,
      }),
    [chartFilter,],
  );
  return (
    <Base
      headerText={t('dashboards.pointsAndTasks.pointsCategory')}
      slug={showSlug ? 'points-categories' : undefined}
      showAddButton={showAddButton}
      filtration={filtration}
      dashboardLayoutItemName={
        DashboardLayoutItemName.PointsAndTasksPointsCategory
      }
    >
      <ChartRenderer showTotal={showTotal} query={query} type={ChartType.pie} />
    </Base>
  );
};

export default PointsCategories;
