import { Query, } from '@cubejs-client/core';
import { zodResolver, } from '@hookform/resolvers/zod';
import { Grid, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import ChartDetailLayout from 'Components/Dashboard/ChartDetailLayout';
import { totalNumberOfAvailableGiftCodesQuery, } from 'Components/Dashboard/ImportedPlGiftCodes/queries';
import TotalNumberOfAvailableGiftCodes from 'Components/Dashboard/ImportedPlGiftCodes/TotalNumberOfAvailableGiftCodes';
import { useMemo, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { OptionTypes, ChartType, } from 'Utils/Charts/types';
import { ChainAndTimeValidationSchema, } from 'Utils/Charts/ValidationSchemas';
import { z, } from 'zod';

type ValidationSchema = z.infer<typeof ChainAndTimeValidationSchema>;

const TotalNumberOfAvailableGiftCodesPage = () => {
  const { t, } = useTranslation('dashboards');

  const filtration = [] as OptionTypes[];
  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(ChainAndTimeValidationSchema),
    defaultValues: {
      date: 'This year',
      chainIds: [],
    },
  });

  const query = useMemo(
    () =>
      totalNumberOfAvailableGiftCodesQuery(),
    [],
  );
  return (
    <AppContainer>
      <ChartDetailLayout pageTitle={t('common:dashboardSidebar.chains')}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <TotalNumberOfAvailableGiftCodes
                showSlug={false}
                filtration={filtration}
              />
            </FormProvider>
          </Grid>
          <Grid item xs={12}>
            <ChartRenderer
              query={query}
              type={ChartType.table}
              pivotConfig={{
                x: ['chain.created_at',],
                y: ['measures',],
                fillMissingDates: true,
                joinDateRange: false,
              }}
            />
          </Grid>
        </Grid>
      </ChartDetailLayout>
    </AppContainer>
  );
};

export default TotalNumberOfAvailableGiftCodesPage;
