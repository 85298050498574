import { styled, } from "@mui/material/styles";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { ReactNode, useState, } from 'react';
import KeyboardArrowUpIcon from 'Utils/svg/KeyboardArrowUpIcon';
import KeyboardArrowDownIcon from 'Utils/svg/KeyboardArrowDownIcon';
import ImageAsync from 'Components/ImageAsync';
import IconButtonSquare from 'Components/IconButtonSquare';

const CardContainer = styled(Card)(({ theme, }) => ({
  marginBottom: theme.typography.pxToRem(1),
  borderRadius: 0,
}));

const ImageContainer = styled('div')(() => ({
  height: 45,
  minHeight: 45,
  width: 70,
  minWidth: 70,
  marginRight: 4,
}));

interface Props {
  title?: ReactNode,
  children?: ReactNode,
  imageSrc?: string,
  disabled?: boolean
}

const CardItem = ({
  title,
  children,
  imageSrc,
  disabled,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen,] = useState(false);
  const handleToggle = () => setIsOpen((s) => !s);

  return (
    <CardContainer>
      <Box
        p={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap={1} sx={{ mr: 1, }}>
          <ImageContainer>
            <ImageAsync src={imageSrc} />
          </ImageContainer>
          <Typography variant="h3" color="primary">{title}</Typography>
        </Box>
        <IconButtonSquare
          variant={isOpen ? 'contained' : 'outlined' }
          onClick={handleToggle}
          disabled={disabled}
        >
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButtonSquare>
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box p={2} pt={0}>
          <Typography
            variant="body1"
          >
            {children}
          </Typography>
        </Box>
      </Collapse>

    </CardContainer>
  );
}; 

export default CardItem;
