import create from 'zustand';
import {  ChainFragment, CustomerRegistrationFragment, CustomerSortableFields, CustomerWithPurchasesFragment, OrderBy, PointOfSellOptionFragment, } from 'Apollo/graphql';
import { ChangeTableFilter, ChangeTableSort, ChangeTableData, } from 'Utils/types';
import { IsAcceptedOption, } from 'Utils/options/useOptionsAccepted';

export type TableRegistrationsFilter = {
  chain: ChainFragment | null,
  pos: PointOfSellOptionFragment | null,
  author: string,
  customerId: string,
  firstName: string,
  lastName: string,
  email:string,
  phoneNumber: string,
  deviceCode: string
  acceptedTermsAndConditions: IsAcceptedOption | null,
  acceptedPulzeOn: IsAcceptedOption | null,
  acceptedPulzeCare: IsAcceptedOption | null,
};

export type RegistrationStore = {
  modalEraseAllCustomerData: {
    isOpen: boolean,
    registration: CustomerRegistrationFragment | CustomerWithPurchasesFragment | null,
  },
  modalExportRegistration: {
    isOpen: boolean,
  },
  modalQrRegistration: {
    isOpen: boolean,
  },
  modalQrRegistrationBrandAmbassador: {
    isOpen: boolean,
  },
  modalDetailRegistration: {
    isOpen: boolean,
    registration: CustomerRegistrationFragment | null,
  },
  modalRemoveRegistration: {
    isOpen: boolean,
    registration: CustomerRegistrationFragment | CustomerWithPurchasesFragment| null,
  },
  tableRegistrations: {
    data: {
      sortField: CustomerSortableFields | null,
      sortOrder: OrderBy | null,
      limit: number,
      offset: number,
    },
    filter: TableRegistrationsFilter,
  },
  openModalEraseAllCustomerData: (registration: CustomerRegistrationFragment | CustomerWithPurchasesFragment) => void,
  closeModalEraseAllCustomerData: () => void,
  openModalExportRegistration: () => void,
  closeModalExportRegistration: () => void,
  openModalQrRegistration: () => void,
  closeModalQrRegistration: () => void,
  openModalQrRegistrationBrandAmbassador: () => void,
  closeModalQrRegistrationBrandAmbassador: () => void,
  openModalDetailRegistration: (registration: CustomerRegistrationFragment) => void,
  closeModalDetailRegistration: () => void,
  openModalRemoveRegistration: (registration: CustomerRegistrationFragment | CustomerWithPurchasesFragment) => void,
  closeModalRemoveRegistration: () => void,
  changeTableData: ChangeTableData,
  changeTableFilter: ChangeTableFilter,
  changeTableSort: ChangeTableSort,
};

export const useRegistrationStore = create<RegistrationStore>((set) => ({
  modalEraseAllCustomerData: {
    isOpen: false,
    registration: null,
  },
  modalExportRegistration: {
    isOpen: false,
  },
  modalQrRegistration: {
    isOpen: false,
  },
  modalQrRegistrationBrandAmbassador: {
    isOpen: false,
  },
  modalRemoveRegistration: {
    isOpen: false,
    registration: null,
  },
  modalDetailRegistration: {
    isOpen: false,
    registration: null,
  },
  tableRegistrations: {
    data: {
      sortField: null,
      sortOrder: null,
      limit: 10,
      offset: 0,
    },
    filter: {
      chain: null,
      pos: null,
      author: '',
      customerId: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      deviceCode: '',
      acceptedTermsAndConditions: null,
      acceptedPulzeOn: null,
      acceptedPulzeCare: null,
    },
  },

  /*
   * Modal Export
   */
  openModalExportRegistration: () =>
    set({
      modalExportRegistration: {
        isOpen: true,
      },
    }),
  closeModalExportRegistration: () =>
    set({
      modalExportRegistration: {
        isOpen: false,
      },
    }),
  /*
   * Modal Qr
   */
  openModalQrRegistration: () =>
    set({
      modalQrRegistration: {
        isOpen: true,
      },
    }),
  closeModalQrRegistration: () =>
    set({
      modalQrRegistration: {
        isOpen: false,
      },
    }),
  /*
   * Modal Qr Brand Ambassador
   */
  openModalQrRegistrationBrandAmbassador: () =>
    set({
      modalQrRegistrationBrandAmbassador: {
        isOpen: true,
      },
    }),
  closeModalQrRegistrationBrandAmbassador: () =>
    set({
      modalQrRegistrationBrandAmbassador: {
        isOpen: false,
      },
    }),

  /*
   * Modal erase  customer data
   */
  openModalEraseAllCustomerData: (registration) =>
    set({
      modalEraseAllCustomerData: {
        isOpen: true,
        registration,
      },
    }),
  closeModalEraseAllCustomerData: () =>
    set((state) => ({
      modalEraseAllCustomerData: {
        ...state.modalDetailRegistration,
        isOpen: false,
      },
    })),
  /*
   * Modal Edit
   */
  openModalDetailRegistration: (registration) =>
    set({
      modalDetailRegistration: {
        isOpen: true,
        registration,
      },
    }),
  closeModalDetailRegistration: () =>
    set((state) => ({
      modalDetailRegistration: {
        ...state.modalDetailRegistration,
        isOpen: false,
      },
    })),

  /*
   * Modal Remove
   */
  openModalRemoveRegistration: (registration) =>
    set({
      modalRemoveRegistration: {
        isOpen: true,
        registration,
      },
    }),
  closeModalRemoveRegistration: () =>
    set((state) => ({
      modalRemoveRegistration: {
        ...state.modalRemoveRegistration,
        isOpen: false,
        registration: null,
      },
    })),

  /**
   * Registration table
   */
  changeTableData: (name, value) =>
    set((state) => ({
      tableRegistrations: {
        ...state.tableRegistrations,
        data: {
          ...state.tableRegistrations.data,
          [name]: value,
        },
      },
    })),
  changeTableFilter: (name, value) =>
    set((state) => ({
      tableRegistrations: {
        ...state.tableRegistrations,
        data: {
          ...state.tableRegistrations.data,
          offset: 0,
        },
        filter: {
          ...state.tableRegistrations.filter,
          [name]: value,
        },
      },
    })),
  changeTableSort: (sortField, sortOrder) =>
    set((state) => ({
      tableRegistrations: {
        ...state.tableRegistrations,
        data: {
          ...state.tableRegistrations.data,
          sortField: sortField as CustomerSortableFields,
          sortOrder,
        },
      },
    })),
}));
