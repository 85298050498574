import Typography from '@mui/material/Typography';
import { CustomerVerificationMethod, CustomerVerificationStartResultEmailGrpc, } from 'Apollo/graphql';
import { RegistrationPhase, RegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { useTranslation, } from 'react-i18next';
import CheckGrpcVerification from './CheckGrpcVerification';


interface Props {
  verification: CustomerVerificationStartResultEmailGrpc;
  completeCustomerVerificationPhase: (verification: RegistrationStore['verification']) => void;
  goBackToPhase: () => void;
}
const GrpcEmailVerification = ({verification,completeCustomerVerificationPhase,goBackToPhase,}:Props): JSX.Element => {
  const { t, } = useTranslation();
  
    return (
      <>
        <Typography variant="h1" sx={{mb: 4,}}>
          {t('registrationSeller:phaseCustomerVerification.title')}
        </Typography>
        <CheckGrpcVerification
          onComplete={
            () => completeCustomerVerificationPhase({
              shouldVerify: false,
              isVerified: true,
              method: CustomerVerificationMethod.Email,
              value: verification.globalApiGrpcVerification.verificatedValue,})
          }
          onGoBack={() => goBackToPhase()}
          startedVerification={(verification as CustomerVerificationStartResultEmailGrpc).globalApiGrpcVerification}
        />
      </>
    );
  };

export default GrpcEmailVerification;
