import { Query, } from '@cubejs-client/core';
import { zodResolver, } from '@hookform/resolvers/zod';
import { Grid, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import ChartDetailLayout from 'Components/Dashboard/ChartDetailLayout';
import { totalNumberOfRepurchasesQuery, } from 'Components/Dashboard/Registrations/queries';
import TotalNumberOfRepurchases from 'Components/Dashboard/Registrations/TotalNumberOfRepurchases';
import { useMemo, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import ChartRenderer from 'Utils/Charts/RenderChart';
import { OptionTypes, ChartType, } from 'Utils/Charts/types';
import useDashboardFilter from 'Utils/Charts/UseDashboardFilter';
import { SourceAndTimeAndUserRoleValidationSchema, } from 'Utils/Charts/ValidationSchemas';
import { z, } from 'zod';

type ValidationSchema = z.infer<
  typeof SourceAndTimeAndUserRoleValidationSchema
>;

const RepurchasesPage = () => {
  const { t, } = useTranslation('dashboards');
  const filtration = [OptionTypes.source, OptionTypes.role,OptionTypes.time,];
  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(SourceAndTimeAndUserRoleValidationSchema),
    defaultValues: {
      date: 'This year',
      chainIds: [],
      posIds: [],
      userRoleId: null,
    },
  });
  const watch = methods.watch();

  const { filtersArray, dateRange, granularity, } = useDashboardFilter(
    {
      chainIds: {
        operator: 'equals',
        values: watch.chainIds,
        filterMember: 'chain.id',
      },
      posIds: {
        operator: 'equals',
        values: watch.posIds,
        filterMember: 'customer.posid',
      },
      userRoleId: {
        operator: 'equals',
        value: watch.userRoleId,
        filterMember: 'user.role',
      },
    },
    watch.date,
  );

  const query = useMemo(
    () =>
      totalNumberOfRepurchasesQuery({
        timeDimensions: [
          {
            dateRange,
            granularity,
          },
        ],
        showNullValues: true,
        filters: filtersArray,
      }),
    [dateRange, filtersArray, granularity,],
  );
  return (
    <AppContainer>
      <ChartDetailLayout pageTitle={t('common:dashboardSidebar.registrations')}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <TotalNumberOfRepurchases
                showSlug={false}
                filtration={filtration}
                chartFilter={filtersArray}
                timeDimensions={query.timeDimensions}
              />
            </FormProvider>
          </Grid>
          <Grid item xs={12}>
            <ChartRenderer
              query={query}
              type={ChartType.table}
              pivotConfig={{
                x: ['purchase.createdat', 'measures',],
                y: ['purchase.is_customers_first_purchase',],
                fillMissingDates: true,
                joinDateRange: false,
              }}
            />
          </Grid>
        </Grid>
      </ChartDetailLayout>
    </AppContainer>
  );
};

export default RepurchasesPage;
