import { Query, } from '@cubejs-client/core';
import { QueryParams, } from '../types';

export const topReasonsForReplacementQuery = ({
  timeDimensions,
  filters = [],
}: QueryParams): Query => ({
  measures: ['replacement.count',],
  timeDimensions: [
    {
      dateRange: timeDimensions?.[0]?.dateRange,
      dimension: timeDimensions?.[0]?.dimension || 'replacement.createdat',
      granularity: timeDimensions?.[0]?.granularity,
    },
  ],
  dimensions: ['replacement.reason',],
  order: {
    'replacement.count': 'desc',
  },
  filters,
});

export const totalNumberOfReplacementsQuery = ({
  timeDimensions,
  filters = [],
}: QueryParams): Query => ({
  measures: ['replacement.count',],
  dimensions: ['replacement.status',],
  timeDimensions: [
    {
      dateRange: timeDimensions?.[0]?.dateRange,
      dimension: timeDimensions?.[0]?.dimension || 'replacement.createdat',
      granularity: timeDimensions?.[0]?.granularity || 'year',
    },
  ],
  filters,
});
