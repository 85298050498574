import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { AssessmentBlockTypeEnum, } from 'Apollo/graphql';
import { areOptionsEqualById, getOptionFieldId, } from 'Utils/helpers';

export type AssessmentBlockOption = {
  id: AssessmentBlockTypeEnum,
  labelKey: string,
};

export const ASSESSMENT_BLOCK_OPTIONS: AssessmentBlockOption[] = [
  {
    id: AssessmentBlockTypeEnum.Text,
    labelKey: 'tasksAdmin:options.assessmentBlock.TEXT',
  },
  {
    id: AssessmentBlockTypeEnum.Number,
    labelKey: 'tasksAdmin:options.assessmentBlock.NUMBER',
  },
  {
    id: AssessmentBlockTypeEnum.YesNo,
    labelKey: 'tasksAdmin:options.assessmentBlock.YES_NO',
  },
  {
    id: AssessmentBlockTypeEnum.Radio,
    labelKey: 'tasksAdmin:options.assessmentBlock.RADIO',
  },
  {
    id: AssessmentBlockTypeEnum.Checkbox,
    labelKey: 'tasksAdmin:options.assessmentBlock.CHECKBOX',
  },
  {
    id: AssessmentBlockTypeEnum.Scale,
    labelKey: 'tasksAdmin:options.assessmentBlock.SCALE',
  },
  {
    id: AssessmentBlockTypeEnum.Photo,
    labelKey: 'tasksAdmin:options.assessmentBlock.Photo',
  },
];

type GetOptionLabel = (o: AssessmentBlockOption) => string;
type GetOptionKey = (o: AssessmentBlockOption) => string;

type UseOptionsAssessmentBlock = () => {
  options: AssessmentBlockOption[],
  getOptionLabel: GetOptionLabel,
  getOptionKey: GetOptionKey,
  isOptionEqualToValue: typeof areOptionsEqualById<AssessmentBlockOption>, 
};

export const useOptionsAssessmentBlock: UseOptionsAssessmentBlock = () => {
  const { t, } = useTranslation('common');
  const getOptionLabel = useCallback<GetOptionLabel>(
    (o) => t(o.labelKey, o.id),
    [ t, ],
  );

  return {
    options: ASSESSMENT_BLOCK_OPTIONS,
    getOptionLabel,
    getOptionKey: getOptionFieldId,
    isOptionEqualToValue: areOptionsEqualById,
  };
};
