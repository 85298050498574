import Grid from '@mui/material/Grid';
import { FormInputAutocomplete, FormInputGroupRadio, FormInputText, FormSubmit, } from 'Components/Form';
import { useOptionsPointsTransactionOperation, } from 'Utils/options/useOptionsOperation';
import { PointsTransactionReasonOption, useOptionsPointsTransactionReason, } from 'Utils/options/usePointsTransactionReason';
import { useFormContext, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { useEffect, } from 'react';
import { CreatePointsTransactionForm, } from '../modals/ModalCreatePointsTransaction/utils';

interface Props {
  loading: boolean;
}

const PointsTransactionCreateForm = ({ loading, }: Props): JSX.Element => {
  const { t, } = useTranslation('pointsTransactionAdmin');
  const pointsTransactionOperationOptions = useOptionsPointsTransactionOperation();
  const pointsTransactionReasonMyPoints = useOptionsPointsTransactionReason();
  const context = useFormContext<CreatePointsTransactionForm>();
  const reason = context.watch('reason');
  useEffect(() => {
    if (reason === 'Voucher') {
      context.setValue('operation', pointsTransactionOperationOptions.options[1]);
    }
  }, [reason, context, pointsTransactionOperationOptions.options,]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormInputText name="username" label={t('pointsTransaction.labelAuthor')} required />
      </Grid>
      <Grid item xs={12}>
        <FormInputAutocomplete<PointsTransactionReasonOption>
          name="reason"
          options={pointsTransactionReasonMyPoints.options}
          label={t('pointsTransaction.labelReason')}
          getOptionLabel={pointsTransactionReasonMyPoints.getOptionLabel}
          isOptionEqualToValue={pointsTransactionReasonMyPoints.isOptionEqualToValue}
          placeholder={t('common:select.placeholder')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputGroupRadio
          name="operation"
          label={t('pointsTransaction.labelOperation')}
          options={pointsTransactionOperationOptions.options}
          required
          getOptionKey={pointsTransactionOperationOptions.getOptionKey}
          getOptionLabel={pointsTransactionOperationOptions.getOptionLabel}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="amount" label={t('pointsTransaction.labelAmount')} type="number" required />
      </Grid>
      <Grid item xs={12}>
        <FormSubmit size="large" disabled={loading}>
          {t('common:actions.confirm')}
        </FormSubmit>
      </Grid>
    </Grid>
  );
};

export default PointsTransactionCreateForm;
