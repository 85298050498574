/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloCache, DefaultContext, MutationUpdaterFunction, Reference, } from '@apollo/client';
import { AssortmentFragmentDoc, AssortmentInput, CreateAssortmentMutation, DeleteAssortmentMutation, Exact, } from 'Apollo/graphql';

type UpdateAssortmentCreate = MutationUpdaterFunction<CreateAssortmentMutation, Exact<{
  assortmentInput: AssortmentInput;
}>, DefaultContext, ApolloCache<any>> | undefined;

export const updateAssortmentCreate: UpdateAssortmentCreate = (cache, { data, }) => {
  try {
    if (data?.createAssortment) {
      const newRef = cache.writeFragment({
        id: cache.identify(data.createAssortment),
        data: data.createAssortment,
        fragment: AssortmentFragmentDoc,
        fragmentName: data.createAssortment.__typename,
      });
  
      cache.modify({
        fields: {
          assortments: (existing) => ({
            ...existing,
            data: [ ...existing.data, newRef, ],
          }),
        },
      });
    }
  } catch {
    // ignore error
  }
};

type UpdateAssortmentRemove = MutationUpdaterFunction<DeleteAssortmentMutation, Exact<{
  id: string;
}>, DefaultContext, ApolloCache<any>> | undefined;

export const updateAssortmentRemove: UpdateAssortmentRemove = (cache, { data, }) => {
  try {
    if (data?.deleteAssortment) {
      cache.modify({
        fields: {
          assortments: (existing, { readField, }) => ({
            ...existing,
            data: existing.data.filter((itemRef: Reference) => readField('id', itemRef) !== data.deleteAssortment.id),
          }),
        },
      });
    }
  } catch {
    // ignore error
  }
};
