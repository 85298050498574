import { hu, enUS, cs, pl, } from 'date-fns/locale';

export type LocalObject = {
    [key: string]: Locale
};
export const localeObject: LocalObject = {
    "en": enUS,
    "hu": hu,
    'cz': cs,
    'pl': pl,
};
