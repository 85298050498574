import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const OrderedListIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    {...props}
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m2.859 2.908.506-.408h.846v3.75h-.885V3.49l-.467.351v-.933Zm3.391.842h12.5V5H6.25V3.75Zm0 5h12.5V10H6.25V8.75Zm12.5 5H6.25V15h12.5v-1.25ZM4.678 9.533l.036-.05.081-.112c.132-.184.2-.407.195-.633 0-.15-.025-.3-.071-.443a1.06 1.06 0 0 0-.615-.661 1.404 1.404 0 0 0-.565-.103 1.368 1.368 0 0 0-.573.109 1.084 1.084 0 0 0-.599.653c-.043.13-.066.266-.067.403v.063h1.013v-.063c0-.07.02-.137.057-.196a.218.218 0 0 1 .071-.068.237.237 0 0 1 .215 0c.029.02.053.046.07.075a.3.3 0 0 1 .039.102c.026.122.01.25-.045.362-.044.08-.094.156-.15.228L2.52 10.62l-.015.016v.675H4.99v-.874H3.865l.813-.905Zm-.047 4.77c.058.04.108.088.15.143.148.198.222.441.209.688 0 .133-.021.266-.063.392a.99.99 0 0 1-.608.625c-.192.071-.396.105-.6.099-.144 0-.288-.02-.426-.061a1.177 1.177 0 0 1-.323-.154.939.939 0 0 1-.228-.221 1.333 1.333 0 0 1-.144-.25 1.298 1.298 0 0 1-.098-.466v-.062h1.018v.062c0 .033.003.068.01.102.007.033.02.064.038.093.016.027.039.05.067.065a.25.25 0 0 0 .196.01.199.199 0 0 0 .07-.05.275.275 0 0 0 .052-.093.521.521 0 0 0 .022-.157.604.604 0 0 0-.028-.204.25.25 0 0 0-.064-.1.172.172 0 0 0-.075-.037.66.66 0 0 0-.096-.008h-.201v-.806h.21c.029 0 .058-.005.086-.014a.205.205 0 0 0 .081-.043.218.218 0 0 0 .06-.083c.02-.048.029-.1.026-.152a.35.35 0 0 0-.02-.125.208.208 0 0 0-.12-.123.25.25 0 0 0-.196.008.204.204 0 0 0-.069.066.43.43 0 0 0-.05.196v.06H2.5v-.06c.006-.244.084-.481.225-.68a1 1 0 0 1 .224-.233c.099-.074.21-.13.327-.166.143-.045.293-.066.443-.064.145-.001.289.012.43.045.115.026.225.069.329.125.17.089.31.227.4.398a.913.913 0 0 1 .112.433c.01.238-.064.472-.209.66a.705.705 0 0 1-.15.143Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default OrderedListIcon;
