import { styled, } from '@mui/material';
import { StaticDatePicker, } from '@mui/x-date-pickers';
import InputTextClassic from './InputTextClassic';

const StyledStaticDatePicker = styled(StaticDatePicker)<{
  showButtons?: boolean;
}>(({ theme, showButtons = false, }) => ({
  '& .MuiDialogActions-root': {
    display: !showButtons ? 'none' : 'flex',
  },
}));

type Props = {
  value: Date | string | null;
  onChange: (value: Date | null) => void;
  showButtons?: boolean;
  minDate?: Date;
  disableFuture?: boolean;
};

const ClassicStaticDatePicker = ({
  value,
  onChange,
  minDate,
  showButtons = false,
  disableFuture = true,
}: Props) => {
  return (
    <StyledStaticDatePicker
      showButtons={showButtons}
      value={value}
      disableFuture={disableFuture}
      minDate={minDate}
      onChange={(changedValue) => {
        const date = changedValue as Date;
        onChange(date);
        // setValue('date', [date.toString(), getValues('date')[1],]);
      }}
      showToolbar={false}
      componentsProps={{ actionBar: { actions: [], }, }}
      renderInput={(ip) => (
        <InputTextClassic
          ref={ip.inputRef}
          className={ip.InputProps?.className}
          endAdornment={ip.InputProps?.endAdornment}
          startAdornment={ip.InputProps?.startAdornment}
          InputLabelProps={ip.InputLabelProps}
          inputProps={ip.inputProps}
          id={ip.id}
          disabled={ip.disabled}
          fullWidth={ip.fullWidth}
          label={ip.label}
          sx={{
            '& .MuiSvgIcon-fontSizeMedium': {
              fontSize: {
                xs: '1.5rem',
                lg: '1.25rem',
              },
            },
            '& .MuiInputAdornment-positionEnd': {
              height: 'unset',
              paddingRight: '8px',
              marginLeft: 0,
              top: {
                xs: 'calc(50% - 12px)',
                lg: 'calc(50% - 12px)',
              },
            },
            '& .MuiIconButton-edgeEnd': {
              marginRight: 0,
              color: 'primary.main',
              width: {
                xs: '28px',
                lg: '24px',
              },
              height: {
                xs: '28px',
                lg: '24px',
              },
            },
          }}
        />
      )}
    />
  );
};

export default ClassicStaticDatePicker;
