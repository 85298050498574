import { KeyboardEventHandler, } from 'react';
import { Editable, } from 'slate-react';
import { styled, } from '@mui/material/styles';
import Toolbar from './Toolbar';
import { RenderElement, RenderLeaf, } from './types';

const StyledContainer = styled(
  'div',
  { shouldForwardProp: (prop) => (prop !== 'readOnly' && prop !== 'error'), }
)<{ readOnly?: boolean, error?: boolean, }>(({ theme, readOnly, error, }) => ({
  width: '100%',
  ...(!readOnly && {
    background: theme.palette.common.white,
    padding: '20px 15px;',
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: error ? theme.palette.error.main : '#d9d9d9',

    '&:focus-within': {
      borderColor: error ? theme.palette.error.main : '#333333',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '12px 15px',
    },
  }),
  ...theme.typography.body1,

  '& [data-slate-editor] > * + *': {
    marginTop: '1em',
  },

  h1: {
    ...theme.typography.h2,
    color: theme.palette.primary.main,
    margin: 0,
  },
  h2: {
    ...theme.typography.h3,
    color: theme.palette.primary.main,
    margin: 0,
  },
  p: {
    margin: 0,
  },
  strong: {
    fontWeigh: theme.typography.fontWeightBold,
  },
  code: {
    backgroundColor: '#9d9d9d5c',
    padding: theme.spacing(0, 0.25),
  },
  em: {

  },
  u: {

  },
}));

interface Props {
  renderElement: RenderElement,
  renderLeaf: RenderLeaf,
  onEditorKeyDown: KeyboardEventHandler<HTMLDivElement>
  readOnly?: boolean,
  placeholder?: string,
  error?: boolean,
};

const EditorLayout = ({
  renderElement,
  renderLeaf,
  onEditorKeyDown,
  readOnly,
  placeholder,
  error,
}: Props): JSX.Element => {
  return (
    <StyledContainer
      readOnly={readOnly}
      error={error}
    >
      {!readOnly && <Toolbar />}
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={onEditorKeyDown}
        readOnly={readOnly}
        placeholder={placeholder}
      />
    </StyledContainer>
  );
};

export default EditorLayout;
