import Grid from '@mui/material/Grid';
import { TaskContentBlockFragment, } from 'Apollo/graphql';
import { useMemoArrayId, } from 'Tools/useMemoArrayId';
import Block from './Blocks';

interface Props {
  contentBlocks: TaskContentBlockFragment[]
}

const TaskContent = ({
  contentBlocks,
}: Props): JSX.Element => {
  const list = useMemoArrayId(contentBlocks || []);
  
  return (
    <Grid container spacing={2}>
      {list.map((block, index) => (
        <Block
          key={block.id}
          index={index}
          block={block.payload}
        />
      ))}
    </Grid>
  );
};

export default TaskContent;
