import create from 'zustand';
import { ChainFragment, NewsArticleFragment, NewsArticleSortableFields, NewsArticleTableFragment, OrderBy, } from 'Apollo/graphql';
import { ChangeTableFilter, ChangeTableSort, ChangeTableData, } from 'Utils/types';

export type TableNewsArticlesFilter = {
  newsArticleId: string,
  title: string,
  description: string,
  authorId: string,
  chainId: ChainFragment | null
};

export type NewsArticlesStore = {
  modalDetailNewsArticle: {
    isOpen: boolean,
    newsArticle: NewsArticleTableFragment | null,
  },
  modalCreateNewsArticle: {
    isOpen: boolean,
  },
  modalEditNewsArticle: {
    isOpen: boolean,
    newsArticle: NewsArticleTableFragment | null,
  },
  modalRemoveNewsArticle: {
    isOpen: boolean,
    newsArticle: NewsArticleTableFragment | NewsArticleFragment | null,
  },
  tableNewsArticles: {
    data: {
      sortField: NewsArticleSortableFields | null,
      sortOrder: OrderBy | null,
      limit: number,
      offset: number,
    },
    filter: TableNewsArticlesFilter,
  },
  openModalCreateNewsArticle: () => void,
  closeModalCreateNewsArticle: () => void,
  openModalEditNewsArticle: (newsArticle: NewsArticleTableFragment) => void,
  closeModalEditNewsArticle: () => void,
  openModalDetailNewsArticle: (newsArticle: NewsArticleTableFragment) => void,
  closeModalDetailNewsArticle: () => void,
  openModalRemoveNewsArticle: (newsArticle: NewsArticleTableFragment | NewsArticleFragment) => void,
  closeModalRemoveNewsArticle: () => void,
  changeTableData: ChangeTableData,
  changeTableFilter: ChangeTableFilter,
  changeTableSort: ChangeTableSort,
};

export const useNewsArticlesStore = create<NewsArticlesStore>((set) => ({
  modalDetailNewsArticle: {
    isOpen: false,
    newsArticle: null,
  },
  modalCreateNewsArticle: {
    isOpen: false,
  },
  modalEditNewsArticle: {
    isOpen: false,
    newsArticle: null,
  },
  modalRemoveNewsArticle: {
    isOpen: false,
    newsArticle: null,
  },
  tableNewsArticles: {
    data: {
      sortField: null,
      sortOrder:  null,
      limit: 10,
      offset: 0,
    },
    filter: {  
      newsArticleId: '',
      title: '',
      description: '',
      authorId: '',
      chainId: null,
    },
  },
  /*
   * Modal Create
   */
  openModalCreateNewsArticle: () => set({
    modalCreateNewsArticle: {
      isOpen: true,
    },
  }),
  closeModalCreateNewsArticle: () => set({
    modalCreateNewsArticle: {
      isOpen: false,
    },
  }),
    /*
   * Modal Detail
   */
    openModalDetailNewsArticle: (newsArticle) => set({
      modalDetailNewsArticle: {
        isOpen: true,
        newsArticle,
      },
    }),
    closeModalDetailNewsArticle: () => set((state) => ({
      modalDetailNewsArticle: {
        ...state.modalEditNewsArticle,
        isOpen: false,
      },
    })),
  /*
   * Modal Edit
   */
  openModalEditNewsArticle: (newsArticle) => set({
    modalEditNewsArticle: {
      isOpen: true,
      newsArticle,
    },
  }),
  closeModalEditNewsArticle: () => set((state) => ({
    modalEditNewsArticle: {
      ...state.modalEditNewsArticle,
      isOpen: false,
    },
  })),
  /*
   * Modal Remove
   */
  openModalRemoveNewsArticle: (newsArticle) => set({
    modalRemoveNewsArticle: {
      isOpen: true,
      newsArticle,
    },
  }),
  closeModalRemoveNewsArticle: () => set((state) => ({
    modalRemoveNewsArticle: {
      ...state.modalRemoveNewsArticle,
      isOpen: false,
      newsArticle: null,
    },
  })),

  /**
   * News article table
   */
   changeTableData: (name, value) => set((state) => ({
    tableNewsArticles: {
      ...state.tableNewsArticles,
      data: {
        ...state.tableNewsArticles.data,
        [name]: value,
      },
    },
   })),
   changeTableFilter: (name, value) => set((state) => ({
    tableNewsArticles: {
      ...state.tableNewsArticles,
      data: {
        ...state.tableNewsArticles.data,
        offset: 0,
      },
      filter: {
        ...state.tableNewsArticles.filter,
        [name]: value,
      },
    },
   })), 
   changeTableSort: (sortField, sortOrder) => set((state) => ({
    tableNewsArticles: {
      ...state.tableNewsArticles,
      data: {
        ...state.tableNewsArticles.data,
        sortField: sortField as NewsArticleSortableFields,
        sortOrder,
      },
    },
   })),
}));
