import { ReactNode, } from 'react';
import { useTranslation, } from 'react-i18next';
import Typography from '@mui/material/Typography';
import LoadingBox from 'Components/LoadingBox';
import type { AnyObject, } from 'Utils/types';
import ItemInput from './ItemInput';
import { ObjValue, OnChange, } from './types';

export interface Props<T extends AnyObject,> {
  objValue: ObjValue<T>,
  options: T[],
  loading?: boolean,
  getOptionKey: (option: T) => string,
  getOptionLabel: (option: T) => ReactNode,
  getOptionMax?: (option: T) => number | null | undefined,
  onChange: OnChange<T>,
  noOptionsText?: string,
  totalDisabledPlus?: boolean
};

const OptionList = <T extends AnyObject,>({
  objValue,
  onChange,
  getOptionKey,
  getOptionLabel,
  getOptionMax,
  options,
  loading,
  noOptionsText,
  totalDisabledPlus,
}: Props<T>): JSX.Element => {
  const { t, } = useTranslation();

  if (loading) return (
    <LoadingBox />
  );

  if (options.length < 1) return (
    <Typography
      variant="body1"
    >
      {noOptionsText || t('common:select.noOptions')}
    </Typography>
  );

  return (
    <>
      {options.map((o) => (
        <ItemInput<T>
          key={getOptionKey(o)}
          option={o}
          onChange={onChange}
          objValue={objValue}
          getOptionLabel={getOptionLabel}
          getOptionMax={getOptionMax}
          getOptionKey={getOptionKey}
          totalDisabledPlus={totalDisabledPlus}
        />
      ))}
    </>
  );
};

export default OptionList;
