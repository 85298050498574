import { yupResolver, } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled, } from '@mui/material/styles';
import {
  AlzaPurchaseErrorEnum,
  CreateAlzaVoucherPurchaseResult,
  GetAvailableAlzaVoucherPurchaseOptionsQuery,
  useCreateAlzaVoucherPurchaseMutation,
} from 'Apollo/graphql';
import FormSubmit from 'Components/Form/components/FormSubmit';
import FormInputAutocomplete from 'Components/Form/inputs/FormInputAutocomplete';
import ImageAsync from 'Components/ImageAsync';
import LoadingBox from 'Components/LoadingBox';
import { useAuthUser, } from 'Tools/auth/hooks/useAuthUser';
import { ROUTES, } from 'Utils/constants';
import { areOptionsEqualById, renderOptionById, } from 'Utils/helpers';
import { AlzaPurchaseOption, useOptionsAlzaVoucherPurchase, } from 'Utils/options/useOptionsAlzaVoucherPurchase';
import { useState, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { alzaPurchaseDefaultValues, validationSchema, } from './utils';

type Props = {
  data: GetAvailableAlzaVoucherPurchaseOptionsQuery;
};

const Item = styled(Paper)(({ theme, }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.5, 2),
  marginBottom: '1rem',
}));

const StyledChip = styled(Chip)(({ theme, }) => ({
  color: 'white',
  background: theme.palette.primary.main,
  fontVariant: 'h1',
  alignSelf: 'flex-end',
  marginBottom: '1rem',
}));
type StateType = null | CreateAlzaVoucherPurchaseResult;
const AlzaView = ({ data, }: Props): JSX.Element => {
  const { t, } = useTranslation('myPoints');
  const authUser = useAuthUser();
  const {
    getAvailableAlzaPurchaseOptions: { options, exampleVoucher, },
  } = data;
  const navigate = useNavigate();
  const backToPoints = () => navigate(`/${ROUTES.myPoints}`);
  const [state, setState,] = useState<StateType>(null);

  const methods = useForm({
    defaultValues: alzaPurchaseDefaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  });
  const alzaPurchaseOptions = useOptionsAlzaVoucherPurchase(data.getAvailableAlzaPurchaseOptions.options);
  const [createMutation, response,] = useCreateAlzaVoucherPurchaseMutation({
    onCompleted: (r) => {
      setState((s) => r.createAlzaVoucherPurchase);
    },
    onError: () => {
      // TODO: merge form errors
    },
  });
  const voucherValue = methods.watch('value')?.id || '';
  const handleSubmit = methods.handleSubmit(async (values) => {
    await createMutation({
      variables: {
        alzaVoucherPurchaseInput: {
          userId: authUser?.id || '',
          voucherValue: Number(values.value?.id) || 0,
        },
      },
    });
  });
  if (!data) {
    return <LoadingBox />;
  }
  if (state?.success) {
    return (
      <>
        <Typography variant="h1" mb={1.5}>
          {t('voucher.detailTitlePurchased')}
        </Typography>
        <Typography variant="inherit" mb={4}>
          {t('voucher.alza.detailDescriptionPurchased')}
        </Typography>
        <Button size="large" fullWidth variant="contained" color="primary" onClick={backToPoints}>
          {t('common:actions.done')}
        </Button>
      </>
    );
  }
  if (!state?.success && state?.reason === AlzaPurchaseErrorEnum.Noemail) {
    return (
      <>
        <Typography variant="h1" mb={1.5}>
          {t('voucher.detailTitlePurchaseFailed')}
        </Typography>
        <Typography variant="inherit" mb={4}>
          {t('voucher.alza.missingEmail')}
        </Typography>
        <Button size="large" fullWidth variant="contained" color="primary" onClick={backToPoints}>
          {t('common:actions.done')}
        </Button>
      </>
    );
  }
  if (!state?.success && state?.reason === AlzaPurchaseErrorEnum.Novouchers) {
    return (
      <>
        <Typography variant="h1" mb={1.5}>
          {t('voucher.detailTitlePurchaseFailed')}
        </Typography>
        <Typography variant="inherit" mb={4}>
          {t('voucher.noVouchers')}
        </Typography>
        <Button size="large" fullWidth variant="contained" color="primary" onClick={backToPoints}>
          {t('common:actions.done')}
        </Button>
      </>
    );
  }

  if (!state?.success && state?.reason === AlzaPurchaseErrorEnum.Nopoints) {
    return (
      <>
        <Typography variant="h1" mb={1.5}>
          {t('voucher.detailTitlePurchaseFailed')}
        </Typography>
        <Typography variant="inherit" mb={4}>
          {t('voucher.alza.noPoints')}
        </Typography>
        <Button size="large" fullWidth variant="contained" color="primary" onClick={backToPoints}>
          {t('common:actions.done')}
        </Button>
      </>
    );
  }

  return (
    <>
      <Typography variant="h1" mb={1.5}>
        {t('voucher.detailTitle')}
      </Typography>
      <Item>
        <StyledChip label={`${voucherValue} ${t('voucher.points')}`} />
        <ImageAsync src={data?.getAvailableAlzaPurchaseOptions?.exampleVoucher?.image?.publicUrl} />
        <Box display="flex" justifyContent="space-between" flexDirection="column">
          <Typography mt={2} variant="h1" color="primary">
            {data?.getAvailableAlzaPurchaseOptions?.exampleVoucher?.name}
          </Typography>
          <Typography mt={2} mb={2} variant="inherit">
            {data?.getAvailableAlzaPurchaseOptions?.exampleVoucher?.description}
          </Typography>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormInputAutocomplete<AlzaPurchaseOption>
                    name="value"
                    getOptionLabel={(o) => o.labelKey}
                    options={alzaPurchaseOptions.options || []}
                    isOptionEqualToValue={areOptionsEqualById}
                    renderOption={renderOptionById(alzaPurchaseOptions.getOptionLabel)}
                    label={t('voucher.alza.valueInput')}
                    placeholder={t('common:select.placeholder')}
                    required
                  />
                </Grid>
                <Grid item xs={12} marginBottom={1} marginTop={1}>
                  <Box display="flex" justifyContent="flex-end">
                    <FormSubmit size="large">{t('voucher.purchase')}</FormSubmit>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Item>
      <Button size="large" fullWidth variant="outlined" color="inherit" onClick={backToPoints}>
        {t('common:actions.back')}
      </Button>
    </>
  );
};

export default AlzaView;
