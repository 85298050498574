import { styled, } from '@mui/material/styles';
import { TableCell, } from '@mui/material';
import ImageAsync from 'Components/ImageAsync';

const StyledTableCell = styled(TableCell)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
}));

const ImageContainer = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: 'calc(1.5em + 20px)',

  '& > img': {
    objectPosition: 'left',
  },
}));

interface Props {
  src?: string | null,
}

const TableCellImage = ({
  src,
}: Props): JSX.Element => {
  return (
    <StyledTableCell>
      <ImageContainer>
        <ImageAsync
          src={src}
        />
      </ImageContainer>
    </StyledTableCell>
  );
};

export default TableCellImage;
