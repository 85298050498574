import Core from 'Modules/Core';
import { ThemeProvider, } from '@mui/material/styles';
import { CssBaseline, } from '@mui/material';
import { BrowserRouter as Router, } from 'react-router-dom';
import { SnackbarProvider, } from 'notistack';
import { ApolloProvider, } from '@apollo/client';
import { apolloClient, } from 'Apollo';
import { appTheme, } from 'Utils/theme';
import Notification from 'Components/Notification';
import LocalizationProvider from 'Tools/LocalizationProvider';
import { useEffect, } from 'react';
import Helmet from 'react-helmet';
import cube from '@cubejs-client/core';
import { CubeProvider, } from '@cubejs-client/react';
import { NewVersionAvailableModal } from 'Components/NewVersionAvailableModal';

const cubejsApi = cube('token', {apiUrl: 'http://localhost:4000/cubejs-api/v1',});


const App = (): JSX.Element => {
  useEffect(() => {
    const favicon = document.getElementById('favicon');

    const market = import.meta.env.VITE_APP_MARKET;
    if (favicon) {
      favicon.setAttribute('href', `/favicon${market}.ico`);
    }
  }, []);
  return (
    <>
      <CubeProvider cubejsApi={cubejsApi}>
        <ApolloProvider client={apolloClient}>
          <LocalizationProvider>
            <Router>
              <ThemeProvider theme={appTheme}>
                <NewVersionAvailableModal />
                <CssBaseline />
                <SnackbarProvider
                  Components={{
                    common: Notification,
                  }}
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <Core />
                </SnackbarProvider>
              </ThemeProvider>
            </Router>
          </LocalizationProvider>
        </ApolloProvider>
      </CubeProvider>
      <Helmet>
      {/* <!-- Foxentry start --> */}
      <script type="text/javascript">
        {`
    var Foxentry;
    (function () {
      var e = document.querySelector('script'),
        s = document.createElement('script');
      s.setAttribute('type', 'text/javascript');
      s.setAttribute('async', 'true');
      s.setAttribute('src', 'https://cdn.foxentry.cz/lib');
      e.parentNode.appendChild(s);
      s.onload = function () {
        Foxentry = new FoxentryBase('${
          import.meta.env.VITE_APP_FOXENTRY_PROJECT_ID
        }');
      };
    })();
    `}
      </script>
      {/* <!-- Foxentry end --> */}
      </Helmet>
    </>
  );
};

export default App;
