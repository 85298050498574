import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

import { ReactNode, useMemo, useState, } from 'react';
import { isCzechMarket, isHungarianMarket, } from 'Utils/market';
import { prizeValueMap as CZ_PRIZE_VALUE_MAP, } from '../../Components/PrizeWheel/data/xmas_2023_cz';

export enum PrizeType {
  Points = 'points',
  Wish = 'wish',
}

export type Prize = {
  prizeType: PrizeType;
  prizePoints: number;
  wishHeading: ReactNode;
  wishBody: ReactNode;
};

const Img = styled('img')(() => ({
  width: '100%',
  display: 'block',
  maxHeight: '350px',
  objectFit: 'contain',
}));

const CZ_MARKET_PRIZES: Record<string, Prize> = {
  '1': {
    prizeType: PrizeType.Points,
    prizePoints: 100,
    wishHeading: '',
    wishBody: '',
  },
  '2': {
    prizeType: PrizeType.Points,
    prizePoints: 200,
    wishHeading: '',
    wishBody: '',
  },
  '3': {
    prizeType: PrizeType.Points,
    prizePoints: 500,
    wishHeading: '',
    wishBody: '',
  },
  '4': {
    prizeType: PrizeType.Wish,
    prizePoints: 0,
    wishHeading: 'Vaše Vánoční přání',
    wishBody: (
      <Typography variant="body0" paragraph>
        Někdy to nevyjde, ale nezapomeňte, že největší dary jsou okolo vás. Přejeme vám veselé Vánoce v kruhu vašich nejbližších.
      </Typography>
    ),
  },
};

const HU_MARKET_PRIZES: Record<string, Prize> = {
  '1': {
    prizeType: PrizeType.Wish,
    prizePoints: 0,
    wishHeading: 'Egyél egy bejglit!',
    wishBody: (
      <>
        <Typography variant="body0" paragraph>
          Akár házi készítésű, akár bolti süteményről van szó, a bejgli nem hiányozhat az ünnepi asztalról.
        </Typography>
        <Img
          src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExa2g3bmhkM3UybjV2YmlvdW50bnJ1ZDhlaWMweXNiMHQ1N25kdzZ4eiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/AslHvNIKDnqN6rvYOg/giphy.gif"
          alt=""
        />
      </>
    ),
  },
  '2': {
    prizeType: PrizeType.Wish,
    prizePoints: 0,
    wishHeading: 'Legyen sikerekben gazdag új éved!',
    wishBody: (
      <>
        <Typography variant="body0" paragraph>
          Sikerekben és örömökben gazdag új évet kívánunk.
        </Typography>
        <Img
          src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExYmEyOHhxdzdqOGl5aTU1cDg3ZG91Njh5MG85eTgzcGZjM2pvMXR5NiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/26tOZ42Mg6pbTUPHW/giphy.gif"
          alt=""
        />
      </>
    ),
  },
  '3': {
    prizeType: PrizeType.Wish,
    prizePoints: 0,
    wishHeading: 'Itt a forrócsoki ideje!',
    wishBody: (
      <>
        <Typography variant="body0" paragraph>
          Az ünnepi időszak remek alkalom arra, hogy egy forrócsokival hangolódj a meghitt pillanatokra.
        </Typography>
        <Img
          src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExa2hkbWVrM2o2aW44eHFiNmJtNjQ5encwZnR3czB1MHhxbm5sN2NqciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/aj8Ej31TBK1tS/giphy.gif"
          alt=""
        />
      </>
    ),
  },
  '4': {
    prizeType: PrizeType.Wish,
    prizePoints: 0,
    wishHeading: 'Nézd meg a kedvenc karácsonyi filmed!',
    wishBody: (
      <>
        <Typography variant="body0" paragraph>
          Ha nincs kedvenced, csak nézz meg egyet a klasszikusok közül! (Reszkessetek betörők, Grinch, Mi a manó?, Holiday..)
        </Typography>
        <Img
          src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExYTgyN2FiNWNhdmFkaDRxYzdrNXR0eGh2YXpjMmdzNjhiemVuODExeSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/GepjBlRKsD1uM/giphy.gif"
          alt=""
        />
      </>
    ),
  },
  '5': {
    prizeType: PrizeType.Wish,
    prizePoints: 0,
    wishHeading: 'Ne felejts el pihenni!',
    wishBody: (
      <>
        <Typography variant="body0" paragraph>
          Az ünnepek alatt gondolj magadra is, és pihenj egy kicsit!
        </Typography>
        <Img
          src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExY2U2OXY4dHh6cTJpc3pkYWR0dG1ieDVrb3ZpYzgwM3k5dXpsNnVlcSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/RdzzVrtvfqnM4/giphy.gif"
          alt=""
        />
      </>
    ),
  },
  '6': {
    prizeType: PrizeType.Wish,
    prizePoints: 0,
    wishHeading: 'Legyen békés és boldog Karácsonyod!',
    wishBody: (
      <>
        <Typography variant="body0" paragraph>
          Szeretetben és pihenésben gazdag Karácsonyt kívánunk.
        </Typography>
        <Img
          src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExMjEwMno3cmJjMWl3YXZ3YW9hbHRicHFzcnhtejF0OWl6MDVhbXp4aSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/26mfytMNKaEW1GrgQ/giphy.gif"
          alt=""
        />
      </>
    ),
  },
  '7': {
    prizeType: PrizeType.Wish,
    prizePoints: 0,
    wishHeading: 'Sok malac, és lencse Szilveszterkor!',
    wishBody: (
      <>
        <Typography variant="body0" paragraph>
          A szilveszteri szokás szerint a malacpecsenye újévi fogyasztása szerencsét hoz, pontosabban a fül, a farok, a köröm és a csülök részek. A
          lencse szerencsehozó étel, amelyet január elsején, első étkezéskor kell enni, hogy soha ne ürüljön ki a pénztárcád.
        </Typography>
        <Img
          src="/images/xmas-wheel/hu/wish_7_image.jpg"
          alt=""
        />
      </>
    ),
  },
  '8': {
    prizeType: PrizeType.Wish,
    prizePoints: 0,
    wishHeading: 'Készíts sok ünnepi fotót!',
    wishBody: (
      <>
        <Typography variant="body0" paragraph>
          Legyen kéznél a telefonod, hogy megörökíthesd a közös pillanatokat!
        </Typography>
        <Img
          src="/images/xmas-wheel/hu/wish_8_image.jpg"
          alt=""
        />
      </>
    ),
  },
};

const resolvePrizeNumber = (prizeValue: string, prizeValueMap?: Map<string, number>): number => {
  const prizeValueAsInt = !prizeValueMap ? parseInt(prizeValue, 10) : prizeValueMap.get(prizeValue);

  if (!prizeValueAsInt || Number.isNaN(prizeValueAsInt)) {
    throw new Error('Invalid prize value');
  }

  return prizeValueAsInt - 1;
};

export const usePrize = () => {
  const [prizeValue, setPrizeValue,] = useState<string>('4');

  const prize = useMemo(() => {
    let prizeItem;
    let prizeNumber;

    if (isCzechMarket) {
      prizeItem = CZ_MARKET_PRIZES[prizeValue];
      prizeNumber = resolvePrizeNumber(prizeValue, CZ_PRIZE_VALUE_MAP);
    } else if (isHungarianMarket) {
      prizeItem = HU_MARKET_PRIZES[prizeValue];
      prizeNumber = resolvePrizeNumber(prizeValue);
    }

    if (!prizeItem) {
      throw new Error('Invalid prize value');
    }

    if (prizeNumber === undefined) {
      throw new Error('Invalid prize number');
    }

    return {
      ...prizeItem,
      prizeNumber,
    };
  }, [prizeValue,]);

  return [prize, setPrizeValue,] as const;
};
