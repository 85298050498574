import SvgIcon, { SvgIconProps, } from '@mui/material/SvgIcon';

const UnOrderedListIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    {...props}
    fill="none"
  >
    <path
      d="M3.381 7.94a1.296 1.296 0 1 0 0 2.595c.718-.001 1.298-.58 1.298-1.299 0-.717-.58-1.297-1.298-1.297Zm0-5.19c-.718 0-1.297.58-1.297 1.297 0 .718.58 1.298 1.297 1.298.718 0 1.298-.58 1.298-1.298 0-.718-.58-1.297-1.298-1.297Zm0 10.378c-.718 0-1.297.588-1.297 1.298 0 .709.588 1.297 1.297 1.297.71 0 1.298-.588 1.298-1.297 0-.71-.58-1.298-1.298-1.298Zm3.46 2.162h10.378c.476 0 .865-.389.865-.864a.867.867 0 0 0-.865-.865H6.841a.867.867 0 0 0-.865.865c0 .475.39.864.865.864Zm0-5.189h10.378c.476 0 .865-.389.865-.865a.867.867 0 0 0-.865-.864H6.841a.867.867 0 0 0-.865.864c0 .476.39.865.865.865Zm-.865-6.054c0 .476.39.865.865.865h10.378c.476 0 .865-.389.865-.865a.867.867 0 0 0-.865-.865H6.841a.867.867 0 0 0-.865.865Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default UnOrderedListIcon;
