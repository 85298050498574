import { useTranslation, } from 'react-i18next';
import { Typography, } from '@mui/material';
import AppContainer from 'Components/AppContainer';
import { NotificationType, } from 'Apollo/graphql';
import { useDelayedResetNotificationCategoryCount, } from 'Tools/useDelayedResetNotificationCategoryCount';
import AssortmentList from './AssortmentList';

const AssortmentSellerPage = (): JSX.Element => {
  const { t, } = useTranslation('assortmentSeller');

  useDelayedResetNotificationCategoryCount(NotificationType.Assortment);

  return (
    <AppContainer maxWidth="sm">
      <Typography
        variant="h1"
        marginBottom={3}
      >
        {t('assortmentTitle')}
      </Typography>
      <AssortmentList />
    </AppContainer>
  );
};

export default AssortmentSellerPage;
