import AppContainer from 'Components/AppContainer';
import { Grid, Typography, } from '@mui/material';

import UsersByRoles from 'Components/Dashboard/Users/UsersByRoles';
import ActiveInactiveUsers from 'Components/Dashboard/Users/ActiveInactiveUsers';
import { Claim, DashboardLayoutItemName, useUserDashboardLayoutQuery, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth';
import { useTranslation, } from 'react-i18next';

const Users = () => {
  const { data, } = useUserDashboardLayoutQuery();
  const authUser = useAuthUser();
  const { t,} = useTranslation('common');
  return (
    <AppContainer>
      <Typography variant="h1" marginBottom={2}>{t('dashboardSidebar.users')}</Typography>
      <Grid container spacing={2}>
        <Grid xs={12} md={6} container item spacing={2}>
          {authUser?.claimsSet.has(Claim.DashboardUsersActiveUsers) && (
            <Grid item xs={12}>
              <ActiveInactiveUsers
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.UsersActiveUsers,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid xs={12} md={6} container item spacing={2}>
          {authUser?.claimsSet.has(Claim.DashboardUsersUserRoles) && (
            <Grid item xs={12}>
              <UsersByRoles
                showAddButton={
                  !data?.userDashboardLayout.layout?.includes(
                    DashboardLayoutItemName.UsersUserRoles,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AppContainer>
  );
};

export default Users;
