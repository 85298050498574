import { ReactNode, } from 'react';
import { styled, } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const StyledListItemButton = styled(ListItemButton)(({ theme, }) => ({
  padding: '16px 24px',

  '& .MuiListItemIcon-root': {
    minWidth: 40,
  },

  '& .MuiListItemText-primary': {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '0.875rem',
    lineHeight: '1.5em',
    letterSpacing: '0.25px',
  },

  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: '#E6E6E6',
  },
}));

interface Props {
  children: ReactNode,
  icon: ReactNode,
  disabled?: boolean,
  onClick?: () => void,
  isAllowed?: boolean,
};

const SidebarMenuButton = ({
  icon,
  disabled,
  children,
  onClick,
  isAllowed,
}: Props): JSX.Element | null => {
  if (isAllowed === false) return null; 
  
  return (
    <StyledListItemButton
      onClick={onClick}
      disabled={disabled}
    >
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={children} />
    </StyledListItemButton>
  );
};

export default SidebarMenuButton;
