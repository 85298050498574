import { yupResolver, } from '@hookform/resolvers/yup';
import { useReactivateUserMutation, } from 'Apollo/graphql';
import { useAuthUser, } from 'Tools/auth/hooks/useAuthUser';
import { useSnackbar, } from 'notistack';
import { useCallback, } from 'react';
import { useTranslation, } from 'react-i18next';
import { FormProvider, useForm,} from 'react-hook-form';
import View from './View';
import { reactivateUserValidationValues, } from './utils';

interface Props {
  onCloseReactivate: () => void;
  onCloseDetail: () => void;
}

const Logic = ({  onCloseReactivate, onCloseDetail, }: Props): JSX.Element => {
  const { t, } = useTranslation('users');
  const { enqueueSnackbar, } = useSnackbar();
  const authUser = useAuthUser();
  const methods = useForm({
    defaultValues: { username: '', },
    resolver: yupResolver(reactivateUserValidationValues),
    mode: 'onTouched',
    context: {
      authUser,
    },
  });

  const [reactivateMutation, reactivateMutationResult,] = useReactivateUserMutation({
    onCompleted: () => {
      enqueueSnackbar({
        title: t('reactivateUserModal.notifReactivatedTitle') as string,
        message: t('reactivateUserModal.notifReactivatedMessage', { username: methods.getValues().username, }),
        variant: 'common',
      });
      onCloseReactivate();
      onCloseDetail();
    },
  });

  const handleSubmit = useCallback(() => {
    reactivateMutation({
      variables: { username: methods.getValues().username, },
    });
  }, [reactivateMutation, methods,]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View reactivateMutationResult={reactivateMutationResult} onClose={onCloseReactivate} onSubmit={handleSubmit} />
      </form>
    </FormProvider>
  );
};

export default Logic;
